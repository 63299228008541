import React from 'react';
import locationImage from '../../images/location-2-svgrepo-com.svg';
import profileapi from '../../api/profileapi';
import notify from '../../error';
import { allStates, getDistricts } from "../../stateDistrict";
import '../css/modifyAddress.css';
export default function ModifyAddress2(props) {
  // const [page,Setpage] = React.useState(1)

  const [formDataPassword, setFormDataPassword] = React.useState(props.data)
  console.log(props.data)
  function handleChangePassword(event) {
    //  console.log(event);
    const { name, value, type, checked } = event.target;
    setFormDataPassword((prevFormData) => {
      return {
        ...prevFormData,
        [name]: type === 'checkbox' ? checked : value,
      };
    });
    //  console.log(formDataPassword);
  }

  // const OnCancel=()=>
  // {
  //  console.log(props)
  //  props.back()
  // }

  const onAddition = async () => {

    let variable = formDataPassword.default_address == 1 ? 1 : 0;

    const response = await profileapi.ModifyAddress(

      formDataPassword.name,
      formDataPassword.phone,
      formDataPassword.addressline1,
      formDataPassword.addressline2,
      formDataPassword.landmark,
      formDataPassword.town,
      formDataPassword.pincode,
      formDataPassword.latitude,
      formDataPassword.longitude,
      formDataPassword.district,
      formDataPassword.state,
      variable,
      props.data.address_id,
    );

    console.log(response);
    props.reload();
    if (response.data.status === true) {
      props.back();
      notify('success', 'Address updated successfully');
    } else {
      notify('warn', response.data.error);
    }
  };

  return (
    <React.Fragment>
      <div className='profile-account-setting-right-container'>
        <div className='profile-account-setting-right-new-address-heading'>
          Edit My Address
        </div>
        <div className='profile-account-setting-right-new-address-location'></div>

        <div className='profile-account-setting-right-new-address-input'>
          <div>
            <p>Name</p>
            <input
              type='text'
              value={formDataPassword.name}
              name='name'
              onChange={handleChangePassword}
              placeholder='Name'
            ></input>
          </div>
          <div>
            <p>Mobile NUmber</p>
            <input
              type='text'
              value={formDataPassword.phone}
              name='phone'
              onChange={handleChangePassword}
              placeholder='10- digit mobile number'
            ></input>
          </div>
          <div>
            <p>PinCode</p>
            <input
              type='text'
              value={formDataPassword.pincode}
              name='pincode'
              onChange={handleChangePassword}
              placeholder='Pincode'
            ></input>
          </div>
          <div>
            <p>Flat,House no.,Landmark</p>
            <input
              type='text'
              value={formDataPassword.addressline1}
              name='addressline1'
              onChange={handleChangePassword}
              placeholder='Flat , House no. , Landmark   '
            ></input>
          </div>
          <div>
            <p>Town/City</p>
            <input
              type='text'
              value={formDataPassword.town}
              name='town'
              onChange={handleChangePassword}
              placeholder='Town/City'
            ></input>
          </div>
          <div>
            <p>Area,Street,Sector,Village</p>
            <input
              type='text'
              value={formDataPassword.addressline2}
              name='addressline2'
              onChange={handleChangePassword}
              placeholder='Area , Street , Sector , Village'
            ></input>
          </div>
          <div>

            <p>Select State</p>
            <select
              name="state"
              value={
                formDataPassword.state
                  ? formDataPassword.state
                    ? formDataPassword.state
                    : ""
                  : ""
              }

              onChange={handleChangePassword}
            >
              <option value="">Select State</option>
              {allStates.map((state) => (
                <option key={state} value={state}>
                  {state}
                </option>
              ))}
            </select>
          </div>
          <div>
            <p>District</p>
            <input
              type='text'
              value={formDataPassword.district}
              name='district'
              onChange={handleChangePassword}
              placeholder='District'
            ></input>
          </div>
        </div>
        <div className='profile-account-setting-right-new-address-input-check'>
          <input
            type='checkbox'
            id='profile-account-setting-right-new-address-input-check-1'
            checked={formDataPassword.default_address}
            onChange={handleChangePassword}
            name='default_address'
          />
          <label htmlFor='profile-account-setting-right-new-address-input-check-1'>
            Make this my default address
          </label>
        </div>
        <div className='profile-account-setting-right-new-address-input-result'>
          <button onClick={onAddition}>Update Address</button>
          <div onClick={props.back}>Cancel</div>
        </div>
      </div>
    </React.Fragment>
  );
}
