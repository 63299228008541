import axios from "axios";
//company
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

class ProfileService {
  MyAddedServiceprovider() {
    return axios.get(API_BASE_URL + `/v1/api/MyAddedServiceProvider`, {
      headers: {
        authtoken: localStorage.getItem("authtoken"),
        "Content-Type": "application/json",
      },
    });
  }
  AddServiceprovider(email, image, mobile, name, service_id, subservice_id) {
    return axios.post(
      API_BASE_URL + "/v1/api/addServiceprovider",
      { email, image, mobile: `+91${mobile}`, name, service_id, subservice_id },
      {
        headers: {
          authtoken: localStorage.getItem("authtoken"),
        },
      }
    );
  }
  DeleteServiceProvider(id) {
    return axios.post(
      API_BASE_URL + "/v1/api/delete_added_serviceprovider",
      { sp_id: id },
      {
        headers: {
          authtoken: localStorage.getItem("authtoken"),
        },
      }
    );
  }
  ModifyProvider(id, image, mobile, name, serviceid, sub_service_id) {
    return axios.post(
      API_BASE_URL + "/v1/api/modify_added_serviceprovider",
      {
        sp_id: id,
        logo: image,
        mobile: `+91${mobile}`,
        provider_name: name,
        service: parseInt(serviceid),
        sub_service: parseInt(sub_service_id),
      },
      {
        headers: {
          authtoken: localStorage.getItem("authtoken"),
        },
      }
    );
  }
  DeleteMyProduct(
    service_id,
    service_provider_id,
    quantity_id,
    color_id,
    wholeProduct
  ) {
    // Create an object to hold the request data
    const payload = {
      sp_productid: quantity_id,
      sp_id: service_provider_id
    };

    // Check if selectAll is  "false" and add it to the payload object
    if (wholeProduct === false) {
      if (color_id.length > 0) {
        const newdata = color_id.filter(item => item !== null)
        payload.color_id = newdata;
        payload.service_id = service_id;
      }
    }
    console.log(payload);

    // Make the axios request with payload
    return axios.post(
      API_BASE_URL + "/v1/api/deleteServiceProviderProduct_withcolor",
      payload,
      {
        headers: {
          authtoken: localStorage.getItem("authtoken"),
        },
      }
    );
  }

  UpdateServiceProviderProduct(
    service_provider_id,
    serviceProvider_ProductID,
    discount,
    actual_price,
    unit,
    out_of_stock,
    product_name,
    multiple_images,
    delete_image_id,
    color_id,
    color_code, color_name
  ) {
    const requestData = {
      service_provider_id,
      serviceProvider_ProductID,
      out_of_stock,

    };
    // Convert to integer if not null
    if (discount !== null) {
      requestData.discount = parseInt(discount);
    }

    if (actual_price !== null) {
      requestData.actual_price = parseInt(actual_price);
    }
    if (color_name !== null && color_code !== null) {
      requestData.color_code = color_code;
      requestData.color_name = color_name;
    }
    if (color_id !== null) {
      requestData.color_id = color_id;
    }

    // Include unit only if not null
    if (unit !== null) {
      requestData.unit = unit;
    }
    if (multiple_images.length > 0) {
      requestData.multiple_images = multiple_images;
    }
    if (delete_image_id.length > 0) {
      requestData.delete_image_id = delete_image_id;
    }

    return axios.post(
      API_BASE_URL + "/v1/api/updateServiceProviderProduct",
      requestData,
      {
        headers: {
          authtoken: localStorage.getItem("authtoken"),
        },
      }
    );
    // console.log(
    //   service_provider_id,
    //   serviceProvider_ProductID,
    //   discount,
    //   actual_price,
    //   selling_price,
    //   unit,
    //   out_of_stock,
    //   description,
    //   product_img,
    //   product_name
    // );
  }
  AllServiceSubSerice() {
    return axios.get(API_BASE_URL + `/v1/api/all_service_subservice`, {
      headers: {
        authtoken: localStorage.getItem("authtoken"),
        "Content-Type": "application/json",
      },
    });
  }
  BlockList() {
    return axios.get(API_BASE_URL + `/v1/api/BlockList`, {
      headers: {
        authtoken: localStorage.getItem("authtoken"),
        "Content-Type": "application/json",
      },
    });
  }
  UpdateProfile(first_name, last_name, gender, username, email, DOB) {
    return axios.post(
      API_BASE_URL + "/v1/api/updateprofile",
      { first_name, last_name, gender, username, email, DOB },
      {
        headers: {
          authtoken: localStorage.getItem("authtoken"),
        },
      }
    );
  }
  ChangeProfilePicture(imageUrl) {
    return axios.post(
      API_BASE_URL + "/v1/api/updateprofile",
      { image: imageUrl },
      {
        headers: {
          authtoken: localStorage.getItem("authtoken"),
        },
      }
    );
    // console.log(imageUrl);
  }
  UpdateProfileMobile(mobile) {
    return axios.post(
      API_BASE_URL + "/v1/api/UpdateProfileMobile",
      { mobile },
      {
        headers: {
          authtoken: localStorage.getItem("authtoken"),
        },
      }
    );
  }
  ConfirmUpdateProfileMobile(mobile, otp) {
    return axios.post(
      API_BASE_URL + "/v1/api/ConfirmUpdateProfileMobile",
      { mobile, otp },
      {
        headers: {
          authtoken: localStorage.getItem("authtoken"),
        },
      }
    );
  }
  checkUsername(username) {
    return axios.post(
      API_BASE_URL + "/v1/api/checkusername",
      { username },
      {
        headers: {
          authtoken: localStorage.getItem("authtoken"),
        },
      }
    );
  }
  spcheckUsername(username) {
    return axios.post(
      API_BASE_URL + "/v1/api/check_sp_username",
      { username },
      {
        headers: {
          authtoken: localStorage.getItem("authtoken"),
        },
      }
    );
  }
  ShowAddress() {
    return axios.get(API_BASE_URL + `/v1/api/showaddress`, {
      headers: {
        authtoken: localStorage.getItem("authtoken"),
        "Content-Type": "application/json",
      },
    });
  }
  AddAdress(
    name,
    phone,
    addressline1,
    addressline2,
    landmark,
    town,
    pincode,
    latitude,
    longitude,
    district,
    state,
    default_address
  ) {
    return axios.post(
      API_BASE_URL + "/v1/api/addaddress",
      {
        name,
        phone,
        addressline1,
        addressline2,
        landmark,
        town,
        pincode,
        latitude,
        longitude,
        district,
        state,
        default_address,
      },
      {
        headers: {
          authtoken: localStorage.getItem("authtoken"),
        },
      }
    );
  }
  RemoveAddress(address_id) {
    return axios.post(
      API_BASE_URL + "/v1/api/removeaddress",
      { address_id },
      {
        headers: {
          authtoken: localStorage.getItem("authtoken"),
        },
      }
    );
  }
  ModifyAddress(
    name,
    phone,
    addressline1,
    addressline2,
    landmark,
    town,
    pincode,
    latitude,
    longitude,
    district,
    state,
    default_address,
    address_id
  ) {
    return axios.post(
      API_BASE_URL + "/v1/api/modifyaddress",
      {
        name,
        phone,
        addressline1,
        addressline2,
        landmark,
        town,
        pincode,
        latitude,
        longitude,
        district,
        state,
        default_address,
        address_id,
      },
      {
        headers: {
          authtoken: localStorage.getItem("authtoken"),
        },
      }
    );
  }
  UpdatePassword(password) {
    return axios.post(
      API_BASE_URL + "/v1/api/updateprofile",
      { password },
      {
        headers: {
          authtoken: localStorage.getItem("authtoken"),
        },
      }
    );
  }

  unBlockProvider(serviceprovider_id) {
    return axios.post(
      API_BASE_URL + "/v1/api/Unblock",
      { serviceprovider_id: serviceprovider_id },
      {
        headers: {
          authtoken: localStorage.getItem("authtoken"),
        },
      }
    );
  }

  downloadCatlog(sp_id, service_id, product_type_id, sub_service_id) {
    const payload = { sp_id };

    if (product_type_id !== undefined && product_type_id !== null && product_type_id !== '') {
      payload.product_type_id = parseInt(product_type_id);
    }

    if (sub_service_id !== undefined && sub_service_id !== null && sub_service_id !== '') {
      payload.sub_service_id = parseInt(sub_service_id);
    }
    if (service_id !== undefined && service_id !== null && service_id !== '') {
      payload.service_id = parseInt(service_id);
    }


    return axios.post(
      API_BASE_URL + "/v1/api/downloadpdf_Allproduct_serviceprovider",
      payload,
      {
        headers: {
          authtoken: localStorage.getItem("authtoken"),
        },
      }
    );
  }

  changeMobileNumber(mobile) {
    return axios.post(
      API_BASE_URL + "/v1/api/UpdateProfileMobile",
      { mobile },
      {
        headers: {
          authtoken: localStorage.getItem("authtoken"),
        },
      }
    );
  }

  // verify mobile number of seller
  validateMobileNumber(phone) {
    return axios.post(
      API_BASE_URL + "/v1/api/validate_phone",
      { phone },
      {
        headers: {
          authtoken: localStorage.getItem("authtoken"),
        },
      }
    );
  }

  verifyOTPseller(otp) {
    return axios.post(
      API_BASE_URL + "/v1/api/confirm_validate",
      { otp },
      {
        headers: {
          authtoken: localStorage.getItem("authtoken"),
        },
      }
    );
  }

  checkOtpForNumberChange(mobile, otp) {
    return axios.post(
      API_BASE_URL + "/v1/api/ConfirmUpdateProfileMobile",
      { mobile, otp },
      {
        headers: {
          authtoken: localStorage.getItem("authtoken"),
        },
      }
    );
  }

  // AllPromotion(index) {
  //   return axios.get(API_BASE_URL + `/v1/api/displayPost/${index}`, {
  //     headers: {
  //       authtoken: localStorage.getItem('authtoken'),
  //       'Content-Type': 'application/json',
  //     },
  //   });
  // }
  // DeletePost(is_comment, id) {
  //   return axios.post(
  //     API_BASE_URL + '/v1/api/deletePost', //0 for post //1 for comment
  //     { is_comment, id },
  //     {
  //       headers: {
  //         authtoken: localStorage.getItem('authtoken'),
  //       },
  //     }
  //   );
  // }
}

export default new ProfileService();
