import React, { useRef } from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import "./css/PdfViewer.css"
import generatePDF from 'react-to-pdf';
import logo2 from '../../images/logo.png';
import myProfileServiceApi from '../../api/myProfileServiceApi';
import notify from '../../error';
import { useParams } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import { useReactToPrint } from 'react-to-print';
import { BiTargetLock } from 'react-icons/bi';
import Loading from '../../components/Loading';
import { useState } from 'react';
const styles = StyleSheet.create({
    page: {
        display: 'flex',
        flexDirection: 'column',
        width: "900px",
        margin:"0px 20px",
        paddingTop:"20px"
    },
    image: {
        width: 80,
        height: 80,
        marginTop: "-40px"
    },
    Imagesection: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%"
    },
    Heading: {
        color: "#fff",
        fontSize: 20,
        fontWeight: "bold"
    },
    Heading1:{
        color: "black",
        fontSize: 20,
        fontWeight: "bold"
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
    },
    Billsection: {
        marginTop: 20,
        display: "flex",
        flexDirection: "column",
        backgroundColor: '#61cbd3',
        padding: "10px",
        width: "50%",
        borderRadius: "5px"
    },
    Billsection1: {
        marginTop: 20,
        display: "flex",
        flexDirection: "column",
        padding: "10px",
        width: "50%",
        borderRadius: "5px"
    },
    Billsection2: {
        marginTop: 20,
        display: "flex",
        flexDirection: "column",
        justifyContent:"flex-start",
        padding: "10px",
        width: "50%",
        borderRadius: "5px"
    }
    ,
    Grid: {
        display: "flex",
        gap: "10px",
        width: "100%"
    },

    gstsection: {
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "column",
        alignItems: "flex-start"
    },
    text: {
        fontFamily: 'Helvetica',
        fontWeight: "normal",
        fontSize: "14px"
    },
    gstTitle: {
        fontSize: 20,
        fontWeight: 'bold',
        color: '#00838f', // Set the color to purple
        textAlign: 'center', // Align the text to the center
    },
    gstTitle1:{
        fontSize: 20,
        fontWeight: 'bold', 
        textAlign: 'center',
        color: "black",
    },
    addressText: {
        fontSize: 12,
        textTransform: 'uppercase', // Convert text to uppercase
    },
    dateSection: {
        display: "flex",
        flexDirection: "column", fontWeight: "medium", gap: "5px",
        alignItems: "flex-start",
        width: "100%",
    },

    dateText: {
        color: "gray",
        display: "flex",
        gap: "20px",
        fontWeight: "bold"


    },
    Billtext: {
        display: "flex",
        flexDirection: "column"
    },
    BillAddress: {
        display: "flex",
        justifyContent: "space-around",
        width: "100%",
        marginTop: "10px",
        marginBottom: "-20px",
        padding: "10px 30px ",
        alignItems: "center"
    },
    date: {
        color: 'black',
        fontWeight: "bold",

    },
    table: {
        display: 'table',
        width: '100%',
        marginTop: 20,

        borderRadius: '5px', // Add rounded corners to the table
        // overflow: "hidden"
    },
    tableRow: {

        display: 'table-row',
        margin: 'auto',
        backgroundColor: '#61cbd3',
    },
    tableRow1: {

        display: 'table-row',
        margin: 'auto',
        // backgroundColor: '#525151',
        // color:'white',
        fontWeight:"bold",
        border: "3px solid black",
    },
    tableCell: {
        display: 'table-cell',
        padding: 5,

    },
    tableheading: {
        backgroundColor: '#00838f',
        color: "#fff",
    },
    tableheading1:{
        // backgroundColor: '#00838f',
    },
    tabletext: {
        fontWeight: "bold",
        fontSize: "14px"
    },
    Footer: {
        display: "flex",
        width: "100%"
    },
    totalAmountContainer: {
        display: "flex",
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: "40px",
        width: "50%",

    },
    horizontalLine: {
        width: "inherit",
        margin: "-2px",
        borderStyle: 'solid',
        borderBottomWidth: 1.5,
        borderBottomColor: 'black',

    },
    totalAmountText: {
        fontWeight: "bold",
        fontSize: "20px"
    },
});

// C7

const PdfViewer = () => {
    const { orderid, type } = useParams();
    const isonlineinvoice = type === 'online' ? true : false;
    const [data, setApiData] = React.useState([]);
    const targetRef = useRef();
    const [selectedpaper,setSelectedpaper]=useState("A4");
    function formatDate(inputDate) {
        const options = { year: 'numeric', month: 'short', day: '2-digit' };
        const date = new Date(inputDate);
        return date.toLocaleDateString('en-US', options);
    }

    const formattedDate = formatDate(data.order_date);

    const getdatapdf = async () => {
let response;
       if(isonlineinvoice){
         response = await myProfileServiceApi.InvoicePdfOnline(
            orderid
        );
       }else{
         response = await myProfileServiceApi.InvoicePdfOffline(
            orderid
          );
       }
     
        console.log(response);

        if (response.data && response.data.error != '') {
            notify('error', response.data.error);
        } else {
            setApiData(response.data);



        }
    }

    const handlePrint = useReactToPrint({
        
        pageStyle:()=>{return "font-size:'40px'"},
        content: () => targetRef.current,

    });
    React.useEffect(() => {
        getdatapdf();
    }, [])

    return (
        <>
            {data && Object.keys(data).length > 0 ? (
                <div className='invoice-pdf-container'>
                    <select className='paperselected' onChange={
                    (event)=>{
                        setSelectedpaper(event.target.value)
                    }}>
                      <option value="A4" selected>A4 size</option>
                      <option value="C7">80mm size</option>
                    </select>
                    <div className="invoice-pdf-content">

                        <div className="pdf-buttons-container">
                            <button className='save-pdf' onClick={() => generatePDF(targetRef, { filename: 'invoice.pdf' })}>Save as PDF</button>

                            <button onClick={handlePrint}>Print</button>
                        </div>

                        <Document ref={targetRef} className={selectedpaper === "C7" ? "receiptsizeselected":"A4SIZE"}>
                            <Page size={selectedpaper} style={styles.page}>
                                <View style={styles.gstsection}>
                            {selectedpaper === "A4" ?
                                <Text style={styles.gstTitle}>GST Invoice</Text>: <Text style={styles.gstTitle1} className="headingtextprint">GST Invoice</Text>
                            }
                                    <Text style={styles.addressText} className={selectedpaper === "C7" ? "textprint":""}>
                                    Putatoe Solutions Pvt. Ltd. , gorakhpur - 273209
                                    </Text>
                                    <View style={styles.dateSection}>
                                        <Text style={styles.dateText} className={selectedpaper === "C7" ? "textprint1":"simple"}>Invoice No #
                                            <Text style={styles.date} className={selectedpaper === "C7" ? "textprint1":"simple"}>23652362</Text>
                                        </Text>
                                        <Text style={styles.dateText}  className={selectedpaper === "C7" ? "textprint1":"simple"}>Invoice Date
                                            <Text style={styles.date}  className={selectedpaper === "C7" ? "textprint1":"simple"}> {formattedDate}</Text>
                                        </Text>
                                        <View style={styles.Imagesection}>
                                            <Text style={styles.dateText}  className={selectedpaper === "C7" ? "textprint1":""}>Due Date
                                                <Text style={styles.date}  className={selectedpaper === "C7" ? "textprint1":""}> {formattedDate}</Text>
                                            </Text>
                                            <img
                                                style={styles.image}
                                                src={logo2}
                                            /></View>

                                    </View>




                                </View>
                                <View style={styles.Grid}>
                            {
                                selectedpaper === "A4" ? <>
                                    <View style={styles.Billsection}>
                                        <Text style={styles.Heading}>Billed By</Text>
                                        <Text style={styles.date}>Putatoe Solutions Private Limited</Text>

                                        <Text>403V,Basharatpur,    </Text>
                                        <Text> Gorakhpur,    </Text>
                                        <Text>  Uttar Pradesh, India - 273004
                                        </Text>


                                        <View style={styles.Billtext}>

                                            <Text style={styles.date}>GSTIN:  <Text style={styles.text}>09AAMCP3761E1Z7</Text></Text>
                                            <Text style={styles.date}>PAN:  <Text style={styles.text}>AAMCP3761E</Text></Text>
                                            <Text style={styles.date}>Email: <Text style={styles.text}>putatoecustomercare@gmail.com</Text></Text>
                                            <Text style={styles.date}>Phone: <Text style={styles.text}>+91 77601 78902</Text></Text>
                                        </View>



                                    </View>
                                    </>:
                                    <>
                                    <View style={styles.Billsection1}>
                                        <Text style={styles.Heading1} className="headingtextprint">Billed By</Text>
                                        <Text style={styles.date} className="headingtextprint2">Putatoe Solutions Private Limited</Text>

                                        <Text className="headingtextprint2">403V,Basharatpur,    </Text>
                                        <Text className="headingtextprint2"> Gorakhpur,    </Text>
                                        <Text className="headingtextprint2">  Uttar Pradesh, India - 273004
                                        </Text>


                                        <View style={styles.Billtext}>

                                            <Text style={styles.date} className="headingtextprint2">GSTIN:  <Text style={styles.text}className="headingtextprint3">09AAMCP3761E1Z7</Text></Text>
                                            <Text style={styles.date} className="headingtextprint2">PAN:  <Text style={styles.text} className="headingtextprint3">AAMCP3761E</Text></Text>
                                            <Text style={styles.date} className="headingtextprint2">Email: <Text style={styles.text} className="headingtextprint3">putatoecustomercare@gmail.com</Text></Text>
                                            <Text style={styles.date} className="headingtextprint2">Phone: <Text style={styles.text} className="headingtextprint3">+91 77601 78902</Text></Text>
                                        </View>



                                    </View>
                                    </>
            }
                                    {/* <View style={styles.Billsection}>
                                        <Text style={styles.Heading}>Billed To</Text>
                                        <Text style={styles.date}>Putatoe Solutions Private Limited</Text>
                                        <Text>403V,Basharatpur,    </Text>
                                        <Text> Gorakhpur,    </Text>
                                        <Text>  Uttar Pradesh, India - 273004
                                        </Text>



                                        <View style={styles.Billtext}>

                                            <Text style={styles.date}>GSTIN:  <Text style={styles.text}>09AAMCP3761E1Z7</Text></Text>
                                            <Text style={styles.date}>PAN:  <Text style={styles.text}>AAMCP3761E</Text></Text>

                                        </View>



                                    </View> */}
                            {  selectedpaper === "A4" ? <>
                                    <View style={styles.Billsection}>
                                        <Text style={styles.Heading}>Billed To</Text>
                                        <View style={styles.Billtext}>


                                            <Text style={styles.date}>Customer Name:  <Text style={styles.text}>{data.customer_details.name}</Text></Text>

                                            {data.customer_details.email && (
                                                <Text style={styles.date}>
                                                    Email: <Text style={styles.text}>{data.customer_details.email}</Text>
                                                </Text>
                                            )}                             <Text style={styles.date}>
                                                Mobile: <Text style={styles.text}>{data.customer_details.mobile_no}</Text>
                                            </Text>

                                        </View>



                                        </View>
                                        </>:
                                        <>
                                        <View style={styles.Billsection2}>
                                        <Text style={styles.Heading1} className="headingtextprint">Billed To</Text>
                                        <View style={styles.Billtext}>


                                            <Text style={styles.date} className="headingtextprint2">Customer Name:  <Text style={styles.text} className="headingtextprint3">{data.customer_details.name}</Text></Text>

                                            {data.customer_details.email && (
                                                <Text style={styles.date} className="headingtextprint2">
                                                    Email: <Text style={styles.text} className="headingtextprint3">{data.customer_details.email}</Text>
                                                </Text>
                                            )}                             <Text style={styles.date} className="headingtextprint2">
                                                Mobile: <Text style={styles.text} className="headingtextprint3">{data.customer_details.mobile_no}</Text>
                                            </Text>

                                        </View>

                                        </View>
                                        </>
                                }
                                    </View>

                                {/* Table Heading Row */}
                                {  selectedpaper === "A4" ? 
                                <>
                                <View style={styles.table}>

                                    <View style={{ ...styles.tableRow, ...styles.tableheading }}>
                                        <View style={{ ...styles.tableCell, width: '30%', paddingLeft: "20px" }}>
                                            <Text style={{ ...styles.text, ...styles.tabletext }}>Item</Text>
                                        </View>
                                        <View style={styles.tableCell}>
                                            <Text style={{ ...styles.text, ...styles.tabletext }}>Price</Text>
                                        </View>
                                        <View style={styles.tableCell}>
                                            <Text style={{ ...styles.text, ...styles.tabletext }}>Quantity</Text>
                                        </View>
                                        {/* <View style={styles.tableCell}>
                                            <Text style={{ ...styles.text, ...styles.tabletext }}>Discount</Text>
                                        </View> */}
                                        <View style={styles.tableCell}>
                                            <Text style={{ ...styles.text, ...styles.tabletext }}>Units</Text>
                                        </View>
                                        <View style={styles.tableCell}>
                                            <Text style={{ ...styles.text, ...styles.tabletext }}>Total</Text>
                                        </View>


                                    </View>
                                    {data.invoice_list.map((item, index) => (
                                        <View key={index} style={styles.tableRow}>
                                            <View style={{ ...styles.tableCell, width: '30%' }}>
                                                <Text style={styles.text}>{index + 1}. </Text>
                                                <Text style={styles.text}>{item.product_name + " "}{item.color_name ? `( ${item.color_name} )` : null}</Text>
                                            </View>
                                            <View style={styles.tableCell}>
                                                {isonlineinvoice ? <Text style={styles.text}>{item.price}</Text> : <Text style={styles.text}>{item.price_each_item}</Text>}
                                            </View>
                                            <View style={styles.tableCell}>
                                                {isonlineinvoice ? <Text style={styles.text}>{item.quantity}</Text> : <Text style={styles.text}>{item.product_quantity}</Text>}
                                            </View>
                                            {/* <View style={styles.tableCell}>
                                                <Text style={styles.text}>{item.discount}</Text>
                                            </View> */}
                                            <View style={styles.tableCell}>
                                                {isonlineinvoice ? <Text style={styles.text}>{item.units}</Text> : <Text style={styles.text}>{item.quantity}</Text>}
                                            </View>

                                            <View style={styles.tableCell}>
                                                {isonlineinvoice ? <Text style={styles.text}>{item.total_price}</Text> : <Text style={styles.text}>{item.final_price}</Text>}
                                            </View>

                                        </View>

                                    ))}
                                </View>
</> :
<>
<View style={styles.table}>

<View style={{ ...styles.tableRow1, ...styles.tableheading1 }}>
    <View style={{ ...styles.tableCell, width: '30%', paddingLeft: "20px",border:"1px solid black" }}>
        <Text style={{ ...styles.text, ...styles.tabletext }} className="headingpart4">Item</Text>
    </View>
    <View style={{...styles.tableCell,border:"1px solid black"}}>
        <Text style={{ ...styles.text, ...styles.tabletext }} className="headingpart4">Price</Text>
    </View>
    <View style={{...styles.tableCell,border:"1px solid black"}}>
        <Text style={{ ...styles.text, ...styles.tabletext }} className="headingpart4">Quantity</Text>
    </View>
    {/* <View style={{...styles.tableCell,border:"1px solid black"}}>
        <Text style={{ ...styles.text, ...styles.tabletext }} className="headingpart4">Discount</Text>
    </View> */}
    <View style={{...styles.tableCell,border:"1px solid black"}}>
        <Text style={{ ...styles.text, ...styles.tabletext }} className="headingpart4">Units</Text>
    </View>
    <View style={{...styles.tableCell,border:"1px solid black"}}>
        <Text style={{ ...styles.text, ...styles.tabletext }} className="headingpart4">Total</Text>
    </View>


</View>
{data.invoice_list.map((item, index) => (
    <View key={index} style={styles.tableRow1}>
        <View style={{ ...styles.tableCell, width: '30%',border:"1px solid black" }}>
            <Text style={styles.text} className="text4">{index + 1}. </Text>
            <Text style={styles.text} className="text4">{item.product_name + " "}{item.color_name ? `( ${item.color_name} )` : null}</Text>
        </View>
        <View style={{...styles.tableCell,border:"1px solid black"}}>
            {isonlineinvoice ? <Text style={styles.text} className="text4">{item.price}</Text> : <Text style={styles.text} className="text4">{item.price_each_item}</Text>}
        </View>
        <View style={{...styles.tableCell,border:"1px solid black"}}>
            {isonlineinvoice ? <Text style={styles.text} className="text4">{item.quantity}</Text> : <Text style={styles.text} className="text4">{item.product_quantity}</Text>}
        </View>
        {/* <View style={{...styles.tableCell,border:"1px solid black"}}>
            <Text style={styles.text} className="text4">{item.discount}</Text>
        </View> */}
        <View style={{...styles.tableCell,border:"1px solid black"}}>
            {isonlineinvoice ? <Text style={styles.text} className="text4">{item.units}</Text> : <Text style={styles.text} className="text4">{item.quantity}</Text>}
        </View>

        <View style={{...styles.tableCell,border:"1px solid black"}}>
            {isonlineinvoice ? <Text style={styles.text} className="text4">{item.total_price}</Text> : <Text style={styles.text} className="text4">{item.final_price}</Text>}
        </View>

    </View>

))}
</View>
</>}
{
     selectedpaper === "A4" ? <>
                                <View style={styles.Footer}>
                                    <View style={{ ...styles.Billsection, width: "50%" }}>
                                        <Text style={styles.Heading}>Bank Details</Text>


                                        <View style={styles.Billtext}>

                                            <Text style={{ ...styles.date, display: "flex", gap: "10px", alignItems: "center" }}>Account Holder Name  <Text style={{ ...styles.text, width: "50%", display: "flex", flexWrap: "wrap" }}>Putatoe Solutions Private Limited</Text></Text>
                                            <Text style={styles.date}>Account Number  <Text style={styles.text}>191905001686</Text></Text>
                                            <Text style={styles.date}>IFSC <Text style={styles.text}>ICIC0001919</Text></Text>
                                            <Text style={styles.date}>Account Type <Text style={styles.text}>Current</Text></Text>
                                            <Text style={styles.date}>Bank <Text style={styles.text}>ICCI</Text></Text>
                                        </View>



                                    </View>
                                    <View style={styles.totalAmountContainer}>
                                        <hr style={styles.horizontalLine} />
                                        {isonlineinvoice ? <Text style={styles.totalAmountText}>Total (INR): {data.invoice_list.reduce((total, item) => total + parseFloat(item.total_price), 0).toFixed(2)}</Text> : <Text style={styles.totalAmountText}>Total (INR): {data.invoice_list.reduce((total, item) => total + parseFloat(item.final_price), 0).toFixed(2)}</Text>}
                                        <hr style={styles.horizontalLine} />
                                    </View>
                                </View></> :
                                <>
                                <View style={styles.Footer}>
                                    <View style={{ ...styles.Billsection1, width: "50%" }}>
                                        <Text style={styles.Heading1} className="headingtextprint">Bank Details</Text>


                                        <View style={styles.Billtext}>

                                            <Text style={{ ...styles.date, display: "flex", gap: "10px", alignItems: "center" }} className="headingtextprint2">Account Holder Name  <Text style={{ ...styles.text, width: "50%", display: "flex"}} className="headingtextprint3">Putatoe Solutions Private Limited</Text></Text>
                                            <Text style={styles.date} className="headingtextprint2">Account Number  <Text style={styles.text} className="headingtextprint3">191905001686</Text></Text>
                                            <Text style={styles.date} className="headingtextprint2">IFSC <Text style={styles.text} className="headingtextprint3">ICIC0001919</Text></Text>
                                            <Text style={styles.date} className="headingtextprint2">Account Type <Text style={styles.text} className="headingtextprint3">Current</Text></Text>
                                            <Text style={styles.date} className="headingtextprint2">Bank <Text style={styles.text} className="headingtextprint3">ICCI</Text></Text>
                                        </View>



                                    </View>
                                    <View style={styles.totalAmountContainer}>
                                        <hr style={styles.horizontalLine} className='hrr' />
                                        {isonlineinvoice ? <Text style={styles.totalAmountText}     className={selectedpaper === "C7" ? "headingtextprint4":"simple1"}>Total (INR): {data.invoice_list.reduce((total, item) => total + parseFloat(item.total_price), 0).toFixed(2)}</Text> : <Text style={styles.totalAmountText} className={selectedpaper === "C7" ? "headingtextprint4":"simple1"}>Total (INR): {data.invoice_list.reduce((total, item) => total + parseFloat(item.final_price), 0).toFixed(2)}</Text>}
                                        <hr style={styles.horizontalLine}  className='hrr'/>
                                    </View>
                                </View>
                                </>
}
                            </Page>
                        </Document>



                    </div>

                </div>

            ):( <>
                <div className="loading" id="loading" style={{ height: "100vh" }}>
                  <div className="obj"></div>
                  <div className="obj"></div>
                  <div className="obj"></div>
                  <div className="obj"></div>
                  <div className="obj"></div>
                  <div className="obj"></div>
                  <div className="obj"></div>
                  <div className="obj"></div>
                  <div className="obje">LOADING MORE</div>
                  <div className="obj"></div>
                  <div className="obj"></div>
                  <div className="obj"></div>
                  <div className="obj"></div>
                  <div className="obj"></div>
                  <div className="obj"></div>
                  <div className="obj"></div>
                  <div className="obj"></div>
                </div>
                <div className="mobile-loader" style={{ height: "50vh" }}>
                  <div className="loader"></div>
                </div>
              </>)}


        </>
    );
}

export default PdfViewer
