import React, { Component } from 'react'
import './chat-css/chatare.css';
export default function ChatArea(props)
{
 return (
   <React.Fragment>
     <>
       <div className='chat-box-main-chatiing'>
         {props &&
           props.data &&
           props.data.conversation &&
           props.data.conversation.map((item)=>
           {
            return (
              <>
                {item['0'] ? (
                  <div className='chat-box-main-chatiing-chat-right'>
                    <div className='chat-box-main-chatiing-chat-right-message'>
                      {item['0']}
                    </div>
                    <span>{item.time.substr(0, 25)}</span>
                  </div>
                ) : (
                  <div className='chat-box-main-chatiing-chat-left'>
                    <div className='chat-box-main-chatiing-chat-left-message'>
                      {item['1']}
                    </div>
                    <span>{item.time.substr(0, 25)}</span>
                  </div>
                )}
              </>
            );
           })}
       </div>
     </>
   </React.Fragment>
 );
}