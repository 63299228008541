import React, { Component } from "react";
import myProfileServiceApi from "../../api/myProfileServiceApi";
import notify from "../../error";
import { useGlobalContext } from "../../context";
import "./css/ManageMyProduct.css";
import back from "../myprofileIcoons/bx-arrow-back..svg";
import { motion } from "framer-motion";
import dustbin from "../../imageMobile/trash-bin-svgrepo-com.svg";
import profileapi from "../../api/profileapi";
import penciledit from "../../imageMobile/pencil-svgrepo-com.svg";
import Loading from "../../components/Loading";
import { BiSolidEditAlt } from "react-icons/bi";
import { MdDelete } from "react-icons/md";

export default function ManageMyProduct(props) {
  const { Userprofile, loading, setLoading, setInnerPage } = useGlobalContext();
  const [page, setPage] = React.useState(1);
  const [productList, setProductList] = React.useState();
  const [editId, setEditId] = React.useState({});
  const [formData, setFormData] = React.useState({
    amount: "",
    description: "",
    product: "",
  });
  const [formData2, setFormData2] = React.useState({
    id: "",
    amount: "",
    description: "",
    product: "",
  });
  const [searchString, setSearchString] = React.useState("");
  const [filteredProductList, setFilteredProductList] = React.useState([]);
  const handleChangeseacrch = (e) => {
    const input = e.target.value;
    setSearchString(input);
  };

  const GetMyServices = async () => {
    try {
      setLoading(true);
      const response = await myProfileServiceApi.ServiceProviderAllMyProduct();
      // console.log(response);
      if (response.data) {
        setLoading(false);
        setProductList(response.data.transaction_list);
      }
      // console.log(response.data.transaction_list);
    } catch (error) {
      notify("error", "Error fetching my products");
    }
  };

  React.useEffect(() => {
    GetMyServices();
    // allServicesubService();
  }, []);
  React.useEffect(() => {
    let searchTimeout;

    const GetMyfilterproducts = async () => {
      try {
        setLoading(true);
        const response = await myProfileServiceApi.GetmyProductsfilter(
          searchString
        );
        // console.log(response);
        if (response.data) {
          setLoading(false);
          setFilteredProductList(response.data.transaction_list);
        }
        // console.log(response.data.transaction_list);
      } catch (error) {
        notify("error", "Error fetching my products");
      }
      return () => {
        if (searchTimeout) {
          clearTimeout(searchTimeout);
        }
      };
    };

    searchTimeout = setTimeout(() => {
      if (searchString) {
        GetMyfilterproducts();
      }
    }, 500);

    // Clear the timeout when the component unmounts
    return () => {
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }
    };
  }, [searchString]);

  function goBack() {
    props.return();
    setInnerPage(false);
  }
  function handleChange(event) {
    console.log(event);
    const { name, value, type, checked } = event.target;
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: type === "checkbox" ? checked : value,
      };
    });
    console.log(formData);
  }

  function handleChange2(event) {
    console.log(event);
    const { name, value, type, checked } = event.target;
    setFormData2((prevFormData) => {
      return {
        ...prevFormData,
        [name]: type === "checkbox" ? checked : value,
      };
    });
    console.log(formData2);
  }

  const DeleteParticluarSerivce = async (trans_id) => {
    const response = await myProfileServiceApi.DeleteMyProduct(trans_id);
    console.log(response);
    if (response.data && response.data.error !== "") {
      notify("error", response.data.error);
    } else {
      notify("success", "Successfully Deleted The Service");
      GetMyServices();
    }
  };

  const AddParticularService = async () => {
    const response = await myProfileServiceApi.ServiceProviderAddMyProduct(
      Userprofile.service_provider_id,
      formData.amount,
      formData.description,
      formData.product
    );
    console.log(response);
    if (response.data && response.data.error !== "") {
      notify("error", response.data.error);
    } else {
      // If the response is successful, set formData to an empty object
      setFormData({ amount: "", description: "", product: "" });

      notify("success", "Successfully Added The Service");
      GetMyServices();
      setPage(1);
    }
  };

  const EditMyProductService = async () => {
    const response = await myProfileServiceApi.EditMyProduct(
      formData2.id,
      formData2.amount,
      formData2.description,
      formData2.product
    );
    console.log(response);
    if (response.data && response.data.error !== "") {
      notify("error", response.data.error);
    } else {
      notify("success", "Successfully Edited The Service");
      GetMyServices();
      setPage(1);
    }
  };

  React.useEffect(() => {
    setFormData2((prev) => {
      return {
        id: editId.id,
        amount: editId.amount,
        description: editId.description,
        product: editId.tagline,
      };
    });
  }, [editId]);

  return (
    <React.Fragment>
      <>
        {page === 1 && (
          <div className="manage-services-profile-service-main-page">
            <section className="manageProductActions">
              <div className="my-service-profile-ongoing-and-pending-online-back-my-product">
                <div className="manage-services-profile-service-main-page-main-box-before">
                  <img src={back} onClick={goBack} alt="" />
                  {productList && (
                    <>
                      Total <span>{productList.length}</span> Product(s)
                    </>
                  )}
                </div>
              </div>
              <div className="manage-services-profile-service-main-page-add-service">
                <button
                  onClick={() => {
                    setPage(2);
                  }}
                >
                  Add New Product
                </button>
              </div>
            </section>
            <div className="manageProductSearchBox">
              <input
                type="text"
                name="prod"
                placeholder="Search Your Products"
                value={searchString}
                onChange={handleChangeseacrch}
              />
            </div>

            <div className="manage-myproducts-profile-serivce-main-page-main-box">
              {loading ? (
                <>
                  <Loading />
                </>
              ) : searchString !== "" ? (
                filteredProductList.length > 0 ? (
                  filteredProductList.map((item) => (
                    <>
                    <div className="manage-myproducts-profile-serivce-main-page-main-box-card">
                      <section className="manage-myproducts-profile-details">
                        <div className="manage-myproducts-profile-serivce-main-page-main-box-card-amount">
                          Amount : &nbsp; <span> &#8377; {item.amount}</span>
                        </div>
                        <div className="manage-myproducts-profile-serivce-main-page-main-box-card-name">
                          Name :  &nbsp; <span>{item.tagline}</span>
                        </div>
                        <div className="manage-myproducts-profile-serivce-main-page-main-box-card-desc">
                          Description : &nbsp;
                          <span>{item.description}</span>
                        </div>
                      </section>
                      <div className="manage-myproducts-profile-serivce-main-page-main-box-card-icon">
                        <section className="manage-myproducts-buttons edit-button"     onClick={() => {
                              setEditId(item);
                              setPage(3);
                            }}>
                          edit
                          <BiSolidEditAlt
                            src={penciledit}
                        
                          />
                        </section>
                        <section className="manage-myproducts-buttons"                             onClick={() => DeleteParticluarSerivce(item.id)}>
                          delete
                          <MdDelete
                            src={dustbin}

                          />
                        </section>
                      </div>
                    </div>
                  </>
                  ))
                ) : (
                  <div className="no-products-message">
                    No products found for the search query.
                  </div>
                )
              ) : productList && productList.length > 0 ? (
                productList.map((item) => (
                  <>
                    <div className="manage-myproducts-profile-serivce-main-page-main-box-card">
                      <section className="manage-myproducts-profile-details">
                        <div className="manage-myproducts-profile-serivce-main-page-main-box-card-amount">
                          Amount : &nbsp; <span> &#8377; {item.amount}</span>
                        </div>
                        <div className="manage-myproducts-profile-serivce-main-page-main-box-card-name">
                          Name :  &nbsp; <span>{item.tagline}</span>
                        </div>
                        <div className="manage-myproducts-profile-serivce-main-page-main-box-card-desc">
                          Description : &nbsp;               <span>{item.description}</span>
           
                        </div>
                      </section>
                      <div className="manage-myproducts-profile-serivce-main-page-main-box-card-icon">
                      <section className="manage-myproducts-buttons edit-button"     onClick={() => {
                              setEditId(item);
                              setPage(3);
                            }}>
                          edit
                          <BiSolidEditAlt
                            src={penciledit}
                        
                          />
                        </section>
                        <section className="manage-myproducts-buttons"                             onClick={() => DeleteParticluarSerivce(item.id)}>
                          delete
                          <MdDelete
                            src={dustbin}

                          />
                        </section>
                      </div>
                    </div>
                  </>
                ))
              ) : (
                <div className="no-products-message">
                  No products to display.
                </div>
              )}
            </div>
          </div>
        )}
        {page === 2 && (
          <div className="manage-services-profile-service-main-page">
            <div className="profile-wallet-main-page-heading profile-wallet-main-page-heading-left">
              <b className="my-profile-wallet-page-transfer-top-back">
                <img
                  src={back}
                  onClick={() => {
                    setPage(1);
                  }}
                ></img>
              </b>
              <span>Add New Product</span>
            </div>
            <div className="manage-myproducts-profile-service-main-page-inputs">
              <div className="manage-myproducts-profile-service-main-page-inputs-1">
                <span>Amount</span>
                <input
                  type="number"
                  name="amount"
                  value={formData.amount}
                  onChange={handleChange}
                  placeholder={`Enter Amount`}
                ></input>
              </div>
              <div className="manage-myproducts-profile-service-main-page-inputs-1">
                <span>Product Name</span>
                <input
                  type="text"
                  name="product"
                  value={formData.product}
                  onChange={handleChange}
                  placeholder="Enter Product Name"
                ></input>
              </div>
              <div className="manage-myproducts-profile-service-main-page-inputs-1">
                <span>Description</span>
                <input
                  type="text"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  placeholder="Enter Description"
                ></input>
              </div>
            </div>
            <div className="manage-myproducts-profile-service-main-page-inputs-submit">
              <button onClick={AddParticularService}>Submit</button>
            </div>
          </div>
        )}
        {page === 3 && (
          <div className="manage-services-profile-service-main-page">
            <div className="profile-wallet-main-page-heading profile-wallet-main-page-heading-left">
              <b className="my-profile-wallet-page-transfer-top-back">
                <img
                  src={back}
                  onClick={() => {
                    setPage(1);
                  }}
                ></img>
              </b>
              <span>Add New Product</span>
            </div>
            <div className="manage-myproducts-profile-service-main-page-inputs">
              <div className="manage-myproducts-profile-service-main-page-inputs-1">
                <span>Amount</span>
                <input
                  type="number"
                  name="amount"
                  value={formData2.amount}
                  onChange={handleChange2}
                  placeholder={`Enter Amount`}
                ></input>
              </div>
              <div className="manage-myproducts-profile-service-main-page-inputs-1">
                <span>Product Name</span>
                <input
                  type="text"
                  name="product"
                  value={formData2.product}
                  onChange={handleChange2}
                  placeholder="Enter Product Name"
                ></input>
              </div>
              <div className="manage-myproducts-profile-service-main-page-inputs-1">
                <span>Description</span>
                <input
                  type="text"
                  name="description"
                  value={formData2.description}
                  onChange={handleChange2}
                  placeholder="Enter Description"
                ></input>
              </div>
            </div>
            <div className="manage-myproducts-profile-service-main-page-inputs-submit">
              <button onClick={EditMyProductService}>Submit</button>
            </div>
          </div>
        )}
      </>
    </React.Fragment>
  );
}
