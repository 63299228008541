import axios from 'axios';
//company
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

class PutatoeServiceApi {
  PopularSellerAndProductData(index) {
    return axios.get(API_BASE_URL + '/v1/api/popular_by_service/' + index, {
      headers: {
        authtoken: localStorage.getItem('authtoken'),
      },
    });
  }
  PopularServiceData(index) {
    return axios.get(API_BASE_URL + '/v1/api/service/' + index, {
      headers: {
        authtoken: localStorage.getItem('authtoken'),
      },
    });
  }

  PopularSUBSellerAndProductData(index,service_id) {
    return axios.post(API_BASE_URL + '/v1/api/ProductAccordingToSubServiceFilter_withcolor/0', { sub_service_id:index,service_id,sub_service_withoutindex:1},{
      headers: {
        authtoken: localStorage.getItem('authtoken'),
      },
    });
  }
  PopularSUBServiceData(index) {
    return axios.get(
      API_BASE_URL + '/v1/api/individual_subservice_providers/' + index,
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }

  // PopularSUBServiceproducts(index) {
  //   return axios.get(API_BASE_URL + '/v1/api/popularproductByService_new_api/' + index, {
  //     headers: {
  //       authtoken: localStorage.getItem('authtoken'),
  //     },
  //   });
  // }
  serviceCategory(index){
    return axios.post(API_BASE_URL + '/v1/api/serviceCategory_new',{ service_id:index}, {
      headers: {
        authtoken: localStorage.getItem('authtoken'),
      },
    });
  }
  ServiceProviderAllProduct(sub_service_id,service_provider_id,index){
    const data = {
      service_provider_id,
    };
    if (sub_service_id!=="" && sub_service_id!==null) {
      data.sub_service_id = sub_service_id;
    }
  
    return axios.post(API_BASE_URL + '/v1/api/ServiceProviderAllProducts_by_sub_service/'+index,data, {
      headers: {
        authtoken: localStorage.getItem('authtoken'),
      },
    });
  }
  

  // AllPromotion(index) {
  //   return axios.get(API_BASE_URL + `/v1/api/displayPost/${index}`, {
  //     headers: {
  //       authtoken: localStorage.getItem('authtoken'),
  //       'Content-Type': 'application/json',
  //     },
  //   });
  // }
  // DeletePost(is_comment, id) {
  //   return axios.post(
  //     API_BASE_URL + '/v1/api/deletePost', //0 for post //1 for comment
  //     { is_comment, id },
  //     {
  //       headers: {
  //         authtoken: localStorage.getItem('authtoken'),
  //       },
  //     }
  //   );
  // }
}

export default new PutatoeServiceApi();
