import './loading.css';
export default function Loading({stylechange="no",text="LOADING MORE" , customStyle}) {
    return (
        <>
            <div className="loading" id='loading' stylechange={stylechange} style={customStyle}>
                <div className='obj'></div>
                <div className='obj'></div>
                <div className='obj'></div>
                <div className='obj'></div>
                <div className='obj'></div>
                <div className='obj'></div>
                <div className='obj'></div>
                <div className='obj'></div>
                <div className='obje'>{text}</div>
                <div className='obj'></div>
                <div className='obj'></div>
                <div className='obj'></div> 
                <div className='obj'></div>
                <div className='obj'></div>
                <div className='obj'></div>
                <div className='obj'></div>
                <div className='obj'></div>
            </div>
            <div className="mobile-loader" stylechange={stylechange}>
                <div className="loader"></div>
            </div>
        </>

    )
}