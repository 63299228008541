import axios from 'axios';
//company
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

class FollowListApi {
    ShowFollowing() {
        return axios.get(API_BASE_URL + '/v1/api/fav_list', {
          headers: {
            authtoken: localStorage.getItem('authtoken'),
          },
        });
      }


    ShowMyFollowers() {
        return axios.get(API_BASE_URL + '/v1/api/myfollowers', {
          headers: {
            authtoken: localStorage.getItem('authtoken'),
          },
        });
      }
}

export default new FollowListApi();
