import React, { Component } from "react";
import cartapi from "../api/cartapi";
import "./cart-css/cartViewPage2.css";
import CartItem from "./CartItems";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CouponImg from "../images/coupon-tag.svg";
import CouponModal from "./CouponModal";
import AddressModal from "./AddressModal";
import AddNewAddress from "./AddNewAddresss";
import paymentApi from "../api/paymentApi";
import { useGlobalContext } from "../context";
import notify from "../error";
import EditMyAddress from "./EditAddress";
import profileapi from "../api/profileapi";
import sadlogo from "../images/sad-logo.png"
import logo from "../images/logo.png";
import api from "../api/api";
import mobileApi from "../api/mobileApi";
import { AiOutlineArrowLeft } from "react-icons/ai";

export default function CartViewPageRecharge() {
  const { loginDetail, Userprofile, setUserProfile,setPage,externalRedirect,setExternalRedirect } = useGlobalContext();
  const [isRechargeModalOpen, setRechargeModalOpen] = React.useState(false);
  const [requiredAmount, setRequiredAmount] = React.useState(0);
  const [changes, setChanges] = React.useState(false);
  const [cart, setCart] = React.useState();
  const [isPaymentProcessing, setIsPaymentProcessing] = React.useState(false);
  const [EditAddress, setEditAddress] = React.useState("");
  const [ADDNewAddress, setADDNewAddress] = React.useState(false);
  const [showCouponModal, setShowCouponModal] = React.useState(false);
  const [showAddressModal, setShowAddressModal] = React.useState(false);
  const [selectedAddress, setSelectedAddress] = React.useState(
    "Gorakhnath Mandir, Gorakhpur, Uttar Pradesh, 273015, India"
  );
  const [couponResponse, setCouponResponse] = React.useState(null);
  const [DeliveryDay, setDeliveryDay] = React.useState(1);
  const [DeliveryTime, setDeliveryTime] = React.useState(1);
  const [PaymentMode, setPaymentMode] = React.useState("");
  const [DeliveryLocation, setDeliveryLocation] = React.useState(
    "Gorakhnath Mandir, Gorakhpur, Uttar Pradesh, 273015, India"
  );
  const CouponModalChangeStatus = () => {
    setShowCouponModal(!showCouponModal);
  };
  const ChangeAddress = () => {
    setShowAddressModal(!showAddressModal);
  };
  const ChangeNewAddress = () => {
    setADDNewAddress(!ADDNewAddress);
  };
  const MySelectedAddress = (e) => {
    console.log(e);
    if (DeliveryLocation) {
      setSelectedAddress(DeliveryLocation[e]);
    }
  };
  const ChangeDeliveryDay = (index) => {
    console.log(index);
    setDeliveryDay(index);
  };
  const ChangeDeliveryTime = (index) => {
    console.log(index);
    setDeliveryTime(index);
  };

  // const PaymentModeType=(e)=>
  // {
  //  console.log('touched')
  //  console.log(e.target.value)
  //  setPaymentMode(e.target.value);
  // }
  function ChangeChanges(operation) {
    setChanges(!changes);
    console.log("changes"); //pass in child
    if (operation) {
      if (Number.isInteger(operation)) {
        console.log("operation for counter");
      } else if (isNaN(operation)) {
        console.log("delete operation");
      }
    }
  }
  // const cartDetail = async () => {
  //   const response = await cartapi.ShowCart();
  //   console.log(response);
  //   setCart(response.data);
  // };
  // const [daySlot, setDaySlot] = React.useState([]);
  // const [timeSlot, setTimeSlot] = React.useState([]);
  // const BillingDetails = async () => {
  //   const response = await cartapi.BillingPage();
  //   console.log(response);
  //   setDaySlot(response.data.day_slot);
  //   setTimeSlot(response.data.time_slots);
  //   setDeliveryLocation(response.data.address_list);
  //   setSelectedAddress(response.data.address_list[0]);
  // };
  // const cartDetail = async () => {
  //   const response = await cartapi.ShowCart();
  //   console.log(response);
  //   setCart(response.data);
  // };
  // React.useEffect(() => {
  //   cartDetail();
  //   console.log('cartdetail called after edit');
  // }, [changes]);
  // React.useEffect(() => {
  //   cartDetail();
  //   BillingDetails();
  //   console.log('cartdetail called in starting');
  // }, []);

  // React.useEffect(() => {
  //   BillingDetails();
  //   console.log('cartdetail called in starting');
  // }, [ADDNewAddress, EditAddress]);

  // const arr = [
  //   'Within 24 Hours',
  //   'Monday',
  //   'Tuesday',
  //   'Wednusday',
  //   'Thusday',
  //   'Friday',
  //   'Saturday',
  //   'Sunday',
  // ];
  // const arr2 = [
  //   'Anytime',
  //   '7 AM - 9 AM',
  //   '9 AM - 11 AM',
  //   '11 AM - 1 PM ',
  //   '1 PM - 3 PM',
  //   '3 PM - 5 PM',
  //   '5 PM - 7 PM',
  //   '7 PM - 9 PM',
  // ];

  const closeModal = () => {
    setRechargeModalOpen(false);

  };
  const callmoneywallet = () => {
   setExternalRedirect(true);
    navigate(`/myprofile`);
    setPage('13');
    closeModal();
  }

  function handleChange(event) {
    // console.log('reac')
    const { name, value, type, checked } = event.target;
    // console.log(value)
    setPaymentMode(value);
  }

  ////////////payments

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  // async function displayRazorpay() {
  //   const res = await loadScript(
  //     'https://checkout.razorpay.com/v1/checkout.js'
  //   );

  //   if (!res) {
  //     notify('error','Razorpay SDK failed to load. Are you online?');
  //     return;
  //   }

  //   // const result = await axios.post('http://localhost:5000/payment/orders');

  //   if (!result) {
  //     notify('error','Server error. Are you online?');
  //     return;
  //   }

  //   const { amount, id: order_id, currency } = result.data;

  //   const options = {
  //     key: 'rzp_test_r6FiJfddJh76SI', // Enter the Key ID generated from the Dashboard
  //     amount: amount.toString(),
  //     currency: currency,
  //     name: 'Soumya Corp.',
  //     description: 'Test Transaction',
  //     // image: { logo },
  //     order_id: order_id,
  //     handler: async function (response) {
  //       const data = {
  //         orderCreationId: order_id,
  //         razorpayPaymentId: response.razorpay_payment_id,
  //         razorpayOrderId: response.razorpay_order_id,
  //         razorpaySignature: response.razorpay_signature,
  //       };

  //       // const result = await axios.post(
  //       //   'http://localhost:5000/payment/success',
  //       //   data
  //       // );

  //       alert(result.data.msg);
  //     },
  //     prefill: {
  //       name: 'Soumya Dey',
  //       email: 'SoumyaDey@example.com',
  //       contact: '9999999999',
  //     },
  //     notes: {
  //       address: 'Soumya Dey Corporate Office',
  //     },
  //     theme: {
  //       color: '#61dafb',
  //     },
  //   };

  //   const paymentObject = new window.Razorpay(options);
  //   paymentObject.open();
  // }

  const navigate = useNavigate();
  const moveToConfirmationPage = (id) => {
    navigate(`./order/${id}`);
  };
  const movetorecharge = () => {
    navigate(`/mobile-recharge`);
  };
  const payNowRespone = () => { };

  const PlaceOrderFunction = async () => {
    // checkout.js 890line
    setIsPaymentProcessing(true);
    if (!Userprofile) {
      notify("error", "Please Login To Continue");
      return;
    }
    console.log(PaymentMode);
    if (
      PaymentMode === undefined ||
      PaymentMode === "" ||
      PaymentMode === null
    ) {
      notify("warn", "Please select a payment mode");
    } else if (selectedAddress === null || selectedAddress === "") {
      notify("warn", "Please select an address");
    }
    if (PaymentMode === "cod") {
      // may need to use parseInt()
      // all start 3 are to be id's
      const response = await paymentApi.CashOnDelivery(
        DeliveryDay,
        DeliveryTime,
        selectedAddress.id,
        null
      );
      console.log(response);
      var data = response.data;
      console.log(data);
      if (data.error !== "" || data.status === false) {
        console.log(true);
        notify("warn", data.error);
      } else if (data["orderId"] !== null) {
        var id = data["orderId"];
        notify("success", "Order Placed Successfully!");
        // document.location.href = 'OrderConfirmed.html?id=' + id;
        moveToConfirmationPage(id);
      } else {
        notify("error", "Order Failed!");
      }
    } else if (PaymentMode === "paynow") {
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );

      if (!res) {
        notify("error", "Razorpay SDK failed to load. Are you online?");
        return;
      }

      const response = await mobileApi.RazorRecharge(
        location?.state?.op_code,
        location?.state?.serial_no,
        location?.state?.amount,
        location?.state?.circle,
        null
      );
      console.log(response);
      let data = response.data;
      if (data.error !== "") {
        // console.log()
        notify("warn", data.error);
      } else if (data.rpay_response.status === "created") {
        var options = {
          key: data["rpay_response"]["key_id"],
          amount: data["rpay_response"]["amount"],
          currency: data["rpay_response"]["currency"],
          name: "PUTATOE SOLUTIONS PVT. LTD.",
          description: "Pay For Your Order",
          image: "https://i.ibb.co/ctGstkw/logo.png",
          order_id: data["rpay_response"]["id"],
          handler: async function (response) {
            const data2 = {
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_order_id: response.razorpay_order_id,
              razorpay_signature: response.razorpay_signature,
              putatoe_order_id: data["rpay_response"]["putatoe_order_id"],
            };

            const response3 = await mobileApi.VerifyRechargeRazorPay(
              location?.state?.op_code,
              location?.state?.serial_no,
              location?.state?.amount,
              location?.state?.circle,
              null,
              data["rpay_response"]["putatoe_order_id"],
              response.razorpay_signature,
              response.razorpay_order_id,
              response.razorpay_payment_id
            );
            console.log(response3);
            let data3 = response3.data;
            if (data3["status"] == true) {
              notify("success", "Payment Successful!");
              //  document.location.href = 'OrderConfirmed.html?id=' + data['id'];
              moveToConfirmationPage(data["id"]);
            } else {
              notify("error", "Payment unsuccessful");
            }
          },
          prefill: {
            name: data["rpay_response"]["name"],
            email: data["rpay_response"]["email"],
            contact: data["user_phone"],
          },
          notes: {
            address: "Putatoe Technologies",
          },
          theme: {
            color: "#00838f",
          },
        };
        var rzp1 = new window.Razorpay(options);
        rzp1.on("payment.failed", function (response) {
          notify("error", "Payment failed due to banks issue");
        });
        rzp1.open();
      }
    } else if (PaymentMode === "wallet") {
      const response = await mobileApi.WalletRecharge(
        location?.state?.op_code,
        location?.state?.serial_no,
        location?.state?.amount,
        location?.state?.circle,
        null
      );
      console.log(response);
      var data = response.data;
      console.log(data);
      if (data.error !== "") {
        // If there is an error message in the data, show a warning notification.
        notify("error", data.error);
        if (data.error === "low balance in wallet") {
          setRechargeModalOpen(true);
          setRequiredAmount(Userprofile.wallet_balance ? -(Userprofile.wallet_balance - location?.state?.amount ): -(0 - location?.state?.amount));
          console.log(requiredAmount);
        }
      } else if (
        response.data.transaction_id &&
        response.data.transaction_id !== ""
      ) {
        // If there is a transaction ID in the response, show a success notification and move to the recharge page.
        notify("success", "Payment Successful!");
        movetorecharge();
      } else {
        // If none of the above conditions match, show an error notification.
        notify("error", "Payment Failed.");
        // Redirect or handle this condition as needed.
      }
    }
    setIsPaymentProcessing(false);
    // Call the profile API here
    const response2 = await api.ProfileApi();
    if (response2.data.error === "") {
      setUserProfile(response2.data);
      localStorage.setItem("UserProfile", JSON.stringify(response2.data));
    }
  };

  const handlerechargeCouponApplied = (response) => {
    console.log("Coupon Applied Response:", response);
    setCouponResponse(response);
  };
  const removeCoupon = async () => {
    const response = await cartapi.removeCoupon();
    if (response.data.error === "") {
      console.log(response);
      setCouponResponse(null);
      notify("success", "Coupon Removed Successfully");
    } else {
      notify("error", response.data.error);
    }
  };
  const location = useLocation();
  console.log(location);
  const cartDetail = async () => {
    const response = await cartapi.ShowCart();
    console.log(response);
    setCart(response.data);
  };
  return (
    <React.Fragment>
      {showCouponModal && (
        <CouponModal
          show={showCouponModal}
          setShow={setShowCouponModal}
          cartDetail={cartDetail}
          isrechargepage={location?.pathname}
          rechargeamount={location?.state?.amount}
          onCouponApplied={handlerechargeCouponApplied}
        ></CouponModal>
      )}
      <div className="cart-main-page-2">
        <AiOutlineArrowLeft
          className="backBtn"
          onClick={() => { 
            movetorecharge();
            console.log(location)
         }}
        />
        <div className="cart-main-page-item-row-2">
          <div className="cart-main-page-item-row-2-recharge">
            <div className="cart-main-page-item-row-2-recharge-col1">
              <div>
                <img
                  src={location?.state?.op_image ? location?.state?.op_image : logo}
                  alt=""
                />
              </div>
            </div>
            <div className="cart-main-page-item-row-2-recharge-col2">
              <div>{location?.state?.text1}</div>
              <span>
                {location?.state?.text2 + "op_code " + location?.state?.op_code}
              </span>
              <section>Price &#8377;{location?.state?.amount}</section>
            </div>
          </div>
        </div>
        <div className="cart-main-page-amount-row-2">
          <div className="cart-main-page-amount-row-coupon-heading-2">
            COUPONS
          </div>
          <div className="cart-main-page-amount-row-coupon-2">
            <div>
              <img src={CouponImg}></img>
              <span>Apply Coupons</span>
            </div>
            {couponResponse == null ? (
              <button onClick={CouponModalChangeStatus}>Apply</button>
            ) : (
              <button onClick={removeCoupon}>Remove Coupon</button>
            )}
          </div>
          <div className="cart-main-page-amount-row-heading-2">
            PRICE DETAILS
          </div>
          <div className="cart-main-page-amount-row-bag-total-2">
            <div className="">Total Price</div>
            <span>&#8377;{location?.state?.amount}</span>
          </div>
          <div className="cart-main-page-amount-row-bag-discount-2">
            <div>Coupon CashBack</div>
            <span>
              -&#8377;
              {couponResponse && couponResponse.cashback_awarded
                ? couponResponse.cashback_awarded
                : 0}
            </span>
          </div>
          <div className="cart-main-page-amount-row-coupon-discount-2">
            <div>coupon Discount</div>
            <span>
              -&#8377;
              {couponResponse && couponResponse.discount_awarded
                ? couponResponse.discount_awarded
                : 0}
            </span>
          </div>
          <div className="cart-main-page-amount-row-final-price-2">
            <div>Total Amount</div>
            <span>
              &#8377;
              {couponResponse && couponResponse.amount_after_discount
                ? couponResponse.amount_after_discount
                : location?.state?.amount}
            </span>
          </div>

          <div className="cart-main-page-amount-row-heading-2">
            PAYMENT MODE
          </div>
          <div className="cart-main-page-amount-row-payment-mode-2">
            <form>
              <input
                id="payment-mode-input-id-is-online-payment-upi"
                type="radio"
                name="payment"
                value="payupi"
                onChange={handleChange}
              ></input>
              <label htmlFor="payment-mode-input-id-is-online-payment-upi">
                Pay through UPI
              </label>
              <br />

              {Userprofile ? (
                <>
                  <>
                    <input
                      id="payment-mode-input-id-is-wallet-payment"
                      type="radio"
                      name="payment"
                      value="wallet"
                      onChange={handleChange}
                    ></input>
                    <label htmlFor="payment-mode-input-id-is-wallet-payment">
                      Pay Using Wallet
                    </label>
                    <div className="payment-mode-is-wallet-payment-balance">
                      {" "}
                      Wallet balance ₹ {Userprofile.wallet_balance ? Userprofile.wallet_balance : 0}
                    </div>
                    <br></br>
                  </>
                </>
              ) : (
                <span></span>
              )}
            </form>
          </div>

          <div className="cart-main-page-amount-row-button-2">
            <button onClick={PlaceOrderFunction} disabled={isPaymentProcessing}>
              {isPaymentProcessing ? "Processing..." : "PAY"}
            </button>
          </div>
        </div>
        {
          isRechargeModalOpen && <InsufficientBalanceModal isOpen={isRechargeModalOpen} requiredAmount={requiredAmount} onclose={closeModal} onProceed={callmoneywallet} />
        }
      </div>
    </React.Fragment>
  );
}

const InsufficientBalanceModal = ({ isOpen, requiredAmount, onclose, onProceed }) => {
  console.log(requiredAmount);
  return (
    <div className={`insufficient-modal`}>
      <div className="insufficient-modal-content">
      <div className='insufficient-modal-content-button'>
          <button className="insufficient-modal-close-button" onClick={onclose}>
            X
          </button>
        </div>
        <div className="insufficient-model-content-header">
   
          <img src={sadlogo} alt="Wallet" />
          <h1>Insufficient Wallet Balance!!!</h1>
          <h4>Required amount ₹{requiredAmount}</h4>
        </div>
        <div className="insufficient-model-content-footer">
          <p>Do you want to add money to your Putatoe wallet and proceed with the recharge?</p>
          <button onClick={onProceed}>Proceed</button>
        </div>

      </div>
    </div>
  );
};