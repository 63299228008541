import React, { useEffect, useState } from 'react'
import postapi from '../../api/postapi';
import BlogCard from '../../components/blog-components/BlogCard';
import { useGlobalContext } from '../../context'
import './myblog.css';
import Singleblog from '../../components/blog-components/Singleblog';
import Createblog from '../../components/blog-components/Createblog';
import Loading from '../../components/Loading';


function Myblog() {
  // const [myownblog, setMyownblog] = useState();
  const [name, setName] = useState('');
  const [username, setUserName] = useState('');
  const [allBlogs, setAllBlogs] = useState([]);
  const [startIndex, setStartIndex] = useState(0);
  const [picture, setPicture] = useState('');
  const [isEmpty, setIsEmpty] = useState(false);
  const { modifyModal, blogInfo, loading, setLoading, blogUpdate, createBlogModal, editBlogData, postBlog,myownblog,setMyownblog,isMobileView } = useGlobalContext();

  const observer = React.useRef();
  const markerRef = React.useCallback(node => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        setStartIndex(prevStartIndex => prevStartIndex + 18);
      }
    });
    if (node) observer.current.observe(node);
  }, [setStartIndex]);


  const getMyBlogs = async (startIndex) => {
    setLoading(true);
    try {
      const res = await postapi.displayOwnBlog(startIndex);
      if (res) {
        setLoading(false);
        if (res.data.data.length === 0) {
          console.log("hello isEmpty")
          setIsEmpty(true);
        } else {
          setName(res.data.fullname);
          setUserName(res.data.username);
          setPicture(res.data.profile_image)
          // setMyownblog([...myownblog,res.data.data]);
          if(startIndex==0){
            setMyownblog(res.data.data);
          }
          else{
            setMyownblog([...myownblog,...res.data.data])
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    if(startIndex!=0 && !isEmpty){
      getMyBlogs(startIndex);
    }
  }, [startIndex]);

  useEffect(() => {
    if(startIndex ==0){
      getMyBlogs(0);
    }
  }, [blogUpdate, postBlog])

  return (
    <>
        { !isMobileView && <div className="myblog-blog-view">
            <div className="myblog-blog-page-heading">
              <span>My Blogs</span>
            </div>
            </div>
}
      {myownblog ?
        <div className='myblog-blog-container'>
          { isMobileView && <div className="myblog-blog-view">
            <div className="myblog-blog-page-heading">
              <span>My Blogs</span>
            </div>
            </div>
}
          {
            modifyModal &&
            <div className="blogOverlay">
              {createBlogModal && editBlogData ?
                <Createblog editData={editBlogData[0]} />
                :
                <Singleblog blogInfo={blogInfo} />
              }
            </div>
          }

          {myownblog.map((elm, index) => {
            return (
              <section key={index} className='MyBlogContainer MyBlogContainer1'>

                <BlogCard elm={elm} page={"myBlog"} name={name} picture={picture} username={username} />
              </section>
            )
          })
          }
          {myownblog.length > 0 && !isEmpty && <div className="marker" ref={markerRef} />}
          {loading && <div className="blogposts_loading"><Loading /></div>}
          {
            isEmpty &&  <div className="allfetched">"That's all we have"</div>
          }
        </div>
        : isEmpty ?
          <div className='EmptyBlogContainer'>No Blogs Yet...</div>
        : loading && <Loading />
      }
    </>
  )
}


export default Myblog
