import React, { Component } from "react";
import "./css/feedbackmainpageView.css";
import FeedBackapi from "../../api/FeedBackapi";
import notify from "../../error";
import iconnofeedbackgiven from "./img/noFeedbackGiven.png";
import iconnofeedbackrecieved from "./img/noFeedbackReceived.png";
import back from "../myprofileIcoons/bx-arrow-back..svg";
import FeedBackCard from "./FeedbackCard";
import { useGlobalContext } from "../../context";
import Jobapi from "../../api/Jobapi";

export default function FeedBackMainPageView() {
  const { setInnerPage } = useGlobalContext();
  const [typeOfFeedBack, setTypeOfFeedBack] = React.useState(1);
  const [FeedbackReceived, setFeedBackReceived] = React.useState([]);
  const [Feedbackgiven, setFeedBackgiven] = React.useState([]);
  const [formData, setFormData] = React.useState({
    name: "",
    message: "",
  });

  const ReceivedFeedBack = async () => {
    const response = await FeedBackapi.FeedBackReceived();
    if (response.data.error !== "") {
      notify("error", response.data.error);
    } else {
      // notify('success','here are response')
      setFeedBackReceived(response.data.messages);
    }
  };

  function ChangeTypeOfFeedback(i) {
    if (i === 3) {
      setInnerPage(true);
    }
    setTypeOfFeedBack(i);
  }

  const GivenFeedBack = async () => {
    const response = await FeedBackapi.FeedbackSend();
    if (response.data.error !== "") {
      notify("error", response.data.error);
    } else {
      // notify('success','here are response')
      setFeedBackgiven(response.data.my_messages);
    }
  };

  async function AgainGetResponse() {
    GivenFeedBack();
    ReceivedFeedBack();
  }
  React.useEffect(() => {
    GivenFeedBack();
    ReceivedFeedBack();
  }, []);
  const [isFeedbackReceived, setisFeedbackReceived] = React.useState(false);

  if (typeOfFeedBack === 1) {
    return (
      <React.Fragment>
        <>
          <div className="my-feedback-main-page-view">
            <div className="profile-wallet-main-page-heading">
              <span>FEEDBACK &nbsp; GIVEN</span>
            </div>
            <div className="my-feedback-main-page-type-buttons">
              <button
                style={
                  !isFeedbackReceived
                    ? { borderBottom: "3px solid #1D8B97" }
                    : {}
                }
                onClick={() => {
                  ChangeTypeOfFeedback(1);
                  setisFeedbackReceived(false);
                }}
              >
                FeedBack Given
              </button>
              <button
                style={
                  isFeedbackReceived
                    ? { borderBottom: "3px solid #1D8B97" }
                    : {}
                }
                onClick={() => {
                  ChangeTypeOfFeedback(2);
                  setisFeedbackReceived(true);
                }}
              >
                FeedBack Received
              </button>
              <button
                onClick={() => {
                  ChangeTypeOfFeedback(3);
                }}
              >
                Give Feedback
              </button>
            </div>
            <div className="my-feedback-main-page-data">
              {Feedbackgiven.length > 0 ? (
                Feedbackgiven.map((item) => {
                  return (
                    <FeedBackCard
                      data={item}
                      refresh={AgainGetResponse}
                      free="yes"
                    ></FeedBackCard>
                  );
                })
              ) : (
                <>
                  <div class="my-feedback-main-page-no-feedback-given">
                    <img src={iconnofeedbackgiven} alt="icon" />
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      </React.Fragment>
    );
  }
  if (typeOfFeedBack === 2) {
    return (
      <React.Fragment>
        <>
          <div className="my-feedback-main-page-view">
            <div className="profile-wallet-main-page-heading">
              <span>FEEDBACK &nbsp; RECEIVED</span>
            </div>
            <div className="my-feedback-main-page-type-buttons">
              <button
                style={
                  !isFeedbackReceived
                    ? { borderBottom: "3px solid #1D8B97" }
                    : {}
                }
                onClick={() => {
                  ChangeTypeOfFeedback(1);
                  setisFeedbackReceived(false);
                  ReceivedFeedBack()
                }}
              >
                FeedBack Given
              </button>
              <button
                style={
                  isFeedbackReceived
                    ? { borderBottom: "3px solid #1D8B97" }
                    : {}
                }
                onClick={() => {
                  ChangeTypeOfFeedback(2);
                  setisFeedbackReceived(true);
                }}
              >
                FeedBack Received
              </button>
              <button
                onClick={() => {
                  ChangeTypeOfFeedback(3);
                }}
              >
                Give Feedback
              </button>
            </div>
            <div className="my-feedback-main-page-data">
              {FeedbackReceived.length > 0 ? (
                FeedbackReceived.map((item) => {
                  return (
                    <FeedBackCard
                      data={item}
                      refresh={AgainGetResponse}
                      free="yes"
                    ></FeedBackCard>
                  );
                })
              ) : (
                <>
                  {" "}
                  <div class="my-feedback-main-page-no-feedback-received">
                    <img src={iconnofeedbackrecieved} alt="icon" />
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      </React.Fragment>
    );
  }

  function handleChange(event) {
    console.log(event);
    const { name, value, type, checked } = event.target;
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: type === "checkbox" ? checked : value,
      };
    });
    console.log(formData);
  }

  function containsOnlyNumbers(str) {
    return /^\d+$/.test(str);
  }

  const GiveMyFeedback = async () => {
    let response = "";

    if (formData.name.length === 0) {
      notify("error", "Phone number or username is required");
      return;
    }

    if (!formData.message) {
      notify("error", "Message is required");
      return;
    }

    if (containsOnlyNumbers(formData.name)) {
      if (formData.name.length !== 10) {
        notify("error", "Please provide a valid 10-digit phone number.");
        return;
      }

      // if phone number
      response = await FeedBackapi.SendFeedback(
        formData.message,
        null,
        parseInt(`+91${formData.name}`)
      );
    } else {
      if (formData.name.length < 3) {
        notify("error", "Username must be at least 3 characters long.");
        return;
      }

      // if username
      response = await FeedBackapi.SendFeedback(
        formData.message,
        formData.name,
        null
      );
    }

    // console.log(response);

    if (response.data.error !== "") {
      notify("error", response.data.error);
    } else {
      notify("success", "Successfully given feedback");
      AgainGetResponse();
      ChangeTypeOfFeedback(1);
      setFormData({});
    }
  };

  if (typeOfFeedBack === 3) {
    return (
      <React.Fragment>
        <div className="my-feedback-my-profile-add-feedback">
          <div className="my-profile-wallet-page-transfer-top-back">
            <img
              src={back}
              onClick={() => {
                ChangeTypeOfFeedback(1);
                setInnerPage(false);
              }}
              alt=""
            />
          </div>
          <div className="profile-wallet-main-page-heading">
            <span>ADD&nbsp; FEEDBACK</span>
          </div>

          <div className="my-feedback-my-profile-inputs">
            <div className="add-address-modal-coupon-enter-input">
              <span>USERNAME OR MOBILE NUMBER</span>
              <input
                type="text"
                value={formData.name}
                name="name"
                onChange={handleChange}
                placeholder="Username or Mobile Number"
                required
              ></input>
            </div>
            <div className="add-address-modal-coupon-enter-input">
              <span>MESSAGE</span>
              <textarea
                type="text"
                value={formData.message}
                name="message"
                onChange={handleChange}
                placeholder="Enter your Message here"
                required
              ></textarea>
            </div>
          </div>
          <div className="my-feedback-my-profile-inputs-submit">
            <button onClick={GiveMyFeedback}>SUBMIT</button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
