import React from "react";
import "../css/accountsetting.css";
import { IoCloseCircleSharp } from "react-icons/io5";
import { useGlobalContext } from "../../context";
import EnterOTP from "./EnterOTP";
import notify from "../../error";
import { useState } from "react";
import profileapi from "../../api/profileapi";

function FieldChange(props) {
  console.log("Entered Field Change",props);
  const {setIsNumberChange, sendOtp, setSendotp,} = useGlobalContext();
  const [mobNumber, setMobNumber] = useState('');

  const handleInputChange=(event)=>{
    setMobNumber(event.target.value);
  }

  async function handleOptSend() {
    if(mobNumber.length ===0){
      (notify("error", "Please fill in your Mobile Number")) ;
      return;
    }
    else if (mobNumber.length !== 10){
      (notify("error", "Invalid Mobile Number")) ;
      return;
    }
    let response;
if(props.purpose===
  "register seller"){
     response= await profileapi.validateMobileNumber(
      `+91${mobNumber}`
    );
  }
  else{
     response= await profileapi.changeMobileNumber(
      `+91${mobNumber}`
    );
  }

    console.log("THIS IS MOB RESPONSE");
    console.log(response);
    if (response && response.data && response.data.error !== "") {
      notify("error", response.data.error);
      
    } else {
      (setSendotp((prevState)=> !prevState));
    }
  }
  
  return (
    <div className="overlayTheModal">
      <section className="mycontainer">
        <IoCloseCircleSharp
          size={30}
          className="crossIcon"
          onClick={() => setIsNumberChange(false)}
        />
        <div className="headingOfFieldChange">{props.textValue}</div>
        <input
          name="mobileNumber"
          type="number"
          className="inputsty"
          placeholder="Enter Mobile Number"
          value={mobNumber}
          onChange={handleInputChange}
        ></input>

        <button
          className="buttonsty"
          onClick={handleOptSend}
        >
          SEND OTP
        </button>
      </section>
      {sendOtp && <EnterOTP newNumber={mobNumber} purpose={props.purpose} details={props.details} setDetails={props.setDetails} />}
    </div>
  );
}

export default FieldChange;
