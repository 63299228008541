import React, { Component } from 'react'
import './css/ViewRecharge.css'
import emptyoption from '../images/recharge1.svg';
export default function ViewRechargeDetail(props)
{

 const wrapper=React.useRef(null);
 OutsideAlerter(wrapper);
 function OutsideAlerter(ref) {
   React.useEffect(() => {
     function handleClickDetailOutside(event) {
       if (ref.current && !ref.current.contains(event.target)) {
         console.log(ref.current);
         props.close();
       }
     }
     document.addEventListener('mousedown', handleClickDetailOutside);
     return () => {
       document.removeEventListener('mousedown', handleClickDetailOutside);
     };
   }, [ref]);
 }


 return (
   <React.Fragment>
     <>
       <div className='mobile-recharge-plan-detail-model-background'>
         <div className='mobile-recharge-plan-detail-model' ref={wrapper}>
           <div className='mobile-recharge-plan-detail-model-top'>
             <div className='mobile-recharge-plan-detail-model-top-row'>
               <div className='mobile-recharge-plan-detail-model-bottom-col1'>
                 Plan Amount
               </div>
               <div className='mobile-recharge-plan-detail-model-bottom-col2'>
                 &#8377; {props.data.recharge_amount}
               </div>
             </div>
             <div className='mobile-recharge-plan-detail-model-top-row'>
               <div className='mobile-recharge-plan-detail-model-bottom-col1'>
                 Recharge TalkTime
               </div>
               <div className='mobile-recharge-plan-detail-model-bottom-col2'>
                 {props.data.recharge_talktime}
               </div>
             </div>
             <div className='mobile-recharge-plan-detail-model-top-row'>
               <div className='mobile-recharge-plan-detail-model-bottom-col1'>
                 Description
               </div>
               <div className='mobile-recharge-plan-detail-model-bottom-col2'>
                 {props.data.recharge_long_desc}
               </div>
             </div>
           </div>
           <div className='mobile-recharge-plan-detail-model-bottom'>
             <div className='mobile-recharge-plan-detail-model-bottom-col1'>
               Validity
             </div>
             <div className='mobile-recharge-plan-detail-model-bottom-col2a'>
               {props.data.recharge_validity}
             </div>
             <div className='mobile-recharge-plan-detail-model-bottom-col3'>
               <img src={emptyoption}></img>
             </div>
           </div>
         </div>
       </div>
     </>
   </React.Fragment>
 );
}