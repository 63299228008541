import React from "react";
import "../serviceprovider-css/selectionpanel.css";
import { useServiceContext } from "../serviceContext.js";
import CheckList from "./checkList";
import Slider from "@mui/material/Slider";
import Box from "@mui/material/Box";

export default function SelectionPanel(props) {
  const { priceData, setPriceData, priceDataTwo, setPriceDataTwo } =
    useServiceContext();
  const [productype, setProductype] = React.useState();
  const [brand, setBrand] = React.useState();
  const [value, setValue] = React.useState([0, 3700]);

  function valuetext(value) {
    return value;
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setPriceData([newValue[0], newValue[1]]);
  };

  const reversedColors = props.colorsdata.map(color => {
    return {
        "color_code": color.color_code,
        "color_name": color.color_name
    };
});
console.log(props.data.color,reversedColors);
  return (
    <React.Fragment key={"selection"}>
      <div className="selection-panel-container">
        <CheckList
          name="Product Type"
          items={props.data.producttype}
          sup={productype}
        />
        <CheckList name="Brands" items={props.data.brand} sup={brand} />
        <div className='checklist-container'>
        <div className="checklist-heading">Price</div>
       
       <div className="checklist-items-container"> <Box sx={{ width: 150,marginLeft:"13px" }}>
          <Slider
            getAriaLabel={() => "Temperature range"}
            value={value}
            onChange={handleChange}
            valueLabelDisplay="auto"
            min={0}
            max={10000}
            step={100}
            getAriaValueText={valuetext}
          />
        </Box>
        <section className="rangeData">
          <div>min: &#8377; {priceData[0]} </div>
          <div>max: &#8377; {priceData[1]} </div>
        </section>
        </div>
        </div>

        <CheckList name="Quantity" items={props.data.quantity} />
       { reversedColors.length>0 &&  <CheckList name="Colors" items={reversedColors} />}
      </div>
    </React.Fragment>
  );
}
