import React, { useState } from "react";
import "../css/accountsetting.css";
import { useGlobalContext } from "../../context";
import LoginSidebarForgotPassword from "../../components/login-sidebar-forgotpass";

import profileapi from "../../api/profileapi";
import user from "../../images/blogimages/user.png";
import AddressCards from "./addressCards";
import AddNewAddress from "./addNewAddress";
import ModifyAddress2 from "./modifyAddress";
import { useNavigate } from "react-router-dom";
import api from "../../api/api";
import postapi from "../../api/postapi";
import notify from "../../error";
import { IoMdArrowBack } from "react-icons/io";
import Loading from "../../components/Loading";
import { MdModeEditOutline } from "react-icons/md";
import { IconContext } from "react-icons";
import FieldChange from "./FieldChange.jsx";


export default function AccountSettings() {
  const {
    Userprofile,
    loginPage,
    mobileNumber,
    setMobileNumber,
    setLoginPage,
    setUserProfile,
    setLoading,
    loading,
    isNumberChange,
    setIsNumberChange,
    refreshPage,
    setRefreshPage

  } = useGlobalContext();
  //  console.log(Userprofile)

  console.log(Userprofile);
  const [page, setPage] = React.useState(1);
  const [showMenu, setShowMenu] = React.useState(true);
  const [change, setChange] = React.useState(false);
  const [usernameAvailable, setUsernameAvailable] = React.useState(false);
  const [AddressList, setAddressList] = React.useState([]);
  const [ModifyAddressList, setModifyAddressList] = React.useState();
  const [formDataPassword, setFormDataPassword] = React.useState({
    oldPassword: "",
    newPAssword: "",
    confirmPassword: "",
  });
  const [newMobNumber, setNewMobNumber] = React.useState("");
  const [formDataProfile, setFormDataProfile] = React.useState({
    firstName: Userprofile.first_name,
    lastName: Userprofile.last_name,
    emailId: Userprofile.email,
    mobileNumber: Userprofile.phone
      ? Userprofile.phone.substr(3)
      : Userprofile.phone,
    userName: Userprofile.username,
    gender: Userprofile.gender,
    dob: Userprofile.DOB ? Userprofile.DOB : ""
  });
  const [formDataProfile2, setFormDataProfile2] = React.useState({
    firstName: Userprofile.first_name,
    lastName: Userprofile.last_name,
    emailId: Userprofile.email,
    mobileNumber: Userprofile.phone
      ? Userprofile.phone.substr(3)
      : Userprofile.phone,
    userName: Userprofile.username,
    gender: Userprofile.gender,
  });
  const [hasInputChanged, setHasInputChanged] = React.useState(false);
  // Function to check username availability
  const checkUsernameAvailability = async (username) => {
    try {
      // Send a request to your API to check if the username is available
      const response = await profileapi.checkUsername(username);
      if (response && response.data && response.data.error !== "") {
        setUsernameAvailable(false);
      } else {
        setUsernameAvailable(true);
      }
    } catch (error) {
      console.error("Error checking username availability:", error);
    }
  };

  function handleChangePassword(event) {
    //  console.log(event);
    const { name, value, type, checked } = event.target;
    setFormDataPassword((prevFormData) => {
      return {
        ...prevFormData,
        [name]: type === "checkbox" ? checked : value,
      };
    });
  }

  function handleKeyPress(event) {
    const currentLength = event.target.value.length;
    const inputName = event.target.name;
    if (
      inputName === "mobileNumber" &&
      (currentLength >= 10 || (event.key && !/^[0-9]*$/.test(event.key)))
    ) {
      event.preventDefault();
    }

    if (
      inputName !== "mobileNumber" &&
      (currentLength >= 15 || (event.key && !/^[a-zA-Z]*$/.test(event.key)))
    ) {
      event.preventDefault();
    }
  }

  function handleChangeProfile(event) {
    setChange(true);
    console.log(formDataProfile);
    const { name, value, type, checked } = event.target;
    setFormDataProfile((prevFormData) => {
      return {
        ...prevFormData,
        [name]: type === "checkbox" ? checked : value,
      };
    });
    if (name === "userName" && value === Userprofile.username) {
      setUsernameAvailable("current");
    }
    if (name === "userName" && value !== Userprofile.username) {
      checkUsernameAvailability(value);
      setHasInputChanged(true);
    }
  }
  function validateMobileNumber(number) {
    const mobileRegex = /^[6-9]\d{9}$/;
    return mobileRegex.test(number);
  }

  async function changeProfile() {

    if (!change) {
      notify("warn", "No changes yet");
      return;
    }
    if (
      !formDataProfile.firstName ||
      formDataProfile.firstName.trim().length === 0
    ) {
      notify("error", "Please fill in your First Name");
      return;
    }

    if (
      !formDataProfile.lastName ||
      formDataProfile.lastName.trim().length === 0
    ) {
      notify("error", "Please fill in your Last Name");
      return;
    }

    if (
      !formDataProfile.userName ||
      formDataProfile.userName.trim().length === 0
    ) {
      notify("error", "Please fill in your User Name");
      return;
    }
    if (
      formDataProfile.userName.trim().length > 20
    ) {
      notify("error", "Username should be less than 20 Characters");
      return;
    }
    if (!formDataProfile.userName && usernameAvailable) {
      notify("error", "Please choose differnt User Name");
      return;
    }

    if (
      !formDataProfile.emailId ||
      formDataProfile.emailId.trim().length === 0
    ) {
      notify("error", "Please fill in your Email Address");
      return;
    }
    if (!formDataProfile.gender || formDataProfile.gender.trim().length === 0) {
      notify("error", "Please fill in your Gender");
      return;
    }
    if (hasInputChanged && formDataProfile.userName === Userprofile.username) {
      notify("warn", "already have this username");
    }

    if (formDataProfile.userName !== Userprofile.username && !usernameAvailable) {
      notify("error", "Input username is not available");
      return;
    }
    setLoading(true);
    const response = await profileapi.UpdateProfile(
      formDataProfile.firstName,
      formDataProfile.lastName,
      formDataProfile.gender,
      formDataProfile.userName,
      formDataProfile.emailId,
      formDataProfile.dob
    );
    console.log(response);
    if (response && response.data && response.data.error !== "") {
      notify("error", response.data.error);
    } else {
      getProfileAfterLogin();
    }
  }

  async function ChangePasswordApiCall() {
    if (formDataPassword.newPAssword.length > 10 || formDataPassword.confirmPassword.length > 10) {
      notify("warn", "Maximum 10 characters required");
      return;
    }
    if (formDataPassword.newPAssword !== formDataPassword.confirmPassword) {
      notify("warn", "Password doesn't match ");
      return;
    }
    if (formDataPassword.newPAssword.length < 8) {
      notify("warn", "Minimum 8 characters required");
      return;
    }
    const response = await profileapi.UpdatePassword(
      // formDataProfile.firstName,
      // formDataProfile.lastName,
      // formDataProfile.gender,
      // formDataProfile.mobileNumber,
      // formDataProfile.userName,
      // formDataProfile.emailId,
      formDataPassword.newPAssword
    );
    console.log(response);
    if (response && response.data && response.data.error != "") {
      notify("error", response.data.error);
    } else {
      getProfileAfterLoginPass();
    }
  }

  const getProfileAfterLoginPass = async () => {
    const response2 = await api.ProfileApi();
    console.log(response2);
    //  setAbc(response2.data);
    // localStorage.setItem("user",response2.data)
    if (response2.data.error === "") {
      setLoginPage(0);
      notify("success", "Password Updated Successfully");
    } else {
      notify("error", "Password Update Failed");
    }
    console.log(abc);
  };

  const [display, setDisplay] = React.useState("login-sidebar-container-none");

  const showLoginPage = () => {
    setDisplay("login-sidebar-container2");
    setLoginPage(2);
    console.log(display);
  };
  const showLoginPage2 = () => {
    console.log("fired");
    setDisplay("login-sidebar-container-none");
  };

  const [blockListuser, setBlockListuser] = React.useState([]);
  const [blockListprovider, setBlockListprovider] = React.useState([]);
  const [isblockList, setIsBlockList] = React.useState(true);
  const CallBlockList = async () => {
    setPage(4);
    const response = await profileapi.BlockList();
    console.log(response);
    let arr1 = response.data.blocked_serviceproviders;
    let arr2 = response.data.blocked_users;
    console.log(arr1, arr2);
    setBlockListprovider(arr1);
    setBlockListuser(arr2);
    console.log(arr2);
  };
  const unblockServiceProvider = async (ServiceProvider) => {
    const { id } = ServiceProvider;
    const response = await profileapi.unBlockProvider(id);
    if (response && response.data && response.data.error != "") {
      notify("error", response.data.error);
    } else {
      notify("success", "Service Provider Unblock Successfully");
      CallBlockList();
    }
  };

  const CancelAddNewAddress = () => {
    setPage(2);
    console.log(page);
  };
  const CallAddressList = async () => {
    //  setPage(4);
    const response = await profileapi.ShowAddress();
    console.log(response);
    setAddressList(response.data.all_address);
  };
  React.useState(() => {
    CallAddressList();
  }, []);

  const ModifyAddress = (data) => {
    console.log(data);
    console.log("recieved");
    setModifyAddressList(data);
    setPage(7);
  };

  //*****************************For Number Change****************************/
  const [showComponent, setShowComponent] = useState(false);
  const [numberChangeCompleted, setNumberChangeCompleted] = useState(false);

  console.log(showComponent);

  React.useEffect(() => {
    setLoading(false);
    console.log(refreshPage);

    if (refreshPage) {
      console.log("Use Effect" + refreshPage);
      console.log(JSON.stringify(refreshPage));
      localStorage.setItem("UserProfile", JSON.stringify(refreshPage));
      setUserProfile(refreshPage);
      setChange(true);
      // changeProfile();
      //setMobileNumber(`${formDataProfile.mobileNumber}`);
      console.log("Entered InternalChangePRofile1");

    }

    setFormDataProfile({
      firstName: Userprofile.first_name,
      lastName: Userprofile.last_name,
      emailId: Userprofile.email,
      mobileNumber: Userprofile.phone
        ? Userprofile.phone.substr(3)
        : Userprofile.phone,
      userName: Userprofile.username,
      gender: Userprofile.gender,
    })

  }, [refreshPage]);


  React.useEffect(() => {
    console.log("profile changed");
    setFormDataProfile({
      firstName: Userprofile.first_name,
      lastName: Userprofile.last_name,
      emailId: Userprofile.email,
      mobileNumber: Userprofile.phone
        ? Userprofile.phone.substr(3)
        : Userprofile.phone,
      userName: Userprofile.username,
      gender: Userprofile.gender,
    })
  }, [Userprofile]) // if userprofile change update formdata


  const [abc, setAbc] = React.useState();
  const getProfileAfterLogin = async () => {
    const response2 = await api.ProfileApi();
    console.log(response2);
    setAbc(response2.data);
    // localStorage.setItem("user",response2.data)
    if (response2.data.error === "") {
      //  setLoginPage(0);
      notify("success", "Profile Updated Successfully");
    } else {
      notify(
        "error",
        "Profile Update Failed , Please Logout and ReLogin For Proper Functioning of App"
      );
    }
    setLoading(false);
    console.log(abc);
  };
  React.useEffect(() => {
    setLoading(false);
    console.log(abc);

    if (abc) {
      console.log(abc);
      console.log(JSON.stringify(abc));
      localStorage.setItem("UserProfile", JSON.stringify(abc));
      setUserProfile(abc);
    }
  }, [abc]);

  const navigate = useNavigate();
  const fields = [
    "First Name",
    "Last Name",
    "Email Id",
    "Moblie Number",
    "UserName",
    "Gender",
  ];

  const fileInputRef = React.useRef(null);

  const handleLinkClick = () => {
    fileInputRef.current.click();
  };

  const handleFileInputChange = async (e) => {
    const file = e.target.files[0];
    try {
      setLoading(true);
      const uploadRes = await postapi.UploadImageOGC(file);
      if (uploadRes.data.error === "") {
        await profileapi.ChangeProfilePicture(uploadRes.data.url);
        // await profileapi.ChangeProfilePicture('');
      }
      const responseProfile = await api.ProfileApi();
      setUserProfile(responseProfile.data);
      localStorage.setItem("UserProfile", JSON.stringify(responseProfile.data));
      setLoading(false);
      notify("success", "Profile Photo Updated Successfully");
    } catch (err) {
      console.log(err);
    }
  };

  //const [IsNumberChange , setIsNumberChange] = useState(false)
  const handleNumberChange = () => {
    console.log("Initiate Number Change");
    setIsNumberChange((prevState) => !prevState);
  };

  return (
    <React.Fragment>
      {console.log("this is render check " + refreshPage)}
      {loading && (
        <section className="LoadingContainer">
          <Loading />
        </section>
      )}
      <div className="profile-account-setting-container">
        <div
          className={`profile-account-setting-container-left-parent-heading`}
        >
          Account Settings
        </div>
        <div
          className={`profile-account-setting-container-left profile-account-setting-container-leftanother ${showMenu ? "showLeftContainerMenu" : "hideLeftContainerMenu"
            }`}
        >
          <div className={`profile-account-setting-container-left-heading`}>
            Account Settings
          </div>
          <div
            className="profile-account-setting-container-left-1 profile-account-setting-container-left-secondclass"
            onClick={() => {
              setPage(1);
              setShowMenu(false);
              console.log(page);
            }}
          >
            <div
              className="profile-account-setting-container-left-1-unselected"
              style={{ visibility: page === 1 && "visible" }}
            ></div>
            <span>Profile Information</span>
          </div>
          {/* 2 */}
          <div
            className="profile-account-setting-container-left-1 profile-account-setting-container-left-secondclass"
            onClick={() => {
              setPage(2);
              setShowMenu(false);
              console.log(page);
            }}
          >
            <div
              className="profile-account-setting-container-left-1-unselected"
              style={{ visibility: page === 2 && "visible" }}
            ></div>
            <span>Manage Address</span>
          </div>
          {/* 3 */}
          <div
            className="profile-account-setting-container-left-1 profile-account-setting-container-left-secondclass"
            onClick={() => {
              setPage(3);
              setShowMenu(false);
              console.log(page);
            }}
          >
            <div
              className="profile-account-setting-container-left-1-unselected"
              style={{ visibility: page === 3 && "visible" }}
            ></div>
            <span>Change Password</span>
          </div>
          {/* 4 */}
          <div
            className="profile-account-setting-container-left-1 profile-account-setting-container-left-secondclass"
            onClick={CallBlockList}
          >
            <div
              className="profile-account-setting-container-left-1-unselected"
              style={{ visibility: page === 4 && "visible" }}
            ></div>
            <span>Block List</span>
          </div>
          {/* 5 */}
          <div
            className="profile-account-setting-container-left-1 profile-account-setting-container-left-secondclass"
            onClick={() => {
              setPage(5);
              setShowMenu(false);
              console.log(page);
            }}
          >
            <div
              className="profile-account-setting-container-left-1-unselected"
              style={{ visibility: page === 5 && "visible" }}
            ></div>
            <span>Help</span>
          </div>
          {/* 6 */}
        </div>
        {page && page === 1 && (
          <div
            className={`profile-account-setting-right-container ${showMenu ? "hideRightContainer" : "showRightContainer"
              }`}
          >
            {(isNumberChange) && <FieldChange textValue="Change Your Mobile Number" purpose="change number" details={newMobNumber} setDetails={setNewMobNumber} />}
            <IoMdArrowBack
              className="backButton"
              onClick={() => setShowMenu(true)}
            />
            <div className="profile-account-setting-profile-main">
              <div className="profile-account-setting-profile-main-image">
                <img src={Userprofile.profile_image || user} />
              </div>
              <div className="profile-account-setting-profile-main-names">
                <div>
                  {Userprofile.first_name} {Userprofile.last_name}
                </div>
                <a className="" onClick={handleLinkClick}>
                  Change Profile photo
                </a>
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleFileInputChange}
                  style={{ display: "none" }}
                />
              </div>
            </div>
            {/*  */}
            <div className="profile-account-setting-profile-details">
              {/*  */}
              <div className="profile-account-setting-profile-detials-box">
                <div className="profile-account-setting-profile-details-box-field">
                  {fields[0]}
                  <span style={{ color: "red" }}>*</span>
                </div>
                <div className="profile-account-setting-profile-detail-box-field-value">
                  <input
                    name="firstName"
                    type="text"
                    onChange={handleChangeProfile}
                    maxLength={15}
                    onKeyPress={handleKeyPress}
                    value={formDataProfile.firstName}
                  />
                </div>
              </div>
              {/*  */}

              <div className="profile-account-setting-profile-detials-box">
                <div className="profile-account-setting-profile-details-box-field">
                  {fields[1]}
                  <span style={{ color: "red" }}>*</span>
                </div>
                <div className="profile-account-setting-profile-detail-box-field-value">
                  <input
                    name="lastName"
                    type="text"
                    onChange={handleChangeProfile}
                    onKeyPress={handleKeyPress}
                    maxLength={15}
                    value={formDataProfile.lastName}
                  >
                    {/* {Userprofile.first_name} */}
                  </input>
                </div>
              </div>
              <div className="profile-account-setting-profile-detials-box">
                <div className="profile-account-setting-profile-details-box-field">
                  {fields[2]}
                </div>
                <div className="profile-account-setting-profile-detail-box-field-value">
                  <input
                    name="emailId"
                    type="text"
                    onChange={handleChangeProfile}
                    value={formDataProfile.emailId}
                  >
                    {/* {Userprofile.first_name} */}
                  </input>
                </div>
              </div>
              <div className="profile-account-setting-profile-detials-box">
                <div className="profile-account-setting-profile-details-box-field">
                  {fields[3]}
                  <span style={{ color: "red" }}>*</span>
                </div>
                <div className="profile-account-setting-profile-detail-box-field-value">
                  <input
                    name="mobileNumber"
                    type="number"
                    onChange={handleChangeProfile}
                    onKeyPress={handleKeyPress}
                    maxLength={10}
                    value={formDataProfile.mobileNumber}
                    readOnly
                  >
                    {/* {Userprofile.first_name} */}
                  </input>
                  <div>
                    <IconContext.Provider value={{ color: "#00838f", size: "1.5em", style: { marginRight: '15px' } }}>
                      <MdModeEditOutline onClick={handleNumberChange} />
                    </IconContext.Provider>

                  </div>
                </div>



              </div>
              <div className="profile-account-setting-profile-detials-box">
                <div className="profile-account-setting-profile-details-box-field">
                  {fields[4]}
                  <span style={{ color: "red" }}>*</span>
                </div>

                <div className="profile-account-setting-profile-detail-box-field-value">
                  <input
                    name="userName"
                    type="text"
                    onChange={handleChangeProfile}
                    value={formDataProfile.userName}
                  >
                    {/* {Userprofile.first_name} */}
                  </input>
                </div>
                {hasInputChanged && usernameAvailable !== null && (
                  <div className="availability-message">
                    {usernameAvailable === true
                      ? "Username is available!"
                      : usernameAvailable === "current"
                        ? "Username is current"
                        : "Username is not available"}
                  </div>
                )}
              </div>
              <div className="profile-account-setting-profile-detials-box">
                <div className="profile-account-setting-profile-details-box-field">
                  {fields[5]}
                </div>
                <div className="profile-account-setting-profile-detail-box-field-value">
                  <select
                    name="gender"
                    onChange={handleChangeProfile}
                    value={formDataProfile.gender}
                  >
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Prefer not to say">Prefer not to say</option>

                  </select>
                </div>

              </div>
              <div className="profile-account-setting-profile-detials-box">
                <div className="profile-account-setting-profile-details-box-field">

                  Date Of Birth:
                </div>
                <div className="profile-account-setting-profile-detail-box-field-value">
                  <input
                    type="date"
                    value={formDataProfile.dob}
                    name="dob"
                    onChange={handleChangeProfile}

                  ></input>
                </div>
              </div>
            </div>
            <div className="profile-account-setting-profile-save">
              <button onClick={changeProfile}>Save Changes</button>
            </div>
          </div>
        )}

        {page && page === 2 && (
          <>
            <div
              className={`profile-account-setting-right-container ${showMenu ? "hideRightContainer" : "showRightContainer"
                }`}
            >
              <IoMdArrowBack
                className="backButton"
                onClick={() => setShowMenu(true)}
              />
              <div className="profile-account-setting-right-container-address-heading">
                Manage Address
              </div>
              <div
                className="profile-account-setting-right-container-address-new"
                onClick={() => {
                  setPage(6);
                  console.log(page);
                }}
              >
                <div>+</div>
                <span>Add a new Address</span>
              </div>
              {/* list item */}
              <div className="profile-account-setting-right-container-address-list">
                {/* <AddressCards /> */}
                {/* list item */}
                {AddressList &&
                  AddressList.map((item) => {
                    return (
                      <>
                        <AddressCards
                          data={item}
                          other={ModifyAddress}
                          reload={CallAddressList}
                        />
                      </>
                    );
                    console.log(item);
                  })}
                {/* <div className='profile-account-setting-right-container-address-list-item'>
                 <div className='profile-account-setting-right-container-address-list-item-dot'>
                   <div></div>
                   <div></div>
                   <div></div>
                 </div>
                 <div className='profile-account-setting-right-container-address-list-item-modal'>
                   <div>Edit</div>
                   <div>Delete</div>
                 </div>
                 <div className='profile-account-setting-right-container-address-list-item-top'>
                   <div>HOME</div>
                   <span>Jigmee w Sherpa</span>
                   <section>1212121212</section>
                 </div>
                 <div className='profile-account-setting-right-container-address-list-item-bottom'>
                   Hauns Building, Assmaly Enterprises,MG Market, Gangtok
                   ,Sikkim 727101
                 </div>
               </div> */}
              </div>
            </div>
          </>
        )}
        {page && page === 3 && (
          <>
            <div
              className={`profile-account-setting-right-container ${showMenu ? "hideRightContainer" : "showRightContainer"
                }`}
            >
              <IoMdArrowBack
                className="backButton"
                onClick={() => setShowMenu(true)}
              />
              <div className="profile-account-setting-profile-main-password">
                <div className="profile-account-setting-profile-main-image-password">
                  <img src={Userprofile.profile_image}></img>
                </div>
                <div className="profile-account-setting-profile-main-names-password">
                  <div>
                    {Userprofile.first_name} {Userprofile.last_name}
                  </div>
                </div>
              </div>
              <div className="profile-account-setting-password-change-box">
                <div className="profile-account-setting-password-change-box-list">
                  <div>New password</div>
                  <input
                    type="text"
                    //  placeholder='First Name'
                    onChange={handleChangePassword}
                    name="newPAssword"
                    value={formDataPassword.newPAssword}
                  />
                </div>
                <div className="profile-account-setting-password-change-box-list">
                  <div>
                    Confirm New<br></br>password
                  </div>
                  <input
                    type="text"
                    //  placeholder='First Name'
                    onChange={handleChangePassword}
                    name="confirmPassword"
                    value={formDataPassword.confirmPassword}
                  />
                </div>
                <div className="profile-account-setting-password-change-button">
                  <button
                    onClick={() => {
                      ChangePasswordApiCall();
                    }}
                  >
                    Change Password
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
        {page && page === 4 && (
          <>
            <div
              className={`profile-account-setting-right-container ${showMenu ? "hideRightContainer" : "showRightContainer"
                }`}
            >
              <IoMdArrowBack
                className="backButton"
                onClick={() => setShowMenu(true)}
              />
              <div className="profile-account-setting-right-container-Block-heading">
                Block List{" "}
                <span>
                  {isblockList
                    ? blockListuser.length
                    : blockListprovider.length}
                </span>
              </div>
              <div className="profile-account-setting-right-container-Block-list-container">
                <div className="follow-list-wrapper-btns">
                  <button
                    className="follow-list-following-btn "
                    onClick={() => {
                      setIsBlockList(true);
                    }}
                  >
                    <b
                      style={
                        isblockList ? { borderBottom: "3px solid #1D8B97" } : {}
                      }
                      className="follow-list-btn-text-border-bottom"
                    >
                      Blocked Users
                    </b>
                  </button>
                  <button
                    className="follow-list-my-followers-btn"
                    onClick={() => {
                      setIsBlockList(false);
                    }}
                    style={{ borderRight: "none" }}
                  >
                    <b
                      style={
                        !isblockList
                          ? { borderBottom: "3px solid #1D8B97" }
                          : {}
                      }
                      className="follow-list-btn-text-border-bottom"
                    >
                      Blocked Service Providers
                    </b>
                  </button>
                </div>
                {isblockList ? (
                  blockListuser && blockListuser.length !== 0 ? (
                    blockListuser.map((item) => {
                      return (
                        <div className="profile-account-setting-right-container-Block-list-item">
                          <div>{item.name}</div>
                          <button>unblock</button>
                        </div>
                      );
                    })
                  ) : (
                    <div>No users blocked</div>
                  )
                ) : blockListprovider.length !== 0 ? (
                  blockListprovider.map((item) => (
                    <div
                      key={item.id}
                      className="profile-account-setting-right-container-Block-list-item"
                    >
                      <div>{item.name}</div>
                      <button onClick={() => unblockServiceProvider(item)}>
                        Unblock
                      </button>
                    </div>
                  ))
                ) : (
                  <div>No service providers blocked</div>
                )}
              </div>
            </div>
          </>
        )}
        {page && page === 5 && (
          <>
            <div
              className={`profile-account-setting-right-container ${showMenu ? "hideRightContainer" : "showRightContainer"
                }`}
            >
              <IoMdArrowBack
                className="backButton"
                onClick={() => setShowMenu(true)}
              />
              <div className="profile-account-setting-right-container-help-heading">
                Help
              </div>
              <div
                className="profile-account-setting-right-container-help-heading-sub"
                onClick={() => {
                  navigate("/contactus", { replace: true });
                }}
              >
                Help Center
              </div>
              <div
                className="profile-account-setting-right-container-help-heading-sub"
                onClick={() => {
                  navigate("/contactus", { replace: true });
                }}
              >
                Support Request
              </div>
            </div>
          </>
        )}
        {page && page === 6 && (
          <div
            className={`profile-account-setting-right-container ${showMenu ? "hideRightContainer" : "showRightContainer"
              }`}
          >
            <IoMdArrowBack
              className="backButton"
              onClick={() => setShowMenu(true)}
            />
            <AddNewAddress
              back={CancelAddNewAddress}
              reload={CallAddressList}
            />

          </div>
        )}
        {page && page === 7 && (
          <>
            <ModifyAddress2
              back={CancelAddNewAddress}
              reload={CallAddressList}
              data={ModifyAddressList}
            />
          </>
        )}
        {/* <div className='login-sidebar'>
         <LoginSidebarForgotPassword view={display}  />
       </div> */}
      </div>

    </React.Fragment>
  );

}
