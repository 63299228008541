import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import postapi from '../../api/postapi';
import { useGlobalContext } from '../../context';
import { FaUserCircle } from 'react-icons/fa';
import notify from '../../error';
import '../blog-css/blogCard.css';

// images and svgs imports
import more from '../../images/blogimages/more.svg';
import { AiOutlineLike } from 'react-icons/ai';
import { AiFillLike } from 'react-icons/ai';
import {FaRegComment} from 'react-icons/fa';
import {PiShareFatDuotone} from 'react-icons/pi';
import {sanitize} from 'dompurify';

import cmt from '../../images/blogimages/Comments.svg';
import share from '../../images/blogimages/shareImg.svg';
import photo from "../../images/post-photo.svg";
import mic from "../../images/post-mic.svg";
import { FaPaperPlane} from "react-icons/fa";




export default function BlogCard({ elm, page, name, picture, username }) {
  // console.log(name);

  const noImage = "https://images.unsplash.com/photo-1594322436404-5a0526db4d13?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1129&q=80";
  const [isOptionVisible, setIsOptionVisible] = useState(false);
  const [isseemoreclicked,setIsseemoreclick]=useState(false);
  const [liked, setLiked] = React.useState(elm.is_liked ? 1 : 0);
  const [blogCardComment, setBlogCardComment] = useState('');
  const { setModifyModal, Userprofile, setBlogInfo, setBlogUpdate, setCreateBlogModal, setEditBlogData,isMobileView } = useGlobalContext();


  const wrapper = React.useRef();
  const modalRef = React.useRef(null);


  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setIsOptionVisible(false);
    }
  };

  const postBlogCardComment = async (id) => {
    try {
      const res = await postapi.AddBlogComment(id, blogCardComment);
      if (res.status === 200) notify("success", "Comment added successfully...");
      setBlogUpdate(prevBlogUpdate => !prevBlogUpdate);
    } catch (err) {
      console.log(err);
    }
  }

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      setIsseemoreclick(false);
    };
  }, []);


  function ClickLike() {
    if (liked) {
      setLiked(0);
      HandleDisLike();
      return;
    }
    HandleLike();
    setLiked(1);
  }

  const HandleLike = async () => {
    console.log("liked");
    try {
      await postapi.setBlogLike(elm.id);
    } catch (err) {
      console.log(err);
    }
  };

  const HandleDisLike = async () => {
    console.log("disliked");
    try {
      await postapi.setBlogDislike(elm.id);
    } catch (err) {
      console.log(err);
    }
  };

  const enterKeyHandler = (event) => {
    if (event.key === "Enter") {
      if (!Userprofile) {
        notify('warn', "Please log in first...");
        setBlogCardComment("");
        event.target.blur();
        return;
      }
      event.target.blur();
      setBlogCardComment("");
      if(blogCardComment !== ""){
        postBlogCardComment(elm.id);
        return
      }
      else{
        notify("error","Comment Should Not Be Empty")
      }
    }
  }

  function truncateText(text, wordLimit) {
    // Create a temporary element to parse the HTML
    const tempElement = document.createElement('div');
    tempElement.innerHTML = text;

    // Extract text content and split it into words
    const contentText = tempElement.textContent || tempElement.innerText;
    const words = contentText.split(/\s+/); // Split by whitespace characters

    if (words.length > wordLimit) {
      const truncatedWords = words.slice(0, wordLimit);
      return `${truncatedWords.join(' ')}`;
    }
    return contentText;
  }

  const truncatedHeadline = truncateText(elm.hadline, 5);
  const truncatedDetail = truncateText(elm.detail, 3);
  console.log(truncatedDetail);

  const handleShareClick = async (blogId, bodyData) => {
    if (!Userprofile) {
      notify('warn', "Please log in first...");
      return;
    }
    try {
      const res = await postapi.shareBlog(blogId, bodyData);
      if (res.data.error === '') {
        notify("success", "Successfully shared Blog...");
        setBlogUpdate(prevBlogUpdate => !prevBlogUpdate);
      }
    } catch (err) {
      notify("err", err.message);
    }
  }

  function handleBlogClick(id, img) {
    setModifyModal(true);
    setBlogInfo([id, img]);
  }

  async function handleDeleteClick(id) {
    try {
      const response = await postapi.DeleteBlog(id);
      if (response.data.error === '') {
        notify("success", "Blog Deleted successfully...");
        setBlogUpdate(prevBlogUpdate => !prevBlogUpdate);
      }
    } catch (err) {
      notify("error", err.message);
    }
  }
  // headline,description,details,category_id,url
  function handleEditClick(elm) {
    setModifyModal(true);
    setCreateBlogModal(true);
    setEditBlogData([elm])
  }

  function checklength(elm){
    console.log(elm.detail.split(" "),elm.detail.split(" ").length);
    if((elm.detail.split(" ").length >  3)){
      return true;
    }
    else if((elm.detail.split("\n").length >  3)){
      return true;
    }
    else if((elm.detail.split("<br>").length >  3)){
      return true;
    }
    else if((elm.detail.length > 30)){
      return true;
    }
    else{
      return false;
    }
  }

  return (
    <div className="blogsofAll blogsofAll1">
      <div className="blogsofAllNav">
        <>
          <div className="blogsofAllProfile">
            {elm.profile_image ? (
              <img src={elm.profile_image} alt="" />
            ) : picture ?
              (
                <img src={picture} alt="" />
              )
              :
              (
                <FaUserCircle size={50} color='#00838f' />
              )}
          </div>

          <div className="blogsofAllUsername">
            <p>{elm.name || name || "Unknown"} </p>
            <p>{elm.datetime}</p>
          </div>
        </>
        {Userprofile &&
          <div className='post-card-top-row-dots' onClick={() => setIsOptionVisible(!isOptionVisible)} ref={modalRef}>
            <img className='moreIcon' src={more} alt='more_icon' />
            <section className={`post-card-top-row-dots-option-no ${isOptionVisible ? 'post-card-top-row-dots-option' : ''}`} ref={wrapper}>
              {Userprofile && (elm.username === Userprofile.username || username === Userprofile.username) ?
                <>
                  <span onClick={() => handleEditClick(elm)}>Edit</span>
                  <span className='delete' onClick={() => handleDeleteClick(elm.id)}>Delete</span>
                </> :
                <><span>Block</span></>
              }

            </section>
          </div>
        }
      </div>
      <div className='blogDetailContainer' onClick={() => 
      {
        if(!isMobileView){
        handleBlogClick(elm.id, elm.profile_image)
      }
      }
        }>
        <div className="blogTitle">
          <p>{truncatedHeadline}</p>
        </div>
        <div className="perticulerBlogContent">
          <p className='particularBlogDescription'>
          {!isseemoreclicked &&  <>{truncatedDetail} {!isMobileView && checklength(elm) && <>{`...`}</>}</>}
          
           {isMobileView && !isseemoreclicked && checklength(elm) && <span className='seemore' onClick={()=>{
            setIsseemoreclick(true);
           }}>{`...`}See More</span>
           }

           {isseemoreclicked && 
           <>
           <span dangerouslySetInnerHTML= {{ __html: sanitize(elm.detail)}}>
            </span>
            <span className='seemore' onClick={()=>{
              setIsseemoreclick(false);
            }}>
              
              {` ...`}See less
        </span>
            </>
            }
          </p>
        </div>
        {elm.image ? (
          <img src={elm.image} alt="blog_image" className='blogImage' onClick={() => 
            {
              if(isMobileView){
              handleBlogClick(elm.id, elm.profile_image)
            }
            }
              }/>
        ) :
          (
            <img src={noImage} alt="blog_image" className='blogImage' onClick={() => 
              {
                if(isMobileView){
                handleBlogClick(elm.id, elm.profile_image)
              }
              }
                }/>
          )}
      </div>

      <div className="blogCardInteractions">
        <div className="blogCardLikes">
          {
            elm.is_liked || liked ?
              <AiFillLike className='likeIcon' onClick={ClickLike} />
              :
              <AiOutlineLike className='likeIcon' onClick={ClickLike} />
          }
          <p>{elm.totalLikes + liked}</p>
        </div>
        <div className="BlogComments">
          <FaRegComment className='blogIcon' /><p>{elm.total_comments} </p>
        </div>
        <div className='BlogShare'
          onClick={(e) => handleShareClick(elm.id, elm.detail + '')}
        >
          <PiShareFatDuotone className='shareIcon'/>
          <p>{elm.share_count || 0}</p>
        </div>
      </div>

      <div className='singleBlogComment'>
        <div>
          {Userprofile ? (
            <img className='blogCommentUserProfileImg' src={Userprofile.profile_image} alt='blogUserProfileImg' />
          ) : picture ?
            (
              <img src={picture} alt="" className='blogCommentUserProfileImg' />
            )
            :
            (
              <FaUserCircle size={42} color='#00838f' className='blogCommentUserProfileImg' />
            )}
        </div>
        <input value={blogCardComment}
          placeholder='Write a comment...'
          type='text'
          onChange={(e) => setBlogCardComment(e.target.value)}
          onKeyDown={(e) => enterKeyHandler(e)}
        />
        {/* <div className="post-card-add-comment-image">
          <img src={mic}></img>
          <img src={photo}></img>
        </div> */}
        <FaPaperPlane className="sendIcon1" onClick={(event)=>{
          if (!Userprofile) {
            notify('warn', "Please log in first...");
            setBlogCardComment("");
            event.target.blur();
            return;
          }
          event.target.blur();
          setBlogCardComment("");
          if(blogCardComment !== ""){
            postBlogCardComment(elm.id);
            return
          }
          else{
            notify("error","Comment Should Not Be Empty")
          }
        }}/>
      </div>
    </div>
  )
}