import React, { useState } from "react";
import { PiShareFatFill } from "react-icons/pi";
import { AiOutlineLike } from "react-icons/ai";
import { FaUserCircle } from "react-icons/fa";
import { AiFillLike } from "react-icons/ai";
import profileImage from "../images/DTh/account.png";
import more from "../images/blogimages/more.svg";
import cmt from "../images/blogimages/Comments.svg";
import "../post-css/postCard.css";
import photo from "../images/post-photo.svg";
import mic from "../images/post-mic.svg";
import share from "../images/blogimages/shareImg.svg";
import { useGlobalContext } from "../context";
import { usePostContext } from "../postContext.js";
import postapi from "../api/postapi";
import notify from "../error";
import {sanitize} from 'dompurify';
import { FaPaperPlane} from "react-icons/fa";
export default function PostCard(props) {
  const {
    setImage4,
    setprevHead,
    setPostInfo,
    promotionModal,
    setCreatePostModal,
    postInfo,
    setPostUpdate,
    setEditPostData,
    editPostData,
    setloadingsingle,
    Loadingsingle
  } = usePostContext();

  const { onDelete } = props;
  const [display2, setDisplay2] = useState(false);
  const [isseemoreclicked,setIsseemoreclick]=useState(false);
  const modalRef = React.useRef(null);
  const [commentText, setCommentText] = useState("");
  const { Userprofile, setDisplay, setLoginPage, modifyModal, setModifyModal,isMobileView,setLoading } =
    useGlobalContext();

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setDisplay2(false);
    }
  };
  const noImage =
    "https://images.unsplash.com/photo-1594322436404-5a0526db4d13?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1129&q=80";

  const wrapper = React.useRef();
  function HandleClick() {
    wrapper.current.classList.toggle("post-card-top-row-dots-option");
  }

  function HandleModalClick(id, profileImg, isLiked) {
    setModifyModal(true);
    // setLoading(true);
    setCreatePostModal(false);
    setPostInfo([id, profileImg, isLiked]);
    setloadingsingle(true);
  }
  const [liked, setLiked] = React.useState(0);
  function ClickLike() {
    if (liked) {
      setLiked(0);
      HandleDisLike();
      return;
    }
    HandleLike();
    setLiked(1);
  }

  const postComment = async (postId, comment) => {
    try {
      const response = await postapi.CommentPost(postId, comment);
      if (response.status === 200) {
        notify("success", "Comment added succssfully...");
        setPostUpdate((prevState) => !prevState);
      }
    } catch (error) {
      notify("error", "Could not post comment...");
      console.log(error);
    }
  };

  function HandleSubmit(e) {
    if (!Userprofile && e.key === "Enter") {
      setDisplay("login-sidebar-container2");
      setLoginPage(1);
      notify("error", "Login First To Comment");
      return;
    }
    if (e.key === "Enter") {
      setCommentText('')
      postComment(props.data.id, commentText);
      setPostUpdate(true);
    }
  }

  const HandleLike = async () => {
    const response = await postapi.LikePost(props.data.id);
    console.log(response);
  };

  const HandleDisLike = async () => {
    const response = await postapi.DislikePost(props.data.id);
    console.log(response);
  };

  const HandleShare = async () => {
    const response = await postapi.SharePost(props.data.id);
    console.log(response);
  };

  function HandleEditClick() {
    setEditPostData([props.data]);
    setModifyModal(true);
    setCreatePostModal(true);
    console.log(editPostData)
    // console.log(props.data.id,props.data.image,props.data.detail)
  }

  function truncateText(text, wordLimit) {
    // Create a temporary element to parse the HTML
    const tempElement = document.createElement('div');
    tempElement.innerHTML = text;

    // Extract text content and split it into words
    const contentText = tempElement.textContent || tempElement.innerText;
    const words = contentText.split(/\s+/); // Split by whitespace characters

    if (words.length > wordLimit) {
      const truncatedWords = words.slice(0, wordLimit);
      return `${truncatedWords.join(' ')}`;
    }
    return contentText;
  }
  const truncatedHTML = truncateText(props.data.detail, 3);
  const Username_Post = Userprofile ? Userprofile.username : null;

  const HandleBlockPost = async () => {
    props.onBlock(props.data.userid);
  };

  function checklength(elm){
    console.log(elm,elm.detail.split(" "),elm.detail.split(" ").length);
    if((elm.detail.split(" ").length >  3)){
      console.log(1);
      return true;
    }
    else if((elm.detail.split("\n").length >  3)){
      console.log(2);
      return true;
    }
    else if((elm.detail.split("<br>").length >  3)){
      console.log(3);
      return true;
    }
    else if((elm.detail.length > 30)){
      console.log(4);
      return true;
    }
    else{
      console.log(5);
      return false;
    }
  }
  return (
    <div className="post-card-container blogsofAll1">
      <div className="post-card-top-row">
        <div className="post-card-top-row-details">
          {props.data.profile_image ? (
            <img src={props.data.profile_image || profileImage} alt="" />
          ) : (
            <FaUserCircle
              size={50}
              color="#00838f"
            />
          )}{" "}
        </div>
        <div className="post-card-top-row-info">
          <p>{props.data.name || "Unkown User"}</p>
          <p>{props.data.datetime}</p>
        </div>
        {Userprofile && (
          <div
            className="post-card-top-row-dots"
            style={{ cursor: "pointer" }}
            onClick={() => setDisplay2(!display2)}
            ref={modalRef}
          >
            <img className="moreIcon" src={more} alt="more_icon" />
            {/* {display2 && props.data.name === Username_Post && ( */}
            {display2 && props.data.name !== Username_Post &&(
              <section className="post-card-top-row-dots-option" ref={wrapper}>
                <span
                  onClick={() => {
                    HandleEditClick();
                  }}
                >
                  Edit
                </span>
                <div onClick={() => onDelete(props.data.id)}>Delete</div>
              </section>
            )}
            {/* {display2 && props.data.name !== Username_Post && (
              <section className="post-card-top-row-dots-option" ref={wrapper}>
                <div onClick={HandleBlockPost}>Block</div>
              </section>
            )} */}
          </div>
        )}
      </div>
      <div
        className="post-card-detail-container"
        onClick={() =>{
          if(!isMobileView){
            HandleModalClick(
              props.data.id,
              props.data.profile_image,
              props.data.is_liked
            )
            // setLoading(true);
          }
        }
        }
      >
        {props.data.detail ? (
          <div
            className="post-card-post-text"
            // dangerouslySetInnerHTML={{ __html: truncatedHTML }}
          >
            {!isseemoreclicked &&  <>{truncatedHTML} {!isMobileView && checklength(props.data) && <>{`...`}</>}</>}
          
          {isMobileView && !isseemoreclicked && checklength(props.data) && <span className='seemore' onClick={()=>{
           setIsseemoreclick(true);
          }}>{`...`}See More</span>
          }

          {isseemoreclicked && 
          <>
          <span dangerouslySetInnerHTML= {{ __html: sanitize(props.data.detail)}}>
           </span>
           <span className='seemore' onClick={()=>{
             setIsseemoreclick(false);
           }}>
             
             {` ...`}See less
       </span>
           </>
           }
          </div>
        ) : (
          <div
            className="post-card-post-text"
            dangerouslySetInnerHTML={{ __html: "No Title" }}
          />
        )}
        <div>
          <img
            src={props.data.image || noImage}
            className="post-card-post-image"
            alt=""
            onClick={() =>{
              if(isMobileView){
                HandleModalClick(
                  props.data.id,
                  props.data.profile_image,
                  props.data.is_liked
                )
                // setLoading(true);
              }
            }
            }
          />
        </div>
      </div>

      <div className="postCardInteractions">
        <div className="postCardLikes">
          {props.data.is_liked || liked ? (
            <AiFillLike className="likeIcon" onClick={ClickLike} />
          ) : (
            <AiOutlineLike className="likeIcon" onClick={ClickLike} />
          )}
          <p>{props.data.total_like + liked}</p>
        </div>
        <div className="postComments">
          <img src={cmt} alt="commentIcon" />
          <p>{props.data.comments.length} </p>
        </div>
        <div
          className="postShare"
          // onClick={(e) => handleShareClick(elm.id, elm.detail + "")}
        >
          <img src={share} alt="shareIcon" />
          <p>{0}</p>
        </div>
      </div>

      <div className="post-card-add-comment">
        {props.data.profile_image ? (
          <img src={props.data.profile_image || profileImage} alt="" />
        ) : (
          <FaUserCircle
            size={42}
            color="#00838f"
            className="postCommentUserProfileImg"
          />
        )}
        <input
          type="text"
          placeholder="Write a comment"
          value={commentText}
          onChange={(e) => setCommentText(e.target.value)}
          onKeyDown={HandleSubmit}
        />
        {/* <div className="post-card-add-comment-image">
          <img src={mic} alt="" />
          <img src={photo} alt="" />
        </div> */}
        <FaPaperPlane className="sendIcon1" onClick={(event)=>{
          // if (!Userprofile) {
          //   notify('warn', "Please log in first...");
          //   setBlogCardComment("");
          //   event.target.blur();
          //   return;
          // }
          // event.target.blur();
          // setBlogCardComment("");
          // if(blogCardComment !== ""){
          //   postBlogCardComment(elm.id);
          //   return
          // }
          // else{
          //   notify("error","Comment Should Not Be Empty")
          // }
          if (!Userprofile) {
            setDisplay("login-sidebar-container2");
            setLoginPage(1);
            notify("error", "Login First To Comment");
            return;
          }
          if(commentText !== ""){
            setCommentText('')
            postComment(props.data.id, commentText);
              setPostUpdate(true);
              return ;
          }
          else {
            notify("error","Comment Should Not Be Empty")
          }
        }}/>
      </div>
    </div>
  );
}
