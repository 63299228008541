import React from "react";
import '../css/notification.css';
import { useGlobalContext } from '../context';
import apiclient from "../api/apiclient";
import { RxCross2 } from "react-icons/rx";
import logo from '../images/logo.png';
import { FaBell } from "react-icons/fa"
import Loading from "../components/Loading";
import tick from '../images/tick.svg'
export default function Notification() {
  const { Userprofile, fetchunreadNotification, setNotification, notificationref } = useGlobalContext();

  // const [notification, setNotification] = React.useState();
  const [notificationData, setNotificationData] = React.useState([]);
  const [startIndex, setStartIndex] = React.useState(0);
  const [isEmpty, setIsEmpty] = React.useState(false);
  const [loading1, setLoading1] = React.useState(false);

  const fetchNotification = async (index) => {
    const response = await apiclient.ShowNotification(index);
    fetchunreadNotification();
    console.log(response);
    if (response) {
      setNotificationData([...notificationData, ...response.data.notification]);
    }
    if (response.data.notification.length == 0) {
      setIsEmpty(true);
    }
    setLoading1(false);
  };


  const observer = React.useRef();
  const markerRef = React.useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setStartIndex((prevStartIndex) => prevStartIndex + 18);
          setLoading1(true);
        }
      });
      if (node) observer.current.observe(node);
    },
    [setStartIndex]
  );


  React.useEffect(() => {
    setLoading1(true);
    fetchNotification(startIndex);
    // const arr = ['no notification to display', 'je', 'be ready'];
    // setNotificationData(arr);
    // console.log(typeof notificationData);
  }, [startIndex]);

  const DoMarkAsRead = () => {
    fetchNotification();
  }



  return (
    <React.Fragment>
      <div className='notification-container text-center p-3' ref={notificationref}>
        <div className='notification-container-header '>
          <div >
            <div>Notifications &nbsp; {notificationData && notificationData.length > 10 ? <span>10+</span> : <span>{notificationData.length}</span>}
            </div>
            <RxCross2 className="notification-close-button" onClick={() => {
              setNotification(false);
            }} />
          </div>

          {/* <section onClick={DoMarkAsRead}>Mark all as Read <img src={tick}></img></section> */}
        </div>
        {
          notificationData && notificationData.map((item) => {
            return (
              <>
                <div className='notification-container-message'>
                  {!item.isread ? (
                    <div className='notification-container-message-unread'>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className='notification-container-message-top'>
                    {item.image ? (
                      <img src={item.image} alt="Item Image" />
                    ) : (
                      <FaBell size={30} style={{ paddingTop: "10px" }} />
                    )}
                    <span>{item.message}</span>
                  </div>
                  <div className='notification-container-message-bottom'>
                    {item.date_time}
                  </div>
                </div>
              </>
            );
          })
        }
        {notificationData.length > 0 && !isEmpty && <span className="marker" ref={markerRef} />}
        {loading1 &&
          <span className="notification_loading mx-auto">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </span>}
        {
          // isEmpty &&  <span className="allfetched fulllyfetched">"That's all we have"</span>
        }
      </div>
    </React.Fragment>
  );
}