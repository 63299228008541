import React, { Component, useRef } from "react";
import { useGlobalContext } from "../../context";
import Loading from '../../components/Loading';
import myProfileServiceApi from "../../api/myProfileServiceApi";
import back from "../myprofileIcoons/bx-arrow-back..svg";
import notify from "../../error";
import "./css/OfflineOrder.css";
import { motion } from "framer-motion";
import PdfViewer from "./PdfViewer";
import { Link } from "react-router-dom";
export default function OfflineOrders(props) {
  const { Userprofile, isMobileView, setIsMobileView, loading, setLoading, setInnerPage, userServiceIDselected } = useGlobalContext();
  const date = new Date();
  const date2 = new Date();
  const reffe=useRef("");

  function subtract15Days(date2) {
    const newDate = new Date(date2);
    newDate.setDate(date2.getDate() - 15);
    return newDate;
  }
  const newDate = subtract15Days(date2);
  const [myOrders, setMyOrders] = React.useState();
  const [selectedOrderId, setSelectedOrderId] = React.useState(null);
  const [isDropdownVisible, setDropdownVisible] = React.useState(false);

  const handleStatusButtonClick = (orderId) => {
    console.log(orderId);
    setSelectedOrderId(orderId);

  };

  const handleModalClose = () => {
    setSelectedOrderId(null);
  };

  const handleCancelOrder = async (Id) => {
    console.log(Id);
  };

  const handleCompleteOrder = async (orderId) => {

  }
  React.useEffect(() => {
    GetAllMyOrders();
  }, []);

  //date inputs using calander
  const [formData, setFormData] = React.useState({
    to: `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(date.getDate()).padStart(2, "0")}`,
    from: `${newDate.getFullYear()}-${String(newDate.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(newDate.getDate()).padStart(2, "0")}`,
    editFrom: `${newDate.getFullYear()}-${String(
      newDate.getMonth() + 1
    ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
  });

  //date manupulation
  //how to play with dates
  function handleChange(event) {
    console.log(event);
    setMyOrders(
      {
        cancelled_orders:[],
        completed_orders:[],
        pending_orders:[],
      }
    );
    const { name, value, type, checked } = event.target;
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: type === "checkbox" ? checked : value,
      };
    });
    if (name === "editFrom") {
      console.log("here", value);
      const newDate = new Date();
      let now = new Date();
      now = getLastWeeksDate();

      function getLastWeeksDate() {
        const now = new Date();

        return new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
      }

      function getLast30Date() {
        const now = new Date();

        return new Date(now.getFullYear(), now.getMonth(), now.getDate() - 30);
      }
      function getLastmonthDate() {
        const now = new Date();

        return new Date(now.getFullYear(), now.getMonth() - 1, now.getDate());
      }
      function getLast6monthDate() {
        const now = new Date();

        return new Date(now.getFullYear(), now.getMonth() - 6, now.getDate());
      }
      function get2022Date() {
        const now = new Date();

        return new Date(now.getFullYear() - 1, 0, 1);
      }
      function get2021Date() {
        const now = new Date();

        return new Date(now.getFullYear() - 2, 0, 1);
      }
      function get2020Date() {
        const now = new Date();

        return new Date(now.getFullYear() - 3, 0, 1);
      }
      function get2019Date() {
        const now = new Date();

        return new Date(now.getFullYear() - 4, 0, 1);
      }
      function get2018Date() {
        const now = new Date();

        return new Date(now.getFullYear() - 5, 0, 1);
      }
      function get2017Date() {
        const now = new Date();

        return new Date(now.getFullYear() - 6, 0, 1);
      }
      if (value === "1") {
        console.log("here 1");
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            from: `${newDate.getFullYear()}-${String(
              newDate.getMonth() + 1
            ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
            to: `${newDate.getFullYear()}-${String(
              newDate.getMonth() + 1
            ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
          };
        });
      }

      if (value === "2") {
        console.log("here 2");

        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
              2,
              "0"
            )}-${String(now.getDate()).padStart(2, "0")}`,
            to: `${newDate.getFullYear()}-${String(
              newDate.getMonth() + 1
            ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
          };
        });
      }

      if (value === "3") {
        console.log("here 3");
        now = getLast30Date();
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
              2,
              "0"
            )}-${String(now.getDate()).padStart(2, "0")}`,
            to: `${newDate.getFullYear()}-${String(
              newDate.getMonth() + 1
            ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
          };
        });
      }
      if (value === "4") {
        console.log("here 4");
        now = getLast6monthDate();
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
              2,
              "0"
            )}-${String(now.getDate()).padStart(2, "0")}`,
            to: `${newDate.getFullYear()}-${String(
              newDate.getMonth() + 1
            ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
          };
        });
      }
      if (value === "5") {
        console.log("here 5");
        now = get2022Date();
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
              2,
              "0"
            )}-${String(now.getDate()).padStart(2, "0")}`,
            to: `${newDate.getFullYear()}-${String(
              newDate.getMonth() + 1
            ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
          };
        });
      }
      if (value === "6") {
        // console.log('here 5');
        now = get2021Date();
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
              2,
              "0"
            )}-${String(now.getDate()).padStart(2, "0")}`,
            to: `${newDate.getFullYear()}-${String(
              newDate.getMonth() + 1
            ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
          };
        });
      }
      if (value === "7") {
        // console.log('here 5');
        now = get2020Date();
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
              2,
              "0"
            )}-${String(now.getDate()).padStart(2, "0")}`,
            to: `${newDate.getFullYear()}-${String(
              newDate.getMonth() + 1
            ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
          };
        });
      }
      if (value === "8") {
        // console.log('here 5');
        now = get2019Date();
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
              2,
              "0"
            )}-${String(now.getDate()).padStart(2, "0")}`,
            to: `${newDate.getFullYear()}-${String(
              newDate.getMonth() + 1
            ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
          };
        });
      }
      if (value === "9") {
        console.log("here 5");
        now = get2018Date();
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
              2,
              "0"
            )}-${String(now.getDate()).padStart(2, "0")}`,
            to: `${newDate.getFullYear()}-${String(
              newDate.getMonth() + 1
            ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
          };
        });
      }

      if (value === "10") {
        // console.log('here 5');
        now = get2017Date();
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
              2,
              "0"
            )}-${String(now.getDate()).padStart(2, "0")}`,
            to: `${newDate.getFullYear()}-${String(
              newDate.getMonth() + 1
            ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
          };
        });
      }
    }
    console.log(formData);
  }

  async function GetAllMyOrders() {
    try {
      setLoading(true);
      const selectedServiceID = userServiceIDselected ? userServiceIDselected[0] : null;
      const response = await myProfileServiceApi.ALlOfflineOrders(
        Userprofile.service_provider_id,
        formData.from,
        formData.to,
        selectedServiceID
      );

      if (response.data && response.data.error != "") {
        notify("error", response.data.error);
      } else {
        setLoading(false);
        setMyOrders(response.data);
        console.log(response.data);
      }
    } catch (error) {
      notify("error", `Error fetching myorders`);
    }
  }
  React.useEffect(() => {
    GetAllMyOrders();
  }, [formData.to, formData.from]);

  const makeload = () => {
    GetAllMyOrders();
  };

  const [myObjectKeyArray, setMyObjectKeyArray] = React.useState([]);

  const goBack = () => {
    props.return();
    setInnerPage(false);
  };

  const [page, setPage] = React.useState(1);
  const [existingpage,setExistingpage]=React.useState(1)
  const [billData, setBillData] = React.useState();

  const exitDetail = () => {
    setPage(existingpage);
  };
  React.useEffect(()=>{
    document.addEventListener("click",((event)=>{
      console.log("cllicking");
      console.log(reffe?.current);
      if(reffe && reffe.current && reffe.current.contains(event.target)){
        return 
      }
      setDropdownVisible(false);
    }))
  },[])

  return (
    <React.Fragment>
      <>
        <div className="my-service-profile-ongoing-and-pending-online">
          {page !== 4 ? (
            <>

              <div className="my-online-ongoing-pending-profile-time-filter-calander-box">
                <div className="my-service-profile-ongoing-and-pending-online-back">
                  <img src={back} onClick={goBack} alt="" />
                </div>
                <div className="my-order-profile-time-filter-calander-1">
                  <span>From</span>
                  <input
                    type="date"
                    onChange={handleChange}
                    name="from"
                    value={formData.from}
                  ></input>
                </div>
                <div className="my-order-profile-time-filter-calander-1">
                  <span>Till</span>
                  <input
                    type="date"
                    onChange={handleChange}
                    name="to"
                    value={formData.to}
                  ></input>
                </div>
                <div className="my-order-profile-time-filter-calander-3">
                  <span>Sort By : </span>
                  <select onChange={handleChange} name="editFrom">
                    <option value={0}>Time Filter</option>
                    <option value={1}>Today</option>
                    <option value={2}>Last One Week</option>
                    <option value={3}>Last 30 Days</option>
                    <option value={4}>Last 6 Months</option>
                    <option value={5}>2022</option>
                    <option value={6}>2021</option>
                    <option value={7}>2020</option>
                    <option value={8}>2019</option>
                    <option value={9}>2018</option>
                    <option value={10}>2017</option>
                  </select>
                </div>
              </div>
              <div className="my-service-profile-ongoing-and-pending-online-selector">
                <div className="my-service-profile-ongoing-and-pending-online-selector-tab">
                  <motion.span
                    onClick={() => {
                      setPage(1);
                      setExistingpage(1);
                    }}
                    animate={{
                      color: page === 1 ? "black" : "rgba(0, 0, 0, 0.512)",
                      "border-bottom":
                        page === 1 ? "3px solid #1D8B97" : "none",
                    }}
                    whileHover={{ color: "black" }}
                  >
                    Cancelled
                  </motion.span>
                  <motion.span
                    onClick={() => {
                      setPage(2);
                      setExistingpage(2);
                    }}
                    animate={{
                      color: page === 2 ? "black" : "rgba(0, 0, 0, 0.512)",
                      "border-bottom":
                        page === 2 ? "3px solid #1D8B97" : "none",
                    }}
                    whileHover={{ color: "black" }}
                  >
                    Completed
                  </motion.span>
                  <motion.span
                    onClick={() => {
                      setPage(3);
                      setExistingpage(3);
                    }}
                    animate={{
                      color: page === 3 ? "black" : "rgba(0, 0, 0, 0.512)",
                      "border-bottom":
                        page === 3 ? "3px solid #1D8B97" : "none",
                    }}
                    whileHover={{ color: "black" }}
                  >
                    Pending
                  </motion.span>
                </div>
                {page === 1 ? (
                  <>
                    <div className="my-service-profile-ongoing-and-pending-online-box">
                      {myOrders && myOrders.cancelled_orders.length !== 0 ? (
                        myOrders.cancelled_orders.map((item) => {
                          return (
                            <>
                              {!isMobileView ? (
                                <div className="my-bills-profile-page-view-main-right-main-box-card">
                                  {/* <div className='my-bills-profile-page-view-main-right-main-box-card-img'>
                                  <img></img>
                                </div> */}
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-provider">
                                    {item.customer_details.name}
                                  </div>
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-des">
                                    <span>Order Id : {item.order_id}</span>
                                    {/* <span>{item.datetime.slice(5, 16)}</span> */}
                                    <span>
                                      Mobile : {item.customer_details.mobile_no}
                                    </span>
                                  </div>
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-time">
                                    <span>
                                      Order type : {item.current_status}
                                    </span>
                                    {/* <button>Complete Order</button> */}
                                  </div>
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-des">
                                    {item.datetime.slice(0, 16)}
                                  </div>
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-price">
                                    <span>&#8377;{item.amt}</span>
                                    <button
                                      onClick={() => {
                                        setPage(4);
                                        setBillData(item);
                                      }}
                                    >
                                      View Items
                                    </button>
                                  </div>
                                </div>
                              ) : (
                                <div className="my-bills-profile-page-view-main-right-main-box-card-mobileview">
                                  {/* <div className='my-bills-profile-page-view-main-right-main-box-card-img'>
                                  <img></img>
                                </div> */}
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-provider">
                                    <span
                                      style={{
                                        fontWeight: "normal",
                                        color: "rgba(0, 0, 0, 0.476)",
                                      }}
                                    >
                                      User Name:
                                    </span>
                                    {" " + item.customer_details.name}
                                  </div>
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-des">
                                    <span>Order Id : {item.order_id}</span>
                                    {/* <span>{item.datetime.slice(5, 16)}</span> */}
                                    <span>
                                      Mobile : {item.customer_details.mobile_no}
                                    </span>
                                  </div>
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-time">
                                    <span>
                                      <span
                                        style={{
                                          fontWeight: "normal",
                                          color: "rgba(0, 0, 0, 0.476)",
                                        }}
                                      >
                                        Order type :
                                      </span>{" "}
                                      {item.current_status}
                                    </span>
                                    {/* <button>Complete Order</button> */}
                                  </div>
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-des">
                                    Order Date : {item.datetime.slice(0, 16)}
                                  </div>
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-price">
                                    <div>
                                      {" "}
                                      <span
                                        style={{
                                          fontWeight: "normal",
                                          color: "rgba(0, 0, 0, 0.476)",
                                        }}
                                      >
                                        Amount:
                                      </span>{" "}
                                      &#8377;{item.amt}
                                    </div>
                                    <div className="buttons-container">
                                      <button
                                        onClick={() => {
                                          setPage(4);
                                          setBillData(item);
                                        }}
                                      >
                                        View Items
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </>
                          );
                        })
                      ) : (
                        <div className="NoShowCenter noshowceenter">
                          {loading ? <Loading /> : "nothing to show..."}
                        </div>
                      )}
                    </div>
                  </>
                ) : page === 2 ? (
                  <>
                    <div className="my-service-profile-ongoing-and-pending-online-box">
                      {myOrders && myOrders.completed_orders.length !== 0 ? (
                        myOrders.completed_orders.map((item) => {
                          return (
                            <>
                              {!isMobileView ? (
                                <div className="my-bills-profile-page-view-main-right-main-box-card">
                                  {/* <div className='my-bills-profile-page-view-main-right-main-box-card-img'>
                                  <img></img>
                                </div> */}
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-provider">
                                    {item.customer_details.name}
                                  </div>
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-des">
                                    <span>Order Id : {item.order_id}</span>
                                    {/* <span>{item.datetime.slice(5, 16)}</span> */}
                                    <span>
                                      Mobile : {item.customer_details.mobile_no}
                                    </span>
                                  </div>
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-time">
                                    <span>
                                      Order type : {item.current_status}
                                    </span>
                                    {/* <button>Complete Order</button> */}
                                  </div>
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-des">
                                    {item.datetime.slice(0, 16)}
                                  </div>
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-price">
                                    <span>&#8377;{item.amt}</span>
                                    <div className="buttons-container">
                                      <button
                                        onClick={() => {
                                          setPage(4);
                                          setBillData(item);
                                        }}
                                      >
                                        View Items
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className="my-bills-profile-page-view-main-right-main-box-card-mobileview">
                                  {/* <div className='my-bills-profile-page-view-main-right-main-box-card-img'>
                                  <img></img>
                                </div> */}
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-provider">
                                    <span
                                      style={{
                                        fontWeight: "normal",
                                        color: "rgba(0, 0, 0, 0.476)",
                                      }}
                                    >
                                      User Name:
                                    </span>
                                    {" " + item.customer_details.name}
                                  </div>
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-des">
                                    <span>Order Id : {item.order_id}</span>
                                    {/* <span>{item.datetime.slice(5, 16)}</span> */}
                                    <span>
                                      Mobile : {item.customer_details.mobile_no}
                                    </span>
                                  </div>
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-time">
                                    <span>
                                      <span
                                        style={{
                                          fontWeight: "normal",
                                          color: "rgba(0, 0, 0, 0.476)",
                                        }}
                                      >
                                        Order type :
                                      </span>{" "}
                                      {item.current_status}
                                    </span>
                                    {/* <button>Complete Order</button> */}
                                  </div>
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-des">
                                    Order Date : {item.datetime.slice(0, 16)}
                                  </div>
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-price">
                                    <div>
                                      {" "}
                                      <span
                                        style={{
                                          fontWeight: "normal",
                                          color: "rgba(0, 0, 0, 0.476)",
                                        }}
                                      >
                                        Amount:
                                      </span>{" "}
                                      &#8377;{item.amt}
                                    </div>
                                    <div className="buttons-container">
                                      <button
                                        onClick={() => {
                                          setPage(4);
                                          setBillData(item);
                                        }}
                                      >
                                        View Items
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </>
                          );
                        })
                      ) : (
                        <div className="NoShowCenter noshowceenter">
                          {loading ? <Loading /> : "nothing to show..."}
                        </div>
                      )}
                    </div>
                    {/* </div> */}
                  </>
                ) : (
                  <>
                    <div className="my-service-profile-ongoing-and-pending-online-box">
                      {myOrders && myOrders.pending_orders.length !== 0 ? (
                        myOrders.pending_orders.map((item) => {
                          return (
                            <>
                              {!isMobileView ? (
                                <div className="my-bills-profile-page-view-main-right-main-box-card">
                                  {/* <div className='my-bills-profile-page-view-main-right-main-box-card-img'>
                                  <img></img>
                                </div> */}
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-provider">
                                    {item.customer_details.name}
                                  </div>
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-des">
                                    <span>Order Id : {item.order_id}</span>
                                    {/* <span>{item.datetime.slice(5, 16)}</span> */}
                                    <span>
                                      Mobile : {item.customer_details.mobile_no}
                                    </span>
                                  </div>
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-time">

                                    <span >Order type :</span>  {item.current_status}


                                    <button
                                      onClick={() => handleStatusButtonClick(item.order_id)}
                                    >
                                      Status
                                    </button>

                                  </div>
                                  {selectedOrderId === item.order_id && (
                                    <OrderStatusModal onClose={handleModalClose} onCancel={handleCancelOrder} orderId={item.id} onCompleteOrder={handleCompleteOrder} />
                                  )}
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-des">
                                    {item.datetime.slice(0, 16)}
                                  </div>
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-price">
                                    <span>&#8377;{item.amt}</span>
                                    <div className="buttons-container">
                                      <button
                                        onClick={() => {
                                          setPage(4);
                                          setBillData(item);
                                        }}
                                      >
                                        View Items
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className="my-bills-profile-page-view-main-right-main-box-card-mobileview">
                                  {/* <div className='my-bills-profile-page-view-main-right-main-box-card-img'>
                                  <img></img>
                                </div> */}
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-provider">
                                    <span
                                      style={{
                                        fontWeight: "normal",
                                        color: "rgba(0, 0, 0, 0.476)",
                                      }}
                                    >
                                      User Name:
                                    </span>
                                    {" " + item.customer_details.name}
                                  </div>
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-des">
                                    <span>Order Id : {item.order_id}</span>
                                    {/* <span>{item.datetime.slice(5, 16)}</span> */}
                                    <span>
                                      Mobile : {item.customer_details.mobile_no}
                                    </span>
                                  </div>
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-time">
                                    <span>
                                      <span
                                        style={{
                                          fontWeight: "normal",
                                          color: "rgba(0, 0, 0, 0.476)",
                                        }}
                                      >
                                        Order type :
                                      </span>{" "}
                                      {item.current_status}
                                    </span>
                                    {/* <button>Complete Order</button> */}
                                    {selectedOrderId === item.order_id && (
                                      <OrderStatusModal onClose={handleModalClose} onCancel={handleCancelOrder} orderId={item.id} onCompleteOrder={handleCompleteOrder} />
                                    )}
                                  </div>
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-des">
                                    Order Date : {item.datetime.slice(0, 16)}
                                  </div>
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-price">
                                    <div>
                                      {" "}
                                      <span
                                        style={{
                                          fontWeight: "normal",
                                          color: "rgba(0, 0, 0, 0.476)",
                                        }}
                                      >
                                        Amount:
                                      </span>{" "}
                                      &#8377;{item.amt}
                                    </div>
                                    <div className="buttons-container">
                                      <button
                                        onClick={() => {
                                          setPage(4);
                                          setBillData(item);
                                        }}
                                      >
                                        View Items
                                      </button>
                                      <button
                                        onClick={() => handleStatusButtonClick(item.order_id)}
                                      >
                                        Status
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </>
                          );
                        })
                      ) : (
                        <div className="NoShowCenter noshowceenter">
                          {loading ? <Loading /> : "nothing to show..."}
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </>
          ) : (
            <div className="my-bills-profile-page-view-main-right">
              <BillDetailsView
                data={billData}
                isMobileView={isMobileView}
                return={exitDetail}
                page={existingpage}
                reffee={reffe}
                isDropdownVisible={isDropdownVisible}
                setDropdownVisible={setDropdownVisible}
              ></BillDetailsView>
            </div>
          )}
        </div>
      </>
    </React.Fragment>
  );
}

function BillDetailsView(props) {
  // const [isDropdownVisible, setDropdownVisible] = React.useState(false);

  const toggleDropdown = () => {
    props.setDropdownVisible(!props.isDropdownVisible);
  };


  return (
    <React.Fragment>
      <>
        <div className='my-bills-profile-page-view-main-right-data  '>
          {/* <div className='my-service-profile-ongoing-and-pending-online-back'></div> */}
          <div className='my-bills-profile-page-view-main-right-heading-data my-bills-profile-page-view-main-right-data-bill-option'>
            <div>
              <img src={back} onClick={props.return} className='bacckbutton'></img>
              <span className="mobilefont">View Details</span>
            </div>

            <div className="my-order-profile-dropdown" ref={props.reffee}>

              <div className="ellipsis" onClick={toggleDropdown} >
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>
            </div>
          </div>
          <div className='my-bills-profile-page-view-main-right-data-box'>
            {props &&
              props.data &&
              props.data.items &&
              props.data.items.length > 0 &&
              props.data.items.map((item) => {
                return (
                  <div className='my-bills-profile-page-view-main-right-data-box-card'>
                    <div className='my-bills-profile-page-view-main-right-data-box-card-img'>
                      <img src={item.image}></img>
                    </div>
                    <div className='my-bills-profile-page-view-main-right-data-box-card-des'>
                      <span>{item.brand}</span>
                      <span>{item.name}</span>
                      <span>{item.product_type}</span>
                     
                      {!props.isMobileView && (   <span>{item.product_quantity}</span>)}
                       {/* Show qty, unit, and color in mobile view */}
 {props.isMobileView && (
 <div className='my-bills-profile-page-view-main-right-data-box-card-mobile-info'>
  <span>{item.product_quantity}</span> 
      <span> | {item.quantity} Units </span>
      
      {item.color && item.color.length > 0 && (
        <div className='my-bills-profile-page-view-main-right-data-box-card-color'>
         | <div
            className="initial-choice service-color-choice"
            style={{
              backgroundColor: item.color[0].color_code,
            }}
          >
          </div>{" "}
          {item.color[0].color_name}
        </div>
      )}
    </div>)}
                    </div>
                    { !props.isMobileView &&item.color && item.color.length > 0 && <div className='my-bills-profile-page-view-main-right-data-box-card-color'>
                    <div className="initial-choice service-color-choice" style={{
                  backgroundColor:
                    item.color[0].color_code,
                }}
                >
                </div> {item.color[0].color_name}
                    </div>}
                    {!props.isMobileView && <div className='my-bills-profile-page-view-main-right-data-box-card-qty'>
                      Units : {item.quantity}
                    </div>}
                    <div className='my-bills-profile-page-view-main-right-data-box-card-price'>
                      <span>&#8377;{item.price}</span>
                    </div>
                  </div>
                );
              })}
          </div>

          {props.isDropdownVisible && (
            <div className="dropdown-content">
  <Link to={`/viewpdf/offline/${props.data.order_id}/`} target="_blank"><button >View Bill</button></Link>
            </div>
          )}

         

        </div>
      </>
    </React.Fragment>
  );
}


const OrderStatusModal = ({ orderId, onCancel, onClose, onCompleteOrder, }) => {
  return (
    <div className="order-status-modal">
      <div className="order-status-modal-content ">
        <div className='service-provider-modal-content-button'>
          <button className="service-provider-modal-close-button" onClick={onClose}>
            X
          </button>
        </div>
        <h3>Change Order Status</h3>

        <div className="buttons-container">
          <button onClick={() => onCancel(orderId)}>Cancel Order</button>
          <button onClick={() => onCompleteOrder(orderId)}>Complete Order</button>
        </div>

      </div>
    </div>
  );
};