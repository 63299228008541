import React, { useRef, useState } from 'react';
import '../css/imageto-url.css'
import postapi from '../api/postapi';

const Imagetourl = () => {
    const photoRef = useRef(null);
    const [imageUrl, setImageUrl] = useState('');
    const [showImage, setShowImage] = useState(false);
    const resetData = () => {
        photoRef.current.value = "";
        setImageUrl('');
        setShowImage(false);
      }

      const uploadImage = async () => {
        const selectedFile = photoRef.current.files[0];
    
        if (selectedFile) {
          const response = await postapi.UploadImageOGC(selectedFile);
    console.log(response);
          if (response.data.error === '' && response.data.url !== null) {
            setImageUrl(response.data.url);
            setShowImage(true);
          }
        } else {
          alert('Please choose the image you want to upload');
        }
      };
      const copyToClipboard = async () => {
        const url = imageUrl;
        await navigator.clipboard.writeText(url);
        alert("URL copied to clipboard");
      }
    
  return (
    <div className='image-url-div'>
    <nav className="imageurl-navbar">
      <p>Convert your Image Into URL</p>
    </nav>
    <div className="imageurl-container">
      <div className="imageurl-input">
        <label >Choose your Image</label>
        <input
          name="imageurl-mainimage"
          type="file"
          className="imageurl-control-file"
          ref={photoRef}
        />
      </div>
      <div className="image-url-button">
        <button  onClick={uploadImage}>Upload Image</button>
        <button  onClick={resetData}>Clear Image</button>
      </div>
      {showImage && (
        <div className='show-image-url'>
          <input type="text" className="image-url-form" value={imageUrl} readOnly />
          <div className="image-url-button">
            <button onClick={copyToClipboard}>Copy to clipboard</button>
            <button  onClick={resetData}>Reset</button>
          </div>
          <div  className="image-url-preview" style={{ marginTop:"20px",height: '500px' }}>
            <img  style={{ maxWidth: '100%', maxHeight: '500px', margin: 'auto' }} src={imageUrl} alt="image" />
          </div>
        </div>
      )}
    </div>
  </div>
  )
}

export default Imagetourl