import React, { Component, useEffect, useRef, useState } from "react";
import "./css/myordermainpage.css";
import Orders from "../../api/Orders";
import home from "./building-svgrepo-com.svg";
import Loading from "../../components/Loading";
import notify from "../../error";
import noshow from "./noOrders.png";
import Tick from "../../images/tick-svgrepo-com.svg";
import person from '../../images/ordercart/2.png';
import truck from '../../images/ordercart/3.png';
import gift from '../../images//4.png';
import cnacleUpload from '../../images/blogimages/cnacleUpload.svg'
import cancelimage from "../../images/ordercart/icons8-close-100.svg"
export default function MyOrderMainPage() {
  const [startIndex, setStartIndex] = useState(0);
  const [isEmpty, setIsEmpty] = useState(false);
  const [orderHistory, setOrderHistory] = useState();
  const [loading1,setLoading1]=useState(false);
  // ---->to render multiple pages
  const observer = React.useRef();
  const windowWidth = useRef(window.innerWidth);
  var windowwidthstatus=useRef(false);
  const windowHeight = useRef(window.innerHeight);
  const markerRef = React.useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && isEmpty === false) {
          setStartIndex((prevStartIndex) => prevStartIndex + 18);
        }
      });
      if (node) observer.current.observe(node);
    },
    [setStartIndex, isEmpty]
  );

  const date = new Date();
  const date2 = new Date();

  function subtract15Days(date2) {
    const newDate = new Date(date2);
    newDate.setDate(date2.getDate() - 15);
    return newDate;
  }

  // const date = new Date('2022-11-14T00:00:00.000Z');
  const [loading, setLoading] = React.useState(false);
  const newDate = subtract15Days(date2);

  //date inputs using calander
  const [formData, setFormData] = React.useState({
    to: `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(date.getDate()).padStart(2, "0")}`,
    from: `${newDate.getFullYear()}-${String(newDate.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(newDate.getDate()).padStart(2, "0")}`,
    editFrom: `${newDate.getFullYear()}-${String(
      newDate.getMonth() + 1
    ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
  });

  //date manupulation
  //how to play with dates
  function handleChange(event) {
    setIsEmpty(false);
    setStartIndex(0)
    const { name, value, type, checked } = event.target;
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: type === "checkbox" ? checked : value,
      };
    });
    if (name === "editFrom") {

      const newDate = new Date();
      let now = new Date();
      now = getLastWeeksDate();

      function getLastWeeksDate() {
        const now = new Date();

        return new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
      }

      function getLast30Date() {
        const now = new Date();

        return new Date(now.getFullYear(), now.getMonth(), now.getDate() - 30);
      }
      function getLastmonthDate() {
        const now = new Date();

        return new Date(now.getFullYear(), now.getMonth() - 1, now.getDate());
      }
      function getLast6monthDate() {
        const now = new Date();

        return new Date(now.getFullYear(), now.getMonth() - 6, now.getDate());
      }
      function get2022Date() {
        const now = new Date();

        return new Date(now.getFullYear() - 1, 0, 1);
      }
      function get2021Date() {
        const now = new Date();

        return new Date(now.getFullYear() - 2, 0, 1);
      }
      function get2020Date() {
        const now = new Date();

        return new Date(now.getFullYear() - 3, 0, 1);
      }
      function get2019Date() {
        const now = new Date();

        return new Date(now.getFullYear() - 4, 0, 1);
      }
      function get2018Date() {
        const now = new Date();

        return new Date(now.getFullYear() - 5, 0, 1);
      }
      function get2017Date() {
        const now = new Date();

        return new Date(now.getFullYear() - 6, 0, 1);
      }
      if (value === "1") {
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            from: `${newDate.getFullYear()}-${String(
              newDate.getMonth() + 1
            ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
            to: `${newDate.getFullYear()}-${String(
              newDate.getMonth() + 1
            ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
          };
        });
      }

      if (value === "2") {
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
              2,
              "0"
            )}-${String(now.getDate()).padStart(2, "0")}`,
            to: `${newDate.getFullYear()}-${String(
              newDate.getMonth() + 1
            ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
          };
        });
      }

      if (value === "3") {
        now = getLast30Date();
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
              2,
              "0"
            )}-${String(now.getDate()).padStart(2, "0")}`,
            to: `${newDate.getFullYear()}-${String(
              newDate.getMonth() + 1
            ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
          };
        });
      }
      if (value === "4") {
        now = getLast6monthDate();
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
              2,
              "0"
            )}-${String(now.getDate()).padStart(2, "0")}`,
            to: `${newDate.getFullYear()}-${String(
              newDate.getMonth() + 1
            ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
          };
        });
      }
      if (value === "5") {
        now = get2022Date();
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
              2,
              "0"
            )}-${String(now.getDate()).padStart(2, "0")}`,
            to: `${newDate.getFullYear()}-${String(
              newDate.getMonth() + 1
            ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
          };
        });
      }
      if (value === "6") {
        now = get2021Date();
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
              2,
              "0"
            )}-${String(now.getDate()).padStart(2, "0")}`,
            to: `${newDate.getFullYear()}-${String(
              newDate.getMonth() + 1
            ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
          };
        });
      }
      if (value === "7") {
        now = get2020Date();
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
              2,
              "0"
            )}-${String(now.getDate()).padStart(2, "0")}`,
            to: `${newDate.getFullYear()}-${String(
              newDate.getMonth() + 1
            ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
          };
        });
      }
      if (value === "8") {
        now = get2019Date();
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
              2,
              "0"
            )}-${String(now.getDate()).padStart(2, "0")}`,
            to: `${newDate.getFullYear()}-${String(
              newDate.getMonth() + 1
            ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
          };
        });
      }
      if (value === "9") {
        now = get2018Date();
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
              2,
              "0"
            )}-${String(now.getDate()).padStart(2, "0")}`,
            to: `${newDate.getFullYear()}-${String(
              newDate.getMonth() + 1
            ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
          };
        });
      }

      if (value === "10") {
        now = get2017Date();
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
              2,
              "0"
            )}-${String(now.getDate()).padStart(2, "0")}`,
            to: `${newDate.getFullYear()}-${String(
              newDate.getMonth() + 1
            ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
          };
        });
      }
    }
  }

  async function GetOrdersHistory(index) {
    setLoading(true);
    setLoading1(true);
    try {
      const response = await Orders.GetOrderHistory(
        formData.from,
        formData.to,
        index
      );
      if (response.data.error !== "") {
        throw new Error(response.data.error);
      } else {
        setOrderHistory(response.data.product_list);
      }
    } catch (error) {
      notify("error", "Error while fetching orders.");
    }
    setLoading(false);
    setLoading1(false);
  }

  async function GetOrdersHistoryClone(index) {
    setLoading(true);
    // setLoading1(true);
    try {
      const response = await Orders.GetOrderHistory(
        formData.from,
        formData.to,
        index
      );
      if (response.data.error !== "") {
        throw new Error(response.data.error);
      } else if (response.data.product_list.length === 0) {
        setIsEmpty(true);
      } else {
        setOrderHistory((prevHistory) => [
          ...prevHistory,
          ...response.data.product_list,
        ]);
      }
    } catch (error) {
      notify("error", "Error while fetching orders.");
    }
    setLoading(false);
    // setLoading1(false);
  }

  React.useEffect(() => {
    GetOrdersHistory(0);
  }, [formData.to, formData.from]);

  useEffect(() => {
    if (startIndex !== 0) GetOrdersHistoryClone(startIndex);
  }, [startIndex]);

  const makeload = () => {
    GetOrdersHistory(0);
  };
React.useEffect(()=>{
  if(windowWidth<475){
    windowwidthstatus.current=true;
  }
},[])
  return (
    <React.Fragment>
      <>
        <div className="my-order-profile-main-page">
          <div className="my-order-profile-time-filter-calander-box">
            <div className="profile-order-main-page-heading">
              <span>My Orders</span>
            </div>
            <div className="my-order-profile-time-filter-calander-1">
              <span>From</span>
              <input
                type="date"
                onChange={handleChange}
                name="from"
                value={formData.from}
              ></input>
            </div>
            <div className="my-order-profile-time-filter-calander-1">
              <span>Till</span>
              <input
                type="date"
                onChange={handleChange}
                name="to"
                value={formData.to}
              ></input>
            </div>
            <div className="my-order-profile-time-filter-calander-3">
              <span>Sort By : </span>
              <select onChange={handleChange} name="editFrom">
                <option value={0}>Time Filter</option>
                <option value={1}>Today</option>
                <option value={2}>Last One Week</option>
                <option value={3}>Last 30 Days</option>
                <option value={4}>Last 6 Months</option>
                <option value={5}>2022</option>
                <option value={6}>2021</option>
                <option value={7}>2020</option>
                <option value={8}>2019</option>
                <option value={9}>2018</option>
                <option value={10}>2017</option>
              </select>
            </div>
          </div>
        
      
          {(orderHistory && !loading1) ? (
            <>
              <div className="my-order-profile-order-box-container my-order-profile-order-box-containerorder">
              {orderHistory.map((eachOrder, index) => {
  const cartList = eachOrder[1][0]?.cart_list;

  // Check if cart_list is not empty before rendering MyOrderCard
  if (cartList && cartList.length > 0) {
    return (
      <MyOrderCard
        data={cartList}
        time={eachOrder[1][0].time_stamp}
        status_id={eachOrder[1][0].status_id}
        payment_mode={eachOrder[1][0].payment_mode}
        order_id={eachOrder[1][0].order_id}
        i={index}
        updated_time={eachOrder[1][0].update_date_time}
        reload={makeload}
        loading={loading}
        transaction_amount={eachOrder[1][0].transaction_amount}
        alldata={eachOrder[1][0]}
      />
    );
  }

  // Return null or an empty fragment if cart_list is empty
  return null;
})}
                {orderHistory && orderHistory.length > 0 && (
                  <div className="marker" ref={markerRef} />
                )}
              </div>
              {(loading || isEmpty) && (
                <div className="myorder_loading">
                  {loading && <Loading />}
                  {isEmpty && <span> That's all we have...</span>}
                </div>
              )}
            </>
          ) :<> { (orderHistory && orderHistory?.length == 0 && !loading1) ? (
            <div className="my-order-profile-order-box-container-no-order">
              <img src={noshow} alt="" />
            </div>
          ):<>{loading1 && <Loading></Loading>}</>}
          </>}

        </div>
      </>
    </React.Fragment>
  );
}
function MyOrderCard(props) {

  const [smallScreen, setSmallScreen] = useState(window.innerWidth < 720);
  const [showDetails, setShowDetails] = useState(false);
  const [track,settrack]=useState(false);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  console.log(props);

  const cancelMyOrder = async () => {
    const response = await Orders.CancelOrder(props.order_id);
    if (response.data && response.data.error !== "") {
      notify("error", response.data.error);
    } else {
      props.reload();
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setSmallScreen(window.innerWidth < 720);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {!smallScreen ? (
        <div className="my-order-profile-card-box">
          <div className="my-order-profile-card-box-time"><div className="my-order-profile-card-box-time-left">{props.time.slice(4, 26)}</div>
            <div className="my-order-profile-card-box-time-right">Total Amount : <span>&#8377;{props.transaction_amount}</span></div>
          </div>
          <div className="my-order-profile-card-box-item-detail">
            <div className="my-order-profile-card-box-item-detail-left">
              {props.data[0].color  && props.data[0].color.length > 0 ? (
                // If color array is not empty, use the color and image from it


                <img src={props.data[0].color[0].images[0]}></img>

              ) : (
                // Otherwise, use the default image
                <img src={props.data[0]?.image} alt="" />
              )}
            </div>
            <div className="my-order-profile-card-box-item-detail-right">
              <div>{props.data[0].brand_name}</div>
             { props.data[0].name.length > 20 ?
             <span>{`${props.data[0].name.substring(0,20)}...`}</span>:<span>{props.data[0].name}</span>
              }
              <section>{props.data[0].service_provider}</section>
              <article>
                {props.data[0].size_qty}      <div></div> {" "}  {props.data[0].quantity} unit(s) <div></div> &#8377;{" "}
                {props.data[0].total_price}
              </article>
              {props.data[0].color && props.data[0].color.length > 0 && <div className='my-bills-profile-page-view-main-right-data-box-card-color'>
                <div className="initial-choice service-color-choice" style={{
                  backgroundColor:
                    props.data[0].color[0].color_code, margin: 0, marginRight: "2px"
                }}
                >
                </div> {props.data[0].color[0].color_name}
              </div>}
              <div className="my-order-profile-card-box-item-status-view-more">
                <div className="my-order-profile-card-box-item-status-more-images">
                  {props.data && props.data.length > 1 && props.data.slice(0,4).map((item, index) => (
                    <React.Fragment key={index}>
                      {item.color && item.color.length > 0 ? (
                        // If color array is not empty, use the color and image from it
                        <img src={item.color[0].images[0]} alt={`Color Image ${index}`} />
                      ) : (
                        // Otherwise, use the default image
                        <img src={item?.image} alt={`Default Image ${index}`} />
                      )}
                    </React.Fragment>
                  ))}
                </div>
                {props.data.length>1 &&<button onClick={toggleDetails}>View More</button>}
              </div>
            </div>

          </div>

          <div className="my-order-profile-card-box-item-status">
            <div>
              Status : &nbsp;
              {props.status_id === 1 ? (
                <span>Order Placed</span>
              ) : props.status_id === 2 ? (
                <span>Shipped</span>
              ) : (props.status_id === 5 || props.status_id === 10) ? (
                <span>Cancelled by putatoe</span>
              ) : props.status_id === 14 ? (
                <span>Cancelled</span>
              ):(
                <span>Delivered</span>
              )}
            </div>
            <div className="my-order-profile-card-box-item-status-shipped-time">
            <div>
            
              { props.status_id === 2 ? (
                      <span>{props.updated_time.slice(4, 26)}</span>
              ) : props.status_id === 5 ? (
                <span>{props.updated_time.slice(4, 26)}</span>
              ) :props.status_id === 4 ? (
                <span>{props.updated_time.slice(4, 26)}</span>
              ):null}
            </div>
            </div>
            <div className="my-order-profile-card-box-item-status2">
              Payment :&nbsp;
              <span>{props.payment_mode? props.payment_mode:null}</span>
            </div>
            {/* <div>
              <img src={home} alt="" /> <p>Home Delivery</p>
            </div> */}
            {props.status_id === 1 && (
              <div className="my-order-profile-card-box-item-status-cancel">
                <button onClick={cancelMyOrder}>Cancel</button>
              </div>
            )}
             <div className="my-order-profile-card-box-item-status-cancel track" onClick={()=>{
                  settrack(true);
                 }}>
                  <button >Track</button>
                </div> 
          </div>
          {
            track && 
            <>
            <div className="trackorrder">
            <Trackingcard trackinfo={props.alldata} settrack={settrack}></Trackingcard>
            </div>
            </>
          }
        </div>
      ) : (
        // {/* if mobile screen then below layout will be present */}

        <div className="my-order-profile-card-box">
          {/* <div className="my-order-profile-card-box-time">
            {props.time.slice(4, 26)}
          </div> */}
          <div className="my-order-profile-card-box-time">
            <div className="my-order-profile-card-box-time-left">{props.time.slice(4, 26)}</div>
            <div className="my-order-profile-card-box-time-right">Total Amount : 
            <span>&#8377;{props.transaction_amount}</span>
            </div>
          </div>
          <div className="my-order-profile-card-box-item-detail-left">
          {props.data[0].color &&  props.data[0].color.length > 0 ? (
                // If color array is not empty, use the color and image from it


                <img src={props.data[0].color[0].images[0]}></img>

              ) : (
                // Otherwise, use the default image
                <img src={props.data[0]?.image} alt="" />
              )}
          </div>

          <div className="my-order-profile-card-box-item-detail">
            <div className="my-order-profile-card-box-item-detail-right">
              <div>{props.data[0].brand_name}</div>
              { props.data[0].name.length > 20 ?
             <span>{`${props.data[0].name.substring(0,20)}...`}</span>:<span>{props.data[0].name}</span>
              }

              <section>{props.data[0].service_provider}</section>
              <article>
                {props.data[0].quantity} unit(s) <div></div> &#8377;{" "}
                {props.data[0].total_price}
              </article>
              {props.data[0].color && props.data[0].color.length > 0 && <div className='my-bills-profile-page-view-main-right-data-box-card-color'>
                <div className="initial-choice service-color-choice" style={{
                  backgroundColor:
                    props.data[0].color[0].color_code, margin: 0, marginRight: "2px"
                }}
                >
                </div> {props.data[0].color[0].color_name}
              </div>}
              <div className="my-order-profile-card-box-item-status-view-more">
                <div className="my-order-profile-card-box-item-status-more-images">
                  {props.data && props.data.length > 1 && props.data.slice(0,2).map((item, index) => (
                    <React.Fragment key={index}>
                      {item.color && item.color.length > 0 ? (
                        // If color array is not empty, use the color and image from it
                        <img src={item.color[0].images[0]} alt={`Color Image ${index}`} />
                      ) : (
                        // Otherwise, use the default image
                        <img src={item?.image} alt={`Default Image ${index}`} />
                      )}
                    </React.Fragment>
                  ))}
                </div>
                {props.data.length>1 &&<button onClick={toggleDetails}>View More</button>}
              </div>

            </div>
            <div className="my-order-profile-card-box-item-status">
              <div>
                Status : &nbsp;
                {props.status_id === 1 ? (
                  <span>Order Placed</span>
                ) : props.status_id === 2 ? (
                  <span>Shipped</span>
                ) : (props.status_id === 5 || props.status_id === 10) ? (
                  <span>Cancelled by putatoe</span>
                ) : props.status_id === 14 ? (
                  <span>Cancelled by user</span>
                ):(
                  <span>Delivered</span>
                )}
              </div>
              <div className="my-order-profile-card-box-item-status-shipped-time">
            <div>
            
              { props.status_id === 2 ? (
                      <span>{props.updated_time.slice(4, 26)}</span>
              ) : props.status_id === 5 ? (
                <span>{props.updated_time.slice(4, 26)}</span>
              ) :props.status_id === 4 ? (
                <span>{props.updated_time.slice(4, 26)}</span>
              ):null}
            </div>
            </div>
              {/* <div>
                <img src={home} alt="" /> <p>Home Delivery</p>
              </div> */}
              <div className="my-order-profile-card-box-item-status2">
              Payment :&nbsp;
              <span>{props.payment_mode? props.payment_mode:null}</span>
            </div>
              {props.status_id === 1 && (
                <div className="my-order-profile-card-box-item-status-cancel">
                  <button onClick={cancelMyOrder}>Cancel</button>
                </div>
              )}
                 <div className="my-order-profile-card-box-item-status-cancel track" onClick={()=>{
                  settrack(true);
                 }}>
                  <button>Track</button>
                </div>    
            </div>
          </div>
          {
            track && 
            <>
            <div className="trackorrder">
            <Trackingcard trackinfo={props.alldata} settrack={settrack}></Trackingcard>
            </div>
            </>
          }
        </div>
      )}

      {showDetails && (
        <div className="my-order-profile-card-box-item-details">
          <div className="my-order-profile-card-box-item-details-content">



            <div className="my-order-profile-card-box-tem-details-header">


              <div className="my-order-profile-card-box-item-status-total-items">
                <p>Total Item(s) : {props.data.length}</p>
              </div>
              <button className="my-orders-modal-details-close-btn" onClick={toggleDetails}>
                X
              </button>
            </div>
            {props.data.map((product, index) => (

              <div className="my-order-profile-card-box-item-detail" key={index}>
                <div className="my-order-profile-card-box-item-detail-left">
                  <span>{index + 1 + " ."}</span>
                  {product.color && product.color.length > 0 ? (
                    // If color array is not empty, use the color and image from it
                     
                    <>
                   {(smallScreen)?       
                   <><div className="my-order-profile-card-box-item-detail-left-class">
                    <img src={product.color[0].images[0]}></img>
                     </div>
                    </>:<img src={product.color[0].images[0]}></img>
                    }
                     </>
                  ) : (
                    // Otherwise, use the default image
                    <img src={product?.image} alt="" />
                  )}
                </div>
                <div className="my-order-profile-card-box-item-detail-right">
                  {
                    (!smallScreen)?<>
                    <div>{product.brand_name}</div>
                    { props.data[0].name.length > 20 ?
             <span>{`${props.data[0].name.substring(0,20)}...`}</span>:<span>{props.data[0].name}</span>
              }
                  <section>{product.service_provider}</section>
                  <article>
                    {product.size_qty}      <div></div> {" "}  {product.quantity} unit(s) <div></div> &#8377;{" "}
                    {product.total_price}
                  </article>
                  {product.color && product.color.length > 0 && <div className='my-bills-profile-page-view-main-right-data-box-card-color'>
                    <div className="initial-choice service-color-choice" style={{
                      backgroundColor:
                        product.color[0].color_code, margin: 0, marginRight: "2px"
                    }}
                    >
                    </div> {product.color[0].color_name}
                  </div>
                  }
                    </>:<>
                    <div className="my-order-profile-card-box-item-detail-right-subsectionleft">
                  <div>{product.brand_name}</div>
                  <span>{product.name}</span>
                  <section>{product.service_provider}</section>
                  </div>
                  <div className="my-order-profile-card-box-item-detail-right-subsectionright">
                  <article>
                    {product.size_qty}      <div></div> {" "}  {product.quantity} unit(s) <div></div> &#8377;{" "}
                    {product.total_price}
                  </article>
                  {product.color && product.color.length > 0 && <div className='my-bills-profile-page-view-main-right-data-box-card-color'>
                    <div className="initial-choice service-color-choice" style={{
                      backgroundColor:
                        product.color[0].color_code, margin: 0, marginRight: "2px"
                    }}
                    >
                    </div> {product.color[0].color_name}
                  </div>
                  }
                  </div>
                    </>
                  }
                </div>
  
              </div>
            ))}

          </div>
        </div>
      )}


    </>
  );
}

function Trackingcard({trackinfo,settrack}){
  return (
    <>
    <div className="trackorderparent">
    <img className='crossIcon' src={cnacleUpload} alt="cross_icon" onClick={() => {
      settrack(false);
              }} />
              <div className="trackingheading">
              <div className="tracking_status">
                TRACKING STATUS
              </div>
              <div className="order_status">
                {trackinfo.order_id}
              </div>
              </div>
            <div className='order-confirmation-cart-page-items-order-status-track'>
          <div className='order-confirmation-cart-page-items-order-status-track-1 trackordernew curvestage'>
            <div>
              <div>
                <img src={Tick}></img>
              </div>
              <span className="timmee">
              <span className="ordersstaus">Order Placed</span>
              <span className="datetime">{trackinfo.order_placed_datetime}</span>
              </span>
            </div>
          </div>

          {
            (trackinfo.order_cancelled_byuser_datetime  || trackinfo.order_cancelled_datetime ) &&  <>
            <div className="cannncelstage trackordernew curvestage1">
        
          </div>
          </>
          }
          {
           (trackinfo.order_cancelled_byuser_datetime || trackinfo.order_cancelled_datetime ) && <>
            <div className="cannncelstage trackordernew curvestage1">
          </div>
          </>
          }
          {
             (trackinfo.order_cancelled_byuser_datetime || trackinfo.order_cancelled_datetime ) && <>
            <div className="cannncelstage trackordernew curvestage1">
            <div>
              <div>
            {  (trackinfo.order_cancelled_byuser_datetime || trackinfo.order_cancelled_datetime) && <img src={cancelimage}></img>}
              </div>
              <span className="timmee">
              <span className="cannncel ordersstaus">
              {  trackinfo.order_cancelled_byuser_datetime  ? <>Order Cancelled</> : <>Order Cancelled by putatoe</> }
                </span>  
              <span className="datetime">{trackinfo.order_cancelled_byuser_datetime}</span>
              </span>
            </div>
          </div>
          </>
          }

{
              (trackinfo.order_cancelled_byuser_datetime == null && trackinfo.order_cancelled_datetime == null) && <>

          <div className={trackinfo.order_shipped_datetime ? "order-confirmation-cart-page-items-order-status-track-1 trackordernew":"order-confirmation-cart-page-items-order-status-track-2 trackordernew"}>
            <div>
              <div>
            {  trackinfo.order_shipped_datetime ? <img src={Tick}></img>: <img src={person}></img>}
              </div>
              <span className="timmee">
              <span className="ordersstaus">Shipped</span>
              <span className="datetime">{trackinfo.order_shipped_datetime}</span>
            </span>
          </div>
          </div>
          </>
}
{
               (trackinfo.order_cancelled_byuser_datetime == null && trackinfo.order_cancelled_datetime == null) &&  <>

          <div className={trackinfo.order_delivered_datetime ? "order-confirmation-cart-page-items-order-status-track-1 trackordernew curvestage1":"order-confirmation-cart-page-items-order-status-track-2 trackordernew curvestage1"}>
            
          </div>
</>
}

{
              (trackinfo.order_cancelled_byuser_datetime == null && trackinfo.order_cancelled_datetime == null) && <>
          <div className={trackinfo.order_delivered_datetime ? "order-confirmation-cart-page-items-order-status-track-1 trackordernew curvestage1":"order-confirmation-cart-page-items-order-status-track-2 trackordernew curvestage1"}>
            <div>
              <div>
              {  trackinfo.order_delivered_datetime ? <img src={Tick}></img>: <img src={person}></img>}
              </div>
              <span className="timmee">
              <span className="ordersstaus">Delivered</span>
              <span className="datetime">{trackinfo.order_delivered_datetime}</span>
              </span>
            </div>
          </div>
          </>
}
        </div>
                </div>

    </>
  )
}