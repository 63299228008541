import React from 'react';
import '../css/modal.css';
import EditMyAddress from './EditAddressNew';

function EditAddressModal({ address, show, onClose, fetchAddress, HideModal }) {
    if (!show) {
        return null;
    }

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <div className="modal-header">
                    <button className="close-button" onClick={onClose}>
                        <p>x</p>
                    </button>
                </div>
                <div className="modal-body">
                    <EditMyAddress address={address} onClose={onClose} fetchAddress={fetchAddress}  HideModal={HideModal}/>
                </div>
            </div>
        </div>
    );
}

export default EditAddressModal;
