import React, { Component } from 'react';
import '../serviceprovider-css/advertisment.css';
import starf from '../images/Starf.svg';
import star from '../images/Star.svg';
import photo from '../images/advertisment-image.svg';
import location from '../images/ad-location.svg'
import { BsFillChatRightDotsFill, BsChatDots,  } from "react-icons/bs"
import {BiBlock} from "react-icons/bi"
import { IoMdMegaphone } from "react-icons/io"
import { useGlobalContext } from '../context';
import car from '../images/ad-car.svg'
import store from '../images/ad-store.svg'
import phone from '../images/ad-call.svg'
import promotion from '../images/promotions.svg'
import blog from '../images/blog.svg'
import api from '../api/api';
import chat from '../images/comment-multiple-svgrepo-com.svg';
import apiclient from '../api/apiclient';
import notify from '../error';
import { FaStar ,FaStarHalfAlt } from 'react-icons/fa'
import { Link, useNavigate } from 'react-router-dom';
import chatapi from '../api/chatapi';

export default function Advertisement(props) {
  console.log(props)
  const [rating, setRating] = React.useState()
  const [userrating, setUserRating] = React.useState(0);
  const [isFavorite, setIsFavorite] = React.useState(false);

  const { Userprofile,        setUserProfile, loginPage, display, setDisplay,
    setLoginPage,         isMobileView, setIsMobileView} = useGlobalContext();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState('');
  const [reviewmessage, setReviewmessage] = React.useState('');

  const handleRatingChange = (value) => {
    setUserRating(value);
  };

  const handleInputChange = (event) => {
    setReviewmessage(event.target.value);
  };


  const handleOpenModal = (item) => {
    setModalOpen(true);
    setSelectedItem(item);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedItem('');
  };
  const handlePhoneClick = () => {
    if(!isMobileView){
    handleOpenModal('phone')
  } else{
    window.location.href = `tel:${props.data.serviceprovider_mobile}`;
  }
  };

  React.useEffect(() => {
    let arr = []
    let i = 1
    for (; i <= props.data.serviceprovider_rating; i++) {
      arr.push(
        <img className='service-view-card-star' src={starf} alt=''></img>
      );
    }
    for (; i <= 5; i++) {
      arr.push(
        <img className='service-view-card-star' src={star} alt=''></img>
      );
    }
    setRating(arr)
    fetchFavoriteStatus();
    
  }, [])
  const fetchFavoriteStatus = async () => {
    try {
      //  API call to check the favorite status
      const response = await apiclient.Checkfavserviceseller(props.providerId);
      
      // Update the state based on the response
      setIsFavorite(response.data.is_favourite);
    } catch (error) {
      console.error('Error fetching favorite status:', error);
    }
  };
  const AddToMyFollowList = async () => {
    if (!Userprofile) {
      setDisplay("login-sidebar-container2");
      setLoginPage(1);
      notify("error", "Login First To Follow");
      return;
    }
    // Check if user is already following
  if (isFavorite) {
    const deleteResponse = await apiclient.Deletesellerfromfavlist(props.providerId);
    console.log(deleteResponse);
    if (deleteResponse.data.error !== '') {
      notify('error', deleteResponse.data.error);
    } else {
      notify(
        'success',
        `Successfully unfollowed ${props.data.serviceprovider_name}`
      );
      fetchFavoriteStatus();
      setIsFavorite(false);
    }
  } else {
    const response = await apiclient.AddServiceProviderToFav(props.providerId);
    console.log(response);
    if (response.data.error !== '') {
      notify('error', response.data.error);
    } else {
      notify(
        'success',
        `Successfully followed ${props.data.serviceprovider_name}`
      );
      fetchFavoriteStatus();
    }
  }
  //update profile follower count
  const profileResponse = await api.ProfileApi();
  console.log(profileResponse);
  if (profileResponse.data.error !== '') {
    notify('error',profileResponse.data.error);
  } else {
    setUserProfile(profileResponse.data);
  }

  localStorage.setItem("UserProfile", JSON.stringify(profileResponse.data));
  }

  const navigate = useNavigate();

  const chatwithprovider = async () => {
    const response = await chatapi.ConnectToANewServiceProvider(props.providerId)
    console.log(response)
    if (response.data.error !== '') {
      notify('error', response.data.error)
    }
    else {
      navigate('/chat/seller')
    }
  }

  const handlereview = async (event) => {
    event.preventDefault();
    if (reviewmessage.trim() === '') {
      handleCloseModal();
      notify("error", "Write some review to send")
      return;
    }

    console.log('Message:', reviewmessage);
    const response = await apiclient.UserSellerReview(props.providerId,userrating, reviewmessage)
    console.log(response)
    if (response.data.error !== '') {
      notify('error', response.data.error)
      handleCloseModal();
      setReviewmessage('')
      setUserRating(0)
    }
    else {
      notify("success", "Thanks for your review!");
      setReviewmessage('');
      setUserRating(0)
      handleCloseModal();
    }

  };

  const blockProvider = async () => {

    if (!Userprofile) {
      handleCloseModal();
      setDisplay("login-sidebar-container2");
      setLoginPage(1);
      notify("error", "Login First To Block The Service Provider");
      return;
    }
      const response = await apiclient.BlockProvider(props.providerId);

      if (response.data.error !== '') {
        notify('error', response.data.error)
       }
        else {
notify("success","Service Provider Blocked Successfully");
handleCloseModal();
navigate('/')
      }
 
  };

  
  
  return (
    <React.Fragment>
      <div className='service-provider-advertisement'>
        <div className='service-provider-advertisement-image-container'>
          <div className='service-provider-advertisement-image'>
            <img src={props.data.image}></img>
            hello
          </div>
          <div className='service-provider-advertisement-rating-container'>
            {rating}
            <div className='service-provider-advertisement-rating-text'>
              {props.data.serviceprovider_rating}
            </div>
          </div>
          <div className='service-provider-advertisement-number-rating'>
            (1,750)
          </div>
        </div>

        <div className='service-provider-advertisement-info-container'>
          <div className='service-provider-advertisement-name-container'>
            <div className='service-provider-advertisement-name'>
              {props.data.serviceprovider_name}
            </div>
            <div className='service-provider-advertisement-time'>
              Opens At : 8:00 Am -10 : 00 Pm
            </div>
          </div>

          <div className='service-provider-advertisement-follow-container'>
            <button
              className='service-provider-advertisement-follow'
              onClick={AddToMyFollowList}
            >
              <div className='service-provider-advertisement-follow-text'>
              {isFavorite ? 'Following' : 'Follow'}
              </div>
            </button>
          </div>

          <div className='service-provider-advertisement-popularity-container'>
            <div className='service-provider-advertisement-follower-container'>
              <div className='service-provider-advertisement-follower-number'>
                1000
              </div>
              <div className='service-provider-advertisement-follower-text'>
                Follower
              </div>
            </div>
            <div className='service-provider-advertisement-break-container'></div>
            <div className='service-provider-advertisement-following-container'>
              <div className='service-provider-advertisement-follower-number'>
                20
              </div>
              <div className='service-provider-advertisement-follower-text'>
                Following
              </div>
            </div>
            <div className='service-provider-advertisement-break-container'></div>
            <div className='service-provider-advertisement-item-container'>
              <div className='service-provider-advertisement-follower-number'>
                {props.len}
              </div>
              <div className='service-provider-advertisement-follower-text'>
                Items
              </div>
            </div>
          </div>

          <div className='service-provider-advertisement-details-container'>
            <div className='service-provider-advertisement-location-container'>
              <div className='service-provider-advertisement-detail-image-container'>
                <img
                  src={location}
                  className='service-provider-advertisement-detail-image'
                ></img>
              </div>
              <div className='service-provider-advertisement-detail-text'>
                {props.data.address_line1}
              </div>
            </div>
            <div className='service-provider-advertisement-delivery-container'>
              <div className='service-provider-advertisement-detail-image-container'>
                <img
                  src={car}
                  className='service-provider-advertisement-detail-image'
                ></img>
              </div>
              <div className='service-provider-advertisement-detail-text'>
                Home delivery available
              </div>
            </div>
            <div className='service-provider-advertisement-pickup-container'>
              <div className='service-provider-advertisement-detail-image-container'>
                <img
                  src={store}
                  className='service-provider-advertisement-detail-image'
                ></img>
              </div>
              <div className='service-provider-advertisement-detail-text'>
                Store Pickup available
              </div>
            </div>
          </div>

          <div className='service-provider-advertisement-other-container'>
            <div className='service-provider-advertisement-chat'>
              <img src={chat} onClick={chatwithprovider}></img>

            </div>
            <div className='service-provider-advertisement-phone'>
              <img src={phone} onClick={handlePhoneClick}></img>
            </div>
            <div className='service-provider-advertisement-phone'>
              <BsFillChatRightDotsFill size={29} color='#fff' onClick={() => handleOpenModal('review')} />
            </div>
            <div className='service-provider-advertisement-phone'>
              <IoMdMegaphone size={29} color='#fff' onClick={() => handleOpenModal('promotion')} />
            </div>
            <div className='service-provider-advertisement-phon'>
              <img src={blog} style={{ transform: "scale(1.02)" }} onClick={() => handleOpenModal('blog')} ></img>
            </div>
            <div className='service-provider-advertisement-phone'>
              <BiBlock size={24} color='#fff' onClick={() => handleOpenModal('block')}/>
            </div>

          </div>

        </div>

      </div>
      {/* mobileview */}
      <div className='service-provider-advertisement  service-provider-advertisement-mobileview '>
        <div className='service-provider-advertisement-image-container'>
          <div className='service-provider-advertisement-image'>
            <img src={props.data.image}></img>
          </div>
          <div className='service-provider-advertisement-rating-container'>
            {rating}
            <div className='service-provider-advertisement-rating-text'>
              {props.data.serviceprovider_rating}
            </div>
          </div>
          <div className='service-provider-advertisement-number-rating'>
            (1,750)
          </div>
        </div>

        <div className='service-provider-advertisement-info-container'>
          <div className='service-provider-advertisement-name-container'>
            <div className='service-provider-advertisement-name'>
              {props.data.serviceprovider_name}
            </div>
            <div className='service-provider-advertisement-time'>
              Opens At : 8:00 Am -10 : 00 Pm
            </div>
          </div>





          <div className='service-provider-advertisement-details-container'>
            <div className='service-provider-advertisement-location-container'>
              <div className='service-provider-advertisement-detail-image-container'>
                <img
                  src={location}
                  className='service-provider-advertisement-detail-image'
                ></img>
              </div>
              <div className='service-provider-advertisement-detail-text'>
                {props.data.address_line1}
              </div>
            </div>
            <div className='service-provider-advertisement-delivery-container'>
              <div className='service-provider-advertisement-detail-image-container'>
                <img
                  src={car}
                  className='service-provider-advertisement-detail-image'
                ></img>
              </div>
              <div className='service-provider-advertisement-detail-text'>
                Home delivery available
              </div>
            </div>
            <div className='service-provider-advertisement-pickup-container'>
              <div className='service-provider-advertisement-detail-image-container'>
                <img
                  src={store}
                  className='service-provider-advertisement-detail-image'
                ></img>
              </div>
              <div className='service-provider-advertisement-detail-text'>
                Store Pickup available
              </div>
            </div>
          </div>






        </div>
        <div className='service-provider-advertisement-other-container'>
            <div className='service-provider-advertisement-chat'>
              <img src={chat} onClick={chatwithprovider}></img>

            </div>
            <div className='service-provider-advertisement-phone'>
              <img src={phone} onClick={handlePhoneClick}></img>
            </div>
            <div className='service-provider-advertisement-phone'>
              <BsFillChatRightDotsFill size={29} color='#fff' onClick={() => handleOpenModal('review')} />
            </div>
          
           
            <div className='service-provider-advertisement-phone'>
              <BiBlock size={24} color='#fff' onClick={() => handleOpenModal('block')} />
            </div>

          </div>
        <div className='service-provider-advertisement-popularity-container'>
            <div className='service-provider-advertisement-follower-container'>
              <div className='service-provider-advertisement-follower-number'>
                1000
              </div>
              <div className='service-provider-advertisement-follower-text'>
                Follower
              </div>
            </div>
            <div className='service-provider-advertisement-break-container'></div>
            <div className='service-provider-advertisement-following-container'>
              <div className='service-provider-advertisement-follower-number'>
                20
              </div>
              <div className='service-provider-advertisement-follower-text'>
                Following
              </div>
            </div>
            <div className='service-provider-advertisement-break-container'></div>
            <div className='service-provider-advertisement-item-container'>
              <div className='service-provider-advertisement-follower-number'>
                {props.len}
              </div>
              <div className='service-provider-advertisement-follower-text'>
                Items
              </div>
            </div>
          </div>
          <div className='service-provider-advertisement-follow-container'>
            <button
              className='service-provider-advertisement-follow'
              onClick={AddToMyFollowList}
            >
              <div className='service-provider-advertisement-follow-text'>
              {isFavorite ? 'Following' : 'Follow'}
              </div>
            </button>
          </div>

      </div>
      {modalOpen && (
        <div className="service-provider-modal" onClick={handleCloseModal} >
          <div className="service-provider-modal-content" onClick={(e) => e.stopPropagation()}>
            <div className='service-provider-modal-content-button'>
              <button className="service-provider-modal-close-button" onClick={handleCloseModal}>
                X
              </button>
            </div>

            {/* <textarea className="service-provider-addressforminput"  
            type="text" 
            value={reviewmessage}
        onChange={handleInputChange}
            placeholder="Write your message"
            >

            </textarea>
            <div class="service-provider-addressr-Button" onClick={handlereview}>
                        SUBMIT REVIEW
                     </div>

                     </div> */}

            {selectedItem === 'review' && (
              <div>
                <div className="rating-container">
                <h1>Give a rating</h1>
                <StarRating value={userrating} maxValue={5} onChange={handleRatingChange} />
                </div>
       
                <h1>Add review message</h1>
                <textarea
                  className="service-provider-addressforminput"
                  type="text"
                  value={reviewmessage}
                  onChange={handleInputChange}
                  placeholder="Write your message"
                ></textarea>
                <div className="service-provider-addressr-Button" onClick={handlereview}>
                  SUBMIT REVIEW
                </div>
              </div>
            )}

            {selectedItem === 'promotion' && (
              <div>
                {/* Render specific content for 'megaphone' item */}
                <div> promotion item content</div>
                <div className="service-provider-addressr-Button" >
                  SUBMIT
                </div>
              </div>
            )}

            {selectedItem === 'blog' && (
              <div>
                {/* Render specific content for 'blog' item */}
                <div>Blog item content</div>
                <div className="service-provider-addressr-Button" >
                  SUBMIT
                </div>
              </div>
            )}
               {selectedItem === 'phone' && (
              <div className='service-provider-contact-details'>
                {/* Render specific content for 'blog' item */}
                <div>Seller Contact Number</div>
                <p>{props.data.serviceprovider_mobile}</p>
              </div>
            )}
                 {selectedItem === 'block' && (
              <div className='service-provider-contact-details'>
            <p>Are you sure you want to block this service provider?</p>
            <div className="buttons-container">
            <button onClick={blockProvider}>Yes</button>
        <button onClick={handleCloseModal}>No</button>
            </div>
       
              </div>
            )}
          </div>
        </div>
      )}

    </React.Fragment>

  );
}

const StarRating = ({ value, onChange }) => {
  const [hoverValue, setHoverValue] = React.useState(0);

  const handleMouseEnter = (newValue) => {
    setHoverValue(newValue);
  };

  const handleMouseLeave = () => {
    setHoverValue(0);
  };
  const handleStarClick = (clickedValue) => {
    const newValue = clickedValue === value ? 0 : clickedValue;
    onChange(newValue);
  };

  const stars = [];
  for (let i = 1; i <= 5; i++) {
    const isFilled = i <= (hoverValue || value); // Use hoverValue when hovered, else use current value

    stars.push(
      <span
        key={i}
        className={`star ${isFilled ? 'filled' : 'unfilled'}`}
        onClick={() => handleStarClick(i)}
        onMouseEnter={() => handleMouseEnter(i)}
        onMouseLeave={handleMouseLeave}
      >
        <FaStar />
      </span>
    );
  }

  return (
    <div className="star-rating">
      {stars}
    </div>
  );
};