import React, { useState } from "react";
import profileapi from "../api/profileapi";
import notify from "../error";
import "./cart-css/Adress.css";
import EditAddressModal from "./EditAddressModal";

export default function AddressModal(props) {
  // for hide and show edit address modal 
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };



  function HideModal() {
    props.setShow();
  }

  function OtherClickMain() {
    props.addition();
    props.setShow();
  }

  function OtherClickMainEdit(e) {
    props.editing(e);
    props.setShow();
  }

  function handleChange(event) {
    const { name, value, type, checked } = event.target;
    // console.log(value, ' value 1212ad');
    props.selected(value);
  }

  function handleContinue() {
    props.setShow();
  }

  const DeleteAddress = async (val) => {
    const response = await profileapi.RemoveAddress(val);
    console.log(response);
    notify("success", "Address Deleted succesfully");
  };

  return (
    <React.Fragment>
      {props.show && (
        <div className="Address-modal-container-background">
          <div
            className={
              props.show
                ? " Address-modal-container Address-modal-container-intro-in"
                : "Address-modal-container"
            }
          >
            <div className="Address-modal-cross">
              <div>Change Delivery Address</div>
              <span onClick={HideModal}>X</span>
            </div>
            {
              props.Loading ? <div style={{ textAlign: 'center' }}>Loading...</div>
                :
                <div className="Address-modal-coupon-enter">
                  {props.address.length > 0 &&
                    props.address.map((item, index) => {
                      return (
                        <div key={item.address_id} className="Address-modal-coupon-enter-row addressrowforr">
                          <div className="Address-modal-coupon-enter-row-input">
                            <>
                              <input
                                type="radio"
                                name="Address"
                                value={index}
                                onChange={handleChange}
                                id={index}
                              ></input>
                              <span>
                                <label htmlFor={index}> {item.name && <>
                                  {item.name} <br />
                                </>}
                                  {item.addressline1} , {item.addressline2} , {item.landmark} , {item.district} , {item.state} , {item.pincode}
                                  <br />
                                  {item?.phone}
                                </label>

                              </span>
                            </>

                          </div>

                        </div>
                      );
                    })}
                </div>

            }

            <div className="Address-modal-coupon-enter-button-last">
              <button onClick={handleContinue}>Continue</button>
              <button onClick={handleOpenModal}>Edit Address</button>
            </div>
          </div>
        </div>
      )}
      <EditAddressModal show={isModalOpen} onClose={handleCloseModal} address={props.selectedAddress}  fetchAddress={props.fetchAddress} HideModal={HideModal}/>
    </React.Fragment>
  );
}
