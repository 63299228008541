import React, { useState, useContext, useEffect } from 'react';
import { useCallback } from 'react';
import api from './api/api';

import apiclient from './api/apiclient';
import productPage from './api/productPage';
import testing from './testing';
// import productPage from './api/productPage';

//rerenderd is happeing becuase of fetchproducts api  but context is rerendering only once

const ProductContext = React.createContext();

const ProductProvider = ({ children }) => {
  const [image, setImage] = React.useState();
  const [isError, setIsError] = React.useState(false);
  const [loading,setLoading]=React.useState(true);
  const [rating, setRating] = React.useState();
  const [ratingModal, setRatingModal] = React.useState();
  const [productInfo, setProductInfo] = React.useState();
  const [IsServiceProviderApi, setIsServiceProviderApi] = React.useState();
  const [productData, setproductData] = React.useState([
    localStorage.getItem('productData').split(',')[2],
  ]);
  const [brandData, setbrandData] = React.useState([]);
  const [categoryData, setcategoryData] = React.useState([]);
  const [quantityData, setQuantityData] = React.useState([]);
  const [colorData, setColorData] = React.useState([]);
  const [priceData, setPriceData] = React.useState([]);
  // console.log(testing)
  const [serviceDetail, setServiceDetail] = React.useState();
  const [serviceDetail2, setServiceDetail2] = React.useState();
  const [filtersdata, setFiltersdata] = React.useState(); // store new filter data from api
  const [productDetail, setProductDetail] = React.useState();
  const [globalIndex, setGlobalIndex] = React.useState(0);
  //1 ->no more product  0->not called
  const [username, setUsername] = React.useState('');

  const fetchServiceDetail = async () => {
    if (username) {
      let index = 0;
      let arr = [];
      let response2;
      console.log('fetch service details');
      do {
        response2 = await productPage.GetProductData(
          username[0],
          username[1],
          index
        );
        console.log(response2);
        console.log(response2);
        // index += response2.data.product_list.length;
        arr.push(response2.data);
        console.log(index);
        console.log(arr);
      } while (index > 0 && index % 18 == 0);
      setServiceDetail(arr);
      
      console.log(serviceDetail);
    }
  };

  // const fetchServiceDetailFiltered = async () => {
  //   if (username) {
  //     let index = 0;
  //     let arr = [];
  //     let response2;
  //     console.log('fetch service details');
  //     do {
  //       response2 = await productPage.GetAllProductData(
  //         brandData,
  //         null,
  //         null,
  //         productData,
  //         quantityData,
  //         null,
  //         username[0],
  //         null,
  //         username[1],
  //         index
  //       );
  //       console.log(response2);
  //       console.log(response2);
  //       // index += response2.data.product_list.length;
  //       arr.push(response2.data);
  //       console.log(index);
  //       console.log(arr);
  //     } while (index > 0 && index % 18 == 0);
  //     setServiceDetail(arr);
  //     console.log(serviceDetail);
  //   }
  // };

  const fetchServiceDetailFiltered2 = async () => {
    if (username) {
      let index = 0;
      let arr = [];
      let response2;
      console.log('fetch service details');
      do {
        // response2 = await apiclient.SellerProducts(username[2],username[1],index);
        response2 = await productPage.GetFilteredProductData(
          brandData,
          priceData.length>0 ?parseInt(priceData[0]):null,
         priceData.length>0? parseInt(priceData[1]):null,
          productData,
          quantityData,
          null,
          username[0],
          null,
          username[1],
          index,colorData,
        );
        console.log(response2);
        console.log(response2);
        // index += response2.data.product_list.length;
        arr.push(response2.data);
        console.log(index);
        console.log(arr);
        if (arr[0].product_list.length < 18) {
          console.log('less than 18');
          setGlobalIndex(1);
        }
      } while (index > 0 && index % 18 === 0);
      setServiceDetail2(arr);
      console.log(serviceDetail2);
    }
  };

  //pagination 

  const fetchServiceDetailFilteredGlobalIndex = async () => {
    if (username) {
      console.log('global index called inside function');
      let index = globalIndex;
      let arr = [];
      let response2;
      console.log('fetch service details');
      // response2 = await apiclient.SellerProducts(username[2],username[1],index);
      console.log(username);
       response2 = await productPage.GetFilteredProductData(
         brandData,
         priceData.length>0 ?parseInt(priceData[0]):null,
         priceData.length>0? parseInt(priceData[1]):null,
         productData,
         quantityData,
         null,
         username[0],
         null,
         username[1],
         index
       );

      console.log(response2); 
      console.log(response2);
      // index += response2.data.product_list.length;
      arr.push(response2.data);
      console.log(index);
      console.log(arr);

      setServiceDetail2((prev) => {
        return [...prev, response2.data];
      });
      console.log(serviceDetail2);
      if (response2.data.product_list.length < 18) {
        console.log('less than 18');
        setGlobalIndex(1);
        return;
      }
    }
  };


  const fetchAllfilterdata=async()=>{
    if (username) {
      const response2 = await productPage.GetAllFiltersnewData(username[0],username[1])
      console.log(response2.data);
      if (response2.data.error !== '') {
        console.log('error', response2.data.error);
      } else {
        const {brand,quantity,producttype,color}=response2.data
        setFiltersdata({ brand, quantity, producttype,color });
        
  
    }
  }
  

}


  React.useEffect(() => {
    console.log('global index called inside useeffect');
    console.log(globalIndex);
    if (globalIndex !== 0 && globalIndex !== 1 && globalIndex % 18 === 0) {
      console.log('global index called inside useeffect if conditipn');
      fetchServiceDetailFilteredGlobalIndex();
    }
  }, [globalIndex]);

 

  React.useEffect(() => {
    // fetchIsServiceProviderAxpi();
    const isDefaultPriceData = priceData.length===0;
    if(!isDefaultPriceData){
      console.log("price data changes");
    const timeoutId = setTimeout(() => {
      fetchServiceDetailFiltered2();
    }, 800); // Change the delay time (in milliseconds) as needed

    // Cleanup function to clear the timeout if priceData changes again
    return () => clearTimeout(timeoutId);
  }
  else{
    console.log("filter normal");
    fetchServiceDetailFiltered2();
  }
  
    console.log('rendered effect');
    console.log(productData);

    setGlobalIndex(0)
  }, [brandData, productData, quantityData, priceData,colorData]);


  React.useEffect(() => {
    // fetchIsServiceProviderApi(username);
    // fetchServiceDetail();
    setPriceData([]);
    setproductData([
      parseInt(localStorage.getItem('productData').split(',')[2]),
    ]);
    setbrandData([]);
    setQuantityData([]);
    setColorData([])
    // fetchServiceDetailFiltered();
    
    setGlobalIndex(0)
    fetchAllfilterdata();

    console.log('rendered effect', username);
  }, [username]);

  console.log(serviceDetail, IsServiceProviderApi);
  return (
    <ProductContext.Provider
      value={{
        username,
        setUsername,
        image,
        setImage,
        rating,
        setRating,
        ratingModal,
        setRatingModal,
        IsServiceProviderApi,
        setIsServiceProviderApi,
        isError,
        setIsError,
        serviceDetail,
        setServiceDetail,
        quantityData,
        setQuantityData,
        productInfo,
        setProductInfo,
        categoryData,
        setcategoryData,
        brandData,
        setbrandData,
        productData,
        setproductData,
        priceData,
        setPriceData,
        productDetail,
        setProductDetail,
        serviceDetail2,
        setServiceDetail2,
        filtersdata,
        globalIndex,
        setGlobalIndex,
        loading,
        setLoading,colorData, setColorData
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};


// make sure use
export const useProductContext = () => {
  return useContext(ProductContext);
};

export { ProductProvider, ProductContext };
