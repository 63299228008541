import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css'; 
import './index.css';
import App from './App';

import { AppProvider } from './context';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Adminpanel from './Adminpanel';


const root = ReactDOM.createRoot(document.getElementById('root'));
  // window.screen.orientation.lock('portrait');
  const isAdminRoute = window.location.pathname.startsWith('/admin');
  root.render(
  <React.Fragment>
    {/* <script src='./js/controllers/variable.js'></script>
    <script src='./js/controllers/pageHandlers.js'></script>
    <script src='./js/controllers/login.js'></script>
    <script src='./js/controllers/aes.js'></script>
    <script src='./js/popper.js'></script>
    <script src='./js/apis/urls.js'></script> */}
   {!isAdminRoute ? ( <GoogleOAuthProvider clientId='234552605255-f6plqcaia9v3t8cqheqhrc7aiftsl6ag.apps.googleusercontent.com'>
      <AppProvider>
        <div className='desktop-view'>
          <App />
        </div>
 
      </AppProvider>

      {/* {getLocation2()} */}
      {/* {loginWithOTP} */}
    </GoogleOAuthProvider>
    ):
    <>
    <Adminpanel/>
    </>
    }
  </React.Fragment>
);
