import '../css/login-sidebar-forgotpass.css';
import React from 'react';
import api from '../api/api';
import { useGlobalContext } from '../context';
import logo from '../images/putatoepass1.svg';
import google from '../images/google.svg';
import eyeclose from '../images/eyeclose-pass.svg';
import eye from '../images/eye-pass.svg';
import arrow from '../images/arrowreset.svg';
import otpimage from '../images/otpVerificationimage.svg';
import presignup from '../aes';
import { BsArrowLeft } from 'react-icons/bs'
import forgotpassword from '../images/forgotpassword.svg';
import successpassword from '../images/successpassword.svg';
import resetpassword from '../images/resetpassword.svg';
import notify from '../error';
import profileapi from '../api/profileapi';

export default function LoginSidebarForgotPassword(props) {
  const {
    loginDetail,
    setLoginDetail,
    loginPage,
    setLoginPage,
    authtemp,
    setAuthtemp,
    mobileNumber,
    setMobileNumber,
    timer,
    setTimer,
    Userprofile,
    setUserProfile,
    loginModal,
    setLoginModal,
    otptoken,
    setOtptoken,
  } = useGlobalContext();

  const [Resetnewpassword, setResetnewpassword] = React.useState(true);
  const [Resetconfirmpassword, setResetconfirmpassword] = React.useState(true);
  const [newpassword, setNewpassword] = React.useState();
  const [confirmpassword, setConfirmpassword] = React.useState();
  const [display, setDisplay] = React.useState();
  const [localmobileNumber, setLocalMobileNumber] = React.useState();
  const [otp, setOtp] = React.useState({ 1: '', 2: '', 3: '', 4: '' });
  const [page, setPage] = React.useState(1);
  const arr = [1, 2, 3, 4];

  const wrapperRef = React.useRef(null);
  const resendref = React.useRef(null);
  const timeresendref = React.useRef(null);
  OutsideAlerter(wrapperRef);
  function OutsideAlerter(ref) {
    React.useEffect(() => {

      function handleClickOutside(event) {
        console.log(event.target)
        if (ref.current && !ref.current.contains(event.target)) {
          console.log(ref.current);
          // setDisplay(!display)
          setTimer(false)
          setLoginPage(0);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }


  const { loading, setLoading } = useGlobalContext();

  const UserLocation = async () => {
    setLoading(true);
    const response = await api.UserLocationApi();
    console.log(response);
    if ('token' in response.data) {
      console.log('yes authtoken set');
      localStorage.setItem('authtoken', response.data['token']);
      // let keyus=[data[keys]]
      console.log(response.data['token'], 'token');
      localStorage.setItem('userkey', JSON.stringify(response.data['keys']));
      console.log('presignup called');
      presignup();
      console.log(loading, 'afterpresignup');
      setOtptoken(localStorage.getItem("otptoken"))
      setLoading(false);
    }
    // presignup()
  };

  const ChangeEyeNewPass = () => {
    setResetnewpassword(!Resetnewpassword);
  };

  const ChangeEyeConfirmPass = () => {
    setResetconfirmpassword(!Resetconfirmpassword);
  };

  const ChangePageLogin = () => {
    setPage(1);
  };
  const ChangePageNewAccount = () => {
    setPage(2);
  };
  //  console.log(props)
  // const [display, setDisplay] = React.useState(props.view);
  React.useEffect(() => {
    // setDisplay(props.view)
  }, [props.view]);
  // console.log(display)
  // const onCross = () => {
  //   props.onChange();
  //   // setDisplay('login-sidebar-container-none');
  // };

  // const displayOTPBox = async () => {
  //   console.log(display);
  //   if (checkValidMobileNumber()) {
  //     console.log('here', mobileNumber);
  //     setDisplay(!display);
  //   } else {
  //     alert('invalid mobile');
  //   }
  //   const response = await api.LoginWithOtpMobile('+91' + mobileNumber);
  //   console.log(response);
  //   if (response && response.data.token) {
  //     setAuthtemp(response.data.token);
  //   }
  // };

  function editMobileNumber(e) {
    const val = e.target.value;
    setLocalMobileNumber(val);
    // console.log(localmobileNumber);
  }

  function editNewPassword(e) {
    const val = e.target.value;
    setNewpassword(val);
    // console.log(newpassword);
  }

  function editConfirmPassword(e) {
    const val = e.target.value;
    setConfirmpassword(val);
    // console.log(confirmpassword);
  }

  const checkotp = async () => {
    let str = `${otp[1]}${otp[2]}${otp[3]}${otp[4]}`;
    // setTimer(true)
    console.log(str);
    console.log(authtemp);

    //conditions
    if (loginPage === 10) {
      const response = await api.ConfirmOtp(str, authtemp);
      console.log(response);
      console.log('rea');
      if ((response.data.error === '')) {

        localStorage.setItem('authtoken', response.data.token);
        console.log(localStorage.getItem('authtoken'));
        presignup();
        setLoginModal(true);
        const myTimeout = setTimeout(getProfileAfterLogin, 5000);
        // notify('success','login successful');
        setLoginDetail(response.data);
        setLoginPage(0);
      }
      else {
        notify('error', response.data.error);
      }
    }
    else if (loginPage === 9) {
      console.log(localStorage.getItem('prevtoken'), localStorage.getItem('authtoken'), str);
      const response = await api.ConfirmRegister(localStorage.getItem('prevtoken'), localStorage.getItem('authtoken'), str)
      console.log(response);

      console.log('usercreation')
      if (response.data.error === '') {
        localStorage.setItem('authtoken', response.data.token);
        console.log(localStorage.getItem('authtoken'));
        presignup();
        notify('success', 'account created successfuly')
        const myTimeout = setTimeout(getProfileAfterLogin, 5000);
        setLoginDetail(response.data);
        // setUserProfile(response.data);
        setLoginPage(0);
      } else {
        notify('error', response.data.error);
        UserLocation();
      }
    }
    else if (loginPage === 11) {
      const response = await profileapi.ConfirmUpdateProfileMobile(localStorage.getItem('mob'), str)
      console.log(response);
      console.log('rea');
      if (response.data.error === '') {
        // localStorage.setItem('authtoken', response.data.token);
        // console.log(localStorage.getItem('authtoken'));
        // presignup();
        const myTimeout = setTimeout(getProfileAfterLogin, 5000);
        notify('success', 'Mobile Number Updated successful');
        // setLoginDetail(response.data);
        setLoginPage(0);
      } else {
        notify('error', response.data.error);
      }
    }



    console.log(loginDetail);
    console.log('cehcked');
  };


  const { changeInCart, setChangeInCart } = useGlobalContext();
  const [abc, setAbc] = React.useState();
  const getProfileAfterLogin = async () => {
    const response2 = await api.ProfileApi();
    console.log(response2);
    setAbc(response2.data);
    if (response2.data.error === '') {
      setLoginPage(0);
      setChangeInCart(!changeInCart);
      setLoading(false);
      notify('success', 'successful login');
    } else {
      notify('error', 'login failed');
    }
    // localStorage.setItem('user', response2.data);
    console.log(abc);
  };
  React.useEffect(() => {
    console.log(abc);
    if (abc) {
      setUserProfile(abc);
      console.log(abc)
      localStorage.setItem('UserProfile', JSON.stringify(abc));
    }
  }, [abc]);

  const updateotp = (e) => {
    const name = e.target.name;

    const val = e.target.value.charAt(0);
    setOtp((prev) => {
      return { ...prev, [name]: val };
    });
    console.log(otp);
  };

  const checkValidMobileNumber = () => {
    console.log('here', localmobileNumber);
    if (localmobileNumber.length === 10) {
      return true;
    } else {
      return false;
    }
  };
  // const onCrossOtp = () => {
  //   setDisplay(!display);
  // };

  const ClickContinue = async () => {

    setMobileNumber(localmobileNumber)
    const response = await ClickContinue2();
    if (response === undefined) {
      return;
    } else {
      setLoginPage(3);
    }


    console.log(loginPage);
  };

  const ClickContinue2 = async () => {
    const response = await api.ForgotPasswordMobile('+91' + localmobileNumber);
    console.log(response);
    if (response.data.error || response.data.status === false) {
      notify("error", response.data.error)
      return;
    }
    else {
      setAuthtemp(response.data.token);
      return "success";
    }
  };

  const ClickSubmit = () => {
    if (loginPage === 3) {
      if (!otp['1'] || !otp['2'] || !otp['3'] || !otp['4']) {
        notify('warn', 'Please enter the OTP');
        return;
      } else {
        setLoginPage(4);
      }

    } else if (loginPage === 10) {
      checkotp();
    }
    else if (loginPage === 9) {
      console.log('register')
      checkotp();
    }
    else if (loginPage === 11) {
      checkotp()
    }
  };

  const ClickReset = async () => {
    // setLoginPage(5);
    let str = `${otp[1]}${otp[2]}${otp[3]}${otp[4]}`;
    if (!newpassword || !confirmpassword) {
      notify('warn', 'Password field can\'t be empty');
      return;
    }
    console.log(str);
    console.log(authtemp);
    if (newpassword === confirmpassword) {
      const response = await api.ChangePassWordForgot(authtemp, str, newpassword)
      console.log(response)
      if (response.data.status === true) {
        setLoginPage(5)
      }
      else {
        notify('error', response.data.error)
      }
    }
    else {
      notify('error', 'password doesn\'t not match')
      setLoginPage(4)
    }


  };
  const ClickLogin = () => {
    setLoginPage(1);
  };

  const ResendOtpMobileFunc = async () => {
    setMobileNumber(mobileNumber);
    setSeconds(120)
    var letters = /^\d+$/;
    if (letters.test(mobileNumber)) {
      try {
        console.log("ksk", authtemp);
        // localStorage.setItem("authtoken",authtemp);
        const response = await api.LoginWithOtpMobile('+91' + mobileNumber);
        if (response && response.data.token) {
          setAuthtemp(response.data.token);
          setOtptoken(response.data.token);
          localStorage.setItem("otptoken", response.data.token);
          setTimer(true);
          setLoginPage(10);
          console.log("Re-send", response);
          notify("success", "otp is send to your number");
        }
        else if (response.data.status === false) {
          notify("error", response.data.error);
          return false;
        }
      }
      catch (exception) {
        console.log("no resend");
        return false;
      }
      // if (response && response.data.token) {
      //   setAuthtemp(response.data.token);
      //   setTimer(true);
      //   setLoginPage(10);
      // }
    } else {
      const response = await api.LoginWithOtpUserName(mobileNumber);
      console.log(response);
      if (response && response.data.token) {
        setAuthtemp(response.data.token);
        setTimer(true);
        setLoginPage(10);
      }
    }
    return true;
  };


  const userlocationresend = async () => {
    const response = await api.UserLocationApi();
    console.log(response);
    if ('token' in response.data) {
      console.log('yes authtoken set');
      localStorage.setItem('authtoken', response.data['token']);
      console.log(response.data['token'], 'token');
      localStorage.setItem('userkey', JSON.stringify(response.data['keys']));
      console.log('presignup called');
      presignup();
      console.log(localStorage.getItem("authtoken"))
    }
  }

  // timer

  const [seconds, setSeconds] = React.useState(120);
  //  React.useState(() => {
  //   //  const interval = setInterval(() => {

  //      console.log('run', timer);
  //      setSeconds(120);
  //      console.log(seconds);
  //   //  }, 1000);
  //   //  return () => {
  //   //    clearInterval(interval);
  //   //  };
  //  }, [seconds]);


  React.useEffect(() => {
    // console.log(seconds,timer)
    const interval = setInterval(() => {
      // console.log(timer)
      {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [seconds]);


  function giveseconds() {
    console.log('called')
    setSeconds(120)
  }









  const inputfocus = (elmnt) => {
    console.log(elmnt)


    if (elmnt.key === 'Delete' || elmnt.key === 'Backspace') {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {
        if (elmnt.target.previousElementSibling) {
          elmnt.target.previousElementSibling.focus();
        }

      }
    } else {
      console.log('next');
      // Check if the entered character is a digit
      const isDigit = /^[0-9]+$/.test(elmnt.target.value);

      if (!isDigit) {
        // If the entered character is not a digit, don't shift focus
        return;
      }

      const next = elmnt.target.tabIndex;
      if (next < 4) {
        elmnt.target.nextElementSibling.focus();
      }
    }
  };

  //  const otpref=React.useRef();

  let otptimerminute = React.useRef(0);
  let otptimersecond = React.useRef(59);

  let [otptimerminuteupdate, setOtptimermiupdate] = React.useState(otptimerminute.current);
  let [otptimersecondupdate, setOtptimerseupdate] = React.useState(otptimersecond.current);

  const otptimerfunc = () => {
    resendref.current.style.pointerEvents = "none";
    ResendOtpMobileFunc().then((status1) => {
      if (status1) {
        timeresendref.current.style.visibility = "visible";
        let otpminutetimer;
        let otpsecondtimer;
        otpminutetimer = setInterval(() => {
          if (otptimerminute.current > 0) {
            otptimerminute.current = otptimerminute.current - 1;
            setOtptimermiupdate(otptimerminute.current)
          }
        }, 60000);

        otpsecondtimer = setInterval(() => {
          if (otptimersecond.current === 0 && otptimerminute.current !== 0) {
            console.log("kkss1", otptimerminute.current, otptimersecond.current);
            otptimersecond.current = 60;
          }
          else if (otptimersecond.current === 0 && otptimerminute.current === 0) {
            console.log("kkss2", otptimerminute.current, otptimersecond.current);
            // resendref.current.addEventListener("click",otptimerfunc);
            clearInterval(otpminutetimer);
            clearInterval(otpsecondtimer);
            otptimerminute.current = 0;
            otptimersecond.current = 59;
            timeresendref.current.style.visibility = "hidden";
            resendref.current.style.pointerEvents = "auto";
            resendref.current.style.color = "black";
            resendref.current.style.cursor = "pointer";
          }
          if (otptimersecond.current > 0) {
            otptimersecond.current = otptimersecond.current - 1;
            setOtptimerseupdate(otptimersecond.current)
          }
        }, 1000);
        userlocationresend();
        console.log(resendref.current.style)
        resendref.current.style.cursor = "not-allowed";
        // resendref.current.style.pointerEvents="none";
        resendref.current.style.color = "grey";
      }
    })
    // if(ResendOtpMobileFunc()){
    // }
  }

  return (
    <React.Fragment>
      {loginPage === 2 && (
        <div className={props.view} ref={wrapperRef}>
          <div className='login-sidebar-forgot-heading mt-4'>
            <div >Forgot Password?</div>
            <div></div>
          </div>
          <div className='login-sidebar-forgot-text'>
            Please enter registered phone number.
          </div>
          <div className='login-sidebar-forgot-inputs'>
            <input
              placeholder='Enter the phone Number'
              type='text'
              pattern='[1-9]{1}[0-9]{9}'
              size='1'
              maxLength='10'
              value={localmobileNumber}
              onChange={editMobileNumber}
            ></input>
          </div>
          <div className='login-sidebar-forgot-continue-button'>
            <button onClick={ClickContinue}>Continue</button>
          </div>
        </div>
      )}
      {(loginPage === 3 || loginPage === 10 || loginPage === 9 || loginPage === 11) && {
        giveseconds,
      } && (

          <div className={props.view} ref={wrapperRef}>
            <div className='login-sidebar-back-image'>
              <BsArrowLeft
                // className=' fs-2 '
                style={{ fontSize: '24px', position: 'relative', top: "20px", left: "20px" }}
                onClick={() => {
                  setLoginPage(1);
                }}
              />
            </div>

            <div className='login-sidebar-forgot-image'>
              <img src={otpimage} alt='varify otp '></img>
            </div>
            <div className='login-sidebar-forgot-heading mx-auto'>OTP VERIFICATION</div>
            <div className='login-sidebar-forgot-text'>
              Enter the OTP sent to your registered number
            </div>
            <div className='login-sidebar-forgot-inputs-otp'>
              {arr.map((item) => {
                return (
                  <input
                    name={item}
                    value={otp.item}
                    onChange={updateotp}

                    onKeyDown={(e) => {
                      // Prevent any action for non-digit characters and limit length to 1
                      if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
                        e.preventDefault();
                      }


                    }}

                    autoComplete='off'
                    type='text'
                    className={`input-otp-${item}`}
                    size='1'
                    pattern='[0-9]{1}'
                    maxLength='1'
                    onKeyUp={inputfocus}
                    tabIndex={item}
                  ></input>
                );
              })}
            </div>
            {/* <div className='login-sidebar-forgot-time'>00:{seconds} Sec</div> */}
            <div className='login-sidebar-forgot-resend my-3'>
              Don’t receive code ?
              <span onClick={() => {
                otptimerfunc();
                resendref.current.removeEventListener("click", otptimerfunc);
              }} ref={resendref}> Re-send</span>
            </div>
            <div className='otptimer d-none' ref={timeresendref}>
              <div className='otptimerremain'>Time remaining:&#160;</div>
              <div className='otptimermainsection'>
                {/* String(otptimerminute.current).padStart(2, '0') */}
                <div className='otptimerminutesection'>{String(otptimerminute.current).padStart(2, '0')}</div>:<div className='otptimersecondsection'>{String(otptimersecond.current).padStart(2, '0')}</div>
              </div>
            </div>

            <div className='login-sidebar-forgot-continue-button mb-5'>
              <button onClick={ClickSubmit}>Submit</button>
            </div>
          </div>
        )}
      {(loginPage === 20) && {
        giveseconds,
      } && (
          <div className={props.view} ref={wrapperRef}>
            <div className='login-sidebar-back-image'>
              <BsArrowLeft />
            </div>
            <div className='login-sidebar-forgot-image mx-auto'>
              <img src={otpimage} alt=''></img>
            </div>
            <div className='login-sidebar-forgot-heading'>OTP VERIFICATION</div>
            <div className='login-sidebar-forgot-text'>
              Enter the OTP sent to - +91-{mobileNumber}
            </div>
            <div className='login-sidebar-forgot-inputs-otp'>
              {arr.map((item) => {
                return (
                  <input
                    name={item}
                    value={otp.item}
                    onChange={updateotp}
                    autoComplete='off'
                    type='text'
                    onKeyDown={(e) => {
                      // Prevent any action for non-digit characters and limit length to 1
                      if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
                        e.preventDefault();
                      }


                    }}
                    className={`input-otp-${item}`}
                    size='1'
                    pattern='[0-9]{1}'
                    maxLength='1'
                    tabIndex={item}
                  ></input>
                );
              })}
            </div>
            <div className='login-sidebar-forgot-time'>00:{seconds} Sec</div>
            <div className='login-sidebar-forgot-resend '>
              Don’t receive code ?
              <span onClick={ResendOtpMobileFunc}>Re-send</span>
            </div>
            <div className='login-sidebar-forgot-continue-button'>
              <button onClick={ClickSubmit}>Submit</button>
            </div>
          </div>
        )}
      {loginPage === 4 && (
        <div className={props.view} ref={wrapperRef}>
          <div className='login-sidebar-forgot-heading'>
            Reset your password
          </div>
          <div className='login-sidebar-forgot-text'>
            Here’s a tip: Use a combination of Numbers, Uppercase, lowercase and
            Special characters
          </div>
          <div className='login-sidebar-forgot-inputs-reset'>
            {Resetnewpassword ? (
              <fieldset>
                <legend>New Password</legend>
                <input
                  placeholder='eg. myamazingpass1'
                  type='password'
                  pattern='[1-9]{1}[0-9]{9}'
                  size='1'
                  value={newpassword}
                  onChange={editNewPassword}
                ></input>
                <img src={eye} onClick={ChangeEyeNewPass}></img>
              </fieldset>
            ) : (
              <fieldset>
                <legend>New Password</legend>
                <input
                  placeholder='eg. myamazingpass1'
                  type='text'
                  pattern='[1-9]{1}[0-9]{9}'
                  size='1'
                  value={newpassword}
                  onChange={editNewPassword}
                ></input>
                <img src={eyeclose} onClick={ChangeEyeNewPass}></img>
              </fieldset>
            )}
            {Resetconfirmpassword ? (
              <fieldset>
                <legend>Re-Enter New password</legend>
                <input
                  placeholder='Confirm password'
                  type='password'
                  pattern='[1-9]{1}[0-9]{9}'
                  size='1'
                  value={confirmpassword}
                  onChange={editConfirmPassword}
                ></input>
                <img src={eye} onClick={ChangeEyeConfirmPass}></img>
              </fieldset>
            ) : (
              <fieldset>
                <legend>Re-Enter New password</legend>
                <input
                  placeholder='Confirm password'
                  type='text'
                  pattern='[1-9]{1}[0-9]{9}'
                  size='1'
                  value={confirmpassword}
                  onChange={editConfirmPassword}
                ></input>
                <img src={eyeclose} onClick={ChangeEyeConfirmPass}></img>
              </fieldset>
            )}
          </div>
          <div className='login-sidebar-forgot-continue-button'>
            <button onClick={ClickReset}>Reset Password</button>
          </div>
        </div>
      )}
      {loginPage === 5 && (
        <div className={props.view} ref={wrapperRef}>
          <div className='login-sidebar-forgot-success'>Reset successful</div>
          <div className='login-sidebar-forgot-success-text'>
            You can now log in to your account
          </div>
          <div className='login-sidebar-forgot-success-continue-button'>
            <button onClick={ClickLogin}>
              Login Now<img src={arrow}></img>
            </button>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
