import React, { Component } from 'react';
import myProfileServiceApi from '../../api/myProfileServiceApi';
import notify from '../../error';
import { useGlobalContext } from '../../context';
import './css/DeleteServiceProviderAccount.css';
import back from '../myprofileIcoons/bx-arrow-back..svg';
import { motion } from 'framer-motion';
import dustbin from '../../imageMobile/trash-bin-svgrepo-com.svg';
import profileapi from '../../api/profileapi';
import api from '../../api/api';
import { useNavigate } from 'react-router-dom';

export default function DeleteServiceProviderAccount(props) {
  const { Userprofile, setUserProfile,setInnerPage} = useGlobalContext();
  const navigate = useNavigate();
  function goBack(){
    setInnerPage(false)
   props.return();
  }

  const Delete=async()=>
  {

   const response = await myProfileServiceApi.DeleteServiceProvider() ;
   console.log(response);

   if (response.data && response.data.error === '') 
   {
    // Call the profile API here
       const response2 = await api.ProfileApi();
       if (response2.data.error === "") {
        setUserProfile(response2.data)
       localStorage.setItem("UserProfile", JSON.stringify(response2.data));
       goBack();
       navigate("/");
       notify("success","Service Provider Deleted Successfully");
       }
  }

  }


  return (
    <React.Fragment>
      <>
        <div className='manage-services-profile-service-main-page'>
          <div className='my-service-profile-ongoing-and-pending-online-back'>
            <img src={back} onClick={goBack}></img>
          </div>
          <div className='delete-service-provider-profile-service-main-page'>
            <span>Do You Want To Delete Your Service Provider Account ?</span>
            <div className='delete-service-provider-profile-service-main-page-buttons'>
             <button onClick={goBack}>NO</button>
             <button onClick={Delete}>YES</button>
            </div>
          </div>
        </div>
      </>
    </React.Fragment>
  );
}
