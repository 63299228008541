import axios from "axios";
//company
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

class PostService {
  // uploading to the server
  UploadImageOGCforADDproduct(files, detail) {
    const fd = new FormData();
    if ( Array.isArray(Object.keys(files))) {
      for (const key in files) {
        if (files.hasOwnProperty(key)) {
          fd.append("files", files[key]);
        }
      }
    }  else {
      fd.append("files", files);
    }
    // console.log(files);
  
    return axios.post(API_BASE_URL + "/v1/api/multiple_uploadImagesOGC", fd, {
      headers: {
        authtoken: localStorage.getItem("authtoken"),
      },
    });
  }
  
  UploadImageOGC(files, detail) {
    const fd = new FormData();
    fd.append("files", files, "/path/to/file");
    return axios.post(API_BASE_URL + "/v1/api/uploadImagesOGC", fd, {
      headers: {
        authtoken: localStorage.getItem("authtoken"),
      },
    });
  }
  diplayAllBlogs(index) {
    return axios({
      method: "GET",
      url: API_BASE_URL + `/v1/api/displayBlog/${index}`,
      headers: {
        authtoken: localStorage.getItem("authtoken"),
        "Content-Type": "application/json",
      },
    });
  }
  displaySingleBlog(blogId) {
    return axios({
      method: "GET",
      url: API_BASE_URL + `/v1/api/singleblog/${blogId}`,
      headers: {
        authtoken: localStorage.getItem("authtoken"),
        "Content-Type": "application/json",
      },
    });
  }
  displayOwnBlog(index) {
    return axios.get(API_BASE_URL + `/v1/api/displayOwnBlog/${index}`, {
      headers: {
        authtoken: localStorage.getItem("authtoken"),
        "Content-Type": "application/json",
      },
    });
  }
  AddBlog(title, description, body, category, imgUrl) {
    console.log(imgUrl);
    const fd = new FormData();
    fd.append("hadline", title);
    fd.append("dec", "something");
    fd.append("detail", body);
    fd.append("category_id", category);
    fd.append("publication_id", 1);
    fd.append("files", imgUrl);
    fd.append("sheep", imgUrl);
    return axios.post(API_BASE_URL + "/v1/api/addToBlog", fd, {
      headers: {
        authtoken: localStorage.getItem("authtoken"),
      },
    });
  }
  AddBlogComment(id, comment) {
    return axios.post(
      API_BASE_URL + "/v1/api/addBlogComment",
      { id: id, comment: comment },
      {
        headers: {
          authtoken: localStorage.getItem("authtoken"),
        },
      }
    );
  }
  FetchBlogComment(id) {
    return axios.post(
      API_BASE_URL + "/v1/api/displayBlogComments",
      { id: id },
      {
        headers: {
          authtoken: localStorage.getItem("authtoken"),
        },
      }
    );
  }
  setBlogLike(id) {
    return axios.get(API_BASE_URL + `/v1/api/likeBlog/${id}`, {
      headers: {
        authtoken: localStorage.getItem("authtoken"),
      },
    });
  }
  setBlogDislike(id) {
    return axios.post(
      API_BASE_URL + "/v1/api/unlikeblog",
      { blog_id: id },
      {
        headers: {
          authtoken: localStorage.getItem("authtoken"),
        },
      }
    );
  }
  AllPromotion(index) {
    return axios.get(API_BASE_URL + `/v1/api/displayPost/${index}`, {
      headers: {
        authtoken: localStorage.getItem("authtoken"),
        "Content-Type": "application/json",
      },
    });
  }
  DeletePost(is_comment, id) {
    return axios.post(
      API_BASE_URL + "/v1/api/deletePost", //0 for post //1 for comment
      { is_comment, id },
      {
        headers: {
          authtoken: localStorage.getItem("authtoken"),
        },
      }
    );
  }
  SharePost(post_id) {
    return axios.post(
      API_BASE_URL + "/v1/api/SharePost",
      { post_id },
      {
        headers: {
          authtoken: localStorage.getItem("authtoken"),
        },
      }
    );
  }
  MyPromotion(index) {
    return axios.get(API_BASE_URL + "/v1/api/displayOwnPost/" + index, {
      headers: {
        authtoken: localStorage.getItem("authtoken"),
        "Content-Type": "application/json",
      },
    });
  }
  NumberofLike(id) {
    return axios.get(API_BASE_URL + "/v1/api/postLike/" + id, {
      headers: {
        authtoken: localStorage.getItem("authtoken"),
        "Content-Type": "application/json",
      },
    });
  }
  LikeWithoutLogin(id) {
    return axios.get(API_BASE_URL + "/v1/api/LikePostNotUser/" + id, {
      headers: {
        authtoken: localStorage.getItem("authtoken"),
        "Content-Type": "application/json",
      },
    });
  }
  LikePost(id) {
    return axios.get(API_BASE_URL + "/v1/api/postLike/" + id, {
      headers: {
        authtoken: localStorage.getItem("authtoken"),
        "Content-Type": "application/json",
      },
    });
  }
  DislikePost(id) {
    return axios.get(API_BASE_URL + "/v1/api/postDislike/" + id, {
      headers: {
        authtoken: localStorage.getItem("authtoken"),
        "Content-Type": "application/json",
      },
    });
  }
  EditPromotion(id, details, url) {
    console.log(url);
    return axios.post(
      API_BASE_URL + "/v1/api/EditPost/" + id,
      { details: details, url: `${url}` },
      {
        headers: {
          authtoken: localStorage.getItem("authtoken"),
        },
      }
    );
  }

  AddPromotion(files, detail) {
    const fd = new FormData();
    fd.append("files", files);
    fd.append("detail", detail);
    return axios.post(API_BASE_URL + "/v1/api/addToPost", fd, {
      headers: {
        authtoken: localStorage.getItem("authtoken"),
      },
    });
    // console.log(files,detail);
  }
  CommentPost(id, detail) {
    return axios.post(
      API_BASE_URL + "/v1/api/commentOnPost",
      { id, detail },
      {
        headers: {
          authtoken: localStorage.getItem("authtoken"),
        },
      }
    );
  }
  EditCommentPost(id, comment) {
    return axios.post(
      API_BASE_URL + "/v1/api/modifyPostComment",
      { id, comment },
      {
        headers: {
          authtoken: localStorage.getItem("authtoken"),
        },
      }
    );
  }
  DeleteCommentPost(id, comment) {
    return axios.post(
      API_BASE_URL + "/v1/api/DeletePostComment",
      { id, comment },
      {
        headers: {
          authtoken: localStorage.getItem("authtoken"),
        },
      }
    );
  }
  DisplayCommentPost(index) {
    return axios.get(API_BASE_URL + "/v1/api/displayPostComment/" + index, {
      headers: {
        authtoken: localStorage.getItem("authtoken"),
      },
    });
  }
  SinglePost(id) {
    return axios.get(API_BASE_URL + "/v1/api/single_post/" + id, {
      headers: {
        authtoken: localStorage.getItem("authtoken"),
      },
    });
  }
  BlockPost(userid) {
    return axios.post(
      API_BASE_URL + "/v1/api/Block_promotions",
      { post_userid: userid },
      {
        headers: {
          authtoken: localStorage.getItem("authtoken"),
        },
      }
    );
  }

  // UploadImageOGC(files,detail) {
  //   const fd = new FormData();
  //   fd.append("files",files, "/path/to/file");
  //   return axios.post(
  //     API_BASE_URL + '/v1/api/uploadImagesOGC',
  //     fd,
  //     {
  //       headers: {
  //         authtoken: localStorage.getItem('authtoken'),
  //       },
  //     }
  //   );
  // }

  GetAllblogs(index = 0) {
    return axios.get(API_BASE_URL + "/v1/api/displayBlog/" + index, {
      headers: {
        authtoken: localStorage.getItem("authtoken"),
      },
    });
  }

  DeleteBlog(id) {
    return axios.get(API_BASE_URL + "/v1/api/deleteBlog/" + id, {
      headers: {
        authtoken: localStorage.getItem("authtoken"),
      },
    });
  }

  EditBlog(Id, Headline, Description, Details, CategoryId, Url) {
    return axios.post(
      API_BASE_URL + "/v1/api/EditBlog/" + Id,
      {
        headline: Headline,
        description: "anything",
        details: Details,
        category_id: CategoryId,
        url: Url,
      },
      {
        headers: {
          authtoken: localStorage.getItem("authtoken"),
        },
      }
    );
    // console.log(Id,Headline,Description,Details,CategoryId,Url);
  }

  shareBlog(blogId, subData) {
    const fd = new FormData();
    fd.append("blog_id", blogId);
    fd.append("dec", subData);
    return axios.post(API_BASE_URL + "/v1/api/shareblog", fd, {
      headers: {
        authtoken: localStorage.getItem("authtoken"),
      },
    });
    // console.log(blogId,subData);
  }

  modifyBlogComment(commentId, comment) {
    return axios.post(
      API_BASE_URL + "/v1/api/modifyBlogComment",
      {
        id: commentId,
        comment: comment,
      },
      {
        headers: {
          authtoken: localStorage.getItem("authtoken"),
        },
      }
    );
    // console.log(blogId,comment);
  }

  deleteBlogComment(commentId) {
    return axios.post(
      API_BASE_URL + "/v1/api/deleteBlogComment",
      {
        id: commentId,
      },
      {
        headers: {
          authtoken: localStorage.getItem("authtoken"),
        },
      }
    );
  }
  getdeliverypersoninfo(){
    return axios.get(API_BASE_URL + "/v1/api/GetDeliverPersonDetails",{
      headers: {
        authtoken: localStorage.getItem("authtoken"),
      },
    })
  }
}

export default new PostService();
