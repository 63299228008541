
import React, { useId, useState } from "react";
import postapi from "../../api/postapi";
import { useGlobalContext } from "../../context";
import notify from "../../error";
import "../blog-css/blogComment.css";
import user from "../../images/blogimages/user.png";
import more from "../../images/blogimages/more.svg";


export default function BlogComment(props) {
    const { setBlogUpdate, Userprofile } = useGlobalContext();
    const [display, setDisplay] = useState(false);
    const [editComment, setEditComment] = useState(false);
    const [newComment, setNewComment] = useState(props.comment);
    const date = new Date(props.date);
    const formattedDate = date.toLocaleString();
    const formattedTime = date.toLocaleTimeString([], { hour: 'numeric', minute: 'numeric' });

    const modalRef = React.useRef(null);
    React.useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            setDisplay(false);
        }
    };

    const handleDotsClick = (userId,blogOwnerId) => {
        if (Userprofile.user_id === userId || Userprofile.user_id === blogOwnerId ) {
            console.log(userId,blogOwnerId,Userprofile.user_id);
            if(display){
                setDisplay(false);
                return;
            }
            setDisplay(true);
        }
        else{
            notify('warn','You can not edit comment...');
        }
        // setDisplay(!display);
    }

    const handleEnterPress = async (event, commentId, comment) => {
        if (event.key === 'Enter') {
            // console.log(props);
            setEditComment(false);
            try {
                const response = await postapi.modifyBlogComment(commentId, comment);
                if (response.status === 200) {
                    notify('success', 'Comment editted successfully...');
                    setBlogUpdate((prevState) => !prevState);
                }
            } catch (error) {
                console.log(error);
                notify('error', 'Something went wrong');
            }
        }
        if (event.key === 'Escape') {
            setEditComment(false);
        }
    }

    const handleEditClick = () => {
        setEditComment(true);
        setDisplay(false);
    }
    const handleDeleteClick = async(commentId) => {
        setDisplay(false);
        try{
            const response = await postapi.deleteBlogComment(commentId);
            if(response.status === 200){
                setBlogUpdate((prevState) => !prevState);
                notify('success',"Comment deleted successfully");
            }
        }catch(error){
            console.log("deleted");
        }
    }

    return (
        <section className="commentContainer commentcontainer1">
            <div className="commentUser">
                <img src={props.profileImage || user} alt="profileImage" />
            </div>
            <div className="commentData">
                <h6>{props.username}</h6>
                {/* <h6>{formattedDate}</h6> */}
                {editComment
                    ?
                    <input type="text" autoFocus value={newComment} onChange={(e) => setNewComment(e.target.value)} onKeyDown={(e) => { handleEnterPress(e, props.commentId, newComment) }} />
                    :
                    <p>{props.comment}</p>
                }
            </div>
            <div className="commentDots" onClick={(e) => handleDotsClick(props.userId,props.blogOwnerId)}>
                <img className='moreIcon' src={more} alt='more_icon' />
            </div>
            {display &&
                <div className="moreOptionModal">
                    <p className="editclick" onClick={() => handleEditClick()}>Edit</p>
                    <p onClick={() => handleDeleteClick(props.commentId)} className="deleteclick">Delete</p>
                </div>
            }
        </section>
    )
}