import React, { useEffect, useState } from 'react'
import back from "../myprofileIcoons/bx-arrow-back..svg";
import couponapi from "../../api/couponapi"
import { useGlobalContext } from '../../context';
import notify from '../../error';
import "../MyServices/css/ManageCouponstyle.css"
import Loading from '../../components/Loading';
import Addcoupon from './Addcoupon';
import { FaEdit } from "react-icons/fa";

import dustbin from "../../imageMobile/trash-bin-svgrepo-com.svg";
const Managecoupon = (props) => {
  // console.log("ssjjsj")
  const { setInnerPage, isMobileView } = useGlobalContext();
  const { initial, setinitial } = useState(true);
  const [coupon, setCoupon] = useState([]);
  const [index, setindex] = useState(0);
  const [loadingcoupon, setLoadingcoupon] = useState(true);
  const [loadingcoupon1, setLoadingcoupon1] = useState(false);
  const [addcouponpressed, setAddcouponpressed] = useState(false);
  const [emptyFetch, setEmptyFetch] = useState(false);

  const observer = React.useRef();
  const markerRef = React.useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && emptyFetch === false) {
          setindex((prevStartIndex) => prevStartIndex + 18);
          setLoadingcoupon1(true);
        }
        console.log(entries, entries[0]);
        // SetScrollset(false);
        if (emptyFetch) setLoadingcoupon1(false);
      });
      if (node) observer.current.observe(node);
    },
    [setindex, emptyFetch]
  );
  function goBack() {
    props.return();
    setInnerPage(false)
    setinitial(true);
  }
  const apicallforcoupon = async () => {
    const response = await couponapi.couponsaved(index);
    console.log(response);
    if (response.data && response.data.error === "") {
      setCoupon([...coupon, ...response.data.all_coupon])
    }
    else {
      notify("error", response.data.error)
    }
    if (response && response.data.all_coupon.length === 0) {
      setEmptyFetch(true);
    }
    setLoadingcoupon(false);
    setLoadingcoupon1(true);
  }

  const apicallforcouponclone = async () => {
    const response = await couponapi.couponsaved(0);
    console.log(response);
    if (response.data && response.data.error === "") {
      setCoupon([...response.data.all_coupon])
    }
    else {
      notify("error", response.data.error)
    }
    if (response && response.data.all_coupon.length === 0) {
      setEmptyFetch(true);
    }
    setLoadingcoupon(false);
    setLoadingcoupon1(true);
  }

  const apicallforcoupon1 = async () => {
    const response = await couponapi.couponsaved(0);
    console.log(response);
    if (response.data && response.data.error === "") {
      setCoupon([...response.data.all_coupon])
    }
    else {
      notify("error", response.data.error)
    }


    setLoadingcoupon(false);
    setLoadingcoupon1(true);
  }
  const apicallfordelete = async (coupon_id) => {
    const response = await couponapi.coupondelete(coupon_id);
    console.log(response);
    if (response.data && response.data.error === "") {
      // setCoupon([...coupon,...response.data.all_coupon])
      setLoadingcoupon(true);

      apicallforcoupon1();
    }
    else {
      notify("error", response.data.error)
    }

    // if (response && response.data.all_coupon.length === 0) {
    //   setEmptyFetch(true);
    // }
    // setLoadingcoupon(false);
    // setLoadingcoupon1(true);
  }

  useEffect(() => {
    apicallforcoupon();
  }, [index])

  useEffect(() => {
    if (!addcouponpressed && !initial) {
      apicallforcouponclone();
    }
  }, [addcouponpressed])

  const dateexpiry = (couponexpirydate) => {
    const date = new Date();

    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    let day1;
    let month1;
    const couponexpirydate1 = couponexpirydate.split("-");

    if ((couponexpirydate1[0] - year) < 0) {
      return false;
    }
    else if ((couponexpirydate1[0] - year) == 0 && (couponexpirydate1[1] - month) < 0) {
      return false;
    }
    return true;
  }

  return (
    <>
      {!addcouponpressed ? <>
        <div className='managecouponfront'>
          <div className='managecouponmainparent'>
            <div className="my-service-profile-ongoing-and-pending-online-back">
              <img src={back} onClick={goBack}></img>
            </div>
            <div className='managecoupontotalsection'>
              <div className="manage-services-profile-service-main-page-main-box-before">
                {coupon && (
                  <>
                    Total <span>{coupon.length}</span> Coupon(s)
                  </>
                )}
              </div>
              <div className="manage-services-profile-service-main-page-add-service">
                <button
                  style={{ marginRight: "10px" }}
                  onClick={() => {
                    setAddcouponpressed(true);
                  }}
                >
                  Add New Coupon
                </button>
              </div>
            </div>
          </div>

          {
            coupon && coupon.length == 0 && loadingcoupon ?
              <>
                <Loading></Loading>
              </> :
              <>
                {
                  (coupon && coupon.length > 0 && !loadingcoupon) ?
                    <>      <div className='maincouponcontent'>
                      {
                        coupon.map((item) => {
                          return (
                            <>
                              <div className='couponcontent'>
                                <div className='couponheading'>
                                  {item.coupon_name}
                                </div>
                                <div className='couponheading couponheading1'>
                                  {item.coupon_type}
                                </div>
                                <div className='couponinfocontent'>
                                  <div className='couponleft'>
                                    <div className='couponbaseprice'>
                                      <span className='couponbasepriceleft'>Base Price</span>: <span className='couponbasepriceright'>
                                        <span className='basepricebefore'>₹</span>
                                        {item?.base_price}</span>
                                    </div>
                                    <div className='couponbaseprice'>
                                      <span className='couponbasepriceleft'>Max Discount</span>: <span className='couponbasepriceright'> <span className='basepricebefore'>₹</span>{item.maximum_discount}</span>
                                    </div>
                                    {
                                      (item.discount_in_rupee || item.discount_in_percentage) &&
                                      <>
                                        <div className='couponbaseprice'>
                                          {item.discount_in_rupee ? <><span className='couponbasepriceleft'>Discount</span>:<span className='couponbasepriceright'><span className='basepricebefore'> ₹</span>{item.discount_in_rupee}</span></> :
                                            <>
                                              {
                                                item.discount_in_percentage && <><span className='couponbasepriceleft'>Discount</span>:<span className='couponbasepriceright'> {item.discount_in_percentage}%</span></>
                                              }
                                            </>
                                          }

                                        </div>


                                      </>
                                    }


                                  </div>
                                  <div className='couponright'>
                                    <div className='couponbaseprice'>
                                      <div className="manage-services-profile-service-main-page-main-box-card-price">
                                        <span>
                                          <img
                                            src={dustbin}
                                            onClick={() => { apicallfordelete(item.coupon_id) }}
                                            alt="dustbin"
                                          ></img>
                                        </span>
                                      </div>
                                    </div>

                                    {
                                      (item.cashback || item.cashback_in_percentage) &&
                                      <>
                                        <div className='couponbaseprice'>
                                          {item.cashback ? <><span className='couponbasepriceleft'>Cashback</span>:<span className='couponbasepriceright'><span className='basepricebefore'> ₹</span>{item.cashback}</span></> :
                                            <>
                                              {
                                                item.cashback_in_percentage && <><span className='couponbasepriceleft'>Cashback</span>:<span className='couponbasepriceright'> {item.cashback_in_percentage}%</span></>
                                              }
                                            </>
                                          }

                                        </div>


                                      </>
                                    }

                                    {
                                      item && item.is_userSpecific != null &&
                                      <div className='couponbaseprice'>
                                        <span className='couponbasepriceleft'>User specific</span>: <span className='couponbasepriceright'>
                                          {item.is_userSpecific ?
                                            <>
                                              <span className='yes'>Yes</span>
                                            </> :
                                            <>
                                              <span className='No'>No</span>
                                            </>}</span>
                                      </div>
                                    }

                                    {
                                      item && item.is_combo != null &&
                                      <div className='couponbaseprice'>
                                        <span className='couponbasepriceleft'>Avail for Combo</span>: <span className='couponbasepriceright'>
                                          {item.is_combo ?
                                            <>
                                              <span className='yes'>Yes</span>
                                            </> :
                                            <>
                                              <span className='No'>No</span>
                                            </>}</span>
                                      </div>
                                    }
                                  </div>
                                </div>
                                {
                                  item && item.expiry_date &&
                                  <div className='expirysection'>
                                    <div className='couponbaseprice'>
                                      <span className='couponbasepriceright'>
                                        {
                                          dateexpiry(item.expiry_date) ?
                                            <>
                                              <span className='couponbasepriceleft'>Expire date</span> :
                                              <span className='greendate'>
                                                {"  "}
                                                {item.expiry_date.split("-")[2]}-{item.expiry_date.split("-")[1]}-{item.expiry_date.split("-")[0]}
                                              </span></> : <>
                                              <span className='couponbasepriceleft'>Expire :</span>
                                              <span className='reddate'> Expired</span>
                                            </>
                                        }
                                      </span>
                                    </div>
                                  </div>
                                }
                              </div>
                            </>
                          )
                        })
                      }
                      <div>
                        {!emptyFetch &&
                          <div
                            className="marker"
                            style={{ minHeight: "200px" }}
                            ref={markerRef}
                          >
                          </div>
                        }
                        {loadingcoupon1 && !emptyFetch ? (
                          <Loading />
                        ) : (
                          <div className="allfetched">
                            "That's all we have"
                          </div>
                        )}
                      </div>
                    </div>
                    </> :
                    <>
                      {(coupon && coupon.length === 0 && !loadingcoupon) && <p className="noresult">No results to display
                      </p>}
                    </>
                }
              </>
          }

        </div></> :
        <>
          <Addcoupon backto={setAddcouponpressed} loaddingcoupon={(() => {
            setLoadingcoupon(true)
          })} coupon={() => {
            setCoupon([])
          }}></Addcoupon>
        </>}
    </>
  )
}

export default Managecoupon 