import React, { Component } from 'react';
import { useProductContext } from '../productPageContext.js';
import brick from '../imageMobile/brick.svg';
import '../serviceprovider-css/productImageModal.css';
import { FiX } from 'react-icons/fi';
export default function ProductImageModal() {
  const { image, setImage } = useProductContext();
  // console.log(image)
  const hide = () => {
    setImage();
  };
  console.log(image)

  const [index,setIndex]=React.useState(0);
  const changeIndex=(e)=>
  {
    console.log(e.target.name)
    setIndex(e.target.name)
  }

  return (
    <React.Fragment>
      <div className='product-Image-Modal-background' onClick={hide}></div>
      <div className='product-Image-Modal-container'>
      <div className='product-Image-Modal-close-icon' onClick={hide}>
          <FiX size={20} />
        </div>
        <div className='product-Image-Modal-image-container'>
          <img src={image[index]} alt=''/>
        </div>
        {image.length > 1 && (
          <div className='product-Image-Modal-image-sec-container'>
            {image.map((img, imgIndex) => (
              <div
                key={imgIndex}
                className="product-Image-Modal-image-sec"
                onClick={changeIndex}
              >
                <img src={img} name={imgIndex}   className={parseInt(index) === imgIndex ? 'bright-active-image' : ''} alt=''/>
              </div>
            ))}
          </div>
        )}
      </div>
    </React.Fragment>
  );
}
