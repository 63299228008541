import React from "react";
import Header from "./components/header";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
// import aes from './js/controllers/aes.js'
import $ from "jquery";
// import Sidebar from './components/sidebar';
// import Dashboard from './components/dashboard';
// import api from './api/api';
// import apiclient from './api/apiclient';
// import AllService from './components/allServices';
// import BestSellers from './components/bestSellers';
// import Footer from './components/footer';
// import PopularProductByService from './components/popularProductsByService';
// import Carousel from './components/carousel';
// import './app.css'
// import useScript from './useScript';
import { PostProvider } from "./postContext";
import AllPost from "./post-components/allPost";
import UserProfileOther from "./components/UserProfileOther";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import HomeView from "./HomeView";
import MobileApp from "./mobileApp";
import ServiceProviderView from "./serviceprovider-components/serviceProviderView";
import { ServiceProvider } from "./serviceContext.js";
import apiclient from "./api/apiclient";
// import MyPost from "./post-components/myPost";
// import SavedPost from "./post-components/savedPost";
import CreatePost from "./post-components/createPost";
import api from "./api/api";
import presignup from "./aes";
import { ProductProvider } from "./productPageContext";

import Chat from "./chat-componenets/Chat";
import Blog from "./components/blog-components/Blog";
// import axios from 'axios' ;
import Singleblog from "./components/blog-components/Singleblog";
import Myblog from "./myprofile/MyBlogs/Myblog";
import Createblog from "./components/blog-components/Createblog";
import Myprofile from "./myprofile/Myprofile";
// import presignup from './js/controllers/login';
// import { getLocation2 } from './js/location';
import { ToastContainer, toast } from "react-toastify";
import { useGlobalContext } from "./context";
import "react-toastify/dist/ReactToastify.css";
import ProductProviderView from "./product-components/productProviderView";
import PutatoeServiceView from "./PutatoeService-Components/PutatoeServiceView";
import PutatoeSubServiceView from "./PutatoeService-Components/PutatoeSubServiceView";
import CartMainViewPage from "./cart-componenets/CartMainViewPage";
import CartViewPage2 from "./cart-componenets/CartViewPage2";
import OrderConfirmation from "./cart-componenets/OrderConfirmation";
import News from "./news/News";
import MobileRechargeView from "./Mobile-reacharge/mobileReachargeview";
import DthRechargeViewPage from "./DthRecharge/DThlandingPageView";
import JobsViewPage from "./Jobs/JobsViewPage";
import LoanPage from "./Loans/LoanPageView";
import AboutUs from "./aboutUs/AboutUs";
import ContactUs from "./ContactUs/contactus";
import TermsANdCondition from "./TermsANdCondition/TermsANdCondition";
import CartViewPageRecharge from "./cart-componenets/CartViewPageRecharge";
import SearchDesktopMainView from "./SearchDesktop/SearchDesktopMainView";
import postapi from "./api/postapi";
import Imagetourl from "./components/imagetourl";
import LoginModal from "./components/LoginModal";
import Pagenotfound from "./components/PageNotFound/Pagenotfound";
import Footer from "./components/footer";
import ProductInfo from "./product-components/productInfo.js";
import PdfViewer from "./myprofile/MyServices/PdfViewer.js";



// function addScript(srct) {
//   const script = document.createElement('script');
//   script.src = srct;
//   // script.async = true;
//   console.log(script)
//   document.body.appendChild(script);
// }

// addScript('./js/controllers/variable.js');
// addScript('./js/controllers/login.js');
// addScript('./js/controllers/aes.js');
// addScript('./js/controllers/variable.js');
function App() {

  const currentVersion = "1.86";
  const { loading, setLoading, loginModal, otptoken, setOtptoken, token, setToken, } = useGlobalContext();


  if (!localStorage.getItem('serviceId')) {
    localStorage.setItem('serviceId', '6');
  }

  React.useEffect(() => {
    const storedAuthToken = localStorage.getItem("authtoken");
    const storedVersion = localStorage.getItem("version");

    if (
      storedVersion < currentVersion || !storedAuthToken ||
      storedAuthToken === "" ||
      !storedVersion

    ) {
      UserLocation();
      localStorage.clear();
      localStorage.setItem("version", currentVersion);
    }
  }, []);

  const UserLocation = async () => {

    const response = await api.UserLocationApi();
    console.log(new Date().toLocaleTimeString('en-IN', { timeZone: 'Asia/Kolkata', hour12: false, hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3 }));

    if ("token" in response.data && response.data["token"]) {
      // console.log("yes authtoken set");
      localStorage.setItem("authtoken", response.data["token"]);
      // let keyus=[data[keys]]
      console.log(response.data["token"], "token");
      localStorage.setItem("userkey", JSON.stringify(response.data["keys"]));
      // console.log("presignup called");
      presignup(setToken);
      // setToken(localStorage.getItem("authtoken"));

      console.log(loading, "afterpresignup");
      console.log("skksks", localStorage.getItem('authtoken'));
      // setOtptoken(localStorage.getItem("otptoken"));
      localStorage.setItem("isUserLocationCompleted", true);

      // setTimeout(() => {
      //   // setLoading(false);
      // }, 900);
    }
    // presignup()
  };

  const notify = () => {
    console.log("click");
    toast.warn("Wow so easy!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  // const TestCall=async()=>
  // {
  //   const response= await postapi.GetAllblogs();
  //   console.log(response);
  // }
  // React.useEffect(()=>
  // {
  //   TestCall();
  // },[])

  return (
    <>
      {token && <React.Fragment>
        <div className="App" style={{ maxHeight: loginModal && "100vh", overflow: loginModal && "hidden" }}>
          {loginModal && <LoginModal />}

         
          <Router>
            <Header />
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="colored"
            />
            <ScrollToTop></ScrollToTop>
            <Routes>
              <Route exact path="/" element={<HomeView />} />
              <Route
                exact
                path="/search"
                element={<SearchDesktopMainView></SearchDesktopMainView>}
              />
              <Route path="/recharge" element={<AllPost />}></Route>
              <Route
                path="/post"
                element={
                  <PostProvider>
                    <AllPost />
                  </PostProvider>
                }
              ></Route>
              <Route
                path="/createPost"
                element={
                  <PostProvider>
                    <CreatePost />
                  </PostProvider>
                }
              ></Route>
              <Route
                path="/mypost"
                element={
                  <PostProvider>
                    {/* <MyPost /> */}
                  </PostProvider>
                }
              ></Route>
              <Route
                path="/savedpost"
                element={
                  <PostProvider>
                    {/* <SavedPost /> */}
                  </PostProvider>
                }
              ></Route>
              <Route
                exact
                path="/:serviceprovider"
                element={
                  <ServiceProvider>
                    <ServiceProviderView />
                  </ServiceProvider>
                }
              ></Route>
              <Route
                path="/productprovider"
                element={
                  <ProductProvider>
                    <ProductProviderView />
                  </ProductProvider>
                }
              ></Route>
              <Route
                path="/potatoeProduct/:name/:service"
                element={<PutatoeServiceView></PutatoeServiceView>}
              ></Route>
              <Route
                path="/Sub_serviceProduct/:name/:service"
                element={<PutatoeSubServiceView></PutatoeSubServiceView>}
              ></Route>
              <Route
                exact
                path="/cart"
                element={<CartMainViewPage></CartMainViewPage>}
              ></Route>
              <Route
                path="/cart/final"
                element={<CartViewPage2></CartViewPage2>}
              ></Route>
              <Route
                path="/cart/recharge"
                element={<CartViewPageRecharge></CartViewPageRecharge>}
              ></Route>
              <Route
                path="/cart/final/order/:orderID"
                element={<OrderConfirmation></OrderConfirmation>}
              ></Route>

              <Route path="/chat/:type" element={<Chat />}></Route>

              <Route exact path="/news" element={<News></News>}></Route>
              <Route
                exact
                path="/mobile-recharge"
                element={<MobileRechargeView></MobileRechargeView>}
              ></Route>

              <Route
                exact
                path="/bill-payments"
                element={<DthRechargeViewPage></DthRechargeViewPage>}
              ></Route>
              <Route
                exact
                path="/jobs"
                element={<JobsViewPage></JobsViewPage>}
              ></Route>
              <Route exact path="/loan" element={<LoanPage></LoanPage>}></Route>
              <Route path="/blog" element={<Blog />}></Route>
              <Route path="/blog/:blogid" element={<Singleblog />}></Route>
              <Route exact path="/blog/myprofile" element={<Myblog />}></Route>
              <Route
                exact
                path="/blog/createblog"
                element={<Createblog />}
              ></Route>
              <Route exact path="/myprofile" element={localStorage.getItem('UserProfile') ? <Myprofile /> : <Navigate to="/pagenotfound" />}></Route>
              <Route exact path="/about" element={<AboutUs />}></Route>
              <Route exact path="/contactUs" element={<ContactUs />}></Route>
              <Route
                exact
                path="/terms-and-conditon"
                element={<TermsANdCondition />}
              ></Route>
              <Route path="/viewpdf/:type/:orderid" element={<PdfViewer />} />
              <Route path="/imagetourl" element={<Imagetourl />}></Route>
              <Route path="/user/:userId" element={<UserProfileOther />} />
              <Route exact path="/pagenotfound" element={<Pagenotfound />} />

              <Route path="/product/:productId/:productName/:serviceId" element={<ProductProvider><ProductInfo /></ProductProvider>} />

            </Routes>
            <Footer />
          </Router>
        </div>
      </React.Fragment>}
    </>


  );
}

export default App;

const ScrollToTop = () => {
  // Extracts pathname property(key) from an object
  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return <React.Fragment></React.Fragment>;
};
