import React from "react";
import "./css/Jobs.css";
import Jobapi from "../api/Jobapi";
import notify from "../error";
import { useNavigate } from 'react-router-dom';
import { useGlobalContext } from "../context";
import { ENTERED } from "react-transition-group/Transition";

export default function JobCard({ JobLists }) {
  const navigate= useNavigate();
  const {setModifyModal,setJobModalInfo,Userprofile,page, setPage,setExternalRedirect} = useGlobalContext();

  const ApplytoJob = async (JobId) => {
    if(!Userprofile){
      notify("error","Login to Apply for Job");
      return;
    }
    const response = await Jobapi.ApplyJobPosition(JobId);
    console.log("Apply Job Response ",response);
    if (response.data.error !== "") {
      if (response.data.error === "Job Profile not created") {
        notify("error", response.data.error);
        // Navigate to the MyProfile page with the Job Applications tab selected
        setExternalRedirect(true);
        setPage("7");
        navigate('/myprofile');
      } else {
        notify("error", response.data.error);
      }

    } else {
      notify("success", "Appied Job Successfully...");
    }
  };

  const handleModalClick = (cardInfo)=>{
    setModifyModal((prevState)=>!prevState);
    setJobModalInfo(cardInfo);
  }
  return (
    <React.Fragment>
      {JobLists.map((eachJob) => {
        return (
          <div className="job-card-container" onClick={()=>handleModalClick(eachJob)}>
            <div className="job-card-top-row-container">
              <div className="job-card-top-row-image">
                <img src={eachJob.serviceprovider_image}></img>
              </div>
              <div className="job-card-top-row-details">
                <div className="job-card-top-row-details-sp-name">
                  {eachJob.serviceprovider_name}
                </div>
                <div className="job-card-top-row-details-job-role">
                  {eachJob.job_position}
                </div>
              </div>
            </div>
              <div className="job-card-skills">
                <p >
                  Skills Required : {eachJob.skills_required}
                </p>
              </div>
              <div className="job-card-salary">
                <p >
                  <b>Salary : &#8377; {eachJob.salary}</b>
                </p>
              </div>
              <div className="job-card-description">
                <p >
                  Description : {eachJob.description}
                </p>
              </div>
            <div className="job-card-apply-button-container">
              <button 
                className="job-card-apply-button"
                onClick={() => {
                  ApplytoJob(eachJob.job_id);
                  setModifyModal(prevState => !prevState);
                }}
              >
                Apply
              </button>
            </div>
          </div>
        );
      })}
    </React.Fragment>
  );
}
