import React, { Component } from 'react'
import './css/bankcard.css'
import BankApi from '../../api/BankApi';
import notify from '../../error';
export default function Bankcard(props)
{
 const RemoveMyBankAccount=async()=>
 {
  const response = await BankApi.RemoveBankAccount(props.data.account_id)
  console.log(response)
  if(response.data.error!=='')
  {
   notify('error',response.data.error)
  }
  else
  { props.refresh();
   notify('success','Account removed successfully')
   
  }
 }
 return (
   <React.Fragment>
     <div className='my-bank-account-profile-card'>
       <div className='my-bank-account-profile-card-top'>
         <span>Account Holder Name : {props.data.account_holder_name}</span>
       </div>
       <div className='my-bank-account-profile-card-mid'>
         <span>Account Number : {props.data.account_number}</span>
         <span>Account Type : {props.data.account_type}</span>
       </div>
       <div className='my-bank-account-profile-card-mid'>
         IFSC : {props.data.IFSC}
       </div>
       <div className='my-bank-account-profile-card-bot'>
        {/* //pass data to child function with arguments parameter */}
         <button onClick={()=>{props.newpage(2) ; props.para(props.data)}}>Edit</button>
         <button onClick={RemoveMyBankAccount}>Delete</button>
       </div>
     </div>
   </React.Fragment>
 );
}