import React, { Component } from 'react';
import { useGlobalContext } from '../../context';
import myProfileServiceApi from '../../api/myProfileServiceApi';
import back from '../myprofileIcoons/bx-arrow-back..svg';
import notify from '../../error';
import './css/myJobs.css';
import { motion } from 'framer-motion';

export default function PostJobSerivce(props) {
  const { Userprofile,setInnerPage } = useGlobalContext();
  const [formData, setFormData] = React.useState({
    postion: '',
    salary: '',
    skill: '',
    desc: '',
  });
  const [myPost, setMyPost] = React.useState();
  const [jobProfiles, setJobProfiles] = React.useState();
  const [jobProfilesDec, setJobProfilesDec] = React.useState();
  const [appliedCandidates, setAppliedCandidates] = React.useState([]); 

  function handleChange(event) {
    console.log(event);
    const { name, value, type, checked } = event.target;
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: type === 'checkbox' ? checked : value,
      };
    });
    console.log(formData);
  }
  React.useEffect(() => {
    GetAllMyJob();
  }, []);

  async function GetAllMyJob() {
    //how to check if variable is undefined or null 
//     The following code:

// if(variable == null) { ... }
// is equivalent to

// if (variable === undefined || variable === null) { ... }
    if(Userprofile.service_provider_id == null)
    {
      notify('error','Invalid Service Id')
      return;
    }
    const response = await myProfileServiceApi.MyJobPostService(
      parseInt(Userprofile.service_provider_id)
    );
    console.log(response);

    if (response.data && response.data.error != '') {
      notify('error', response.data.error);
    } else {
      setMyPost(response.data);
      console.log(response.data);
    }
  }

  const GetNearbyProfiles = async () => {
    const response = await myProfileServiceApi.NearByJobProfiles(Userprofile.service_provider_id);
    console.log(response);
    if (response.data && response.data.error !== '') {
      notify('error', response.data.error);
    } else {
      setJobProfiles(response.data.Job_Profiles);
    }
  };

  React.useEffect(() => {
    GetAllMyJob();
    GetNearbyProfiles();
  }, []);

  const makeload = () => {
    GetAllMyJob();
  };

  const goBack = () => {
    props.return();
    setInnerPage(false);
  };

  const [page, setPage] = React.useState(1);
  const [billData, setBillData] = React.useState();
  const [formError, setFormError] = React.useState({
    postion: '',
    salary: '',
    skill: '',
    desc: '',
  });
  const exitDetail = () => {
    setPage(3);
  };

  const postMyJob = async () => {
    const errors = {};

  // Add validation for job position
  if (formData.postion.trim() === '') {
    errors.postion = 'Job Position is required.';
  } else if (formData.postion.length > 25) {
    errors.postion = 'Job Position cannot exceed 25 characters.';
  }

  // Add validation for salary
  if (formData.salary.trim() === '') {
    errors.salary = 'Salary is required.';
  }

  // Add validation for skills
  if (formData.skill.trim() === '') {
    errors.skill = 'Skills are required.';
  } else if (formData.skill.length > 100) {
    errors.skill = 'Skills field cannot exceed 100 characters.';
  }

  // Add validation for job description
  if (formData.desc.trim() === '') {
    errors.desc = 'Job Description is required.';
  }

  // Check if there are any errors
  if (Object.keys(errors).length > 0) {
    setFormError(errors);
    return;
  }

    const response = await myProfileServiceApi.PostJobService(
      Userprofile.service_provider_id,
      formData.postion,
      parseInt(formData.salary),
      formData.skill,
      formData.desc
    );
    console.log(response);
    if (response.data && response.data.error !== '') {
      notify('error', response.data.error);
    } else {
      notify('success', 'Job Posted Successfully');
      GetAllMyJob();
      setPage(1);
      setFormData({
        postion: '',
        salary: '',
        skill: '',
        desc: '',
      });
    }
  };

  const viewApplications = async (item) => {
    setPage(5);
    console.log(item);
    setAppliedCandidates(item.job_profile_ids);
  };

  const ViewProfileDetials=(item)=>
  {
   setJobProfilesDec(item)
   setPage(4);
  }

  return (
    <React.Fragment>
      <>
      
        <div className='my-service-profile-ongoing-and-pending-online'>
          {page !== 4 ? (
            <>
            
              <div className='my-service-profile-ongoing-and-pending-online-selector'>
                <div className='my-service-profile-ongoing-and-pending-online-selector-tab'>
                <div className='my-service-profile-ongoing-and-pending-online-back'>
                <img src={back} onClick={goBack}></img>
              </div>
                  <motion.span
                    onClick={() => {
                      setPage(1);
                    }}
                    animate={{
                      color: page === 1 ? 'black' : 'rgba(0, 0, 0, 0.512)',
                      'border-bottom':
                        page === 1 ? '3px solid #1D8B97' : 'none',
                    }}
                    whileHover={{ color: 'black' }}
                  >
                    My Job Posts
                  </motion.span>
                  <motion.span
                    onClick={() => {
                      setPage(2);
                    }}
                    animate={{
                      color: page === 2 ? 'black' : 'rgba(0, 0, 0, 0.512)',
                      'border-bottom':
                        page === 2 ? '3px solid #1D8B97' : 'none',
                    }}
                    whileHover={{ color: 'black' }}
                  >
                    Post Jobs
                  </motion.span>
                  <motion.span
                    onClick={() => {
                      setPage(3);
                    }}
                    animate={{
                      color: page === 3||page===5 ? 'black' : 'rgba(0, 0, 0, 0.512)',
                      'border-bottom':
                        page === 3||page===5  ? '3px solid #1D8B97' : 'none',
                    }}
                    whileHover={{ color: 'black' }}
                  >
                    Nearby Applications
                  </motion.span>
                </div>
                {page === 1 ? (
                  <>
                    <div className='my-job-post-service-profile-page-view-main'>
                      {myPost &&
                        myPost.job_list &&
                        myPost.job_list.length > 0 ?
                        myPost.job_list.map((item) => {
                          return (
                            <div className='my-job-post-service-profile-page-view-main-card-box'>
                              <div className='my-job-post-service-profile-page-view-main-card-box-item-detail'>
                                <div className='my-job-post-service-profile-page-view-main-card-box-item-detail-left'>
                                  <img src={item.serviceprovider_image}></img>
                                </div>
                                <div className='my-job-post-service-profile-page-view-main-card-box-item-detail-right'>
                                  <div>{item.serviceprovider_name}</div>
                                  <span>{item.job_position}</span>
                                  <section>
                                    Skill Required : &nbsp;
                                    {item.skills_required}
                                  </section>
                                  <div className='my-job-post-service-profile-page-view-main-salary'>
                                    Salary : &nbsp;&#8377;{item.salary}
                                  </div>
                                  <article>
                                    Description : &nbsp;{item.description}
                                  </article>
                                </div>
                              </div>
                              <div className='my-job-post-service-profile-page-view-main-card-box-item-status-cancel'>
                                <button onClick={()=>viewApplications(item)}>
                                  View Applicants
                                </button>
                              </div>
                            </div>
                          );
                        })
                        :
                        <>
                        <div class="loading" id="loading">
                          <div class="obj"></div>
                          <div class="obj"></div>
                          <div class="obj"></div>
                          <div class="obj"></div>
                          <div class="obj"></div>
                          <div class="obj"></div>
                          <div class="obj"></div>
                          <div class="obj"></div>
                          <div class="obje">LOADING MORE</div>
                          <div class="obj"></div>
                          <div class="obj"></div>
                          <div class="obj"></div>
                          <div class="obj"></div>
                          <div class="obj"></div>
                          <div class="obj"></div>
                          <div class="obj"></div>
                          <div class="obj"></div>
                        </div>
                        <div className="mobile-loader">
                          <div className="loader"></div>
                        </div>
                      </>
                        }
                    </div>
                  </>
                ) : page === 2 ? (
                  <>
                    <div className='my-job-post-service-profile-page-view-main-2' jobsections="yes">
                      <div className='my-job-post-service-profile-page-view-main-input'>
                      <div className={`my-job-post-service-profile-page-view-main-input-box ${formError.postion ? 'error' : ''}`}>
                          <span>Job Position</span>
                          <input
                            type='text'
                            name='postion'
                            value={formData.postion}
                            onChange={handleChange}
                            placeholder='Enter Position'
                          ></input>
                           {formError.postion && <p className="error-message">{formError.postion}</p>}
                        </div>
                        <div className={`my-job-post-service-profile-page-view-main-input-box ${formError.salary ? 'error' : ''}`}>
                          <span>Salary</span>
                          <input
                            type='number'
                            name='salary'
                            value={formData.salary}
                            onChange={handleChange}
                            placeholder='Enter Salary'
                          ></input>
                          {formError.salary && <p className="error-message">{formError.salary}</p>}
                        </div>
                        <div className={`my-job-post-service-profile-page-view-main-input-box ${formError.skill ? 'error' : ''}`}>
                          <span>Skills Required</span>
                          <input
                            type='text'
                            name='skill'
                            value={formData.skill}
                            onChange={handleChange}
                            placeholder='Enter Skills'
                          ></input>
                           {formError.skill && <p className="error-message">{formError.skill}</p>}
                        </div>
                        <div className={`my-job-post-service-profile-page-view-main-input-box ${formError.desc ? 'error' : ''}`}>
                          <span>Job Description</span>
                          <input
                            type='text'
                            name='desc'
                            value={formData.desc}
                            onChange={handleChange}
                            placeholder='Enter description'
                          ></input>
                           {formError.desc && <p className="error-message">{formError.desc}</p>}
                        </div>
                        <div className='my-job-post-service-profile-page-view-main-input-submit'>
                          <button onClick={postMyJob}>Submit</button>
                        </div>
                      </div>
                    </div>
                  </>
                ) : page == 3 ? (
                  <>
                    <div className='my-job-post-service-profile-page-view-main'>
                      {jobProfiles &&
                        jobProfiles.length > 0 &&
                        jobProfiles.map((item) => {
                          console.log("ksksksk",item);
                          return (
                            <>
                              <div className='my-job-post-service-profile-page-view-main-nearby-profile my-job-post-service-profile-page-view-main-nearby-profile1'>
                              <div className='myjobprofilenearbyflexparent'>
                              <div className='myjobprofilenearbyflex'>
                                <div className='my-job-post-service-profile-page-view-main-nearby-profile-head'>
                                  {item.f_name}&nbsp;{item.l_name}
                                </div>
                                <div className='my-job-post-service-profile-page-view-main-nearby-profile-foi'>
                                  Address :&nbsp;
                                  {item.address.town}
                                  &nbsp;
                                  {item.address.district}
                                </div>
                                
                                <div className='my-job-post-service-profile-page-view-main-nearby-profile-foi'>
                                  Education:
                                  {
                                  (item.degree_post_graduation != "None" && item.degree_post_graduation != null) ? <>&nbsp;{item.degree_post_graduation}</>:<>
                                 {
                                  (item.degree_graduation != "None" && item.degree_graduation != null) ? <>&nbsp;{item.degree_graduation}</> :<>
                                  {
                          (item.higher_secondary_percent != "" && item.higher_secondary_percent != null) ? <>
                          12th
                          </>:<>
                          {
                             (item.diploma_percent != "" && item.diploma_percent != null) ? <>
                             Diploma
                             </> :<>
                             {
  (item.secondary_percent != "" && item.secondary_percent != null) ? <>10th</>:<>&nbsp;&#60;10th</>
 }
                             </>
                          }
                          </>
                        }
                                  </>
                        }
                                  </>
                        }
                                </div>

                                    <div className='my-job-post-service-profile-page-view-main-nearby-profile-skills'>Skills :&nbsp;{item.skills? item.skills :"Not Available"}</div>
                                  
                                <>
                        
                                    <div className='my-job-post-service-profile-page-view-main-nearby-profile-exp'>
                                      {/* Experience :&nbsp;{item.experience?item.experience:"Not Available"} */}
                                      {
                                        item.experience ? 
                                        <>
                                        Experience:&nbsp;{item.experience}
                                        </>:
                                        <>
                                        </>
                                      }
                                      </div>
                                </>
                                      </div>
                               {

                                <div className='my-job-post-service-profile-page-view-main-nearby-profile-image1'>
                                  <img src={`${item.image}`}></img>
                              </div>
                        }
                              </div>
                                <div className='my-job-post-service-profile-page-view-main-nearby-profile-button'>
                                  <button onClick={()=>{ViewProfileDetials(item)}}>View Details</button>
                                </div>
                                </div>
                            </>
                          );
                        })}
                    </div>
                  </>
                ) : (
                  <>
                  <div className='my-job-post-service-profile-page-view-main'>
                      {appliedCandidates &&
                        appliedCandidates.length > 0 ?
                        appliedCandidates.map((item) => {
                          return (
                            <>
                              <div className='my-job-post-service-profile-page-view-main-nearby-profile'>
                                <div className='my-job-post-service-profile-page-view-main-nearby-profile-head'>
                                  {item.f_name}&nbsp;{item.l_name}
                                </div>
                                <div className='my-job-post-service-profile-page-view-main-nearby-profile-foi'>
                                  Field Of Interest :&nbsp;
                                  {item.field_of_interest}
                                </div>
                                
                                  
                                    <div className='my-job-post-service-profile-page-view-main-nearby-profile-skills'>Skills :&nbsp;{item.skills? item.skills :"Not Available"}</div>
                                  
                                <>
                       
                                    <div className='my-job-post-service-profile-page-view-main-nearby-profile-exp'>Experience :&nbsp;{item.experience?item.experience:"Not Available"}</div>
                                  
                                </>
                                <div className='my-job-post-service-profile-page-view-main-nearby-profile-button'>
                                  <button onClick={()=>{ViewProfileDetials(item)}}>View Details</button>
                                </div>
                              </div>
                            </>
                          );
                        }):(
                          <>
                              <p>No applied candidates</p>
                          </>
                        )}
                    </div>
                  </>
                )}
              </div>
            </>
          ) : (
            <div className='my-bills-profile-page-view-main-right'>
              <BillDetailsView
                data={jobProfilesDec}
                return={exitDetail}
              ></BillDetailsView>
            </div>
          )}
        </div>
      </>
    </React.Fragment>
  );
}

function BillDetailsView(props) {
 console.log(props)
 const { address_line1, address_line2, district, state, town } = props.data.address;
 const formattedAddress =
  `${address_line1}` +
  (address_line2 && address_line2 !== town ? `, ${address_line2}` : '') +
  (town !== district && town !== state ? `, ${town}` : '') +
  (district !== state ? `, ${district}` : '') +
  `, ${state}`;
  return (
    <React.Fragment>
      <>
        <div className='my-bills-profile-page-view-main-right-data'>
          <div className='my-bills-profile-page-view-main-right-heading-data'>
            <img src={back} onClick={props.return}></img>
            <span>Job Profile</span>
          </div>
          <div className='my-job-post-service-profile-page-view-main-nearby-profile-detail'>
            <div className='my-job-post-service-profile-page-view-main-nearby-profile-detailleft'>
              <div className='my-job-post-service-profile-page-view-main-nearby-profile-detailleftimage aligns'>
                <img src={props.data.image}></img>
              </div>
              <div className='my-job-post-service-profile-page-view-main-nearby-profile-detaillefttextname aligns'>
                <p >{`${props.data.f_name} ${props.data.l_name}`}</p>
              </div>
              <div className='my-job-post-service-profile-page-view-main-nearby-profile-detaillefttextname pos aligns'>
                <p >{(props.data.position_of_responsibility != "" && props.data.position_of_responsibility != null && props.data.position_of_responsibility != undefined) ? props.data.position_of_responsibility:"Fresher"}</p>
              </div>

              <div className='my-job-post-service-profile-page-view-main-nearby-profile-detaillefttextname skills'>
               <p>Skills :</p>

               {
                props.data.skills.length != "" ? <>
                 <div className='skillssectionss'>
                {
                  props.data.skills.split(",").map((items)=>{
                    return (
                      <>
                      {
                        <span>{items}</span>
                      }
                      </>
                    )
                  })
                }
            </div>
                </>:<>
                  <div className='my-job-post-service-profile-page-view-main-nearby-profile-detaillefttextname notavailable '>
                    <p>Not Available</p>
                  </div>
                </>
               }
              </div>

              <div className='my-job-post-service-profile-page-view-main-nearby-profile-detaillefttextname skills'>
               <p>Interests :</p>

               {
                props.data.interests.length != "" ? <>
                 <div className='skillssectionss'>
                {
                  props.data.interests.split(",").map((items)=>{
                    return (
                      <>
                      {
                        <span>{items}</span>
                      }
                      </>
                    )
                  })
                } 
            </div>
                </>:<>
                  <div className='my-job-post-service-profile-page-view-main-nearby-profile-detaillefttextname notavailable '>
                    <p>Not Available</p>
                  </div>
                </>
               }
              </div>
              <div className='my-job-post-service-profile-page-view-main-nearby-profile-detaillefttextname skills'>
               <p>Portfolio :</p>

               {
                props.data.portfolio ? <>
                 <div className='skillssectionss'>
                <div>
                <a href={props.data.portfolio} target="_blank">{props.data.portfolio}</a>
                </div>
            </div>
                </>:<>
                  <div className='my-job-post-service-profile-page-view-main-nearby-profile-detaillefttextname notavailable '>
                    <p>Not Available</p>
                  </div>
                </>
               }
              </div>
            </div>
            <div className='my-job-post-service-profile-page-view-main-nearby-profile-detailright'>
              <div className='my-job-post-service-profile-page-view-main-nearby-profile-detailrightbasic'>
                <div className='basicinformation'>
                  Basic Information
                </div>
                
              <div className='my-job-post-service-profile-page-view-main-nearby-profile-detailrightbasicflex'>
              <div className='location detailrightbasicflexsection'>
                Location:<br></br>
                <div>
                {`${props.data.address.district} , ${props.data.address.state}`}
                </div>
                </div>
                <div className='yearofexp detailrightbasicflexsection'>
                Experience:<br></br>
                <div>
                {
                props.data.experience != "" && props.data.experience != null && props.data.experience != undefined ? <>{props.data.experience}</>:<>
                <div className='my-job-post-service-profile-page-view-main-nearby-profile-detaillefttextname notavailable '>
                    <p>Not Available</p>
                  </div>
                </>
                }
                </div>
                </div>
                <div className='contact detailrightbasicflexsection'>
                Contact No:<br></br>
                <div>
                {
                props.data.mobile != "" && props.data.mobile != null && props.data.mobile != undefined ? <>{props.data.mobile}</>:<>
                <div className='my-job-post-service-profile-page-view-main-nearby-profile-detaillefttextname notavailable '>
                    <p>Not Available</p>
                  </div>
                </>
                }
                </div>
                </div>
                <div className='email detailrightbasicflexsection'>
                Email Id:<br></br>
                <div>
                {
                props.data.emaill != "" && props.data.emaill != null && props.data.emaill != undefined ? <>{props.data.emaill}</>:<>
                <div className='my-job-post-service-profile-page-view-main-nearby-profile-detaillefttextname notavailable '>
                    <p>Not Available</p>
                  </div>
                </>
                }
                </div>
                </div>
                <div className='Education detailrightbasicflexsection'>
                  Education:<br></br>
                  <div>
                    {
                    (props.data.degree_post_graduation != "None" && props.data.degree_post_graduation != null) ? <>&nbsp;{props.data.degree_post_graduation}</>:<>
                   {
                    (props.data.degree_graduation != "None" && props.data.degree_graduation != null) ? <>&nbsp;{props.data.degree_graduation}</> :<>
                    {
            (!props.data.diploma_percent && props.data.higher_secondary_percent) ? <>
            12th
            </>:<>
{
            (props.data.diploma_percent && !props.data.higher_secondary_percent) ? <>
            Diploma
            </>:<>
            {
             (props.data.secondary_percent != "" && props.data.secondary_percent != null) ? <>10th</>:<>&nbsp;&#60;10th</>
            }

            </>
}
            </>
          }
                    </>
          }
                    </>
                  }
                  </div>
          </div>        
                </div>
              </div>
              <div className='my-job-post-service-profile-page-view-main-nearby-profile-detailrightbasic my-job-post-service-profile-page-view-main-nearby-profile-detailrightbasiceducation'>
                <div className='basicinformation'>Education Detail  </div>
                {props.data?.secondary_percent  || props.data?.higher_secondary_percent || props.data?.degree_graduation || props?.data.degree_post_graduation || props?.data.diploma_percent || props?.data.post_graduation_percent || props?.data.graduation_percent  ? <div className='edducation'>
                  <ul className='my-job-post-service-profile-page-view-main-nearby-profile-detaillefttextname notavailable'>
                  {
                    (props.data.degree_post_graduation)
                    ?
 <>
                    <li>
                      <span className='deggree'>Post Graduation Degree : </span> &nbsp;
                      {props.data.degree_post_graduation}
                      <br></br>
                      <span className='deggree percentage'>Percentage : </span> 
                      {props.data.post_graduation_percent && 0 < props.data.post_graduation_percent && props.data.post_graduation_percent <= 100 ? <>{props.data.post_graduation_percent}%</>:"Not valid"}
                    </li>
<br></br>
                    </> : <></>
                  }
{
  (props.data.degree_graduation)
                    ? <>
                    <li>
                      <span className='deggree'>Graduation Degree :</span> &nbsp;
                      {props.data.degree_graduation}
                      <br></br>
                      <span className='deggree percentage'>Percentage : </span>
                      {props.data.graduation_percent && 0 < props.data.graduation_percent && props.data.graduation_percent <= 100 ? <>{props.data.graduation_percent}%</>:"Not valid"}
                    </li>
                    <br></br>
                    </>:<></>
                  }
                  {
                    (props.data.higher_secondary_percent)
                    ? <>
                    <li>
                      <span className='deggree'>Higher secondary Education : </span> &nbsp;
                      <br></br>
                      <span className='deggree percentage'>Percentage : </span> 
                      {props.data.higher_secondary_percent  && 0 < props.data.higher_secondary_percent && props.data.higher_secondary_percent <= 100 ? <>{props.data.higher_secondary_percent}%</>:"Not valid"}
                    </li>
<br></br>
                    </> :<></>
                  }
{
                    (props.data.diploma_percent)
                    ? <>
                    <li>
                      <span className='deggree'>Diploma Percentage : </span> &nbsp;
                      <br></br>
                      <span className='deggree percentage'>Percentage : </span> 
                      {props.data.diploma_percent  && 0 < props.data.diploma_percent && props.data.diploma_percent <= 100 ? <>{props.data.diploma_percent}%</>:"Not valid"}
                    </li>
<br></br>
                    </> : <></>
                  }
{
                    (props.data.secondary_percent)
                    ? <>
                    <li>
                      <span className='deggree'>Secondary Education : </span> &nbsp;
                      <br></br>
                      <span className='deggree percentage'>Percentage : </span> 
                      {props.data.secondary_percent  && 0 < props.data.secondary_percent && props.data.secondary_percent <= 100 ? <>{props.data.secondary_percent}%</>:"Not valid"}
                    </li>
                    </>:<></>
                  }
                  </ul>
                </div> :<>
                <div className='edducation'><div className='notavailble1'>Not Available</div></div>
                </>
}
                {/* {
                  !props.data.secondary_percent  && !props.data.higher_secondary_percent && !props.data.degree_graduation && !props.data.degree_post_graduation && !props.data.diploma_percent  && <div className='edducation'><div className='notavailble1'>Not Available</div></div>
                } */}
              </div>
              </div>


            {/* <div className='my-job-post-service-profile-page-view-main-nearby-profile-detail-details'>
              Name: {props.data.f_name} {props.data.l_name}
            </div>
            <div className='my-job-post-service-profile-page-view-main-nearby-profile-detail-details'>
              Email :&nbsp;{props.data.emaill}
            </div>
            <div className='my-job-post-service-profile-page-view-main-nearby-profile-detail-details'>
              Address :&nbsp;{formattedAddress}
            </div>
            <div className='my-job-post-service-profile-page-view-main-nearby-profile-detail-details'>
              Guardian Name :&nbsp;{props.data.guardian_name?props.data.guardian_name:"Not Available"}
            </div>
            <div className='my-job-post-service-profile-page-view-main-nearby-profile-detail-details'>
              Id Proof Number :&nbsp;{props.data.id_proof?props.data.id_proof:"Not Available"}
            </div>
            <div className='my-job-post-service-profile-page-view-main-nearby-profile-detail-details'>
              Secondary Percentage :&nbsp;{props.data.secondary_percent?props.data.secondary_percent:"Not Available"}
            </div>
            <div className='my-job-post-service-profile-page-view-main-nearby-profile-detail-details'>
              Higher Secondary Percentage :&nbsp;{props.data.higher_secondary_percent?props.data.higher_secondary_percent:"Not Available"}
            </div>
            <div className='my-job-post-service-profile-page-view-main-nearby-profile-detail-details'>
              Graduation Percentage :&nbsp;{props.data.graduation_percent?props.data.graduation_percent:"Not Available"}
            </div>
            <div className='my-job-post-service-profile-page-view-main-nearby-profile-detail-details'>
              Post Graduation Percentage :&nbsp;{props.data.post_graduation_percent?props.data.post_graduation_percent:"Not Available"}
            </div>
            <div className='my-job-post-service-profile-page-view-main-nearby-profile-detail-details'>
              Diploma Percentage :&nbsp;{props.data.diploma_percent?props.data.diploma_percent:"Not Available"}
            </div>
            <div className='my-job-post-service-profile-page-view-main-nearby-profile-detail-details'>
              Skills :&nbsp;{props.data.skills?props.data.skills:"Not Available"}
            </div>
            <div className='my-job-post-service-profile-page-view-main-nearby-profile-detail-details'>
              Interests :&nbsp;{props.data.interests?props.data.interests:"Not Available"}
            </div>
            <div className='my-job-post-service-profile-page-view-main-nearby-profile-detail-details'>
              Projects :&nbsp;{props.data.projects?props.data.projects:"Not Available"}
            </div>
            <div className='my-job-post-service-profile-page-view-main-nearby-profile-detail-details'>
              Fields of Interest :&nbsp;{props.data.field_of_interest?props.data.field_of_interest:"Not Available"}
            </div>
            <div className='my-job-post-service-profile-page-view-main-nearby-profile-detail-details'>
              Portfolio Link :&nbsp;{props.data.portfolio?props.data.portfolio:"Not Available"}
            </div>
            <div className='my-job-post-service-profile-page-view-main-nearby-profile-detail-details'>
              Experience :&nbsp;{props.data.experience?props.data.experience:"Not Available"}
            </div>
            <div className='my-job-post-service-profile-page-view-main-nearby-profile-detail-details'>
              Internships :&nbsp;{props.data.internships?props.data.internships:"Not Available"}
            </div> */}
          </div>
        </div>
      </>
    </React.Fragment>
  );
}
