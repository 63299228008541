import postapi from "../api/postapi";
import React, { useEffect, useState } from "react";
import "../post-css/allPost.css";
import PostCard from "./postCard";
// import PostCardModal from "./PostModal";
import PostCardModal from "./PostCardModal";
import CreatePost from "./createPost";
import { usePostContext } from "../postContext.js";
import "../serviceprovider-css/serviceProviderView.css";
import { useGlobalContext } from "../context";
import { useNavigate } from "react-router-dom";
import Loading from "../components/Loading";
import notify from "../error";
import { IoMdArrowBack } from "react-icons/io";

export default function AllPost() {
  const navigate = useNavigate();
  const {
    allpost,
    setallpost,
    isLoading,
    setIsLoading,
    setprevHead,
    image4,
    detail,
    imageUrl,
    // new
    postInfo,
    setPostInfo,
    postUpdate,
    createPostModal,
    setCreatePostModal,
    editPostData,
    setEditPostData,
  } = usePostContext();
  const { modifyModal, setModifyModal,Userprofile } = useGlobalContext();
  const [startIndex, setStartIndex] = useState(0);
  const [isEmpty, setIsEmpty] = useState(false);

  function HandleHead() {
    setprevHead("add");
  }

  const observer = React.useRef();
  const markerRef = React.useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && isEmpty === false) {
          setStartIndex((prevStartIndex) => prevStartIndex + 18);
        }
      });
      if (node) observer.current.observe(node);
    },
    [setStartIndex, isEmpty]
  );

  const fetchPosts = async (index) => {
    try {
      setIsLoading(true);
      const response = await postapi.AllPromotion(index);
      setIsLoading(false);
      if (response.data.data.length === 0) {
        setIsEmpty(true);
        return;
      }
      setallpost((prevPosts) => [...prevPosts, ...response.data.data]);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const fetchPostClone = async (index) => {
    try {
      setIsLoading(true);
      const response = await postapi.AllPromotion(index);
      setIsLoading(false);
      if (response.data.data.length === 0) {
        setIsEmpty(true);
        return;
      }
      setallpost(response.data.data);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPostClone(0);
  }, [postUpdate]);

  useEffect(() => {
    fetchPosts(startIndex);
  }, [startIndex]);

  const handleCreateButtonClick = ()=>{
    if(!Userprofile){
      notify('warn',"Please log in or sign up...");
      return;
    }
    setModifyModal(true);
    setPostInfo();
    setEditPostData([]);
    setCreatePostModal(true);
  }

  const HandleEdit = async () => {
    if (imageUrl) {
      const response = await postapi.EditPromotion(image4.id, detail, imageUrl);
      if (response.status === 200) {
        notify("success", "Post Edited Successfully...");
        // fetchPosts();
      } else {
        notify("error", "Something went Wrong");
      }
    } else {
      const response = await postapi.EditPromotion(
        image4.id,
        detail,
        image4.image
      );
      if (response.status === 200) {
        notify("success", "Post Edited Successfully...");
        // fetchPosts();
      } else {
        notify("error", "Something went Wrong");
      }
    }
  };

  const HandleDelete = async (postId) => {
    try {
      const response = await postapi.DeletePost(0, postId);
      if (response.status === 200) {
        notify("success", "Post Deleted Successfully...");
        // After successful deletion, update the 'mypost' state
        setallpost((prevPosts) =>
          prevPosts.filter((post) => post.id !== postId)
        );
      }
    } catch (error) {
      // Handle error here if needed
      console.error("Error deleting post:", error);
    }
  };

  const HandleBlockPost = async (userId) => {
    try {
      const response = await postapi.BlockPost(userId);
      if (response.status === 200) {
        notify("success", "Post Blocked Successfully...");
        setallpost((prevPosts) =>
          prevPosts.filter((post) => post.userid !== userId)
        );
      }
    } catch (error) {
      console.error("Error blocking post:", error);
    }
  };
  return (
    <>
      {modifyModal && (
        <div className="postOverlay">
          {createPostModal && editPostData ? (
            // edit the post
            <CreatePost editData={editPostData} />
          ) : createPostModal ? (
            // create the post
            <CreatePost />
          ) : (
            postInfo && (
              // single post modal
              <div className="perticulerPost">
                <PostCardModal
                  id={postInfo[0]}
                  profileInfo={{ img: postInfo[1], isLiked: postInfo[2] }}
                />
              </div>
            )
          )}
        </div>
      )}
      <section className="postMain">
        <div className="protions-write-a-post-button-container">
        <IoMdArrowBack className="backButtonPost" onClick={()=>navigate('/')}/>          
          <button
            className="protions-write-a-post-button"
            onClick={() => {handleCreateButtonClick()}}
          >
            Write a Post
          </button>
        </div>
        <div className="post-container">
          {allpost &&
            allpost.map((eachData, index) => {
              return (
                <PostCard
                  data={eachData}
                  key={index}
                  onDelete={HandleDelete}
                  onBlock={HandleBlockPost}
                />
              );
            })}
          {allpost.length > 0 && <div ref={markerRef} />}
          {isEmpty && <div>That's all we have...</div>}
          {isLoading && (
            <div className="posts_loading">
              <Loading />
            </div>
          )}
        </div>
      </section>
    </>
  );
}
