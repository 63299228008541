import React from "react";
import { useGlobalContext } from "../../context";
import { IoCloseCircleSharp } from "react-icons/io5";
import { useState, } from "react";
import "../css/accountsetting.css";
import notify from "../../error";
import profileapi from "../../api/profileapi";
import api from "../../api/api";
import myProfileServiceApi from "../../api/myProfileServiceApi";

function EnterOTP(props){
    console.log("Entered OTP");
    console.log(props.purpose);
    const  {
      setIsNumberChange,
      sendOtp, 
      setSendotp,
      setLoading,
      setUserProfile,
      setLoginPage,
      setChangeInCart,
      changeInCart,
      refreshPage,
      setRefreshPage,
      isOtpForSellerRegisteration, setIsOtpForSellerRegisteration,
    } = useGlobalContext();

    const [abc, setAbc] = useState();


    const [otp, setOtp] = React.useState({ 1: '', 2: '', 3: '', 4: '' });
    const [isAddingBusiness, setIsAddingBusiness] = React.useState(false);
    const arr = [1, 2, 3, 4];
  const messageItem={
    alignSelf:"center",
  }

  const handleCrossClicked=()=> {
    setIsNumberChange(false);
    setSendotp(false);
  }

  const handleReSend = ()=>{
    setSendotp(false);
  }

  async function handleSubmit(){
    const userOTP = `${otp[1]}${otp[2]}${otp[3]}${otp[4]}`;
    console.log("This is User OTP "+userOTP);
    console.log(typeof userOTP)
    if(userOTP.length!== 4){
      (notify("error", "Please fill the complete OTP")) ;
      return;
    }
    let response;
    if(props.purpose===
      "register seller"){
         response= await profileapi.verifyOTPseller(
        userOTP
        );
      }
      else{
         response = await profileapi.checkOtpForNumberChange(`+91${props.newNumber}`, userOTP);
      }
 
    console.log("This is OTP Response" + response);
    if (response && response.data && response.data.error !== "") {
      notify("error", response.data.error);
      return;
    }else if(props.purpose == "change number") {      
      getProfileAfterNumberChange();
    }
    else if (props.purpose == "register seller"){
      handleRegisterSeller();
    }

    console.log(props.purpose);
  }

  //********************************FOR REGISTER SELLER******************************/
  const handleRegisterSeller=()=>{
    setIsOtpForSellerRegisteration(true);
  }


  //****************************FOR NUMBER CHANGE******************************* */
    const getProfileAfterNumberChange = async () => {
    const response2 = await api.ProfileApi();
    console.log("This is response after otp submition "+response2);
    setRefreshPage(response2.data);
    console.log("if not entered");

    // localStorage.setItem("user",response2.data)
    if (response2.data.error === "") {
      setIsNumberChange(false);
      setSendotp(false);
      notify("success", "Number Updated Successfully");
      setRefreshPage(response2.data);
    } else {
      notify(
        "error",
        "Number Update Failed"
      );
      
    }
    //setLoading(false);
    console.log(refreshPage);
  };


 
  const inputfocus = (elmnt) => {
    console.log(elmnt)
  
    
     if (elmnt.key === 'Delete' || elmnt.key === 'Backspace') {
       const next = elmnt.target.tabIndex - 2;
       if (next > -1) {
        if (elmnt.target.previousElementSibling) {
          elmnt.target.previousElementSibling.focus();
        }
  
       }
     } else {
       console.log('next');
      // Check if the entered character is a digit
      const isDigit = /^[0-9]+$/.test(elmnt.target.value);
  
      if (!isDigit) {
        // If the entered character is not a digit, don't shift focus
        return;
      }
    
       const next = elmnt.target.tabIndex;
       if (next < 4) {
          elmnt.target.nextElementSibling.focus();
       }
     }
   };

 const updateotp = (e) => {
  const name = e.target.name;
  const val = e.target.value;
  setOtp((prev) => {
    return { ...prev, [name]: val };
  });
  console.log(otp);
};
  return (
    <div className="overlayTheModal">
      <section 
      className="mycontainer MycontainerEnterOTP" >
        <IoCloseCircleSharp
          size={30}
          className="crossIcon"
          onClick={(handleCrossClicked)}
        />
        <div className="headingOfFieldChange">Enter The OTP</div>

        <div className="message">
          <div style={messageItem}>We Will Send You A One Time Password On this Mobile Number</div>
          <span style={messageItem}> {props.newNumber} </span>
        </div>

        <div className="login-sidebar-forgot-inputs-otp">

              {arr.map((item) => {
                return (
                  <input
                    name={item}
                    value={otp.item}
                    onChange={updateotp}
                    onKeyDown={(e) => {
                      // Prevent any action for non-digit characters and limit length to 1
                      if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
                        e.preventDefault();
                      }
              
                      
                    }}
                    
                    autoComplete='off' 
                    type='text'
                    className={`input-otp-${item}`}
                    size='1'
                    pattern='[0-9]{1}'
                    maxLength='1'
                    onKeyUp={inputfocus}
                    tabIndex={item}
                  ></input>
                );
              })}
        </div>

        <div className="login-sidebar-forgot-resend">Did not receive code ?<span onClick={handleReSend}>Re-send</span></div>

        <button
          className="buttonsty"
          onClick={handleSubmit}
        >
          SUBMIT
        </button>
      </section>
      {isAddingBusiness && (
        <div className="overlay addingprovider" style={{ top: "0" }}>
          <div>Creating provider account. Please wait...</div>
        </div>
      )}
    </div>
  );

}

export default EnterOTP;