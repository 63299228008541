import { useState } from "react";
import profileapi from "../../api/profileapi";
import postapi from "../../api/postapi";
import { useGlobalContext } from "../../context";
import { IoCloseCircleSharp } from "react-icons/io5";
import { MdEdit } from "react-icons/md";
import { ChromePicker } from 'react-color';
import { GetColorName } from 'hex-color-to-color-name';
import camera from "../../images/blogimages/camera.svg";
import "./css/ManageProductModal.css";
import notify from "../../error";


export default function ManageProductModal({ product, quantityIndex, colorIndex }) {
  const { modifyModal, setModifyModal, setProductsUpdate } = useGlobalContext();
  const [isModifying, setIsModifying] = useState(false);
  const [isColorPickerVisible, setIsColorPickerVisible] = useState(false); // Initialize as true
  const [fetchedImg, setFetchedImg] = useState(product.qty_list[quantityIndex].color && colorIndex !== null && product.qty_list[quantityIndex].color.length > 0
    ? product.qty_list[quantityIndex].color[colorIndex].image
    : product.qty_list[quantityIndex].images);
  const [choosenImg, setChoosenImg] = useState([]);
  const [deletedImgArray, setDeletedImgArray] = useState([]);
  const [isChecked, setIsChecked] = useState(
    product.qty_list[quantityIndex].color && colorIndex !== null && product.qty_list[quantityIndex].color.length > 0
    ? product.qty_list[quantityIndex].color[colorIndex].out_of_stock
    : product.qty_list[quantityIndex].out_of_stock
  );
  const [productName, setProductName] = useState(product.product_name);
  const [productDiscount, setProductDiscount] = useState(
    product.qty_list[quantityIndex].color && colorIndex !== null && product.qty_list[quantityIndex].color.length > 0
      ? product.qty_list[quantityIndex].color[colorIndex].discount
      : product.qty_list[quantityIndex].discount
  );
  const [actualPrice, setActualPrice] = useState(
    product.qty_list[quantityIndex].color && colorIndex !== null && product.qty_list[quantityIndex].color.length > 0
      ? product.qty_list[quantityIndex].color[colorIndex].actual_price
      : product.qty_list[quantityIndex].actual_price
  );
  const [units, setUnits] = useState(product.qty_list[quantityIndex].color && colorIndex !== null && product.qty_list[quantityIndex].color.length > 0
    ? product.qty_list[quantityIndex].color[colorIndex].unit
    : product.qty_list[quantityIndex].units);

    const [updatedcolor,setUpdatedcolor]=useState({    othercolorname: "",
    othercolorcode:""});
    const getColorName = (hex) => {
      const colorNameResult = GetColorName(hex);
      return colorNameResult || 'Unknown';
    };
    const handleChangecolorpicker = (color) => {
      console.log(color);
      const colorNameResult = getColorName(color.hex);
      console.log(colorNameResult);
      setUpdatedcolor({
     
        othercolorcode: color.hex,
        othercolorname: colorNameResult,
      });
      // setIsColorPickerVisible(!isColorPickerVisible);
    };

    const handlecoloredit=()=>{
      setIsColorPickerVisible(!isColorPickerVisible);
    }
  const handleChange = (e) => {
    setProductName(e.target.value);
  };
  const handleSwitch = (e) => {
    console.log(e.target.checked);
    setIsChecked(e.target.checked);
    if (colorIndex !== null && product.qty_list[quantityIndex].color && product.qty_list[quantityIndex].color.length > 0) {
      product.qty_list[quantityIndex].color[colorIndex].out_of_stock = e.target.checked ? 1 : 0;
    } else {
      product.qty_list[quantityIndex].out_of_stock = e.target.checked ? 1 : 0;
    }
  };
  console.log(quantityIndex, colorIndex);
  const handleImageUpload = (event) => {
    setChoosenImg([event.target.files]);
    const fileReaderArr = Array.from(event.target.files);

    // Map over the tempImgArray and read each file asynchronously
    Promise.all(
      fileReaderArr.map((file) => {
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.onload = () => {
            resolve({ image: reader.result }); // Include the image_url property
          };
          reader.readAsDataURL(file);
        });
      })
    ).then((results) => {
      // Use spread operator to append the new results to the existing tempImg state
      setFetchedImg((prevTempImg) => [...prevTempImg, ...results]);
    });
  };

  async function uploadOGCFunction(files) {
    try {
      // console.log(files);
      const response = await postapi.UploadImageOGCforADDproduct(files[0]);
      return response.data.url;
    } catch (err) {
      console.log(err);
    }
  }

  const handleRemoveImage = (indexToRemove) => {
    setDeletedImgArray((prevArr) => [
      ...prevArr,
      fetchedImg[indexToRemove].image_id,
    ]);
    console.log(fetchedImg[indexToRemove].image_id);
    const updatedTempImg = [...fetchedImg];
    updatedTempImg.splice(indexToRemove, 1);
    setFetchedImg(updatedTempImg);
  };
  const hasChanged = (newValue, oldValue) => parseInt(newValue) !== oldValue;
  const modifyBtnClick = async (
    service_provider_id,
    serviceProvider_ProductID,
    discount,
    actual_price,
    unit,
    out_of_stock,
    product_name,
    color_id,updatedcolor
  ) => {
    try {
      let multiple_images = "";
      if (choosenImg.length === 0 && fetchedImg.length === 0) {
        throw new Error("Image can not be empty...");
      }

      if (choosenImg.length !== 0) {
        setIsModifying(true);
        multiple_images = await uploadOGCFunction(choosenImg);
        console.log(multiple_images);
      }
console.log(out_of_stock);

      const response = await profileapi.UpdateServiceProviderProduct(
        service_provider_id,
        serviceProvider_ProductID,
        hasChanged(discount, productDiscount) ? discount : null,
        hasChanged(actual_price, actualPrice) ? actual_price : hasChanged(discount, productDiscount) ? actualPrice : null,
        hasChanged(unit, units) ? unit : null,
        out_of_stock,
        product_name,
        multiple_images,
        deletedImgArray,
        color_id !== null ? color_id : null,
        updatedcolor.othercolorcode ?   updatedcolor.othercolorcode:null,
        updatedcolor.othercolorname ?   updatedcolor.othercolorname:null,
      );
      if (response.data.error === "") {
        setModifyModal(false);
        setIsModifying(false);
        setProductsUpdate(true);
        notify("success", "Product Updated Successfully");
      }
    } catch (err) {
      notify("error", `Failed to modify...${err.message}`);
    }
    console.log(deletedImgArray);
  };

  return (
    <section className="container">
      <IoCloseCircleSharp
        size={30}
        className="crossIcon1"
        onClick={() => setModifyModal(false)}
      />
      <div className="modalHeading">Modify Product</div>
      <form className="modlaForm">
        <label className="label">Product Name</label>
        <input
          className="inputContainer productNameInput"
          type="text"
          value={productName}
          // onChange={handleChange}
          readOnly
        />
        <div className="singleLineInuts">
          <label className="label">Discount</label>
          <input
            className="inputContainer"
            type="text"
            value={productDiscount}
            onChange={(e) => setProductDiscount(e.target.value)}
          />
          <label className="label">Actual Price</label>
          <input
            className="inputContainer"
            type="text"
            value={actualPrice}
            onChange={(e) => setActualPrice(e.target.value)}
          />
          <label className="label">Units</label>
          <input
            className="inputContainer"
            type="text"
            value={units}
            onChange={(e) => setUnits(e.target.value)}
          />

          {product.qty_list[quantityIndex].color && colorIndex !== null && (<>
            <div class="service-color-choices service-color-left-view">
              {product.qty_list[quantityIndex].color && colorIndex !== null && product.qty_list[quantityIndex].color.length > 0 && (
                <>
                  <label className="label">Color</label>
                  <div className="initial-choice service-color-choice" style={{
                    backgroundColor:updatedcolor.othercolorcode || 
                      product.qty_list[quantityIndex].color[colorIndex].color_code,
                  }}
                  >
                  </div>
                  <div onClick={handlecoloredit}>
                  <MdEdit  className="edit-manage-product-color" />
                    </div>

                </>)}
       
            </div>
            <br /></>)}
            {isColorPickerVisible && <div className="color-option-other">

<ChromePicker
  color={updatedcolor.othercolorcode}
  onChange={handleChangecolorpicker}
/>
<button onClick={
  handlecoloredit}>Update Color</button>

</div>}
{isColorPickerVisible &&<br />}
          <label className="switch">
            <input
              value={isChecked}
              type="checkbox"
              checked={isChecked}
              onChange={(e) => handleSwitch(e)}
            />
            <span className="slider round"></span>
          </label>
          <div>out of stock</div>
        </div>
        <div className="imageContainer">
          {fetchedImg &&
            fetchedImg.length > 0 &&
            fetchedImg.map((image, index) => (
              <div key={index} className="imageWrapper">
                <IoCloseCircleSharp
                  size={30}
                  className="crossIcon1"
                  onClick={() => handleRemoveImage(index)}
                />
                <img src={image.image} alt="" className="image" />
              </div>
            ))}
          <form className="imageInputForm">
            <label htmlFor="image-input">Select Image</label>
            <input
              type="file"
              id="image-input"
              multiple
              accept="image/png, image/jpg, image/gif, image/jpeg"
              onChange={(e) => handleImageUpload(e)}
            />
          </form>
        </div>
        {/* new addition */}
      </form>
      <div className="handlerButtons">
        <button
          onClick={() =>
            modifyBtnClick(
              product.service_provider_id,
              product.qty_list[quantityIndex].id,
              productDiscount,
              actualPrice,
              units,
              ( product.qty_list[quantityIndex].color && product.qty_list[quantityIndex].color.length > 0) ?
                product.qty_list[quantityIndex].color[colorIndex].out_of_stock 
    :
                product.qty_list[quantityIndex].out_of_stock
              ,
              productName,
              product.qty_list[quantityIndex].color && colorIndex !== null && product.qty_list[quantityIndex].color.length > 0
                ? product.qty_list[quantityIndex].color[colorIndex].color_id
                : null,updatedcolor
            )
          }
        >
          save
        </button>

        <button onClick={() => setModifyModal(false)}>cancel</button>
        {isModifying ? (
          <div className="overlay addingproduct">
            <div>Updating Product...</div>
          </div>
        ) : null}
      </div>
    </section>
  );
}
