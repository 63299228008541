import axios, { Axios } from 'axios';
//company
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

class PutatoeOrderApi {
  GetAllOrders(category, search_term) {
    return axios.get(API_BASE_URL + '/v1/api/all_orders/0', {
      headers: {
        authtoken: localStorage.getItem('authtoken'),
      },
    });
  }

  GetOldOrders() {
    return axios.get(API_BASE_URL + '/v1/api/old_orders', {
      headers: {
        authtoken: localStorage.getItem('authtoken'),
      },
    });
  }

  ConfirmOrder(id) {
    return axios.post(API_BASE_URL + '/v1/api/confirmOrder_new',{
      booking_id:id,
      serviceprovider_id:JSON.parse(localStorage.getItem("UserProfile")).service_provider_id
    }, {
      headers: {
        authtoken: localStorage.getItem('authtoken'),
      },
    });
  }

  CancelOrderPending(id) {
    return axios.post(API_BASE_URL + '/v1/api/cancelled_order_bysp_new',{
      booking_id:id,
      serviceprovider_id:JSON.parse(localStorage.getItem("UserProfile")).service_provider_id
    }, {
      headers: {
        authtoken: localStorage.getItem('authtoken'),
      },
    });
  }

  
  CompleteOrderOngoing(id) {
    return axios.post(API_BASE_URL + '/v1/api/complete_order_bysp_new',{
      booking_id:id,
      serviceprovider_id:JSON.parse(localStorage.getItem("UserProfile")).service_provider_id
    }, {
      headers: {
        authtoken: localStorage.getItem('authtoken'),
      },
    });
  }



  CancelOrder(order_id) {
    return axios.post(
      API_BASE_URL + '/v1/api/cancel_order_by_user',
      { order_id },
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }

  GetMyOrderAccDate(start_date,end_date,index)
  {
    return axios.post(
      API_BASE_URL + `/v1/api/orderhistory/${index}`,
      { start_date, end_date },
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }
  GetOrderHistory(start_date,end_date,index)
  {
    return axios.post(
      API_BASE_URL + `/v1/api/orderhistory_new/${index}`,
      { start_date, end_date },
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }
}

export default new PutatoeOrderApi();
