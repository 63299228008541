import React, { Component ,useRef} from 'react'
import './css/billmainview.css'
import mybillspage from '../../api/mybillspage';
import { useGlobalContext } from "../../context";
import logo from '../../images/logo.png';
import notify from '../../error';
import back from "../myprofileIcoons/bx-arrow-back..svg";
import noshow from '../Myorders/noOrders.png'
import { motion } from "framer-motion";
import myProfileServiceApi from "../../api/myProfileServiceApi";
import Loading from "../../components/Loading";
import PdfViewer from '../MyServices/PdfViewer';
import { Link } from 'react-router-dom';
export default function Mybillprofilepageviewmain()
{
  const { isMobileView,  loading, setLoading, Userprofile ,setInnerPage} =
  useGlobalContext();
const date = new Date();
const date2 = new Date();

function subtract15Days(date2) {
  const newDate = new Date(date2);
  newDate.setDate(date2.getDate() - 15);
  return newDate;
}
const newDate = subtract15Days(date2);
const [selectedProvider,setSelectedProvider]=React.useState(null);
 const [myBills,setMyBills]=React.useState([]);
 const [page,setPage]=React.useState(1)
 const [formData, setFormData] = React.useState({
   to: `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
     2,
     '0'
   )}-${String(date.getDate()).padStart(2, '0')}`,
   from: `${newDate.getFullYear()}-${String(newDate.getMonth() + 1).padStart(
     2,
     '0'
   )}-${String(newDate.getDate()).padStart(2, '0')}`,
   editFrom: `${newDate.getFullYear()}-${String(
     newDate.getMonth() + 1
   ).padStart(2, '0')}-${String(newDate.getDate()).padStart(2, '0')}`,
 });

   async function GetAllMyOrders() 
   {
     const response = await mybillspage.ShowBills(
       formData.from,
       formData.to
     );
     console.log(response);

     if (response.data && response.data.error != '') {
       notify('error', response.data.error);
     } else {
       setMyBills(response.data);
       console.log(response.data);
       const keys = Object.keys(response.data);
       setMyObjectKeyArray(keys);
     }
   }
    const [myObjectKeyArray, setMyObjectKeyArray] = React.useState([]);
   React.useEffect(() => {
     GetAllMyOrders();
   }, [formData.to, formData.from]);




const goBack=()=>{
setPage(2);
setInnerPage(false);
}





  



   

   //date manupulation
   //how to play with dates
   function handleChange(event) {
     console.log(event);
    //  setLoading(true);
     const { name, value, type, checked } = event.target;
     setFormData((prevFormData) => {
       return {
         ...prevFormData,
         [name]: type === 'checkbox' ? checked : value,
       };
     });
     if (name === 'editFrom') {
       console.log('here', value);
       const newDate = new Date();
       let now = new Date();
       now = getLastWeeksDate();

       function getLastWeeksDate() {
         const now = new Date();

         return new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
       }

       function getLast30Date() {
         const now = new Date();

         return new Date(now.getFullYear(), now.getMonth(), now.getDate() - 30);
       }
       function getLastmonthDate() {
         const now = new Date();

         return new Date(now.getFullYear(), now.getMonth() - 1, now.getDate());
       }
       function getLast6monthDate() {
         const now = new Date();

         return new Date(now.getFullYear(), now.getMonth() - 6, now.getDate());
       }
       function get2022Date() {
         const now = new Date();

         return new Date(now.getFullYear() - 1, 0, 1);
       }
       function get2021Date() {
         const now = new Date();

         return new Date(now.getFullYear() - 2, 0, 1);
       }
       function get2020Date() {
         const now = new Date();

         return new Date(now.getFullYear() - 3, 0, 1);
       }
       function get2019Date() {
         const now = new Date();

         return new Date(now.getFullYear() - 4, 0, 1);
       }
       function get2018Date() {
         const now = new Date();

         return new Date(now.getFullYear() - 5, 0, 1);
       }
       function get2017Date() {
         const now = new Date();

         return new Date(now.getFullYear() - 6, 0, 1);
       }
       if (value === '1') {
         console.log('here 1');
         setFormData((prevFormData) => {
           return {
             ...prevFormData,
             from: `${newDate.getFullYear()}-${String(
               newDate.getMonth() + 1
             ).padStart(2, '0')}-${String(newDate.getDate()).padStart(2, '0')}`,
             to: `${newDate.getFullYear()}-${String(
               newDate.getMonth() + 1
             ).padStart(2, '0')}-${String(newDate.getDate()).padStart(2, '0')}`,
           };
         });
       }

       if (value === '2') {
         console.log('here 2');

         setFormData((prevFormData) => {
           return {
             ...prevFormData,
             from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
               2,
               '0'
             )}-${String(now.getDate()).padStart(2, '0')}`,
             to: `${newDate.getFullYear()}-${String(
               newDate.getMonth() + 1
             ).padStart(2, '0')}-${String(newDate.getDate()).padStart(2, '0')}`,
           };
         });
       }

       if (value === '3') {
         console.log('here 3');
         now = getLast30Date();
         setFormData((prevFormData) => {
           return {
             ...prevFormData,
             from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
               2,
               '0'
             )}-${String(now.getDate()).padStart(2, '0')}`,
             to: `${newDate.getFullYear()}-${String(
               newDate.getMonth() + 1
             ).padStart(2, '0')}-${String(newDate.getDate()).padStart(2, '0')}`,
           };
         });
       }
       if (value === '4') {
         console.log('here 4');
         now = getLast6monthDate();
         setFormData((prevFormData) => {
           return {
             ...prevFormData,
             from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
               2,
               '0'
             )}-${String(now.getDate()).padStart(2, '0')}`,
             to: `${newDate.getFullYear()}-${String(
               newDate.getMonth() + 1
             ).padStart(2, '0')}-${String(newDate.getDate()).padStart(2, '0')}`,
           };
         });
       }
       if (value === '5') {
         console.log('here 5');
         now = get2022Date();
         setFormData((prevFormData) => {
           return {
             ...prevFormData,
             from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
               2,
               '0'
             )}-${String(now.getDate()).padStart(2, '0')}`,
             to: `${newDate.getFullYear()}-${String(
               newDate.getMonth() + 1
             ).padStart(2, '0')}-${String(newDate.getDate()).padStart(2, '0')}`,
           };
         });
       }
       if (value === '6') {
         // console.log('here 5');
         now = get2021Date();
         setFormData((prevFormData) => {
           return {
             ...prevFormData,
             from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
               2,
               '0'
             )}-${String(now.getDate()).padStart(2, '0')}`,
             to: `${newDate.getFullYear()}-${String(
               newDate.getMonth() + 1
             ).padStart(2, '0')}-${String(newDate.getDate()).padStart(2, '0')}`,
           };
         });
       }
       if (value === '7') {
         // console.log('here 5');
         now = get2020Date();
         setFormData((prevFormData) => {
           return {
             ...prevFormData,
             from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
               2,
               '0'
             )}-${String(now.getDate()).padStart(2, '0')}`,
             to: `${newDate.getFullYear()}-${String(
               newDate.getMonth() + 1
             ).padStart(2, '0')}-${String(newDate.getDate()).padStart(2, '0')}`,
           };
         });
       }
       if (value === '8') {
         // console.log('here 5');
         now = get2019Date();
         setFormData((prevFormData) => {
           return {
             ...prevFormData,
             from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
               2,
               '0'
             )}-${String(now.getDate()).padStart(2, '0')}`,
             to: `${newDate.getFullYear()}-${String(
               newDate.getMonth() + 1
             ).padStart(2, '0')}-${String(newDate.getDate()).padStart(2, '0')}`,
           };
         });
       }
       if (value === '9') {
         console.log('here 5');
         now = get2018Date();
         setFormData((prevFormData) => {
           return {
             ...prevFormData,
             from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
               2,
               '0'
             )}-${String(now.getDate()).padStart(2, '0')}`,
             to: `${newDate.getFullYear()}-${String(
               newDate.getMonth() + 1
             ).padStart(2, '0')}-${String(newDate.getDate()).padStart(2, '0')}`,
           };
         });
       }

       if (value === '10') {
         // console.log('here 5');
         now = get2017Date();
         setFormData((prevFormData) => {
           return {
             ...prevFormData,
             from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
               2,
               '0'
             )}-${String(now.getDate()).padStart(2, '0')}`,
             to: `${newDate.getFullYear()}-${String(
               newDate.getMonth() + 1
             ).padStart(2, '0')}-${String(newDate.getDate()).padStart(2, '0')}`,
           };
         });
       }
     }
     console.log(formData);
   }


   const [billData,setBillData]=React.useState();

   const mybilluppersection = ()=>{
    return (
      <>
      <div className='my-bills-profile-page-view-main-left'>
           <div className='my-bills-profile-page-view-left-top'>
             <div className='my-bills-profile-page-view-left-top-heading'>
               <span>MY BILLS</span>
             </div>
           </div>
           <section className='my-bills-options'>
           <div
             className='profile-account-setting-container-left-1'
             onClick={() => {
               setPage(1);
               console.log(page);
             }}
           >
             {page === 1 ? (
               <div className='profile-account-setting-container-left-1-selected'></div>
             ) : (
               <div className='profile-account-setting-container-left-1-unselected'></div>
             )}
             <span>Advanced ({myBills?.advance?.length})</span>
           </div>
           {/* 2 */}
           <div
             className='profile-account-setting-container-left-1'
             onClick={() => {
               setPage(2);
               console.log(page);
             }}
           >
             {page === 2 ? (
               <div className='profile-account-setting-container-left-1-selected'></div>
             ) : (
               <div className='profile-account-setting-container-left-1-unselected'></div>
             )}
             <span>Due ({myBills?.due?.length})</span>
           </div>
           <div
             className='profile-account-setting-container-left-1'
             onClick={() => {
               setPage(3);
               console.log(page);
             }}
           >
             {page === 3 ? (
               <div className='profile-account-setting-container-left-1-selected'></div>
             ) : (
               <div className='profile-account-setting-container-left-1-unselected'></div>
             )}
             <span>No Due ({myBills?.no_due?.length})</span>
           </div>
           </section>
         </div>
      </>
    )
   }

 return (
   <React.Fragment>
     <>
       <div className='my-bills-profile-page-view-main'>
      { 
      !isMobileView ? <>{mybilluppersection()}</> : <>

      {
        page !== 4 && <>{mybilluppersection()}</>
      }
      </>
}
         {page !== 4 ? (
           <div className='my-bills-profile-page-view-main-right my-bills-profile-page-view-main-right2'>
             <div className='my-bills-profile-page-view-main-right-top'>
               <div className='my-order-profile-time-filter-calander-box'>
                 <div className='my-order-profile-time-filter-calander-1' style={{marginLeft:"0px"}}>
                   <span>From</span>
                   <input
                     type='date'
                     onChange={handleChange}
                     name='from'
                     value={formData.from}
                   ></input>
                 </div>
                 <div className='my-order-profile-time-filter-calander-1'>
                   <span>Till</span>
                   <input
                     type='date'
                     onChange={handleChange}
                     name='to'
                     value={formData.to}
                   ></input>
                 </div>
                 <div className='my-order-profile-time-filter-calander-3'>
                   <span>Sort By : </span>
                   <select onChange={handleChange} name='editFrom'>
                     <option value={0}>Time Filter</option>
                     <option value={1}>Today</option>
                     <option value={2}>Last One Week</option>
                     <option value={3}>Last 30 Days</option>
                     <option value={4}>Last 6 Months</option>
                     <option value={5}>2022</option>
                     <option value={6}>2021</option>
                     <option value={7}>2020</option>
                     <option value={8}>2019</option>
                     <option value={9}>2018</option>
                     <option value={10}>2017</option>
                   </select>
                 </div>
               </div>
             </div>

             <div className='my-bills-profile-page-view-main-right-main-box-before'>
               Total{' '}
               <span>
                 {page === 1 ? (
                   <>{myBills.advance && myBills.advance.length}</>
                 ) : page === 2 ? (
                   <>{myBills.due && myBills.due.length}</>
                 ) : (
                   <>{myBills.no_due && myBills.no_due.length}</>
                 )}
               </span>{" "}
               Transaction(s) Done
             </div>
             <div className='my-bills-profile-page-view-main-right-main-box'>
               {page === 1 ? (
                 myBills &&
                 myBills.advance &&
                 myBills.advance.length>0?(
                 myBills.advance.map((item) => {
                   return (
                     <div className='my-bills-profile-page-view-main-right-main-box-card'>
                       <div className='my-bills-profile-page-view-main-right-main-box-card-img'>
                         <img src={item.Serviceprovider_image}></img>
                       </div>
                       <div className='my-bills-profile-page-view-main-right-main-box-card-provider my-bills-profile-page-view-main-right-main-box-card-provider2'>
                         {item.ServiceProvider_name}
                       </div>
                       <div className='my-bills-profile-page-view-main-right-main-box-card-price'>
                       <span onClick={()=>{setPage(4);
                          setSelectedProvider(item);
                          setInnerPage(true);
                          }}>&#8377;{item.amount}</span>
                       </div>
                     </div>
                   );
                 })
                 ):( <div className='my-bills-profile-page-view-main-right-main-box-no-transactions'>
                 <img src={noshow} alt='No Transactions' />
               </div>)
                 
               ) : page === 2 ? (
                 myBills &&
                 myBills.due &&
                 myBills.due.length > 0 ? (
                 myBills.due.map((item) => {
                   return (
                     <div className='my-bills-profile-page-view-main-right-main-box-card'>
                       <div className='my-bills-profile-page-view-main-right-main-box-card-img'>
                         <img src={item.Serviceprovider_image}></img>
                       </div>
                       <div className='my-bills-profile-page-view-main-right-main-box-card-provider my-bills-profile-page-view-main-right-main-box-card-provider2'>
                         {item.ServiceProvider_name}
                       </div>
                       <div className='my-bills-profile-page-view-main-right-main-box-card-price'>
                         <span onClick={()=>{setPage(4);
                          setSelectedProvider(item);
                          setInnerPage(true);
                          }}>&#8377;{item.amount}</span>
                       </div>
                     </div>
                   );
                 })
                 ):(
                  <div className='my-bills-profile-page-view-main-right-main-box-no-transactions'>
                 <img src={noshow} alt='No Transactions' />
               </div>
                 )


               ) : page === 3 ? (
                myBills && myBills.no_due && myBills.no_due.length > 0 ? (
                 myBills.no_due.map((item) => {
                   return (
                     <div className='my-bills-profile-page-view-main-right-main-box-card'>
                       <div className='my-bills-profile-page-view-main-right-main-box-card-img'>
                         <img src={item.Serviceprovider_image}></img>
                       </div>
                       <div className='my-bills-profile-page-view-main-right-main-box-card-provider my-bills-profile-page-view-main-right-main-box-card-provider2'>
                         {item.ServiceProvider_name}
                       </div>
                       <div className='my-bills-profile-page-view-main-right-main-box-card-price'>
                       <span onClick={()=>{setPage(4);
                          setSelectedProvider(item);
                          setInnerPage(true);
                          }}>&#8377;{item.amount}</span>
                       </div>
                     </div>
                   );
                 })
               ):(
                <div className='my-bills-profile-page-view-main-right-main-box-no-transactions'>
                <img src={noshow} alt='No Transactions' />
              </div>
               )
               )
               : (
                 <>

    
           </>
               )
               }
             </div>
           </div>
         ) : (
           <div className='my-bills-profile-page-view-main-right'>
             <BillDetailsView goBack={goBack}currentprovider={selectedProvider} isMobileView={isMobileView}  loading={loading} setLoading={setLoading} Userprofile={Userprofile} ></BillDetailsView>
           </div>
         )}
       </div>
     </>
   </React.Fragment>
 );
}



function BillDetailsView({goBack,currentprovider,isMobileView,  loading, setLoading,Userprofile})
{
  const [isOpendropdown, setIsOpendropdown] = React.useState(false);
  const [page2,setPage2]=React.useState(1);
  const [myOrders, setMyOrders] = React.useState([]);
  const [myOrders2, setMyOrders2] = React.useState([]);
  const [billData, setBillData] = React.useState();
console.log(currentprovider);

const exitDetail = () => {

  setPage2(1);
  
};

React.useEffect(()=>{
 Getalloutgoingincomingbills();
},[])

const {ServiceProvider_id}=currentprovider;
const Getalloutgoingincomingbills=async()=>{
  setLoading(true);
  const response = await mybillspage.ShowIncomingOutgoingBills(
 ServiceProvider_id
  );
  console.log(response);

  if (response.data && response.data.error != '') {
    notify('error', response.data.error);
  } else {
    console.log(response.data);
response.data.offline_order.map((item,index)=>{
  if(item.incoming == 1 && item.outgoing == 0){
    setMyOrders((myorders)=>{
      return [...myorders,item]
    })
  }
  else if (item.incoming == 0 && item.outgoing == 1){
    setMyOrders2((myorders2)=>{
      return [...myorders2,item]
    })
  }
})
}
setLoading(false);
 }
  const toggleDropdown = () => {
    setIsOpendropdown(!isOpendropdown);
  };
  const [isModalprodtransOpen, setIsModalprodtransOpen] = React.useState(false);
  //for trans summary
  const [isModaltranssummaryOpen, setIsModaltranssummaryOpen] = React.useState(false);
  const [usersummarymodal, setUsersummarymodal] = React.useState(false);
  const [selectedProductsummary, setSelectedProductsummary] = React.useState({
    prodtypename: "",
    prodtypeid: "",
    prodname: "",
    quantityname:"",
    quantityid:"",
    prodid: "",
    userid: "",
    user: ""
  });
  const [pagefortrans, setPagefortrans] = React.useState(0);
  const [summarydata, setSummarydata] = React.useState("");
  const [summarydata2, setSummarydata2] = React.useState("");

  //for prod trans
  const [searchQuery, setSearchQuery] = React.useState('');
  const [productTransactions, setProductTransactions] = React.useState([]);

  const [selectedTransaction, setSelectedTransaction] = React.useState({
    prodtypename: "",
    prodtypeid: "",
    prodname: "",
    quantityname:"",
    quantityid:"",
    prodid: ""
  });

  React.useEffect(() => {
    async function fetchproductTranslist() {
      const response = await myProfileServiceApi.fetchProductTranscationlist(Userprofile.service_provider_id);
      if (response.data && response.data.error != "") {
        notify("error", response.data.error);
      } else {
        setProductTransactions(response.data);
      }
    }

    fetchproductTranslist();

  }, []);
  const openProductTransactions = () => {
    setIsModalprodtransOpen(true);
    setTimeout(() => { setIsOpendropdown(!isOpendropdown) }, 1000)

  };
  const closeProductTransactions = () => {
    setIsModalprodtransOpen(false);
  };

  //for transaction summary
  const openTransactionsSummary = () => {
    // setPage(3);
    setSelectedProductsummary({
      prodtypename: "",
      prodtypeid: "",
      quantityname:"",
      quantityid:"",
      prodname: "",
      prodid: "",
      userid: "",
      user: ""
    })
  }

  const openTransactionSummaryModal = () => {

    setIsModaltranssummaryOpen(true);
  }

  const closeTransactionSummaryModal = () => {
    setIsModaltranssummaryOpen(false);

  }
  const handleSelectProdtrans = () => {
    // Handle the selection logic here
    console.log(selectedTransaction);
    Getalloutgoingincomingbills();
    setTimeout(() => { closeProductTransactions() }, 1000)

  };

  return (
    <React.Fragment>
      {page2!==3 && (
    <>
    
      <div className='my-bills-show-incoming-outgoing'>
        <div className="my-service-profile-ongoing-and-pending-online-back">
                  <img src={back} onClick={goBack} alt="" />
                </div>
          <div className='my-bills-profile-page-view-main-right-heading-data' value="showbills">
            <span style={{border:"none"}}>Show Bills</span>
          </div>
          <div className="my-order-profile-dropdown">

            <div className="ellipsis" onClick={toggleDropdown}>
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
            </div>
            </div>
 
        </div>

{isOpendropdown && (
<div className="dropdown-content">
  <ul>
    <li onClick={openProductTransactions}>Product Transactions</li>
    <li onClick={""}>Transactions Summary</li>

  </ul>
</div>
)}

{isModalprodtransOpen && (
                <ProductTransactionsModal
                  isOpen={isModalprodtransOpen}
                  onClose={closeProductTransactions}
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                  productTransactions={productTransactions}
                  handleSelect={handleSelectProdtrans}
                  setSelectedTransaction={setSelectedTransaction}
                  selectedTransaction={selectedTransaction}
                />
              )}

                 
        <div className='my-bills-profile-page-view-main-right-data-box'>
         <div className="my-show-bills-profile-ongoing-and-pending-online-selector">
                <div className="my-service-profile-ongoing-and-pending-online-selector-tab incoming_outgoingsections">
                  <motion.span
                    onClick={() => {
                      setPage2(1);
                    }}
                    animate={{
                      color: page2 === 1 ? "black" : "rgba(0, 0, 0, 0.512)",
                      "border-bottom":
                        page2 === 1 ? "3px solid #1D8B97" : "none",
                    }}
                    whileHover={{ color: "black" }}
                  >
                    Incoming
                  </motion.span>
                  <motion.span
                    onClick={() => {
                      setPage2(2);
                    }}
                    animate={{
                      color: page2 === 2 ? "black" : "rgba(0, 0, 0, 0.512)",
                      "border-bottom":
                        page2 === 2 ? "3px solid #1D8B97" : "none",
                    }}
                    whileHover={{ color: "black" }}
                  >
                    Outgoing
                  </motion.span>
                  </div>
                  </div>
                  {page2 === 1 ? (
                  <>
                    <div className="my-bill-show-profile-ongoing-and-pending-box ">
                      {myOrders && myOrders.length !== 0 ? (
                        myOrders.map((item) => {
                          return (
                            <>
                              {!isMobileView ? (
                                <div className="my-bills-profile-page-view-main-right-main-box-card">
                                  {/* <div className='my-bills-profile-page-view-main-right-main-box-card-img'>
                                  <img></img>
                                </div> */}
                                 
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-des">
                                    <span>Order Id : {item.order_id}</span>
                                    {/* <span>{item.datetime.slice(5, 16)}</span> */}
                                 
                                  </div>
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-time">
                                    <span>
                                      Order type : {item.current_status}
                                    </span>
                                    {/* <button>Complete Order</button> */}
                                  </div>
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-des">
                                    {item.datetime.slice(0, 16)}
                                  </div >
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-price">
                                    <span>&#8377;{item.amt}</span>
                                    <div className="buttons-container">
                                      <button
                                        onClick={() => {
                                          setPage2(3);
                                          setBillData(item);
                                        }}
                                      >
                                        View Items
                                      </button>
                                     
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className="my-bills-profile-page-view-main-right-main-box-card-mobileview">
                                  {/* <div className='my-bills-profile-page-view-main-right-main-box-card-img'>
                                  <img></img>
                                </div> */}
                               
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-des">
                                    <span>Order Id : {item.order_id}</span>
                                    {/* <span>{item.datetime.slice(5, 16)}</span> */}
                                 
                                  </div>
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-time">
                                    <span>
                                      <span
                                        style={{
                                          fontWeight: "normal",
                                          color: "rgba(0, 0, 0, 0.476)",
                                        }}
                                      >
                                        Order type :
                                      </span>{" "}
                                      {item.current_status}
                                    </span>
                                    {/* <button>Complete Order</button> */}
                                  </div>
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-des">
                                    Order Date : {item.datetime.slice(0, 16)}
                                  </div>
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-price">
                                    <div>
                                      {" "}
                                      <span
                                        style={{
                                          fontWeight: "normal",
                                          color: "rgba(0, 0, 0, 0.476)",
                                        }}
                                      >
                                        Amount:
                                      </span>{" "}
                                      &#8377;{item.amt}
                                    </div>
                                    <div className="buttons-container">
                                      <button
                                            onClick={() => {
                                              setPage2(3);
                                              setBillData(item);
                                            }}
                                      >
                                        View Items
                                      </button>
                                     
                                    </div>
                                  </div>
                                </div>
                              )}
                            </>
                          );
                        })
                      ) : (
                        <div className="NoShowCenter">
                          {loading ? <Loading /> : "nothing to show..."}
                        </div>
                      )}
                    </div>
                  </>
                ) : page2 === 2 &&(
                  <>
                    <div className="my-bill-show-profile-ongoing-and-pending-box">
                      {myOrders2 && myOrders2.length !== 0 ? (
                        myOrders2.map((item) => {
                          return (
                            <>
                              {!isMobileView ? (
                                <div className="my-bills-profile-page-view-main-right-main-box-card">
                                  
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-des">
                                    <span>Order Id : {item.order_id}</span>
                                    {/* <span>{item.datetime.slice(5, 16)}</span> */}
                                   
                                  </div>
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-time">
                                    <span>Status : {item.current_status}</span>
                                  </div>
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-des">
                                    {item.datetime.slice(0, 16)}
                                  </div>
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-price">
                                    <span>&#8377;{item.amt}</span>
                                    <div className="buttons-container">
                                      <button
                                            onClick={() => {
                                              setPage2(3);
                                              setBillData(item);
                                            }}
                                      >
                                        View Items
                                      </button>
                                     
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className="my-bills-profile-page-view-main-right-main-box-card-mobileview">
                                
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-des">
                                    <span>Order Id : {item.order_id}</span>
                                    {/* <span>{item.datetime.slice(5, 16)}</span> */}
                              
                                  </div>
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-time">
                                    <span>
                                      <span
                                        style={{
                                          fontWeight: "normal",
                                          color: "rgba(0, 0, 0, 0.476)",
                                        }}
                                      >
                                        Order type :
                                      </span>{" "}
                                      {item.current_status}
                                    </span>
                                    {/* <button>Complete Order</button> */}
                                  </div>
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-des">
                                    Order Date :{item.datetime.slice(0, 16)}
                                  </div>
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-price">
                                    <div>
                                      {" "}
                                      <span
                                        style={{
                                          fontWeight: "normal",
                                          color: "rgba(0, 0, 0, 0.476)",
                                        }}
                                      >
                                        Amount:
                                      </span>{" "}
                                      &#8377;{item.amt}
                                    </div>
                                    <div className="buttons-container">
                                      <button
                                          onClick={() => {
                                            setPage2(3);
                                            setBillData(item);
                                          }}
                                      >
                                        View Items
                                      </button>
                                  
                                    </div>
                                  </div>
                                </div>
                              )}
                            </>
                          );
                        })
                      ) : (
                        <div className="NoShowCenter">
                          {loading ? <Loading /> : "nothing to show..."}
                        </div>
                      )}
              </div>
                  </>
                )}
                       
           </div>
      </>
      )}
                {page2===3 &&(  <div className="my-bills-profile-page-view-main-right">
                  <BillDetailsView2
                    data={billData}
                    isMobileView={isMobileView}
                    return={exitDetail}
                  ></BillDetailsView2>
                </div>
                )}
    </React.Fragment>
  );
}



function BillDetailsView2(props) {
  const [isDropdownVisible, setDropdownVisible] = React.useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  return (
      <React.Fragment>
        <>
          <div className="my-bills-profile-page-view-main-right-data">
            {/* <div className='my-service-profile-ongoing-and-pending-online-back'></div> */}
            <div className="my-bills-profile-page-view-main-right-heading-data my-bills-profile-page-view-main-right-data-bill-option" style={{padding:"0 5px"}}>
              <div>
              <img src={back} onClick={props.return} className='bacckbutton'></img>
              <span className='mobilefont'>View Details</span>
              </div>

            
            <div className="my-order-profile-dropdown">

<div className="ellipsis" onClick={toggleDropdown} >
  <div className="dot"></div>
  <div className="dot"></div>
  <div className="dot"></div>
</div>
</div>
</div>
            <div className="my-bills-profile-page-view-main-right-data-box">
              {props &&
                props.data &&
                props.data.items &&
                props.data.items.length > 0 &&
                props.data.items.map((item) => {
                  return (
                    <div className="my-bills-profile-page-view-main-right-data-box-card">
                      <div className="my-bills-profile-page-view-main-right-data-box-card-img">
                      <div className='my-bills-profile-page-view-main-right-data-box-card-img'>
                      {/* {item.color && item.color.length > 0 ? (
                        // If color array is not empty, use the color and image from it


                        <img src={item.color[0].images[0]}></img>

                      ) : (
                        // Otherwise, use the default image
                        <img src={item.url || logo}></img>
                      )} */}
                      {
                        item.image ? <img src={item.image}></img> : <img src={logo}></img>
                      }
                    </div>
                      </div>
                      <div className="my-bills-profile-page-view-main-right-data-box-card-des">
                        <span>{item.brand}</span>
                        <span>{item.name}</span>
                        <span>{item.product_type}</span>
                                      
                      {!props.isMobileView && (   <span>{item.product_quantity}</span>)}
                       {/* Show qty, unit, and color in mobile view */}
 {props.isMobileView && (
 <div className='my-bills-profile-page-view-main-right-data-box-card-mobile-info'>
  <span>{item.product_quantity}</span> 
      <span> | {item.quantity} Units </span>
      
      {item.color && item.color.length > 0 && (
        <div className='my-bills-profile-page-view-main-right-data-box-card-color'>
         | <div
            className="initial-choice service-color-choice"
            style={{
              backgroundColor: item.color[0].color_code,
            }}
          >
          </div>{" "}
          {item.color[0].color_name}
        </div>
      )}
    </div>)}
                      </div>
                      {!props.isMobileView && item.color && item.color.length > 0 && <div className='my-bills-profile-page-view-main-right-data-box-card-color'>
                      <div className="initial-choice service-color-choice" style={{
                        backgroundColor:
                          item.color[0].color_code,
                      }}
                      >
                      </div> {item.color[0].color_name}
                    </div>}
                   { !props.isMobileView &&   <div className="my-bills-profile-page-view-main-right-data-box-card-qty">
                        Units : {item.quantity}
                      </div>}
                      <div className="my-bills-profile-page-view-main-right-data-box-card-price">
                        <span>&#8377; {item.price}</span>
                      </div>
                    </div>
                  );
                })}
            </div>
            {isDropdownVisible && (
          <div className="dropdown-content">
    
          <Link to={`/viewpdf/offline/${props.data.order_id}/`} target="_blank"><button >View Bill</button></Link>
          </div>
            )}
          </div>
        </>
      </React.Fragment>
      );
}





function TransactionSummary({isOpen, onClose, searchQuery, setSearchQuery, productTransactions, setSelectedTransaction, selectedTransaction,  Userprofile, pagefortrans}) {
  const [showFilteredItems, setShowFilteredItems] = React.useState(false);
      const [searchuser, setSearchuser] = React.useState({
        searchname:"",
      searchphone:""
  });
      const [showcustomername, setShowcustomername] = React.useState([])
      const [Productsnamesfilter, setProductsnamesfilter] = React.useState([])
      const [Quantitynamesfilter, setQuantitynamesfilter] = React.useState([])
      const [isLoading, setIsLoading] = React.useState(false);
  
  const handleprodTransactionClick = (producttrans) => {
        console.log(producttrans);
      const {product_type_name, product_type_id} = producttrans
      setSelectedTransaction({
        prodtypename: product_type_name,
      prodtypeid: product_type_id,
      quantityname:"",
      quantityid:"",
      prodname:"",
      prodid:"",
      userid: "",
      user: "" 
        
      });

      onClose();

  
    
  }

  const handleTransactionClick2 = (producttrans) => {
        console.log(producttrans);
      const {product_name, product_id} = producttrans
    setSelectedTransaction(prev => ({
        ...prev,
        prodname: product_name,
      prodid: product_id
    }));
      onClose();

  };

  const handleTransactionClick3 = (producttrans) => {
    console.log(producttrans);
  const {qty_name, qty_id} = producttrans
setSelectedTransaction(prev => ({
    ...prev,
    quantityname: qty_name,
  quantityid: qty_id
}));
  onClose();

};

const handleTransactionClick4 = (users) => {
  console.log(users);
const {user, user_id} = users
console.log(user,user_id);
setSelectedTransaction(prev => ({
  ...prev,
  userid: user_id,
  user: user
}));
onClose();

};

  React.useEffect(() => {
    console.log("enter2");
    console.log(selectedTransaction);
    if (selectedTransaction && selectedTransaction.prodtypeid!="" ) {

      const result2 = productTransactions.type_details[selectedTransaction.prodtypeid];
      setProductsnamesfilter(result2);
console.log("re");
    }

     if(selectedTransaction && selectedTransaction.prodid!="" )
    {
      console.log("enter");
     let result;
      if (productTransactions.qty_details && productTransactions.qty_details[selectedTransaction.prodid]) {
        result = productTransactions.qty_details[selectedTransaction.prodid];
        console.log(result);
      } else {
        result = []; 
      }
      setQuantitynamesfilter(result);
    }

  
  }, [selectedTransaction,pagefortrans]);

    // Function to filter transactions based on search query
    const filterTransactions = () => {
      if (!searchQuery) {
        setShowFilteredItems(false); // Show all items when search query is empty
      } else {
        setShowFilteredItems(true); // Show filtered items when search query is not empty
      }
    };
    const handleBackClick = () => {
        onClose();

    }



const handleSearchuserChange = async(e) => {

  const input = e.target.value;
  console.log(input);

  if (!input){
    setSearchuser({
      searchname: "",
      searchphone: ""
    });
    setShowcustomername([]);
    return;
} else if(isNaN(input)) {
        setSearchuser({
          searchname: input,
          searchphone: "", 
        });
  } else if(input) {
        setSearchuser(prev => ({
          searchname: "",
          searchphone: input
        }));
  } 
  
  
 
};

React.useEffect(() => {
  let searchTimeout;
  
  const fetchcustomer = async () => {

    if (
      searchuser &&
      (searchuser.searchname !== "" || searchuser.searchphone !== "")
    ) {
      setIsLoading(true);
      const response = await myProfileServiceApi.fetchusersfortrancation(
        Userprofile.service_provider_id,
        searchuser
      );
      console.log(response);
      console.log(response.data.allCustomer);
      setShowcustomername(response.data.allCustomer);
      setIsLoading(false);
      return;
    } else {
      setSearchuser({
        searchname: "",
        searchphone: "",
      });
    }
    return () => {
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }
    };
  };
 // Check if both fields are empty or cleared
   if (!searchuser.searchname && !searchuser.searchphone) {

    return;
  }
 // Set a new timeout for making the API call
 searchTimeout = setTimeout(() => {
  if (searchuser.searchname || searchuser.searchphone) {
    fetchcustomer();
  }
}, 1000);

// Clear the timeout when the component unmounts
return () => {
  if (searchTimeout) {
    clearTimeout(searchTimeout);
  }
};
}, [searchuser.searchname, searchuser.searchphone]);


const modalRef = useRef();

React.useEffect(() => {
  function handleClickOutside(event) {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      // Click occurred outside of the modal, close it here
      onClose(); 
    }
  }

  // Attach the event listener
  document.addEventListener('mousedown', handleClickOutside);

  // Clean up the event listener when the component unmounts
  return () => {
    document.removeEventListener('mousedown', handleClickOutside);
  };
}, []);



 


      return (
      <>
        <div  className={`product-transactions-modal ${isOpen ? 'open' : ''}`}>
          <div className="product-transactions-modal-content" ref={modalRef} >

            {pagefortrans===1 ? (
              <>

                {/* Show product names here */}

                <div className="product-transactions-modal-body">

                  <ul className="product-transactions-transaction-list">
                  <li onClick={() => {
                   
                   setSelectedTransaction(prev => ({
                     ...prev,
                     prodname: "All Products",
                     prodid: 0,
                 }));
                   onClose();
                 }}>
                   All Products 
                 </li>
                    {Productsnamesfilter.length > 0 && Productsnamesfilter.map(product => (

                      <li
                        key={product.product_id}
                        onClick={() => handleTransactionClick2(product)}
                      >
                        {product.product_name}
                      </li>
                    ))}

                  </ul>

                </div>
              </>
            )
              : pagefortrans===0? <>
                <div className="product-transactions-modal-header"> <input
                  type="text"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onKeyDown={filterTransactions}
                />
                  <button className="close-button" style={{ marginTop: "-20px", marginLeft: "10px" }} onClick={handleBackClick}>
                    &times;
                  </button>
                </div>
                <div className="product-transactions-modal-body">

                  <ul className="product-transactions-transaction-list">
                    <li onClick={() => {
                   
                      setSelectedTransaction(prev => ({
                        ...prev,
                        prodtypename: "All Products Type",
                        prodtypeid: 0,
                    }));
                      onClose();
                    }}>
                      All Products Type
                    </li>
                    {(showFilteredItems
                      ? productTransactions.producttypes.filter((product) =>
                        product.product_type_name.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1
                      )
                      : productTransactions.producttypes
                    ).map((product) => (
                      <li
                        key={product.product_type_id}
                        onClick={() => handleprodTransactionClick(product)}
                      >
                        {product.product_type_name}
                      </li>
                    ))}
                  </ul>
                </div>
              </> :  pagefortrans!==2?(<>

                <div className="product-transactions-modal-body">
                  <label >Search Users</label>
                  <input type="text" placeholder="Enter name or phone number"
                    value={searchuser.name || searchuser.phone}
                    onChange={handleSearchuserChange}
                
                  />
                      {isLoading && <span className="loader-trans"></span>}
                  <label >Select User</label>
                  <ul className="product-transactions-transaction-list">
                  <li onClick={() => {
                   
                   setSelectedTransaction(prev => ({
                     ...prev,
                     user: "All Users",
                     userid: 0,
                 }));
                   onClose();
                 }}>
                   All Users
                 </li>
                    {showcustomername && showcustomername.length > 0 && showcustomername.map(user => (
                      <li key={user.user_id} onClick={()=>handleTransactionClick4(user)}>
                       {user.user+" "}
                         {user.phone}
                      </li>
                    ))}
                  </ul>
                  <div className="product-transactions-modal-footer">
                    <button onClick={handleBackClick}>ok</button>
                  </div>
                </div>
              </>)
              :(<>
              
              <div className="product-transactions-modal-body">

<ul className="product-transactions-transaction-list">
<li onClick={() => {
                   
                   setSelectedTransaction(prev => ({
                     ...prev,
                     quantityname: "All Quantity",
                     quantityid: 0
                 }));
                   onClose();
                 }}>
                   All Quantity
                 </li>
  {Quantitynamesfilter.length > 0 && Quantitynamesfilter.map(product => (

    <li
      key={product.qty_id}
      onClick={() => handleTransactionClick3(product)}
    >
      {product.qty_name}
    </li>
  ))}

</ul>


</div>
              </>)


            }
          </div>
        </div>
      </>
      )

    }


    function ProductTransactionsModal({isOpen, onClose, searchQuery, setSearchQuery, productTransactions, handleSelect, setSelectedTransaction, selectedTransaction}) {



      const [showFilteredItems, setShowFilteredItems] = React.useState(false);
          const [showProductsnames, setShowProductsnames] = React.useState(false);//show products name according to selected product type
          const [showProductsquantity, setShowProductquantity] = React.useState(false);
          const [Productsnamesfilter, setProductsnamesfilter] = React.useState([])
          const [Quantitynamesfilter, setQuantitynamesfilter] = React.useState([])
      const handleTransactionClick = (producttrans) => {
            console.log(producttrans);
          const {product_type_name, product_type_id} = producttrans
          setSelectedTransaction(prev => ({
            ...prev,
            prodtypename: product_type_name,
            prodtypeid: product_type_id,
        }));
    
          setShowProductsnames(true);
    
      };
      const handleTransactionClick2 = (producttrans) => {
            console.log(producttrans);
          const {product_name, product_id} = producttrans
          setSelectedTransaction(prev => ({
            ...prev,
            prodname: product_name,
          prodid: product_id
        }));
    setShowProductquantity(true);
    
    
      };
      const handleTransactionClick3 = (quantity) => {
        console.log(quantity);
      const {qty_name, qty_id} = quantity
      setSelectedTransaction(prev => ({
        ...prev,
        quantityname: qty_name,
      quantityid: qty_id
    }));
    
    
    };
    
      React.useEffect(() => {
    
        if (selectedTransaction && selectedTransaction.prodtypeid!="") {
          const result = productTransactions.type_details[selectedTransaction.prodtypeid];
          setProductsnamesfilter(result);
        }
        if(Productsnamesfilter && Productsnamesfilter.length>0 && selectedTransaction && selectedTransaction.prodid!=""){
          const result2 = productTransactions.qty_details[selectedTransaction.prodid];
    setQuantitynamesfilter(result2);
        }
      }, [selectedTransaction]);
    
      // Function to filter transactions based on search query
      const filterTransactions = () => {
        if (!searchQuery) {
            setShowFilteredItems(false); // Show all items when search query is empty
        } else {
            setShowFilteredItems(true); // Show filtered items when search query is not empty
        }
      };
    
    
      const handleBackClick = () => {
            setSelectedTransaction({    prodtypename: "",
            prodtypeid: "",
            prodname: "",
            quantityname:"",
            quantityid:"",
            prodid: ""});
          setShowProductsnames(false);
     
      }
      const handleBackClick2 = () => {
        setShowProductquantity(false);
    }
          return (
          <div className={`product-transactions-modal ${isOpen ? 'open' : ''}`}>
            <div className="product-transactions-modal-content">
    
    
              {showProductsnames && !showProductsquantity ? (
                <>
    
                  {/* Show product names here */}
                  <div className="product-transactions-modal-header">
                    <h2>Select Product Name</h2>
                    <button className="close-button" onClick={handleBackClick}>
                      &times;
                    </button>
                  </div>
    
                  <div className="product-transactions-modal-body">
                    {selectedTransaction && (
                      <div className="product-transactions-selected-transaction">
                        <span>Selected Product Type :</span>  {selectedTransaction.prodtypename}
                        <br />
                        {selectedTransaction.prodname && <span style={{ marginTop: "10px" }}>Selected Product Name: {selectedTransaction.prodname}</span>}
                      </div>
                    )}
                    <ul className="product-transactions-transaction-list">
                      {Productsnamesfilter.length > 0 && Productsnamesfilter.map(product => (
    
                        <li
                          key={product.product_id}
                          onClick={() => handleTransactionClick2(product)}
                        >
                          {product.product_name}
                        </li>
                      ))}
    
                    </ul>
                    <div className="product-transactions-modal-footer">
                      <button onClick={handleSelect}>Select</button>
                      <button onClick={onClose}>Cancel</button>
                    </div>
                  </div>
                </>
              ) : !showProductsquantity ?(
                <>
                  <div className="product-transactions-modal-header">
                    <h2>Select Product Type</h2>
                    <button className="close-button" onClick={onClose}>
                      &times;
                    </button>
                  </div>
    
                  <input
                    type="text"
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onKeyDown={filterTransactions}
                  />
                  <div className="product-transactions-modal-body">
    
                    <ul className="product-transactions-transaction-list">
                  
                      {(showFilteredItems
                        ? productTransactions.producttypes.filter((product) =>
                          product.product_type_name.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1
                        )
                        : productTransactions.producttypes
                      ).map((product) => (
                        <li
                          key={product.product_type_id}
                          onClick={() => handleTransactionClick(product)}
                        >
                          {product.product_type_name}
                        </li>
                      ))}
                      
                    </ul>
                    {selectedTransaction && (
                      <div className="product-transactions-selected-transaction">
                        <span>Selected Product :</span>  {selectedTransaction.prodtypename}
                      </div>
                    )}
    
    
                    <div className="product-transactions-modal-footer">
                      <button onClick={handleSelect}>Select</button>
                      <button onClick={onClose}>Cancel</button>
                    </div>
                  </div>
                </>
              )
            :
                showProductsquantity && <>
                <div className="product-transactions-modal-header">
                    <h2>Select Product Quantity</h2>
                    <button className="close-button" onClick={handleBackClick2}>
                      &times;
                    </button>
                  </div>
    
            
                  <div className="product-transactions-modal-body">
                  {selectedTransaction && (
                      <div className="product-transactions-selected-transaction">
                        <span>Selected Product Type :</span>  {selectedTransaction.prodtypename}
                        <br />
                        {selectedTransaction.prodname && <span style={{ marginTop: "10px" }}>Selected Product Name: {selectedTransaction.prodname}</span>}
                        <br />
                        {selectedTransaction.quantityname && <span style={{ marginTop: "10px" }}>Selected Product Quantity: {selectedTransaction.quantityname}</span>}
                      </div>
                    )}
                    <ul className="product-transactions-transaction-list">
                      {
                         Quantitynamesfilter && Quantitynamesfilter.length > 0 && Quantitynamesfilter.map((product) => (
                        <li
                          key={product.qty_id}
                          onClick={() => handleTransactionClick3(product)}
                        >
                          {product.qty_name}
                        </li>
                      ))}
                    </ul>
               
    
                    <div className="product-transactions-modal-footer">
                      <button onClick={handleSelect}>Select</button>
                      <button onClick={onClose}>Cancel</button>
                    </div>
                  </div>
                </>
              }
            </div>
    
          </div>
    
          );
    }
    























































// import React, { Component } from 'react'
// import './css/billmainview.css'
// import mybillspage from '../../api/mybillspage';
// import notify from '../../error';
// export default function Mybillprofilepageviewmain()
// {
// const date = new Date();
// const date2 = new Date();

// function subtract6Months(date2) {
//   date2.setMonth(date2.getMonth() - 6);
//   console.log(date2, date);
//   return date2;
// }

// const newDate = subtract6Months(date2);
//  const [myBills,setMyBills]=React.useState([]);
//  const [page,setPage]=React.useState(1)
//  const [formData, setFormData] = React.useState({
//    to: `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
//      2,
//      '0'
//    )}-${String(date.getDate()).padStart(2, '0')}`,
//    from: `${newDate.getFullYear()}-${String(newDate.getMonth() + 1).padStart(
//      2,
//      '0'
//    )}-${String(newDate.getDate()).padStart(2, '0')}`,
//    editFrom: `${newDate.getFullYear()}-${String(
//      newDate.getMonth() + 1
//    ).padStart(2, '0')}-${String(newDate.getDate()).padStart(2, '0')}`,
//  });

//    async function GetAllMyOrders() 
//    {
//      const response = await mybillspage.ShowBills(
//        formData.from,
//        formData.to
//      );
//      console.log(response);

//      if (response.data && response.data.error != '') {
//        notify('error', response.data.error);
//      } else {
//        setMyBills(response.data);
//        console.log(response.data);
//        const keys = Object.keys(response.data);
//        setMyObjectKeyArray(keys);
//      }
//    }
//     const [myObjectKeyArray, setMyObjectKeyArray] = React.useState([]);
//    React.useEffect(() => {
//      GetAllMyOrders();
//    }, [formData.to, formData.from]);








  



   

//    //date manupulation
//    //how to play with dates
//    function handleChange(event) {
//      console.log(event);
//      const { name, value, type, checked } = event.target;
//      setFormData((prevFormData) => {
//        return {
//          ...prevFormData,
//          [name]: type === 'checkbox' ? checked : value,
//        };
//      });
//      if (name === 'editFrom') {
//        console.log('here', value);
//        const newDate = new Date();
//        let now = new Date();
//        now = getLastWeeksDate();

//        function getLastWeeksDate() {
//          const now = new Date();

//          return new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
//        }

//        function getLast30Date() {
//          const now = new Date();

//          return new Date(now.getFullYear(), now.getMonth(), now.getDate() - 30);
//        }
//        function getLastmonthDate() {
//          const now = new Date();

//          return new Date(now.getFullYear(), now.getMonth() - 1, now.getDate());
//        }
//        function getLast6monthDate() {
//          const now = new Date();

//          return new Date(now.getFullYear(), now.getMonth() - 6, now.getDate());
//        }
//        function get2022Date() {
//          const now = new Date();

//          return new Date(now.getFullYear() - 1, 0, 1);
//        }
//        function get2021Date() {
//          const now = new Date();

//          return new Date(now.getFullYear() - 2, 0, 1);
//        }
//        function get2020Date() {
//          const now = new Date();

//          return new Date(now.getFullYear() - 3, 0, 1);
//        }
//        function get2019Date() {
//          const now = new Date();

//          return new Date(now.getFullYear() - 4, 0, 1);
//        }
//        function get2018Date() {
//          const now = new Date();

//          return new Date(now.getFullYear() - 5, 0, 1);
//        }
//        function get2017Date() {
//          const now = new Date();

//          return new Date(now.getFullYear() - 6, 0, 1);
//        }
//        if (value === '1') {
//          console.log('here 1');
//          setFormData((prevFormData) => {
//            return {
//              ...prevFormData,
//              from: `${newDate.getFullYear()}-${String(
//                newDate.getMonth() + 1
//              ).padStart(2, '0')}-${String(newDate.getDate()).padStart(2, '0')}`,
//              to: `${newDate.getFullYear()}-${String(
//                newDate.getMonth() + 1
//              ).padStart(2, '0')}-${String(newDate.getDate()).padStart(2, '0')}`,
//            };
//          });
//        }

//        if (value === '2') {
//          console.log('here 2');

//          setFormData((prevFormData) => {
//            return {
//              ...prevFormData,
//              from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
//                2,
//                '0'
//              )}-${String(now.getDate()).padStart(2, '0')}`,
//              to: `${newDate.getFullYear()}-${String(
//                newDate.getMonth() + 1
//              ).padStart(2, '0')}-${String(newDate.getDate()).padStart(2, '0')}`,
//            };
//          });
//        }

//        if (value === '3') {
//          console.log('here 3');
//          now = getLast30Date();
//          setFormData((prevFormData) => {
//            return {
//              ...prevFormData,
//              from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
//                2,
//                '0'
//              )}-${String(now.getDate()).padStart(2, '0')}`,
//              to: `${newDate.getFullYear()}-${String(
//                newDate.getMonth() + 1
//              ).padStart(2, '0')}-${String(newDate.getDate()).padStart(2, '0')}`,
//            };
//          });
//        }
//        if (value === '4') {
//          console.log('here 4');
//          now = getLast6monthDate();
//          setFormData((prevFormData) => {
//            return {
//              ...prevFormData,
//              from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
//                2,
//                '0'
//              )}-${String(now.getDate()).padStart(2, '0')}`,
//              to: `${newDate.getFullYear()}-${String(
//                newDate.getMonth() + 1
//              ).padStart(2, '0')}-${String(newDate.getDate()).padStart(2, '0')}`,
//            };
//          });
//        }
//        if (value === '5') {
//          console.log('here 5');
//          now = get2022Date();
//          setFormData((prevFormData) => {
//            return {
//              ...prevFormData,
//              from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
//                2,
//                '0'
//              )}-${String(now.getDate()).padStart(2, '0')}`,
//              to: `${newDate.getFullYear()}-${String(
//                newDate.getMonth() + 1
//              ).padStart(2, '0')}-${String(newDate.getDate()).padStart(2, '0')}`,
//            };
//          });
//        }
//        if (value === '6') {
//          // console.log('here 5');
//          now = get2021Date();
//          setFormData((prevFormData) => {
//            return {
//              ...prevFormData,
//              from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
//                2,
//                '0'
//              )}-${String(now.getDate()).padStart(2, '0')}`,
//              to: `${newDate.getFullYear()}-${String(
//                newDate.getMonth() + 1
//              ).padStart(2, '0')}-${String(newDate.getDate()).padStart(2, '0')}`,
//            };
//          });
//        }
//        if (value === '7') {
//          // console.log('here 5');
//          now = get2020Date();
//          setFormData((prevFormData) => {
//            return {
//              ...prevFormData,
//              from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
//                2,
//                '0'
//              )}-${String(now.getDate()).padStart(2, '0')}`,
//              to: `${newDate.getFullYear()}-${String(
//                newDate.getMonth() + 1
//              ).padStart(2, '0')}-${String(newDate.getDate()).padStart(2, '0')}`,
//            };
//          });
//        }
//        if (value === '8') {
//          // console.log('here 5');
//          now = get2019Date();
//          setFormData((prevFormData) => {
//            return {
//              ...prevFormData,
//              from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
//                2,
//                '0'
//              )}-${String(now.getDate()).padStart(2, '0')}`,
//              to: `${newDate.getFullYear()}-${String(
//                newDate.getMonth() + 1
//              ).padStart(2, '0')}-${String(newDate.getDate()).padStart(2, '0')}`,
//            };
//          });
//        }
//        if (value === '9') {
//          console.log('here 5');
//          now = get2018Date();
//          setFormData((prevFormData) => {
//            return {
//              ...prevFormData,
//              from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
//                2,
//                '0'
//              )}-${String(now.getDate()).padStart(2, '0')}`,
//              to: `${newDate.getFullYear()}-${String(
//                newDate.getMonth() + 1
//              ).padStart(2, '0')}-${String(newDate.getDate()).padStart(2, '0')}`,
//            };
//          });
//        }

//        if (value === '10') {
//          // console.log('here 5');
//          now = get2017Date();
//          setFormData((prevFormData) => {
//            return {
//              ...prevFormData,
//              from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
//                2,
//                '0'
//              )}-${String(now.getDate()).padStart(2, '0')}`,
//              to: `${newDate.getFullYear()}-${String(
//                newDate.getMonth() + 1
//              ).padStart(2, '0')}-${String(newDate.getDate()).padStart(2, '0')}`,
//            };
//          });
//        }
//      }
//      console.log(formData);
//    }


//    const [billData,setBillData]=React.useState();
//  return (
//    <React.Fragment>
//      <>
//        <div className='my-bills-profile-page-view-main'>
//          <div className='my-bills-profile-page-view-main-left'>
//            <div className='my-bills-profile-page-view-left-top'>
//              <div className='my-bills-profile-page-view-left-top-heading'>
//                <span>MY BILLS</span>
//              </div>
//            </div>
//            <div
//              className='profile-account-setting-container-left-1'
//              onClick={() => {
//                setPage(1);
//                console.log(page);
//              }}
//            >
//              {page === 1 ? (
//                <div className='profile-account-setting-container-left-1-selected'></div>
//              ) : (
//                <div className='profile-account-setting-container-left-1-unselected'></div>
//              )}
//              <span>Completed</span>
//            </div>
//            {/* 2 */}
//            <div
//              className='profile-account-setting-container-left-1'
//              onClick={() => {
//                setPage(2);
//                console.log(page);
//              }}
//            >
//              {page === 2 ? (
//                <div className='profile-account-setting-container-left-1-selected'></div>
//              ) : (
//                <div className='profile-account-setting-container-left-1-unselected'></div>
//              )}
//              <span>Pending</span>
//            </div>
//            <div
//              className='profile-account-setting-container-left-1'
//              onClick={() => {
//                setPage(3);
//                console.log(page);
//              }}
//            >
//              {page === 3 ? (
//                <div className='profile-account-setting-container-left-1-selected'></div>
//              ) : (
//                <div className='profile-account-setting-container-left-1-unselected'></div>
//              )}
//              <span>Offline Orders</span>
//            </div>
//          </div>

//          {page !== 4 ? (
//            <div className='my-bills-profile-page-view-main-right'>
//              <div className='my-bills-profile-page-view-main-right-top'>
//                <div className='my-order-profile-time-filter-calander-box'>
//                  <div className='my-order-profile-time-filter-calander-1'>
//                    <span>From</span>
//                    <input
//                      type='date'
//                      onChange={handleChange}
//                      name='from'
//                      value={formData.from}
//                    ></input>
//                  </div>
//                  <div className='my-order-profile-time-filter-calander-1'>
//                    <span>Till</span>
//                    <input
//                      type='date'
//                      onChange={handleChange}
//                      name='to'
//                      value={formData.to}
//                    ></input>
//                  </div>
//                  <div className='my-order-profile-time-filter-calander-3'>
//                    <span>Sort By : </span>
//                    <select onChange={handleChange} name='editFrom'>
//                      <option value={0}>Time Filter</option>
//                      <option value={1}>Today</option>
//                      <option value={2}>Last One Week</option>
//                      <option value={3}>Last 30 Days</option>
//                      <option value={4}>Last 6 Months</option>
//                      <option value={5}>2022</option>
//                      <option value={6}>2021</option>
//                      <option value={7}>2020</option>
//                      <option value={8}>2019</option>
//                      <option value={9}>2018</option>
//                      <option value={10}>2017</option>
//                    </select>
//                  </div>
//                </div>
//              </div>

//              {}
//              <div className='my-bills-profile-page-view-main-right-main-box'>
//                {page === 1 ? (
//                  <>completed</>
//                ) : page === 2 ? (
//                  <>pending</>
//                ) : page === 3 ? (
//                  myBills &&
//                  myBills.offline_order &&
//                  myBills.offline_order.map((item) => {
//                    return (
//                      <div className='my-bills-profile-page-view-main-right-main-box-card'>
//                        <div className='my-bills-profile-page-view-main-right-main-box-card-img'>
//                          <img></img>
//                        </div>
//                        <div className='my-bills-profile-page-view-main-right-main-box-card-provider'>
//                          {item.serviceprovider}
//                        </div>
//                        <div className='my-bills-profile-page-view-main-right-main-box-card-des'>
//                          <span>Order Id : {item.order_id}</span>
//                          <span>Order type : {item.current_status}</span>
//                        </div>
//                        <div className='my-bills-profile-page-view-main-right-main-box-card-time'>
//                          {item.datetime} IST
//                        </div>
//                        <div className='my-bills-profile-page-view-main-right-main-box-card-price'>
//                          <span>&#8377;{item.amt}</span>
//                          <button
//                            onClick={() => {
//                              setPage(4);
//                              setBillData(item);
//                            }}
//                          >
//                            View Items
//                          </button>
//                        </div>
//                      </div>
//                    );
//                  })
//                ) : (
//                  <></>
//                )}
//              </div>
//            </div>
//          ) : (
//            <div className='my-bills-profile-page-view-main-right'>
//              <BillDetailsView data={billData}></BillDetailsView>
//            </div>
//          )}
//        </div>
//      </>
//    </React.Fragment>
//  );
// }



// function BillDetailsView(props)
// {
//   return (
//     <React.Fragment>
//       <>
//         <div className='my-bills-profile-page-view-main-right-data'>
//           <div className='my-bills-profile-page-view-main-right-heading-data'>
//             <span>View Details</span>
//           </div>
//           <div className='my-bills-profile-page-view-main-right-data-box'>
//             {props &&
//               props.data &&
//               props.data.items &&
//               props.data.items.length > 0 &&
//               props.data.items.map((item) => {
//                 return (
//                   <div className='my-bills-profile-page-view-main-right-data-box-card'>
//                     <div className='my-bills-profile-page-view-main-right-data-box-card-img'>
//                       <img></img>
//                     </div>
//                     <div className='my-bills-profile-page-view-main-right-data-box-card-des'>
//                       <span>{item.brand}</span>
//                       <span>{item.name}</span>
//                       <span>{item.product_quantity}</span>
//                     </div>
//                     <div className='my-bills-profile-page-view-main-right-data-box-card-qty'>
//                       Qty : {item.quantity}
//                     </div>
//                     <div className='my-bills-profile-page-view-main-right-data-box-card-price'>
//                       <span>&#8377;{item.price}</span>
//                     </div>
//                   </div>
//                 );
//               })}
//           </div>
//         </div>
//       </>
//     </React.Fragment>
//   );
// }