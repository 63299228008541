import React, { useEffect, useState } from "react";
import { useGlobalContext } from "../../context";
import postapi from "../../api/postapi";
import BlogCard from "./BlogCard";
import Loading from "../Loading";
import Singleblog from "./Singleblog";
import Createblog from "./Createblog";
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate } from 'react-router-dom';




function Blog() {
  const navigate = useNavigate();
  const [allBlogs, setAllBlogs] = useState([]);
  const [startIndex, setStartIndex] = useState(0);
  const { modifyModal, setModifyModal, postBlog, createBlogModal, setCreateBlogModal, blogInfo, loading, setLoading, blogUpdate, editBlogData, setEditBlogData } = useGlobalContext();

  // ---->to render multiple pages
  const observer = React.useRef();
  const markerRef = React.useCallback(node => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        setStartIndex(prevStartIndex => prevStartIndex + 18);
      }
    });
    if (node) observer.current.observe(node);
  }, [setStartIndex]);


  // blog fetching function
  const getAllBlogData = async (index) => {
    try {
      setLoading(true);
      const res = await postapi.diplayAllBlogs(index);
      setLoading(false);
      if (res) setAllBlogs((prevBlogs) => [...prevBlogs, ...res.data.data]);
    } catch (err) {
      console.log(err);
    }
  }

  const getAllBlogs = async (index) => {
    try {
      setLoading(true);
      const res = await postapi.diplayAllBlogs(index);
      setLoading(false);
      if (res) setAllBlogs(res.data.data);
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    if(startIndex!==0){
      getAllBlogData(startIndex)
    }
  }, [startIndex]);

  useEffect(() => {
    getAllBlogs(0);
  }, [postBlog, blogUpdate])

  return (
    <>
      {
        modifyModal &&
        <div className="blogOverlay">
          {createBlogModal && editBlogData ?
            <Createblog editData={editBlogData[0]} />
            : createBlogModal ?
              <Createblog />
              :
              <Singleblog blogInfo={blogInfo} />
          }
        </div>
      }
      <div className="blogMain profile">

        <div className="addBlogButton">
          <IoMdArrowBack className="backButtonBlog" onClick={()=>navigate('/')}/>          
          <button onClick={() => {
            setModifyModal(true);
            setCreateBlogModal(true);
            setEditBlogData([]);
          }} >Write a blog</button>
        </div>
        <div className="allBlogs">

          {allBlogs && allBlogs.map((elm, index) => {
            return <BlogCard key={index} elm={elm} />
          })
          }
          {allBlogs.length > 0 && <div className="marker" ref={markerRef} />}
          {loading &&  <div className="blogposts_loading"><Loading />
          
          </div>}

        </div>
      </div>

    </>
  );
}

export default Blog;
