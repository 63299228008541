import axios from 'axios';
//company
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

class PutatoeBillsprofileApi {
  ShowBills(start_date,end_date) {
    return axios.post(
      API_BASE_URL + '/v1/api/customerTransactionForUser',
      { start_date, end_date },
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }

ShowIncomingOutgoingBills(serviceprovider_id) {
  return axios.post(
    API_BASE_URL + '/v1/api/showBills',
    { serviceprovider_id},
    {
      headers: {
        authtoken: localStorage.getItem('authtoken'),
      },
    }
  );
}
}

export default new PutatoeBillsprofileApi();
