import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import cartapi from "../api/cartapi";
import notify from "../error.js";
import "./cart-css/CartMainViewPage.css";
import CartItem from "./CartItems";
import emptyPic from "./emptycart.png";
import Loading from "../components/Loading";
import { useGlobalContext } from "../context";
export default function CartMainViewPage() {
  const { changeInCart, setChangeInCart,setCartdata } = useGlobalContext();
  const [loading, setLoading] = React.useState(false);
  const [empty, setEmpty] = useState(false);
  const [changes, setChanges] = React.useState(false);
  const [cart, setCart] = React.useState();
  function ChangeChanges(operation) {
    setChanges(!changes);
  }
  const cartDetail = async () => {
    try {
      const response = await cartapi.ShowCart();
      if (response.data.error === "") {
        setCart(response.data);
        setCartdata(response.data);
      }
      if (response.data.items_list.length === 0) setEmpty(true);
    } catch (error) {
      notify("error", `can not fetch cart :${error.message}`);
    }
  };

  React.useEffect(() => {
    console.log("cartdetail called in starting");

    cartDetail();
  }, []);

  React.useEffect(() => {
    console.log("cartdetail called after edit");

    cartDetail();
  }, [changes,changeInCart]);

  return (
    <React.Fragment>
      <div className="cart-main-page">
        {cart?.items_list && cart.items_list.length > 0 ? (
          <>
          <div className="cart-main-page-item-row-flipped">
            <div className="cart-main-page-item-row">
              {cart.items_list.map((item) => {
                return (
                  <CartItem
                    diff={ChangeChanges}
                    data={item}
                    page={1}
                  ></CartItem>
                );
              })}
            </div>
            </div>
            <div className="cart-main-page-amount-row">
              <div className="cart-main-page-amount-row-heading">
                PRICE DETAILS
              </div>
              <div className="cart-main-page-amount-row-bag-total">
                <div className="">Bag Total</div>
                {cart && <span>&#8377;{cart.actual_total}</span>}
              </div>
              <div className="cart-main-page-amount-row-bag-discount">
                <div>Bag Discount</div>
                {cart && <span>-&#8377;{cart.discount_total}</span>}
              </div>
              <div className="cart-main-page-amount-row-coupon-discount">
                <div>coupon Discount</div>
                {cart && <span>-&#8377;{cart.coupon_discount}</span>}
              </div>
              <div className="cart-main-page-amount-row-coupon-discount">
                <div>Delivery Charge</div>
                {cart && <span>&#8377;{cart.delivery_charge}</span>}
              </div>
              <div className="cart-main-page-amount-row-final-price">
                <div>Total Amount</div>
                {cart && <span>&#8377;{cart.final_discounted_price}</span>}
              </div>

              <div className="cart-main-page-amount-row-button">
                <Link to="/cart/final">
                  <button>PROCEED TO PAYMENT</button>
                </Link>
              </div>
            </div>
          </>
        ) : empty ? (
          <>
            <div className="cart-main-page-empty-cart-image">
              <img src={emptyPic} alt="" />
            </div>
          </>
        ) : (
          <div className="LoadingContainer">
          <Loading />
          </div>
        )}
      </div>
    </React.Fragment>
  );
}
