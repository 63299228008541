import React, { Component } from "react";
import "./css/mybankaccountmainpage.css";
import BankApi from "../../api/BankApi";
import notify from "../../error";
import Bankcard from "./BankAccountCard";
import back from "../myprofileIcoons/bx-arrow-back..svg";
import nobankicon from "./img/bank-customer-getting-loan-man-wheeling-cart-with-cash-flat-vector-illustration-finance-money-banking-service_74855-8233.avif";
import { useGlobalContext } from "../../context";
export default function MyBankAccountMainPage() {
  const { setInnerPage } = useGlobalContext();
  const [BankAccountsList, setBankAccountsList] = React.useState([
    {
      IFSC: "587823",
      account_holder_name: "abcd name",
      account_id: 2,
      account_number: "789456133012",
      account_type: "saving",
    },
    {
      IFSC: "587823",
      account_holder_name: "abcd name",
      account_id: 2,
      account_number: "789456133012",
      account_type: "saving",
    },
    {
      IFSC: "587823",
      account_holder_name: "abcd name",
      account_id: 2,
      account_number: "789456133012",
      account_type: "saving",
    },
    {
      IFSC: "587823",
      account_holder_name: "abcd name",
      account_id: 2,
      account_number: "789456133012",
      account_type: "saving",
    },
    {
      IFSC: "587823",
      account_holder_name: "abcd name",
      account_id: 2,
      account_number: "789456133012",
      account_type: "saving",
    },
    {
      IFSC: "587823",
      account_holder_name: "abcd name",
      account_id: 2,
      account_number: "789456133012",
      account_type: "saving",
    },
    {
      IFSC: "587823",
      account_holder_name: "abcd name",
      account_id: 2,
      account_number: "789456133012",
      account_type: "saving",
    },
    {
      IFSC: "587823",
      account_holder_name: "abcd name",
      account_id: 2,
      account_number: "789456133012",
      account_type: "saving",
    },
    {
      IFSC: "587823",
      account_holder_name: "abcd name",
      account_id: 2,
      account_number: "789456133012",
      account_type: "saving",
    },
    {
      IFSC: "587823",
      account_holder_name: "abcd name",
      account_id: 2,
      account_number: "789456133012",
      account_type: "saving",
    },
    {
      IFSC: "587823",
      account_holder_name: "abcd name",
      account_id: 2,
      account_number: "789456133012",
      account_type: "saving",
    },
    {
      IFSC: "587823",
      account_holder_name: "abcd name",
      account_id: 2,
      account_number: "789456133012",
      account_type: "saving",
    },
    {
      IFSC: "587823",
      account_holder_name: "abcd name",
      account_id: 2,
      account_number: "789456133012",
      account_type: "saving",
    },
    {
      IFSC: "587823",
      account_holder_name: "abcd name",
      account_id: 2,
      account_number: "789456133012",
      account_type: "saving",
    },
    {
      IFSC: "587823",
      account_holder_name: "abcd name",
      account_id: 2,
      account_number: "789456133012",
      account_type: "saving",
    },
    {
      IFSC: "587823",
      account_holder_name: "abcd name",
      account_id: 2,
      account_number: "789456133012",
      account_type: "saving",
    },
    {
      IFSC: "587823",
      account_holder_name: "abcd name",
      account_id: 2,
      account_number: "789456133012",
      account_type: "saving",
    },
    {
      IFSC: "587823",
      account_holder_name: "abcd name",
      account_id: 2,
      account_number: "789456133012",
      account_type: "saving",
    },
  ]);
  const FetchBankACcountsList = async () => {
    const response = await BankApi.ViewBankAccounts();
    console.log(response);
    if (response.data.error !== "") {
      notify("error", response.data.error);
    } else {
      setBankAccountsList(response.data.accounts);
    }
  };
  React.useEffect(() => {
    FetchBankACcountsList();
  }, []);

  const [page, setPage] = React.useState(0);
  const [EditDetails, setEditDetails] = React.useState(0);
  const ChangePageNumber = (i) => {
    setPage(i);
    if (i === 0) {
      setInnerPage(false);
    } else {
      setInnerPage(true);
    }
  };

  const ChangeDetailsNumber = (i) => {
    setFormData2({
      number: i.account_number,
      type: i.account_type,
      name: i.account_holder_name,
      ifsc: i.IFSC,
      account_id: i.account_id,
    });
  };

  const [formData, setFormData] = React.useState({
    number: "",
    type: "",
    name: "",
    ifsc: "",
  });
// for errors 
  const [formErrorAdd, setFormErrorAdd] = React.useState({
    number: '',
    type: '',
    name: '',
    ifsc: ''
  });

  const [formErrorModify, setFormErrorModify] = React.useState({
    number: '',
    type: '',
    name: '',
    ifsc: ''
  });


  const [formData2, setFormData2] = React.useState({
    number: "",
    type: "",
    name: "",
    ifsc: "",
    account_id: 0,
  });
  function handleChange(event) {
    console.log(event);
    const { name, value, type, checked } = event.target;
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: type === "checkbox" ? checked : value,
      };
    });    
    console.log(formData);
  }

  function handleChange2(event) {
    console.log(event);
    const { name, value, type, checked } = event.target;
    setFormData2((prevFormData) => {
      return {
        ...prevFormData,
        [name]: type === "checkbox" ? checked : value,
      };
    });
    console.log(formData2);
  }
  const isValidAccountNumber = (accountNumber) => {
    const accountNumberRegex = /^\d{9,18}$/; // Validate account number with 9 to 18 digits
    if(accountNumber==""){
      return false;
    }
    return accountNumberRegex.test(accountNumber);
  };

  const isValidIFSC = (ifscCode) => {
    const ifscCodeRegex = /^[A-Z]{4}[0-9A-Z]{7}$/; // Validate IFSC code with 4 letters followed by 7 alphanumeric characters
    return ifscCodeRegex.test(ifscCode);
  };

  const OnAddNewAccountSubmit = async () => {
    let newErrors = {};

    // Perform validations
    if (formData.number === "") {
      newErrors.number = "Please enter the account number.";

    }

    if (formData.number !== "" &&!isValidAccountNumber(formData.number)) {
      newErrors.number = "Please enter a valid account number.";
 
    }

    if (formData.type === "") {
      newErrors.type = "Please select the account type.";

    }

    if (formData.name === "") {
      newErrors.name = "Please enter the account holder name.";

    }

    if (formData.ifsc === "") {
      newErrors.ifsc = "Please enter the IFSC code.";


    }

    if (!isValidIFSC(formData.ifsc)) {
      newErrors.ifsc = "Please enter a valid IFSC code.";
      
    }
    setFormErrorAdd(newErrors);
     // If there are any errors, return without submitting
     console.log(newErrors);
     if (Object.keys(newErrors).length > 0) {
      return;
    }

    // Submit the form
    const response = await BankApi.AddBankAccount(
      parseInt(formData.number),
      formData.type,
      formData.name,
      formData.ifsc
    );
    console.log(response);
    if (response.data.error !== "") {
      notify("error", response.data.error);
    } else {
      notify("success", "Bank Account Added Successfully");
      FetchBankACcountsList();
      ChangePageNumber(0);
      setFormData({
        number: '',
        type: '',
        name: '',
        ifsc: ''
      })
    }
  };

  const OnModifyAccountSubmit = async () => {
    let newErrors = {};
    // Perform validations
    if (formData2.number === "") {
      newErrors.number = "Please enter the account number.";

    }

    if (!isValidAccountNumber(formData2.number)) {
      newErrors.number = "Please enter a valid account number.";
    }

    if (formData2.type === "") {
      newErrors.type = "Please select the account type.";
    }

    if (formData2.name === "") {
      newErrors.name = "Please enter the account holder name.";
    }

    if (formData2.ifsc === "") {
      newErrors.ifsc = "Please enter the IFSC code.";
    }

    if (!isValidIFSC(formData2.ifsc)) {
      newErrors.ifsc = "Please enter a valid IFSC code.";
    }

    setFormErrorModify(newErrors);
    // If there are any errors, return without submitting
    if (Object.keys(newErrors).length > 0) {
     return;
   }

    const response = await BankApi.ModifyBankAccount(
      parseInt(formData2.account_id),
      parseInt(formData2.number),
      formData2.type,
      formData2.name,
      formData2.ifsc
    );
    console.log(response);
    if (response.data.error !== "") {
      notify("error", response.data.error);
    } else {
      notify("success", "Bank Account Modified Successfully");
      FetchBankACcountsList();
      ChangePageNumber(0);
    }
  };

  return (
    <React.Fragment>
      <>
        {page === 0 ? (
          <div className="my-bank-account-profile-main-page">
            <div className="profile-wallet-main-page-heading">
              <span>My Bank Accounts</span>
              <div className="profile-wallet-main-page-add-accounts">
                <button
                  onClick={() => {
                    ChangePageNumber(1);
                  }}
                >
                  + &nbsp;&nbsp;&nbsp;&nbsp;Add New Account
                </button>
              </div>
            </div>

            <div className="profile-wallet-main-page-my-accounts">
              {BankAccountsList.length > 0 ? (
                BankAccountsList.map((item) => {
                  return (
                    <Bankcard
                      data={item}
                      newpage={ChangePageNumber}
                      para={ChangeDetailsNumber}
                      refresh={FetchBankACcountsList}
                    ></Bankcard>
                  );
                })
              ) : (
                <>
                  <div className="my-bank-account-profile-main-page-no-accounts">
                    <img src={nobankicon} alt="icon" />
                    <div class="my-bank-account-profile-main-page-text">
                      No Bank Accounts to show{" "}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        ) : page === 1 ? (
          <div className="my-bank-account-profile-main-page">
            <div className="profile-wallet-main-page-heading addbankheadding">
              <b className="my-profile-wallet-page-transfer-top-back">
                <img
                  src={back}
                  onClick={() => {
                    ChangePageNumber(0);
                  }}
                ></img>
              </b>
              <span className="addheadingspan">Add New Account</span>
            </div>
            <div className="my-bank-account-profile-main-page-add-account-input">
            <div className={`add-address-modal-coupon-enter-input ${((!formData.number && formErrorAdd.number)) ? 'error': (formData.number && !isValidAccountNumber(formData.number)) ? 'error' : ''}`}>
                <span>Account Number</span>
                <input
                  type="number"
                  value={formData.number}
                  name="number"
                  onChange={handleChange}
                  placeholder="Enter Your Account Number"
                  required
                ></input>
                {
                ((!formData.number && formErrorAdd.number)) ? <p className="error-message">{formErrorAdd.number}</p>:<>{
                  (formData.number && !isValidAccountNumber(formData.number)) ? 
                  <p className="error-message">Please enter a valid account number</p>
                  :<></>
                }</>
                }
              </div>
              <div className={`add-address-modal-coupon-enter-input ${(!formData.type && formErrorAdd.type) ? 'error' : ''}`}>
                <span>Account Type</span>
                <select
                  value={formData.type}
                  name="type"
                  onChange={handleChange}
                  placeholder="Select Account Type"
                  required
                >
                  <option value="">Select Account Type</option>
                  <option value="saving">Savings Account</option>
                  <option value="current">Current Account</option>
                </select>
                {(!formData.type && formErrorAdd.type) && <p className="error-message">{formErrorAdd.type}</p>}
              </div>
              <div className={`add-address-modal-coupon-enter-input ${(!formData.name && formErrorAdd.name) ? 'error' : ''}`}>
                <span>Account Holder Name</span>
                <input
                  type="text"
                  value={formData.name}
                  name="name"
                  onChange={handleChange}
                  placeholder="Enter Account Holder Name"
                  required
                ></input>
                 {(!formData.name && formErrorAdd.name) && <p className="error-message">{formErrorAdd.name}</p>}
              </div>
              <div className={`add-address-modal-coupon-enter-input ${((!formData.ifsc && formErrorAdd.ifsc)) ? 'error': (formData.ifsc && !isValidIFSC(formData.ifsc)) ? 'error' : ''}`}>
                <span>IFSC Code</span>
                <input
                  type="text"
                  value={formData.ifsc}
                  name="ifsc"
                  onChange={handleChange}
                  placeholder="Enter Your IFSC Code"
                  required
                ></input>
                {
                (!formData.ifsc) ? <p className="error-message">{formErrorAdd.ifsc}</p>:<>{
                  (formData.ifsc && !isValidIFSC(formData.ifsc)) ? 
                  <p className="error-message">Please enter a valid IFSC number</p>
                  :<></>
                }</>
                }
              </div>
            </div>
            <div className="my-bank-account-profile-main-page-add-account-submit">
              <button onClick={OnAddNewAccountSubmit}>Submit</button>
            </div>
          </div>
        ) : (
          <>
            <div className="my-bank-account-profile-main-page">
              <div className="profile-wallet-main-page-heading addbankheadding">
                <b className="my-profile-wallet-page-transfer-top-back bacck1">
                  <img
                    src={back}
                    onClick={() => {
                      ChangePageNumber(0);
                    }}
                  ></img>
                </b>
                <span className="addheadingspan">Modify Your Account</span>
              </div>
              <div className="my-bank-account-profile-main-page-add-account-input">
              <div className={`add-address-modal-coupon-enter-input  ${(formErrorModify.number !== "" && !isValidAccountNumber(formData2.number)) ? 'error' : ''}`}>
                  <span>Account Number</span>
                  <input
                    type="number"
                    value={formData2.number}
                    name="number"
                    onChange={handleChange2}
                    placeholder="Enter Your Account Number"
                    required
                  ></input>
                   {
                (!formData2.number) ? <p className="error-message">{formErrorModify.number}</p>:<>{
                  (formData2.number && !isValidAccountNumber(formData2.number)) ? 
                  <p className="error-message">Please enter a valid account number</p>
                  :<></>
                }</>
                }
                </div>
                <div className={`add-address-modal-coupon-enter-input ${(!formData2.type && formErrorModify.type) ? 'error' : ''}`}>
                  <span>Account Type</span>
                  <select
                    value={formData2.type}
                    name="type"
                    onChange={handleChange2}
                    placeholder="Select Account Type"
                    required
                  >
                    <option value="">Select Account Type</option>
                    <option value="saving">Savings Account</option>
                    <option value="current">Current Account</option>
                  </select>
                  {(!formData2.type && formErrorModify.type) && <p className="error-message">{formErrorModify.type}</p>}
                </div>
                <div className={`add-address-modal-coupon-enter-input ${(!formData2.name && formErrorModify.name) ? 'error' : ''}`}>
                  <span>Account Holder Name</span>
                  <input
                    type="text"
                    value={formData2.name}
                    name="name"
                    onChange={handleChange2}
                    placeholder="Enter Account Holder Name"
                    required
                  ></input>
                   {(!formData2.name && formErrorModify.name) && <p className="error-message">{formErrorModify.name}</p>}
                </div>
                <div className={`add-address-modal-coupon-enter-input ${(formErrorModify.ifsc !== "" && !isValidIFSC(formData2.ifsc)) ? 'error' : ''}`}>
                  <span>IFSC Code</span>
                  <input
                    type="text"
                    value={formData2.ifsc}
                    name="ifsc"
                    onChange={handleChange2}
                    placeholder="Enter Your IFSC Code"
                    required
                  ></input>
                    {
                (!formData2.ifsc) ? <p className="error-message">{formErrorModify.ifsc}</p>:<>{
                  (formData2.ifsc && !isValidIFSC(formData2.ifsc)) ? 
                  <p className="error-message">Please enter a valid IFSC number</p>
                  :<></>
                }</>
                }
                </div>
              </div>
              <div className="my-bank-account-profile-main-page-add-account-submit">
                <button onClick={OnModifyAccountSubmit}>Submit</button>
              </div>
            </div>
          </>
        )}
      </>
    </React.Fragment>
  );
}
