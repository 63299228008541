import React, { useEffect, useRef, useState } from 'react';
import cartapi from '../api/cartapi';
import './cart-css/cartViewPage2.css';
import CartItem from './CartItems';
import { Link, useNavigate } from 'react-router-dom';
import CouponImg from '../images/coupon-tag.svg';
import CouponModal from './CouponModal';
import AddressModal from './AddressModal';
import AddNewAddress from './AddNewAddresss';
// import Loading from '../components/Loading';
import paymentApi from '../api/paymentApi';
import { useGlobalContext } from '../context';
import notify from '../error';
import EditMyAddress from './EditAddress';
// import profileapi from '../api/profileapi';
import api from "../api/api";
// import empty from './emptycart.png';
import EditAddressModal from './EditAddressModal';
import profileapi from '../api/profileapi';


export default function CartViewPage2() {
  const { Userprofile, setLoginPage, setDisplay, setUserProfile, cartdata, coouponid } = useGlobalContext();

  const [changes, setChanges] = React.useState(false);
  const [paymentclick, setPaymentclick] = React.useState(false);
  const initialrender = useRef(true);
  const [cart, setCart] = useState({ ...cartdata });
  const [EditAddress, setEditAddress] = React.useState('');
  const [ADDNewAddress, setADDNewAddress] = React.useState(false);
  const [showCouponModal, setShowCouponModal] = React.useState(false);
  const [showAddressModal, setShowAddressModal] = React.useState(false);
  const [DeliveryDay, setDeliveryDay] = React.useState(1);
  const [DeliveryTime, setDeliveryTime] = React.useState(1);
  const [PaymentMode, setPaymentMode] = React.useState('');
  const [selectedAddress, setSelectedAddress] = React.useState({});
  const [DeliveryLocation, setDeliveryLocation] = React.useState([]);

  //----------------------------------------------------------------
  const [Loading, setLoading] = useState(false);


  const CouponModalChangeStatus = () => {
    setShowCouponModal(!showCouponModal);
  };
  const ChangeAddress = () => {
    setShowAddressModal(!showAddressModal);
  };
  const ChangeNewAddress = () => {
    setADDNewAddress(!ADDNewAddress);
  };

  const MySelectedAddress = (e) => {
    // console.log(e, 'myselectedaddress 1212ad', 'delivery location', DeliveryLocation);
    if (DeliveryLocation) {
      setSelectedAddress(DeliveryLocation[e]);
    }
  };

  const ChangeDeliveryDay = (index) => {
    console.log(index);
    setDeliveryDay(index);
  };

  const ChangeDeliveryTime = (index) => {
    console.log(index);
    setDeliveryTime(index);
  };

  // const PaymentModeType=(e)=>
  // {
  //  console.log('touched')
  //  console.log(e.target.value)
  //  setPaymentMode(e.target.value);
  // }

  function ChangeChanges(operation) {
    setChanges(!changes);
    console.log('changes'); //pass in child
    if (operation) {
      if (Number.isInteger(operation)) {
        console.log('operation for counter');
      } else if (isNaN(operation)) {
        console.log('delete operation');
      }
    }
  }

  const cartDetail = async () => {
    const response = await cartapi.ShowCart();
    // console.log(response);
    setCart(response.data);
  };

  const isNewProductCouponApplied = cart?.items_list?.some((item) => item.coupon_discount !== null);
  const removeAppliedCoupon = async () => {
    try {
      const response = await cartapi.removeCoupon();
      if (response.data.error === '') {
        // console.log(response);
        // Call cartDetail function to update cart details
        cartDetail();

        notify('success', 'Coupon Removed Successfully');
      } else {
        notify('error', response.data.error);
      }
    } catch (error) {
      console.log(error);
      notify('error', 'An error occurred while removing the coupon');
    }
  };

  const [daySlot, setDaySlot] = React.useState([]);
  const [timeSlot, setTimeSlot] = React.useState([]);


  const BillingDetails = async () => {
    const response = await cartapi.BillingPage();
    console.log(response, 'response from billingdetails api  1212ad');
    setDaySlot(response.data.day_slot);
    setTimeSlot(response.data.time_slots);
    // setDeliveryLocation(response.data.address_list);
    // setSelectedAddress(response.data.address_list[0]);
  };


  // api call for get address list 
  const fetchAddress = async () => {
    try {
      setLoading(true);
      const response = await profileapi.ShowAddress();
      // console.log('response from fetchaddress: 1212ad', response)
      if (response) {
        setDeliveryLocation(response.data.all_address);
        setSelectedAddress(response.data.all_address.find(address => address.default_address === 1));
        setLoading(false);
      }
    } catch (error) {
      notify("error", "Failed to fetch addresses");
      console.error("Error fetching addresses:", error);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchAddress();
  }, []);



  React.useEffect(() => {
    cartDetail();
    // console.log('cartdetail called after edit');
  }, [changes]);

  // React.useEffect(() => {
  //   cartDetail();
  // }, []);


  React.useEffect(() => {
    BillingDetails();
  }, [ADDNewAddress, EditAddress]);



  function handleChange(event) {
    // console.log('reac')
    const { name, value, type, checked } = event.target;
    // console.log(value)
    setPaymentMode(value);
  }

  ////////////payments

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }


  const navigate = useNavigate();
  const moveToConfirmationPage = (id) => {
    navigate(`./order/${id}`)
  }

  const userprofileapicall = async () => {
    const response2 = await api.ProfileApi();
    if (response2.data.error === "") {
      setUserProfile(response2.data);
      localStorage.setItem("UserProfile", JSON.stringify(response2.data));
    }
  }
  const PlaceOrderFunction = async () => {
    if (PaymentMode === 'cod') {
      // may need to use parseInt()
      // all start 3 are to be id's
      const response = await paymentApi.CashOnDelivery(
        DeliveryDay,
        DeliveryTime,
        selectedAddress.address_id,
        coouponid
      );
      console.log(response);
      var data = response.data;
      console.log(data);
      if (data.error !== '' || data.status === false) {
        console.log(true);
        notify('warn', data.error);
        setPaymentclick(false);
      } else if (data['orderId'] !== null) {
        var id = data['orderId'];
        notify('success', 'Order Placed Successfully!');
        // document.location.href = 'OrderConfirmed.html?id=' + id;
        moveToConfirmationPage(id)
      } else {
        notify('error', 'Order Failed!');
        setPaymentclick(false);
      }
    } else if (PaymentMode === 'paynow') {

    } else if (PaymentMode === 'wallet') {
      const response = await paymentApi.PayFromWallet(
        DeliveryDay,
        DeliveryTime,
        selectedAddress.address_id,
        coouponid
      );
      console.log(response);
      var data = response.data;
      console.log(data);
      if (data['orderId']) {
        var id1 = data['orderId'];
        notify('success', 'Payment Successful!');
        moveToConfirmationPage(id1);
        userprofileapicall();
        return;
      }
      // else if (data["balance_amount"]){
      //   notify("error","Not having enough wallet balance")
      //   setPaymentclick(false);
      // }
      else {
        notify("error", "Transcation failed")
        setPaymentclick(false);
      }
      // } else if (data['status'] === true) {
      //   //dbt whether correct
      //   notify('warn', 'Not having enough wallet balance.');
      //   // location.href = "/putatoe_client/checkout.html";
      // } else {
      //   notify('error', 'Order failed.');
      //   //  location.href = '/putatoe_client/checkout.html';
      // }
    }
  };
  const checkstatus = () => {
    if (!Userprofile) {
      notify('error', 'Please Login To Continue')
      setDisplay('login-sidebar-container2');
      setLoginPage(1);
      return
    }
    if (
      PaymentMode === undefined ||
      PaymentMode === '' ||
      PaymentMode === null
    ) {
      notify('warn', 'Please select a payment mode');
      return false;
    } else if (selectedAddress === null || selectedAddress === '') {
      notify('warn', 'Please select an address');
      return false;
    }
    return true;
  }

  // ------------------------------------
  const UserProfile = JSON.parse(localStorage.getItem("UserProfile"));
  const [formDataPassword, setFormDataPassword] = useState({
    name: `${UserProfile.first_name} ${UserProfile.last_name}`,
    phone: UserProfile.phone,
    default_address: 1
  });

  const handleSubmit = async (obj) => {

    try {
      const { name, phone, addressline1, addressline2, landmark, town, state, district, pincode, latitude, longitude, address_id, default_address } = obj;


      const response = await profileapi.ModifyAddress(name, phone, addressline1, addressline2, landmark, town, pincode, latitude, longitude, district, state, default_address, address_id
      );
      if (response.status === 200) {
        // fetchAddress()
      }

    } catch (error) {
      console.error(error);
      // notify('warn', 'try again later');
    }

  };

  useEffect(() => {
    let fetchfun = true;
    if (Object.keys(selectedAddress).length > 0 && fetchfun) {
      const newobj = { ...selectedAddress, ...formDataPassword };
      handleSubmit(newobj);
      fetchfun = false;
    }
  }, [selectedAddress])

  // ------------------------------------






  useEffect(() => {
    if (initialrender.current) {
      initialrender.current = false;
      return;
    }
    if (paymentclick) {
      PlaceOrderFunction();
    }
  }, [paymentclick])

  return (
    <React.Fragment>
      {showCouponModal && (
        <CouponModal
          show={showCouponModal}
          setShow={setShowCouponModal}

          cartDetail={cartDetail}
        ></CouponModal>
      )}
      {showAddressModal && (
        <AddressModal
          fetchAddress={fetchAddress}
          selectedAddress={selectedAddress}
          address={DeliveryLocation}
          Loading={Loading}
          show={showAddressModal}
          setShow={setShowAddressModal}
          selected={MySelectedAddress}
          addition={ChangeNewAddress}
          editing={setEditAddress}
        ></AddressModal>
      )}
      {ADDNewAddress && (
        <AddNewAddress show={ADDNewAddress} setShow={setADDNewAddress} />
      )}
      {
        EditAddress && (
          <EditMyAddress show={EditAddress} setShow={setEditAddress}></EditMyAddress>
        )
      }
      <div className='cart-main-page-2'>
        <div className='cart-main-page-item-row-2'>
          <div className='cart-main-page-item-row-delivery-address-2'>
            <div className='cart-main-page-item-row-delivery-address-3'>
              {Object.keys(selectedAddress).length > 0 &&
                <>
                  Deliver to: {selectedAddress.name ? selectedAddress.name : <>{Userprofile?.first_name} {Userprofile?.last_name}</>}
                  <br></br>
                  {`${selectedAddress?.addressline1} , ${selectedAddress?.addressline2} , ${selectedAddress?.landmark} , ${selectedAddress?.district} , ${selectedAddress?.state} , ${selectedAddress?.pincode}`}<br></br>
                  {selectedAddress.phone ? selectedAddress.phone : Userprofile?.phone && Userprofile?.phone.substr(3)}
                </>}

            </div>

            <div className='cart-main-page-item-row-delivery-address-2-butoon-container'>
              <button onClick={ChangeAddress}>CHANGE ADDRESS</button>
              {/* <button onClick={handleOpenModal} >EDIT ADDRESS</button> */}
            </div>
            {/* <EditAddressModal address={{ ...selectedAddress, name: Userprofile?.first_name + " " + Userprofile?.last_name }} show={isModalOpen} onClose={handleCloseModal} /> */}

          </div>
          <div className='flippedcart'>
            {cart &&
              cart.items_list &&
              cart.items_list.map((item) => {
                return (
                  <>
                    <CartItem
                      diff={ChangeChanges}
                      data={item}
                      page={2}
                    ></CartItem>
                  </>
                );
              })}
          </div>
        </div>
        <div className='cart-main-page-amount-row-2'>
          <div className='cart-main-page-amount-row-coupon-heading-2'>
            COUPONS
          </div>
          <div className='cart-main-page-amount-row-coupon-2'>
            <div>
              <img src={CouponImg}></img>
              <span>Apply Coupons</span>
            </div>
            {isNewProductCouponApplied ? (
              <button onClick={removeAppliedCoupon}>Remove Coupon</button>
            ) :
              <button onClick={CouponModalChangeStatus}>Apply</button>
            }


          </div>
          <div className='cart-main-page-amount-row-heading-2'>
            PRICE DETAILS
          </div>
          <div className='cart-main-page-amount-row-bag-total-2'>
            <div className=''>Bag Total</div>
            <span>&#8377;{cart.actual_total}</span>
          </div>
          <div className='cart-main-page-amount-row-bag-discount-2'>
            <div>Bag Discount</div>
            <span>-&#8377;{cart.discount_total}</span>
          </div>
          <div className='cart-main-page-amount-row-coupon-discount-2'>
            <div>coupon Discount</div>
            <span>-&#8377;{cart.coupon_discount}</span>
          </div>
          {cart.cashback_amount_awarded != null && cart.cashback_amount_awarded != undefined && cart.cashback_amount_awarded != 0 &&
            <div className='cart-main-page-amount-row-coupon-discount-2'>
              <div>Cashback</div>
              <span>-&#8377;{cart.cashback_amount_awarded}</span>
            </div>
          }
          <div className='cart-main-page-amount-row-coupon-discount-2'>
            <div>Delivery Charge</div>
            {cart && <span>&#8377;{cart.delivery_charge}</span>}
          </div>
          <div className='cart-main-page-amount-row-final-price-2'>
            <div>Total Amount</div>
            <span>&#8377;{cart.final_discounted_price}</span>
          </div>

          <div className='cart-main-page-amount-row-delivery-day-2'>
            <div className='cart-main-page-amount-row-delivery-day-heading-2'>
              SELECT YOUR CHOICE OF DELIVERY DAY
            </div>
            <div className='cart-main-page-amount-row-delivery-day-box-2'>
              {daySlot.map((item, index) => {
                console.log(item, 'delivery day 1212ad');
                return DeliveryDay === item[0] ? (
                  <button
                    key={`delivery-day-${index}`}
                    className='cart-main-page-amount-row-delivery-day-box-2-selected'
                    onClick={() => {
                      ChangeDeliveryDay(item[0]);
                    }}
                  >
                    {item[1]}
                  </button>
                ) : (
                  <button
                    key={`delivery-day-${index}`}
                    onClick={() => {
                      ChangeDeliveryDay(item[0]);
                    }}
                  >
                    {item[1]}
                  </button>
                );
              })}
            </div>
          </div>

          <div className='cart-main-page-amount-row-delivery-day-2'>
            <div className='cart-main-page-amount-row-delivery-day-heading-2'>
              SELECT YOUR CHOICE OF DELIVERY TIME
            </div>
            <div className='cart-main-page-amount-row-delivery-day-box-3'>
              {timeSlot.map((item, index) => {
                // console.log(item);
                return DeliveryTime === item[0] ? (
                  <button
                    key={`delivery-time-${index}`}
                    className='cart-main-page-amount-row-delivery-day-box-2-selected'
                    onClick={() => {
                      ChangeDeliveryTime(item[0]);
                    }}
                  >
                    {item[1]}
                  </button>
                ) : (
                  <button
                    key={`delivery-time-${index}`}
                    onClick={() => {
                      ChangeDeliveryTime(item[0]);
                    }}
                  >
                    {item[1]}
                  </button>
                );
              })}
            </div>
          </div>


          <div className='cart-main-page-amount-row-heading-2'>
            PAYMENT MODE
          </div>
          <div className='cart-main-page-amount-row-payment-mode-2'>
            <form>
              <input
                id='payment-mode-input-id-is-cash-on-delivery'
                type='radio'
                name='payment'
                value='cod'
                onChange={handleChange}
              ></input>
              <label htmlFor='payment-mode-input-id-is-cash-on-delivery'>
                Cash On Delivery
              </label>
              <br></br>

              {/* <input
                id='payment-mode-input-id-is-online-payment'
                type='radio'
                name='payment'
                value='payupi'

              ></input>
              <label htmlFor='payment-mode-input-id-is-online-payment'>
                Pay through UPI
              </label> */}

              <br />

              {/* {Userprofile ? (
                <>

                  <>
                    <input
                      id='payment-mode-input-id-is-wallet-payment'
                      type='radio'
                      name='payment'
                      value='wallet'
                      onChange={handleChange}
                    ></input>
                    <label htmlFor='payment-mode-input-id-is-wallet-payment'>
                      Pay Using Wallet
                    </label>
                    <div className='payment-mode-is-wallet-payment-balance'>  Wallet balance ₹ {Userprofile.wallet_balance ? Userprofile.wallet_balance : 0}</div>
                    <br></br>
                  </>

                </>
              ) : (
                <span></span>
              )} */}



            </form>
          </div>

          <div className='cart-main-page-amount-row-button-2'>
            <Link to='/cart/final'>
              <button onClick={() => {
                if (checkstatus()) {
                  setPaymentclick(true);
                }
              }}>
                {!paymentclick ?
                  <>PLACE ORDER</> : <>PROCESSING...</>
                }
              </button>
            </Link>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
