import React, { useEffect, useState } from "react";
import { useGlobalContext } from "../context";
import Loading from "../components/Loading";
import postapi from "../api/postapi";
import notify from "../error";
import Comment from "./Comment";
import "../post-css/postCardModal.css";
import { AiOutlineLike } from "react-icons/ai";
import { AiFillLike } from "react-icons/ai";
import { FaUserCircle,FaPaperPlane } from "react-icons/fa";

// images & svgs
import cmt from "../images/blogimages/Comments.svg";
import photo from "../images/post-photo.svg";
import mic from "../images/post-mic.svg";
import share from "../images/blogimages/shareImg.svg";
import logo from "../images/logo.png";
import noUserImg from "../images/DTh/account.png";
import cnacleUpload from '../images/blogimages/cnacleUpload.svg';
import { usePostContext } from "../postContext";

function PostCardModal({ id, profileInfo }) {
  const noImage =
    "https://images.unsplash.com/photo-15943getSinglePostDat22436404-5a0526db4d13?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1129&q=80";
  const [singlePost, setSinglePost] = useState();
  const [allComments, setAllComments] = useState();
  const [singlePostComment, setSinglePostComment] = useState("");
  const { modifyModal, setModifyModal, Userprofile, loading, setLoading,setLoginPage } =
    useGlobalContext();
  const { postUpdate, setPostUpdate,setPostInfo,setloadingsingle,
    Loadingsingle,setDisplay } = usePostContext();

  const getSinglePostData = async (postId) => {
    // setLoading(true);
    setloadingsingle(true);
    try {
      const res = await postapi.SinglePost(postId);
      if (res) {
        setSinglePost(res.data.data[0]);
        console.log(res.data.data[0],res.data["Comment Data"])
        // setAllComments(res.data["Comment Data"]);
        // setLoading(false);
        setloadingsingle(false);
      }
    } catch (error) {
      notify("error", "Could not fetch the posts...");
      console.log(error);
    }
  };

  const postComment = async (postId, comment) => {
    try {
      const response = await postapi.CommentPost(postId, comment);
      if (response.status === 200) {
        notify("success", "Comment added succssfully...");
        setPostUpdate((prevState) => !prevState);
      }
    } catch (error) {
      notify("error", "Could not post comment...");
      console.log(error);
    }
  };

  const enterKeyHandler = (event) => {
    if (event.key === "Enter") {
      event.target.blur();
      setSinglePostComment("");
      postComment(id, singlePostComment);
    }
  };

  const getNewComments = async (id) => {
    try {
      const response = await postapi.DisplayCommentPost(id);
      setAllComments(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getNewComments(id);
    console.log("jjsjsjs")
  }, [id, postUpdate]);

  useEffect(() => {
    
    getSinglePostData(id);
    console.log("jjsjsjs11")
  }, [id]);

  return (
    <>
      {singlePost  ? (
        <div className="post-card-modal-container">
          <img
            className="crossIcon"
            src={cnacleUpload}
            alt="cross_icon"
            onClick={() => {
              setModifyModal(false);
              setSinglePost();
              setPostInfo();
            }}
          />
          <div className="post-card-modal-top-row-details">
            <div className="userprofileImage">
              {profileInfo.img ? (
                <img src={profileInfo.img} alt="" />
              ) : (
                <FaUserCircle size={42} color="#00838f" />
              )}
            </div>
            <div className="post-card-modal-top-row-info">
              <div
                style={{
                  marginBottom: "5px",
                  fontWeight: "600",
                  fontSize: "15px",
                }}
              >
                <b>{singlePost.name || "Unkown user"}</b>
              </div>
              <p style={{ fontSize: 12 }}>{singlePost?.datetime}</p>
            </div>
            <div
              className="post-card-modal-top-row-cross"
              onClick={() => setModifyModal(false)}
              style={{ fontSize: "28px" }}
            >
              &times;
            </div>
          </div>
          <div
            className="post-card-post-text-heading post-card-post-text"
            dangerouslySetInnerHTML={{
              __html: singlePost?.detail,
            }}
          />
          <div className="post-card-modal-image">
            {singlePost?.image ? (
              <img src={singlePost?.image} alt="" />
            ) : (
              <img src={noImage} alt="alt_icon" />
            )}
          </div>
          <div className="post-card-modal-post-comments">
            {allComments.length > 0 ? (
              allComments.map((item, id) => <Comment key={id} data={item} />)
            ) : (
              <p>No comments yet</p>
            )}
          </div>

          <div className="likesAndMorePosts">
            <div className="likesPosts">
              {profileInfo.isLiked === true ? (
                <AiFillLike className="likeIcon" />
              ) : (
                <AiOutlineLike className="likeIcon" />
              )}
              <p>{profileInfo?.totalLikes || 0}</p>
            </div>
            <div className="commentsPosts">
              <img src={cmt} alt="commentIcon" />
              <span>{allComments.length || 0}</span>
            </div>
            <div className="sharePost">
              <img src={share} alt="shareIcon" />
              <p>{profileInfo?.share_count || 0}</p>
            </div>
          </div>

          <div className="singleBlogComment">
            {profileInfo.img ? (
              <img
                className="blogCommentUserProfileImg"
                src={profileInfo.img}
                alt="blogUserProfileImg"
              />
            ) : (
              <FaUserCircle size={42} color="#00838f" />
            )}
            <input
              value={singlePostComment}
              placeholder="Write a comment..."
              type="text"
              onChange={(e) => setSinglePostComment(e.target.value)}
              // {...(isCommentActive ? { autoFocus: true } : {})}
              onKeyDown={(e) => enterKeyHandler(e)}
            />
            {/* <div className="post-card-add-comment-image">
              <img src={mic} alt="" />
              <img src={photo} alt="" />
            </div> */}
             <FaPaperPlane className="sendIcon1" onClick={(event)=>{
              if (!Userprofile) {
                setDisplay("login-sidebar-container2");
                setLoginPage(1);
                notify("error", "Login First To Comment");
                return;
              }
              if(singlePostComment !== ""){
                setSinglePostComment('')
                postComment(id, singlePostComment);
                  setPostUpdate(true);
                  return ;
              }
              else {
                notify("error","Comment Should Not Be Empty")
              }
             }}></FaPaperPlane>
          </div>
        </div>
      ) : (
        Loadingsingle && (
          <div className="modal_loading">
            <Loading />
          </div>
        )
      )}
    </>
  );
}

export default PostCardModal;
