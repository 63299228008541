import React from "react";
// import './myprofile.css';

import post from "../images/DTh/post.png";
import Electric from "../images/2.png";
import challan from "../images/6.png";
import gas from "../images/3.png";
import water from "../images/4.png";
import emptypage from "../images/chatImages/image3.svg";
import edu from "../images/5.png";
import life from "../images/9.png";
import health from "../images/8.png";
import municipal from "../images/7.png";

import landline from "../images/22.png";
import Footer from "../components/footer";
import "../myprofile/myprofile.css";
import { IoIosArrowDropleftCircle } from "react-icons/io";

import LoanIcon from "../images/10.png";
// import ProfileSection from './profileSection';
import DTHServiceSection from "./DthServiceSection";
import { useGlobalContext } from "../context";

export default function DthRechargeViewPage() {

  const { loginDetail, Userprofile,rechargepage, setRechargePage } = useGlobalContext();
  // console.log(Userprofile,'k')
  const [page, setPage] = React.useState(0);
  // let page = React.useRef(0);
  function DecidePage(e) {
    console.log(e.target.dataset.value);
    // page=e.target.dataset.value;
    // console.log(page)  ////see react doesnot rerender child even when ref is passed as a prop
    setPage(e.target.dataset.value);
    setRechargePage(e.target.dataset.value);
  }
  const { expand, setExpand } = useGlobalContext();
  function handleExpandClick() {
    setExpand((expand) => !expand);
    // setSidebarMenu((prevState) =>!prevState);
  }
  React.useEffect(() => {
    // Cleanup function to reset rechargepage when component unmounts
    return () => {
      setRechargePage(0); // Reset rechargepage to an empty value
    };
  }, [setRechargePage]);
  return (
    <>
      {Userprofile ? (
        <div className="payements-container">
          <div className="myprofileMainDiv" style={{ overflowY: "scroll" }}>
            {!expand && (
              <div className="myprofileExpandDiv">
                {/* <BiMenuAltLeft className='expand-icon' onClick={() => handleExpandClick()} /> */}
                <IoIosArrowDropleftCircle
                  className="expand-icon"
                  onClick={() => handleExpandClick()}
                />
              </div>
            )}
            <div
              className={`myprofileMainDivFirst ${
                !expand ? "closeSidebar" : ""
              }`}
            >
              <div className="myprofileprofleleftsideNav myprofileprofleleftsideNav1">
                <div
                  className="profilenavitem"
                  style={{ padding: 0, pointerEvents: "none" }}
                  onClick={(e) => {
                    DecidePage(e);
                  }}
                >
                  <div className="profileNavItemNav">
                    <p style={{ fontSize: "18px" }}>
                      <b>Bill Payments</b>
                    </p>
                  </div>
                </div>

                <div
                  className="profilenavitem"
                  data-value="7"
                  onClick={(e) => {
                    setExpand(false);
                    DecidePage(e);
                  }}
                >
                  <div className="profileNavImg " data-value="7">
                    <img className="bill-img" src={Electric} data-value="7" />
                  </div>
                  <div className="profileNavItemNav" data-value="7">
                    <p data-value="7">Electricity</p>
                  </div>
                </div>

                <div
                  className="profilenavitem"
                  data-value="8"
                  onClick={(e) => {
                    setExpand(false);
                    DecidePage(e);
                  }}
                >
                  <div className="profileNavImg" data-value="8">
                    <img className="bill-img" src={gas} data-value="8" />
                  </div>
                  <div className="profileNavItemNav" data-value="8">
                    <p data-value="8">Gas Booking</p>
                  </div>
                </div>

                {/* special case of my wallet see data-value */}

                <div
                  className="profilenavitem"
                  data-value="9"
                  onClick={(e) => {
                    setExpand(false);
                    DecidePage(e);
                  }}
                >
                  <div className="profileNavImg" data-value="9">
                    <img className="bill-img" src={water} data-value="9" />
                  </div>
                  <div className="profileNavItemNav" data-value="9">
                    <p data-value="9">Water Bill</p>
                  </div>
                </div>

                <div
                  className="profilenavitem"
                  data-value="10"
                  onClick={(e) => {
                    setExpand(false);
                    DecidePage(e);
                  }}
                >
                  <div className="profileNavImg" data-value="10">
                    <img className="bill-img" src={challan} data-value="10" />
                  </div>
                  <div className="profileNavItemNav" data-value="10">
                    <p data-value="10">Challan Payment</p>
                  </div>
                </div>

                <div
                  className="profilenavitem"
                  data-value="11"
                  onClick={(e) => {
                    setExpand(false);
                    DecidePage(e);
                  }}
                >
                  <div className="profileNavImg" data-value="11">
                    <img className="bill-img" src={landline} data-value="11" />
                  </div>
                  <div className="profileNavItemNav" data-value="11">
                    <p data-value="11">LandLine Bill</p>
                  </div>
                </div>

                <div
                  className="profilenavitem"
                  data-value="12"
                  onClick={(e) => {
                    setExpand(false);
                    DecidePage(e);
                  }}
                >
                  <div className="profileNavImg" data-value="12">
                    <img className="bill-img" src={edu} data-value="12" />
                  </div>
                  <div className="profileNavItemNav" data-value="12">
                    <p data-value="12">Education Fees</p>
                  </div>
                </div>

                <div
                  className="profilenavitem"
                  style={{ padding: 0, pointerEvents: "none" }}
                  onClick={(e) => {
                    setExpand(false);
                    DecidePage(e);
                  }}
                >
                  <div className="profileNavItemNav">
                    <p style={{ fontSize: "18px" }}>
                      <b>Financial Services and Taxes</b>
                    </p>
                  </div>
                </div>

                <div
                  className="profilenavitem"
                  data-value="13"
                  onClick={(e) => {
                    setExpand(false);
                    DecidePage(e);
                  }}
                >
                  <div className="profileNavImg" data-value="13">
                    <img className="bill-img" src={LoanIcon} data-value="13" />
                  </div>
                  <div className="profileNavItemNav" data-value="13">
                    <p data-value="13">Loan Repayment</p>
                  </div>
                </div>

                <div
                  className="profilenavitem"
                  data-value="14"
                  onClick={(e) => {
                    setExpand(false);
                    DecidePage(e);
                  }}
                >
                  <div className="profileNavImg" data-value="14">
                    <img className="bill-img" src={life} data-value="14" />
                  </div>
                  <div className="profileNavItemNav" data-value="14">
                    <p data-value="14">Life Insurance</p>
                  </div>
                </div>

                <div
                  className="profilenavitem"
                  data-value="15"
                  onClick={(e) => {
                    setExpand(false);
                    DecidePage(e);
                  }}
                >
                  <div className="profileNavImg" data-value="15">
                    <img className="bill-img" src={health} data-value="15" />
                  </div>
                  <div className="profileNavItemNav" data-value="15">
                    <p data-value="15">Health Insurance</p>
                  </div>
                </div>

                <div
                  className="profilenavitem"
                  data-value="16"
                  onClick={(e) => {
                    setExpand(false);
                    DecidePage(e);
                  }}
                >
                  <div className="profileNavImg" data-value="16">
                    <img className="bill-img" src={municipal} data-value="16" />
                  </div>
                  <div className="profileNavItemNav" data-value="16">
                    <p data-value="16">Municipal Services</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`myprofileMaindivSecond ${
                expand && "hideMyprofileMaindivSecond"
              }`}
            >
              <DTHServiceSection pageNo={rechargepage} />
            </div>
          </div>
        </div>
      ) : (
        <div className="login-to-continue-chat-page">
          <div className="login-to-continue-photo">
            <img src={emptypage}></img>
          </div>
          <span>Login TO Continue</span>
        </div>
      )}
    </>
  );
}
