import { useCallback, useEffect, useRef, useState } from "react";
import { useGlobalContext } from "../../context";
import { BsPlus } from "react-icons/bs";
import AllProductsEditPriceModal from "./AllProductsEditPriceModal";
import myProfileServiceApi from "../../api/myProfileServiceApi";
import notify from "../../error";
import Loading from "../../components/Loading";
import { IoIosArrowDown } from "react-icons/io";

export default function AllProductsCard({
  index,
  length,
  item,
selectedQty,
selectedQtyIndex,
  colorItemIndexes,
  showMorecolours,handleChangeColor,toggleShowMore,setColorItemIndexes
}) {

  // *********TO close the drop down when clicking outside menue ***********//
  // const colorDropdownRef = useRef(null);

  // const closeColorDropdown = (event) => {
  //   if (colorDropdownRef.current && !colorDropdownRef.current.contains(event.target)) {
  //     setShowMorecolours(false);
  //   }
  // };
  // useEffect(() => {
  //   document.addEventListener('click', closeColorDropdown);
  //   return () => {
  //     document.removeEventListener('click', closeColorDropdown);
  //   };
  // }, []);

  // // **********To close the drop-down when choosing other color*******/
  // const handleColorClick = (index) => {
  //   setcolorItemIndex(index);
  //   setShowMorecolours(false); 
  // };

  const [displayPriceQuantityMap, setDisplayPriceQuantityMap] = useState({});
  const [displayPriceQuantity, setDisplayPriceQuantity] = useState(0);
  const [quantityIndex, setQuantityIndex] = useState(0);
  const [units, setUnits] = useState(selectedQty.units);
  const [baseunit,setBaseunit]=useState(selectedQty.units);
  const [displayPriceUnitValue, setDisplayPriceUnitValue] = useState([
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
  ]);
  const [emptyFetch, setEmptyFetch] = useState(false);
  
  const [isEditing, setIsEditing] = useState(false);
  const {
    modifyModal,
    setModifyModal,
    loading,
    setLoading,
    startIndex,
    setStartIndex,
    inputPrice,
    setInputprice,
  } = useGlobalContext();

  useEffect(()=>{
    setUnits(item.qty_list[quantityIndex].units);
    setBaseunit(item.qty_list[quantityIndex].units);
  },[quantityIndex])
  
  const handleDisplayPriceQuantityChange = (index, e) => {
    // console.log(e.target.value);
    const value = e.target.value;
    console.log(value);
    setDisplayPriceQuantityMap((prevMap) => ({
      ...prevMap,
      [index]: value,
    }));
    setQuantityIndex(value);

    setColorItemIndexes((prevColorItemIndexes) => {
      const newColorItemIndexes = [...prevColorItemIndexes];
      newColorItemIndexes[index] = 0; // Set colorItemIndex to 0 for the specific index
      return newColorItemIndexes;
    });
  };

  const handleGenerateBillArray = async (
    item,
    productId,
    color,
    quantityID,
    units,
    serviceProviderId,
    serviceId,
    ) => {
    // console.log(
    //   item,
    //   productId,
    //   color,
    //   quantityID,
    //   quantity,
    //   serviceProviderId,
    //   serviceId,
    //   isEditing && inputPrice
    // );
    console.log(units);
    try {
      const res = await myProfileServiceApi.ServiceAddGenerateBillProduct(
        productId,
        color,
        quantityID,
        units,
        serviceProviderId,
        serviceId,
        isEditing && parseInt(inputPrice),
      );
      if (res.status === 200)
        notify("success", "Product added successfully...");
    } catch (err) {
      console.log(err);
    }
  };

  const observer = useRef();
  const markerRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && emptyFetch === false) {
          setStartIndex((prevStartIndex) => prevStartIndex + 18);
          console.log(emptyFetch);
          console.log("here");
        }
        if (emptyFetch) setLoading(false);
      });
      if (node) observer.current.observe(node);
    },
    [setStartIndex, emptyFetch]
  );

  const EditModal = (price) => {
    setInputprice(showColorInfo ? item.qty_list[quantityIndex].color[colorItemIndexes[index]]?.selling_price : item.qty_list[quantityIndex]?.selling_price);
    setIsEditing(true);
    setModifyModal(true);
  };

  console.log(selectedQty,selectedQtyIndex);
                
  const showColorInfo =
 item.qty_list[quantityIndex].color &&
 item.qty_list[quantityIndex].color.length > 0 &&
  Object.keys(item.qty_list[quantityIndex].color[colorItemIndexes[index]] || {}).length > 0;

  // console.log(selectedQty.images, selectedQty, index, colorItemIndexes, colorItemIndexes[index], selectedQty.color[colorItemIndexes[index]], showColorInfo, Object.keys(selectedQty.color[colorItemIndexes[index]] || {}), Object.keys(selectedQty.color[colorItemIndexes[index]] || {}).length);
  // console.log(showColorInfo,quantityIndex,item.qty_list[quantityIndex].color,item.qty_list[quantityIndex].color.length > 0, Object.keys(selectedQty.color[colorItemIndexes[index]] || {}).length)
  return (
    <>
      <div className="offline-profile-serivce-main-page-main-box-card">
        <div className="offline-profile-serivce-main-page-main-box-card-image">
           {
                            showColorInfo &&
                            item?.qty_list[quantityIndex]?.color &&
                            item?.qty_list[quantityIndex]?.color.length > 0 &&
                            item?.qty_list[quantityIndex]?.color[colorItemIndexes[index]]?.image.length > 0      ?  <img src={item?.qty_list[quantityIndex]?.color[colorItemIndexes[index]].image[0].image}   alt=""></img>:
                    
                          <img
                            src={
                              item?.qty_list[quantityIndex]?.images[0]?.image 
                            }
                            alt=""
                          />
                }
        </div>
        <div className="offline-profile-serivce-main-page-main-box-card-names">
          <div className="offline-product-brand">{item.brand}</div>
          <div className="offline-product-name">{item.product_name}</div>

          <div className="offline-product-type">{item.producttype}</div>
        </div>
        <div className="offline-profile-serivce-main-page-main-box-card-quantity">
          <div>
            <span>Quantity :&nbsp;</span>
            <select
              onChange={(e) => handleDisplayPriceQuantityChange(index, e)}

            >
              {item.qty_list &&
                item.qty_list.length > 0 &&
                item.qty_list.map((item2, index) => {
                  return (
                    <option key={index} value={index}>
                      {item2.quantity}
                    </option>
                  );
                })}
            </select>
          </div>
          <div>
            <span>Units :&nbsp;</span>
            <select onChange={(e) => setUnits(baseunit*parseInt(e.target.value))}>
       
                                {showColorInfo 
    ?      displayPriceUnitValue.map((item2, ind) => (
        <option key={ind} value={item2}>
          {item2*item.qty_list[quantityIndex].color[colorItemIndexes[index]].unit}
        </option>
      ))
    : displayPriceUnitValue &&
      displayPriceUnitValue.map((item2, index) => (
        <option key={index} value={item2}>
          {item2 *item.qty_list[quantityIndex].units}
        </option>
      ))}
            </select>
            
          </div>
          <div class="service-color-choices service-color-left-view"  >
                              {showColorInfo &&item.qty_list[quantityIndex].color &&item.qty_list[quantityIndex].color.length > 0 && (
                                <>
                                <span>Color:</span>
                                <div className="initial-choice service-color-choice " style={{
                                  backgroundColor:
                                   item.qty_list[quantityIndex].color[colorItemIndexes[index]].color_code,
                                }}
                                >
                                </div>
                                </>
                              )}
 {showColorInfo && item.qty_list[quantityIndex].color && item.qty_list[quantityIndex].color.length > 1 && <span onClick={() => toggleShowMore(index)}>{"+"+ item.qty_list[quantityIndex].color.length+ " " } <IoIosArrowDown size={16} fontWeight={600} /></span>}
                              {showMorecolours[index] &&item.qty_list[quantityIndex].color.length > 1 && (
                                <div className="additional-choices-container">
                                  {item.qty_list[quantityIndex].color.map((color, colorindex) => (
                                    <div
                                      className={`additional-choice service-color-choice ${colorItemIndexes[index] === colorindex ? "initial-choice" : ""
                                        }`}

                                      style={{ backgroundColor: color.color_code }}
                                      onClick={() => handleChangeColor(index, colorindex)}
                                    >
                                      {/* {color.color_name} */}
                                    </div>
                                  ))}
                                </div>
                              )}



                            </div>
        </div>
        <div className="offline-profile-serivce-main-page-main-box-card-price">
        <div>{showColorInfo ?item.qty_list[quantityIndex].color[colorItemIndexes[index]].discount :item.qty_list[quantityIndex] &&item.qty_list[quantityIndex].discount} % Off</div>
          <span>
            &#8377;
            {showColorInfo ?item.qty_list[quantityIndex].color[colorItemIndexes[index]].actual_price :item.qty_list[quantityIndex] &&item.qty_list[quantityIndex].actual_price}
          </span>
          <section>
            &#8377;
            {isEditing && !modifyModal
              ? inputPrice
              :  showColorInfo ?item.qty_list[quantityIndex].color[colorItemIndexes[index]]?.selling_price :item.qty_list[quantityIndex] &&item.qty_list[quantityIndex]?.selling_price}
          </section>
          <button
            className="allProducts-edit-button"
            onClick={() => (isEditing ? setIsEditing(false) : EditModal())}
          >
            {isEditing && !modifyModal ? "Cancel" : "Edit"}
          </button>
          {modifyModal && (
            <AllProductsEditPriceModal
              isEditing={isEditing}
              setIsEditing={setIsEditing}
            />
          )}
          <BsPlus
            className="addPlus"
            onClick={() =>{
              handleGenerateBillArray(
                item,
                item.qty_list[quantityIndex].product_id,
                item.qty_list[quantityIndex].color[colorItemIndexes[index]],
                item.qty_list[quantityIndex].quantity_id,
                units,
                item.qty_list[quantityIndex].id,
                item.service_id,
                item.qty_list[quantityIndex].selling_price
              )
              // console.log(item.qty_list[quantityIndex].color[colorItemIndexes[index]]);
            }
          }
          />
        </div>
      </div>
      {index === length - 1 && (
        <div className="marker" style={{ minHeight: "200px" }} ref={markerRef}>
          {loading ? (
            <Loading />
          ) : (
            <div className="allfetched">"That's all we have"</div>
          )}
        </div>
      )}
    </>
  );
}
