import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

class FeedBackService {
  FeedBackReceived() {
    return axios.get(API_BASE_URL + '/v1/api/showanonymous', {
      headers: {
        authtoken: localStorage.getItem('authtoken'),
      },
    });
  }
  FeedbackSend() {
    return axios.get(API_BASE_URL + '/v1/api/showmyfeedback', {
      headers: {
        authtoken: localStorage.getItem('authtoken'),
      },
    });
  }
  ShowReplies(message_id) {
    return axios.post(
      API_BASE_URL + '/v1/api/showreplyanon',
      {
        message_id,
      },
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }
  DeleteReplies(rid, mid) {
    return axios.get(
      API_BASE_URL + '/v1/api/DeleteAnonymous/' + rid + '/' + mid,
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }
  SendFeedback(Message, username, mobile) {
    return axios.post(
      API_BASE_URL + '/v1/api/sendanonymous',
      {
        Message,
        username,
        mobile,
      },
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }
  DeleteFeedback(id) {
    return axios.post(
      API_BASE_URL + '/v1/api/deleteAnonMessage',
      {
        id,
      },
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }
  ReplyToFeedbackGiven(Reply_Message, message_id) {
    return axios.post(
      API_BASE_URL + '/v1/api/sendreplyanongiven',
      {
        Reply_Message,
        message_id,
      },
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }
  ReplyToFeedbackReceived(Reply_Message, message_id) {
    return axios.post(
      API_BASE_URL + '/v1/api/sendreplyanonreceived',
      {
        Reply_Message,
        message_id,
      },
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }
  EditFeedBackMessage(id, message) {
    return axios.post(
      API_BASE_URL + '/v1/api/EditAnonymousMessage/' + id,
      {
        message,
      },
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }
  EditFeedBackReplies(id,message)
  {
    return axios.post(
      API_BASE_URL + '/v1/api/EditFeedbackReply/' + id,
      {
        message,
      },
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }
}
export default new FeedBackService();
