import React, { useState, useEffect, useRef } from 'react';
import newapi from '../api/newapi';
import './news-css/news.css';
import logo from '../images/logo.png';
import Article from './Article';
import searchicon from '../images/search-svgrepo-com.svg';
import {IoCloseCircleSharp} from 'react-icons/io5'
import Loading from '../components/Loading';


export default function News() {
  const [searchTerm, setSearchTerm] = useState('');
  const categoryname=["Business","Entertainment","General","Health","Science","Sports","Technology"];
  const [category, setCategory] = useState(0);
  const [news, setNews] = useState();
  const [isEmptyField, setIsEmptyField] = useState(false);
  const [emptyFetch, setEmptyFetch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await newapi.ShowNews(allCategory[category], searchTerm);
      if (response.data.news.articles.length === 0) {
        setEmptyFetch(true);
        setNews();
        console.log("No results found!");
      } else {
      
        setEmptyFetch(false);
        setNews(response.data.news);
 
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [category, isEmptyField]);

  const handleChangeForm = (e) => {
    setSearchTerm(e.target.value);
    if (e.target.value === '') {
      setIsEmptyField(true);
    } else {
      setIsEmptyField(false);
    }
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    fetchData();
  };

  const allCategory = ['Business', 'Entertainment', 'General', 'Health', 'Science', 'Sports'];

  const selection = (val) => {
    DisplaySelectors2();
    setCategory(val);
  };

  function DisplaySelectors() {
    wrapperRef.current.className = 'all-news-page-selector-cointainer2';
    const elem = document.getElementById('all-news-page-selector-id');
    elem.className = 'all-news-page-selector';
  }

  function DisplaySelectors2() {
    wrapperRef.current.className = 'all-news-page-selector-cointainer';
    const elem = document.getElementById('all-news-page-selector-id');
    elem.className = 'all-news-page-selector-none';
  }

  const wrapperRef = useRef(null);

  return (
    <React.Fragment>
      <div className='news-page-container'>
        <div className='news-page-top-search-container'>
          <form onSubmit={handleSubmitForm}>
            <div className='news-page-search-container'>
              <input
                type='text'
                name='search'
                value={searchTerm}
                onChange={handleChangeForm}
                placeholder='Search for news'
              ></input>
              <div className="news-page-search-image">
                <img src={searchicon} onClick={fetchData}></img>
              </div>
            </div>
          </form>
        </div>
        <div className='news-page-new-article-container'>
          {isLoading? <div style={{height:"50vh",display:"flex",justifyContent:"center",width:"100vw",alignItems:"center"}}><Loading/></div>:
          news &&
            news.articles &&
            news.articles.map((item, index) => {
              return <Article key={index} data={item}></Article>;
            })}
        </div>

        <div
          className='all-news-page-selector-cointainer'
          ref={wrapperRef}
          onMouseOver={DisplaySelectors}
          onMouseOut={DisplaySelectors2}
        >
          <div
            id='all-news-page-selector-id'
            className='all-news-page-selector-none'
          >
            <div className='all-news-page-selector-heading'>
             <p>Choose News Of Your Choice</p> 
              <div className='cross-icon' onClick={DisplaySelectors2}>
              <IoCloseCircleSharp size={30} />
            </div>
            </div>
            <div className='all-news-page-selector-selectors'>
              {allCategory.map((item, index) => {
                if (index === category) {
                  return (
                    <div
                      className='all-news-page-selector-tab-selected'
                      onClick={() => {
                        selection(index);
                      }}
                    >
                      {item}
                    </div>
                  );
                }
                return (
                  <div
                    className='all-news-page-selector-tab'
                    onClick={() => { selection(index); }}
                  >
                    {item}
                  </div>
                );
              })}
            </div>
          </div>
          <div className='all-news-page-selector-cointainer-image'>
            <img
              src={logo}
              onClick={DisplaySelectors}
              onMouseOver={DisplaySelectors}
              onMouseOut={DisplaySelectors2}
            ></img>
          </div>
        </div>
        {emptyFetch && <div className='all-news-page-tag'>No results found!!!</div>}
      </div>
    </React.Fragment>
  );
}