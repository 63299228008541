import React from "react";
import "./css/profileSection.css";
import homepic from "./myprofileIcoons/homeimage.svg";
import AddServiceProvider from "./components/addServiceProvider";
import AccountSettings from "./components/accountsettings";
import MyBillsTwo from "./components/MyBills/MyBillsTwo";
import MyWalletMainMainPage from "./MYNewWallet/mywalletMainMainView";
import MyBankAccountMainPage from "./MyBankAccounts/myBankAccountMainPage";
import ReferAndEarnPageMain from "./ReferAndEarn/ReferAndEarnMainView";
import Managedelivery from "./ManageDelivery/Manage_delivery";
import MyJobApplication from "./MyJobApplication/myJobApplicationView";
import FeedBackMainPageView from "./MyFeedback/myfeedbackMainView";
import ConfessionMainPageView from "./MyConfession/myConfessionMainView";
import MyOrderMainPage from "./Myorders/MyorderMainPage";
import Myblog from "./MyBlogs/Myblog";
import MyPost from "../post-components/myPost";
import Mybillprofilepageviewmain from "./MyBills/Mybillprofilepageviewmain";
import MyServiceMainPage from "./MyServices/myServicemainpage";
import { PostProvider } from '../postContext';
import FollowList from "./FollowList/FollowList";
export default function ProfileSection(props) {
  // console.log(props, "here", props.pageNo === "6");
  // console.log(props.pageNo === "6");
  // console.log(props.pageNo);
  if (props.pageNo === 0) {
    return (
      <React.Fragment>
        <div className="profile-section-container">
          <div className="profile-section-container-0-image">
            <img src={homepic}></img>
          </div>
        </div>
      </React.Fragment>
    );
  } else if (props.pageNo === "1") {
    return (
      <React.Fragment>
        <div className="profile-section-container2">
          <MyServiceMainPage></MyServiceMainPage>
        </div>
      </React.Fragment>
    );
  } else if (props.pageNo === "2") {
    return (
      <React.Fragment>
        <div className="profile-section-container2">
          <Mybillprofilepageviewmain></Mybillprofilepageviewmain>
        </div>
      </React.Fragment>
    );
  } else if (props.pageNo === "3") {
    return (
      <React.Fragment>
        <div className="profile-section-container2">
          <MyOrderMainPage></MyOrderMainPage>
        </div>
      </React.Fragment>
    );
  } else if (props.pageNo === "4") {
    return (
      <React.Fragment>
        <div className="profile-section-container2">
          <MyBankAccountMainPage></MyBankAccountMainPage>
        </div>
      </React.Fragment>
    );
  } else if (props.pageNo === "5") {
    return (
      <React.Fragment>
        <div className="profile-section-container2">
          <ReferAndEarnPageMain></ReferAndEarnPageMain>
        </div>
      </React.Fragment>
    );
  } else if (props.pageNo === "6") {
    return (
      <React.Fragment>
        <div className="profile-section-container2">
          <AddServiceProvider />
        </div>
      </React.Fragment>
    );
  } else if (props.pageNo === "7") {
    return (
      <React.Fragment>
        <div className="profile-section-container2">
          <MyJobApplication></MyJobApplication>
        </div>
      </React.Fragment>
    );
  } else if (props.pageNo === "8") {
    return (
      <React.Fragment>
        <div className="profile-section-container2">
          <FeedBackMainPageView></FeedBackMainPageView>
        </div>
      </React.Fragment>
    );
  } else if (props.pageNo === "9") {
    return (
      <React.Fragment>
        <div className="profile-section-container2">
          <ConfessionMainPageView></ConfessionMainPageView>
        </div>
      </React.Fragment>
    );
  } else if (props.pageNo === "10") {
    return (
      <React.Fragment>
        <div className="profile-section-container2" style={{ overflow: 'hidden' }}>
          <PostProvider>
            <MyPost />
          </PostProvider>
        </div>
      </React.Fragment>
    );
  }
  else if (props.pageNo === "11") {
    return (
      <React.Fragment>
        <div className="profile-section-container2" style={{ overflow: 'hidden' }}>
          <Myblog />
        </div>
      </React.Fragment>
    );
  } else if (props.pageNo === "12") {
    return (
      <React.Fragment>
        <div className="profile-section-container2">
          <AccountSettings />
        </div>
      </React.Fragment>
    );
  } else if (props.pageNo === "13") {
    return (
      <React.Fragment>
        <div className="profile-section-container2">
          <MyWalletMainMainPage></MyWalletMainMainPage>
        </div>
      </React.Fragment>
    );
  } else if (props.pageNo === "14") {
    return (
      <React.Fragment>
        <div className="profile-section-container2">
          <FollowList></FollowList>
        </div>
      </React.Fragment>
    );
  }
  else if (props.pageNo === "60") {
    return (
      <React.Fragment>
        <div className="profile-section-container2">
          <Managedelivery></Managedelivery>
        </div>
      </React.Fragment>
    );
  }
  else {
    return (
      <React.Fragment>
        no {props.pageNo} {props.pageNo === "6"}
      </React.Fragment>
    );
  }
}
