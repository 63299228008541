import React from "react";
import "../css/popularProductCard.css";
import defaultImage from "../images/imageNotFound.png";
import { Link } from "react-router-dom";

export default function Categorycard(props) {
  // console.log(props)
  const name = () => {
    // console.log(e)
    // console.log(props)
    // console.log([props.service_id, props.sub_service_id,]);
    // setUserName([props.service_id, props.sub_service_id]);
    if (props.type_id2 > 0) {
      // console.log([props.service_id, props.sub_service_id, props.type_id2]);
      localStorage.setItem("productData", [
        props.service_id,
        props.sub_service_id,
        props.type_id2,
      ]);
    } else {
      localStorage.setItem("productData", [
        props.service_id,
        props.sub_service_id,
        props.id,
      ]);
    }
    // console.log('here')
    // console.log(userName,'username');
  };

  return (
    <React.Fragment>
      <div className="service-provider-card">
        <Link to="/productProvider">
          <div className="service-provider-image-container" onClick={name}>
            {props.product_type_image ? (
              <img
                src={props.product_type_image}
                className="service-provider-image"
                alt="Logo"
              />
            ) : (
              <img
                src={defaultImage}
                className="service-provider-image"
                alt="Default Logo"
              />
            )}
          </div>
        </Link>
        <div
          className="service-provider-card-details-top"
          style={{ marginBottom: "1px", justifyContent: "center" }}
        >
          <div
            className="service-provider-card-details-brand-name"
            style={{ width: "100%", textAlign: "center" }}
          >
            {props.product_type_name}
          </div>
        </div>
        <div className="service-provider-card-details-mid">
          <div
            className="service-provider-card-details-price"
            style={{ maxWidth: "100%", textAlign: "center" }}
          >
            UP TO{" "}
            {props.product_type_discount !== null &&
            props.product_type_discount > 0 ? (
              <span style={{ color: "red", fontWeight: "800" }}>
                {props.product_type_discount}% OFF
              </span>
            ) : (
              <span style={{ color: "red", fontWeight: "800" }}>0% OFF</span>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
