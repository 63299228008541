import { AiOutlineLike } from "react-icons/ai";
import { AiFillLike } from "react-icons/ai";
import React from "react";
import "./css/confessioncard.css";
import likes from "../../images/blogimages/likes.svg";
import noimage from "../../images/no-picture-available-icon-1.jpg";
import Confessionapi from "../../api/Confessionapi";
import notify from "../../error";
export default function ConcessionCard(props) {
  const regex = /<h5 class="confessionHeading">(.*?)<\/h5>(.*)/;
  const [extractedText, setExtractedText] = React.useState(null);
  const [heading, setHeading] = React.useState(null);
  const [display, setDisplay] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [editData, setEditData] = React.useState(extractedText);
  const [replyConfession, setReplyConfession] = React.useState([]);
  const [showreplyData, setshowReplyData] = React.useState(false);
  const [reply, setReply] = React.useState("");
  const [likesCount, setLikesCount] = React.useState(props.data.like);
  const [isLiked, setIsLiked] = React.useState(props.data.is_liked);
  const [editedTitle, setEditedTitle] = React.useState(heading);
  const modalRef = React.useRef(null);
  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setDisplay(false);
    }
  };

  React.useEffect(() => {
    const match = props.data.message.match(regex);

    if (match && match[1] && match[2]) {
      setHeading(match[1]);
      setExtractedText(match[2]);
      setEditData(match[2]);
      setEditedTitle(match[1]);
    }
  }, [props.data.message]);
  

  // React.useEffect(() => {
  //   if (props.data.comment.length > 0) {
  //     GetReplyData();
  //   }
  // }, []);

  function ChangeDisplay1() {
    setDisplay(!display);
  }

  function handleChange(event) {
    const { value } = event.target;
    setEditData(value);
  }
  const handleTitleChange = (event) => {
    const { value } = event.target;
    setEditedTitle(value);
  };
  const EditMessage = async (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (editData === "") {
        notify("warn", "Enter your reply");
        return;
      }
      if (editedTitle === "") {
        notify("warn", "Enter your Title");
        return;
      }
      const response = await Confessionapi.editConfession(
        props.data.id,
        editData,
        editedTitle
      );
      console.log(response);

      if (response.data.error !== "") {
        notify("error", response.data.error);
      } else {
        notify("success", "Edited successfully");
        props.refresh();
        setTimeout(() => {
          setEdit(!edit);
        }, 1000);
      }
    }
  };

  const DeleteMyConfession = async () => {
    const response = await Confessionapi.deleteConfession(
      props.data.id,
      props.data.comment
    );
    console.log(response);
    if (response.data.error !== "") {
      notify("error", response.data.error);
    } else {
      notify("success", "Confession Deleted Successfully");

      props.refresh();
    }
  };

  const handleLikeButtonClick = async () => {
    // Call the API to like/unlike the confession
    let response;
    if (isLiked) {
      response = await Confessionapi.unlikeConfession(props.data.id);
      setLikesCount(likesCount - 1);
      setIsLiked(false);
    } else {
      response = await Confessionapi.likeConfession(props.data.id);
      setLikesCount(likesCount + 1);
      setIsLiked(true);
    }

    // Check for error in the response
    if (response.data.error !== "") {
      notify("error", response.data.error);
    } else {
      props.refresh();
    }
  };

  function handleChange2(event) {
    // console.log(event);
    const { value } = event.target;
    setReply(value);
    console.log(reply);
  }
  async function SubmitMyReply(e) {
    if (props.free === "yes" && e.key === "Enter") {
      const response = await Confessionapi.commentConfession(
        props.data.id,
        reply,
        1
      );
      if (response.data.error !== "") {
        notify("error", response.data.error);
      } else {
        e.target.blur();
        GetReplyData();
        props.refresh();
        setReply("");
        notify("success", "replied successfully");
      }
    }
  }

  const GetReplyData = async () => {
    const response = await Confessionapi.showComment(props.data.id);
    console.log(response.data);
    if (response.data.error !== "") {
      notify("error", response.data.error);
    } else {
      setReplyConfession(response.data.data.comments);
    }
  };

  

  return (
    <React.Fragment>
      <div className="my-feedback-profile-feedback-card-main">
        <div className="my-feedback-profile-feedback-card-row1">
          <div className="my-feedback-profile-feedback-card-image">
            {props.data.image ? (
              <img
                src={props.data.image}
                style={{ borderRadius: "10px" }}
                alt="image"
              ></img>
            ) : (
              <img src={noimage} style={{ filter: "unset" }} alt="image"></img>
            )}
          </div>
          <div className="my-feedback-profile-feedback-card-col2">
            <div>
              <div style={{ lineHeight: "1.4" }}>
                Title:{" "}
                {edit ? (
                  <>
                    <input
                      type="text"
                      value={editedTitle}
                      name="edittitle"
                      onChange={handleTitleChange}
                      placeholder="Enter Your Title"
                      onKeyDown={EditMessage}
                      inputMode="search"
                      required
                      maxLength={50}
                    ></input>
                  </>
                ) : (
                  <>{heading}</>
                )}
              </div>
              <div className="messageDiv">
                Message :{" "}
                {edit ? (
                  <>
                    <textarea
                      type="text"
                      value={editData}
                      name="editmessage"
                      onChange={handleChange}
                      placeholder="Enter Your Message"
                      onKeyDown={EditMessage}
                      inputMode="search"
                      rows={5}
                      required
                      style={{ width: "500px" }}
                    ></textarea>
                  </>
                ) : (
                  <div className="extractedTextDiv">{extractedText}</div>
                )}
              </div>
            </div>

            <section>{props.data.datetime}</section>
            <article>
              {props.data.comment.length > 0 ? (
                showreplyData ? (
                  <>
                    <span
                      onClick={() => {
                        setshowReplyData(false);
                      }}
                    >
                      Hide Replies
                    </span>
                    <div className="likes_container">
                      {isLiked ? (
                        <AiFillLike onClick={handleLikeButtonClick} size={20} />
                      ) : (
                        <AiOutlineLike
                          size={20}
                          onClick={handleLikeButtonClick}
                        />
                      )}
                      {/* <img src={likes} width="15px" height="15px" onClick={handleLikeButtonClick}/> */}
                      <span>
                        Likes:{" "}
                        {props.data.is_liked ? props.data.like : likesCount}
                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    <span
                      onClick={() => {
                        setshowReplyData(true);
                        GetReplyData();
                      }}
                    >
                      View Replies
                    </span>
                    <div className="likes_container">
                      {isLiked ? (
                        <AiFillLike onClick={handleLikeButtonClick} size={20} />
                      ) : (
                        <AiOutlineLike
                          size={20}
                          onClick={handleLikeButtonClick}
                        />
                      )}
                      {/* <img src={likes} width="15px" height="15px" onClick={handleLikeButtonClick}/> */}
                      <span>
                        Likes:{" "}
                        {props.data.is_liked ? props.data.like : likesCount}
                      </span>
                    </div>
                  </>
                )
              ) : (
                <div className="likes_container">
                  No Replies To Show
                  {isLiked ? (
                    <AiFillLike size={20} onClick={handleLikeButtonClick} />
                  ) : (
                    <AiOutlineLike size={20} onClick={handleLikeButtonClick} />
                  )}
                  {/* <img src={likes} width="15px" height="15px" onClick={handleLikeButtonClick}/> */}
                  <span>
                    Likes: {props.data.is_liked ? props.data.like : likesCount}
                  </span>
                </div>
              )}
            </article>
            <>
              {!showreplyData && (
                <input
                  type="text"
                  value={reply}
                  name="reply"
                  inputMode="search"
                  onChange={handleChange2}
                  placeholder="Enter Your Message"
                  onKeyDown={SubmitMyReply}
                  required
                ></input>
              )}
            </>
          </div>

          <div
            className="my-feedback-profile-feedback-card-col3"
            onClick={ChangeDisplay1}
            ref={modalRef}
          >
            <div onClick={ChangeDisplay1}></div>
            <div onClick={ChangeDisplay1}></div>
            <div onClick={ChangeDisplay1}></div>

            {display && (
              <section
                class="my-feedback-profile-feedback-card-col3-thredots"
                onClick={ChangeDisplay1}
              >
                <span onClick={DeleteMyConfession}>Delete</span>
                {props.free === "yes" && (
                  <span
                    onClick={() => {
                      setEdit(!edit);
                    }}
                  >
                    Edit
                  </span>
                )}
              </section>
            )}
          </div>
        </div>
        {showreplyData && (
          <div className="my-feedback-profile-feedback-card-row2">
            <div className="my-feedback-profile-feedback-card-row2-replies">
              {replyConfession.length >= 0 &&
                replyConfession.map((item) => {
                  return (
                    <ConfessionRepliesCard
                      data={item}
                      refresh={GetReplyData}
                      showconfesionagain={props.refresh}
                    ></ConfessionRepliesCard>
                  );
                })}
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

function ConfessionRepliesCard(props) {
  const [editData, setEditData] = React.useState(props.data.message);
  const [edit, setEdit] = React.useState(false);
  const [display, setDisplay] = React.useState(false);
  console.log(props);

  function ChangeDisplay1() {
    setDisplay(!display);
  }

  const EditMessage = async (e) => {
    if (e.key === "Enter") {
      if (editData === "") {
        notify("warn", "Enter Your reply");
        return;
      }
      const response = await Confessionapi.editComment(props.data.id, editData);
      console.log(response);
      if (response.data.error !== "") {
        notify("error", response.data.error);
      } else {
        notify("success", "Edited Successfully");
        props.refresh();
        setTimeout(() => {
          setEdit(!edit);
        }, 1000);
      }
    }
  };

  function handleChange(event) {
    const { value } = event.target;
    setEditData(value);
  }

  const DeleteMyReply = async () => {
    const response = await Confessionapi.deleteComment(props.data.id);
    console.log(response);
    if (response.data.error !== "") {
      notify("error", response.data.error);
    } else {
      notify("success", "Reply Deleted Successfully");
      props.refresh();

      props.showconfesionagain();
    }
  };

  function convertTo12HourFormat(timeString) {
    const date = new Date(timeString);
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const hours12 = hours % 12 || 12;
    const formattedTime = `${hours12}:${
      minutes < 10 ? "0" : ""
    }${minutes} ${ampm}`;
    return formattedTime;
  }

  return (
    <React.Fragment>
      <div className="my-feedback-profile-feedback-card-replies-cont">
        <div className="my-feedback-profile-feedback-card-replies-col">
          <img
            src={props.data.profile_image}
            style={{ borderRadius: "40px" }}
            width="40px"
            height="40px"
          />
        </div>
        <div className="my-feedback-profile-feedback-card-replies-col2">
          <div>
            <div>
              {props.data.is_user_comment === true ? (
                <span>You</span>
              ) : (
                <span>{props.data.username}</span>
              )}
            </div>
            <section>
              {props.data.is_user_comment === true && edit ? (
                <>
                  <input
                    type="text"
                    value={editData}
                    name="name"
                    onChange={handleChange}
                    inputMode="search"
                    placeholder="Enter Your Message"
                    onKeyDown={EditMessage}
                    required
                  ></input>
                </>
              ) : (
                <>{props.data.message}</>
              )}
            </section>
          </div>
          <div>
            <article>{convertTo12HourFormat(props.data.datetime)}</article>
          </div>
        </div>
        <div className="my-feedback-profile-feedback-card-replies-col3">
          <div onClick={ChangeDisplay1}></div>
          <div onClick={ChangeDisplay1}></div>
          <div onClick={ChangeDisplay1}></div>
          {display && (
            <section
              class="my-feedback-profile-feedback-card-col3-thredots"
              onClick={ChangeDisplay1}
            >
              <span onClick={DeleteMyReply}>Delete</span>
              {
                <span
                  onClick={() => {
                    setEdit(!edit);
                  }}
                >
                  Edit
                </span>
              }
            </section>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
