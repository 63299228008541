import React, { useEffect, useRef, useState } from 'react'
import "../admincss/admincards.css"
import Adminloginapi from"../adminapi/adminloginapi"
import notify from '../../error';
const Cards = ({data,setBillData,setPage3,tabSelected,allorderfun,loading}) => {
  const [openbox,setOpenbox]=useState(false);
  const [statusorder,setStatusorder]=useState(null);
  const elementref=useRef("");

  useEffect(()=>{
    setOpenbox((openbox)=>{
      if(openbox){
        return false;
      }
    })
  },[tabSelected])
  
  useEffect(()=>{
    if(statusorder){
      if(statusorder==="Cancel the Order"){
        statuschangecall({
          booking_id:data.items[0].booking_id,
          order_cancelled:1
        })
      }
      else if (statusorder==="Ship the order"){
        statuschangecall({
          booking_id:data.items[0].booking_id,
          order_shipped:1
        })
      }
      else if (statusorder==="Delivery the order"){
        statuschangecall({
          booking_id:data.items[0].booking_id,
          order_delivered:1
        })
      }
    }
  },[statusorder])

  useEffect(()=>{
    const manageoutsideclick = (event) =>{
     if(elementref && elementref.current && event && event.target && !elementref.current.contains(event.target)){
      setOpenbox(false);
     }
    }
    document.addEventListener("click",manageoutsideclick)
  },[])
  const statuschangecall=async (payload)=>{
    const response= await Adminloginapi.change_order_status_by_manager(payload);
    console.log(response.data)
    if(response.data.status && response.data.error!==""){
      notify("error","fail to change the status")
    }
    else { 
      notify("success","Status has change")
      loading(true);
     allorderfun();
    }
    setStatusorder(null)
  }
  // const orderfun = async ()=>{
  //   const response = await Adminloginapi.Managerorders();
  //   if(response.data.status && response.data.error!==""){
  //    allorderfun(response.data);
  //   }
  //   else { 
  //     // notify("success","Status has change")
  //   }
  // }
  return (
   <>
   <div className='cardbody'>
   { 
   <>
   {
   (tabSelected === "Pending Orders" || tabSelected === "Received at Areamanager" || tabSelected === "Shipped from Areamanager" || tabSelected === "Partial action of order") &&
   <div class="my-order-profile-dropdown">
    <div class="ellipsis" onClick={()=>{
    setOpenbox(!openbox);
   }} ref={elementref}>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
    </div>
  </div>
}
  <>
    {
      (tabSelected === "Pending Orders" || tabSelected === "Received at Areamanager" || tabSelected === "Shipped from Areamanager" || tabSelected === "Partial action of order") &&
   openbox && <div className='login-details-header-dropdownadmin'>
      {
        (tabSelected === "Pending Orders" || tabSelected === "Partial action of order") ? <span datavalue="Cancel the Order" onClick={(event1)=>{
          setStatusorder(event1?.target?.getAttribute("datavalue"))
   }}>Cancel Order</span> : <>{
          (tabSelected === "Received at Areamanager") ? <span datavalue="Ship the order" onClick={(event1)=>{
            setStatusorder(event1?.target?.getAttribute("datavalue"))
     }}>Ship Order</span> : <>
{
          (tabSelected === "Shipped from Areamanager") ? <span datavalue="Delivery the order" onClick={(event1)=>{
            setStatusorder(event1?.target?.getAttribute("datavalue"))
     }}>Order Delivery</span>:<></>
}
          </>
        }
        </>
      }
    </div>
}
    </>
  </>
}
   <h5 class="card-title">{data.order_id}</h5>
   <p class="card-text">Amount: 
   {
    data.items.reduce((accumulator,value)=>{
        return accumulator + value.price;
    },0)
   }
   </p>
   <h6 class="card-subtitle mb-2 text-muted">Username:{data.name}</h6>
   <h6 class="card-subtitle mb-2 text-muted">Mobile: {data.phone}</h6>
   <p class="card-text">Order Date: {data.datetime}</p>

   <div className='adminbuttonsection'>
    <div className='buttonsectionelement'  onClick={() => {
                                          setPage3(1);
                                          setBillData(data);
                                        }}>View item</div>
    <div className='buttonsectionelement'>View Address</div>
   </div>
   </div>
   </>
  )
}

export default Cards