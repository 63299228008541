import axios from 'axios';
//company
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

class PutatoeMobileApi {
  ShowRecharge() {
    return axios.get(API_BASE_URL + '/v1/api/showrecharges', {
      headers: {
        authtoken: localStorage.getItem('authtoken'),
      },
    });
  }

  // GetAllCircle() {
  //   return axios.get(API_BASE_URL + '/v1/api/getCircles', {
  //     headers: {
  //       authtoken: localStorage.getItem('authtoken'),
  //     },
  //   });
  // }

  GetAllOperator() {
    return axios.post(
      API_BASE_URL + '/v1/api/getOperator',
      { type: 'DTH' },
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }

  FetchCircle(mobile) {
    return axios.post(
      API_BASE_URL + '/v1/api/getcircle',
      { mobile },
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }

  GetPLan(type, op_code, circle_code, page_no) {
    return axios.post(
      API_BASE_URL + '/v1/api/getplans',
      { type, op_code, circle_code, page_no },
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }
  DisputeRaise(order_id) {
    return axios.post(
      API_BASE_URL + '/v1/api/raisedispute',
      { order_id },
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }

  RazorRecharge(operator_code, mobile, amount, circle, coupon_id) {
    return axios.post(
      API_BASE_URL + '/v1/api/RazorRecharge',
      {
        operator_code,
        mobile,
        amount,
        circle,
        coupon_id,
      },
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }

  WalletRecharge(operator_code, mobile, amount, circle, coupon_id) {
    return axios.post(
      API_BASE_URL + '/v1/api/recharge',
      {
        operator_code,
        mobile,
        amount,
        circle,
        coupon_id,
        payment_method: 'wallet',
      },
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }

  VerifyRechargeRazorPay(
    operator_code,
    recharge_number,
    amount,
    circle,
    coupon_id,
    putatoe_order_id,
    razorpay_signature,
    razorpay_order_id,
    razorpay_payment_id
  ) {
    return axios.post(
      API_BASE_URL + '/v1/api/VerifyRecharge',
      {
        operator_code,
        recharge_number,
        amount,
        circle,
        coupon_id,
        putatoe_order_id,
        razorpay_signature,
        razorpay_order_id,
        razorpay_payment_id,
      },
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }
}

export default new PutatoeMobileApi();
