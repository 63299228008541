import "./css/Jobs.css";
import Jobapi from "../api/Jobapi";
import notify from "../error";
import cnacleUpload from "../images/blogimages/cnacleUpload.svg";
import { useGlobalContext } from "../context";

export default function JobModal({ cardInfo }) {
  const { setModifyModal,Userprofile } = useGlobalContext();
  const ApplytoJob = async (JobId) => {
    if(!Userprofile){
      notify("error","Login to Apply for Job");
      return;
    }
    const response = await Jobapi.ApplyJobPosition(JobId);
    console.log("Apply Job Response ", response);
    if (response.data.error !== "") {
      notify("error", response.data.error);
    } else {
      notify("success", "Appied Job Successfully...");
    }
  };

  const HandleCrossClick = () => {
    setModifyModal((prevState) => !prevState);
  };

  return (
    <section className="jobs-main-overlay">
      <div className="job-modal-container">
        <img
          src={cnacleUpload}
          className="crossIcon"
          alt="cross_icon"
          onClick={HandleCrossClick}
        />
        <div className="job-card-top-row-container">
          <div className="job-card-top-row-image">
            <img src={cardInfo.serviceprovider_image} alt="jobImage" />
          </div>
          <div className="job-card-top-row-details">
            <div className="job-card-top-row-details-sp-name">
              {cardInfo.serviceprovider_name}
            </div>
            <div className="job-card-top-row-details-job-role">
              {cardInfo.job_position}
            </div>
          </div>
        </div>
        <main className="job-modal-second-row">
          <div className="job-card-skills">
            <h5 className='modal-heading'>Skills Required : </h5>
            <span>{cardInfo.skills_required}</span>
          </div>
          <div className="job-card-salary">
                <h5 className='modal-heading'>Salary :</h5>
              <span>&#8377; {cardInfo.salary}</span>
          </div>
          <div className="job-modal-description">
            <h5 className='modal-heading'>Description : </h5>
            <span>{cardInfo.description}</span>
          </div>
          <div className="job-card-apply-button-container">
            <button
              className="job-card-apply-button"
              onClick={() => ApplytoJob(cardInfo.job_id)}
            >
              Apply
            </button>
          </div>
        </main>
      </div>
    </section>
  );
}
