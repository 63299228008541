import React, { Component } from "react";
import MyBillsApi from "../api/MyBillsApi";
import { useGlobalContext } from "../context";
import radar from "../images/radar-observatory-svgrepo-com.svg";
import cover from "../images/DthCoverPage.svg";
import notify from "../error";
import "./css/DthRechargePage.css";
import { Navigate, redirect, useNavigate } from "react-router-dom";
import PrePaidImage from "../images/PrePaid-PostPaid/PrePaid-PostPaid.jpg";
import mobileApi from "../api/mobileApi";
import emptyoption from "../images/recharge1.svg";
import arrow from "../images/arrowRech.svg";
import arrow2 from "../images/arrowRech2.svg";
import MobileHistory from "../Mobile-reacharge/mobileRechargeHistory";

export default function PrePaidMobile(props) {
  const [operatorList, setOperatorList] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [allPlans, setAllPlans] = React.useState();
  const [allPlanType, setAllPlanType] = React.useState();
  const [selectedType, setSelectedType] = React.useState("Talktime");
  const [viewDetail, setViewDetail] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [errors, setErrors] = React.useState({
    mobile: '',
    op_code: '',
    amount: '',
  });
  const [history, setHistory] = React.useState(false);
  function AllowMeTOViewHistory() {
    setHistory(!history);
    console.log(history);
  }
  const PrePaidMobileList = async () => {
    const response = await MyBillsApi.OtherOperatorList("Postpaid-Mobile");
    setOperatorList(response.data.operatorList);
  };
  const scrollref = React.useRef(null);
  function ScrollRight() {
    console.log("scrol");
    scrollref.current.scrollLeft += 80;
  }
  function ScrollLeft() {
    scrollref.current.scrollLeft -= 80;
  }
  React.useEffect(() => {
    PrePaidMobileList();
  }, []);

  const [formData, setFormData] = React.useState({
    operator_code: "",
    DTH: "",
    amount: "",
    coupon_id: null,
    payment_method: "",
    planname: "",
  });

  function ChangeValue(e) {
    const { name, value, type, checked } = e.target;
    // console.log(value,name)
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: type === "checkbox" ? checked : value,
      };
    });
    console.log(formData);
  }
  const validateMobileNumber = (mobileNumber) => {
    const regex = /^[6-9]\d{9}$/;
    return regex.test(mobileNumber);
  };
  const navigate = useNavigate();
  const BillMyDTH = async () => {
    const { operator_code, DTH, amount } = formData;
    if (DTH === "") {
      notify("error", "Please enter a valid mobile number.");
      return;
    }

    if (!validateMobileNumber(DTH)) {
      notify("error", "Please enter a valid 10-digit mobile number.");
      return;
    }

    if (operator_code === "") {
      notify("error", "Please select an operator.");
      return;
    }

    if (amount === "" || parseInt(amount) <= 0) {
      notify("error", "Please enter a valid recharge amount.");
      return;
    }
    navigate("/cart/recharge", {
      replace: true,
      state: {
        op_code: operatorList[formData.operator_code].op_code,
        serial_no: formData.DTH,
        amount: formData.amount,
        circle: 2,
        op_image: operatorList[formData.operator_code].operator_image,
        text1: `Mobile Number ${formData.DTH}`,
        text2: `Recharge ${
          operatorList[formData.operator_code].operator_name
        } with `,
      },
    });
  };

  //payments

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  const fetchPlans = async (operator, circle) => {
    setLoading(true);
    const response2 = await mobileApi.GetPLan("", operator, circle, 1);
    console.log(response2);
    setSelectedType(response2.data.details.data[0].recharge_type);
    if (response2.data.error !== "") {
      notify("error", response2.data.error);
      setLoading(false);
    }
    setAllPlans(response2.data.details.data);

    var unique = [];
    var distinct = [];
    for (let i = 0; i < response2.data.details.data.length; i++) {
      if (!unique[response2.data.details.data[i].recharge_type]) {
        distinct.push(response2.data.details.data[i].recharge_type);
        unique[response2.data.details.data[i].recharge_type] = 1;
      }
    }
    setLoading(false);

    setAllPlanType(distinct);
    console.log("plan type", distinct);
  };

  const fetchCircle = async (value) => {
    const response = await mobileApi.FetchCircle(value);
    setLoading(true);
    console.log(response);
    if (response.data["details"]["message"] === "Invalid number") {
      notify("error", "Invalid Number");
      setLoading(false);
      return;
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      circle_code: response.data.details.data.circle_code,
      op_code:
        response.data.details.data.op_code === "BSNL Recharge/Validity (RCV)"
          ? "BSNL 3G"
          : response.data.details.data.op_code,
      circle: response.data.details.data.circle,
      operator: response.data.details.data.operator,
    }));
    console.log(formData);
    setLoading(true);
    setTimeout(() => {
      console.log(formData, "here", response.data);
      fetchPlans(
        response.data.details.data.operator,
        response.data.details.data.circle
      );
    }, 0);
  };

  function validateloanmobilenumber(value) {
    const regexExp = /^[6-9]\d{9}$/gi;
    if (
      value.length > 10 ||
      value.length < 10 ||
      regexExp.test(value === false)
    ) {
      notify("error", "Invalid mobile number");
    }
    console.log(regexExp.test(value));
    return regexExp.test(value);
  }

  function handleEnter(event) {
    if (event.key === "Enter") {
      var value = event.target.value;
      console.log(value);
      if (value.length === 10) {
        if (!validateloanmobilenumber(value)) {
          fetchCircle(value);
        }
      }
    }
  }

  function handleKeyPress(event) {
    const currentLength = event.target.value.length;
  
    if (currentLength >= 10 || (event.key && !/^[0-9]*$/.test(event.key))) {
      event.preventDefault();
    }
  }

  function handleChange(event) {
    const { name, value, type, checked } = event.target;
    if (errors[name] && value) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
    }

    if (name === 'mobile' && !validateMobileNumber(value)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        mobile: 'Please enter a valid 10-digit mobile number.',
      }));
    }

    if (name === 'amount' && !value) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        amount: 'Please enter a valid recharge amount.',
      }));
    }



    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: type === "checkbox" ? checked : value,
      };
    });
    if (name === "mobile") {
      console.log(value);
      if (value.length === 10) {
        if (!validateloanmobilenumber(value)) {
          if (isNaN(value) !== true) {
            setAllPlans(null);
            setLoading(true);
            fetchCircle(value);
          }
        }
      }
    } else if (name === "op_code") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        operator: value,
      }));
      console.log("op",event.target.value);
      fetchPlans(event.target.value, formData.circle);
    }
    //   else if (name === "circle_code" ) {
    //     // Check if the operator or region has changed

    //     setFormData((prevFormData) => ({
    //       ...prevFormData,
    //       circle: value,
    //     }));
    //              console.log(formData);
    //       fetchPlans(formData.operator, formData.circle);

    //     }
  }

  const moveToCart = async () => {
    const { op_code, mobile, amount } = formData;
    if (!mobile) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        mobile: 'Please enter mobile number.',
      }));
      return;
    }

    if (!validateMobileNumber(mobile)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        mobile: 'Please enter a valid 10-digit mobile number.',
      }));
      return;
    }

    if (!op_code) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        op_code: 'Please select an operator.',
      }));
      return;
    }

    if (!amount || parseInt(amount) <= 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        amount: 'Please enter a valid recharge amount.',
      }));
      return;
    }
    
    else {
      let name = "";
      if (formData.op_code === "JIO") {
        name = "Reliance Jio";
      } else if (formData.op_code === "AI") {
        name = "Airtel";
      } else if (formData.op_code === "VF") {
        name = "Vi";
      } else if (formData.op_code === "BSNL") {
        name = "BSNL";
      } else if (formData.op_code === "BSS") {
        name = "BSNL(STV)";
      } else if (formData.op_code === "BSV") {
        name = "BSNL 3G";
      }
      if (formData.mobile.length === 10) {
        if (!validateloanmobilenumber(formData.mobile)) {
          if (isNaN(formData.mobile) !== true) {
            navigate("/cart/recharge", {
              replace: true,
              state: {
                op_code: formData.op_code,
                serial_no: formData.mobile,
                amount: formData.amount,
                circle: 2,
                op_image: "",
                text1: `Mobile Number ${formData.mobile}`,
                text2: `Recharge ${name} `,
                planname: formData.planname,
                pageno:props.pageNo,
                redirect:"/mobile-recharge"
              },
            });
          }
        }
      }
    }
  };

  function ChangePlanType(i) {
    setSelectedType(allPlanType[i]);
  }
  function selectplan(val, planname) {
    setFormData((prevFormData) => ({
      ...prevFormData,
      amount: val,
      planname: planname,
    }));
    // notify('success', 'selected plan');
  }
  function AllowMeTOViewDetail(detail) {
    setViewDetail(detail);
    console.log(detail);
  }

  function PlanCard(props) {
    const [isModalOpen, setIsModalOpen] = React.useState(false);

    const openModal = () => {
      setIsModalOpen(true);
      props.modal();
    };

    const closeModal = () => {
      setIsModalOpen(false);
    };

    // console.log(props.data)
    return (
      <React.Fragment>
        <>
          <div className="mobile-recharge-inner-circle-2-plan-container-plan">
            <div className="mobile-recharge-inner-circle-2-plan-container-plan-col1">
              <div className="mobile-recharge-inner-circle-2-plan-container-plan-col1-row1">
                &#8377; {props.data.recharge_amount}
              </div>
              {props.data.recharge_validity != "nan" && (
                <div className="mobile-recharge-inner-circle-2-plan-container-plan-col1-row3">
                  <>
                    {props.data.recharge_validity}
                    <span>Validity</span>
                  </>
                </div>
              )}
              <div className="mobile-recharge-inner-circle-2-plan-container-plan-col1-row3">
                {props.data.recharge_talktime != "-" && (
                  <>
                    {props.data.recharge_talktime}
                    <span>Talktime</span>
                  </>
                )}
              </div>
              <div className="mobile-recharge-inner-circle-2-plan-container-plan-col1-row3">
                {props.data.internet_data && (
                  <>
                    {props.data.internet_data}
                    <span>Data</span>
                  </>
                )}
              </div>
            </div>
            <div className="mobile-recharge-inner-circle-2-plan-container-plan-col2">
              <div className="mobile-recharge-inner-circle-2-plan-container-plan-col2-row1">
                <div onClick={props.selectMe}>Buy</div>
              </div>
              <div
                className="mobile-recharge-inner-circle-2-plan-container-plan-col2-row2"
                onClick={openModal}
              >
                View Details
              </div>
            </div>
          </div>
          {isModalOpen && (
            <div className="plan-details-modal">
              <div className="plan-details-modal-content ">
                <div className="plan-details-modal-content-button">
                  <div className="plan-details-modal-heading">Plan Details</div>
                  <button
                    className="plan-details-modal-close-button"
                    onClick={closeModal}
                  >
                    X
                  </button>
                </div>

                <div className="plan-details-modal-description-amount">
                  &#8377;{viewDetail.recharge_amount}
                </div>
                <div className="plan-details-modal-description-row-data-info">
                  {viewDetail.recharge_validity != "nan" && (
                    <div className="plan-details-modal-description-validity">
                      <>
                        {" "}
                        <span>Validity</span> {viewDetail.recharge_validity}
                      </>
                    </div>
                  )}
                  <div className="plan-details-modal-description-validity">
                    {" "}
                    {viewDetail.recharge_talktime != "-" && (
                      <>
                        {viewDetail.recharge_talktime}
                        <span>Talktime</span>
                      </>
                    )}
                  </div>
                  <div className="plan-details-modal-description-data">
                    {viewDetail.internet_data && (
                      <>
                        <span>Data</span> {viewDetail.internet_data}
                      </>
                    )}
                  </div>
                </div>
                <div className="plan-details-modal-description-longdesc">
                  {viewDetail.recharge_long_desc}
                </div>

                <div className="buttons-container">
                  <button onClick={props.selectMe}>Buy Now</button>
                </div>
              </div>
            </div>
          )}
        </>
      </React.Fragment>
    );
  }
  // filter plans
  const shouldFilterPlans = searchQuery !== "" && allPlans.length > 0;

  const filteredPlans = shouldFilterPlans
    ? allPlans.filter((item) =>
        item.recharge_amount.toString().includes(searchQuery)
      )
    : allPlans;

  return (
    <React.Fragment>
      {history && (
        <MobileHistory
          close={() => {
            AllowMeTOViewHistory();
          }}
        ></MobileHistory>
      )}
      <div className="my-dth-recharge-main-page-container">
        <div className="my-dth-recharge-main-page-container-heading">
          <div>
            {/* <img src={radar}></img> */}
            <i class="fa-solid fa-sim-card fa-xl"></i>
          </div>
          <div>
            Let's Get Your<br></br>
            <span>PrePaid Bill Payment Done</span>
            <hr></hr>
          </div>
        </div>
        <div className="rechargeContainer">
          <form className="recharge-form" onSubmit={(e) => e.preventDefault()}>
            <div className="mobile-recharge-inner-circle-1-inputs">
              <input
               className={` ${errors.mobile  ? 'error' : ''}`}
                type="number"
                name="mobile"
                placeholder="Mobile Number"
                value={formData.mobile}
                onChange={handleChange}
                onKeyDown={handleEnter}
                onKeyPress={handleKeyPress}
                maxLength="10"
              />
               {errors.mobile && <p className="error-message error-center-mobilepage">{errors.mobile}</p>}
              <select
                  className={` ${errors.op_code  ? 'error' : ''}`}
                value={formData.op_code}
                onChange={handleChange}
                onKeyDown={handleEnter}
                name="op_code"
              >
                <option value="">Select operator</option>
                <option value="VFP">IdeaVodafone</option>
                <option value="JIO">Reliance Jio</option>
                <option value="AT">Airtel</option>
                <option value="VF">Vi</option>
                <option value="BSNL">BSNL</option>
                <option value="BSS">BSNL(STV)</option>
                <option value="BSV">BSNL 3G</option>
              </select>
              {errors.op_code && <p className="error-message error-center-mobilepage">{errors.op_code}</p>}

              <select
                value={formData.circle_code}
                onChange={handleChange}
                name="circle_code"
                onKeyDown={handleEnter}
              >
                <option value="">Select circle</option>
                <option value="36">Andaman and Nicobar Islands</option>
                <option value="1">Andhra Pradesh</option>
                <option value="26">Arunachal Pradesh</option>
                <option value="2">Assam</option>
                <option value="3">Bihar</option>
                <option value="4">Chennai</option>
                <option value="27">Chhattisgarh</option>
                <option value="41">Dadra and Nagar</option>
                <option value="40">Daman and Diu</option>
                <option value="5">Delhi</option>
                <option value="5">Delhi NCR</option>
                <option value="28">Goa</option>
                <option value="6">Gujrat</option>
                <option value="7">Haryana</option>
                <option value="8">Himachal Pradesh</option>
                <option value="9">Jammu & Kashmir</option>
                <option value="24">Jharkhand</option>
                <option value="10">Karnataka</option>
                <option value="11">Kerala</option>
                <option value="12">Kolkata</option>
                <option value="39">Lakshadweep</option>
                <option value="14">Madhya Pradesh</option>
                <option value="13">Maharashtra</option>
                <option value="29">Manipur</option>
                <option value="30">Meghalaya</option>
                <option value="31">Mizoram</option>
                <option value="15">Mumbai</option>
                <option value="32">Nagaland</option>
                <option value="16">North East</option>
                <option value="17">Odisha</option>
                <option value="38">Puducherry</option>
                <option value="18">Punjab</option>
                <option value="19">Rajasthan</option>
                <option value="33">Sikkim</option>
                <option value="20">Tamil Nadu</option>
                <option value="37">Telangana</option>
                <option value="25">Tripura</option>
                <option value="34">Uttar Pradesh</option>
                <option value="21">UP East</option>
                <option value="22">UP West</option>
                <option value="35">Uttarkhand</option>
                <option value="23">West Bengal</option>
              </select>

              <input
                  className={` ${errors.amount  ? 'error' : ''}`}
                type="number"
                name="amount"
                placeholder="Enter Recharge Amount"
                value={formData.amount}
                onChange={handleChange}
                onKeyPress={handleKeyPress}
                onKeyDown={handleEnter}
              ></input>
                    {errors.amount && <p className="error-message error-center-mobilepage">{errors.amount}</p>}
            </div>
            <div className="mobile-recharge-inner-circle-buttons mobile-recharge-buttons-desktop-view">
              <button onClick={moveToCart}>Confirm Recharge</button>
              <span onClick={AllowMeTOViewHistory}>VIEW RECHARGE HISTORY</span>
            </div>
          </form>
          <div className="mobile-recharge-inner-circle-2">
            {allPlans ? (
              <>
                <div className="mobile-recharge-inner-circle-2-search">
                  <div>Select Plan</div>
                  <input
                    type="text"
                    placeholder="Search plans"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </div>
                {searchQuery ? (
                  // Show filtered plans when there's a search query
                  <>
                    <div className="mobile-recharge-inner-circle-2-top">
                      <div className="mobile-recharge-inner-circle-2-top-scroll">
                        <div
                          className="mobile-recharge-inner-circle-2-scroll"
                          ref={scrollref}
                        >
                          {allPlanType && !searchQuery&&
                            allPlanType.map((item, index) => {
                              if (item === selectedType) {
                                return <div>{item}</div>;
                              }
                              return (
                                <span
                                  onClick={() => {
                                    ChangePlanType(index);
                                  }}
                                >
                                  {item}
                                </span>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                    <div className="mobile-recharge-inner-circle-2-plan-container">
                      {filteredPlans.length === 0 ? (
                        <div className="mobile-recharge-inner-circle-2-plan-no-results-found">
                          No results found{" "}
                          <span>
                            Please try searching with a different search term.
                          </span>
                        </div>
                      ) : (
                        filteredPlans.map((item) => (
                          <PlanCard
                            key={item.id}
                            data={item}
                            selectMe={() =>
                              selectplan(
                                item.recharge_amount,
                                item.recharge_type
                              )
                            }
                            modal={() => AllowMeTOViewDetail(item)}
                          ></PlanCard>
                        ))
                      )}
                    </div>
                  </>
                ) : (
                  <div className="mobile-recharge-inner-circle-2-full">
                    <div className="mobile-recharge-inner-circle-2-top">
                      <div className="mobile-recharge-inner-circle-2-top-scroll">
                        <div
                          className="mobile-recharge-inner-circle-2-scroll"
                          ref={scrollref}
                        >
                          {allPlanType &&
                            allPlanType.map((item, index) => {
                              if (item === selectedType) {
                                return <div>{item}</div>;
                              }
                              return (
                                <span
                                  onClick={() => {
                                    ChangePlanType(index);
                                  }}
                                >
                                  {item}
                                </span>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                    <div className="mobile-recharge-inner-circle-2-plan-container">
                      {allPlans &&
                        allPlans.map((item) => {
                          if (item.recharge_type === selectedType)
                            return (
                              <PlanCard
                                data={item}
                                selectMe={() =>
                                  selectplan(
                                    item.recharge_amount,
                                    item.recharge_type
                                  )
                                }
                                modal={() => AllowMeTOViewDetail(item)}
                              ></PlanCard>
                            );
                        })}
                    </div>
                  </div>
                )}
              </>
            ) : loading ? (
              <div className="loadingImg">
                <img src="https://i.pinimg.com/originals/07/24/88/0724884440e8ddd0896ff557b75a222a.gif" />
              </div>
            ) : (
              <div className="mobile-recharge-inner-circle-2-empty">
                <img src={emptyoption}></img>
              </div>
            )}
          </div>

          {/* <div className="mobile-recharge-inner-circle-buttons mobile-recharge-buttons-mobile-view">
            <button onClick={moveToCart}>Confirm Recharge</button>
            <span onClick={AllowMeTOViewHistory}>VIEW RECHARGE HISTORY</span>
          </div> */}
        </div>

        {/* <div className='my-dth-recharge-main-page-container-allProvider-content-line-2'>
          <div>What is a PrePaid ?</div>
          <p>
            Prepaid refers to a billing system used by mobile network operators or telecommunications companies to provide services to their customers.
            In a prepaid plan, customers purchase a certain amount of credit or talk time in advance and use it to access services such as voice calls, 
            text messaging, and mobile data.
          </p>
          <p>
            Prepaid plans are often available in the form of prepaid SIM cards or rechargeable vouchers. 
            Customers can purchase these from authorized sellers, retail stores, or online platforms. 
            The prepaid credit is usually loaded onto the SIM card or account, allowing users to access services immediately.
          </p>
        </div>

        <div className='my-dth-recharge-main-page-container-allProvider-content-line-2'>
          <div>What are the advantages of using PrePaid Connection?</div>
          <p>
            One advantage of prepaid plans is that customers have greater control over their expenses.
            They can choose how much credit to load and how to allocate it based on their usage needs.
            Prepaid plans are also convenient for individuals who may not have access to 
            traditional postpaid services due to credit checks or long-term contracts.
          </p>
        </div> */}
      </div>
    </React.Fragment>
  );
}
