import React from 'react';
// import src from '../images/download.jfif';
import scrollright from '../images/scrollright.svg';
import scrollleft from '../images/scrollleft.svg';
import '../css/carousel.css';
import { useGlobalContext } from '../context';

export default function Carousel() {
  const { bannerloading, banner } = useGlobalContext();
  const [index, setIndex] = React.useState(0);
  const [nextIndex, setNextIndex] = React.useState(1); // Initial value

  const [isHovered, setIsHovered] = React.useState(false);
  let lastIndex = banner?.length - 1;

  const move = async () => {
    // Your move logic here
  };

  React.useEffect(() => {
    lastIndex = banner.length - 1;
    if (banner.length === 0) return;

    if (index < 0) {
      setIndex(lastIndex);
    }
    if (index > lastIndex) {
      setIndex(0);
    }
    setNextIndex((index + 1) % banner.length);
  }, [index, banner]);

  const scrollLeft = () => {
    if (banner.length === 0) return;

    if (index - 1 < 0) {
      setIndex(lastIndex);
    } else {
      setIndex(index - 1);
    }
    setNextIndex((index + 1) % banner.length);
  };

  const scrollRight = () => {
    if (banner.length === 0) return;

    if (index + 1 > lastIndex) {
      setIndex(0);
    } else {
      setIndex(index + 1);
    }
    setNextIndex((index + 1) % banner.length);
  };

  React.useEffect(() => {
    if (banner.length === 0) return;

    const autoChangeSlide = () => {
      const nextIndex = (index + 1) % banner.length;
      setIndex(nextIndex);
      setNextIndex((nextIndex + 1) % banner.length);
    };

    const slider = setInterval(autoChangeSlide, 3000);

    return () => {
      clearInterval(slider);
    };
  }, [index, banner.length]);


  return (
    <React.Fragment>
      <div className='carousal-container-desktop'>
        <div className={`carousel-head ${isHovered ? 'hovered' : ''}`}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <div className='carousel-image'>
            {bannerloading ? (
              <div className='carousel-loader'>Loading...</div>
            ) : (
              banner.length > 0 && banner.map((item, i) => (
                <React.Fragment key={i}>
                  <img
                    src={item.image}
                    className={`carousel-image ${i === index ? 'active' : 'inactive'}`}
                    onClick={move}
                    alt={`carouselImage${i}`}
                  />
                </React.Fragment>
              ))
            )}
          </div>
          {window.innerWidth > 600 &&
            <div className='carousel-scroll'>
              <button
                className='carousel-scroll-left'
                onClick={scrollLeft}
              >
                <img src={scrollleft} alt='Scroll Left'></img>
              </button>
              <button
                className='carousel-scroll-right'
                onClick={scrollRight}
              >
                <img src={scrollright} alt='Scroll Right'></img>
              </button>
            </div>
          }
        </div>
      </div>
    </React.Fragment>
  );
}
