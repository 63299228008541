import React, { useEffect, useState } from "react";
import myProfileServiceApi from "../../api/myProfileServiceApi";
import GenerateBillCard from "./GenerateBillCard";
import GeneratePdf from "./GeneratePdf";
import { useGlobalContext } from "../../context";

import back from "../myprofileIcoons/bx-arrow-back..svg";
import "./css/GenerateBill.css";
import notify from "../../error";
import Loading from "../../components/Loading";

export default function GenerateBill(props) {
  const [generateBillArray, setGenerateBillArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const { showPdfModal, setShowPdfModal } = useGlobalContext();
  const { modifyModal } = useGlobalContext();

  function handleModifyDeleteChange(boolValue) {
    boolValue && getGenerateBillProductFunction();
  }
  const getGenerateBillProductFunction = async () => {
    try {
      setLoading(true);
      const res = await myProfileServiceApi.ShowAddedGenerateBillProduct();
      setGenerateBillArray(res.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };
  function goBack() {
    props.return();
  }

  const handlerGeneratePdf = (total) => {
    if (total !== 0) setShowPdfModal(true);
    else notify("warn", "No products added!!!");
  };

  useEffect(() => {
    getGenerateBillProductFunction();
  }, []);

  return (
    <div className="generate-bill-service-main-page">
      <div className="generate-bill-ongoing-and-pending-online-back">
        {generateBillArray && !loading && generateBillArray.items_list && (
          <p>{`Total ${generateBillArray.items_list.length} Product(s)`}</p>
        )}
      </div>
      <div className="manage-services-profile-service-main-page-main-box generate-bill-main-box">
        {generateBillArray && !loading  &&
          generateBillArray.items_list ?
          generateBillArray.items_list.map((item, index) => {
            return (
              <GenerateBillCard
                key={index}
                item={item}
                changeCallFunction={handleModifyDeleteChange}
              />
            );
          })
         :(<>
               <Loading />
         </>) }
      </div>
      <div className="generate-bill-button-container">
      <div className="generate-bill-button">
        total amount: &#8377;{generateBillArray.final_discounted_price}
      </div>
      <button
        className="generate-bill-button pdf-button"
        onClick={() => handlerGeneratePdf(generateBillArray.actual_total)}
      >
        Generate Bill
      </button>
</div>
      {showPdfModal && (
        <GeneratePdf
          amount={generateBillArray.final_discounted_price}
          changeCallFunction={handleModifyDeleteChange}

        />
      )}
    </div>
  );
}
