import React, { Component } from 'react'
import '../css/CartMOdalHeader.css'
import cartapi from '../api/cartapi';
import { useGlobalContext } from '../context';
import { useNavigate } from 'react-router-dom';
export default function CartMOdalHeader(props)
{
   const { changeInCart, setChangeInCart } = useGlobalContext();
  console.log(props)
//  const [cartDetail,setCartDetail]=React.useState()

 const [changes, setChanges] = React.useState(false);
 function ChangesChange()
 {
  setChanges(!changes);
 }

 const navigate=useNavigate()


 return (
   <React.Fragment>
     {props.data ?
     <div className='navbar-header-cart-modal-container'>
       <div className='navbar-header-cart-modal-container-item-list'>
         {props.data &&
           props.data.items_list &&
           props.data.items_list.map((item) => 
           {
            return <CartItemsModal key={item.cart_id} data={item}></CartItemsModal>;

           })}
         {/* {cartDetail && cartDetail.items_list && <p>heelo</p>} */}
       </div>
       <div className='navbar-header-cart-modal-container-button'>
         <div>Total Amount : &#8377;{props.data.final_discounted_price}</div>
         <section onClick={()=>{navigate('./cart')}}>Update Cart</section>
         <button onClick={()=>{navigate('./cart/final')}}>Buy Now</button>
       </div>
     </div>:
     <>
     </>
     }
   </React.Fragment>
 );
}


function CartItemsModal(props)
{ 
 const { changeInCart, setChangeInCart } = useGlobalContext();
  const Delete = async () => {
    console.log('delete');
    const response = await cartapi.DeleteProd(props.data.cart_id);
    console.log(response);
    if (response.data.error === '' && response.data.status === true) {
      // props.diff('delete');
      setChangeInCart(!changeInCart)
    }
  };



  return (
    <React.Fragment>
      <div className='navbar-header-cart-modal-container-item'>
        <div>
          <img src={props.data.url}></img>
        </div>
        <section>
          <div className='navbar-header-cart-modal-container-item-details'>
            <div>{props.data.product_name}</div>
            <span>Quantity : {props.data.product_quantity}</span>
            <section>{props.data ? props.data.discount : ''}% OFF</section>
          </div>
          <div className='navbar-header-cart-modal-container-item-quantity'>
            Qty {props.data.quantity}
          </div>
          <div className='navbar-header-cart-modal-container-item-price'>
            &#8377;{props.data ? props.data.selling_price : ''}
          </div>
        </section>
        <article onClick={Delete}>X</article>
      </div>
    </React.Fragment>
  );
}