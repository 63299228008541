import React, { useEffect, useState } from "react";
import logo from "../images/logo.png";
import playstore from "../images/playstore.png";
import apiclient from "../api/apiclient";

import { BiLogoFacebook } from "react-icons/bi";
import { FiInstagram } from "react-icons/fi";
import { FaLinkedinIn } from "react-icons/fa";
// import { HiOutlineChevronDown, HiOutlineChevronUp } from "react-icons/hi";

import "../css/footer.css";
import { useNavigate } from "react-router-dom";
import notify from "../error";
export default function Footer() {
  // const [showMoreFooter, setShowMoreFooter] = useState(false);

  const [smallScreen, setSmallScreen] = useState(window.innerWidth < 480);

  useEffect(() => {
    const handleResize = () => {
      setSmallScreen(window.innerWidth < 480);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const navigate = useNavigate();

  function ToGrocery() {
    localStorage.setItem("productData", [6, 281, JSON.stringify(null)]);
    navigate("/productprovider");
  }
  async function favPages(name) {
    try {
      const response = await apiclient.addFavservice(name);
      if (response.data.error !== "") {
        notify("error", response.data.error);
      } else {
        notify("success", "Added to Favourite ");
      }
      window.location.reload(false);
    } catch (error) {
      notify("error", "Error in adding favorite");
    }
  }

  // const showMoreFun = () => {
  //   setShowMoreFooter(!showMoreFooter);
  // };
  // {console.log(showMoreFooter,smallScreen)}
  return (
    <>
        <div className="footer">
          <section className="footer-col1">
            <div className="footer-logo-container">
              <img src={logo} className="footer-logo" alt="" />
            </div>
            <div className="footer-text">
              <h2>Putatoe</h2>
              <div>version 12.0.1</div>
            </div>
          </section>
          <section className="footer-col2">
            <div>
              <h2>Useful Links</h2>
              <ul className="footer-col2-links">
                <div onClick={() => navigate("/mobile-recharge")}>
                  Recharge & Payments
                </div>
                <div onClick={() => navigate("/jobs")}>Jobs & internships</div>
                <div onClick={() => navigate("/blog")}>Blog Posts</div>
                <div onClick={() => navigate("/post")}>Promotions</div>
                <div onClick={() => navigate("/loan")}>Loans</div>
              </ul>
            </div>
            <div>
              <h2>About Putatoe</h2>
              <ul className="footer-col2-links">
                <div onClick={()=>navigate("/about")}>About us</div>
                <div onClick={()=>navigate("/contactUs")}>Contact us</div>
                <div onClick={()=>navigate("/terms-and-conditon")}>Terms and conditions</div>
              </ul>
            </div>
            <div>
              <h2>Popular Searches</h2>
              <ul className="footer-col2-links">
                <div onClick={ToGrocery}>Grocery</div>
                <div
                  onClick={() =>
                    navigate("Sub_serviceProduct/323/Construction")
                  }
                >
                  Construction
                </div>
                <div onClick={() => favPages("Daily_Needs")}>Daily Needs</div>
                <div onClick={() => favPages("Courier")}>Courier Service</div>
                <div onClick={() => favPages("Education")}>Education</div>
                <div onClick={() => favPages("Laundry")}>Laundry Service</div>
              </ul>
            </div>
          </section>
          <section className="footer-col3">
            <div className="inner-divs">
              <div className="footer-col3-heading">Connect with us</div>
              <div className="footer-icon-container">
                <div
                  className="footer-facebook-container"
                  onClick={() => {
                    window.location.href =
                      "https://www.facebook.com/putatoetechnologies";
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <BiLogoFacebook className="footer-facebook" />
                </div>
                <div
                  className="footer-linkedin-container"
                  onClick={() => {
                    window.location.href =
                      "https://www.linkedin.com/company/putatoe/";
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <FaLinkedinIn className="footer-linkedin" />
                </div>
                <div
                  className="footer-instagram-container"
                  onClick={() => {
                    window.location.href =
                      "https://www.instagram.com/put.at.toe/";
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <FiInstagram className="footer-instagram" />
                </div>
              </div>
            </div>
            <div className="inner-divs">
              <div className="footer-col3-heading">Get our App</div>
              <img className="footer-col3-img" src={playstore} alt="" />
            </div>
          </section>
        </div>
    </>
  );
}
