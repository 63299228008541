import React from 'react'
import Jobapi from '../api/Jobapi';
import JobCard from "./JobCard";
import Loading from '../components/Loading';
import './css/Jobs.css'
import { useGlobalContext } from '../context';
import JobModal from './JobModal';

export default function JobsViewPage()
{
  const {modifyModal,jobModalInfo} = useGlobalContext();
  const [JobLists,setJobLists] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const getAllJobs = async () => {
    try {
      const response = await Jobapi.DisplayAllJobs();
      setJobLists(response.data.job_lists);
      console.log("Job Lists ",JobLists);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching Jobs:", error);
      setIsLoading(false);
    }
  };
  React.useEffect(() => {
    getAllJobs();
  },[])
  return (
    <React.Fragment>
      <div className='jobs-main-page-container'>
        {modifyModal && <JobModal cardInfo={jobModalInfo} />}
        {isLoading &&  <div style={{height:"50vh",display:"flex",justifyContent:"center",width:"100vw",alignItems:"center"}}><Loading /></div>}
        <JobCard JobLists={JobLists}/>
      </div>
    </React.Fragment>
  )
}