import React, { useContext, useState } from "react";

const PostContext = React.createContext();

const PostProvider = ({ children }) => {
  const [detail, setDetail] = useState();
  const [image1, setImage1] = useState();
  const [Delete, setDelete] = useState(1);
  const [image2, setImage2] = useState();
  const [image3, setImage3] = useState();
  const [mypost, setmypost] = useState();
  const [commentText, setCommentText] = useState([]);
  const [image4, setImage4] = useState();
  const [prevHead, setprevHead] = useState();
  const [newpost,setnewpost]=useState(false);
  // infinite scroll------->
  const [allpost, setallpost] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [startIndex, setStartIndex] = useState(0);
  const [imageUrl, setImageUrl] = useState();
  const [changed, setChanged] = useState(false);

  // postModal
  const [promotionModal, setPromotionModal] = useState(false);
  const [postInfo, setPostInfo] = useState([]);
  const [postUpdate, setPostUpdate] = useState(true);
  // create post states
  const [createPostModal, setCreatePostModal] = useState(true);
  // edit post
  const [editPostData, setEditPostData] = useState([]);
  const [Loadingsingle,setloadingsingle]=useState(false);

  return (
    <PostContext.Provider
      value={{
        image1,
        setImage1,
        image2,
        setImage2,
        image3,
        setImage3,
        allpost,
        setallpost,
        isLoading,
        setIsLoading,
        startIndex,
        setStartIndex,
        commentText,
        setCommentText,
        mypost,
        setmypost,
        Delete,
        setDelete,
        image4,
        setImage4,
        prevHead,
        setprevHead,
        detail,
        setDetail,
        imageUrl,
        setImageUrl,
        promotionModal,
        setPromotionModal,
        postInfo,
        setPostInfo,
        postUpdate,
        setPostUpdate,
        createPostModal,
        setCreatePostModal,
        editPostData,
        setEditPostData,newpost,
        setnewpost,changed,setChanged,Loadingsingle,setloadingsingle
      }}
    >
      {children}
    </PostContext.Provider>
  );
};
// make sure use
export const usePostContext = () => {
  return useContext(PostContext);
};

export { PostProvider, PostContext };
