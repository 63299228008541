import React, { Component } from "react";
import "./cart-css/CouponModal.css";
import NocouponImg from "../images/noCoupons.png";
import cartapi from "../api/cartapi";
import { useGlobalContext } from "../context";
import notify from "../error";
import Loading from "../components/Loading";
export default function CouponModal(props) {
  const [coupon, setCoupon] = React.useState([]);
  const [selectedCoupon, setSelectedCoupon] = React.useState(null);
    const { setLoading, loading,coouponid,setCoouponid } = useGlobalContext();
  console.log(props);

  const fetchAllCoupons = async () => {
    setLoading(true);
    let isRecharge = false;

    if (props.isrechargepage === "/cart/recharge") {
      isRecharge = "true";
    }
    const response = await cartapi.AllCoupons(isRecharge);
    console.log(response);
    if (response.data.error === "") {
      console.log(response);
      setLoading(false);
      setCoupon(response.data.coupons);
    }
  };
  function HideModal() {
    props.setShow();
  }

  const applyCoupon = async (id, e) => {
    e.target.disabled = true;
    let response;
    setCoouponid(id);
    if (props.isrechargepage === "/cart/recharge") {
      response = await cartapi.ApplyCouponsMobRecharge(
        id,
        props.rechargeamount
      );
      console.log(response);
      props.onCouponApplied(response.data);
    } else {
      response = await cartapi.ApplyCoupons(id);
    }

    console.log(response);
    if (response.data.error === "") {
      console.log(response);
      // Call cartDetail function to update cart details
      if (props.isrechargepage !== "/cart/recharge") {
        props.cartDetail();
      }
      HideModal();
      notify("success", "Coupon Applied Successfully");
    }
  };

  React.useEffect(() => {
    fetchAllCoupons();
  }, []);

  return (
    <React.Fragment>
      {props.show && (
        <div className="cart-coupon-modal-container-background">
          <div
            className={
              props.show
                ? " cart-coupon-modal-container cart-coupon-modal-container-intro-in"
                : "cart-coupon-modal-container"
            }
          >
            <div className="cart-coupon-modal-cross">
              <span onClick={HideModal}>X</span>
            </div>
            {/* <div className='cart-coupon-modal-coupon-enter'>
              <input
                type='text'
                name='Coupon'
                placeholder='Enter Coupon Code'
                value={selectedCoupon ? selectedCoupon.name : ''}
                onChange={(e) => setSelectedCoupon({ name: e.target.value })}
              ></input>
              <button  onClick={applyCoupon}> Apply</button>
            </div> */}
            {coupon && coupon.length > 0 ? (
              <div className="cart-coupon-modal-coupon-coupon">
                {coupon.map((coupon) => (
                  <div className="coupon-info" key={coupon.id}>
                    <div className="cart-coupon-modal-coupon-enter">
                      <h2>{coupon.name}</h2>
                      {props.isrechargepage === "/cart/recharge" ? (
                        <button onClick={(e) => applyCoupon(coupon.id, e)}>
                          Apply
                        </button>
                      ) : (
                        <button onClick={(e) => applyCoupon(coupon.id, e)}>
                          Apply
                        </button>
                      )}
                    </div>
                    <p>
                      Use code <strong className="couuponname">{coupon.name}</strong> to get{" "}
                      {coupon.discount} off along with{" "}
                      {coupon.cashback
                        ? `get ${coupon.cashback} cashback.`
                        : "no cashback."}
                    </p>
                  </div>
                ))}
              </div>
            ) : loading ? (
              <div className="loadingHolder"><Loading />
              </div>
            ) : (
              <div className="cart-coupon-modal-coupon-no-coupon">
                <img src={NocouponImg} alt="" />
              </div>
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
