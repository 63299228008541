import React, { useState } from "react";
import "../css/popularProductCard.css";
import { useGlobalContext } from "../context";
import { motion } from "framer-motion";
import starf from "../images/Starf.svg";
import star from "../images/Star.svg";
import defaultImage from "../images/imageNotFound.png";
import add from "../images/add.svg";
import { Link } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";
import { BsCartPlus } from "react-icons/bs";
import apiclient from "../api/apiclient";
import cartapi from "../api/cartapi";
import notify from "../error";
import { useNavigate } from "react-router-dom";
import { BiShareAlt } from "react-icons/bi";

export default function PopularProductCard(props) {
  const { userName, setUserName, changeInCart, setChangeInCart } =
    useGlobalContext();
  const [counterDisplay, setCounterDisplay] = React.useState(false);
  const [cartCount, setCartCounter] = React.useState(1);
  const [rating, setRating] = React.useState();
  const [showConfirmation, setShowConfirmation] = React.useState(false);
  const [myCartId, setMyCardId] = React.useState();
  const [options, setOptions] = React.useState([]);
  const [quantityItemIndex, setQuantityItemIndex] = React.useState(0);
  const [colorItemIndex, setcolorItemIndex] = React.useState(0);
  const [showMorecolours, setShowMorecolours] = React.useState(false);

  const navigate = useNavigate();
  const colorref = React.useRef();
  const [open, setopen] = useState();

  const toggleShowMore = (element) => {
    // console.log("dkkdk")
    setopen(element.target);
    setShowMorecolours(!showMorecolours);
  };
  const name = () => {
    // console.log(e)
    // console.log(props);
    // Determine if it's the home page route

    // console.log([props.service_id, props.sub_service_id, props.type_id2]);
    // setUserName([props.service_id, props.sub_service_id]);
    if (props.type_id2 > 0) {
      // console.log([props.service_id, props.sub_service_id, props.type_id2]);
      localStorage.setItem("productData", [
        props.service_id,
        props.sub_service_id,
        props.type_id2,
      ]);

    } else {
      // console.log([props.service_id, props.sub_service_id, props.type_id]);
      localStorage.setItem("productData", [
        props.service_id,
        props.sub_service_id,
        props.type_id,
      ]);

    }
    // console.log('here')
    // console.log(userName,'username');
  };
  const handleClearCartConfirmation = async () => {
    setShowConfirmation(false); // Close the modal
    const apiResponse = await cartapi.ClearCart();
    if (apiResponse.data.error === '') {
      RunAddToCart();
    }
    else {
      // console.log("eneterd");

      notify('error', apiResponse.data.error);
    }
  };

  const RunAddToCart = async () => {
    // console.log(props.qty_list[quantityItemIndex]);
    if (showColorInfo ? !props.qty_list[quantityItemIndex].color[colorItemIndex].out_of_stock : !props.qty_list[quantityItemIndex].out_of_stock) {
      const response = await apiclient.AddToCart(
        props.qty_list[quantityItemIndex].product_id,
        props.qty_list[quantityItemIndex].quantity_id,
        "",
        1,
        props.service_id,
        -props.qty_list[quantityItemIndex].id,
        props.qty_list[quantityItemIndex].color && props.qty_list[quantityItemIndex].color.length > 0 ? props.qty_list[quantityItemIndex].color[colorItemIndex].color_id : null
      );
      // console.log(
      //   props.qty_list[quantityItemIndex].product_id,
      //   props.qty_list[quantityItemIndex].quantity_id,
      //   "",
      //   1,
      //   props.service_id,
      //   -props.qty_list[quantityItemIndex].id,
      //   props.qty_list[quantityItemIndex].color && props.qty_list[quantityItemIndex].color.length > 0 ? props.qty_list[quantityItemIndex].color[colorItemIndex].color_id : null
      // );
      if (response.data.error !== '') {
        notify('error', response.data.error);

        if (response.data.error === "Can't add product from different Services") {
          // console.log("ok");
          setShowConfirmation(true);
        } else {

          setCounterDisplay(true);
          notify('success', `one item added to cart`);
          setMyCardId(response.data.cart_id);
          setChangeInCart(!changeInCart)
        }

      } else {
        // console.log(response.data.cart_id)
        setCounterDisplay(true);
        notify("success", `one item added to cart`);
        setMyCardId(response.data.cart_id);
        setChangeInCart(!changeInCart);
      }
    } else {
      notify("error", "Item is out of stock");
    }
    // setProductDetail();
  };

  const DeleteMyProduct = async () => {
    setCounterDisplay(false);
    const response = await cartapi.DeleteProd(myCartId);
    // console.log(response);
    if (response.data.error === "" && response.data.status === true) {
      notify("success", "product removed from cart");
      setChangeInCart(!changeInCart);
    }
  };
  const runUpdateMyCart = async () => {
    const response = await cartapi.UpdateProd(myCartId, null, cartCount, 1);
    // console.log(response);
    if (response.data && response.data.error !== "") {
      notify("error", response.data.error);
    } else {
      setChangeInCart(!changeInCart);
    }
  };



  React.useEffect(() => {
    if (myCartId) {
      runUpdateMyCart();
    }
  }, [cartCount]);

  React.useEffect(() => {
    let arr = [];
    for (let i = 0; i < props.qty_list.length; i++) {
      arr.push(
        <option key={i} value={i}>
          {props.qty_list[i].quantity}
        </option>
      );
    }
    setOptions(arr);
  }, []);

  const manageOutsideclick = (event) => {
    // console.log("manageoutsideclick1");
    // console.log(event.target);
    // console.log(colorref,colorref.current,colorref.current.contains(event.target));
    if (colorref && colorref.current && colorref.current.contains(event.target)) {
      // console.log("inside click");
      setShowMorecolours(true);
    }
    else {
      // console.log("inside click11");
      setShowMorecolours(false);
    }
  }

  React.useEffect(() => {
    document.addEventListener("click", manageOutsideclick);

    return () => {
      document.removeEventListener("click", manageOutsideclick);
    }
  }, []);

  const handleChangeQty = (e) => {
    // console.log(e.target.value);
    setQuantityItemIndex(e.target.value);
    setcolorItemIndex(0);
    setCounterDisplay(false);
  };

  const handleChangeColor = (index) => {
    // console.log(index);
    setcolorItemIndex(index);
    setCounterDisplay(false);
  };
  // console.log(colorItemIndex);
  if (!props.qty_list || props.qty_list.length === 0) {
    return null; // Don't render any product if qty_list is empty
  }

  function getBaseUrl() {
    // Check if the application is running on localhost
    if (window.location.hostname === "localhost" && window.location.port === "3000") {
      // Running on localhost:3000
      return "http://localhost:3000";
    } else if (window.location.hostname === "putatoetest.web.app") {
      return "https://putatoetest.web.app";
    }
    else {
      return "https://www.putatoe.com"
    }
  }
  const showColorInfo =
    props.qty_list[quantityItemIndex].color && props.qty_list[quantityItemIndex].color.length > 0 &&
    Object.keys(props.qty_list[quantityItemIndex].color[colorItemIndex]).length > 0;
  // Get the base URL
  const baseUrl = getBaseUrl();
  // console.log(baseUrl);
  // let percentage=(props.qty_list[quantityItemIndex].discount)*100/props.qty_list[quantityItemIndex].selling_price;
  // let percentage=(props.qty_list[quantityItemIndex].discount)*100/props.qty_list[quantityItemIndex].selling_price;
  const shareOption = async () => {
    const { service_id, sub_service_id, type_id } = props;
    // console.log(props.productid);
    if (type_id) {
      try {
        if (navigator.share) {
          // console.log('Web Share API is supported');
          await navigator.share({
            title: 'Share Link',
            text: 'Check out this' + props.name + '!',
            url: `${baseUrl}/product/${props.productid}/${props.name}/${props.service_id}`
          });

          notify("success", "Link shared successfully");
        } else {
          // console.log('Web Share API is not supported');
          // Fallback for browsers that do not support Web Share API
          const link = `${baseUrl}/product/${props.productid}/${props.name}/${props.service_id}`;
          const tempInput = document.createElement("input");
          tempInput.value = link;
          document.body.appendChild(tempInput);
          tempInput.select();
          document.execCommand("copy");
          document.body.removeChild(tempInput);
          notify("success", "Link copied successfully");
        }
      } catch (error) {
        console.error('Error sharing link:', error);
        notify("error", "Error sharing link");
      }
    }
  };
  return (
    <React.Fragment>
      {/* {console.log(props.qty_list[quantityItemIndex].selling_price)} */}
      <div className="service-provider-card spcard-mobile sserviccecard" onClick={name}>
        <Link to="/productprovider">
          <div className="service-provider-image-container">
            {showColorInfo && props.qty_list[quantityItemIndex].color[colorItemIndex].image.length > 0 ? (
              // If color array has images, render the color image
              <img
                src={props.qty_list[quantityItemIndex].color[colorItemIndex].image[0].image}
                className="service-provider-image"
                alt="Color Image"
              />
            ) : (props.logo ? (
              <img
                src={props.qty_list[quantityItemIndex].qty_image}
                className="service-provider-image"
                alt="Logo"
              />
            ) : (
              <img
                src={defaultImage}
                className="service-provider-image"
                alt="Default Logo"
              />
            ))}
            {/* {console.log(props)} */}
          </div>
          <div className="service-provider-card-details-top">
            <div className="service-provider-card-details-brand-name">
              {props?.bran_name.toLowerCase() != "none" && <>{props.bran_name}</>}
            </div>
            <div className="service-provider-card-details-product-name">
              {props.name}
            </div>
            <div className="service-provider-card-details-product-type">
              {props.product_type}
            </div>
          </div>
        </Link>
        <div className="service-provider-card-details-mid">
          <div className="service-provider-card-details-price-cont">
            <div className="service-provider-card-details-price sp-cd-price-cont-mobile">
              <span>&#8377;</span>{" "}
              {showColorInfo
                ? props.qty_list[quantityItemIndex].color[colorItemIndex].selling_price
                : props.qty_list[quantityItemIndex].selling_price}
            </div>
            {props.qty_list[quantityItemIndex].discount !== 0 ? (
              <div className="service-provider-card-details-pricecut sp-cd-price-cont-mobile">
                <strike>
                  M.R.P. &#8377;
                  {showColorInfo
                    ? props.qty_list[quantityItemIndex].color[colorItemIndex].actual_price
                    : props.qty_list[quantityItemIndex].actual_price}
                </strike>
              </div>
            ) : null}
            {props.qty_list[quantityItemIndex].discount !== 0 ? (
              <div className="service-provider-card-details-price-percentage sp-cd-price-cont-mobile">
                {showColorInfo
                  ? props.qty_list[quantityItemIndex].color[colorItemIndex].discount
                  : props.qty_list[quantityItemIndex].discount}% Off
              </div>
            ) : null}

            <div className="service-provider-card-details-price sp-cd-price-cont-desktop">
              <div className="sp-card-price-offer-cont">
                &#8377;
                {showColorInfo
                  ? props.qty_list[quantityItemIndex].color[colorItemIndex].selling_price
                  : props.qty_list[quantityItemIndex].selling_price}
                {props.qty_list[quantityItemIndex].discount !== 0 ? (
                  <span
                    style={{
                      color: "green",
                      fontWeight: "400",
                      marginLeft: 12,
                    }}
                  >
                    {props.qty_list[quantityItemIndex].discount}% OFF
                  </span>
                ) : null}
              </div>
              {props.qty_list[quantityItemIndex].discount !== 0 ? (
                <span style={{ color: "gray", fontWeight: "400" }}>
                  <strike>
                    &#8377;
                    {showColorInfo
                      ? props.qty_list[quantityItemIndex].color[colorItemIndex].actual_price
                      : props.qty_list[quantityItemIndex].actual_price}
                  </strike>
                </span>
              ) : null}
            </div>

          </div>
        </div>
        <div className="service-rating-instock-container">
          <div className="service-stock-color-container">
            {!showColorInfo
              ? !props.qty_list[quantityItemIndex].out_of_stock
                ? <span>In Stock</span>
                : <span>Out Of Stock</span>
              : !props.qty_list[quantityItemIndex].color[colorItemIndex].out_of_stock
                ? <span>In Stock</span>
                : <span>Out Of Stock</span>
            }
            <BiShareAlt className="shareIcon" onClick={shareOption} />
          </div>
          <div className="service-provider-card-rating-container">
            <section>
              <img className="service-card-card-star" src={starf} alt=""></img>
              <img className="service-card-card-star" src={starf} alt=""></img>
              <img className="service-card-card-star" src={starf} alt=""></img>
              <img className="service-card-card-star" src={starf} alt=""></img>
              <img className="service-card-card-star" src={star} alt=""></img>
            </section>
            {/* onClick={toggleShowMore} */}
            <div className="service-color-choices"  >
              {showColorInfo && props.qty_list[quantityItemIndex].color && props.qty_list[quantityItemIndex].color.length > 0 && (
                <div className="initial-choice service-color-choice" style={{
                  backgroundColor:
                    props.qty_list[quantityItemIndex].color[colorItemIndex].color_code,
                }}>

                </div>

              )}
              {showColorInfo && props.qty_list[quantityItemIndex].color && props.qty_list[quantityItemIndex].color.length > 1 && <span ref={colorref} onClick={toggleShowMore}>{"+" + props.qty_list[quantityItemIndex].color.length + " "} <IoIosArrowDown size={16} fontWeight={600} /></span>}

              {showMorecolours && props.qty_list[quantityItemIndex].color.length > 1 && (
                <div className="additional-choices-container">
                  {props.qty_list[quantityItemIndex].color.map((color, index) => (
                    <div
                      className={`additional-choice service-color-choice ${colorItemIndex === index ? "initial-choice" : ""
                        }`}

                      style={{ backgroundColor: color.color_code }}
                      onClick={() => handleChangeColor(index)}
                    >
                      {/* {color.color_name} */}
                    </div>
                  ))}
                </div>
              )}



            </div>
          </div>
          <div className="service-unit-individual-qty">
            <span>
              Unit: <span>              {showColorInfo
                ? props.qty_list[quantityItemIndex].color[colorItemIndex].unit
                : props.qty_list[quantityItemIndex].units}</span>
            </span>
          </div>
        </div>
        <div className="service-provider-card-bottom">
          <div className="service-card-product-info-container-prime-quantity">
            {/* <span style={{ fontSize: "10px" }}>Qty:</span> */}
            <select name="pieces" onChange={handleChangeQty}>
              {options}
            </select>
          </div>
          {!counterDisplay ? (
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              className="service-provider-add-to-cart"
              onClick={RunAddToCart}
            //  disabled={props.qty_list[quantityItemIndex].out_of_stock}
            >
              <BsCartPlus className="service-provider-add-to-cart-image" />
              <div className="service-provider-plus-icon">+</div>
              {!showColorInfo
                ? !props.qty_list[quantityItemIndex].out_of_stock
                  ? <span className="adddtocart">Add to cart</span>
                  : <span className="outoffstock" >Out Of Stock</span>
                : !props.qty_list[quantityItemIndex].color[colorItemIndex].out_of_stock
                  ? <span className="adddtocart">Add to cart</span>
                  : <span className="outoffstock">Out Of Stock</span>
              }
            </motion.button>
          ) : (
            <>
              <div className="service-provider-card-bottom-counter">
                <div
                  className="service-provider-card-bottom-counter-minus"
                  onClick={() => {
                    cartCount > 1
                      ? setCartCounter((prev) => prev - 1)
                      : DeleteMyProduct();
                  }}
                >
                  -
                </div>
                <div className="service-provider-card-bottom-counter-count">
                  {cartCount}
                </div>
                <div
                  className="service-provider-card-bottom-counter-plus"
                  onClick={() => {
                    setCartCounter((prev) => prev + 1);
                  }}
                >
                  +
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {showConfirmation && (
        <ConfirmationModal
          onCancel={() => setShowConfirmation(false)}
          onConfirm={handleClearCartConfirmation}
        />
      )}
    </React.Fragment>
  );
}


const ConfirmationModal = ({ onCancel, onConfirm }) => {
  return (
    <div className="confirmation-modal-serviceprovider">
      <div className="confirmation-modal-serviceprovider-content">
        <h3>Do you want to replace the service provider products?</h3>
        <div className="confirmation-modal-serviceprovider-buttons">
          <button onClick={onConfirm}>Yes</button>
          <button onClick={onCancel}>No</button>
        </div>
      </div>
    </div>
  );
};