import React, { useState, useEffect } from "react";
import { useGlobalContext } from "../../context";
import myProfileServiceApi from "../../api/myProfileServiceApi";
import back from "../myprofileIcoons/bx-arrow-back..svg";
import { BsPlus } from "react-icons/bs";
import Loading from "../../components/Loading";
import notify from "../../error";
import { motion } from "framer-motion";
import "./css/AllProducts.css";
import AllMyProducts from "./AllMyProducts";
import GenerateBill from "./GenerateBill";
import { IoIosSend } from "react-icons/io";
import ALlProductsCard from "./AllProductsCard";

export default function AllProducts(props) {
  const [productList, setProductList] = useState([]);
  const [displayPriceQuantityMap, setDisplayPriceQuantityMap] = React.useState(
    {}
  );
  const [apicalling,setApicalling]=useState(false);
  const [page, setPage] = useState(1);
  const [colorItemIndexes, setColorItemIndexes] = React.useState(Array(productList.length).fill(0));
  const [showMorecolours, setShowMorecolours] = React.useState(Array(productList.length).fill(false));
  const [emptyFetch, setEmptyFetch] = React.useState(false);
  const [stringinsearch,setStringinsearch]=useState("");
  const {
    Userprofile,
    loading,
    setLoading,
    setInnerPage,
    modifyModal,
    setModifyModal,
    inputPrice,
    startIndex,
    setStartIndex,
  } = useGlobalContext();

  const getAllProducts = async (serviceProviderId, index) => {
    setLoading(true);
    try {
      console.log(stringinsearch);
      const response =
        stringinsearch === ""
          ? await myProfileServiceApi.ServiceProviderAllProduct(
              serviceProviderId,
              index
            )
          : null;
  
      if (response && response.data.product_list.length === 0) {
        setEmptyFetch(true);
      } else if (response && index === 0) {
        const newProductList = response.data.product_list;
        setProductList(newProductList);
  
        // Update colorItemIndexes and showMorecolours
        setColorItemIndexes(Array(newProductList.length).fill(0));
        setShowMorecolours(Array(newProductList.length).fill(false));
      } else if (response) {
        const newProductList = response.data.product_list;
        setProductList((prevProductList) => [...prevProductList, ...newProductList]);
  
        // Update colorItemIndexes and showMorecolours
        setColorItemIndexes((prevColorIndexes) => [
          ...prevColorIndexes,
          ...Array(newProductList.length).fill(0),
        ]);
        setShowMorecolours((prevShowMore) => [
          ...prevShowMore,
          ...Array(newProductList.length).fill(false),
        ]);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };
  

  useEffect(() => {
    getAllProducts(Userprofile.service_provider_id, startIndex);
  }, [Userprofile, startIndex]);

    // useEffect to handle component unmounting
    useEffect(() => {
      // Cleanup function to reset startIndex
      return () => {
        setStartIndex(0);
      };
    }, []);
  function goBack() {
    props.return();
    setInnerPage(false);
    // setStartIndex(0)
  }
useEffect(()=>{
  let searchtimeout;

  const searchProduct = async () => {
    if (stringinsearch.length > 0) {
      try {
        setLoading(true);
        setApicalling(true);
  
        const response = await myProfileServiceApi.SearchProductApi(stringinsearch);
  
        if (response.data.product_list.length !== 0) {
          setProductList(response.data.product_list);
  
          // Update colorItemIndexes and showMorecolours
          setColorItemIndexes(Array(response.data.product_list.length).fill(0));
          setShowMorecolours(Array(response.data.product_list.length).fill(false));
        } else {
          setProductList([]);
          setColorItemIndexes([]);
          setShowMorecolours([]);
        }
  
        setLoading(false);
        setApicalling(false);
      } catch (err) {
        setLoading(false);
        setApicalling(false);
        notify("error", "Error while searching");
      }
    } else {
      setProductList([]);
      setColorItemIndexes([]);
      setShowMorecolours([]);
    }
  };
  
  if (stringinsearch.length === 0) {
    setStartIndex(0);
    getAllProducts(Userprofile.service_provider_id, 0);
  }
  searchtimeout=setTimeout(() => {
    if (stringinsearch && stringinsearch!=="") {
      console.log("called");
    searchProduct();
    }
  }, 1000);

  return ()=>{
    if(searchtimeout){
      clearTimeout(searchtimeout);
    }
  }
},[stringinsearch])

// for color data of products
  const handleChangeColor = (index, colorIndex) => {
    const newColorItemIndexes = [...colorItemIndexes];
    newColorItemIndexes[index] = colorIndex;
    setColorItemIndexes(newColorItemIndexes);
  };

  const toggleShowMore = (index) => {

    const newShowMoreColors = [...showMorecolours];
    newShowMoreColors[index] = !newShowMoreColors[index];
    setShowMorecolours(newShowMoreColors);
  };



  return (
    <section>
      <div className="offline-profile-service-main-page">
        <div className="my-service-profile-products-section-selector-tab">
          <div className="offline-profile-ongoing-and-pending-online-back">
            <img src={back} onClick={goBack} alt="backIcon" />
          </div>
          <motion.span
            className={`${page === 1 && "underlinedOnPage"} `}
            onClick={() => {
              setPage(1);
            }}
            whileHover={{ color: "black" }}
          >
            All Products
          </motion.span>
          <motion.span
            className={`${page === 2 && "underlinedOnPage"} `}
            onClick={() => {
              setPage(2);
            }}
            whileHover={{ color: "black" }}
          >
            My Products
          </motion.span>
          <motion.span
            className={`${page === 3 && "underlinedOnPage"} `}
            onClick={() => {
              setPage(3);
            }}
            whileHover={{ color: "black" }}
          >
            Generate Bill
          </motion.span>
        </div>
        {page === 1 ? (
          <>
            <div className="AllProductsSearchBox">
              <input
                type="text"
                placeholder="Enter your product here"
                onChange={(e)=>{
                  setStringinsearch(e.target.value);
                  // searchProduct(e);
                }}
                // searchProduct
              />
              <IoIosSend className="sendIcon" />
            </div>
            <div className="manage-services-profile-service-main-page-main-box-before">
              {productList && (
                <>
                  Total <span>{productList.length}</span> Product(s)
                </>
              )}
            </div>
            <div className="manage-services-profile-service-main-page-main-box">
              {productList.length !== 0  && colorItemIndexes && colorItemIndexes.length > 0 && colorItemIndexes.length===productList.length
                ? productList.map((item, index) => {
                    const selectedQtyIndex =
                      displayPriceQuantityMap[index] || 0;
                    const selectedQty = item.qty_list[selectedQtyIndex];

                    return (
                      <ALlProductsCard
                        index={index}
                        length={productList.length}
                        item={item}
                        selectedQty={selectedQty}
                        selectedQtyIndex={selectedQtyIndex}
                        key={index}
                        colorItemIndexes={colorItemIndexes}
                        showMorecolours={showMorecolours}
                        handleChangeColor={handleChangeColor}
                        toggleShowMore={toggleShowMore}
                        setColorItemIndexes={setColorItemIndexes}
                      />
                    );
                  })
                : ((loading && stringinsearch === "") || (loading && stringinsearch !=="" && apicalling))? <Loading />:<p>No results</p>}
            </div>
          </>
        ) : page === 2 ? (
          <AllMyProducts />
        ) : (
          <GenerateBill  />
        )}
      </div>
    </section>
  );
}
