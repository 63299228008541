import React, { Component } from 'react';
import PutatoeServiceApi from '../api/PutatoeServiceApi';
import PopularProductsSubContainer from '../components/popularProductsSubContainer';
import Service from '../components/service';
import PutatoeServiceOptionCards from './PutatoeServiceOptionCards';
import { useParams, Link } from 'react-router-dom';
import { useGlobalContext } from '../context';
import starf  from '../images/Starf.svg'
import star from '../images/Star.svg';
import location from '../images/location-pointer.svg';
import '../PutatoeService-Css/putatoesubService.css';
import { useNavigate } from 'react-router-dom';

import PopularProductCard from '../components/popularProductCard';
import scrollright from '../images/scrollright.svg'
import scrollleft from '../images/scrollleft.svg';
import { motion } from 'framer-motion';
import '../css/popularProductsSubContainer.css';
import '../css/carousel.css';
import Loading from '../components/Loading';


export default function PutatoeSubServiceView() {
  const { name ,service} = useParams();
  const  {tempProduct}=useGlobalContext();
  const nameInt = name;
  let currentUrl = window.location.href;
  // write code to get option from here
  let option = 6;
  const [popularProduct, setPopularProduct] = React.useState('');
  const [popularSeller, setPopularSeller] = React.useState([]);
  const [OurService, setOurService] = React.useState([]);

  const fetchPopularByService = async () => {
    try {
      const response = await PutatoeServiceApi.PopularSUBSellerAndProductData(
      nameInt,tempProduct[0].toString()


      );
      
      setPopularProduct(response.data.product_list);
      console.log(popularProduct);
      // setPopularSeller(response.data.popular_seller);
      console.log(popularProduct);
      // setPopularSeller(response.data.popular_seller);
    } catch (error) {
      console.log(popularSeller)
      console.log(popularSeller.length)
    }
  };
  const fetchOtherService = async () => {
    const response = await PutatoeServiceApi.PopularSUBServiceData(nameInt);

    setOurService(response.data);
  };
  const navigate =useNavigate();
  const nav=(username,id,service_id,sub_service_id)=>
  {
    localStorage.setItem('driverData', [username,id,sub_service_id,sub_service_id,service_id]);
    navigate(`/${username}`);
  }
  React.useEffect(() => {
     console.log("triggered sub");
    fetchPopularByService();
    fetchOtherService();
  }, [option]);

  return (
    <React.Fragment>
      <div className='putatoeServiceView-container'>
        {popularProduct && popularProduct.length>0 && <div className='putatoeServiceView-top-heading'>TOP DEALS</div>}
        <Subpopularsubcontainer
          products={popularProduct}
          category={service}
          
          id={nameInt}
        ></Subpopularsubcontainer>
       
        {console.log(popularSeller)}
        {/* {popularSeller && popularSeller.length>0 && <><div className='putatoeServiceView-sec-heading-container'>
        {/* {popularSeller && popularSeller.length>0 && <><div className='putatoeServiceView-sec-heading-container'>
          <div className='putatoeServiceView-sec-vertical-fill'></div>
          <div className='putatoeServiceView-sec-heading'>BESTSELLERS</div>
        </div>
        <div className='putatoeServiceView-sec-data'>
          <Service
            name={service}
            popular={popularSeller}
            parent_id={option}
          ></Service>
        </div>
        </>
         } */}
        
        <div className='putatoeServiceView-third-heading'>
          OUR SERVICE PROVIDER
        </div>
        <div className='putatoeServiceView-third-data-container'>
          {OurService &&
            OurService.service_provider &&
            OurService.service_provider.map((item) => {
               console.log(item)
              return (
                <div onClick={()=>nav(item.username,item.id,OurService.service_id,nameInt)}>
                  <PutatoeSubServiceCards
                    data={item}
                    service_id={OurService.service_id}
                    sub_service_id={OurService.sub_service_id}
                    user={item.username}
                    id={item.id}
                    parent_id={OurService.service_id}
                  />
                </div>
              );
            })}
        </div>
      </div>
    </React.Fragment>
  );
}

// two function in one

function PutatoeSubServiceCards(props) {
  const { userName, setUserName } = useGlobalContext();

const name = () => {
  console.log([props.user, props.id, props.parent_id]);
  // setUserName([props.user,props.id,props.parent_id])
  console.log([props.username, props.id, props.service_id]);
  localStorage.setItem('driverData', [props.user, props.id, props.parent_id]);
  console.log(
    'local storage',
    localStorage.getItem('driverData').split(',')[0],
    localStorage.getItem('driverData').split(',')[1],
    localStorage.getItem('driverData').split(',')[2]
  );
};

  

  return (
    <>
      <div
        className='PutatoeServiceOptionCards-container-desktop-2'
        onClick={name}
      >
        <div className='PutatoeServiceOptionCards-desktop-text'>
          <h1 className='PutatoeServiceOptionCards-heading-2-desktop'>
            <span>{props.data.provider}</span>
          </h1>

          <div className='PutatoeServiceOptionCards-heading-Ratings'>
            {/* <img src={star}></img> */}
            {props.data.rating &&
              [...Array(parseInt(props.data.rating))].map((item, index) => (
                // <Field id={index + 1} />
                <img key={index} src={starf}></img>
              ))}
          </div>
          <div className='PutatoeServiceOptionCards-heading-distance'>
            <span>
              <img src={location}></img>
            </span>
            {props.data.distance} km away
          </div>
          <div className='PutatoeServiceOptionCards-heading-description'>
            {props.data.discription}
          </div>
        </div>
        <div className='PutatoeServiceOptionCards-image-container'>
          <img
            className='PutatoeServiceOptionCards-image'
            src={props.data.logo}
            alt=''
          ></img>
        </div>
      </div>
    </>
  );
}

const Subpopularsubcontainer = (props) => {
  const [isHovered, setIsHovered] = React.useState(false);
  const wrapperRef = React.useRef(null);
  const scrollLeft=(e)=>
  {
    console.log(e)
    console.log(wrapperRef.current.offsetWidth)
    console.log(props.products.length*200)
      let x = wrapperRef.current.scrollLeft;
      let y = wrapperRef.current.scrollTop;
      console.log(x)
      wrapperRef.current.scrollLeft+=800;
  }
  const scrollRight=(e)=>
  {
      console.log(e);
      console.log(wrapperRef.current.offsetWidth);
      console.log(props.products.length * 200);
      let x = wrapperRef.current.scrollLeft;
      let y = wrapperRef.current.scrollTop;
      wrapperRef.current.scrollLeft -= 800;
  }
  // console.log('rerendered subcontainerof products')
  
  
  const navigate = useNavigate();
  function move() {
  
    navigate(`/Sub_serviceProduct/${props.id}/${props.category}`);
  }
  
    return (
      <React.Fragment>
      
          <div className='popular-product-container'>
          {props.products  ? (
            <>
                {props.products.length > 0 ? (
            <>
                    <div className='popular-product-container-info-heading'>
            <div className='popular-product-category'  onClick={move}>
              Top Deals For {props.category}
            </div>
            </div>
            <div   className={`popular-products-per-category-head ${isHovered ? 'hovered' : ''}`}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}>
            <div className='carousel-scroll'>
              <button
                className='carousel-scroll-left'
                onClick={scrollLeft}
              >
                <img src={scrollleft}></img>
              </button>
              
              <button
                className='carousel-scroll-right' 
                onClick={scrollRight}
              >
                <img src={scrollright}></img>
              </button>
            </div>
              <div className='popular-products-per-category popular-products-per-category-for-mobile-view' ref={wrapperRef}>
              {props.products && props.products.map((product) => (
                      <PopularProductCard
                        name={product.product_name}
                        logo={product.logo}
                        price={product.price}
                        productid={product.service_provider_product_id}
                        product_type={product.producttype}
                        service_id={product.service_id}
                        sub_service_id={product.sub_service_id}
                        type_id={product.producttype_id}
                        qty_list={product.qty_list}
                        bran_name={product.brand}
                        // type_id2={
                        //   props.product_type && props.product_type[index] ? props.product_type[index].product_type_id : -1
                        // }
                      ></PopularProductCard>
    
                      ))}
  
            
              </div>
            </div>
            </>
          ) : (
            <>
              {/* Show message when there are no products */}
              <div style={{margin:"30px"}}>No Products available</div>
            </>
          )}
        </>):(
          <>
          <div style={{margin:"30px"}}></div>
           <Loading/>
          </>)}
        
          </div>
  
      </React.Fragment>
    );
  }
  
  
  
  
