import React, { useEffect, useRef, useState } from "react";
import { useGlobalContext } from "../../context";
import myProfileServiceApi from "../../api/myProfileServiceApi";
import back from "../myprofileIcoons/bx-arrow-back..svg";
import logo from "../../images/logo.png";
import notify from "../../error";
import "./css/OfflineIncomingAndOutgoingOrder.css";
import { motion } from "framer-motion";
import { MdDelete } from "react-icons/md";

import PdfViewer from "./PdfViewer";
import Loading from "../../components/Loading";
import { Link } from "react-router-dom";
export default function OfflineIncomingAndOutgoingOrder(props) {
  const { Userprofile, isMobileView, setInnerPage, loading, setLoading, userServiceIDselected } =
    useGlobalContext();
  const date = new Date();
  const date2 = new Date();
  const sp_id = Userprofile.service_provider_id;

  function subtract15Days(date2) {
    const newDate = new Date(date2);
    newDate.setDate(date2.getDate() - 2);
    return newDate;
  }
  const newDate = subtract15Days(date2);

  // const date = new Date('2022-11-14T00:00:00.000Z');

  const [startIndex, setStartIndex] = React.useState(0);

  const [emptyFetch, setEmptyFetch] = React.useState(false);

  const [myOrders, setMyOrders] = React.useState();
  const [myOrders2, setMyOrders2] = React.useState();
  const [isOpendropdown, setIsOpendropdown] = React.useState(false);
  const [isModalprodtransOpen, setIsModalprodtransOpen] = React.useState(false);

  //for trans summary
  const [isModaltranssummaryOpen, setIsModaltranssummaryOpen] = React.useState(false);
  const [usersummarymodal, setUsersummarymodal] = React.useState(false);
  const [selectedProductsummary, setSelectedProductsummary] = React.useState({
    prodtypename: "All Products Type",
    prodtypeid: 0,
    prodname: "All Products ",
    quantityname: "All Quantity",
    quantityid: 0,
    prodid: 0,
    userid: "",
    user: ""
  });
  const [pagefortrans, setPagefortrans] = React.useState(0);
  const [summarydata, setSummarydata] = React.useState("");
  const [summarydata2, setSummarydata2] = React.useState("");
  let selectedpageref=React.useRef();


  //for prod trans
  const [searchQuery, setSearchQuery] = React.useState('');
  const [productTransactions, setProductTransactions] = React.useState([]);

  const [selectedTransaction, setSelectedTransaction] = React.useState({
    prodtypename: "",
    prodtypeid: "",
    prodname: "",
    quantityname: "",
    quantityid: "",
    prodid: ""
  });

  const toggleDropdown = () => {
    setIsOpendropdown(!isOpendropdown);
  };
  const [page, setPage] = React.useState(1);
useEffect(()=>{

  if(page==1 || page==2){
    selectedpageref.current=page;
    console.log("jajajajaajaj",selectedpageref.current);
  }
},[page])
  // Store previous form data for page1 while navigating from page 3 to page 1
  const [previousFormData, setPreviousFormData] = React.useState({
    to: `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(date.getDate()).padStart(2, "0")}`,
    from: `${newDate.getFullYear()}-${String(newDate.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(newDate.getDate()).padStart(2, "0")}`,
    editFrom: `${newDate.getFullYear()}-${String(
      newDate.getMonth() + 1
    ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
  });

  // const getMyOrders = () =>
  // {
  //   // 'process.env.REACT_APP_API_BASE_URL/v1/api/incomingdetails'
  //     const res = axios.get('process.env.REACT_APP_API_BASE_URL/v1/api/incomingdetails',
  //     {sp_id},
  //     {
  //       headers: {
  //         authtoken: localStorage.getItem('authtoken'),
  //       },
  //     });
  //     console.log("Incoming : ",res)
  // }
  // React.useEffect(() => {
  //   getMyOrders();
  // },[])
  //date inputs using calander
  const [formData, setFormData] = React.useState({
    to: `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(date.getDate()).padStart(2, "0")}`,
    from: `${newDate.getFullYear()}-${String(newDate.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(newDate.getDate()).padStart(2, "0")}`,
    editFrom: `${newDate.getFullYear()}-${String(
      newDate.getMonth() + 1
    ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
  });

  //date manupulation
  //how to play with dates
  function handleChange(event) {
    console.log(event);
    setStartIndex(0)
    setEmptyFetch(false);
    setMyOrders([]);
    setMyOrders2([]);
    const { name, value, type, checked } = event.target;
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: type === "checkbox" ? checked : value,
      };
    });
    if (name === "editFrom" ) {

      console.log("here", value);
      const newDate = new Date();
      let now = new Date();
      now = getLastWeeksDate();

      function getLastWeeksDate() {
        const now = new Date();

        return new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
      }

      function getLast30Date() {
        const now = new Date();

        return new Date(now.getFullYear(), now.getMonth(), now.getDate() - 30);
      }
      function getLastmonthDate() {
        const now = new Date();

        return new Date(now.getFullYear(), now.getMonth() - 1, now.getDate());
      }
      function getLast6monthDate() {
        const now = new Date();

        return new Date(now.getFullYear(), now.getMonth() - 6, now.getDate());
      }
      function get2022Date() {
        const now = new Date();

        return new Date(now.getFullYear() - 1, 0, 1);
      }
      function get2021Date() {
        const now = new Date();

        return new Date(now.getFullYear() - 2, 0, 1);
      }
      function get2020Date() {
        const now = new Date();

        return new Date(now.getFullYear() - 3, 0, 1);
      }
      function get2019Date() {
        const now = new Date();

        return new Date(now.getFullYear() - 4, 0, 1);
      }
      function get2018Date() {
        const now = new Date();

        return new Date(now.getFullYear() - 5, 0, 1);
      }
      function get2017Date() {
        const now = new Date();

        return new Date(now.getFullYear() - 6, 0, 1);
      }
      if (value === "1") {
        console.log("here 1");
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            from: `${newDate.getFullYear()}-${String(
              newDate.getMonth() + 1
            ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
            to: `${newDate.getFullYear()}-${String(
              newDate.getMonth() + 1
            ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
          };
        });
      }

      if (value === "2") {
        console.log("here 2");

        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
              2,
              "0"
            )}-${String(now.getDate()).padStart(2, "0")}`,
            to: `${newDate.getFullYear()}-${String(
              newDate.getMonth() + 1
            ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
          };
        });
      }

      if (value === "3") {
        console.log("here 3");
        now = getLast30Date();
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
              2,
              "0"
            )}-${String(now.getDate()).padStart(2, "0")}`,
            to: `${newDate.getFullYear()}-${String(
              newDate.getMonth() + 1
            ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
          };
        });
      }
      if (value === "4") {
        console.log("here 4");
        now = getLast6monthDate();
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
              2,
              "0"
            )}-${String(now.getDate()).padStart(2, "0")}`,
            to: `${newDate.getFullYear()}-${String(
              newDate.getMonth() + 1
            ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
          };
        });
      }
      if (value === "5") {
        console.log("here 5");
        now = get2022Date();
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
              2,
              "0"
            )}-${String(now.getDate()).padStart(2, "0")}`,
            to: `${newDate.getFullYear()}-${String(
              newDate.getMonth() + 1
            ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
          };
        });
      }
      if (value === "6") {
        // console.log('here 5');
        now = get2021Date();
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
              2,
              "0"
            )}-${String(now.getDate()).padStart(2, "0")}`,
            to: `${newDate.getFullYear()}-${String(
              newDate.getMonth() + 1
            ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
          };
        });
      }
      if (value === "7") {
        // console.log('here 5');
        now = get2020Date();
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
              2,
              "0"
            )}-${String(now.getDate()).padStart(2, "0")}`,
            to: `${newDate.getFullYear()}-${String(
              newDate.getMonth() + 1
            ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
          };
        });
      }
      if (value === "8") {
        // console.log('here 5');
        now = get2019Date();
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
              2,
              "0"
            )}-${String(now.getDate()).padStart(2, "0")}`,
            to: `${newDate.getFullYear()}-${String(
              newDate.getMonth() + 1
            ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
          };
        });
      }
      if (value === "9") {
        console.log("here 5");
        now = get2018Date();
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
              2,
              "0"
            )}-${String(now.getDate()).padStart(2, "0")}`,
            to: `${newDate.getFullYear()}-${String(
              newDate.getMonth() + 1
            ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
          };
        });
      }

      if (value === "10") {
        // console.log('here 5');
        now = get2017Date();
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
              2,
              "0"
            )}-${String(now.getDate()).padStart(2, "0")}`,
            to: `${newDate.getFullYear()}-${String(
              newDate.getMonth() + 1
            ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
          };
        });
      }
    }
    console.log(formData);
  }
  const observer = React.useRef();
  const markerRef = React.useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && emptyFetch === false) {
          console.log("intersect");
          setStartIndex((prevStartIndex) => prevStartIndex + 18);
        }
      });
      if (node) observer.current.observe(node);
    },
    [setStartIndex, emptyFetch]
  );

  React.useEffect(() => {
    if (startIndex !== 0) {
      getAllIncomingTransaction(startIndex, true);
      getAllOutgoingTransaction(startIndex,true)
      console.log(startIndex);
    }

    // allServicesubService();
  }, [startIndex]);

  // async function GetAllMyOrdersIncoming() {
  //   const response = await myProfileServiceApi.OfflineShowIncoming(
  //     Userprofile.service_provider_id,
  //     formData.from,
  //     formData.to
  //   );
  //   console.log(response);

  //   if (response.data && response.data.error != "") {
  //     notify("error", response.data.error);
  //   } else {
  //     // setMyOrders(response.data);
  //     console.log(response.data);
  //     // const keys = Object.keys(response.data);
  //     // setMyObjectKeyArray(keys);
  //   }
  // }
  // async function GetAllMyOrdersOutgoing() {
  //   const response = await myProfileServiceApi.OfflineShowOutGoing(
  //     Userprofile.service_provider_id,
  //     formData.from,
  //     formData.to
  //   );
  //   console.log("Outgoing : ", response);

  //   if (response.data && response.data.error != "") {
  //     notify("error", response.data.error);
  //   } else {
  //     // setMyOrders2(response.data);
  //     console.log(response.data);
  //     // const keys = Object.keys(response.data);
  //     // setMyObjectKeyArray(keys);
  //   }
  // }
  async function deleteorderbill(order_id) {
    const response = await myProfileServiceApi.deleteOrderBill(
      Userprofile.service_provider_id,
      order_id
    );
    console.log("Outgoing delete: ", response);

    if (response.data && response.data.error != "") {
      notify("error", response.data.error);
    } else {
      console.log(response.data);

      getAllIncomingTransaction(0);
      getAllOutgoingTransaction();
      notify("success", "Bill Deleted Succesfully");
    }
  }
  // React.useEffect(() => {
  //   GetAllMyOrdersIncoming();
  //   GetAllMyOrdersOutgoing();
  // }, [formData.to, formData.from]);

  // Fetch product transactions data from an API
  React.useEffect(() => {
    async function fetchproductTranslist() {
      const response = await myProfileServiceApi.fetchProductTranscationlist(Userprofile.service_provider_id);
      if (response.data && response.data.error != "") {
        notify("error", response.data.error);
      } else {
        setProductTransactions(response.data);
      }
    }

    fetchproductTranslist();

  }, []);
  const openProductTransactions = () => {

    setIsModalprodtransOpen(true);
    setTimeout(() => { setIsOpendropdown(!isOpendropdown) }, 1000)

  };
  const closeProductTransactions = () => {
    setIsModalprodtransOpen(false);
  };

  //for transaction summary
  const openTransactionsSummary = () => {
    setPage(3);
    setSelectedProductsummary({
      prodtypename: "All Products Type",
    prodtypeid: 0,
    prodname: "All Products ",
    quantityname: "All Quantity",
    quantityid: 0,
    prodid: 0,
    userid: "",
    user: ""
    })
  }

  const openTransactionSummaryModal = () => {

    setIsModaltranssummaryOpen(true);
  }

  const closeTransactionSummaryModal = () => {
    setIsModaltranssummaryOpen(false);

  }


  const handleSelectProdtrans = () => {
    // Handle the selection logic here
    console.log(selectedTransaction);
    getAllOutgoingTransaction(0);
    getAllIncomingTransaction(0);
    setTimeout(() => { closeProductTransactions() }, 1000)

  };
  // const sp_id = Userprofile.service_provider_id;
  let start_date = formData.from;
  let end_date = formData.to;
  const user_id = Userprofile.user_id;
  const product_type_id = Userprofile.product_type_id;
  const sub_service_id = Userprofile.sub_service_id;

  const getAllIncomingTransaction = async (startIndex, isClone = false) => {
    try {
      setLoading(true);
      let res;
      const selectedServiceID = userServiceIDselected ? userServiceIDselected[0] : null;
      console.log(props.existingdate);
        // Check if props.existingdate is not empty
if (props.existingdate && props.existingdate.from && props.existingdate.to) {
  start_date = props.existingdate.from;
  end_date = props.existingdate.to;
  console.log(start_date,end_date);
}

      if (props.userId && selectedTransaction) {
        res = await myProfileServiceApi.OfflineIncomingTransaction(
          selectedServiceID, start_date,
          end_date,
          sp_id,
          props.userId,
          selectedTransaction,
          isClone ? startIndex : 0  // Use startIndex only if it's a clone
        );
      } else if (props.userId) {
        res = await myProfileServiceApi.OfflineIncomingTransaction(
          selectedServiceID, start_date,
          end_date,
          sp_id,
          props.userId,
          isClone ? startIndex : 0  // Use startIndex only if it's a clone
        );
      } else if (!props.userId && selectedTransaction) {
        res = await myProfileServiceApi.OfflineIncomingTransaction(
          selectedServiceID, start_date,
          end_date,
          sp_id, " ", selectedTransaction, isClone ? startIndex : 0  // Use startIndex only if it's a clone
        );
      } else {
        res = await myProfileServiceApi.OfflineIncomingTransaction(
          selectedServiceID,
          start_date,
          end_date,
          sp_id, isClone ? startIndex : 0  // Use startIndex only if it's a clone
        );
      }

      if (res && res.status !== 200) {
        console.log("Error:", res);
        notify("error", res.status);
      } else if (res.data.Offline_order.length === 0) {
        console.log("Empty");
        setMyOrders((prevTransactions) => [
          ...(isClone ? prevTransactions || [] : []),  // Clear previous transactions if it's not a clone
          ...res.data.Offline_order || [],
        ]);
        setEmptyFetch(true);
      } else {
        console.log("Data:", res.data.Offline_order);
        setMyOrders((prevTransactions) => [
          ...(isClone ? prevTransactions || [] : []),  // Clear previous transactions if it's not a clone
          ...res.data.Offline_order || [],
        ]);
      }
    } catch (error) {
      notify("error", "Error fetching Incoming transaction");
      console.log(error);
    } finally {
      setLoading(false);
    }
  };



  const getAllOutgoingTransaction = async (startIndex, isClone = false) => {
    try {
      setLoading(true);
      let res;
      const selectedServiceID = userServiceIDselected ? userServiceIDselected[0] : null;
        // Check if props.existingdate is not empty
if (props.existingdate && props.existingdate.from && props.existingdate.to) {

  start_date = props.existingdate.from;
  end_date = props.existingdate.to;
  console.log(start_date,end_date);
}
      if (props.userId && selectedTransaction) {
        res = await myProfileServiceApi.OfflineOutgoingTransaction(
          selectedServiceID,
          start_date,
          end_date,
          sp_id,
          props.userId,
          selectedTransaction
,     isClone ? startIndex : 0 
        );

      }
      else if (props.userId) {
        res = await myProfileServiceApi.OfflineOutgoingTransaction(
          selectedServiceID,
          start_date,
          end_date,
          sp_id,
          props.userId,     isClone ? startIndex : 0 
        );
      }
      else if (!props.userId && selectedTransaction) {
        res = await myProfileServiceApi.OfflineOutgoingTransaction(
          selectedServiceID,
          start_date,
          end_date,
          sp_id,
          " ",
          selectedTransaction,     isClone ? startIndex : 0 

        );

      }
      else {
        res = await myProfileServiceApi.OfflineOutgoingTransaction(
          selectedServiceID,
          start_date,
          end_date,
          sp_id,     isClone ? startIndex : 0 

        );
      }
      // setMyOrders2(res.data);
      // setLoading(false);

      if (res && res.status !== 200) {
        console.log("Error:", res);
        notify("error", res.status);
      } else if (res.data.Offline_order.length === 0) {
        console.log("Empty");
        setMyOrders2((prevTransactions) => [
          ...(isClone ? prevTransactions || [] : []),  // Clear previous transactions if it's not a clone
          ...res.data.Offline_order || [],
        ]);
        setEmptyFetch(true);
      } else {
        console.log("Data:", res.data.Offline_order);
        setMyOrders2((prevTransactions) => [
          ...(isClone ? prevTransactions || [] : []),  // Clear previous transactions if it's not a clone
          ...res.data.Offline_order || [],
        ]);
      }
    } catch (error) {
      notify("error", "Error fetching Outgoing transction");
      console.log(error);
    }
  };
  const getAllIncomingAmount = async () => {
    try {
      if (selectedProductsummary) {
        const res = await myProfileServiceApi.ShowOfflineIncomingamount(
          sp_id,
          start_date,
          end_date,
          selectedProductsummary
        );
        console.log(res.data);
        setSummarydata(res.data);
      }

    } catch (error) {
      notify("error", "Error fetching incoming amount");
      console.log(error);
    }
  };

  const getAllOutgoingAmount = async () => {
    try {
      if (selectedProductsummary) {
        const res = await myProfileServiceApi.ShowOfflineOutgoingamount(
          sp_id,
          start_date,
          end_date,
          selectedProductsummary
        );
        console.log(res.data);
        setSummarydata2(res.data);
      }

    } catch (error) {
      notify("error", "Error fetching incoming amount");
      console.log(error);
    }
  };
  React.useEffect(() => {
    if (page === 3) { //if transaction summary page is viewed

    } else {
      getAllIncomingTransaction(0);
    }

    

  }, [formData.from, formData.to]);
  let isLastItem;
  React.useEffect(() => {
    if (page === 3) {

    } else {
      getAllOutgoingTransaction(0);
    }
  }, [formData.from, formData.to]);

  React.useEffect(() => {
    return () => {
      if (props.userId) {
        props.onUserIdChange(" ", null," "); // Reset the user_id when unmounting the component

      }
    };
  }, []);

React.useEffect(()=>{
  if (props.existingdate && props.existingdate.from && props.existingdate.to) {

    setFormData(prevData => ({
      ...prevData,
      from: props.existingdate.from,
      to: props.existingdate.to
    }));
    setPreviousFormData(prevData => ({
      ...prevData,
      from: props.existingdate.from,
      to: props.existingdate.to
    }))
 
    console.log(start_date,end_date);
  }
},[props.userId])
  const calculatetransaction = () => {
    getAllIncomingAmount();
    getAllOutgoingAmount();
  }
  const makeload = () => {
    // GetAllMyOrdersIncoming();
    // GetAllMyOrdersOutgoing();
  };

  const [myObjectKeyArray, setMyObjectKeyArray] = React.useState([]);

  const goBack = () => {
    props.return();
    props.onUserIdChange("", null,"");
    setInnerPage(false);
  };

  const goBack2 = () => {
    setFormData(previousFormData);
    setSummarydata("");
    setSummarydata2("");
    setPage(selectedpageref.current)
  };


  const [billData, setBillData] = React.useState();

  const exitDetail = () => {
    setPage(selectedpageref.current)
  };

  return (
    <React.Fragment>
      <>
        <div className="my-service-profile-ongoing-and-pending-online">
          {page !== 4 && page !== 3 ? (
            <>

              <div className="my-online-ongoing-pending-profile-time-filter-calander-box">
                <div className="my-service-profile-ongoing-and-pending-online-back">
                  <img src={back} onClick={goBack} alt="" />
                </div>
                <div className="my-order-profile-time-filter-calander-1">
                  <span>From</span>
                  <input
                    type="date"
                    onChange={handleChange}
                    name="from"
                    value={formData.from}
                  ></input>
                </div>
                <div className="my-order-profile-time-filter-calander-1">
                  <span>Till</span>
                  <input
                    type="date"
                    onChange={handleChange}
                    name="to"
                    value={formData.to}
                  ></input>
                </div>
                <div className="my-order-profile-time-filter-calander-3">
                  <span>Sort By : </span>
                  <select onChange={handleChange} name="editFrom" > 
                    <option value={0}>Time Filter</option>
                    <option value={1}>Today</option>
                    <option value={2}>Last One Week</option>
                    <option value={3}>Last 30 Days</option>
                    <option value={4}>Last 6 Months</option>
                    <option value={5}>2022</option>
                    <option value={6}>2021</option>
                    <option value={7}>2020</option>
                    <option value={8}>2019</option>
                    <option value={9}>2018</option>
                    <option value={10}>2017</option>
                  </select>
                  <div className="my-order-profile-dropdown my-order-profile-dropdown1">

                    <div className="ellipsis" onClick={toggleDropdown}>
                      <div className="dot"></div>
                      <div className="dot"></div>
                      <div className="dot"></div>
                    </div>
                  </div>
                  {isOpendropdown && (
                    <div className="dropdown-content">
                      <ul className="transcaaaation">
                        <li onClick={openProductTransactions}>Product Transactions</li>
                        <li onClick={openTransactionsSummary}>Transactions Summary</li>

                      </ul>
                    </div>
                  )}
                </div>
              </div>



              {isModalprodtransOpen && (
                <ProductTransactionsModal
                  isOpen={isModalprodtransOpen}
                  onClose={closeProductTransactions}
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                  productTransactions={productTransactions}
                  handleSelect={handleSelectProdtrans}
                  setSelectedTransaction={setSelectedTransaction}
                  selectedTransaction={selectedTransaction}
                />
              )}



              <div className="my-service-profile-ongoing-and-pending-online-selector">
                <div className="my-service-profile-ongoing-and-pending-online-selector-tab">
                  <motion.span
                    onClick={() => {
                      setPage(1);
                    }}
                    animate={{
                      color: page === 1 ? "black" : "rgba(0, 0, 0, 0.512)",
                      "border-bottom":
                        page === 1 ? "3px solid #1D8B97" : "none",
                    }}
                    whileHover={{ color: "black" }}
                  >
                    Incoming
                  </motion.span>
                  <motion.span
                    onClick={() => {
                      setPage(2);
                    }}
                    animate={{
                      color: page === 2 ? "black" : "rgba(0, 0, 0, 0.512)",
                      "border-bottom":
                        page === 2 ? "3px solid #1D8B97" : "none",
                    }}
                    whileHover={{ color: "black" }}
                  >
                    Outgoing
                  </motion.span>
                  {(props.userName !== null && props.userName !== " " && props.userName !== undefined) && <div className="offline-customer-name"> Customer Name:- {props.userName}</div>}
                  {(selectedTransaction && selectedTransaction.prodtypeid != "") && <div className="offline-customer-product-type"> {selectedTransaction.prodtypename}</div>}
                  {(selectedTransaction && selectedTransaction.prodid != "") && <div className="offline-customer-product-type"> {selectedTransaction.prodname}</div>}
                  {(selectedTransaction && selectedTransaction.quantityid != "") && <div className="offline-customer-product-type"> {selectedTransaction.quantityname}</div>}
                </div>
                {page === 1 ? (
                  <>
                    <div className="my-service-profile-ongoing-and-pending-online-box">

                      {myOrders && myOrders.length > 0 ? (
                        myOrders.map((item, index) => {
                           isLastItem = index === myOrders.length - 1;
                          return (
                            <React.Fragment key={index}>
                              {!isMobileView ? (
                                <div className="my-bills-profile-page-view-main-right-main-box-card" ref={isLastItem ? markerRef : null}>
                                  {/* <div className='my-bills-profile-page-view-main-right-main-box-card-img'>
                                  <img></img>
                                </div> */}
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-provider">
                                    {item.customer_details.name}
                                  </div>
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-des">
                                    <span>Order Id : {item.order_id}</span>
                                    {/* <span>{item.datetime.slice(5, 16)}</span> */}
                                    <span>
                                      Mobile : {item.customer_details.mobile_no}
                                    </span>
                                  </div>
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-time">
                                    <span>
                                      Order type : {item.current_status}
                                    </span>
                                    {/* <button>Complete Order</button> */}
                                  </div>
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-des">
                                    {item.datetime.slice(0, 16)}
                                  </div>
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-price">
                                    <span>&#8377;{item.amt}</span>
                                    <div className="buttons-container">
                                      <button
                                        onClick={() => {
                                          setPage(4);
                                          setBillData(item);
                                          // selectedpageref=page;
                                        }}
                                      >
                                        View Items
                                      </button>
                                      <MdDelete
                                        size={20}
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          deleteorderbill(item.order_id)
                                        }
                                      />
                                    </div>
                                  </div>


                                </div>

                              ) : (
                                <div className="my-bills-profile-page-view-main-right-main-box-card-mobileview">
                                  {/* <div className='my-bills-profile-page-view-main-right-main-box-card-img'>
                                  <img></img>
                                </div> */}
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-provider">
                                    <span
                                      style={{
                                        fontWeight: "normal",
                                        color: "rgba(0, 0, 0, 0.476)",
                                      }}
                                    >
                                      User Name:
                                    </span>
                                    {" " + item.customer_details.name}
                                  </div>
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-des">
                                    <span>Order Id : {item.order_id}</span>
                                    {/* <span>{item.datetime.slice(5, 16)}</span> */}
                                    <span>
                                      Mobile : {item.customer_details.mobile_no}
                                    </span>
                                  </div>
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-time">
                                    <span>
                                      <span
                                        style={{
                                          fontWeight: "normal",
                                          color: "rgba(0, 0, 0, 0.476)",
                                        }}
                                      >
                                        Order type :
                                      </span>{" "}
                                      {item.current_status}
                                    </span>
                                    {/* <button>Complete Order</button> */}
                                  </div>
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-des">
                                    Order Date : {item.datetime.slice(0, 16)}
                                  </div>
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-price">
                                    <div>
                                      {" "}
                                      <span
                                        style={{
                                          fontWeight: "normal",
                                          color: "rgba(0, 0, 0, 0.476)",
                                        }}
                                      >
                                        Amount:
                                      </span>{" "}
                                      &#8377;{item.amt}
                                    </div>
                                    <div className="buttons-container">
                                      <button
                                        onClick={() => {
                                          setPage(4);
                                          setBillData(item);
                                          // selectedpageref=page;
                                        }}
                                      >
                                        View Items
                                      </button>
                                      <MdDelete
                                        size={20}
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          deleteorderbill(item.order_id)
                                        }
                                      />
                                    </div>
                                  </div>

                                </div>
                              )}
                            </React.Fragment>
                          );
                        })
                      ) : (
                        <div className="NoShowCenter loadingss" style={{ minHeight: "200px" }}
                        >
                          {loading ? <Loading /> : "nothing to show..."}
                        </div>
                      )}
      {myOrders && myOrders.length>0  && (
                                      <div className="marker" ref={markerRef} />
                                    )}
                    </div>
                  </>
                ) : page === 2 ? (
                  <>
                    <div className="my-service-profile-ongoing-and-pending-online-box">
                      {myOrders2 && myOrders2.length !== 0 ? (
                        myOrders2.map((item) => {
                          return (
                            <>
                              {!isMobileView ? (
                                <div className="my-bills-profile-page-view-main-right-main-box-card">
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-provider">
                                    {item.customer_details.name}
                                  </div>
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-des">
                                    <span>Order Id : {item.order_id}</span>
                                    {/* <span>{item.datetime.slice(5, 16)}</span> */}
                                    <span>
                                      Mobile : {item.customer_details.mobile_no}
                                    </span>
                                  </div>
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-time">
                                    <span>Status : {item.current_status}</span>
                                  </div>
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-des">
                                    {item.datetime.slice(0, 16)}
                                  </div>
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-price">
                                    <span>&#8377;{item.amt}</span>
                                    <div className="buttons-container">
                                      <button
                                        onClick={() => {
                                          setPage(4);
                                          setBillData(item);
                                          // selectedpageref=page;
                                        }}
                                      >
                                        View Items
                                      </button>
                                      <MdDelete
                                        size={20}
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          deleteorderbill(item.order_id)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className="my-bills-profile-page-view-main-right-main-box-card-mobileview">
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-provider">
                                    <span
                                      style={{
                                        fontWeight: "normal",
                                        color: "rgba(0, 0, 0, 0.476)",
                                      }}
                                    >
                                      User Name:
                                    </span>
                                    {" " + item.customer_details.name}
                                  </div>
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-des">
                                    <span>Order Id : {item.order_id}</span>
                                    {/* <span>{item.datetime.slice(5, 16)}</span> */}
                                    <span>
                                      Mobile : {item.customer_details.mobile_no}
                                    </span>
                                  </div>
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-time">
                                    <span>
                                      <span
                                        style={{
                                          fontWeight: "normal",
                                          color: "rgba(0, 0, 0, 0.476)",
                                        }}
                                      >
                                        Order type :
                                      </span>{" "}
                                      {item.current_status}
                                    </span>
                                    {/* <button>Complete Order</button> */}
                                  </div>
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-des">
                                    Order Date :{item.datetime.slice(0, 16)}
                                  </div>
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-price">
                                    <div>
                                      {" "}
                                      <span
                                        style={{
                                          fontWeight: "normal",
                                          color: "rgba(0, 0, 0, 0.476)",
                                        }}
                                      >
                                        Amount:
                                      </span>{" "}
                                      &#8377;{item.amt}
                                    </div>
                                    <div className="buttons-container">
                                      <button
                                        onClick={() => {
                                          setPage(4);
                                          setBillData(item);
                                          // selectedpageref=page;
                                        }}
                                      >
                                        View Items
                                      </button>
                                      <MdDelete
                                        size={20}
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          deleteorderbill(item.order_id)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </>
                          );
                        })
                      ) : (
                        <div className="NoShowCenter loadingss" style={{ minHeight: "200px" }}
                        >
                          {loading ? <Loading /> : "nothing to show..."}
                        </div>
                      )

                      }
                      {myOrders && myOrders.length>0  && (
                                      <div className="marker" ref={markerRef} />
                                    )}
                    </div>
                    {/* </div> */}
                  </>
                ) : <>
                  <div className="my-service-profile-ongoing-and-pending-online-box">
                    {myOrders &&
                      myOrders.pending_orders &&
                      myOrders.pending_orders.map((item) => {
                        return (
                          <>
                            <div className="my-bills-profile-page-view-main-right-main-box-card">
                              {/* <div className='my-bills-profile-page-view-main-right-main-box-card-img'>
                                  <img></img>
                                </div> */}
                              <div className="my-bills-profile-page-view-main-right-main-box-card-provider">
                                {item.customer_details.name}
                              </div>
                              <div className="my-bills-profile-page-view-main-right-main-box-card-des">
                                <span>Order Id : {item.order_id}</span>
                                {/* <span>{item.datetime.slice(5, 16)}</span> */}
                                <span>
                                  Mobile : {item.customer_details.mobile_no}
                                </span>
                              </div>
                              <div className="my-bills-profile-page-view-main-right-main-box-card-time">
                                <span>
                                  Order type : {item.current_status}
                                </span>
                                {/* <button>Complete Order</button> */}
                              </div>
                              <div className="my-bills-profile-page-view-main-right-main-box-card-des">
                                {item.datetime.slice(0, 16)}
                              </div>
                              <div className="my-bills-profile-page-view-main-right-main-box-card-price">
                                <span>&#8377;{item.amt}</span>
                                <div className="buttons-container">
                                  <button
                                    onClick={() => {
                                      setPage(4);
                                      setBillData(item);
                                      // selectedpageref=page;
                                    }}
                                  >
                                    View Items
                                  </button>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                  </div>
                </>}
              </div>
            </>
          ) :
            (page !== 4 && page === 3) ? (

              <div className="my-service-profile-ongoing-and-pending-online">
                <div className="my-online-ongoing-pending-profile-time-filter-calander-box">
                  <div className="my-service-profile-ongoing-and-pending-online-back">
                    <img src={back} onClick={goBack2} alt="" />
                  </div>
                  <div className="my-order-profile-time-filter-calander-1">
                    <span>From</span>
                    <input
                      type="date"
                      onChange={handleChange}
                      name="from"
                      value={formData.from}
                    ></input>
                  </div>
                  <div className="my-order-profile-time-filter-calander-1">
                    <span>Till</span>
                    <input
                      type="date"
                      onChange={handleChange}
                      name="to"
                      value={formData.to}
                    ></input>
                  </div>
                  <div className="my-order-profile-time-filter-calander-3">
                    <span>Sort By : </span>
                    <select onChange={handleChange} name="editFrom">
                      <option value={0}>Time Filter</option>
                      <option value={1}>Today</option>
                      <option value={2}>Last One Week</option>
                      <option value={3}>Last 30 Days</option>
                      <option value={4}>Last 6 Months</option>
                      <option value={5}>2022</option>
                      <option value={6}>2021</option>
                      <option value={7}>2020</option>
                      <option value={8}>2019</option>
                      <option value={9}>2018</option>
                      <option value={10}>2017</option>
                    </select>

                  </div>
                </div>
                <div className="transaction-summary-container-inner">

                  <div className="transaction-summary-box">

                    <label>Select the Product Type</label>

                    <div onClick={() => { setPagefortrans(0); openTransactionSummaryModal(); }}>
                      <div className="transacation-summary-product-selected">{selectedProductsummary && selectedProductsummary.prodtypename !== "All Products Type" ? selectedProductsummary.prodtypename : 'All Products Type'}  </div>
                    </div>

                  </div>
                  <div className="transaction-summary-box">

                    <label>Select the Product Name</label>

                    <div onClick={() => {
                      setPagefortrans(1);
                      openTransactionSummaryModal();
                    }}>
                      <div className="transacation-summary-product-selected">{selectedProductsummary && selectedProductsummary.prodname !== "" ? selectedProductsummary.prodname : 'Enter product name'}  </div>
                    </div>
                  </div>
                  <div className="transaction-summary-box">

                    <label>Select the Quantity Name</label>

                    <div onClick={() => {
                      setPagefortrans(2);
                      openTransactionSummaryModal();
                    }}>
                      <div className="transacation-summary-product-selected">{selectedProductsummary && selectedProductsummary.quantityname !== "" ? selectedProductsummary.quantityname : 'Enter Quantity name'}  </div>
                    </div>

                  </div>
                  <div className="transaction-summary-box">
                    <label>Search Users</label>
                    <div onClick={() => {
                      setPagefortrans(3);
                      openTransactionSummaryModal();
                    }}>
                      <div className="transacation-summary-product-selected">{selectedProductsummary && selectedProductsummary.user !== "" ? selectedProductsummary.user : 'All Users'}  </div>
                    </div>
                  </div>

                  <button onClick={calculatetransaction}>Calculate Transaction</button>

                  {summarydata !== "" &&
                    <div className="summary-transacation-incoming-outgoing">
                      <div className="summary-transacation-incoming-outgoing-header">
                        <h1> Summary</h1>
                      </div>
                      <div className="summary-transacation-incoming-outgoing-details">
                        <span>Service Provider Name : {Userprofile && Userprofile.first_name + " " + Userprofile.last_name}</span>
                        <span>Total Incoming Amount : {summarydata && summarydata.total_amount}</span>
                        <span>Total Incoming unit : {summarydata && summarydata.incoming_unit}</span>
                        <span>Total Outgoing Amount : {summarydata2 && summarydata2.total_amount}</span>
                        <span>Total Outgoing unit : {summarydata2 && summarydata2.outgoing_unit}</span>
                        <span>Total left unit : {summarydata2 && summarydata.incoming_unit - summarydata2.outgoing_unit}</span>
                        <span> {
                          summarydata2 &&
                            (summarydata.total_amount - summarydata2.total_amount) > 0 ?
                            `Profit : ${summarydata.total_amount - summarydata2.total_amount}` :
                            (summarydata.total_amount - summarydata2.total_amount) < 0 ?
                              `Loss : ${-(summarydata.total_amount - summarydata2.total_amount)}` :
                              null
                        }</span>
                      </div>
                    </div>
                  }

                </div>
                {isModaltranssummaryOpen && (
                  <TransactionSummary
                    isOpen={isModaltranssummaryOpen}
                    onClose={closeTransactionSummaryModal}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    productTransactions={productTransactions}
                    setSelectedTransaction={setSelectedProductsummary}
                    selectedTransaction={selectedProductsummary}

                    Userprofile={Userprofile} pagefortrans={pagefortrans}
                  />
                )}

              </div>

            )
              : (

                <div className="my-bills-profile-page-view-main-right">
                  <BillDetailsView
                    data={billData}
                    isMobileView={isMobileView}
                    return={exitDetail}
                    apiaftercall={()=>{
              getAllOutgoingTransaction(0);
              getAllIncomingTransaction(0);
              exitDetail();
                    }}
                  ></BillDetailsView>
                </div>
              )}


        </div>

      </>
    </React.Fragment >
  );
}

function BillDetailsView(props) {
  const [isDropdownVisible, setDropdownVisible] = React.useState(false);
  const [edditbill,setEdditbill]=React.useState(false);
  const {Userprofile} =useGlobalContext();
  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };


  return (
    <React.Fragment>
      <>
        <div className="my-bills-profile-page-view-main-right-data">
          {/* <div className='my-service-profile-ongoing-and-pending-online-back'></div> */}
          <div className='my-bills-profile-page-view-main-right-heading-data my-bills-profile-page-view-main-right-data-bill-option'>
            <div>
              <img src={back} onClick={props.return} className='bacckbutton'></img>
              <span className="mobilefont">View Details</span>
            </div>

            <div className="my-order-profile-dropdown">

              <div className="ellipsis" onClick={toggleDropdown} >
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>
            </div>
          </div>
          <div className="my-bills-profile-page-view-main-right-data-box">
            {props &&
              props.data &&
              props.data.items &&
              props.data.items.length > 0 &&
              props.data.items.map((item) => {
                return (
                  <div className="my-bills-profile-page-view-main-right-data-box-card">
                    <div className="my-bills-profile-page-view-main-right-data-box-card-img">
                      <img src={item.url || logo} alt="itemImage" />
                    </div>
                    <div className="my-bills-profile-page-view-main-right-data-box-card-des">
                      <span>{item.brand}</span>
                      <span>{item.name}</span>
                      <span>{item.product_type}</span>
                      {!props.isMobileView && (   <span>{item.product_quantity}</span>)}
                       {/* Show qty, unit, and color in mobile view */}
 {props.isMobileView && (
 <div className='my-bills-profile-page-view-main-right-data-box-card-mobile-info'>
  <span>{item.product_quantity}</span> 
      <span> | {item.quantity} Units </span>
      
      {item.color && item.color.length > 0 && (
        <div className='my-bills-profile-page-view-main-right-data-box-card-color'>
         | <div
            className="initial-choice service-color-choice"
            style={{
              backgroundColor: item.color[0].color_code,
            }}
          >
          </div>{" "}
          {item.color[0].color_name}
        </div>
      )}
    </div>)}
                    </div>
                    { !props.isMobileView && item.color && item.color.length > 0 && <div className='my-bills-profile-page-view-main-right-data-box-card-color'>
                    <div className="initial-choice service-color-choice" style={{
                  backgroundColor:
                    item.color[0].color_code,
                }}
                >
                </div> {item.color[0].color_name}
                    </div>}
                    {!props.isMobileView && <div className="my-bills-profile-page-view-main-right-data-box-card-qty">
                      Units : {item.quantity}
                    </div>}
                    <div className="my-bills-profile-page-view-main-right-data-box-card-price">
                      <span>&#8377; {item.price}</span>
                    </div>
                  </div>
                );
              })}
          </div>
          {isDropdownVisible && (
            <div className="dropdown-content" id="onlyforview">
                 <Link to={`/viewpdf/offline/${props.data.order_id}/`} target="_blank"><button >View Bill</button></Link>
                 <button onClick={()=>{
                  setEdditbill(true);
                 }}>Edit Bill</button>
            </div>
          )}

{edditbill && <Ediit selectedItem={props.data.items} bookingid={props.data.booking_id} 
onCancel={()=>{
  setEdditbill(false);
}}
sp_id={Userprofile.service_provider_id}
apiaftercall={()=>{
  props.apiaftercall();
}}
></Ediit>
}

        </div>
      </>
    </React.Fragment>
  );
}

function ProductTransactionsModal({ isOpen, onClose, searchQuery, setSearchQuery, productTransactions, handleSelect, setSelectedTransaction, selectedTransaction }) {

  const [showFilteredItems, setShowFilteredItems] = React.useState(false);
  const [showProductsnames, setShowProductsnames] = React.useState(false);//show products name according to selected product type
  const [showProductsquantity, setShowProductquantity] = React.useState(false);
  const [Productsnamesfilter, setProductsnamesfilter] = React.useState([])
  const [Quantitynamesfilter, setQuantitynamesfilter] = React.useState([])
  const handleTransactionClick = (producttrans) => {
    console.log(producttrans);
    const { product_type_name, product_type_id } = producttrans
    setSelectedTransaction(prev => ({
      ...prev,
      prodtypename: product_type_name,
      prodtypeid: product_type_id,
    }));

    setShowProductsnames(true);

  };
  const handleTransactionClick2 = (producttrans) => {
    console.log(producttrans);
    const { product_name, product_id } = producttrans
    setSelectedTransaction(prev => ({
      ...prev,
      prodname: product_name,
      prodid: product_id
    }));
    setShowProductquantity(true);


  };
  const handleTransactionClick3 = (quantity) => {
    console.log(quantity);
    const { qty_name, qty_id } = quantity
    setSelectedTransaction(prev => ({
      ...prev,
      quantityname: qty_name,
      quantityid: qty_id
    }));


  };

  React.useEffect(() => {

    if (selectedTransaction && selectedTransaction.prodtypeid != "") {
      const result = productTransactions.type_details[selectedTransaction.prodtypeid];
      setProductsnamesfilter(result);
    }
    if (Productsnamesfilter && Productsnamesfilter.length > 0 && selectedTransaction && selectedTransaction.prodid != "") {
      const result2 = productTransactions.qty_details[selectedTransaction.prodid];
      setQuantitynamesfilter(result2);
    }
  }, [selectedTransaction]);

  // Function to filter transactions based on search query
  const filterTransactions = () => {
    if (!searchQuery) {
      setShowFilteredItems(false); // Show all items when search query is empty
    } else {
      setShowFilteredItems(true); // Show filtered items when search query is not empty
    }
  };


  const handleBackClick = () => {
    setSelectedTransaction({
      prodtypename: "",
      prodtypeid: "",
      prodname: "",
      quantityname: "",
      quantityid: "",
      prodid: ""
    });
    setShowProductsnames(false);

  }
  const handleBackClick2 = () => {
    setShowProductquantity(false);
  }
  return (
    <div className={`product-transactions-modal ${isOpen ? 'open' : ''}`}>
      <div className="product-transactions-modal-content">


        {showProductsnames && !showProductsquantity ? (
          <>

            {/* Show product names here */}
            <div className="product-transactions-modal-header">
              <h2>Select Product Name</h2>
              <button className="close-button" onClick={handleBackClick}>
                &times;
              </button>
            </div>

            <div className="product-transactions-modal-body">
              {selectedTransaction && (
                <div className="product-transactions-selected-transaction">
                  <span>Selected Product Type :</span>  {selectedTransaction.prodtypename}
                  <br />
                  {selectedTransaction.prodname && <span style={{ marginTop: "10px" }}>Selected Product Name: {selectedTransaction.prodname}</span>}
                </div>
              )}
              <ul className="product-transactions-transaction-list">
                {Productsnamesfilter.length > 0 && Productsnamesfilter.map(product => (

                  <li
                    key={product.product_id}
                    onClick={() => handleTransactionClick2(product)}
                  >
                    {product.product_name}
                  </li>
                ))}

              </ul>
              <div className="product-transactions-modal-footer">
                <button onClick={handleSelect}>Select</button>
                <button onClick={onClose}>Cancel</button>
              </div>
            </div>
          </>
        ) : !showProductsquantity ? (
          <>
            <div className="product-transactions-modal-header">
              <h2>Select Product Type</h2>
              <button className="close-button" onClick={onClose}>
                &times;
              </button>
            </div>

            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyDown={filterTransactions}
            />
            <div className="product-transactions-modal-body">

              <ul className="product-transactions-transaction-list">

                {(showFilteredItems
  ? (productTransactions && productTransactions.producttypes
    ? productTransactions.producttypes.filter(product => product.product_type_name.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1)
    : [])
  : productTransactions && productTransactions.producttypes
)?.map((product) => (
                  <li
                    key={product.product_type_id}
                    onClick={() => handleTransactionClick(product)}
                  >
                    {product.product_type_name}
                  </li>
                ))}

              </ul>
              {selectedTransaction && (
                <div className="product-transactions-selected-transaction">
                  <span>Selected Product :</span>  {selectedTransaction.prodtypename}
                </div>
              )}


              <div className="product-transactions-modal-footer">
                <button onClick={handleSelect}>Select</button>
                <button onClick={onClose}>Cancel</button>
              </div>
            </div>
          </>
        )
          :
          showProductsquantity && <>
            <div className="product-transactions-modal-header">
              <h2>Select Product Quantity</h2>
              <button className="close-button" onClick={handleBackClick2}>
                &times;
              </button>
            </div>


            <div className="product-transactions-modal-body">
              {selectedTransaction && (
                <div className="product-transactions-selected-transaction">
                  <span>Selected Product Type :</span>  {selectedTransaction.prodtypename}
                  <br />
                  {selectedTransaction.prodname && <span style={{ marginTop: "10px" }}>Selected Product Name: {selectedTransaction.prodname}</span>}
                  <br />
                  {selectedTransaction.quantityname && <span style={{ marginTop: "10px" }}>Selected Product Quantity: {selectedTransaction.quantityname}</span>}
                </div>
              )}
              <ul className="product-transactions-transaction-list">
                {
                  Quantitynamesfilter && Quantitynamesfilter.length > 0 && Quantitynamesfilter.map((product) => (
                    <li
                      key={product.qty_id}
                      onClick={() => handleTransactionClick3(product)}
                    >
                      {product.qty_name}
                    </li>
                  ))}
              </ul>


              <div className="product-transactions-modal-footer">
                <button onClick={handleSelect}>Select</button>
                <button onClick={onClose}>Cancel</button>
              </div>
            </div>
          </>
        }
      </div>

    </div>

  );
}


function TransactionSummary({ isOpen, onClose, searchQuery, setSearchQuery, productTransactions, setSelectedTransaction, selectedTransaction, Userprofile, pagefortrans }) {
  const [showFilteredItems, setShowFilteredItems] = React.useState(false);
  const [searchuser, setSearchuser] = React.useState({
    searchname: "",
    searchphone: ""
  });
  const [showcustomername, setShowcustomername] = React.useState([])
  const [Productsnamesfilter, setProductsnamesfilter] = React.useState([])
  const [Quantitynamesfilter, setQuantitynamesfilter] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(false);

  const handleprodTransactionClick = (producttrans) => {
    console.log(producttrans);
    const { product_type_name, product_type_id } = producttrans
    setSelectedTransaction({
      prodtypename: product_type_name,
      prodtypeid: product_type_id,
      quantityname: "",
      quantityid: "",
      prodname: "",
      prodid: "",
      userid: "",
      user: ""

    });

    onClose();



  }

  const handleTransactionClick2 = (producttrans) => {
    console.log(producttrans);
    const { product_name, product_id } = producttrans
    setSelectedTransaction(prev => ({
      ...prev,
      prodname: product_name,
      prodid: product_id
    }));
    onClose();

  };

  const handleTransactionClick3 = (producttrans) => {
    console.log(producttrans);
    const { qty_name, qty_id } = producttrans
    setSelectedTransaction(prev => ({
      ...prev,
      quantityname: qty_name,
      quantityid: qty_id
    }));
    onClose();

  };

  const handleTransactionClick4 = (users) => {
    console.log(users);
    const { user, user_id } = users
    console.log(user, user_id);
    setSelectedTransaction(prev => ({
      ...prev,
      userid: user_id,
      user: user
    }));
    onClose();

  };

  React.useEffect(() => {
    console.log("enter2");
    console.log(selectedTransaction);
    if (selectedTransaction && selectedTransaction.prodtypeid != "") {

      const result2 = productTransactions.type_details[selectedTransaction.prodtypeid];
      setProductsnamesfilter(result2);
      console.log("re");
    }

    if (selectedTransaction && selectedTransaction.prodid != "") {
      console.log("enter");
      let result;
      if (productTransactions.qty_details && productTransactions.qty_details[selectedTransaction.prodid]) {
        result = productTransactions.qty_details[selectedTransaction.prodid];
        console.log(result);
      } else {
        result = [];
      }
      setQuantitynamesfilter(result);
    }


  }, [selectedTransaction, pagefortrans]);

  // Function to filter transactions based on search query
  const filterTransactions = () => {
    if (!searchQuery) {
      setShowFilteredItems(false); // Show all items when search query is empty
    } else {
      setShowFilteredItems(true); // Show filtered items when search query is not empty
    }
  };
  const handleBackClick = () => {
    onClose();

  }



  const handleSearchuserChange = async (e) => {

    const input = e.target.value;
    console.log(input);

    if (!input) {
      setSearchuser({
        searchname: "",
        searchphone: ""
      });
      setShowcustomername([]);
      return;
    } else if (isNaN(input)) {
      setSearchuser({
        searchname: input,
        searchphone: "",
      });
    } else if (input) {
      setSearchuser(prev => ({
        searchname: "",
        searchphone: input
      }));
    }



  };

  React.useEffect(() => {
    let searchTimeout;

    const fetchcustomer = async () => {

      if (
        searchuser &&
        (searchuser.searchname !== "" || searchuser.searchphone !== "")
      ) {
        setIsLoading(true);
        const response = await myProfileServiceApi.fetchusersfortrancation(
          Userprofile.service_provider_id,
          searchuser
        );
        console.log(response);
        console.log(response.data.allCustomer);
        setShowcustomername(response.data.allCustomer);
        setIsLoading(false);
        return;
      } else {
        setSearchuser({
          searchname: "",
          searchphone: "",
        });
      }
      return () => {
        if (searchTimeout) {
          clearTimeout(searchTimeout);
        }
      };
    };
    // Check if both fields are empty or cleared
    if (!searchuser.searchname && !searchuser.searchphone) {

      return;
    }
    // Set a new timeout for making the API call
    searchTimeout = setTimeout(() => {
      if (searchuser.searchname || searchuser.searchphone) {
        fetchcustomer();
      }
    }, 1000);

    // Clear the timeout when the component unmounts
    return () => {
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }
    };
  }, [searchuser.searchname, searchuser.searchphone]);


  const modalRef = useRef();

  React.useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        // Click occurred outside of the modal, close it here
        onClose();
      }
    }

    // Attach the event listener
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);






  return (
    <>
      <div className={`product-transactions-modal ${isOpen ? 'open' : ''}`}>
        <div className="product-transactions-modal-content" ref={modalRef} >

          {pagefortrans === 1 ? (
            <>

              {/* Show product names here */}

              <div className="product-transactions-modal-body">

                <ul className="product-transactions-transaction-list">
                  <li onClick={() => {

                    setSelectedTransaction(prev => ({
                      ...prev,
                      prodname: "All Products",
                      prodid: 0,
                    }));
                    onClose();
                  }}>
                    All Products
                  </li>
                  {Productsnamesfilter.length > 0 && Productsnamesfilter.map(product => (

                    <li
                      key={product.product_id}
                      onClick={() => handleTransactionClick2(product)}
                    >
                      {product.product_name}
                    </li>
                  ))}

                </ul>

              </div>
            </>
          )
            : pagefortrans === 0 ? <>
              <div className="product-transactions-modal-header"> <input
                type="text"
                placeholder="Search..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyDown={filterTransactions}
              />
                <button className="close-button" style={{ marginTop: "-20px", marginLeft: "10px" }} onClick={handleBackClick}>
                  &times;
                </button>
              </div>
              <div className="product-transactions-modal-body">

                <ul className="product-transactions-transaction-list">
                  <li onClick={() => {

                    setSelectedTransaction(prev => ({
                      ...prev,
                      prodtypename: "All Products Type",
                      prodtypeid: 0,
                    }));
                    onClose();
                  }}>
                    All Products Type
                  </li>
                  {(showFilteredItems
                    ? productTransactions.producttypes.filter((product) =>
                      product.product_type_name.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1
                    )
                    : productTransactions.producttypes
                  ).map((product) => (
                    <li
                      key={product.product_type_id}
                      onClick={() => handleprodTransactionClick(product)}
                    >
                      {product.product_type_name}
                    </li>
                  ))}
                </ul>
              </div>
            </> : pagefortrans !== 2 ? (<>

              <div className="product-transactions-modal-body">
                <label >Search Users</label>
                <input type="text" placeholder="Enter name or phone number"
                  value={searchuser.name || searchuser.phone}
                  onChange={handleSearchuserChange}

                />
                {isLoading && <span className="loader-trans"></span>}
                <label >Select User</label>
                <ul className="product-transactions-transaction-list">
                  <li onClick={() => {

                    setSelectedTransaction(prev => ({
                      ...prev,
                      user: "All Users",
                      userid: 0,
                    }));
                    onClose();
                  }}>
                    All Users
                  </li>
                  {showcustomername && showcustomername.length > 0 && showcustomername.map(user => (
                    <li key={user.user_id} onClick={() => handleTransactionClick4(user)}>
                      {user.user + " "}
                      {user.phone}
                    </li>
                  ))}
                </ul>
                <div className="product-transactions-modal-footer">
                  <button onClick={handleBackClick}>ok</button>
                </div>
              </div>
            </>)
              : (<>

                <div className="product-transactions-modal-body">

                  <ul className="product-transactions-transaction-list">
                    <li onClick={() => {

                      setSelectedTransaction(prev => ({
                        ...prev,
                        quantityname: "All Quantity",
                        quantityid: 0
                      }));
                      onClose();
                    }}>
                      All Quantity
                    </li>
                    {Quantitynamesfilter.length > 0 && Quantitynamesfilter.map(product => (

                      <li
                        key={product.qty_id}
                        onClick={() => handleTransactionClick3(product)}
                      >
                        {product.qty_name}
                      </li>
                    ))}

                  </ul>


                </div>
              </>)


          }
        </div>
      </div>
    </>
  )

}


function Ediit({ selectedItem, bookingid, onCancel,sp_id,apiaftercall}){
  const [selectedQuantity, setSelectedQuantity] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const { qty_list } = selectedItem;
  const[clikedok,setClickedok]=useState(false);
  useEffect(()=>{
  const apicall=async ()=>{
   const response = await myProfileServiceApi.deleteeditbill({
    "booking_id":bookingid,
    "product_booking_id":selectedQuantity,
    "sp_id":sp_id
   }
    );
    if (response.data && response.data.error != "") {
     setClickedok(()=>{
     return false;
     });
      notify("error", response.data.error);
    } else {
      apiaftercall();
      onCancel();
      setClickedok(false);
      notify("success", "Product successfully deleted");
    }
  }
  if(clikedok){
  apicall();
  }
  },[clikedok])
  return (
    <>
    <div className="quantity-selection-modal">
      <div className="quantity-selection-modal-content">
        <h5>Select items to remove from the bill</h5>
         <div className="quantity-selection-modal-checkboxes">
          {selectedItem.map((quantityOption, index) => {
            // const { product_id, actual_price, selling_price } = qty_list[index];
            return (
              <>
                <label key={index} className="productlist">
                  <input
                    type="checkbox"
                    value={quantityOption.brand}
                    checked={selectedQuantity.some(
                      (item) =>
                        item === quantityOption.product_booking_id
                    )}
                    onChange={(event) =>{
                      if(event.target.checked){
                        console.log("ss1",event.target.checked);
                        setSelectedQuantity((prevselectedquantity)=>{
                          return[...prevselectedquantity,quantityOption.product_booking_id]
                        })
                      }
                      else{
                        let ab = selectedQuantity.filter((items)=>{
                          return items != quantityOption.product_booking_id;
                        })
                        setSelectedQuantity(()=>{
                        return ab;
                        })
                      }
                    }
                    }
                  />
                  {quantityOption.brand}
                </label>
                {/* {!selectAll && selectedQuantity.some((item) => item.quantityId === quantityOption.id) && (
                  <div className="quantity-selection-modal-colors-container">
                    {quantityOption.color && quantityOption.color.length > 0 && (
                      <div className="quantity-selection-modal-colors">
                        <span>Choose color to delete:</span>
                        {quantityOption.color.map((color, colorIndex) => (
                          <label key={colorIndex}>
                            <input
                              type="checkbox"
                              value={color.color_id}
                              checked={selectedQuantity.some(
                                (item) =>
                                  item.quantityId === quantityOption.id &&
                                  item.colorIds.includes(color.color_id)
                              )}
                              onChange={() =>{ 
                                // toggleColor(quantityOption.id, color.color_id)
                              }}
                            />
                            {color.color_name}

                          </label>


                        ))}

                      </div>
                    )}
                  </div>
                )} */}

              </>
            );
          })}
          {/* <label className="selectAllInput">
            <input
              type="checkbox"
              checked={selectAll}
              onChange={()=>{
              if(selectAll){
              setSelectedQuantity([]);
              setSelectAll(false);
              }
              else{
              setSelectedQuantity(selectedItem.map((items)=>{
              return items.product_booking_id;
              }
              ))
              setSelectAll(true)
              }
              }}
            />
            Select All
          </label> */}
        </div>
        <div className="quantity-selection-modal-buttons">
          <button className="modalok"
            onClick={() =>{
            setClickedok(true);
            }}
          >
            OK
          </button>
          <button className="modalok" onClick={onCancel}>Cancel</button>
        </div>
      </div>
    </div>
    </>
  )
}