import React, { Component } from "react";
import myProfileServiceApi from "../../api/myProfileServiceApi";
import notify from "../../error";
import { useGlobalContext } from "../../context";
import "./css/AllOnlineCustomer.css";
import Loading from "../../components/Loading";

import back from "../myprofileIcoons/bx-arrow-back..svg";

export default function AllOnlineCustomers(props) {
  const [allCustomer, setAllCustomer] = React.useState();
  const { Userprofile,setInnerPage } = useGlobalContext();
  const getOnlineCustomer = async () => {
    const response = await myProfileServiceApi.AllOnlineCustomers(
      Userprofile.service_provider_id
    );
    console.log(response);
    if (response.data && response.data.error != "") {
      notify("error", response.data.error);
    } else {
      setAllCustomer(response.data);
    }
  };

  React.useEffect(() => {
    getOnlineCustomer();
  }, []);

  const goBack = () => {
    props.return();
    setInnerPage(false);
  };

  const [searchString, setSearchString] = React.useState("");
  const handleChange = (e) => {
    setSearchString(e.target.value);
  };
  return (
    <React.Fragment>
      <>
        <div className="my-service-profile-all-online-customer">
          <div className="my-service-profile-ongoing-and-pending-online-back">
            <img src={back} onClick={goBack} alt="" />
          </div>
          <div class="add-service-provider-profile-right-inputs-2">
            <input
              type="text"
              name="customer"
              placeholder="Search Your Customer"
              value={searchString}
              onChange={handleChange}
            />
          </div>
          <div className="my-service-profile-all-online-customer-lower-box">
            {allCustomer && allCustomer.list ? (
              allCustomer.list.length > 0 ? (
                allCustomer.list.map((item) => {
                  console.log(item);
                  if (searchString !== "" && searchString) {
                    console.log(searchString);
                    // Implement search logic here
                    if (
                      item.username
                        .toUpperCase()
                        .indexOf(searchString.toUpperCase()) > -1
                    ) {
                      return (
                        <div className="my-service-profile-all-online-customer-lower-box-box">
                          <div className="my-service-profile-all-online-customer-lower-box-image">
                            <img src={item.profile_image}></img>
                          </div>
                          <div className="my-service-profile-all-online-customer-lower-box-box-bottom-section">
                            <div className="my-service-profile-all-online-customer-lower-box-user">
                              {item.username}
                            </div>
                            <div className="my-service-profile-all-online-customer-lower-box-price">
                              Total Transcation : &#8377; {item.amount}
                            </div>
                          </div>
                        </div>
                      );
                    }
                  } else {
                    return (
                      <div className="my-service-profile-all-online-customer-lower-box-box">
                        <div className="my-service-profile-all-online-customer-lower-box-image">
                          <img src={item.profile_image}></img>
                        </div>
                        <div className="my-service-profile-all-online-customer-lower-box-box-bottom-section">
                          <div className="my-service-profile-all-online-customer-lower-box-user">
                            {item.username}
                          </div>
                          <div className="my-service-profile-all-online-customer-lower-box-price">
                            Total Transcation : &#8377; {item.amount}
                          </div>
                        </div>
                      </div>
                    );
                  }
                })
              ) : (
                <div>No Customers</div>
              )
            ) : (
              <>
                <Loading />
              </>
            )}
          </div>
        </div>
      </>
    </React.Fragment>
  );
}
