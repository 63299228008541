import React, { Component, useEffect, useState } from "react";
import "./css/walletmainPage.css";
import chip from "../myprofileIcoons/EMVChip.svg";
import arrow from "../myprofileIcoons/bx-right-arrow-circle.svg";
import walleticon from "./transaction.png";
import coin from "../myprofileIcoons/coin2.svg";

import addimg from "../myprofileIcoons/hand-card-coin.svg";
import bank from "../myprofileIcoons/bxs-bank.svg";
import gift from "../myprofileIcoons/bxs-gift.svg";
import MyWalletapi from "../../api/MyWalletapi";
import { useNavigate } from "react-router-dom";
import RedemAsCredit from "./RedemAsCredit";
import { useGlobalContext } from "../../context";
import notify from "../../error";
import AddMoney from "./AddMoneyModal";
import SendMoney from "./SendMoneyModal";

export default function MyWalletMainpageView(props) {
  const { loginDetail, Userprofile,setInnerPage } = useGlobalContext();
  const [addMoneyShow, setAddMoneyShow] = React.useState(false);
  const [showRedem, setShowRedem] = React.useState(false);
  const [showSendMoney, setShowSendMoney] = React.useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 480);
  const navigate = useNavigate;
  const [transactionsData, setTransactionsData] = React.useState([
    {
      amount: "100",
      bank_transaction_id: "17674369650",
      "date-time": "Tue, 06 Jul 2021 15:07:31 GMT",
      order_id: "ORDERID_466",
      status: "TXN_SUCCESS",
      transaction_id: "20210530111212800110168621004068436",
    },
    {
      amount: "100",
      bank_transaction_id: "17674369650",
      "date-time": "Tue, 07 Jul 2021 15:07:31 GMT",
      order_id: "ORDERID_466",
      status: "TXN_FAILURE",
      transaction_id: "20210530111212800110168621004068436",
    },
    {
      amount: "100",
      bank_transaction_id: "17674369650",
      "date-time": "Tue, 05 Jul 2021 15:07:31 GMT",
      order_id: "ORDERID_466",
      status: "TXN_SUCCESS",
      transaction_id: "20210530111212800110168621004068436",
    },
    {
      amount: "100",
      bank_transaction_id: "17674369650",
      "date-time": "Tue, 09 Jul 2021 15:07:31 GMT",
      order_id: "ORDERID_466",
      status: "TXN_SUCCESS",
      transaction_id: "20210530111212800110168621004068436",
    },
    {
      amount: "100",
      bank_transaction_id: "17674369650",
      "date-time": "Tue, 10 Jul 2021 15:07:31 GMT",
      order_id: "ORDERID_466",
      status: "TXN_SUCCESS",
      transaction_id: "20210530111212800110168621004068436",
    },
    {
      amount: "100",
      bank_transaction_id: "17674369650",
      "date-time": "Tue, 11 Jul 2021 15:07:31 GMT",
      order_id: "ORDERID_466",
      status: "TXN_SUCCESS",
      transaction_id: "20210530111212800110168621004068436",
    },
    {
      amount: "100",
      bank_transaction_id: "17674369650",
      "date-time": "Tue, 30 Jul 2021 15:07:31 GMT",
      order_id: "ORDERID_466",
      status: "TXN_SUCCESS",
      transaction_id: "20210530111212800110168621004068436",
    },
    {
      amount: "100",
      bank_transaction_id: "17674369650",
      "date-time": "Tue, 1 Jul 2021 15:07:31 GMT",
      order_id: "ORDERID_466",
      status: "TXN_SUCCESS",
      transaction_id: "20210530111212800110168621004068436",
    },
  ]);

  useEffect(() => {
    function handleResize() {
      setIsSmallScreen(window.innerWidth < 480);
    }

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  async function ShowMeRedem() {
    const response3 = await MyWalletapi.CheckIfAdHAAREXIST();
    if (response3.data.error === "") {
      const response2 = await MyWalletapi.ConvertRewardToMoney();
      console.log(response2);
      if (response2.data.error !== "") {
        notify("error", response2.data.error);
      } else {
        notify("success", "Reward Converted To Wallet Money");
      }
    } else {
      setShowRedem(!showRedem);
    }
  }

  const MyWalletTransactionsHistory = async () => {
    const response = await MyWalletapi.MyWalletTransactionHistory();
    console.log(response.data.transactions);
    setTransactionsData(response.data.transactions);
  };

  function ShowAddMoney() {
    setAddMoneyShow(!addMoneyShow);
  }

  function ShowSendMoney() {
    setShowSendMoney(!showSendMoney);
  }

  const WalletBalance_RewardPoints = async () => {
    // const response = await Accountapi.UserProfileDetailsService();
    // console.log(response);
  };

  React.useEffect(() => {
    MyWalletTransactionsHistory();
    // WalletBalance_RewardPoints();
  }, []);

  return (
    <React.Fragment>
      <>
        <div className="profile-wallet-main-page-view">
          {showRedem && <RedemAsCredit close={ShowMeRedem}></RedemAsCredit>}
          {addMoneyShow && <AddMoney close={ShowAddMoney}></AddMoney>}
          {showSendMoney && <SendMoney />}
          <div className="profile-wallet-main-page-heading">
            <span>My Wallet</span>
          </div>
          <div className="profile-wallet-main-page-top">
            <div className="profile-wallet-main-page-top-col1">
              <div className="profile-wallet-main-page-wallet-card">
                <div className="profile-wallet-main-page-wallet-card-col1">
                  <div>
                    Wallet Balance :{" "}
                    <b>
                      &#8377;{"  "}
                      {Userprofile.wallet_balance
                        ? Userprofile.wallet_balance
                        : 0}
                    </b>
                  </div>
                  <div>
                    Reward Points :{" "}
                    {Userprofile.reward_points ? Userprofile.reward_points : 0}
                  </div>
                  <div>
                    <img src={chip} />
                  </div>
                </div>
                <div className="profile-wallet-main-page-wallet-card-col2">
                  <b>Putatoe Pay</b>
                  <div
                    onClick={() => {
                      props.newpage(2);
                      setInnerPage(true);
                    }}
                  >
                    <p>transfer to wallet</p>
                    <img src={arrow} />
                  </div>
                </div>
              </div>
            </div>
            <div className="profile-wallet-main-page-top-col2">
              <div className="profile-wallet-main-page-top-col2-top">
                <div className="profile-wallet-main-page-top-col2-top-Title">
                  Reward Points : {Userprofile.reward_points} <img src={coin} />
                </div>
                <section className="profile-wallet-main-page-top-col2-top-Description">
                  <p>100 reward points=1 putatoe credit</p>
                  <p>1 putatoe credit=1 rupee</p>
                  <p>Can be redeemed to your bank accountabove Rs10000</p>
                </section>
              </div>

              <div className="profile-wallet-main-page-top-col2-bot">
                <div
                  className="profile-wallet-main-page-top-col2-bot-1"
                  onClick={ShowAddMoney}
                >
                  <img src={addimg} />
                  <b>Add</b>Money
                </div>
                <div
                  className="profile-wallet-main-page-top-col2-bot-2"
                  onClick={ShowMeRedem}
                >
                  <img src={gift} />
                  <b>Redeem As</b>Credit
                </div>
                <div
                  className="profile-wallet-main-page-top-col2-bot-3"
                  onClick={ShowSendMoney}
                >
                  <img src={bank} />
                  <b>Send Money</b>To Bank
                </div>
              </div>
            </div>
          </div>

          <div className="profile-wallet-main-page-bot">
            {transactionsData.length > 0 && (
              <>
                <div className="profile-wallet-main-page-bot-heading">
                  Recent Transaction
                </div>
                <div className="profile-wallet-main-page-bot-heading2">
                  These are details about last transaction
                </div>
                <div className="profile-wallet-main-page-bot-table-head">
                  <span>Order Id</span>
                  <span>Amount</span>
                  <span>Date</span>
                  <span>Status</span>
                  <span>Bank Transaction Id</span>
                  <span>Transaction ID</span>
                </div>
              </>
            )}
            <div className="profile-wallet-main-page-bot-table-data">
              {transactionsData.length > 0 ? (
                <>
                  {transactionsData &&
                    transactionsData.slice(0, 6).map((item) => {
                      if (isSmallScreen) {
                        return (
                          <div className="smallscreen-table-data-row smallscreen-table-data-rowwallet smallscreen-table-data-row smallscreen-table-data-rowwalletmobile">
                            <div className="table-data-row">
                              <div className="column">
                                Amount: <span>&#8377;{item.amount}</span>
                              </div>
                              <div className="column">
                                <span>{item["date-time"]}</span>
                              </div>
                            </div>
                            <div className="table-data-row">
                              <div className="column">
                                <span className={`${item.status=== 'Failed' ? 'failedColor' : 'successColor'}`}>{item.status}</span>
                              </div>
                              <div className="column">
                                {" "}
                                <span>{item.order_id}</span>
                              </div>
                            </div>
                            <div className="table-data-row-last">
                              <div className="column">
                                <span>Trans Id:</span>
                                <span>{item.transaction_id}</span>
                              </div>
                            </div>
                          </div>
                        );
                      } else
                        return (
                          <div className="profile-wallet-main-page-bot-table-data-row">
                            <span>{item.order_id}</span>
                            <span>&#8377;{item.amount}</span>
                            <span>{item["date-time"]}</span>
                            {item.status === "TXN_SUCCESS" ||
                            item.status.includes("Success") ? (
                              <span>{item.status}</span>
                            ) : (
                              <div>{item.status}</div>
                            )}
                            <span>
                              {" "}
                              {item.bank_transaction_id ? (
                                item.bank_transaction_id
                              ) : (
                                <>-</>
                              )}
                            </span>
                            <span>{item.transaction_id}</span>
                          </div>
                        );
                    })}
                </>
              ) : (
                <>
                  <div className="profile-wallet-transaction-page-no-transcation">
                    <img src={walleticon} alt="icon" />
                  </div>
                </>
              )}
            </div>

            <div className="profile-wallet-main-page-bot-table-show-more">
              <button
                onClick={() => {
                  props.newpage(1);
                  setInnerPage(true);
                }}
              >
                Show More
              </button>
            </div>
          </div>
        </div>
      </>
    </React.Fragment>
  );
}
