import React, { Component } from "react";
import "./css/history.css";
import mobileApi from "../api/mobileApi";
import notify from "../error";
import Loading from "../components/Loading";
import { useGlobalContext } from "../context";
import emptyoption from "../images/recharge1.svg";
import { useNavigate } from "react-router-dom";
export default function MobileHistory(props) {
  const [history, setHistory] = React.useState([]);
  const { Userprofile, setLoading, loading } = useGlobalContext();
  const wrapper = React.useRef(null);
  OutsideAlerter(wrapper);
  function OutsideAlerter(ref) {
    React.useEffect(() => {
      function handleClickDetailOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          console.log(ref.current);
          props.close();
        }
      }
      document.addEventListener("mousedown", handleClickDetailOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickDetailOutside);
      };
    }, [ref]);
  }

  const getHistory = async () => {
    setLoading(true);
    const response = await mobileApi.ShowRecharge();
    console.log(response);
    if (response.data.error !== "") {
      notify("error", response.data.error);
      props.close();
    }
    setLoading(false);
    setHistory(response.data.my_recharges);
  };
  React.useEffect(() => {
    getHistory();
  }, []);

  return (
    <React.Fragment>
      <>
        <div className="mobile-recharge-plan-detail-model-background">
          <div className="mobile-recharge-history-model" ref={wrapper}>
            <button className="close-icon" onClick={props.close}>
              X
            </button>
            {history && history.length ? (
              history.map((item) => {
                return <EachPiece data={item}></EachPiece>;
              })
            ) : loading ? (
              <Loading />
            ) : (
              <div className="mobile-recharge-history-model-empty">
                <span>No Past Transaction</span>
                <img src={emptyoption}></img>
              </div>
            )}
          </div>
        </div>
      </>
    </React.Fragment>
  );
}

function EachPiece(props) {
  const navigate = useNavigate();
  const { Userprofile, loginDetail } = useGlobalContext();
  const raiseDisputeMine = async (id) => {
    const response = await mobileApi.DisputeRaise(id);
    console.log(response);
    if (response.data.error === "") {
      notify("success", "Dispute Raised succesfuly");
    } else {
      notify("error", response.data.error);
    }
  };

  const moveToCart = async () => {
    try {
      const response = await mobileApi.FetchCircle(props.data.mobile_no);
      console.log(response);

      if (response.data.error !== "") {
        notify("error", "Invalid Number");
      } else {
        navigate("/cart/recharge", {
          replace: true,
          state: {
            op_code: response.data.details.data.op_code,
            serial_no: props.data.mobile_no,
            amount: props.data.amount,
            circle: 2,
            op_image: "",
            text1: `Mobile Number ${props.data.mobile_no}`,
            text2: `Recharge ${response.data.details.data.operator} `,
            planname: "",
          },
        });
      }
    } catch (error) {
      console.error(error);
      notify("error", "An error occurred while fetching data");
    }
  };

  const formattedDate = new Date(props.data.trans_date);
  const formattedTime = formattedDate.toLocaleTimeString("en-US");
  const formattedDateTime = formattedDate.toLocaleString("en-US", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });

  return (
    <React.Fragment>
      <div className="recharge-history-recharge-card">
        <div className="recharge-history-recharge-card-col1">
          <div className="recharge-history-recharge-card-col1-image">
            <img src={Userprofile.profile_image} alt="" />
          </div>
        </div>
        <div className="recharge-history-recharge-card-col2">
          <div className="recharge-history-recharge-card-col2-row1">
            <div>
              {" "}
              <span>{formattedDateTime}</span> - <span>{formattedTime}</span>
            </div>

            <div className="recharge-history--mobile-amount-container">
              <span style={{ "font-weight": "900", color: "black" }}>
                {props.data.mobile_no}
              </span>
              <span className="recharge-history-amount">
                &#8377;{props.data.amount}
              </span>
            </div>
          </div>
          <div className="recharge-history-recharge-card-col2-row2">
            <div>
              Trans ID: <span>{props.data.trans_id}</span>
            </div>
            <div>
              Order ID: <span>{props.data.order_id}</span>
            </div>
          </div>
        </div>
        <div className="recharge-history-recharge-card-col3">
          <div>{props.data.status}</div>
          <button
            onClick={() => {
              raiseDisputeMine(props.data.order_id);
            }}
          >
            Raise Dispute
          </button>
          <button
            className="recharge-history-recharge-card-col3-repeat-plan"
            onClick={moveToCart}
          >
            Repeat Plan
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}
