import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

class ConfessionService {
  addConfession(title, image, confession) {
    const confessionTitle = '<h5 class="confessionHeading">' + title + "</h5>"
    const formData = new FormData();
    formData.append('id', 1);
    formData.append('is_comment',0);
    formData.append("message", confessionTitle + confession);
    formData.append('files', image);

    return axios.post(API_BASE_URL + '/v1/api/addToConfession', formData, {
      headers: {
        authtoken: localStorage.getItem('authtoken'),
       
      },
    });
  }

  deleteConfession(id) {
    return axios.post(API_BASE_URL + '/v1/api/deleteConfession',{id:id,
    is_comment: 0}, {
      headers: {
        authtoken: localStorage.getItem('authtoken'),
      },
    });
  }

  editConfession(id,confession,heading) {


    return axios.post(API_BASE_URL + '/v1/api/EditConfession/' + id,{message:`<h5 class="confessionHeading">${heading}</h5>${confession}`}, {
      headers: {
        authtoken: localStorage.getItem('authtoken'),

      },
    });
  }

  showConfessions() {
    return axios.get(API_BASE_URL + '/v1/api/showPersonalConfessionsAndComment', {
      headers: {
        authtoken: localStorage.getItem('authtoken'),
      },
    });
  }

  likeConfession(id){
    return axios.post(API_BASE_URL + '/v1/api/ConfessionLike', {id},{
      headers: {
        authtoken: localStorage.getItem('authtoken'),
      },
    });

  }
  unlikeConfession(id){
    return axios.post(API_BASE_URL + '/v1/api/ConfessionUnlike', {id},{
      headers: {
        authtoken: localStorage.getItem('authtoken'),
      },
    });
  }

  commentConfession(id,message,is_comment){
    const formData = new FormData();
    formData.append('id', id);
    formData.append('is_comment',is_comment);
    formData.append("message",message);
    
    return axios.post(API_BASE_URL + '/v1/api/addToConfession',formData, {
      headers: {
        authtoken: localStorage.getItem('authtoken'),
       
      },
    });
  }
  showComment(id){
    return axios.get(API_BASE_URL + '/v1/api/singleconfession/' + id, {
      headers: {
        authtoken: localStorage.getItem('authtoken'),
      },
    });
  }
  editComment(id,message){
    
    return axios.post(API_BASE_URL + '/v1/api/modifyConfessionComment', {'comment':message,'id':id,},{
      headers: {
        authtoken: localStorage.getItem('authtoken'),
      },
    });
  }
  deleteComment(id){
    return axios.post(API_BASE_URL + '/v1/api/DeleteConfessionComment', {'id':id},{
      headers: {
        authtoken: localStorage.getItem('authtoken'),
      },
    });
  }

}

export default new ConfessionService();
