import React, { useState } from 'react'
import  "./../admincss/adminlogin.css"
import adminloginapi from '../adminapi/adminloginapi';
import { useNavigate } from "react-router-dom";  
import notify from '../../error';
import eyeclose from "./../../images/eyeclose-pass.svg";
import eye from "./../../images/eye-pass.svg";
const Adminlogin = (props) => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [showme, setShowme] = useState(false);

  const handleUserIdChange = (e) => {
    setUserId(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleRememberMeChange = () => {
    setRememberMe(!rememberMe);
  };
  const handleSubmit= async(e)=>{
    e.preventDefault();
    const response = await adminloginapi.Managerlogin(parseInt(userId),password)
    if (response.data && response.data.error != "") {
      notify("error", response.data.error);
    } else {
      localStorage.setItem('AdminToken', response.data.token);
      navigate("/admin/dashboard")
      props.setIsLoggedIn(true);
      console.log(response.data);
    }
  }
  const ChangeEyeNewPass = () => {
    setShowme(!showme);
  }
  return (
    <div className="admin-container">
      <main className="admin-form-signin">
        <form>
          
          <h1 className="admin-container-main-heading">Area Manager Portal</h1>
          <div className="admin-form-floating user">
            <input
              type="number"
              className="admin-form-control"

              placeholder="User ID"
              value={userId}
              onChange={handleUserIdChange}
            />

          </div>
          <div className="admin-form-floating paassword admin-form-control">
            <input
              type={showme ? "password":"text"}
              // className="admin-form-control"
              placeholder="Password"
              value={password}
              onChange={handlePasswordChange}
            />
                   <img src={!showme ? eye:eyeclose} onClick={ChangeEyeNewPass} className='eyeopen'></img>
          </div>
          <div className="admin-checkbox-rememberme ">
            <label>
              <input type="checkbox" checked={rememberMe} onChange={handleRememberMeChange} />
             <span>Remember me</span> 
            </label>
          </div>
          <button className="admin-btn"  onClick={handleSubmit}>
            Sign in
          </button>
        </form>
      </main>
    </div>
  );
}

export default Adminlogin