import React, { Component, useEffect, useRef, useState } from "react";
import { useGlobalContext } from "../../context";
import myProfileServiceApi from "../../api/myProfileServiceApi";
import back from "../myprofileIcoons/bx-arrow-back..svg";
import notify from "../../error";
import "./css/offlineCustomerTransDetail.css";
import { motion } from "framer-motion";
import Loading from "../../components/Loading";
import axios from "axios";
import { event } from "jquery";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export default function OfflineCustomerTransactionDetail(props) {
  const { Userprofile, loading, setLoading,setInnerPage,userServiceIDselected, setUserServiceIDselected,isMobileView } = useGlobalContext();
  const initialref = useRef(true);
  const [searchedstring,setSearchedstring]=useState("");
  const date = new Date();
  const date2 = new Date();
// console.log(userServiceIDselected);
 
function subtract15Days(date2) {
  const newDate = new Date(date2);
  newDate.setDate(date2.getDate() - 15);
  return newDate;
}
const newDate = subtract15Days(date2);
  const [myOrders, setMyOrders] = React.useState();
  const [showConfirmation, setShowConfirmation] = React.useState(false);
  const [conflictusername,setConflictusername]=React.useState(''); // if same mobile number then show user name of that user while merging the bill
  const [editId, setEditId] = React.useState({});
  const [formDatamodify, setFormDatamodify] = React.useState({
    Id: 0,
    Name: "",
    MobileNumber: "",
  });


  const EditOfflineCustomer = async () => {
    // Find the customer with the given customerId
 
    const customer = editId.user_id === formDatamodify.Id;


    console.log(customer);
    if (customer) {
      console.log(customer);

      let changedFields = {};
      console.log(formDatamodify.Name, editId);
      if (formDatamodify.Name !== editId.user) {
        changedFields.customer_name = formDatamodify.Name;
      }
      if (formDatamodify.MobileNumber !== editId.phone) {
        changedFields.customer_phone = formDatamodify.MobileNumber;
      }

      // Check if any fields have changed
      if (Object.keys(changedFields).length === 0) {
        console.log("No changes detected.");
        console.log("Changed fields:", changedFields);
        return; // No changes, so don't make the API call
      }

      // Send a POST request to update the data
      const updateResponse = await axios.post(
        API_BASE_URL + "/v1/api/changeNumber",
        {
          User_id: editId.user_id,
          ...changedFields
        },
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authtoken: localStorage.getItem('authtoken'),
          },

        }
      );

      if (updateResponse.data && updateResponse.data.error != '') {
        if (updateResponse.data.error === "Number Already Exist") {
          notify("error", updateResponse.data.error);
          if(updateResponse.data.exist_customer){
          setConflictusername(updateResponse.data.exist_customer);
          setShowConfirmation(true);
        }
        } else {
          notify("error", updateResponse.data.error);
        }
      } else {

        console.log(updateResponse.data);
      setPage(1);
        notify('success', "edited successfully");
        
        setFormDatamodify({})
        getCustomerTransactionDetails();
        setShowConfirmation(false);
       
      }


    }
  }


  function handleChange1(event) {
    console.log(event);
    const { name, value, type, checked } = event.target;
    setFormDatamodify((prevFormData) => {
      return {
        ...prevFormData,
        [name]: type === "checkbox" ? checked : value,
      };
    });
    console.log(formDatamodify);
  }

  const mergebill = async () => {
    const { Id, Name, MobileNumber } = formDatamodify;
    
    
    let changedFields = {};
      console.log(formDatamodify.Name, editId);
      if (formDatamodify.Name !== editId.user) {
        changedFields.customer_name = formDatamodify.Name;
      }
   
    
    // Check if the Name field has changed
    if (Object.keys(changedFields).length === 0) {
      console.log("Name is not changed.");
      console.log("Changed fields:", changedFields);
      // Don't send the Name parameter in the API call
      const response = await myProfileServiceApi.Mergebill(
        null,
        MobileNumber,
        Id,
      );
      console.log(response);
      if (response.data && response.data.error !== "")
       {
        notify("error", response.data.error);
      } else 
       {

        notify("success", "Edited successfully");
        getCustomerTransactionDetails();
        setFormDatamodify({});
        setPage(1);
      }
    } else {
      // Name 
      const response = await myProfileServiceApi.Mergebill(
        Name, MobileNumber, Id,
      );
      console.log(response);
      if (response.data && response.data.error != "") {
        notify("error", response.data.error);
      } else {
    
        notify('success', "edited successfully");
        getCustomerTransactionDetails();
        setFormDatamodify({})
        setPage(1);
      }
    }
  }

  React.useEffect(() => {
    setFormDatamodify((prev) => {
      return {
        Id: editId.user_id,
        Name: editId.name || editId.user,
        MobileNumber: editId.phone,
      };
    });
  }, [editId]);

  React.useEffect(() => {
    GetAllMyOrders();
  }, []);

  //date inputs using calander
  const [formData, setFormData] = React.useState({
    to: `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(date.getDate()).padStart(2, "0")}`,
    from: `${newDate.getFullYear()}-${String(newDate.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(newDate.getDate()).padStart(2, "0")}`,
    editFrom: `${newDate.getFullYear()}-${String(
      newDate.getMonth() + 1
    ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
  });

  //date manupulation
  //how to play with dates
  function handleChange(event) {
    console.log(event);
    setAdvancedData([]);
    setDuesData([]);
    setNoDuesData([]);
    const { name, value, type, checked } = event.target;
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: type === "checkbox" ? checked : value,
      };
    });
    if (name === "editFrom") {
      console.log("here", value);
      const newDate = new Date();
      let now = new Date();
      now = getLastWeeksDate();

      function getLastWeeksDate() {
        const now = new Date();

        return new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
      }

      function getLast30Date() {
        const now = new Date();

        return new Date(now.getFullYear(), now.getMonth(), now.getDate() - 30);
      }
      function getLastmonthDate() {
        const now = new Date();

        return new Date(now.getFullYear(), now.getMonth() - 1, now.getDate());
      }
      function getLast6monthDate() {
        const now = new Date();

        return new Date(now.getFullYear(), now.getMonth() - 6, now.getDate());
      }
      function get2022Date() {
        const now = new Date();

        return new Date(now.getFullYear() - 1, 0, 1);
      }
      function get2021Date() {
        const now = new Date();

        return new Date(now.getFullYear() - 2, 0, 1);
      }
      function get2020Date() {
        const now = new Date();

        return new Date(now.getFullYear() - 3, 0, 1);
      }
      function get2019Date() {
        const now = new Date();

        return new Date(now.getFullYear() - 4, 0, 1);
      }
      function get2018Date() {
        const now = new Date();

        return new Date(now.getFullYear() - 5, 0, 1);
      }
      function get2017Date() {
        const now = new Date();

        return new Date(now.getFullYear() - 6, 0, 1);
      }
      if (value === "1") {
        console.log("here 1");
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            from: `${newDate.getFullYear()}-${String(
              newDate.getMonth() + 1
            ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
            to: `${newDate.getFullYear()}-${String(
              newDate.getMonth() + 1
            ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
          };
        });
      }

      if (value === "2") {
        console.log("here 2");

        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
              2,
              "0"
            )}-${String(now.getDate()).padStart(2, "0")}`,
            to: `${newDate.getFullYear()}-${String(
              newDate.getMonth() + 1
            ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
          };
        });
      }

      if (value === "3") {
        console.log("here 3");
        now = getLast30Date();
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
              2,
              "0"
            )}-${String(now.getDate()).padStart(2, "0")}`,
            to: `${newDate.getFullYear()}-${String(
              newDate.getMonth() + 1
            ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
          };
        });
      }
      if (value === "4") {
        console.log("here 4");
        now = getLast6monthDate();
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
              2,
              "0"
            )}-${String(now.getDate()).padStart(2, "0")}`,
            to: `${newDate.getFullYear()}-${String(
              newDate.getMonth() + 1
            ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
          };
        });
      }
      if (value === "5") {
        console.log("here 5");
        now = get2022Date();
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
              2,
              "0"
            )}-${String(now.getDate()).padStart(2, "0")}`,
            to: `${newDate.getFullYear()}-${String(
              newDate.getMonth() + 1
            ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
          };
        });
      }
      if (value === "6") {
        // console.log('here 5');
        now = get2021Date();
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
              2,
              "0"
            )}-${String(now.getDate()).padStart(2, "0")}`,
            to: `${newDate.getFullYear()}-${String(
              newDate.getMonth() + 1
            ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
          };
        });
      }
      if (value === "7") {
        // console.log('here 5');
        now = get2020Date();
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
              2,
              "0"
            )}-${String(now.getDate()).padStart(2, "0")}`,
            to: `${newDate.getFullYear()}-${String(
              newDate.getMonth() + 1
            ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
          };
        });
      }
      if (value === "8") {
        // console.log('here 5');
        now = get2019Date();
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
              2,
              "0"
            )}-${String(now.getDate()).padStart(2, "0")}`,
            to: `${newDate.getFullYear()}-${String(
              newDate.getMonth() + 1
            ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
          };
        });
      }
      if (value === "9") {
        console.log("here 5");
        now = get2018Date();
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
              2,
              "0"
            )}-${String(now.getDate()).padStart(2, "0")}`,
            to: `${newDate.getFullYear()}-${String(
              newDate.getMonth() + 1
            ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
          };
        });
      }

      if (value === "10") {
        // console.log('here 5');
        now = get2017Date();
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
              2,
              "0"
            )}-${String(now.getDate()).padStart(2, "0")}`,
            to: `${newDate.getFullYear()}-${String(
              newDate.getMonth() + 1
            ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
          };
        });
      }
    }
    console.log(formData);
  }
  const [AdvancedData, setAdvancedData] = React.useState();
  const [DuesData, setDuesData] = React.useState();
  const [NoDuesData, setNoDuesData] = React.useState();
  const sp_id = Userprofile.service_provider_id;
  const getCustomerTransactionDetails = async () => {
    try {
      setLoading(true);
      const selectedServiceID = userServiceIDselected ? userServiceIDselected[0] : null;
      const postData = {
        sp_id,
        start_date: formData.from,
        end_date: formData.to,
      };
      
      if (selectedServiceID !== null && selectedServiceID!=="") {
        postData.sub_service_id = parseInt(selectedServiceID);
      }
      const res = await axios.post(API_BASE_URL + "/v1/api/showOutstandingAdvanced", postData,
        {
          headers: {
            authtoken: localStorage.getItem("authtoken"),
          },
        }
      );
      console.log("newResponse", res);
      if (res.data) {
        setLoading(false);
        setAdvancedData(res.data.advanced_list);
        setDuesData(res.data.outstanding_list);
        setNoDuesData(res.data.simple_list);
      }
    } catch (error) {
      notify("error", `Error fetching transction.`);
    }
  };
  React.useEffect(() => {
    getCustomerTransactionDetails();
  }, [formData.to, formData.from]);

  async function GetAllMyOrders() {
    const selectedServiceID = userServiceIDselected ? userServiceIDselected[0] : null;
    const response = await myProfileServiceApi.ALlOfflineOrders(
      Userprofile.service_provider_id,
      formData.from,
      formData.to,
      selectedServiceID
    );
    console.log(response);

    if (response.data && response.data.error != "") {
      notify("error", response.data.error);
    } else {
      setMyOrders(response.data);
      console.log(response.data);
      // const keys = Object.keys(response.data);
      // setMyObjectKeyArray(keys);
    }
  }
  React.useEffect(() => {
    GetAllMyOrders();
  }, [formData.to, formData.from]);

  const makeload = () => {
    GetAllMyOrders();
  };

  const [myObjectKeyArray, setMyObjectKeyArray] = React.useState([]);

  const goBack = () => {
    props.return();
    setInnerPage(false);
  };

  const [page, setPage] = React.useState(1);

  const [billData, setBillData] = React.useState();

  const exitDetail = () => {
    setPage(1);
  };
useEffect(()=>{
  if(initialref.current){
    initialref.current=false;
    return;
  }
  let searchedtimeout;
  const apiresponse = (response)=>{
if(response.data.error){
  notify("error","Error while fetching the data")
}
else{
  setLoading(false);
  setAdvancedData(response.data.advanced_list)
  setDuesData(response.data.outstanding_list)
  setNoDuesData(response.data.simple_list)
}
  }
  searchedtimeout=setTimeout(async() => {
    let response;
    if(searchedstring){
      if(/^\d+$/.test(searchedstring)){
      response=await myProfileServiceApi.fetchsearchofflinecustomers(
        {sp_id:Userprofile.service_provider_id,
          search_phone:searchedstring,
        });
       apiresponse(response) 
      }
      else{
            response=await myProfileServiceApi.fetchsearchofflinecustomers(
              {sp_id:Userprofile.service_provider_id,
              search_name:searchedstring,
              search_phone:"",
              });
              apiresponse(response)
          }
        }
        setLoading(false);
      }, 1000);
      
      if(!searchedstring){
        getCustomerTransactionDetails();
      }
  // this code runs while unmounting or while rerendering. because of this all the pending timeout's get clearout.
return ()=>{
  if(searchedtimeout){
    clearTimeout(searchedtimeout);
  }
}
},[searchedstring])

  return (
    <React.Fragment>
      <>
        <div className="my-service-profile-ongoing-and-pending-online">
          {page !== 4  && page!==5 ? (
            <>
         
              <div className="my-online-ongoing-pending-profile-time-filter-calander-box">
              <div className="my-service-profile-ongoing-and-pending-online-back">
                <img src={back} onClick={goBack} alt=""/>
              </div>
                <div className="my-order-profile-time-filter-calander-1">
                  <span>From</span>
                  <input
                    type="date"
                    onChange={handleChange}
                    name="from"
                    value={formData.from}
                  ></input>
                </div>
                <div className="my-order-profile-time-filter-calander-1">
                  <span>Till</span>
                  <input
                    type="date"
                    onChange={handleChange}
                    name="to"
                    value={formData.to}
                  ></input>
                </div>
                <div className="my-order-profile-time-filter-calander-3 my-order-profile-time-filter-calander-3offline">
                  <span>Sort By : </span>
                <select onChange={handleChange} name="editFrom" >
                    <option value={0}>Time Filter</option>
                    <option value={1}>Today</option>
                    <option value={2}>Last One Week</option>
                    <option value={3}>Last 30 Days</option>
                    <option value={4}>Last 6 Months</option>
                    <option value={5}>2022</option>
                    <option value={6}>2021</option>
                    <option value={7}>2020</option>
                    <option value={8}>2019</option>
                    <option value={9}>2018</option>
                    <option value={10}>2017</option>
                  </select>
                </div>
              </div>
                <div className="offlinesearchbox">
                  <input type="text" placeholder="Enter Customer Name or Mobile Number" onChange={(event)=>{
                    setAdvancedData([])
                    setDuesData([])
                    setNoDuesData([])
                    setLoading(true);
                    setSearchedstring(event.target.value)
                  }}></input>
                </div>
              <div className="my-service-profile-ongoing-and-pending-online-selector">
                <div className="my-service-profile-ongoing-and-pending-online-selector-tab">
                  <motion.span
                    onClick={() => {
                      setPage(1); 
                    }}
                    animate={{
                      color: page === 1 ? "black" : "rgba(0, 0, 0, 0.512)",
                      "border-bottom":
                        page === 1 ? "3px solid #1D8B97" : "none",
                    }}
                    whileHover={{ color: "black" }}
                  >
                    Advance
                  </motion.span>
                  <motion.span
                    onClick={() => {
                      setPage(2);
                    }}
                    animate={{
                      color: page === 2 ? "black" : "rgba(0, 0, 0, 0.512)",
                      "border-bottom":
                        page === 2 ? "3px solid #1D8B97" : "none",
                    }}
                    whileHover={{ color: "black" }}
                  >
                    Dues
                  </motion.span>
                  <motion.span
                    onClick={() => {
                      setPage(3);
                    }}
                    animate={{
                      color: page === 3 ? "black" : "rgba(0, 0, 0, 0.512)",
                      "border-bottom":
                        page === 3 ? "3px solid #1D8B97" : "none",
                    }}
                    whileHover={{ color: "black" }}
                  >
                    No Dues
                  </motion.span>
                </div>
                {page === 1 ? (
                  <>
                    <div className="my-service-profile-ongoing-and-pending-online-box">
                      {AdvancedData && AdvancedData.length !== 0 ? (
                        AdvancedData.map((item) => {
                          return (
                            <>
                              <div className="my-bills-profile-page-view-main-right-main-box-card my-bills-profile-page-view-main-right-main-box-cardmobileview">
                                {/* <div className='my-bills-profile-page-view-main-right-main-box-card-img'>
                                  <img></img>
                                </div> */}
                                <div className="my-bills-profile-page-view-main-right-main-box-card-provider">
                                  {item.user}
                                </div>
                                <div className="my-bills-profile-page-view-main-right-main-box-card-des">
                                  {/* <span>Order Id : {item.order_id}</span> */}
                                  {/* <span>{item.datetime.slice(5, 16)}</span> */}
                                  <span>{/* Mobile : {item.phone} */}</span>
                                </div>
                                <div className="my-bills-profile-page-view-main-right-main-box-card-time">
                                  <span>Mobile : {item.phone}</span>
                                  {/* <button>Complete Order</button> */}
                                </div>
                                <div className="my-bills-profile-page-view-main-right-main-box-card-des">
                                  {/* {item.datetime.slice(0, 16)} */}
                                </div>
                                {
                                  isMobileView ? <>
                                  <div className="modifyamountsection">
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-modify-button">
                                <button onClick={() => {
                                setPage(5);
                                setEditId(item);
                              }}>Modify</button>
                                </div>
                                                    
                                <div className="my-bills-profile-page-view-main-right-main-box-card-price">
                                  <span
                                    onClick={() => {
                                      props.setPage4(4);
                                      props.onUserIdChange(item.user_id,item.user,formData);
                                    }}
                                  >
                                    &#8377;{item.amount}
                                  </span>
                                  {/* <button
                                    onClick={() => {
                                      setPage(4);
                                      setBillData(item);
                                    }}
                                  >
                                    View Items
                                  </button> */}
                                </div>
                                  </div>
                                  </>:<>
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-modify-button">
                                <button onClick={() => {
                                setPage(5);
                                setEditId(item);
                              }}>Modify</button>
                                </div>
                                                    
                                <div className="my-bills-profile-page-view-main-right-main-box-card-price">
                                  <span
                                    onClick={() => {
                                      props.setPage4(4);
                                      props.onUserIdChange(item.user_id,item.user,formData);
                                    }}
                                  >
                                    &#8377;{item.amount}
                                  </span>
                                  {/* <button
                                    onClick={() => {
                                      setPage(4);
                                      setBillData(item);
                                    }}
                                  >
                                    View Items
                                  </button> */}
                                </div>
                                  </>
                                }
                              </div>
                            </>
                          );
                        })
                      ) : (
                        <div className="NoShowCenter noshowceenter">
                          {loading ? <Loading class="loading"/> : "nothing to show..."}
                        </div>
                      )}
                    </div>
                  </>
                ) : page === 2 ? (
                  <>
                    <div className="my-service-profile-ongoing-and-pending-online-box">
                      {DuesData && DuesData.length !== 0 ? (
                        DuesData.map((item) => {
                          return (
                            <>
                              <div className="my-bills-profile-page-view-main-right-main-box-card">
                                {/* <div className='my-bills-profile-page-view-main-right-main-box-card-img'>
                                  <img></img>
                                </div> */}
                                <div className="my-bills-profile-page-view-main-right-main-box-card-provider">
                                  {item.user}
                                </div>
                                <div className="my-bills-profile-page-view-main-right-main-box-card-des">
                                  {/* <span>Order Id : {item.order_id}</span> */}
                                  {/* <span>{item.datetime.slice(5, 16)}</span> */}
                                  <span>{/* Mobile : {item.phone} */}</span>
                                </div>
                                <div className="my-bills-profile-page-view-main-right-main-box-card-time">
                                  <span>Mobile : {item.phone}</span>
                                  {/* <button>Complete Order</button> */}
                                </div>
                                <div className="my-bills-profile-page-view-main-right-main-box-card-des">
                                  {/* {item.datetime.slice(0, 16)} */}
                                </div>
                                {
                               isMobileView ? 
                               <>
                               <div className="modifyamountsection">
                               <div className="my-bills-profile-page-view-main-right-main-box-card-modify-button">
                                <button onClick={() => {
                                setPage(5);
                                setEditId(item);
                              }}>Modify</button>
                                </div>
                                                    
                                <div className="my-bills-profile-page-view-main-right-main-box-card-price">
                                  <span style={{background:"red"}}
                                    onClick={() => {
                                      props.setPage4(4);
                                      props.onUserIdChange(item.user_id,item.user,formData);
                                    }}
                                  >
                                    &#8377;{item.amount}
                                  </span>
                                  {/* <button
                                    onClick={() => {
                                      setPage(4);
                                      setBillData(item);
                                    }}
                                  >
                                    View Items
                                  </button> */}
                                </div>
                                  </div>
                               </>:
                               <>
                               <div className="my-bills-profile-page-view-main-right-main-box-card-modify-button">
                                <button onClick={() => {
                                setPage(5);
                                setEditId(item);
                              }}>Modify</button>
                                </div>
                                <div className="my-bills-profile-page-view-main-right-main-box-card-price">
                                  <span style={{background:"red"}}
                                    onClick={() => {
                                      props.setPage4(4);
                                      props.onUserIdChange(item.user_id,item.user,formData);
                                    }}
                                  >
                                    &#8377;{item.amount}
                                  </span>
                                  {/* <button
                                    onClick={() => {
                                      setPage(4);
                                      setBillData(item);
                                    }}
                                  >
                                    View Items
                                  </button> */}
                                </div>
                                </>
                        }
                              </div>
                            </>
                          );
                        })
                      ) : (
                        <div className="NoShowCenter noshowceenter">
                          {loading ? <Loading class="loading"/> : "nothing to show..."}
                        </div>
                      )}
                    </div>
                    {/* </div> */}
                  </>
                ) : (
                  <>
                    <div className="my-service-profile-ongoing-and-pending-online-box">
                      {NoDuesData && NoDuesData.length !== 0 ? (
                        NoDuesData.map((item) => {
                          return (
                            <>
                              <div className="my-bills-profile-page-view-main-right-main-box-card">
                                {/* <div className='my-bills-profile-page-view-main-right-main-box-card-img'>
                                  <img></img>
                                </div> */}
                                <div className="my-bills-profile-page-view-main-right-main-box-card-provider">
                                  {item.user}
                                </div>
                                <div className="my-bills-profile-page-view-main-right-main-box-card-des">
                                  {/* <span>Order Id : {item.order_id}</span> */}
                                  {/* <span>{item.datetime.slice(5, 16)}</span> */}
                                  <span>{/* Mobile : {item.phone} */}</span>
                                </div>
                                <div className="my-bills-profile-page-view-main-right-main-box-card-time">
                                  <span>Mobile : {item.phone}</span>
                                  {/* <button>Complete Order</button> */}
                                </div>
                                <div className="my-bills-profile-page-view-main-right-main-box-card-des">
                                  {/* {item.datetime.slice(0, 16)} */}
                                </div>
                                {
                                  isMobileView ? <>
                                  <div className="modifyamountsection">
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-modify-button">
                                <button onClick={() => {
                                setPage(5);
                                setEditId(item);
                              }}>Modify</button>
                                </div>
                                                    
                                <div className="my-bills-profile-page-view-main-right-main-box-card-price">
                                  <span
                                    onClick={() => {
                                      props.setPage4(4);
                                      props.onUserIdChange(item.user_id,item.user,formData);
                                    }}
                                  >
                                    &#8377;{item.amount}
                                  </span>
                                  {/* <button
                                    onClick={() => {
                                      setPage(4);
                                      setBillData(item);
                                    }}
                                  >
                                    View Items
                                  </button> */}
                                </div>
                                  </div>
                                  </>:<>
                                  <div className="my-bills-profile-page-view-main-right-main-box-card-modify-button">
                                <button onClick={() => {
                                setPage(5);
                                setEditId(item);
                              }}>Modify</button>
                                </div>
                                <div className="my-bills-profile-page-view-main-right-main-box-card-price">
                                  <span
                                    onClick={() => {
                                      props.setPage4(4);
                                      props.onUserIdChange(item.user_id,item.user,formData);
                                    }}
                                  >
                                    &#8377;{item.amount}
                                  </span>
                                  {/* <button
                                    onClick={() => {
                                      setPage(4);
                                      setBillData(item);
                                    }}
                                  >
                                    View Items
                                  </button> */}
                                </div>
                                  </>
                                }
                              </div>
                            </>
                          );
                        })
                      ) : (
                        <div className="NoShowCenter noshowceenter">
                          {loading ? <Loading class="loading"/> : "nothing to show..."}
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </>
          ) : page===5 ? 
          (
            <div className="manage-services-profile-service-main-page">
              <div className="profile-wallet-main-page-heading profile-wallet-main-page-heading-left">
                <b className="my-profile-wallet-page-transfer-top-back">
                  <img
                    src={back}
                    onClick={() => {
                      setPage(1);
                    }}
                  ></img>
                </b>
                <span>Modify  Customer</span>
              </div>
              <div className="manage-myproducts-profile-service-main-page-inputs">
                <div className="manage-myproducts-profile-service-main-page-inputs-1">
                  <span>Name</span>
                  <input
                    type="text"
                    name="Name"
                    value={formDatamodify.Name}
                    onChange={handleChange1}
                    placeholder="Enter Customer Name"
                  ></input>
                </div>
                <div className="manage-myproducts-profile-service-main-page-inputs-1">
                  <span>Mobile Number</span>
                  <input
                    type="number"
                    name="MobileNumber"
                    value={formDatamodify.MobileNumber}
                    onChange={handleChange1}
                    placeholder="Enter Mobile Number"
                  ></input>
                </div>
              </div>
              <div className="manage-myproducts-profile-service-main-page-inputs-submit">
                <button
                  onClick={() => {

                    EditOfflineCustomer();
                  }}
                >
                  Submit
                </button>
              </div>
              {showConfirmation && (
                <ConfirmationModal
                conflictusername={conflictusername}
                setConflictusername={setConflictusername}
                  onCancel={() => setShowConfirmation(false)}
                  onConfirm={() => {
                    mergebill();

                  }}
                />
              )}
            </div>


          ): (
            <div className="my-bills-profile-page-view-main-right">
              <BillDetailsView
                data={billData}
                return={exitDetail}
              ></BillDetailsView>
            </div>
          )}
        </div>

     


      </>
    </React.Fragment>
  );
}

function BillDetailsView(props) {
  return (
    <React.Fragment>
      <>
        <div className="my-bills-profile-page-view-main-right-data">
          {/* <div className='my-service-profile-ongoing-and-pending-online-back'></div> */}
          <div className="my-bills-profile-page-view-main-right-heading-data">
            <img src={back} onClick={props.return}></img>
            <span>View Details</span>
          </div>
          <div className="my-bills-profile-page-view-main-right-data-box">
            {props &&
              props.data &&
              props.data.items &&
              props.data.items.length > 0 &&
              props.data.items.map((item) => {
                return (
                  <div className="my-bills-profile-page-view-main-right-data-box-card">
                    <div className="my-bills-profile-page-view-main-right-data-box-card-img">
                      <img></img>
                    </div>
                    <div className="my-bills-profile-page-view-main-right-data-box-card-des">
                      <span>{item.brand}</span>
                      <span>{item.name}</span>
                      <span>{item.product_quantity}</span>
                    </div>
                    <div className="my-bills-profile-page-view-main-right-data-box-card-qty">
                    Units: {item.quantity}
                    </div>
                    <div className="my-bills-profile-page-view-main-right-data-box-card-price">
                      <span>&#8377; {item.price}</span>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </>
    </React.Fragment>
  );
}



const ConfirmationModal = ({ onCancel, onConfirm, conflictusername,setConflictusername }) => {
  if (!conflictusername) {
    return null;
  }


  return (
    <div className="confirmation-modal">
      <div className="confirmation-modal-content">
        <h3>Are you sure you want to merge this Bill with <span>{conflictusername} </span>?</h3>
        <div className="confirmation-modal-buttons">
          <button onClick={onConfirm}>Yes</button>
          <button onClick={onCancel}>No</button>
        </div>
      </div>
    </div>
  );
};