import React, { useState, useEffect } from 'react'
import './css/FollowList.css'
import FollowingCard from "./FollowingCard"
import MyFollowersCard from "./MyFollowersCard"
import FollowListApi from '../../api/FollowListApi'

const Following = () => {
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const [isFollowing,setisFollowing] = useState(true);
  const [FollowingList,setFollowingList] = useState([]);
  const [FollowersList,setFollowersList] = useState([]);
  const fetchFollowersList = async () => {
      const response = await FollowListApi.ShowMyFollowers();
      const followers = response.data.followers;
      console.log(followers);
      setFollowersList(followers);
  }
  useEffect(() => {
    fetchFollowersList();
  },[])
  const fetchFollowingList = async () => {
      const response = await FollowListApi.ShowFollowing();
      const following = response.data.fav_list;
      console.log(following);
      setFollowingList(following);
  }
  useEffect(() => {
    fetchFollowingList();
  },[])
  return (
    <React.Fragment>
      <div className='follow-list-wrapper-btns'>
        <button className='follow-list-following-btn '  onClick={() => {setisFollowing(true)}} ><b style = { isFollowing ? {borderBottom: "3px solid #1D8B97"} : {}} className='follow-list-btn-text-border-bottom'>FOLLOWING</b></button>
        <button className="follow-list-my-followers-btn" onClick={() => {setisFollowing(false)}} style={{borderRight: "none"}}><b style = { !isFollowing ? {borderBottom: "3px solid #1D8B97"} : {}} className='follow-list-btn-text-border-bottom'>MY FOLLOWERS</b></button>
      </div>
      { isFollowing && <FollowingCard FollowingList = {FollowingList}/>}
      { !isFollowing && <MyFollowersCard FollowersList = {FollowersList} />}
    </React.Fragment>
  )
}

export default Following