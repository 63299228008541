import React, { Component } from "react";
import cartapi from "../api/cartapi";
// import cart from '../api/cartapi';
import "./cart-css/CartItems.css";
import { useGlobalContext } from "../context";
export default function CartItem(props) {
  console.log(props);
  const { changeInCart, setChangeInCart } = useGlobalContext();
  const [counter, setCounter] = React.useState(props.data.quantity);

  React.useEffect(() => {
    if (props.data.quantity !== counter) {
      UpdateMyProduct();
    }
  }, [counter]);

  async function UpdateMyProduct() {
    const response = await cartapi.UpdateProd(
      props.data.cart_id,
      null,
      counter,
      1
    );

    console.log(response);
    if (response.data.error === "" && response.data.status === true) {
      props.diff(counter);
    }
  }

  function reduce() {
    if (counter > 1) {
      setCounter((prev) => prev - 1);
    }
  }

  function increase() {
    setCounter((prev) => prev + 1);
  }
  const Delete = async () => {
    console.log("delete");
    const response = await cartapi.DeleteProd(props.data.cart_id);
    console.log(response);
    if (response.data.error === "" && response.data.status === true) {
      props.diff("delete");
      setChangeInCart(!changeInCart);
    }
  };

  return (
    <React.Fragment>
      <div className="cart-main-page-item">
        <div className="cart-main-page-item-cross" onClick={Delete}>
          X
        </div>
        <div className="cart-main-page-item-col1">
          {props.data && props.data?.url && <img src={props.data.url} alt="" />}
        </div>
        <div className="cart-main-page-item-col2">
          <div className="cart-main-page-item-col2-brand">
            {props.data ? props.data.brand : ""}
          </div>
          <div className="cart-main-page-item-col2-product_name">
            {props.data ? props.data.product_name : ""}
          </div>
          <div className="cart-main-page-item-col2-coupon_discount">
            Coupon Discount: &#8377;
            {props.data
              ? props.data.coupon_discount
                ? props.data.coupon_discount
                : "0"
              : "0"}
          </div>
          <div className="cart-main-page-item-col2-seller">
            Sold By : {props.data ? props.data.seller : ""}
          </div>
          <div className="cart-main-page-item-col2-product_quantity">
            Qunatity : {props.data ? props.data.product_quantity : ""}
          </div>
          <div className="cart-main-page-item-col2-quantity">
            Number of Items : {props.data ? props.data.quantity : ""}
          </div>
          {props.data && props.data.color_name &&

            <div className="cart-main-page-item-col2-colorname">
              {props.data.color_name}
              <div className="initial-choice service-color-choice" style={{
                backgroundColor:
                  props.data.color_code,
              }}
              >
              </div>
            </div>}
          <div className="cart-main-page-item-col2-short_description">
            Short Description :{" "}
            {props.data
              ? props.data.short_description
                ? props.data.short_description
                : "null"
              : "null"}
          </div>
          <div className="cart-main-page-item-col2-rates">
            <div className="">
              &#8377;{props.data ? props.data.selling_price : ""}
            </div>
            {props.data && props.data.discount > 0 ? (
              <>
                <span>&#8377;{props.data ? props.data.actual_price : ""}</span>
                <p>{props.data ? `${props.data.discount}% OFF` : ""}</p>
              </>
            ) : (
              ""
            )}
          </div>
          {props.page === 2 ? (
            <></>
          ) : (
            <div className="cart-main-page-item-col2-counter">
              <div className="cart-main-page-item-col2-minus" onClick={reduce}>
                -
              </div>
              <div className="cart-main-page-item-col2-count">{counter}</div>
              <div className="cart-main-page-item-col2-plus" onClick={increase}>
                +
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
