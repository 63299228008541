import React from "react";
import "./css/myConfessionMainPageView.css";
import Confessionapi from "../../api/Confessionapi";
import notify from "../../error";
import back from "../myprofileIcoons/bx-arrow-back..svg";
import ConfessionCard from "./ConfessionCard";
import iconconfession from "./img/confession.png";
import { useGlobalContext } from "../../context";
import Loading from "../../components/Loading";

export default function ConfessionMainView() {
  const { loading, setLoading,setInnerPage } = useGlobalContext();
  const [showForm, setShowForm] = React.useState(false);
  const [formData, setFormData] = React.useState({
    title: "",
    message: "",
    image: "",
  });
  const [ConfessionReceived, setConfessionReceived] = React.useState([]);
  const fileInputRef = React.useRef(null);
  const handleStartConfessing = () => {
    setShowForm(true);
    setInnerPage(true);
  };

  const ReceivedConfession = async () => {
    const response = await Confessionapi.showConfessions();
    //   console.log(response)
    if (response.data.error !== "") {
      notify("error", response.data.error);
    } else {
      // notify('success','here are response')
      setConfessionReceived(response.data.confessions);
    }
  };

  async function AgainGetResponse() {
    ReceivedConfession();
  }

  React.useEffect(() => {
    ReceivedConfession();
    setLoading(false);
  }, []);

  function handleChange(event) {
    const { name, value, type, checked, files } = event.target;
    console.log(event);
    console.log(name, value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : files ? files[0] : value,
    }));
    console.log(formData);
  }

  const SubmitConfession = () => {
    if (formData.title.length < 10) {
      notify("error", "Confession title must have a minimum of 10 characters.");
      return;
    } else if (formData.message.length < 10) {
      notify("error", "Confession must have a minimum of 10 characters.");
      return;
    }
    setLoading(true);
    Confessionapi.addConfession(
      formData.title,
      formData.image,
      formData.message
    )
      .then((response) => {
        console.log(response.data);
        if (response.data.error !== "") {
          notify("error", response.data.error);
          setFormData({ title: "", message: "", image: "" });
        } else {
          notify("success", "Confession submitted successfully");
          setFormData({ title: "", message: "", image: "" });
          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }
          setShowForm(false);
          AgainGetResponse();
        }
      })

      .catch((error) => {
        console.error(error);
        notify("error", "An error occurred while submitting the confession.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (showForm) {
    return (
      <React.Fragment>
        <div
          className="my-feedback-my-profile-add-feedback"
          style={{ padding: loading && "0px" }}
        >
          {loading && (
            <div className="loadingContainer">
              <Loading />
            </div>
          )}
          <div className="my-profile-wallet-page-transfer-top-back">
            <img
              src={back}
              onClick={() => {
                setShowForm(false);
                setInnerPage(false);
              }}
              alt=""
            />
          </div>
          <div className="profile-wallet-main-page-heading ">
            <span>ADD&nbsp; CONFESSION</span>
          </div>

                    <div className='my-feedback-my-profile-inputs confession-mobile-view-heading'>
                        <div className="add-concession-form">
                            <div className="col-md-6">
                                <div className="addressformlabel">
                                    Confession Title(50 characters maximum)
                                    <span className="text-danger fo-14 ml-1">*</span>
                                </div>
                                <input
                                    type="text"
                                    className="formcontrol"
                                    name="title"
                                    placeholder="Confession title"
                                    id="confessionHeading"
                                    value={formData.title}
                                    onChange={handleChange}

                                />
                                <label className="tooltipmsg"></label>
                            </div>
                            <div className="col-md-6">
                                <div className="addressformlabel">Upload Image (optional)</div>
                    
                                <input className="formcontrol" ref={fileInputRef}  onChange={handleChange} name='image' type="file"   accept='image/png, image/jpg, image/gif, image/jpeg' id="cover-image-confession" />
                            </div>
                            <div className="col-md-12">
                                <div className="addressformlabel">
                                    Confession(3500 characters maximum)
                                    <span className="text-danger fo-14 ml-1">*</span>
                                </div>
                                <textarea
                                    style={{ height: '200px', resize: 'none' }}
                                    placeholder="What are you confessing?"
                                    className="formcontrol"
                                    value={formData.message}
                                    onChange={handleChange}
                                    name='message'
                                    id="confession"
                                ></textarea>
                                <label className="tooltipmsg"></label>
                            </div>
                            <div className="col-md-12  mt-4 my-concession-main-page-type-buttons">
                                <button
                                type='submit'
                                    id="postConfessionButton"

                                    onClick={SubmitConfession}
                                >
                                    Confess
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

  return (
    <React.Fragment>
      <>
        <div className="my-feedback-main-page-view ">
          <div className="my-concession-main-page-view">
            <div className="profile-wallet-main-page-heading">
              <span>MY&nbsp; CONFESSIONS</span>
            </div>
            <div className="my-concession-main-page-type-buttons ">
              <button onClick={handleStartConfessing}>START CONFESSING </button>
            </div>
          </div>

          <div className="my-feedback-main-page-data confessionsectionss">
            {ConfessionReceived?.length > 0 ? (
              ConfessionReceived.map((item) => {
                return (
                  <ConfessionCard
                    data={item}
                    refresh={AgainGetResponse}
                    free="yes"
                  ></ConfessionCard>
                );
              })
            ) : (
              <>
                <div className="my-confession-main-page-no-confession-given">
                  <img src={iconconfession} alt="icon" />
                  <div class="my-confession-main-page-confession-text">
                    NO CONFESSIONS{" "}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </>
    </React.Fragment>
  );
}
