import React,{useState} from "react";
import myProfileServiceApi from "../../api/myProfileServiceApi";
import notify from '../../error';
import { useGlobalContext } from "../../context";
import ModifyModal from "./ModifyModal";


import logo from "../../images/logo.png";
import {BiPencil} from "react-icons/bi";
import {AiFillDelete} from "react-icons/ai";

export default function GenerateBillCard({item,changeCallFunction}){
    const [modalState, setModalState] = useState({});
    const { setModifyModal } = useGlobalContext();


    const handleDeleteClick = async (quantityId,serviceProviderProductId)=>{
      try{
        const res = await myProfileServiceApi.DeleteGenerateBillProduct(quantityId,serviceProviderProductId ? serviceProviderProductId : quantityId);
        if(res.status === 200) {
          notify("success","Product deleted successfully...");
          changeCallFunction(true);
        }
      }catch(err){
        console.log(err);
      }
      }

      const handleModifyClick = () => {
        setModifyModal(true);
        setModalState((prevState) => ({
          ...prevState,
          [item.product_quantity_id]: true,
        }));
      };

      const handleModalClose = () => {
        setModifyModal(false);
        setModalState((prevState) => ({
          ...prevState,
          [item.product_quantity_id]: false,
        }));
      };

    
    return(
        <>
         <div className='generate-bill-serivce-main-page-main-box-card'>
           <section className="generate-bill-card-section-one">
           <div className='generate-bill-serivce-main-page-main-box-card-information'>
               <img className='generate-bill-serivce-main-page-main-box-card-image' src={item.url || logo} alt="productImage"/>
               <section className="generate-bill-product-data">
               <div>{item.product_name}</div>
               <div>Quantity :&nbsp;{item.product_quantity}</div>
               <div>Units :&nbsp;{item.quantity}</div>
               {item.color && item.color.length!==0 && (
               <div className="
               service-color-choices service-color-left-view"><span>Color:</span>
                                <div className="initial-choice service-color-choice " style={{
                                  backgroundColor:
                                   item.color.color_code,
                                }}>
                                </div>
                                </div>
                                )
                             }
               </section>
           </div>
           <div className='generate-bill-serivce-main-page-main-box-card-price'>
             <div>Discount: {item.discount || 0}%</div>
             <div>Price : &#8377;{item.final_price_after_coupon || item.selling_price || item.amount || 0}</div>
           </div>
           </section>
           <section className="generate-bill-card-section-two">
            <button onClick={handleModifyClick}>
              <BiPencil /> modify
            </button>
              {modalState[item.product_quantity_id] && (
                 <ModifyModal
                 item={item}
                 closeModal={handleModalClose}
                 callBackFunction={changeCallFunction}
                /> 
              )}
             <button onClick={(e)=>handleDeleteClick(item.product_quantity_id,item.service_provider_product_id)}>
              <AiFillDelete />delete
             </button>
           </section>
         </div>
        </>
    )
}