import React from 'react'
import UserService from '../api/api';

import { FaLocationDot } from "react-icons/fa6"
import { useGlobalContext } from '../context';
import Geocode from 'react-geocode';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import notify from '../error';
const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
Geocode.setApiKey(googleMapsApiKey);
const LocationSidebar = (props) => {
    const { Userprofile, setUserProfile,addwithoutLogin,setAddwithoutLogin } =
        useGlobalContext();
    const [userlocation, setUserlocation] = React.useState('');
    const [addprediction, setAddprediction] = React.useState('');
    const handleInputChange = (event) => {
        const searchQuery = event.target.value;
        console.log(searchQuery);
        setUserlocation(searchQuery)

    };


    const searchQueryHandler = async (event) => {
        if (event.key === "Enter" && userlocation.length > 0) {
            console.log("api");
            if (Userprofile && Userprofile.default_address) {
                const response = await UserService.addressPrediction(userlocation, Userprofile.default_address.latitude, Userprofile.default_address.longitude);
                console.log(response);
                if (response.data.error === "") {
                    setAddprediction(response.data.predictions);
                } else {
                    console.log(response);
                    console.log("Error occurred while fetching address predictions:", response.data.error);
                }
            }
            else {
                const response = await UserService.addressPrediction(userlocation, null, null);
                console.log(response);
                
                if (response.data.error === "") {
                    setAddprediction(response.data.predictions);
                }
                console.log("error occured to change address");
            }

        }
    };

    const getCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(async (position) => {
                const { latitude, longitude } = position.coords;
                // const geolocation = `Latitude: ${latitude}, Longitude: ${longitude}`;
                const response = await Geocode.fromLatLng(latitude, longitude);
                console.log(response.plus_code.compound_code);
                inputRef.current.value = response.plus_code.compound_code;
            });
        } else {
            console.log('Geolocation is not supported by this browser.');
        }
    };

    const wrapperRef = React.useRef(null);
    const inputRef = React.useRef(null);
    OutsideAlerter(wrapperRef);
    function OutsideAlerter(ref) {
        React.useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    console.log(ref.current);
                    props.setDisplay2('location-sidebar-container-none')
                    setAddprediction("")
                    setUserlocation("")
                }
            }
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [ref]);
    }
    return (
        <>
            <div className={props.view} ref={wrapperRef} >
                <div className="location">


                    <div className="current_location_div">
                        <button className="location-button" onClick={getCurrentLocation}>
                            <i className="fa fa-location-arrow"></i>Get current location
                        </button>
                    </div>
                    <input type="text" className="userlocation" value={userlocation} name='userlocation' placeholder="Search for area, street name...." autoComplete="off" ref={inputRef} onChange={handleInputChange} onKeyUp={searchQueryHandler} />
                    <div className="location_predictions" >

                        {addprediction.length > 0 && addprediction.map((prediction) => (
                            <LocationPrediction key={prediction.place_id}
                                address={prediction}
                                setDisplay2={props.setDisplay2}
                                setAddprediction={setAddprediction}
                                setUserlocation={setUserlocation}
                                setUserProfile={setUserProfile}
                                Userprofile={Userprofile}
                                setAddwithoutLogin={setAddwithoutLogin}
                            />
                        ))}

                    </div>
                </div>
            </div>
        </>
    )
}


const LocationPrediction = (props) => {
    console.log(props);
    const setnewaddress = async (placeId) => {

        const response = await UserService.UserLocation(placeId);
        console.log(response);
        if (response.status) {
            notify("success", "Address changed Succesfully")
            props.setDisplay2('location-sidebar-container-none');
            props.setAddprediction('');
            props.setUserlocation('');
            if (props.Userprofile) {
                const response2 = await UserService.ProfileApi();
                console.log(response2.data);
                if (response2.status) {

                    props.setUserProfile(response2.data)
                    // Store the updated Userprofile in local storage

                    localStorage.setItem('UserProfile', JSON.stringify(response2.data));

                }
            }
            else{
                const response2 = await UserService.ProfileApi();
                console.log(response2.data);
                if (response2.status) 
                {
                    console.log(response2.data.default_address);
                    
                    const defaultAddress =
                     `${response2.data.default_address.address_line1}, ${response2.data.default_address.town}, ${response2.data.default_address.state}`
                     props.setAddwithoutLogin(defaultAddress)
                     localStorage.setItem('defaultAddressWithoutLogin', defaultAddress);
                    


                }
            }
        }
        else {
            notify("error", "Error occurred ")
        }

    }
    return (
        <div className="predictions-mains" onClick={() => setnewaddress(props.address.place_id)}>
            <div className="predictions-mains-subtext">
                <div className="icon-location"><FaLocationDot /></div>
                <div className="detail_location">
                    <div className="detail_location_address" id={props.address.place_id}>
                        {props.address.description}
                    </div>
                </div>
            </div>
        </div>
    )
}


export default GoogleApiWrapper({
    apiKey: googleMapsApiKey
})(LocationSidebar);