import React, { useEffect, useState } from 'react';
import './myprofile.css';
import profileicon from '../images/My_Profile_Icons/1.svg';
import bill from '../images/My_Profile_Icons/2.svg';
import order from '../images/My_Profile_Icons/3.svg';
import account from '../images/My_Profile_Icons/4.svg';
import refer from '../images/My_Profile_Icons/5.svg';
import addsp from '../images/My_Profile_Icons/6.svg';
import myjobapp from '../images/My_Profile_Icons/7.svg';
import feedback from '../images/My_Profile_Icons/8.svg';
import confes from '../images/My_Profile_Icons/10.svg';
import promo from '../images/My_Profile_Icons/11.svg'
import blog from '../images/My_Profile_Icons/12.svg';
import user from "../images/blogimages/user.png";
import myfoll from '../images/My_Profile_Icons/13.svg'
import wallet from '../images/My_Profile_Icons/9.svg';
import myacc from '../images/My_Profile_Icons/14.svg'
import { RiExpandLeftRightLine } from 'react-icons/ri';
import ProfileSection from './profileSection';
import { useGlobalContext } from '../context';


import { IoArrowBack } from 'react-icons/io5';
function Myprofile() {

  const { Userprofile, page, setPage, innerPage, externalRedirect, setExternalRedirect, setMyownblog } = useGlobalContext();
  // console.log(Userprofile,'k')

  const [test, setTest] = React.useState({
    DOB: null,
    default_address: {
      address_line1: 'Harhwa Fatak',
      address_line2: 'Nirala Nagar',
      country: 'India',
      district: 'Gorakhpur',
      landmark: '',
      latitude: 26.7236567,
      longitude: 83.4341722,
      name: null,
      phone: null,
      pincode: '273004',
      state: 'Uttar Pradesh',
      town: 'Nirala Nagar',
    },
    email: null,
    error: '',
    first_name: 'Nikunj',
    gender: null,
    is_service_provider: false,
    last_name: 'Singhal',
    phone: '+919560239205',
    profile_image: '',
    'refferal code': 'PUTV9S7PR2523',
    reward_points: 500,
    status: true,
    user_id: 17392,
    username: 'NikunjSinghal5',
    wallet_balance: null,
  });

  const { expand, setExpand } = useGlobalContext();
  const [shortSidebar, setShortSidebar] = useState(false);
  const [smallScreen, setSmallScreen] = useState(window.innerWidth < 480);

  function DecidePage(e) {
    setPage(e.target.dataset.value);
  }
  function handleExpandClick() {
    setExpand((expand) => !expand);
  }

  useEffect(() => {
    const handleResize = () => {
      setSmallScreen(window.innerWidth < 480);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setExpand(true);
    if (!externalRedirect) {
      setPage(0);
    }
    return () => {
      setExternalRedirect(false);
    };
  }, [])

  const optionClick = (e) => {
    DecidePage(e);
    setExpand(false);
    if (!smallScreen) {
      setShortSidebar(true);
    }
  }


  return (
    <>
      <div className='myprofileMainDiv'>

        <div className={`myprofileMainDivFirst ${!expand ? 'closeSidebar' : ''}`} style={{ width: shortSidebar && '100px', minWidth: shortSidebar && '100px', flex: shortSidebar && 'unset' }} >
          {Userprofile && (
            <div className='myprofileleftsideProfilesection'>
              <div className='myprofileprofileimg'>
                <img src={Userprofile.profile_image || user} alt='' />
                <span className={`${!shortSidebar && "hideText"}`}
                  onClick={() => {
                    setShortSidebar(false);
                    setPage(0);
                  }}>
                  <RiExpandLeftRightLine />
                </span>
              </div>
              <div className={`myprofilusernamesection ${expand ? 'showText' : ''} ${shortSidebar && 'hideText'}`}>
                <h1>
                  {Userprofile.first_name} {Userprofile.last_name}
                </h1>

                <p>Follower {Userprofile.follower_count ? Userprofile.follower_count : 0} | following {Userprofile.following_count ? Userprofile.following_count : 0}</p>
              </div>
            </div>
          )}

          <div className='myprofileprofleleftsideNav'>
            <div data-value='1' className={`profilenavitem ${(page == '1') ? "profilenavitemactive" : ""}`} onClick={(e) => optionClick(e)}>
              {/* ${(page)} */}
              <div className='profileNavImg' data-value='1'>
                <img src={profileicon} data-value='1' />
              </div>
              <div data-value='1' className={`profileNavItemNav ${shortSidebar && "hideText"}`}>
                <p data-value='1'>My service</p>
              </div>
            </div>

            <div data-value='60' className={`profilenavitem ${(page == '60') ? "profilenavitemactive" : ""}`} onClick={(e) => optionClick(e)}>
              {/* ${(page)} */}
              <div className='profileNavImg' data-value='60'>
                <img width="48" height="48" src="https://img.icons8.com/pulsar-line/48/00838f/delivery-scooter.png" alt="delivery-scooter" data-value='60' />
              </div>
              <div data-value='60' className={`profileNavItemNav ${shortSidebar && "hideText"}`}>
                <p data-value='60'>Manage Delivery</p>
              </div>
            </div>

            <div data-value='2' className={`profilenavitem ${(page == '2') ? "profilenavitemactive" : ""}`} onClick={(e) => optionClick(e)}>
              <div className='profileNavImg' data-value='2'>
                <img src={bill} data-value='2' />
              </div>
              <div data-value='2' className={`profileNavItemNav ${shortSidebar && "hideText"}`}  >
                <p data-value='2'>My bills</p>
              </div>
            </div>

            <div className={`profilenavitem ${(page == '3') ? "profilenavitemactive" : ""}`} data-value='3' onClick={(e) => optionClick(e)}>
              <div className='profileNavImg' data-value='3'>
                <img src={order} data-value='3' />
              </div>
              <div data-value='3' className={`profileNavItemNav ${shortSidebar && "hideText"}`}  >
                <p data-value='3'>My orders</p>
              </div>
            </div>

            <div className={`profilenavitem ${(page == '4') ? "profilenavitemactive" : ""}`} data-value='4' onClick={(e) => optionClick(e)}>
              <div className='profileNavImg' data-value='4'>
                <img src={account} data-value='4' />
              </div>
              <div data-value='4' className={`profileNavItemNav ${shortSidebar && "hideText"}`}  >
                <p data-value='4'>My Bank Account</p>
              </div>
            </div>

            <div className={`profilenavitem ${(page == '5') ? "profilenavitemactive" : ""}`} data-value='5' onClick={(e) => optionClick(e)}>
              <div className='profileNavImg' data-value='5'>
                <img src={refer} data-value='5' />
              </div>
              <div data-value='5' className={`profileNavItemNav ${shortSidebar && "hideText"}`}  >
                <p data-value='5'>Refer And Earn</p>
              </div>
            </div>

            <div className={`profilenavitem ${(page == '6') ? "profilenavitemactive" : ""}`} data-value='6' onClick={(e) => optionClick(e)}>
              <div className='profileNavImg' data-value='6'>
                <img src={addsp} data-value='6' />
              </div>
              <div data-value='6' className={`profileNavItemNav ${shortSidebar && "hideText"}`}  >
                <p data-value='6'>Add Service Provider</p>
              </div>
            </div>

            <div className={`profilenavitem ${(page == '7') ? "profilenavitemactive" : ""}`} data-value='7' onClick={(e) => optionClick(e)}>
              <div className='profileNavImg' data-value='7'>
                <img src={myjobapp} data-value='7' />
              </div>
              <div data-value='7' className={`profileNavItemNav ${shortSidebar && "hideText"}`}  >
                <p data-value='7'>My Job Profile</p>
              </div>
            </div>

            <div className={`profilenavitem ${(page == '8') ? "profilenavitemactive" : ""}`} data-value='8' onClick={(e) => optionClick(e)}>
              <div className='profileNavImg' data-value='8'>
                <img src={feedback} data-value='8' />
              </div>
              <div data-value='8' className={`profileNavItemNav ${shortSidebar && "hideText"}`}  >
                <p data-value='8'>My Feedback</p>
              </div>
            </div>

            {/* special case of my wallet see data-value */}
            <div
              className={`profilenavitem ${(page == '13') ? "profilenavitemactive" : ""}`}
              data-value='13'
              onClick={(e) => optionClick(e)}>
              <div className='profileNavImg' data-value='13'>
                <img src={wallet} data-value='13' />
              </div>
              <div data-value='13' className={`profileNavItemNav ${shortSidebar && "hideText"}`}  >
                <p data-value='13'>My Wallet</p>
              </div>
            </div>

            <div className={`profilenavitem ${(page == '9') ? "profilenavitemactive" : ""}`} data-value='9' onClick={(e) => optionClick(e)}>
              <div className='profileNavImg' data-value='9' >
                <img src={confes} data-value='9' />
              </div>
              <div data-value='9' className={`profileNavItemNav ${shortSidebar && "hideText"}`}  >
                <p data-value='9' >My Confession</p>
              </div>
            </div>

            <div
              className={`profilenavitem ${(page == '10') ? "profilenavitemactive" : ""}`}
              data-value='10'
              onClick={(e) => optionClick(e)}>

              <div className='profileNavImg' data-value='10'>
                <img src={promo} />
              </div>
              <div className={`profileNavItemNav ${shortSidebar && "hideText"}`} data-value='10'>
                <p data-value='10'>My Ads</p>
              </div>
            </div>

            <div className={`profilenavitem ${(page == '11') ? "profilenavitemactive" : ""}`} data-value='11' onClick={(e) => {
              optionClick(e)
            }}>
              <div className='profileNavImg' data-value='11'>
                <img src={blog} data-value='11' />
              </div>
              <div data-value='11' className={`profileNavItemNav ${shortSidebar && "hideText"}`}  >
                <p data-value='11'>My Blogs</p>
              </div>
            </div>


            <div className={`profilenavitem ${(page == '14') ? "profilenavitemactive" : ""}`} data-value='14' onClick={(e) => optionClick(e)}>
              <div className='profileNavImg' data-value='14' >
                <img src={myfoll} data-value='14' />
              </div>
              <div data-value='14' className={`profileNavItemNav ${shortSidebar && "hideText"}`}  >
                <p data-value='14' >Follow List</p>
              </div>
            </div>


            <div
              className={`profilenavitem ${(page == '12') ? "profilenavitemactive" : ""}`}
              data-value='12'
              onClick={(e) => optionClick(e)}>
              <div
                className='profileNavImg'
                id='accountSetting'
                data-value='12'
              >
                <img src={myacc} data-value='12' />

              </div>
              <div data-value='12' className={`profileNavItemNav ${shortSidebar && "hideText"}`}  >
                <p data-value='12'>Account Setting</p>
              </div>
            </div>
          </div>
        </div>
        <div className={`myprofileMaindivSecond ${expand && 'hideMyprofileMaindivSecond'}`} style={{ width: expand && '80%' }}>
          {!innerPage && <IoArrowBack onClick={() => {
            setExpand(true)
            // setMyownblog([]);
          }} className='backIcon' />}
          <ProfileSection pageNo={page} />
        </div>
      </div>

    </>
  );
}

export default Myprofile;
