import React, { Component } from 'react'
import './terms.css'
import Footer from '../components/footer';
export default function TermsANdCondition()
{
 const [type,setType]=React.useState('terms')

 function ChangeType(e)
 {
  setType(e);
 }

 if(type==='terms')
{ return (
  <React.Fragment>
    <>
      <div className='conditions-page-cont'>
        <div className='conditions-page-inner'>
          <div className='conditions-page-top'>
            <span
              onClick={() => {
                ChangeType('terms');
              }}
            >TERMS AND CONDITIONS</span>
            <div
              onClick={() => {
                ChangeType('policy');
              }}
            >PRIVACY POLICY</div>
            <div
              onClick={() => {
                ChangeType('refund');
              }}
            >REFUND POLICY</div>
          </div>
          <div className='conditions-page-text'>
            <div class="terms_and_conditions_details">
               <div class="fo-14 fw-600">TERMS AND CONDITIONS</div>
               <div class="fo-12">These terms govern the access or your use of Putatoe Solutions Pvt Ltd, a private
                  limited company incorporated under the Companies Act 2013, having its registered office at H. No.
                  F-16-3 Munshi Puliya, Indira Nagar Lucknow, Uttar Pradesh. This document is published in accordance
                  with the provisions of rule 3(1) of the Information Technology Rules, 2011 that require publishing the
                  rules and regulations, privacy policy and terms of use for access or usage of the said application.
               </div>
               <div class="fo-14 fw-600 mt-3">PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE ACCESSING OR USING
                  THE SERVICES.</div>
               <div class="fo-12">The Terms of Service apply to all users of the Putatoe Solutions Pvt. Ltd. ‘Users’
                  means any individual, person or company. Users’ access and use of the services implies an agreement is
                  bound by these terms. If you do not agree to these terms, then you may not use or access the
                  services.<br></br>Putatoe Solutions Pvt Ltd may restrict you from accessing or using the service,
                  immediately, if the company suspects that:</div>
               <ul class="mt-3 ml-3">
                  <li class="fo-12">You have or are going to breach these terms and conditions and/or</li>
                  <li class="fo-12">You do not qualify according to the standards and policies of Putatoe Solutions
                     Pvt Ltd to use the services.</li>
               </ul>
               <div class="fo-12">Therefore, the company may restrict or deactivate your account for the above said
                  matter.</div>
               <div class="fo-14 fw-600 mt-3">Amendments</div>
               <div class="fo-12">Putatoe Solutions Pvt Ltd may amend any policies or terms time to time and hence
                  they are subject to modification. The same would be notified by posting the modified documents on the
                  platform. The continued usage of the services
                  after amending the policies would be considered as your acceptance towards the amended terms and
                  conditions. It is your responsibility to check the terms and condition of our service frequently for
                  any amendments. If you do
                  not agree with any of the future terms of use, please do not use or access the platform anymore.
               </div>
               <div class="fo-14 fw-600 mt-3">Errors, Corrections, and Right to Modify or Discontinue Service and Sites:
               </div>
               <div class="fo-12">We do not represent or warrant that the Putatoe App will be error-free, free of
                  viruses or other harmful components, or that defects will be corrected. We do not represent or warrant
                  that the information available on or through
                  the Putatoe App will be correct, accurate, timely or otherwise reliable. We may make changes to the
                  features, functionality or content of the Putatoe App at any time. We reserve the right in our sole
                  discretion to edit or delete
                  any documents, information or other content appearing on the Site. Putatoe Solutions Private Limited
                  reserves the right at any time and from time to time to modify or discontinue, temporarily or
                  permanently, the Services and/or
                  Sites (or any part thereof) with or without notice. Putatoe Solutions Pvt Ltd shall not be liable
                  to you or to any third party for any modification, suspension or discontinuance of the Service or any
                  Sites.
               </div>
               <div class="fo-14 fw-600 mt-3">Privacy policy</div>
               <div class="fo-12">Putatoe Solutions Pvt Ltd has established a privacy policy that explain how the
                  user’s personal information or data is collected, used and stored. </div>
               <div class="fo-14 fw-600 mt-3">Restrictions</div>
               <div class="fo-12">In order to use our services, you should be 18years old or above. If you are not old
                  enough to have authority to agree over the terms and conditions then, your parents or guardian must
                  agree to our terms on your behalf.</div>
               <div class="fo-12">You shall not:</div>
               <ul class="mt-3 ml-3">
                  <li class="fo-12">Attempt to gain unauthorized access to our server or any other systems of networks.
                  </li>
                  <li class="fo-12">Remove any trademark, copywrite or other proprietary notices from our services.</li>
                  <li class="fo-12">Cause or launch any programs or script with the intention of hindering the operation
                     or functionality of any portion of the services.</li>
                  <li class="fo-12">Copy, lease or otherwise tamper with the app unless specifically authorized.</li>
               </ul>
               <div class="fo-14 fw-600 mt-3">CONTENT DISCLAIMER:</div>
               <div class="fo-12">Putatoe communicates information provided and created by advertisers, content
                  partners,
                  software developers, publishers, marketing agents, employees, users, resellers and other third
                  parties.
                  While every attempt has been made to ascertain the authenticity of the content on the Platforms
                  Putatoe
                  has no control over content,the accuracy of such content, integrity or quality of such content and
                  the
                  information on our pages, and material on the Platforms may include technical inaccuracies or
                  typographical errors, and we make no guarantees, nor can we be responsible for any such information,
                  including its authenticity, currency, content, quality, copyright compliance or legality, or any other
                  intellectual property rights compliance, or any resulting loss or damage. Further, we are not liable
                  for
                  any kind of damages, losses or action arising directly or indirectly due to any content, including any
                  errors or omissions in any content, access and/or use of the content on the Platforms or any of them
                  including but not limited to content based decisions resulting in loss of data, revenue, profits,
                  property, infection by viruses etc.
               </div>
               <div class="fo-12">All of the data on products and promotions including but not limited to, the prices
                  and
                  the availability of any product or service or any feature thereof, is subject to change without notice
                  by
                  the party providing the product or promotion. You should use discretion while using the Platforms.
               </div>
               <div class="fo-12">Putatoe reserves the right, in its sole discretion and without any obligation, to make
                  improvements to, or correct any error or omissions in, any portion of the Platforms. Where
                  appropriate,
                  we will endeavor to update information listed on the Website on a timely basis, but shall not be
                  liable
                  for any inaccuracies.</div>
               <div class="fo-12">All rights, title and interest including trademarks and copyrights in respect of the
                  domain name and Platforms content hosted on the Platforms are reserved with Putatoe. Users are
                  permitted
                  to read, print or download text, data and/or graphics from the Website or any other Platforms for
                  their
                  personal use only. Unauthorized access, reproduction, redistribution, transmission and/or dealing with
                  any information contained in the platforms in any other manner, either in whole or in part, are
                  strictly
                  prohibited, failing which strict legal action will be initiated against such users</div>
               <div class="fo-12">Links to external Internet sites may be provided within the content on Website or
                  other
                  Platforms as a convenience to users. The listing of an external site does not imply endorsement of the
                  site by Putatoe or its affiliates. Putatoe does not make any representations regarding the
                  availability
                  and performance of its Platforms or any of the external websites to which we provide links. When you
                  click on advertiser banners, sponsor links, or other external links from the Website or other
                  Platforms,
                  your browser automatically may direct you to a new browser window that is not hosted or controlled by
                  Putatoe.</div>
               <div class="fo-12">Putatoe and its affiliates are not responsible for the content, functionality,
                  authenticity or technological safety of these external sites. We reserve the right to disable links to
                  or
                  from third-party sites to any of our Platforms, although we are under no obligation to do so. This
                  right
                  to disable links includes links to or from advertisers, sponsors, and content partners that may use
                  our
                  Marks as part of a co-branding relationship.</div>
               <div class="fo-12">Some external links may produce information that some people find objectionable,
                  inappropriate, or offensive. We are not responsible for the accuracy, relevancy, copyright compliance,
                  legality, or decency of material contained in any externally linked websites. We do not fully screen
                  or
                  investigate business listing websites before or after including them in directory listings that become
                  part of the Materials on our Platforms, and we make no representation and assume no responsibility
                  concerning the content that third parties submit to become listed in any of these directories.</div>
               <div class="fo-12">All those sections in the platforms that invite reader participation will
                  contain
                  views,
                  opinion, suggestion, comments and other information provided by the general public, and
                  Putatoe will
                  at
                  no point of time be responsible for the accuracy or correctness of such information.
                  putatoe
                  reserves
                  the absolute right to accept/reject information from readers and/or advertisements from advertisers
                  and
                  impose/relax Platforms access rules and regulations for any user(s).</div>
               <div class="fo-12">Putatoe also reserves the right to impose/change the access regulations of the
                  Platforms,
                  whether in terms of access fee, timings, equipment, access restrictions or otherwise, which shall be
                  posted from time to time under these terms and conditions. It is the responsibility of users to refer
                  to
                  these terms and conditions each time they use the Platforms.</div>
               <div class="fo-12">While every attempt has been made to ascertain the authenticity of the content in the
                  platforms, Putatoe is not liable for any kind of damages, losses or action arising directly
                  or
                  indirectly, due to access and/or use of the content in the platforms including but not limited
                  to any
                  decisions based on content in the platforms resulting in loss of data, revenue, profits,
                  property,
                  infection by viruses etc</div>
               <div class="fo-14 fw-600 mt-3">WARRANTY DISCLAIMER:</div>
               <div class="fo-12">Please remember that any provider of goods or services is entitled to register with
                  Putatoe. Putatoe does not examine whether the advertisers are good, reputable or quality sellers of
                  goods
                  / service providers. You must satisfy yourself about all relevant aspects prior to availing of the
                  terms
                  of service. Putatoe has also not negotiated or discussed any terms of engagement with any of the
                  advertisers. The same should be done by you. Purchasing of goods or availing of services from
                  advertisers
                  shall be at your own risk.</div>
               <div class="fo-12">We do not investigate, represent or endorse the accuracy, legality, legitimacy,
                  validity
                  or reliability of any products, services, deals, coupons or other promotions or materials, including
                  advice, ratings, and recommendations contained on, distributed through, or linked, downloaded or
                  accessed
                  from the Platforms.</div>
               <div class="fo-12">References that we make to any names, marks, products or services of third parties or
                  hypertext links to third party sites or information do not constitute or imply our endorsement,
                  sponsorship or recommendation of the third party, of the quality of any product or service, advice,
                  information or other materials displayed, purchased, or obtained by you as a result of an
                  advertisement
                  or any other information or offer in or in connection with the Platforms.</div>
               <div class="fo-12">Any use of the Platforms, reliance upon any Materials, and any use of the Internet
                  generally shall be at your sole risk. Putatoe disclaims any and all responsibility or liability for
                  the
                  accuracy, content, completeness, legality, reliability, or operability or availability of information
                  or
                  material displayed in the search results in the platforms.</div>
               <div class="fo-12"><b>THE MATERIAL AND THE PLATFORMS USED TO PROVIDE THE MATERIAL (INCLUDING THE
                     WEBSITE ) ARE PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR
                     IMPLIED
                     OR STATUTORY, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR
                     A
                     PARTICULAR PURPOSE, OR NON-INFRINGEMENT. PUTATOE DISCLAIMS, TO THE FULLEST EXTENT PERMITTED UNDER
                     LAW,
                     ANY WARRANTIES REGARDING THE SECURITY, RELIABILITY, TIMELINESS, ACCURACY AND PERFORMANCE OF THE
                     PLATFORMS
                     AND MATERIALS. PUTATOE DOES NOT WARRANT THAT ANY DEFECTS OR ERRORS WILL BE
                     CORRECTED; OR THAT THE
                     CONTENT IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.</b></div>
               <div class="fo-12"><b>PUTATOE DISCLAIMS ANY AND ALL WARRANTIES TO THE FULLEST EXTENT OF THE LAW,
                     INCLUDING ANY WARRANTIES FOR ANY INFORMATION, GOODS, OR SERVICES, OBTAINED THROUGH, ADVERTISED OR
                     RECEIVED THROUGH ANY LINKS PROVIDED BY OR THROUGH THE PLATFORMS SOME COUNTRIES OR OTHER
                     JURISDICTIONS
                     DO
                     NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU. YOU
                     MAY
                     ALSO
                     HAVE OTHER RIGHTS THAT VARY FROM COUNTRY TO COUNTRY AND JURISDICTION TO JURISDICTION.</b></div>
               <div class="fo-14 fw-600 mt-3">License</div>
               <div class="fo-12">Upon the acceptance of these terms Putatoe Solutions Pvt Ltd grants you a limited,
                  non-exclusive, revocable, no-transferable license to: i) access or use the platform on your personal
                  device ii) and use or access any content
                  or information that may be made available through these services for your own personal, non-commercial
                  use.
               </div>
               <div class="fo-14 fw-600 mt-3">The services</div>
               <div class="fo-12">Putatoe Solutions Pvt Ltd will provide the services to you under this agreement.
                  The services allow the user to use:</div>
               <ul class="mt-3 ml-3">
                  <li class="fo-12">The company’s application to arrange home based services provided by independent
                     third-parties; and </li>
                  <li class="fo-12">To make payment to the third party for the services and receive receipts for the
                     payments.</li>
                     <li class="fo-12">Personal Loans Service : We are only provide platform to get lead for our NBFC companies like ujjivan small finance and etc. so that customer can get best offers based on their eligibility.</li>
               </ul>
               <div class="fo-12">Putatoe Solutions Pvt Ltd should not be liable for the services provided (like
                  quality, error or omission) by the third party through this platform and also does not take any
                  guarantee for the services offered. Putatoe Solutions
                  Pvt Ltd is not responsible for the unsatisfactory or delays of services in respect to the third
                  parties. This platform works as a mere facilitator. Putatoe Solutions Pvt Ltd accepts liability for
                  the services and the UC
                  platform that it provides to you subject to these terms.
               </div>
               <div class="fo-14 fw-600 mt-3">AVAILABILITY:</div>
               <ol class="mt-3 ml-3">
                  <li class="fo-12">Putatoe Solutions Pvt Ltd. will use reasonable efforts to make the Application
                     available at all times. However, you acknowledge the Application is provided over the internet and
                     mobile networks and so the quality and availability
                     of the Application may be affected by factors outside Putatoe's reasonable control.
                  </li>
                  <li class="fo-12">Putatoe Solutions Pvt Ltd. does not accept any responsibility whatsoever for
                     unavailability of the Application, or any difficulty or inability to download or access content or
                     any other communication system failure which
                     may result in the Application being unavailable.
                  </li>
                  <li class="fo-12">Putatoe Solutions Pvt Ltd. will not be responsible for any support or maintenance
                     for the Application</li>
               </ol>
               <div class="fo-14 fw-600 mt-3">Ownership</div>
               <div class="fo-12">All the rights herein shall remain under Putatoe Solutions Pvt Ltd. Neither
                  these terms or use of service grant you any rights excepts the limited license granted above to use
                  in any manner the company’s names, logos, trademarks
                  or any other company’s licensors.
               </div>
               <div class="fo-14 fw-600 mt-3">No discrimination</div>
               <div class="fo-12">Putatoe Solutions Pvt Ltd prohibits discrimination against third party providers
                  based on any race, class, sex, gender identity or any other characteristics. If found you have
                  violated these provisions then you will lose the
                  access of the company’s platform.
               </div>
               <div class="fo-14 fw-600 mt-3">Payment methods</div>
               <div class="fo-12">The platforms allow the users to place order in respect to any of the services
                  provided. Upon placing the order, we may call on your phone number or telephone to confirm the
                  details of the order.</div>
               <div class="fo-12">All payments made against the order of the services should be in respect to Indian
                  rupees. The platform will not entertain any other form of currency. You can pay by i) credit
                  card/debit card or net banking ii) any other RBI approved
                  mode of method at the time of booking the services iii) cash at the time of completion of the
                  services.
               </div>
               <div class="fo-12">These platform charges payment on behalf of the third party and shall be considered
                  the same as payment has been made directly to the third-party provider. Putatoe Solutions Pvt Ltd
                  shall charge you a convenience fee for the
                  service provided by the company to you. The fee may be charged at the time of booking the service or
                  upon completion of the service. Such fee should be inclusive of applicable taxes where required by
                  the law. Charges and fees
                  paid shall be considered as final and non-refundable unless otherwise determined or required by the
                  Consumer Law Legislation.
               </div>
               <div class="fo-12">All charges and fees are due immediately and payment will be facilitated by the
                  Putatoe Solutions Pvt Ltd upon preferred payment method. Charges and fees may change due to the
                  geographical location or upon rise in demand. </div>
               <div class="fo-14 fw-600 mt-3">Limitation of liability</div>
               <div class="fo-12">Our service is provided “as is”, and we can’t guarantee it will be safe and secure
                  or will work perfectly all the time. our responsibility for anything that happens on the service
                  (also called liability) is limited as much as the
                  law will allow. <b>YOU AGREE THAT WE WON’T BE RESPONSIBLE (“LIABLE”) FOR ANY LOST PROFITS,
                     REVENUES, INFORMATION, OR DATA, OR CONSEQUENTIAL, SPECIAL, INDIRECT, EXEMPLARY, PUNITIVE, OR
                     INCIDENTAL DAMAGES ARISING OUT OF OR RELATED TO THESE TERMS EVEN IF WE KNOW THEY ARE POSSIBLE.
                     TO THE EXTENT PERMITTED BY LAW, WE ALSO DISCLAIM ALL WARANTIES, WHETHER EXPRESS OR IMPLIED,
                     INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE,
                     AND NON-INFRINGEMENT. HOWEVER, USERS CAN EXERCISE THEIR RIGHTS UNDER CONSUMER PROTECTION
                     LAW.</b>
               </div>
               <div class="fo-12"><b>PUTATOE SOLUTIONS PVT LTD WILL MAINTAIN A COMPLAINTS MANAGEMENT FRAMEWORK,
                     AND WILL MANAGE THIS FRAMEWORK ON BEHALF OF THIRD-PARTY PROVIDERS, IN A REASONALBE WAY AND IN
                     ACCORDANCE WITH THE RELEVANT CONSUMER LAW LEGISLATION.</b></div>
               <div class="fo-14 fw-600 mt-3">Governing law</div>
               <div class="fo-12">These Terms will be governed by and construed in accordance with the Indian laws,
                  without giving effect to its conflict of laws' provisions or your actual state or country of
                  residence, and you agree to submit to personal jurisdiction
                  in India. You are responsible for compliance with applicable laws. If for any reason a court of
                  competent jurisdiction finds any provision or portion of the Terms to be unenforceable, the
                  remainder of the Terms will continue
                  in full force and effect. Putatoe Solutions Pvt Ltd reserves the right to investigate complaints
                  or reported violations of these Terms and to take any action we deem necessary and appropriate.
                  Such action may include reporting
                  any suspected unlawful activity to law enforcement officials, regulators, or other third parties.
                  In addition, we may take action to disclose any information necessary or appropriate to such
                  persons or entities relating to
                  user profiles, e-mail addresses, usage history, posted materials, IP addresses and traffic
                  information. Putatoe Solutions Pvt Ltd reserves the right to seek all remedies available at law
                  and in equity for violations of these
                  Terms.
               </div>
               <div class="fo-12">You may have rights to make complaints against the third-party relating to any
                  dispute but it should be made within 48 hours form the initial occurrence of such dispute. If it is
                  made after 48 hours after the initial occurrence
                  then, Putatoe Solutions Pvt Ltd shall in its sole discretion choose whether or not to attempt
                  resolution of the dispute.
               </div>
               <div class="fo-12">Except as otherwise stated in these terms, these terms shall be exclusively
                  governed in accordance with the laws of India. In the event of dispute, either party may file an
                  action in the court of India or pursue final and binding
                  arbitration or other alternative resolution as agreed upon the parties.
               </div>

               <div class="fo-14 fw-600 mt-3">General:</div>
               <div class="fo-12">We reserve the right to accept or refuse membership on our sole discretion. You
                  shall not transfer or share your account to others for usage. For further query you may ask at:
               </div>
               <div class="fo-12"><strong>Email: putatoesolutions@putatoe.com</strong></div>
            </div>
          </div>
        </div>
      </div>

    </>
  </React.Fragment>
);}
 else if(type==='policy')
 {
  return (
    <React.Fragment>
      <>
        <div className='conditions-page-cont'>
          <div className='conditions-page-inner'>
            <div className='conditions-page-top'>
              <div
                onClick={() => {
                  ChangeType('terms');
                }}
              >
                TERMS AND CONDITIONS
              </div>
              <span
                onClick={() => {
                  ChangeType('policy');
                }}
              >
                PRIVACY POLICY
              </span>
              <div
                onClick={() => {
                  ChangeType('refund');
                }}
              >
                REFUND POLICY
              </div>
            </div>
            <div className='conditions-page-text'>
              <div class='privacy_policy_details'>
                <div class='fo-14 fw-600'>Privacy Policy</div>
                <div class='fo-12'>
                  The Privacy Policy of 
                  <a href='https://putatoe.com'>www.putatoe.com</a> (hereinafter
                  referred to as “site ") detailed herein below governs the
                  collection, possession, storage, handling and dealing of
                  personal identifiable information/data and sensitive personal
                  data (hereinafter collectively referred to as “information”)
                  of the users of the site.
                </div>
                <div class='fo-12'>
                  {' '}
                  All the users must read and understand this Privacy Policy as
                  it has been formulated to safeguard the user’s privacy. This
                  Privacy Policy also outlines the ways the users can ensure
                  protection of their personal identifiable information.
                </div>
                <div class='fo-12'>
                  You must accept the contents of this Policy in order to use or
                  continue using our site. This Privacy Policy detailed herein
                  is also applicable to user of the site or mobile application
                  through mobile or any other similar device.
                </div>

                <div class='fo-14 fw-600 mt-3'>
                  Interpretation and Definitions
                </div>
                <div class='fo-12'>
                  <b>Interpretation - </b>The words of which the initial letter
                  is capitalized have meanings defined under the following
                  definitions. The following definitions shall have the same
                  meaning regardless of whether they appear in singular or in
                  plural.
                </div>
                <div class='fo-12'>
                  <b>Definitions - </b>
                </div>
                <div class='fo-12'>
                  For the purposes of this Privacy Policy:
                </div>
                <div class='fo-12'>
                  <b>Account</b> means a unique account created for You to
                  access our Service or parts of our Service.{' '}
                </div>
                <div class='fo-12'>
                  <b>Affiliate</b> means an entity that controls, is controlled
                  by or is under common control with a party, where "control"
                  means ownership of 50% or more of the shares, equity interest
                  or other securities entitled to vote for election of directors
                  or other managing authority.
                </div>
                <div class='fo-12'>
                  <b>Application</b> means the software program provided by the
                  Company downloaded by You on any electronic device, named
                  Putatoe Company (referred to as either "the Company", "We",
                  "Us" or "Our" in this Agreement) refers to Putatoe Solutions
                  Pvt. Ltd., H. No. F-16-3 Munshi Puliya, Indira Nagar LKO
                  LUCKNOW Lucknow, Uttar Pradesh.
                </div>
                <div class='fo-12'>
                  <b>Device</b> means any device that can access the Service
                  such as a computer, a cellphone or a digital tablet.{' '}
                </div>
                <div class='fo-12'>
                  <b>Personal Data</b>  is any information that relates to an
                  identified or identifiable individual.{' '}
                </div>
                <div class='fo-12'>
                  <b>Service</b> refers to the services provide by the
                  Application and through the Application.{' '}
                </div>
                <div class='fo-12'>
                  <b>Service Provider</b> means any natural or legal person who
                  processes the data on behalf of the Company. It refers to
                  third-party companies or individuals employed by the Company
                  to facilitate the Service, to provide the Service on behalf of
                  the Company, to perform services related to the Service or to
                  assist the Company in analyzing how the Service is used.
                </div>
                <div class='fo-12'>
                  <b>Third-party Social Media Service</b> refers to any website
                  or any social network website through which a User can log in
                  or create an account to use the Service.{' '}
                </div>
                <div class='fo-12'>
                  <b>Usage Data</b> refers to data collected automatically,
                  either generated by the use of the Service or from the Service
                  infrastructure itself (for example, the duration of a page
                  visit).
                </div>
                <div class='fo-12'>
                  <b>You</b> means the individual accessing or using the
                  Service, or the company, or other legal entity on behalf of
                  which such individual is accessing or using the Service, as
                  applicable.{' '}
                </div>
                <div class='fo-14 fw-600 mt-3'>COLLECTION OF INFORMATION</div>

                <div class='fo-12'>
                  We confirm that we collect those information from you which is
                  required to extend the services available on the site. While
                  using Our Service, At the time of signing up and registration
                  with the site, We may ask You to provide Us with certain
                  personally identifiable information that can be used to
                  contact or identify You. Personally, identifiable information
                  may include, but is not limited to:
                </div>
                <ul class='mt-3 ml-3'>
                  <li class='fo-12'>Email address</li>
                  <li class='fo-12'>First name and Last name</li>
                  <li class='fo-12'>Phone number</li>
                  <li class='fo-12'>
                    Address, State, Province, ZIP/Postal code, City
                  </li>
                </ul>
                <ul>
                  <li class='fo-12'>
                    Once a user registers, the user is no longer anonymous to us
                    and thus all the information provided by you shall be
                    stored, possessed in order to provide you with the requested
                    services and as may be required for compliance with
                    statutory requirements.
                  </li>
                  <li class='fo-12'>
                    User’s registration with us and providing information is
                    intended for facilitating the users in its business.
                  </li>
                  <li class='fo-12'>
                    While using Our Application, in order to provide features of
                    Our Application, We may collect, with Your prior permission
                    Information regarding your location for better experience of
                    our services, to improve and customize Our Services.
                  </li>
                  <li class='fo-12'>
                    We are uploading user’s Primary Account information to
                    process.env.REACT_APP_API_BASE_URL (hereinafter
                    referred to as cloud).
                  </li>
                  <li class='fo-12'>
                    We retains user provided Information for as long as the
                    Information is required for the purpose of providing
                    services to you or where the same is required for any
                    purpose for which the Information can be lawfully processed
                    or retained as required under any statutory enactments or
                    applicable laws.
                  </li>
                </ul>

                <div class='fo-14 mt-3'>
                  <b>Usage Data</b>
                </div>
                <div class='fo-12'>
                  Usage Data is collected automatically when using the Service.
                  <div class='fo-12'>
                    Usage Data may include information such as Your Device's
                    Internet Protocol address, browser type, browser version,
                    the pages of our Service that You visit, the time and date
                    of Your visit, the time spent on those pages, unique device
                    identifiers and other diagnostic data.
                  </div>
                  <div class='fo-12'>
                    When You access the Service by or through a mobile device,
                    We may collect certain information automatically, including,
                    but not limited to, the type of mobile device You use, Your
                    mobile device unique ID, the IP address of Your mobile
                    device, Your mobile operating system, the type of mobile
                    Internet browser You use, unique device identifiers and
                    other diagnostic data.
                  </div>
                  <div class='fo-12'>
                    We may also collect information that Your browser sends
                    whenever You visit our Service or when You access the
                    Service by or through a mobile device.{' '}
                  </div>
                </div>
                <div class='fo-14  mt-3'>
                  <b>Information from Third-Party Social Media Services</b>
                </div>
                <div class='fo-12'>
                  The Company allows You to create an account and log in to use
                  the Service through the following Third-party Social Media
                  Services:
                </div>
                <ul class='mt-3 ml-3'>
                  <li class='fo-12'>Google</li>
                  <li class='fo-12'>Facebook</li>
                  <li class='fo-12'>Twitter</li>
                </ul>
                <div class='fo-12'>
                  If You decide to register through or otherwise grant us access
                  to a Third-Party Social Media Service, We may collect Personal
                  data that is already associated with Your Third-Party Social
                  Media Service's account, such as Your name, Your email
                  address, Your activities or Your contact list associated with
                  that account.
                </div>
                <div class='fo-12'>
                  You may also have the option of sharing additional information
                  with the Company through Your Third-Party Social Media
                  Service's account. If You choose to provide such information
                  and Personal Data, during registration or otherwise, You are
                  giving the Company permission to use, share, and store it in a
                  manner consistent with this Privacy Policy.
                </div>

                <div class='fo-14 mt-3'>
                  <b>PURPOSE AND USAGE OF INFORMATION</b>
                </div>
                <div class='fo-12'>
                  The following are the purposes of collecting the Information:
                </div>
                <ul class='mt-3 ml-3'>
                  <li class='fo-12'>
                    For the verification of your identity, eligibility,
                    registration and to provide customized services.{' '}
                  </li>
                  <li class='fo-12'>
                    For facilitating the services offered/available on the site.{' '}
                  </li>
                  <li class='fo-12'>
                    For advertising, marketing, displaying & publication.{' '}
                  </li>
                  <li class='fo-12'>
                    For enabling communication with the users of the site, so
                    that the users may fetch maximum business opportunities.{' '}
                  </li>
                  <li class='fo-12'>
                    To provide and maintain our Service, including to monitor
                    the usage of our Service.{' '}
                  </li>
                  <li class='fo-12'>
                    To manage Your Account: to manage Your registration as a
                    user of the Service. The Personal Data You provide can give
                    You access to different functionalities of the Service that
                    are available to You as a registered user.{' '}
                  </li>
                  <li class='fo-12'>
                    For the performance of a contract: the development,
                    compliance and undertaking of the purchase contract for the
                    products, items or services You have purchased or of any
                    other contract with Us through the Service.
                  </li>
                  <li class='fo-12'>
                    To contact You: To contact You by email, telephone calls,
                    SMS, or other equivalent forms of electronic communication,
                    such as a mobile application's push notifications regarding
                    updates or informative communications related to the
                    functionalities, products or contracted services, including
                    the security updates, when necessary or reasonable for their
                    implementation.{' '}
                  </li>
                  <li class='fo-12'>
                    To provide You with news, special offers and general
                    information about other goods, services and events which we
                    offer that are similar to those that you have already
                    purchased or enquired about unless You have opted not to
                    receive such information.{' '}
                  </li>
                  <li class='fo-12'>
                    To manage Your requests: To attend and manage Your requests
                    to Us.{' '}
                  </li>
                  <li class='fo-12'>
                    For business transfers: We may use Your information to
                    evaluate or conduct a merger, divestiture, restructuring,
                    reorganization, dissolution, or other sale or transfer of
                    some or all of Our assets, whether as a going concern or as
                    part of bankruptcy, liquidation, or similar proceeding, in
                    which Personal Data held by Us about our Service users is
                    among the assets transferred.{' '}
                  </li>
                  <li class='fo-12'>
                    For other purposes: We may use Your information for other
                    purposes, such as data analysis, identifying usage trends,
                    determining the effectiveness of our promotional campaigns
                    and to evaluate and improve our Service, products, services,
                    marketing and your experience.{' '}
                  </li>
                </ul>
                <div class='fo-12'>
                  Please get in touch with us at the above email address in case
                  you would like to object to any purpose of data processing.
                  However, please note that if you object or withdraw consent to
                  process data as above, we may discontinue providing you with
                  services through our site.
                </div>

                <div class='fo-14 fw-600 mt-3'>
                  We may share Your personal information in the following
                  situations:{' '}
                </div>
                <ul class='mt-3 ml-3'>
                  <li class='fo-12'>
                    With Service Providers: We may share Your personal
                    information with Service Providers to monitor and analyze
                    the use of our Service, to contact You.{' '}
                  </li>

                  <li class='fo-12'>
                    For business transfers: We may share or transfer Your
                    personal information in connection with, or during
                    negotiations of, any merger, sale of Company assets,
                    financing, or acquisition of all or a portion of Our
                    business to another company.{' '}
                  </li>
                  <li class='fo-12'>
                    With Affiliates: We may share Your information with Our
                    affiliates, in which case we will require those affiliates
                    to honor this Privacy Policy. Affiliates include Our parent
                    company or any other subsidiaries, joint venture partners or
                    other companies that We control or that are under common
                    control with Us.
                  </li>
                  <li class='fo-12'>
                    With business partners: We may share Your information with
                    Our business partners to offer You certain products,
                    services or promotions.{' '}
                  </li>
                  <li class='fo-12'>
                    With other users: when You share personal information or
                    otherwise interact in the public areas with other users,
                    such information may be viewed by all users and may be
                    publicly distributed outside. If You interact with other
                    users or register through a Third-Party Social Media
                    Service, Your contacts on the Third-Party Social Media
                    Service may see Your name, profile, pictures and description
                    of Your activity. Similarly, other users will be able to
                    view descriptions of Your activity, communicate with You and
                    view Your profile.{' '}
                  </li>
                  <li class='fo-12'>
                    With Your consent: We may disclose Your personal information
                    for any other purpose with Your consent. Retention of Your
                    Personal Data{' '}
                  </li>
                </ul>
                <div class='fo-12'>
                  The Company will retain Your Personal Data only for as long as
                  is necessary for the purposes set out in this Privacy Policy.
                  We will retain and use Your Personal Data to the extent
                  necessary to comply with our legal obligations (for example,
                  if we are required to retain your data to comply with
                  applicable laws), resolve disputes, and enforce our legal
                  agreements and policies.
                </div>
                <div class='fo-12'>
                  The Company will also retain Usage Data for internal analysis
                  purposes. Usage Data is generally retained for a shorter
                  period of time, except when this data is used to strengthen
                  the security or to improve the functionality of Our Service,
                  or We are legally obligated to retain this data for longer
                  time periods. Transfer of Your Personal Data{' '}
                </div>
                <div class='fo-12'>
                  Your information, including Personal Data, is processed at the
                  Company's operating offices and in any other places where the
                  parties involved in the processing are located. It means that
                  this information may be transferred to — and maintained on —
                  computers located outside of Your state, province, country or
                  other governmental jurisdiction where the data protection laws
                  may differ than those from Your jurisdiction.{' '}
                </div>
                <div class='fo-12'>
                  Your consent to this Privacy Policy followed by Your
                  submission of such information represents Your agreement to
                  that transfer.{' '}
                </div>
                <div class='fo-12'>
                  The Company will take all steps reasonably necessary to ensure
                  that Your data is treated securely and in accordance with this
                  Privacy Policy and no transfer of Your Personal Data will take
                  place to an organization or a country unless there are
                  adequate controls in place including the security of Your data
                  and other personal information.{' '}
                </div>

                <div class='fo-14 fw-600 mt-3'>
                  Disclosure of Your Personal Data
                </div>
                <div class='fo-12'>
                  <b>Business Transactions</b>
                </div>
                <div class='fo-12'>
                  If the Company is involved in a merger, acquisition or asset
                  sale, Your Personal Data may be transferred. We will provide
                  notice before Your Personal Data is transferred and becomes
                  subject to a different Privacy Policy.
                </div>
                <div class='fo-12'>
                  <b>Law enforcement</b>
                </div>
                <div class='fo-12'>
                  Under certain circumstances, the Company may be required to
                  disclose Your Personal Data if required to do so by law or in
                  response to valid requests by public authorities (e.g., a
                  court or a government agency).
                </div>
                <div class='fo-12'>
                  <b>Other legal requirements</b>
                </div>
                <div class='fo-12'>
                  The Company may disclose Your Personal Data in the good faith
                  belief that such action is necessary to:
                </div>
                <ul class='mt-3 ml-3'>
                  <li class='fo-12'>Comply with a legal obligation</li>
                  <li class='fo-12'>
                    Protect and defend the rights or property of the Company
                  </li>
                  <li class='fo-12'>
                    Prevent or investigate possible wrongdoing in connection
                    with the Service
                  </li>
                  <li class='fo-12'>
                    Protect the personal safety of Users of the Service or the
                    public
                  </li>
                  <li class='fo-12'>Protect against legal liability</li>
                </ul>

                <div class='fo-12'>
                  <b>Changes in data</b>{' '}
                </div>
                <div class='fo-12'>
                  User may update, correct, or confirm provided information by
                  logging on to their accounts on the site or by sending a
                  request to PutatoeCare@putatoe.com. The requested changes may
                  take reasonable time due to verification process and server
                  cache policies. In case you would like to receive a copy of
                  our information held by us for porting to another service,
                  please contact us with your request at the email address
                  above. Users may also choose to delete or deactivate their
                  accounts on the site. We will evaluate such requests on a
                  case-to-case basis and take the requisite action as per
                  applicable law. In this regard, please note that information
                  sought to be deleted may remain with us in archival records
                  for the purpose of compliance of statutory enactments, or for
                  any other lawful purpose. Therefore, users are requested to
                  carefully evaluate what types of information they would like
                  to provide to us at the time of registration.
                </div>
                <div class='fo-14 fw-600 mt-3'>
                  REASONABLE PROTECTION OF INFORMATION
                </div>
                <div class='fo-12'>
                  We employ commercially reasonable and industry-standard
                  security measures to prevent unauthorized access, maintain
                  data accuracy and ensure proper use of information we receive.
                </div>
                <div class='fo-12'>
                  These security measures are both electronic as well as
                  physical but at the same time no data transmission over the
                  Internet can be guaranteed to be 100% secure. We strive to
                  protect the User Information, although we cannot ensure the
                  security of Information furnished/transmitted by the users to
                  us.
                </div>
                <div class='fo-12'>
                  We recommend you not to disclose password of your email
                  address, online bank transaction and other important
                  credentials to our employees / agents / affiliates/ personnel,
                  as we do not ask for the same.
                </div>
                <div class='fo-12'>
                  {' '}
                  We recommend that registered users not to share their site’s
                  account password and also to sign out of their account when
                  they have completed their work. This is to ensure that others
                  cannot access Information of the users and correspondence, if
                  the user shares the computer with someone else or is using a
                  computer in a public place
                </div>
                <div class='fo-12'>
                  {' '}
                  We recommend you not to disclose password of your email
                  address, online bank transaction and other important
                  credentials to our employees / agents / affiliates/ personnel,
                  as we do not ask for the same
                </div>
                <div class='fo-14 fw-600 mt-3'>COOKIES</div>
                <div class='fo-12'>
                  We, and third parties with whom we partner, may use cookies,
                  pixel tags, web beacons, mobile device IDs, “flash cookies”
                  and similar files or technologies to collect and store
                  information in respect to your use of the site and track your
                  visits to third party websites.
                </div>
                <div class='fo-12'>
                  We also use cookies to recognize your browser software and to
                  provide features such as recommendations and personalization.
                </div>
                <div class='fo-12'>
                  Third parties whose products or services are accessible or
                  advertised through the site, including social media sites, may
                  also use cookies or similar tools, and we advise you to check
                  their privacy policies for information about their cookies and
                  the practices followed by them. We do not control the
                  practices of third parties and their privacy policies govern
                  their interactions with you.
                </div>
                <div class='fo-14 fw-600 mt-3'>
                  DATA COLLECTION RELATING TO CHILDREN
                </div>
                <div class='fo-12'>
                  We strongly believe in protecting the privacy of children. In
                  line with this belief, we do not knowingly collect or maintain
                  Personally Identifiable Information on our Site from persons
                  under 18 years of age, and no part of our Site is directed to
                  persons under 18 years of age. If you are under 18 years of
                  age, then please do not use or access our services at any time
                  or in any manner. We will take appropriate steps to delete any
                  Personally Identifiable Information of persons less than 18
                  years of age that has been collected on our Site without
                  verified parental consent upon learning of the existence of
                  such Personally Identifiable Information.
                </div>
                <div class='fo-12'>
                  If we become aware that a person submitting personal
                  information is under 18, we will delete the account and all
                  related information as soon as possible. . If you believe we
                  might have any information from or about a child under 18
                  please contact us at PutatoeCare@putatoe.com
                </div>
                <div class='fo-14 fw-600 mt-3'>DATA TRANSFERS</div>
                <div class='fo-12'>
                  User Information that we collect may be transferred to, and
                  stored at, any of our affiliates, partners or service
                  providers which may be inside or outside the country you
                  reside in. By submitting your personal data, you agree to such
                  transfers.
                </div>
                <div class='fo-12'>
                  Your Personal Information may be transferred to countries that
                  do not have the same data protection laws as the country in
                  which you initially provided the information. When we transfer
                  or disclose your Personal Information to other countries, we
                  will protect that information as described in this Privacy
                  Policy. relevant, we will ensure appropriate contractual
                  safeguards to ensure that your information is processed with
                  the highest standards of transparency and fairness.
                </div>
                <div class='fo-14 fw-600 mt-3'>
                  Changes to this Privacy Policy
                </div>
                <div class='fo-12'>
                  We may update Our Privacy Policy from time to time. We will
                  notify You of any changes by posting the new Privacy Policy on
                  this page.
                </div>
                <div class='fo-12'>
                  We will let You know via email and/or a prominent notice on
                  Our Service, prior to the change becoming effective and update
                  the "Last updated" date at the top of this Privacy Policy.{' '}
                </div>
                <div class='fo-12'>
                  You are advised to review this Privacy Policy periodically for
                  any changes. Changes to this Privacy Policy are effective when
                  they are posted on this page.
                </div>
                <div class='fo-14 fw-600 mt-3'>QUESTIONS</div>
                <div class='fo-12'>
                  Please contact us regarding any questions, clarifications, or
                  grievances. Please email us at PutatoeCare@putatoe.com
                </div>
                <div class='fo-14 fw-600 mt-3'>GRIEVANCE OFFICER</div>
                <div class='fo-12'>
                  In accordance with the applicable laws, the name and details
                  of the Grievance officer are provided below:
                </div>
                <div class='fo-12'>Pradeep Srivastava</div>
                <div class='fo-12'>
                  Address: 233, Harahawa phatak ,Post Basharatpur Gorakhpur U.P,
                  273004{' '}
                </div>
                <div class='fo-12'>Email Address: grievances@putatoe.com</div>
                <div class='fo-14 fw-600 mt-3'>COMPLIANCE OFFICER </div>
                <div class='fo-12'>
                  In accordance with the applicable laws, the name and details
                  of the compliance officer are provided below:{' '}
                </div>
                <div class='fo-12'>Pradeep Srivastava</div>
                <div class='fo-12'>Contact Number: 8840834637</div>
                <div class='fo-12'>Email Address: compliance@putatoe.com</div>
                <div class='fo-14 fw-600 mt-3'>Contact Us</div>
                <div class='fo-12'>
                  If you have any questions about this Privacy Policy, You can
                  contact us by email: putatoesolutions@putatoe.com{' '}
                </div>
              </div>
            </div>
          </div>
        </div>

      </>
    </React.Fragment>
  );
 }
 else if(type==='refund')
 {
  return (
    <React.Fragment>
      <>
        <div className='conditions-page-cont'>
          <div className='conditions-page-inner'>
            <div className='conditions-page-top'>
              <div
                onClick={() => {
                  ChangeType('terms');
                }}
              >
                TERMS AND CONDITIONS
              </div>
              <div
                onClick={() => {
                  ChangeType('policy');
                }}
              >
                PRIVACY POLICY
              </div>
              <span
                onClick={() => {
                  ChangeType('refund');
                }}
              >
                REFUND POLICY
              </span>
            </div>
            <div className='conditions-page-text'>
             <div class="refund_policy_details" id="refund_policy_details">
               <div class="fo-14 fw-600">Replacement Policy</div>
               <div class="fo-12">Replacement of a product is allowed only under the following circumstances:
               </div>
               <ul class="mt-3 ml-3">
                  <li class="fo-12">The customer can replace the product unit received within 7 days from the date of
                     delivery and get a replacement unit delivered to the customer.</li>
                  <li class="fo-12">Replacement can be made if the customer establishes that the product delivered in
                     defective condition or has a physical damage within 24 hours of receipt of the product</li>
                  <li class="fo-12">Replacement with a fresh box unit can be provided to the customer if the customer
                     establishes that the product delivered is not functioning properly within 7 days from the date of
                     delivery.</li>
                  <li class="fo-12">In case of receipt of damaged or defective items please report the same to our
                     website support team. The request will, however, be entertained once a boAt team member has checked
                     and determined the same at his own end. This is typically reported back to the customer within 24
                     hours of receipt of the products by boAt.</li>
                  <li class="fo-12">In case you feel that the product received is not as shown on the site or as per
                     the specifications, you must bring it to the notice of our website support team within 24 hours of
                     receiving the product. The website support team after looking into your complaint will take an
                     appropriate decision.</li>
                  <li class="fo-12">In case of replacement, the whole product package with the product and its
                     accessories intact will be picked up by the courier boy arranged by us.</li>
                  <li class="fo-12">A product will not be replaced under the following circumstances:
                     <ul class="mt-3 ml-3">
                        <li class="fo-12">Customer is not happy with the look/sound quality of a defect free product
                        </li>
                        <li class="fo-12">Physical damage not notified within 24 hours of receiving the product</li>
                        <li class="fo-12">An electrical surge or any damage caused by the user.</li>
                     </ul>
                  </li>
               </ul>
               <div class="fo-12">Please see that no return or refund shall be provided. However, the customer can
                  replace the product unit received within 7 days from the date of delivery and get a replacement.</div>
               <div class="fo-14 fw-600 mt-3">Cancellation Policy</div>
               <div class="fo-12">We believe in helping our customers as far as possible, and has therefore a liberal
                  cancellation policy. However, under this policy:</div>
               <ul class="mt-3 ml-3">
                  <li class="fo-12">Cancellations request will not be entertained if the orders have already been
                     communicated to the customers and they have initiated the process of shipping them.</li>
                  <li class="fo-12">There is no cancellation of orders placed under the Same Day Delivery category.</li>
                  <li class="fo-12">No cancellations are entertained for those products that have been obtained on
                     special
                     occasions. (These are limited occasion offers and therefore cancellations are not possible.)</li>
               </ul>
               <div class="fo-14 fw-600 mt-3">Product Pricing</div>
               <ul class="mt-3 ml-3">
                  <li class="fo-12">Product prices listed on the website are current. While every care has been taken to
                     label the products accurately, errors in data entry and updating may occur. We reserve the right to
                     cancel the order incase a transaction has been made where the price indicated was not the correct
                     price. In the rare event that happens, we will give a full refund of all money received from the
                     customer.</li>
                  <li class="fo-12">Prices are subject to change without advance notice.</li>
                  <li class="fo-12">All prices on this web site in Indian Rupees. For international cards used on the
                     site,
                     the banks applicable exchange rate and charges will apply. We have no jurisdiction on this and any
                     disputes or queries on exchange rates and bank charges need to be directed to the bank or
                     institution
                     that issued your card or payment instrument</li>
                  <li class="fo-12">All orders are acknowledged at current pricing. We will bill at price in effect at
                     the
                     time of receipt of money and raising of an invoice.</li>
                  <li class="fo-12">Our products are liable for GST in India.</li>
               </ul>
               <div class="fo-14 fw-600 mt-3">Security</div>
               <ul class="mt-3 ml-3">
                  <li class="fo-12">Transactions on the website are protected by SSL (Secure Sockets Layer) and Secure
                     Data Encryption using a 1024-bit process. Any information you enter when transacting with the
                     webstore is sent in a Secure Socket Layer (SSL) session and is encrypted to protect you against
                     unintentional disclosure to third parties. This is an assurance that it follows the best security
                     practices adopted by major online vendors where all payments are processed in real-time for your
                     security and immediate peace of mind.</li>
                  <li class="fo-12">Credit card and Debit card information is not stored by us and is not taken by us.
                     This information is taken directly by the payment gateway provided who is authorised and is
                     compliant with the regulations and requirements of various banks and institutions and payment
                     franchisees that it is associated with.</li>
                  <li class="fo-12">To report security vulnerabilities affecting any of our products send an email to
                     info@putatoe.com.</li>
               </ul>
               <div class="fo-14 fw-600 mt-3">Out of Stock situations</div>
               <ul class="mt-3 ml-3">
                  <li class="fo-12">Our endeavor is to ensure that all products listed on the site are available in
                     stock for dispatch to the buyer. However, in case the same is not available for any reason, we will
                     contact you within 2 business days and give you the option to exchange, delay or cancel the order
                     based on your convenience.</li>
               </ul>
               <div class="fo-14 fw-600 mt-3">Delivery of products</div>
               <ul class="mt-3 ml-3">
                  <li class="fo-12">Purchases are shipped from our own or third-party warehouses/ stores or offices from
                     various points in India. Our endeavor is to make the products reach you within 7-10 Business days
                     from receipt of order and payment depending on the address for delivery.</li>
                  <li class="fo-12">Order deliveries will be made by our own staff or third-party courier and logistics
                     companies or Postal Services usually between 10.30 am – 7 pm Monday –Saturday.</li>
                  <li class="fo-12">Goods will need to be signed for upon delivery. We take no responsibility for goods
                     signed by an alternative person other than the person ordering the product at the address indicated
                     at the time of the order.</li>
                  <li class="fo-12">Since the transactions are authorised by the card holder, we do not take
                     responsibility for incorrect addresses provided at the time of placing the order</li>
                  <li class="fo-12">We are not responsible for damage of products after delivery.</li>
                  <li class="fo-12">All claims, if any, for shortages or damages must be reported to customer service on
                     the day of delivery through the contact us page on the web store. Also, the said shortage of goods
                     needs be highlighted and signed on the Proof of delivery copy and returned to the delivery person.
                  </li>
                  <li class="fo-12">In case your order has not been delivered but is reflecting “Delivered” on the
                     tracker, please highlight it within 24 hours or else we will not be able to process your request.
                  </li>
               </ul>
               <div class="fo-14 fw-600 mt-3">Delivery Charges</div>
               <ul class="mt-3 ml-3">
                  <li class="fo-12">Shipping and handling rates may vary based on product, packaging, size, volume, type
                     and other considerations. The shipping and handling charges are given at the time of check out and
                     consumers will know about this before making payments.</li>
               </ul>
            </div>
            </div>
          </div>
        </div>
      </>
    </React.Fragment>
  );
 }
}