import React, { Component } from "react";
import "./css/mobileReachargeView.css";
import "./css/plancar.css";
import Footer from "../components/footer";
import { BiMenuAltLeft } from "react-icons/bi";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { useGlobalContext } from "../context";
import emptypage from "../images/chatImages/image3.svg";
import emptyoption from "../images/recharge1.svg";
import mobileApi from "../api/mobileApi";
import notify from "../error";
import account from "../images/DTh/account.png";
import address from "../images/DTh/address.png";
import orders from "../images/DTh/orders.png";
import payment from "../images/DTh/payment.png";
import refer from "../images/DTh/refer.png";
import wallet from "../images/DTh/wallet.png";
import arrow from "../images/arrowRech.svg";
import DTHServiceSection from ".././DthRecharge/DthServiceSection";
import arrow2 from "../images/arrowRech2.svg";
import ViewRechargeDetail from "./ViewRecharge";
import MobileHistory from "./mobileRechargeHistory";
import { useNavigate } from "react-router-dom";
export default function MobileRechargeView() {
  const { loginDetail, Userprofile,rechargepage, setRechargePage } = useGlobalContext();
  const [viewDetail, setViewDetail] = React.useState(false);
  const [allOperator, setAllOperator] = React.useState();
  const [allCircle, setAllCircle] = React.useState();
  const [allPlans, setAllPlans] = React.useState();
  const [allPlanType, setAllPlanType] = React.useState();
  const [selectedType, setSelectedType] = React.useState("Talktime");
  const [loading, setLoading] = React.useState(false);

  const [formData, setFormData] = React.useState({
    mobile: "",
    operator: "",
    op_code: "",
    circle_code: "",
    circle: "",
    amount: "",
  });

  const scrollref = React.useRef(null);
  function ScrollRight() {
    console.log("scrol");
    scrollref.current.scrollLeft += 80;
  }
  function ScrollLeft() {
    scrollref.current.scrollLeft -= 80;
  }

  function validateloanmobilenumber(value) {
    const regexExp = /^[6-9]\d{9}$/gi;
    if (
      value.length > 10 ||
      value.length < 10 ||
      regexExp.test(value === false)
    ) {
      notify("error", "Invalid mobile number");
    }
    console.log(regexExp.test(value));
    return regexExp.test(value);
  }
  function DecidePage(e) {
    console.log(e.target.dataset.value);
    // page=e.target.dataset.value;
    console.log(rechargepage);
    // console.log(page)  ////see react doesnot rerender child even when ref is passed as a prop
    setRechargePage(e.target.dataset.value);
  }
  // function handleEnter(event) {
  //   if (event.key === 'Enter') {
  //     var value = formData.mobile;
  //       console.log(value);
  //       if (value.length === 10) {
  //         if (!validateloanmobilenumber(value)) {
  //           if (isNaN(value) !== true) {
  //             fetchCircle(value);
  //           }
  //         }
  //     }
  //   }
  // }

  // function handleChange(event) {
  //   // console.log(event);
  //   const { name, value, type, checked } = event.target;
  //   setFormData((prevFormData) => {
  //     return {
  //       ...prevFormData,
  //       [name]: type === 'checkbox' ? checked : value,
  //     };
  //   });
  //   if (name === 'mobile') {
  //     console.log(value);
  //     if (value.length === 10) {
  //       if (!validateloanmobilenumber(value)) {
  //         if (isNaN(value) !== true) {
  //           setAllPlans(null);
  //           setLoading(true);
  //           fetchCircle(value);
  //         }
  //       }
  //     }
  //   }
  //   console.log(formData);
  // }
  // React.useEffect(() => {
  //   // Cleanup function to reset rechargepage when component unmounts
  //   console.log("1222")
  //   return () => {
  //     setRechargePage(0); // Reset rechargepage to an empty value
  //   };
  // }, [setRechargePage]);

  const fetchPlans = async (operator, circle) => {
    setLoading(true);
    const response2 = await mobileApi.GetPLan("", operator, circle, 1);
    console.log(response2);
    setSelectedType(response2.data.details.data[0].recharge_type);
    if (response2.data.error !== "") {
      notify("error", response2.data.error);
      setLoading(false);
    }
    setAllPlans(response2.data.details.data);

    var unique = [];
    var distinct = [];
    for (let i = 0; i < response2.data.details.data.length; i++) {
      if (!unique[response2.data.details.data[i].recharge_type]) {
        distinct.push(response2.data.details.data[i].recharge_type);
        unique[response2.data.details.data[i].recharge_type] = 1;
      }
    }
    setLoading(false);

    setAllPlanType(distinct);
    console.log("plan type", distinct);
  };

  const fetchCircle = async (value) => {
    const response = await mobileApi.FetchCircle(value);
    setLoading(true);
    console.log(response);
    if (response.data["details"]["message"] == "Invalid number") {
      notify("error", "Invalid Number");
      setLoading(false);
      return;
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      circle_code: response.data.details.data.circle_code,
      op_code:
        response.data.details.data.op_code === "BSNL Recharge/Validity (RCV)"
          ? "BSNL 3G"
          : response.data.details.data.op_code,
      circle: response.data.details.data.circle,
      operator: response.data.details.data.operator,
    }));
    console.log(formData);
    setLoading(true);
    setTimeout(() => {
      console.log(formData, "here", response.data);
      fetchPlans(
        response.data.details.data.operator,
        response.data.details.data.circle
      );
    }, 0);
  };

  const selectionOption = async () => {
    const response = await mobileApi.GetAllOperator();
    setAllOperator(response.data.operator_list);
  };

  React.useEffect(() => {
    selectionOption();
  }, []);

  function ChangePlanType(i) {
    setSelectedType(allPlanType[i]);
  }

  function selectplan(val) {
    setFormData((prevFormData) => ({ ...prevFormData, amount: val }));
    // notify('success', 'selected plan');
  }

  function AllowMeTOViewDetail(detail) {
    setViewDetail(detail);
    console.log(detail);
  }
  const [history, setHistory] = React.useState(false);
  function AllowMeTOViewHistory() {
    setHistory(!history);
    console.log(history);
  }

  const navigate = useNavigate();

  const moveToCart = async () => {
    if (
      !formData.op_code ||
      !formData.circle_code ||
      !formData.amount ||
      !formData.mobile
    ) {
      return;
    } else if (validateloanmobilenumber(formData.mobile)) {
      notify("error", "Invalid Mobile Number22");
      return;
    } else {
      let name = "";
      if (formData.op_code === "JIO") {
        name = "Reliance Jio";
      } else if (formData.op_code === "AI") {
        name = "Airtel";
      } else if (formData.op_code === "VF") {
        name = "Vi";
      } else if (formData.op_code === "BSNL") {
        name = "BSNL";
      } else if (formData.op_code === "BSS") {
        name = "BSNL(STV)";
      } else if (formData.op_code === "BSV") {
        name = "BSNL 3G";
      }
      if (formData.mobile.length === 10) {
        if (!validateloanmobilenumber(formData.mobile)) {
          if (isNaN(formData.mobile) !== true) {
            navigate("/cart/recharge", {
              replace: true,
              state: {
                op_code: formData.op_code,
                serial_no: formData.mobile,
                amount: formData.amount,
                circle: 2,
                op_image: "",
                text1: `Mobile Number ${formData.mobile}`,
                text2: `Recharge ${name} with `,
              },
            });
          }
        }
      }
    }
  };
  const { expand, setExpand } = useGlobalContext();
  function handleExpandClick() {
    setExpand((expand) => !expand);
    setRechargePage("0");
    // setSidebarMenu((prevState) =>!prevState);
  }
  return (
    <React.Fragment>
      <>
        {Userprofile ? (
          <div className="recharge-container">
            {viewDetail && (
              <ViewRechargeDetail
                data={viewDetail}
                close={() => {
                  AllowMeTOViewDetail(false);
                }}
              ></ViewRechargeDetail>
            )}
            {history && (
              <MobileHistory
                close={() => {
                  AllowMeTOViewHistory();
                }}
              ></MobileHistory>
            )}
            <div className="myprofileMainDiv" style={{ overflowY: "scroll" }}>
              {!expand && (
                <div className="myprofileExpandDiv">
                  <IoIosArrowDropleftCircle
                    className="expand-icon"
                    onClick={() => handleExpandClick()}
                  />
                </div>
              )}
              <div
                className={`myprofileMainDivFirstRecharge ${
                  !expand ? "closeSidebar" : ""
                }`}
              >
                <div className="myprofileprofleleftsideNav">
                  <div
                    className="profilenavitem"
                    style={{ padding: 0, pointerEvents: "none" }}
                    onClick={(e) => {
                      setExpand(false);
                      DecidePage(e);
                    }}
                  >
                    <div className="profileNavItemNav">
                      <p style={{ fontSize: "18px" }}>
                        <b>Recharge</b>
                      </p>
                    </div>
                  </div>

                  <div
                    className="profilenavitem"
                    data-value="3"
                    // onClick={DecidePage}
                    onClick={(e) => {
                      setExpand(false);
                      DecidePage(e);
                    }}
                  >
                    <div className="profileNavImg" data-value="3">
                      <img src={wallet} data-value="3" />
                    </div>
                    <div className="profileNavItemNav" data-value="3">
                      <p data-value="3">PrePaid</p>
                    </div>
                  </div>

                  <div
                    className="profilenavitem"
                    data-value="2"
                    onClick={(e) => {
                      setExpand(false);
                      DecidePage(e);
                    }}
                  >
                    <div className="profileNavImg" data-value="2">
                      <img src={payment} data-value="2" />
                    </div>
                    <div className="profileNavItemNav" data-value="2">
                      <p data-value="2">DTH</p>
                    </div>
                  </div>

                  <div
                    className="profilenavitem"
                    data-value="4"
                    onClick={(e) => {
                      setExpand(false);
                      DecidePage(e);
                    }}
                  >
                    <div className="profileNavImg" data-value="4">
                      <img src={address} data-value="4" />
                    </div>
                    <div className="profileNavItemNav" data-value="4">
                      <p data-value="4">Fastag</p>
                    </div>
                  </div>

                  <div
                    className="profilenavitem"
                    data-value="5"
                    onClick={(e) => {
                      setExpand(false);
                      DecidePage(e);
                    }}
                  >
                    <div className="profileNavImg" data-value="5">
                      <img src={orders} data-value="5" />
                    </div>
                    <div className="profileNavItemNav" data-value="5">
                      <p data-value="5">Data Card</p>
                    </div>
                  </div>

                  <div
                    className="profilenavitem"
                    data-value="6"
                    onClick={(e) => {
                      setExpand(false);
                      DecidePage(e);
                    }}
                  >
                    <div className="profileNavImg" data-value="6">
                      <img src={refer} data-value="6" />
                    </div>
                    <div className="profileNavItemNav" data-value="6">
                      <p data-value="6">BroadBand</p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`myprofileMaindivSecondRecharge ${
                  expand && "hideMyprofileMaindivSecond"
                }`}
              >
                <DTHServiceSection pageNo={rechargepage} />
              </div>
            </div>
          </div>
        ) : (
          <div className="login-to-continue-chat-page">
            <div className="login-to-continue-photo">
              <img src={emptypage}></img>
            </div>
            <span>Login TO Continue</span>
          </div>
        )}
      </>
    </React.Fragment>
  );
}

function PlanCard(props) {
  // console.log(props.data)
  return (
    <React.Fragment>
      <>
        <div
          className="mobile-recharge-inner-circle-2-plan-container-plan"
          onClick={props.selectMe}
        >
          <div className="mobile-recharge-inner-circle-2-plan-container-plan-col1">
            <div className="mobile-recharge-inner-circle-2-plan-container-plan-col1-row1">
              Validity : {props.data.recharge_validity}
            </div>
            <div className="mobile-recharge-inner-circle-2-plan-container-plan-col1-row2">
              {props.data.recharge_long_desc}
            </div>
          </div>
          <div className="mobile-recharge-inner-circle-2-plan-container-plan-col2">
            <div className="mobile-recharge-inner-circle-2-plan-container-plan-col2-row1">
              <div>&#8377; {props.data.recharge_amount}</div>
            </div>
            <div
              className="mobile-recharge-inner-circle-2-plan-container-plan-col2-row2"
              onClick={props.modal}
            >
              View Details
            </div>
          </div>
        </div>
      </>
    </React.Fragment>
  );
}
