import React, { Component } from 'react'
import './cart-css/ORderconfirmation.css';
import { useParams } from 'react-router-dom';
import paymentApi from '../api/paymentApi';
import Tick from '../images/tick-svgrepo-com.svg';
import person from '../images/ordercart/2.png';
import truck from '../images/ordercart/3.png';
import gift from '../images/ordercart/4.png';
import { useGlobalContext } from '../context';
export default function OrderConfirmation() {
  const { changeInCart, setChangeInCart, fetchunreadNotification } = useGlobalContext();
  const { orderID } = useParams();
  console.log(orderID)
  const [order, setOrder] = React.useState([]);
  const OrderConfirmationDetails = async () => {
    const response = await paymentApi.OrderConfirmation(orderID)
    console.log(response);
    setOrder(response.data);
    setChangeInCart(!changeInCart);
    fetchunreadNotification();
  }

  React.useEffect(() => {
    console.log('orderId changed')
    OrderConfirmationDetails();
    // window.alert('page is ellipsed')
  }, [orderID])

  return (
    <React.Fragment>
      {order && <>
        <div className="order-confiramtion-cart-page-container">
        <div className='order-confiramtion-cart-page-container-left'>
          {
            order.product_list && order.product_list.map((item) => {
              return (
                <>
                <div className="order-confirmation-cart-shipped-container">
                  <OrderPageCartItems key={item.product_id} data={item} other={order} orderID={orderID}></OrderPageCartItems>
                  

                  </div>
   
                </>
              )
            })
            
          }
                      
        </div>
      { order&& order?.product_list &&  <ShippedItem  data={order.product_list[0]} other={order} />}
        </div>
      </>}
    </React.Fragment>
  )
}



function OrderPageCartItems(props) {
  const order_id = props.orderID
  const formattedorderid = order_id.replace(/\D/g, "");
  return (
    <React.Fragment>
      {props && (
        <>
          <div className='order-confirmation-cart-page-items'>
            <div className='order-confirmation-cart-page-items-col-1'>
              <img src={props.data.image}></img>
            </div>
            <div className='order-confirmation-cart-page-items-col-2'>
              <div className='order-confirmation-cart-page-items-brand-name'>
                {props.data.brand_name}
              </div>
              <div className='order-confirmation-cart-page-items-item-name'>
                {props.data.name}
              </div>
              <div className='order-confirmation-cart-page-items-soldby'>
                Order Id : {formattedorderid}
              </div>
              <div className='order-confirmation-cart-page-items-soldby'>
                Sold By : {props.data.service_provider}
              </div>
              {props.data && props.data.color_name &&

<div className="cart-main-page-item-col2-colorname">
{props.data.color_name}
<div className="initial-choice service-color-choice" style={{
              backgroundColor:
              props.data.color_code,
            }}
            >
            </div>
</div>}
              <div className='order-confirmation-cart-page-items-quantity'>
                Quantity : {props.data.quantity}
              </div>
              <div className='order-confirmation-cart-page-items-amount-qty'>
                Qty : {props.data.no_of_product}
              </div>
              <div className='order-confirmation-cart-page-items-price'>
                Rs . {props.data.price}
              </div>
             
            </div>
          
          </div>
        </>
      )}
    </React.Fragment>
  );
}


function ShippedItem(props) {
  console.log(props);
  return (
    <>
    <div className="order-confirmation-cart-page-items-shipped-container">
      <div className="order-confirmation-cart-page-items-shipped-inner-container">
      <div className='order-confirmation-cart-page-heading'>
        Order Status

      <div className='order-confirmation-cart-page-items-payment-status'>
          {props.other.payment_mode === 'COD' ? (
            <span>Payment Pending : <span> Cash On Delivery</span> </span>
          ) : (
            <span>Payment done using {props.other.payment_mode}</span>
          )}
        </div>
        </div>
        <div className='order-confirmation-cart-page-items-order-status-track'>
          <div className='order-confirmation-cart-page-items-order-status-track-1'>
            <div>
              <div>
                <img src={Tick}></img>
              </div>
              <span>Confirmed</span>
            </div>
          </div>

          <div className='order-confirmation-cart-page-items-order-status-track-2'>
            <div>
              <div>
                <img src={person}></img>
              </div>
              <span>Shipped</span>
            </div>
          </div>

          <div className='order-confirmation-cart-page-items-order-status-track-2'>
            <div>
              <div>
                <img src={truck}></img>
              </div>
              <span>On The Way</span>
            </div>
          </div>

          <div className='order-confirmation-cart-page-items-order-status-track-2'>
            <div>
              <div>
                <img src={gift}></img>
              </div>
              <span>Delivered</span>
            </div>
          </div>
        </div>
        <hr />
      <div className='order-confirmation-cart-page-items-shipped-date'>

        Your Order Has Been Placed on : {props.data.shippment_date}
      </div>
      <div className='order-confirmation-cart-page-items-expected-time'>
        Expected Delivery : {props.data.expected_delivary_date}
      </div>
      <div className='order-confirmation-cart-page-items-Return-policy'>
        Return Policy Valid Till :  {props.data.return_policy_date && typeof props.data.return_policy_date === 'string' ? (
    <span>
      {props.data.return_policy_date.split('*')[0]}
      <span style={{ color: 'red' }}>*</span>
      {props.data.return_policy_date.split('*')[1]}
    </span>
  ) : (
    props.data.return_policy_date
  )}

      </div>
      <div className='order-confirmation-cart-page-items-col-3'>
        <div className='order-confirmation-cart-page-items-mobile-number'>
          Mobile Number :{props.other.user_mobile}
        </div>
        <div className='order-confirmation-cart-page-items-delivery-address'>
          <p>       Delivery Address :{props.other.user_address.address_line1} , {props.other.user_address.address_line2} , {props.other.user_address.district}, {props.other.user_address.town} , {props.other.user_address.state}</p>
   
        </div>
        
     
      </div>
      <div className="order-total-amount">    <span>Total Amount : &#8377;{props.other.total_amt}</span></div>
</div>
</div>
    </>)
}