import React, { useState, useContext, useEffect } from "react";
import { useCallback } from "react";
import api from "./api/api";
import apiclient from "./api/apiclient";
import PutatoeServiceApi from "./api/PutatoeServiceApi";

import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

//rerenderd is happeing becuase of fetchproducts api  but context is rerendering only once

const ServiceContext = React.createContext();

const ServiceProvider = ({ children }) => {
  const [image, setImage] = React.useState();
  const [isError, setIsError] = React.useState(false);
  const [rating, setRating] = React.useState();
  const [ratingModal, setRatingModal] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [productInfo, setProductInfo] = React.useState();
  const [IsServiceProviderApi, setIsServiceProviderApi] = React.useState("");
  const [productData, setproductData] = React.useState([]);
  const [brandData, setbrandData] = React.useState([]);
  const [categoryData, setcategoryData] = React.useState([]);
  const [quantityData, setQuantityData] = React.useState([]);
  const [colorData, setColorData] = React.useState([]);
  const [priceData, setPriceData] = React.useState([]);
  const [priceDataTwo, setPriceDataTwo] = React.useState();
  // console.log(testing)
  const [serviceDetail, setServiceDetail] = React.useState();
  const [serviceDetail2, setServiceDetail2] = React.useState([]);
  const [productDetail, setProductDetail] = React.useState();
  const [globalIndex, setGlobalIndex] = React.useState(0);
  const [globalIndex2, setGlobalIndex2] = React.useState(0);
  //1 ->no more product  0->not called
  const storedDriverData = localStorage.getItem("driverData");
  const driverDataArray = storedDriverData ? storedDriverData.split(",") : [];
  const [username, setUsername] = React.useState(driverDataArray);
  const [prodlen, setProdlen] = React.useState(0);
  

  const serviceId = localStorage.getItem("serviceId");

  const { serviceprovider } = useParams();
  const navigate = useNavigate();

  console.log(username,serviceprovider);
  const fetchIsServiceProviderApi = async (providername) => {
    console.log(providername,username);

  

    const response = await apiclient.IsServiceProviderApi(providername);
    console.log("rendered all service");
    console.log(response);
    setIsServiceProviderApi(response.data);
    setIsError(response.data.is_serviceProvider);
    console.log(
     
      IsServiceProviderApi["serviceprovider id"],

    );
    if (response.data.error === "invalid Username or username does not exist") {
      console.log("error in name");
      navigate("/pagenotfound");
    }

    if (
      response.data.error === ""
    ) {
      console.log(response.data["serviceprovider id"]);

      localStorage.setItem("driverData", [
        providername,
        response.data["serviceprovider id"]
      ]);
//set service id and subservice id
          localStorage.setItem("sidsbid", [response.data.sub_service[0].sub_service_id,response.data.sub_service[0].service_id]);

    }  else {
      console.log("called2");
      fetchServiceDetail();
    }
  };

  const fetchServiceDetail = async (subserviceID) => {
    console.log("subid:", subserviceID);
    if (username && username.length>0) {
      let index = 0;
      let arr = [];
      let response2;
      console.log("fetch service details",username);
      do {
        // response2 = await apiclient.SellerProducts(username[2],username[1],index);
        if (subserviceID && subserviceID != "") {
          response2 = await PutatoeServiceApi.ServiceProviderAllProduct(
            subserviceID,
            username[1],
            index
          );
          console.log("elif1");
        } else if (
          (IsServiceProviderApi !== " " ||
            IsServiceProviderApi.username.toLowerCase() !==
            username[0].toLowerCase()) &&
          subserviceID || username[3] === undefined &&
          IsServiceProviderApi["serviceprovider id"] !== undefined
        ) {
          console.log("elif");
          console.log(
            (IsServiceProviderApi !== " " ||
              IsServiceProviderApi.username.toLowerCase() !==
              username[0].toLowerCase()) &&
            username[3] === undefined
          );
          let serviceProviderId = String(
            IsServiceProviderApi["serviceprovider id"]
          );
          console.log(serviceProviderId);
          response2 = await PutatoeServiceApi.ServiceProviderAllProduct(
            null,
            serviceProviderId,
            index
          );
          
        } else if (username[3] !== undefined) {
          response2 = await PutatoeServiceApi.ServiceProviderAllProduct(
            username[3],
            username[1],
            index
          );
          console.log(username[3]);
          console.log("elif2");
        } else {
          console.log("from search ");
          response2 = await PutatoeServiceApi.ServiceProviderAllProduct(
            null,
            username[1],
            index
          );
        }
        console.log(response2);

        // index += response2.data.product_list.length;
        arr.push(response2.data);
        console.log(index);
        console.log(arr);
      } while (index > 0 && index % 18 == 0);

      setServiceDetail(arr);
      setGlobalIndex2(0);

      console.log(serviceDetail);
    } 
  };

  const fetchServiceDetailindex = async (subserviceID) => {
    console.log("newindex called ");
    let index = globalIndex2;
    let arr = [];
    let response2;
    console.log("fetch service details");
    // response2 = await apiclient.SellerProducts(username[2],username[1],index);
    console.log(username);
    if (subserviceID) {
      response2 = await PutatoeServiceApi.ServiceProviderAllProduct(
        subserviceID,
        username[1],
        index
      );
    } else {
      response2 = await PutatoeServiceApi.ServiceProviderAllProduct(
        username[3],
        username[1],
        index
      );
    }

    console.log(response2);
    console.log(response2);
    // index += response2.data.product_list.length;
    arr.push(response2.data);
    console.log(index);
    console.log(arr);

    setServiceDetail((prev) => {
      return [...prev, response2.data];
    });
    console.log(serviceDetail2);
    if (response2.data.product_list.length == 0) {
      console.log("less than 18");
      setGlobalIndex2(1);
      return;
    }
  };

  const fetchServiceDetailFiltered = async (service_id, sub_service_id) => {
    console.log(sub_service_id, service_id);
    let index = 0;
    let arr = [];
    let newProductList;
    let response2;
    let driverdata;
    const getServiceAndSubServiceIds = () => {
      const storedSidsBid = localStorage.getItem("sidsbid");
    
      if (storedSidsBid) {
        return storedSidsBid.split(",");
      } else {
        const storedDriverData = localStorage.getItem("driverData");
        const data= storedDriverData ? storedDriverData.split(",") : [undefined, undefined];
        return (data[3]&& data[4])?[data[3],data[4]]: [undefined, undefined]
      }
    };
  
    const [subserviceId, serviceId] = getServiceAndSubServiceIds();
    if (service_id && sub_service_id && username&& username.length>0) { // if subservice chnage from dropown
      console.log(service_id, sub_service_id);
      response2 = await apiclient.SellerProductsFilter(
        username[1],
        service_id.toString(),
        sub_service_id,
        priceData.length>0 ?parseInt(priceData[0]):null,
         priceData.length>0? parseInt(priceData[1]):null,
        brandData,
        productData,
        quantityData,
        index,
        colorData
      );
    }
    else if(username && username.length>0){
      response2 = await apiclient.SellerProductsFilter(
        username[1],
        serviceId,
        subserviceId,
        priceData.length>0 ?parseInt(priceData[0]):null,
        priceData.length>0? parseInt(priceData[1]):null,
        brandData,
        productData,
        quantityData,
        index,
        colorData
      );
    }
    do {

      newProductList = response2?.data.product_list;
      arr.push(response2?.data.product_list);
console.log(response2.data);
      setProdlen(response2.data.product_count);
      if (response2.data.product_list && response2.data.product_list.length === 0) {
        setServiceDetail2([]);
        setGlobalIndex(1);
        setGlobalIndex2(1)
        return;
      }
    } while (index > 0 && index % 18 === 0);
    setServiceDetail2([...newProductList]);
  };

  const fetchServiceDetailFilteredGlobalIndex = async () => {
    const getServiceAndSubServiceIds = () => {
      const storedSidsBid = localStorage.getItem("sidsbid");
    
      if (storedSidsBid) {
        return storedSidsBid.split(",");
      } else {
        const storedDriverData = localStorage.getItem("driverData");
        const data= storedDriverData ? storedDriverData.split(",") : [undefined, undefined];
        return (data[3]&& data[4])?[data[3],data[4]]: [undefined, undefined]
      }
    };
    const [subserviceId, serviceId] = getServiceAndSubServiceIds();
    if (username && username.length>0) {
      let index = globalIndex2;
      let arr = [];
      let response2;
      let driverdata;

        driverdata = localStorage.getItem("driverData").split(",")
        console.log(driverdata);
      
      if (priceData) {
        response2 = await apiclient.SellerProductsFilter(
          username[1],
          serviceId,
          username[3]?username[3]:subserviceId,
          priceData.length>0 ?parseInt(priceData[0]):null,
          priceData.length>0? parseInt(priceData[1]):null,
          brandData,
          productData,
          quantityData,
          index
        );
      }
      const newProductList = response2.data.product_list;

      arr.push(newProductList);
      console.log("new index", response2.data);


      if (response2.data.product_list && response2.data.product_list.length === 0) {
        setGlobalIndex(1);
        setGlobalIndex2(1)
        return;
      }
      setServiceDetail2((prev) => {
        return [...prev, ...newProductList];
      });
    }
  };

  React.useEffect(() => {
    if (globalIndex !== 0 && globalIndex !== 1 && globalIndex % 18 === 0) {
      fetchServiceDetailFilteredGlobalIndex();
    }
  }, [globalIndex]);

  React.useEffect(() => {
    if (globalIndex2 !== 0 && globalIndex2 !== 1 && globalIndex2 % 18 === 0) {
      if (
        (username && brandData && brandData.length > 0) ||
        (productData && productData.length > 0) ||
        (colorData && colorData.length > 0) ||
        (quantityData && quantityData.length > 0) ||
        (priceData && priceData !== undefined)
      ) {
        fetchServiceDetailFilteredGlobalIndex();
      }

      else {
        fetchServiceDetailindex();
      }

    }
  }, [globalIndex2]);

  React.useEffect(() => {


if(username && username.length>0 && username[3]){
    // Check if priceData is equal to []

    const isDefaultPriceData = priceData.length===0;
    if (!isDefaultPriceData) {
      console.log("price data changes");
      const timeoutId = setTimeout(() => {
        fetchServiceDetailFiltered();
      }, 800); // Change the delay time (in milliseconds) as needed

      // Cleanup function to clear the timeout if priceData changes again
      return () => clearTimeout(timeoutId);
    }
    else if (  username &&  username.length>0 && username[3] &&  username[0].toLowerCase()===serviceprovider.toLowerCase() ){ 
      //call only when normal click form home page 
      console.log("normal");
      fetchServiceDetailFiltered();
    }
}
if(username&&  username.length>0 && username[3]===undefined && username[0].toLowerCase()===serviceprovider.toLowerCase()){
fetchServiceDetailFiltered();
  console.log("from search provider"); // fetch data when from url search
      }
  }, [brandData, productData, quantityData, priceData,colorData]);



  React.useEffect(() => {
    let storedSubserviceId = localStorage.getItem("sidsbid");
    if (storedSubserviceId) {
       storedSubserviceId=  storedSubserviceId.split(',');
    }
  
    console.log(username ,username[3]);
    if(username&&  username.length>0 && (username[3]===''||username[3]===undefined )&& username[0].toLowerCase()===serviceprovider.toLowerCase()){
      console.log(storedSubserviceId,storedSubserviceId[0],storedSubserviceId[1]);
      fetchServiceDetail(parseInt(storedSubserviceId[0]));
      console.log( username , username[3],storedSubserviceId ,serviceDetail , serviceDetail?.length>0);
      console.log("no provider so to fetch products called",storedSubserviceId);
      

      fetchServiceDetailFiltered(parseInt(storedSubserviceId[1]),parseInt(storedSubserviceId[0]));

      console.log("from search provider"); // fetch data when from url search
     
      }
          
   else if (  username &&  username.length>0 && username[3] &&  username[0].toLowerCase()===serviceprovider.toLowerCase() ) {
      fetchServiceDetail();

  

      setGlobalIndex(0);
    }
    console.log("rendered effect", username);
  }, [username]);

  React.useEffect(() => {
    console.log("providerview");

    const storedDriverData = localStorage.getItem("driverData");
    console.log(storedDriverData);
    if (storedDriverData) {
      console.log(storedDriverData.split(","));
   setUsername([
     storedDriverData.split(",")[0],
     storedDriverData.split(",")[1],
     storedDriverData.split(",")[2],
     storedDriverData.split(",")[3],
   ]);


 }
 }, [localStorage.getItem("driverData")]);
  React.useEffect(() => {
    console.log("calledprovider");
 
        if (username && username.length>0 &&
      username[0].toLowerCase() !== serviceprovider.toLowerCase() ||
      username[2] === undefined
    ) {
      console.log("call service provider");
cleardata();
     
    }


  }, []);
      // useEffect to handle the asynchronous state update
React.useEffect(() => {
  if (username && username.length === 0) {
    // Only call fetchIsServiceProviderApi when username is empty
    console.log("called new provider");
    fetchIsServiceProviderApi(serviceprovider);
  }
}, [username]);

  const cleardata = async () => {
    localStorage.removeItem("driverData");
    setUsername([])
    console.log("Local storage cleared.",localStorage.getItem("driverData"));
    
  //  await fetchIsServiceProviderApi(serviceprovider);
  }
  console.log(serviceDetail, IsServiceProviderApi);
  return (
    <ServiceContext.Provider
      value={{
        username,
        setUsername,
        image,
        setImage,
        rating,
        setRating,
        ratingModal,
        setRatingModal,
        IsServiceProviderApi,
        setIsServiceProviderApi,
        serviceDetail2,
        setServiceDetail2,
        isError,
        setIsError,
        serviceDetail,
        setServiceDetail,
        quantityData,
        setQuantityData,
        productInfo,
        setProductInfo,
        categoryData,
        setcategoryData,
        brandData,
        setbrandData,
        productData,
        setproductData,
        priceData,
        setPriceData,
        productDetail,
        setProductDetail,
        quantityData,
        setQuantityData,
        globalIndex,
        setGlobalIndex,
        globalIndex2,
        setGlobalIndex2,
        loading,
        setLoading,
        fetchServiceDetail,
        fetchServiceDetailFiltered,
        prodlen,
        setProdlen,
        priceDataTwo,
        setPriceDataTwo,
        colorData, setColorData
      }}
    >
      {children}
    </ServiceContext.Provider>
  );
};
// make sure use
export const useServiceContext = () => {
  return useContext(ServiceContext);
};

export { ServiceProvider, ServiceContext };
