import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

class couponapi {
    couponsaved(index){
        const payload=
        {
            "sp_id" :JSON.parse(localStorage.getItem("UserProfile"))?.service_provider_id,
        }
        return axios.post(
            API_BASE_URL + "/v1/api/Show_service_provider_coupons/" + index,
            payload,
            {
              headers: {
                authtoken: localStorage.getItem("authtoken"),
              },
            }
          );  
    }
    coupondelete(cuponid){
      const payload=
      {
          "sp_id" :JSON.parse(localStorage.getItem("UserProfile"))?.service_provider_id,
          "coupon_id":cuponid,
      }
      return axios.post(
          API_BASE_URL + "/v1/api/delete_service_provider_coupon" ,
          payload,
          {
            headers: {
              authtoken: localStorage.getItem("authtoken"),
            },
          }
        );  
  }
}

export default new couponapi();