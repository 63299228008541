import React, { Component } from 'react'
import './css/transferToWallet.css'
import back from '../myprofileIcoons/bx-arrow-back..svg';
import MyWalletapi from '../../api/MyWalletapi';
import notify from '../../error';
import { useGlobalContext } from '../../context';
import api from '../../api/api';
export default function TransferToWallet(props)
{
 const {Userprofile,setUserProfile,setInnerPage} = useGlobalContext();
const [formData, setFormData] = React.useState()
const [formData2, setFormData2] = React.useState();
function handleChange(event) {
  // console.log(event);
  const { name, value, type, checked } = event.target;
  setFormData(value);
  console.log(formData);
}
function handleChange2(event) {
  // console.log(event);
  const { name, value, type, checked } = event.target;
  setFormData2(value);
  console.log(formData);
}



 const DoTheTransfer=async()=>
 {
  if( /^\d+$/.test(formData))
  {
  const response = await MyWalletapi.WalletTransferServiceWithPhone(formData2,`+91${formData}`)
  console.log(response);
  if(response.data.error!=='')
  {
   notify('error',response.data.error)
  }
  else{
   notify('success', 'Wallet Money Transferred Successfully');
   props.newpage(0);
   const responset = await api.ProfileApi();
   if (responset.data.error !== '') {
     notify(
       'error',
       'unable to update your wallet balance login again to continue'
     );
   } else {
     let abc = responset.data;
     setUserProfile((prev) => {
       console.log(abc);
       console.log(JSON.stringify(abc));
       localStorage.setItem('UserProfile', JSON.stringify(abc));
       return abc;
     });
   }
  }
 // console.log('b')
 }
  else
  {
   // console.log('a')
   const response = await MyWalletapi.WalletTransferServiceWithUsername(
     formData2,
     formData
   );
   console.log(response);
   if (response.data.error !== '') {
     notify('error', response.data.error);
   } else {
     notify('success', 'Wallet Money Transferred Successfully');
     props.newpage(0);
     const responset = await api.ProfileApi();
     if (responset.data.error !== '') {
       notify(
         'error',
         'unable to update your wallet balance login again to continue'
       );
     } else {
       let abc = responset.data;
       setUserProfile((prev) => {
         console.log(abc);
         console.log(JSON.stringify(abc));
         localStorage.setItem('UserProfile', JSON.stringify(abc));
         return abc;
       });
     }
   }
  }
 }
 return (
   <React.Fragment>
     <>
       <div className='my-profile-wallet-page-transfer-view'>
         <div className='my-profile-wallet-page-transfer-top'>
           <div className='my-profile-wallet-page-transfer-top-back'>
             <img
               src={back}
               onClick={() => {
                 props.newpage(0);
                 setInnerPage(false);
               }}
             ></img>
           </div>
         </div>
         <div className='my-profile-wallet-page-transfer-main-body'>
           <div className='my-profile-wallet-page-transfer-main-body-top'>
             <span>Transfer To Wallet</span>
           </div>
           <div className='my-profile-wallet-page-transfer-main-body-mid'>
             <span>
               Enter Your{' '}
               <b>
                 <u>Phone Number OR Username</u>
               </b>
             </span>
             <div>
               pay someone using Putatoe verified phone number/Username
             </div>
           </div>
           <div className='my-profile-wallet-page-transfer-main-body-mid-input'>
             <input
               type='text'
               value={formData}
               name='name'
               onChange={handleChange}
               placeholder='Enter recipient Username or Phone number'
               required
             ></input>
           </div>
           <div className='my-profile-wallet-page-transfer-main-body-mid-amount'>
             <div className='my-profile-wallet-page-transfer-main-body-mid-amount-col1'>
               <span>Enter Amount</span>
               <section>
                 &#8377;{' '}
                 <input
                   type='number'
                   value={formData2}
                   name='name'
                   onChange={handleChange2}
                   placeholder='Amount'
                   required
                 ></input>
               </section>
             </div>
             <div className='my-profile-wallet-page-transfer-main-body-mid-amount-col2'>
              <button onClick={DoTheTransfer}>Continue</button>
             </div>
           </div>
         </div>
       </div>
     </>
   </React.Fragment>
 );
}