import React, { useEffect, useState } from "react";
import "../post-css/createpost.css";
import { usePostContext } from "../postContext.js";
import ul from "../images/olList.svg";
import cnacleUpload from "../images/blogimages/cnacleUpload.svg";
import camera from "../images/blogimages/camera.svg";
import ol from "../images/ulList.svg";
import postapi from "../api/postapi";
import notify from "../error";
// custom Editor import from draft-js---------->
import {
  Editor,
  EditorState,
  RichUtils,
  convertFromHTML,
  ContentState,
} from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { useGlobalContext } from "../context";

// main function starts from here ---------->
export default function CreatePost({ editData }) {
  const [postBody, setPostBody] = React.useState();
  const [errorobject,setErrorobject]=React.useState({});
  const [image, setImage] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const { setCreatePostModal, setPostUpdate,newpost,
    setnewpost, } = usePostContext();
  const { setModifyModal, setLoading } = useGlobalContext();

  const handleKeyCommand = (command) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return "handled";
    }
    return "not-handled";
  };

  const toggleInlineStyle = (style) => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, style));
  };

  const toggleBlockType = (style) => {
    setEditorState(RichUtils.toggleBlockType(editorState, style));
  };

  function HandleCancel() {
    setModifyModal(false);
  }

  function editPostFunction(elm) {
    setImageUrl(elm.image);
    setPostBody(elm.detail);
  }

  useEffect(() => {
    const contentState = editorState.getCurrentContent();
    const html = stateToHTML(contentState);
    console.log(html);
    setPostBody(html);
  }, [editorState]);

  useEffect(() => {
    if (editData[0]) {
      // console.log(editData[0]);
      editPostFunction(editData[0]);

      // seting the editor state
      const contentState = convertFromHTML(editData[0].detail || "");
      const newEditorState = EditorState.createWithContent(
        ContentState.createFromBlockArray(
          contentState.contentBlocks,
          contentState.entityMap
        )
      );
      setEditorState(newEditorState);
    }
  }, [editData]);

  async function uploadOGCFunction(file) {
    try {
      const response = await postapi.UploadImageOGC(file);
      setImageUrl(response.data.url);
    } catch (err) {
      console.log(err);
    }
  }

  const handleImageUpload = (event, editClue) => {
    const reader = new FileReader();
    setImage(event.target.files[0]);
    reader.onload = () => {
      setImageUrl(reader.result);
    };
    reader.readAsDataURL(event.target.files[0]);
    if (editClue !== undefined) uploadOGCFunction(event.target.files[0]);
  };

  const publishBtnClick = async () => {
    if (postBody.length >= 25) {
      try {
        const response = await postapi.AddPromotion(image, postBody);
        if (response.data.error !== "") {
          notify("warn", response.data.error);
          return;
        }
        if (response.status === 200) {
          notify("success", "Post added successfully...");
          setModifyModal(false);
          setCreatePostModal(false);
          setPostUpdate((prevPostState) => !prevPostState);
        }
      } catch (err) {
        console.log(err);
        notify("error", "something went wrong!!!");
      }
      setLoading(false);
    } else {
      notify("error", "Fill the fields first...");
      setLoading(false);
    }
  };

  const editBtnClick = async (id) =>{
    if (postBody.length >= 25) {
      try {
        const response = await postapi.EditPromotion(id,postBody,imageUrl);
        if (response.data.error !== "") {
          notify("warn", response.data.error);
          return;
        }
        if (response.status === 200) {
          notify("success", "Post edited successfully...");
          setModifyModal(false);
          setCreatePostModal(false);
          setPostUpdate((prevPostState) => !prevPostState);
        }
      } catch (err) {
        console.log(err);
        notify("error", "something went wrong!!!");
      }
      setLoading(false);
    } else {
      // notify("error", "Fill the fields first...");
      setLoading(false);
    }
  }
  // if(editData)console.log(editData);

  return (
    <React.Fragment>
      <section className="create-post-holder">
        <div className="create-post-container">
          <div
            className="create-post-modal-top-row-cross"
            onClick={() => setModifyModal(false)}
            style={{ fontSize: "28px" }}
          >
            <img
              className="cancelcross"
              src={cnacleUpload}
              alt="cross_icon"
              onClick={() => {
                setnewpost(false)
                setCreatePostModal(false)
              }}
            />{" "}
          </div>

          <div className="postUploadImg">
            <img
              className="cancelcross"
              src={cnacleUpload}
              alt="cross_icon"
              onClick={() => setImageUrl("")}
            />
            {imageUrl ? (
              <div className="imgContainer">
                <img
                  src={imageUrl}
                  alt="uploaded_image"
                  className="uploadedImg"
                />
              </div>
            ) : (
              <>
                <img src={camera} alt="camerIcon" />
                <div>No Cover image Uploaded</div>
                <span className="imgContainer">
                  <label id="create-post-camera-label" htmlFor="image-input">
                    <input
                      type="file"
                      id="image-input"
                      accept="image/png, image/jpg, image/gif, image/jpeg"
                      onChange={(e) => handleImageUpload(e, editData)}
                    />
                    Select Your Cover Image From <br></br> Your Folder
                  </label>
                </span>
              </>
            )}
          </div>

          <div className="create-post-text-box">
            <div className="create-post-text-options">
              <div className="create-post-text-options-caption">
                <span>Caption(25 characters min)</span>
              </div>
              <div className="create-post-text-options-style-biu">
                <button onClick={() => toggleInlineStyle("BOLD")}>B</button>
                <button onClick={() => toggleInlineStyle("ITALIC")}>I</button>
                <button onClick={() => toggleInlineStyle("UNDERLINE")}>
                  U
                </button>
              </div>
              <div className="create-post-text-options-style-list">
                <button onClick={() => toggleBlockType("unordered-list-item")}>
                  <img src={ul} alt="Unordered List" />
                </button>
                <button onClick={() => toggleBlockType("ordered-list-item")}>
                  <img src={ol} alt="Ordered List" />
                </button>
              </div>
            </div>
            <Editor
              editorState={editorState}
              onChange={(newEditorState) => {
                if(errorobject && postBody?.length > 25){
                  setErrorobject({
                  })
                }
                setEditorState(newEditorState);
                setPostBody(newEditorState.getCurrentContent().getPlainText());
              }}
              handleKeyCommand={handleKeyCommand}
            />
          </div>
         {errorobject && <div className="error">{errorobject.error}</div> }
          <div className="create-post-container-publish">
            {editData[0] ? (
              <button
                onClick={() => {
                  if(postBody?.length<25){
                    setErrorobject({
                      error:"Minimum 25 Characters required"
                    })
                    return;
                  }
                  setLoading(true);
                  editBtnClick(editData[0].id);
                }}
              >
                Edit
              </button>
            ) : (
              <button
                onClick={() => {
                  if(postBody?.length<25){
                    setErrorobject({
                      error:"Minimum 25 Characters required"
                    })
                    return;
                  }
                  setLoading(true);
                  publishBtnClick();
                }}
              >
                Publish
              </button>
            )}
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
