import React from "react";
import PopularProductsSubContainer from "./popularProductsSubContainer";
import CategorySubContainer from "./CategorySubContainer";
import Loading from "./Loading";
import "../css/popularProductsSubContainer.css";
import { useGlobalContext } from "../context";
import "../css/popularProductsByService.css";
import Carousel from "./carousel";

export default function PopularProductByService(props) {
  const {
    products,
    UndesiredProducts,
    tempProduct,
    loading,
    categorydata,
  } = useGlobalContext();

  const [val, setVal] = React.useState([]);
  const [count, setCount] = React.useState(1);


  if (val !== products) {
    setCount(count + 1);
    setVal(products);
  }


  return (
    <React.Fragment>


      <div className='desktop-view'>
        <div className='popular-container pcontainer-mobile'>
          <div className='popular-product-gradient'></div>
          <h1 className='popular-container-heading popular-container-heading-mobile-view'>
            Top Deals
          </h1>
          {products.length > 0 ? (
            <>
              {val &&
                val.map((item, index) => {
                  return (
                    UndesiredProducts.indexOf(`${item.data.service_id}`) === -1 && (
                      <React.Fragment key={`item-${index}`}>
                        {/* {console.log('inside return')} */}
                        {item.data.popularproduct && Object.keys(item.data.popularproduct).length > 0 ? (
                          Object.entries(item.data.popularproduct).map(([category, products]) => (
                            <React.Fragment key={`popular-${category}`}>
                              {/* {console.log(products)} */}
                              <PopularProductsSubContainer
                                key={`popular-sub-${category}`}
                                category={category}
                                products={products.products}
                                sub_service_id={products.sub_service_id}
                                service_id={tempProduct[0]}
                              />
                            </React.Fragment>
                          ))
                        ) : (
                          <>
                            {loading && (
                              <Loading />
                            )}
                          </>
                        )}
                      </React.Fragment>
                    )
                  );
                })}
            </>
          ) : (
            <Loading customStyle={{ height: '30vh' }} />
          )}


        </div>
      </div>


      <Carousel />

      {categorydata && Object.keys(categorydata).length !== 0 && (<div className="desktop-view">
        <div className="popular-container ">
          <h1 className="popular-container-heading">

            Shop By Category

          </h1>
          {categorydata && Object.keys(categorydata).length > 0 ? (
            Object.entries(categorydata).map(([category, products]) => (
              <React.Fragment key={category}>

                <CategorySubContainer
                  key={`shop-by-${category}`}
                  category={category}
                  products={products}
                  product_length={products.length}
                />

              </React.Fragment>
            ))
          ) : (
            <>
              {/* {loading && <Loading />} */}
            </>
          )}
        </div>
      </div>)}

    </React.Fragment>
  );
}
