import React, { Component } from 'react'
import './css/sendmoney.css'
import notify from '../../error';
import BankApi from '../../api/BankApi';
import girl from '../myprofileIcoons/girl.svg';
import back from '../myprofileIcoons/bx-arrow-back..svg';
import { useGlobalContext } from '../../context';
export default function SendMoney(props)
{
  const [selectedAccount, setSelectedAccount] = React.useState(null);
  const [transferAmount, setTransferAmount] = React.useState('');
  const [BankAccountsList, setBankAccountsList] = React.useState([]);
  const [pageState, setPageState] = React.useState('accounts');
  const wrapper = React.useRef(null);
  OutsideAlerter(wrapper);
  function OutsideAlerter(ref) {
    React.useEffect(() => {
      function handleClickDetailOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          console.log(ref.current);
          props.close();
        }
      }
      document.addEventListener('mousedown', handleClickDetailOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickDetailOutside);
      };
    }, [ref]);
  }



  const [formData, setFormData] = React.useState({
    number: '',
    type:'',
   name:'',
  ifsc:''
 })
 function handleChange(event) {
    console.log(event);
    const { name, value, type, checked } = event.target;
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: type === 'checkbox' ? checked : value,
      };
    });
    console.log(formData);
  }



  const FetchBankACcountsList=async()=>
  {
   const response = await BankApi.ViewBankAccounts();
   console.log(response)
   if(response.data.error!=='')
   {
    notify('error',response.data.error)
   }
   else
   {
    setBankAccountsList(response.data.accounts)
   }
  }
  React.useEffect(()=>
  {
   FetchBankACcountsList();
  },[])

const [isOpen, setIsOpen] = React.useState(true);
const handleClose = () => {
  setIsOpen(false);
};

const isValidAccountNumber = (accountNumber) => {
    const accountNumberRegex = /^\d{9,18}$/; // Validate account number with 9 to 18 digits
    return accountNumberRegex.test(accountNumber);
  };
  
  const isValidIFSC = (ifscCode) => {
    const ifscCodeRegex = /^[A-Z]{4}[0-9A-Z]{7}$/; // Validate IFSC code with 4 letters followed by 7 alphanumeric characters
    return ifscCodeRegex.test(ifscCode);
  };
const handleAddAccountClick = () => {
    setPageState('addAccount');
  };

  const handleBackToAccountsClick = () => {
    setPageState('accounts');
  };
const OnAddNewAccountSubmit=async()=>{
// Perform validations
if (formData.number === '') {
    notify('error', 'Please enter the account number.');
    return;
  }

  if (!isValidAccountNumber(formData.number)) {
    notify('error', 'Invalid account number. Please enter a valid account number.');
    return;
  }

  if (formData.type === '') {
    notify('error', 'Please select the account type.');
    return;
  }

  if (formData.name === '') {
    notify('error', 'Please enter the account holder name.');
    return;
  }

  if (formData.ifsc === '') {
    notify('error', 'Please enter the IFSC code.');
    return;
  }

  if (!isValidIFSC(formData.ifsc)) {
    notify('error', 'Please enter a valid IFSC code.');
    return;
  }

  // Submit the form  
const response = await BankApi.AddBankAccount(parseInt(formData.number),formData.type,formData.name,formData.ifsc)
console.log(response)
if(response.data.error!=='')
{
 notify('error',response.data.error)
}
else{
 notify('success','Bank Account Added Successfully')
 FetchBankACcountsList();
 setPageState('accounts')
}

}

const handleAccountSelect = (accountId) => {
  setSelectedAccount(accountId);
  setPageState('enterAmount');
};

const handleTransferAmountChange = (event) => {
  setTransferAmount(event.target.value);
};

const handleTransferSubmit = async () => {
  //  validations
  if (transferAmount === '') {
    notify('error', 'Please enter the transfer amount.');
    return;
  }

  if (isNaN(parseFloat(transferAmount))) {
    notify('error', 'Invalid transfer amount. Please enter a valid amount.');
    return;
  }

  // Calling TransferMoney API with selected account ID and transfer amount
  const response = await BankApi.TransferMoney(selectedAccount, parseFloat(transferAmount));
  console.log(response);
  if (response.data.error !== '') {
    notify('error', response.data.error);
  } else {
    notify('success', 'Money transferred successfully!');
    
  }
};

return (
  <React.Fragment>
    {isOpen && (
      <>
                 <div className='my-wallet-profile-send-money-background'>
            <div className='my-wallet-profile-page-sendmoney-modal' ref={wrapper}>
              <button className='close-icon' onClick={handleClose}>
                &times;
              </button>
              {pageState === 'accounts' ? (
                   <>
              {BankAccountsList.length > 0 ? (
                <div className='bank-accounts-list'>
                      <div className="my-bank-account-heading-withdrawal">
                Select A Bank Account For Withdrawal
            </div>
                  {BankAccountsList.map((item) => {
                   return (
                    <Bankcard2
                      data={item}
                      key={item.account_id}
                      onSelect={handleAccountSelect}
                    
                    ></Bankcard2>
                  )})}
                </div>
              ) : (
                <>
                <div className='nobank-account-div'>
                <p className='no-accounts-msg'>No Bank Accounts!</p>
                     <p className='add-accounts-msg'>Please add a Bank Account</p>
                </div>
                <div className="add-bank-account-button">
                <button  onClick={handleAddAccountClick}>Add Bank Account</button>
                </div>
              
                </>
           
              )}
              </>
            
              ):pageState === 'addAccount' ? (
          <div className='my-bank-account-profile-main-page'>
           <div className='profile-wallet-main-page-heading sendmoney-top'>
             <b className='my-profile-wallet-page-transfer-top-back'>
               <img
                 src={back}
                 onClick={handleBackToAccountsClick}
               ></img>
             </b>
             <span>Add New Account</span>
           </div>
           <div className='my-bank-account-profile-main-page-add-account-input sendmoney-input'>
             <div className='add-address-modal-coupon-enter-input '>
               <span>Account Number</span>
               <input
                 type='number'
                 value={formData.number}
                 name='number'
                 onChange={handleChange}
                 placeholder='Enter Your Account Number'
                 required
               ></input>
             </div>
             <div className='add-address-modal-coupon-enter-input'>
               <span>Account Type</span>
               <select
                 value={formData.type}
                 name='type'
                 onChange={handleChange}
                 placeholder='Select Account Type'
                 required
               >
                 <option value=''>Select Account Type</option>
                 <option value='saving'>Savings Account</option>
                 <option value='current'>Current Account</option>
               </select>
             </div>
             <div className='add-address-modal-coupon-enter-input'>
               <span>Account Holder Name</span>
               <input
                 type='text'
                 value={formData.name}
                 name='name'
                 onChange={handleChange}
                 placeholder='Enter Account Holder Name'
                 required
               ></input>
             </div>
             <div className='add-address-modal-coupon-enter-input'>
               <span>IFSC Code</span>
               <input
                 type='text'
                 value={formData.ifsc}
                 name='ifsc'
                 onChange={handleChange}
                 placeholder='Enter Your IFSC Code'
                 required
               ></input>
             </div>
           </div>
           <div className='my-bank-account-profile-main-page-add-account-submit'>
             <button onClick={OnAddNewAccountSubmit}>Submit</button>
           </div>
         </div>):pageState === 'enterAmount' ? (
                          <div className='transfer-amount-page'>
                          <div className='profile-wallet-main-page-heading sendmoney-transfer'>
                            <span>Enter Transfer Amount</span>
                          </div>
                          <div className='transfer-amount-input'>
                            <input
                              type='number'
                              value={transferAmount}
                              onChange={handleTransferAmountChange}
                              placeholder='Enter Amount'
                              required
                            />
                          </div>
                          <div className='transfer-amount-submit'>
                            <button onClick={handleTransferSubmit}>Submit</button>
                          </div>
                        </div>
                      ) : null
                      }
        
              
              </div>
              </div>

      </>
    )}
  </React.Fragment>
);
}

function Bankcard2(props){
  console.log(props);
    return (
        <React.Fragment>
            
          <div className='my-bank-account-profile-card'>
            <div className='my-bank-account-profile-card-topheading'>
              <div>Account Holder Name:<span> { props.data.account_holder_name}</span> </div>
              <div>Account Number:<span> { props.data.account_number}</span> </div>
              <button style={{width:"100%"}} onClick={() => props.onSelect(props.data.account_id)}>Select</button>
            </div>
          </div>
        </React.Fragment>
      );
}