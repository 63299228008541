import React from "react";
import profileapi from "../../api/profileapi";
import notify from "../../error";

export default function AddressCards(props) {
  // const [page,Setpage] = React.useState(1)
  console.log(props);
  const wrapper = React.useRef();
  function moreOptionAddress() {
    console.log('enter');
    wrapper.current.classList.toggle('profile-account-setting-right-container-address-list-item-modal-2');
  }

  const Remove = async () => {
    const response = await profileapi.RemoveAddress(props.data.address_id)
    console.log(response)

    if (response.data && response.data.error !== '') {
      notify('warn', response.data.error);
    } else {
      notify('success', 'Address deleted  successfully');
      moreOptionAddress();
      props.reload();
    }

  }
  const EditAddress = () => {
    console.log(props.data)
    props.other(props.data)
    console.log('send')
  }

  const editSetASDefault = async () => {
    if (!props.data.default_address == 1) {
      const response = await profileapi.ModifyAddress(
        props.data.name,
        props.data.phone,
        props.data.addressline1,
        props.data.addressline2,
        props.data.landmark,
        props.data.town,
        props.data.pincode,
        props.data.latitude,
        props.data.longitude,
        props.data.district,
        props.data.state,
        1,
        props.data.address_id
      );
      console.log(response);
      props.reload();
      if (response.data && response.data.error !== '') {
        notify('warn', response.data.error);
      } else {
        notify('success', 'Address is set to default  successfully');
        moreOptionAddress();
        props.back();

      }
    }else{
      notify('error', 'Address is already set to default ');
    }
  }
  return (
    <React.Fragment>
      <div className='profile-account-setting-right-container-address-list-item'>
        <div
          className='profile-account-setting-right-container-address-list-item-dot'
          onClick={moreOptionAddress}
        >
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div
          className='profile-account-setting-right-container-address-list-item-modal'
          ref={wrapper}
        >
          <div onClick={EditAddress}>Edit</div>
          <div onClick={Remove}>Delete</div>
          <div onClick={editSetASDefault}>Set as Default</div>
        </div>
        <div className='profile-account-setting-right-container-address-list-item-top'>

          {props.data.default_address == 1 ? <p class="default-address-icon">default address</p> : " "}
          <div>HOME</div>
          <span>{props.data.name}</span>
          <section>{props.data.phone}</section>
        </div>
        <div className='profile-account-setting-right-container-address-list-item-bottom'>
          {props.data.addressline1},{props.data.addressline2},{props.data.district},{props.data.state},{props.data.pincode}
        </div>
      </div>
    </React.Fragment>
  );
}