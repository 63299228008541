import React, { Component } from "react";
import chatapi from "../api/chatapi";
import { useGlobalContext } from "../context";
import "./chat-css/chatbox.css";
import mic from "../imageMobile/mic.svg";
import notify from "../error";
import search from "../images/chatImages/search.svg";
import edit from "../images/chatImages/edit.svg";
import emptypage from "../images/chatImages/image3.svg";
import vcicon from "../images/chatImages/videocall.svg";
import callicon from "../images/chatImages/callicon.svg";
import Picker from 'emoji-picker-react';
import emoji from "../images/chatImages/emoji.svg";
import { BsFillPersonPlusFill } from "react-icons/bs";
import { IoMdArrowBack } from "react-icons/io";
import pin from "../images/chatImages/pinImage.svg";
// import emojismile from '../images/chatImages/emojismile.svg';
// import sendbtn from '../images/chatImages/sendbtn.svg';
// import recordemoji1 from '../images/chatImages/recordemoji1.svg';
// import recordemoji2 from '../images/chatImages/recordemoji2.svg';
// import recordemoji3 from '../images/chatImages/recordemoji3.svg';
// import falseicon1 from '../images/chatImages/falseicon1.svg';
// import falseicon2 from '../images/chatImages/falseicon2.svg';
// import blockcircle from '../images/chatImages/blockcr.svg';
// import block1 from '../images/chatImages/block1.svg';
// import report1 from '../images/chatImages/repoer1.svg';
// import report2 from '../images/chatImages/report2.svg';
// import delete1 from '../images/chatImages/delete1.svg';
// import delete2 from '../images/chatImages/delete2.svg';
// import delete3 from '../images/chatImages/delete3.svg';
// import delete4 from '../images/chatImages/delete4.svg';
import ChatBoxPerson from "./ChatBoxPerson";
import ChatArea from "./ChatArea";
import { error } from "jquery";
export default function Chatbox(props) {
  const [chatFriendInfo, setChatFriendInfo] = React.useState();
  const [chatServiceInfo, setChatServiceInfo] = React.useState();
  const [searchValue, SetSearchValue] = React.useState("");
  const [category, setCategory] = React.useState(props.usertype);
  const [currentChatUser, setCurrentChatUser] = React.useState();
  const [currentChatMessege, setCurrentChatMessage] = React.useState();
  const [SendMessage, setSendMessage] = React.useState();
  const { chatScreen, setChatScreen } = useGlobalContext();
  const [showEmojiPicker, setShowEmojiPicker] = React.useState(false);
  const emojiref=React.useRef();
  const emojiref1=React.useRef();
  const toggleEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };
  const CurrentChatDetails = (p) => {
    setCurrentChatUser(p);
    console.log(currentChatUser);
  };
  const CurrentChatMessageDetails = async (id) => {
    // window.alert('run show message only when apge is active')
    const response = await chatapi.ShowMessage(id);
    setCurrentChatMessage(response.data);
    console.log(response);
  };

  React.useEffect(() => {
    if (currentChatUser) {
      const intervalId = setInterval(() => {
        CurrentChatMessageDetails(currentChatUser.chat_id);
      }, 500000);

      return () => clearInterval(intervalId);
    }
  }, [currentChatUser]);

  const handleSendMessaging = (e) => {
    setSendMessage(e.target.value);
  };

  const handleRealSending = async (e) => {
    if (e.key === "Enter") {
      setSendMessage(e.target.value);
      if(e.target.value.length<=0){
        return;
      }
      const response = await chatapi.SendMessage(
        currentChatUser.chat_id,
        e.target.value
      );
      CurrentChatMessageDetails(currentChatUser.chat_id);
      console.log(response);
      if (response.data.error !== "") {
        notify("error", response.data.error);
      }
      setSendMessage("");
    }
  };

  React.useEffect(() => {
    if (props.check) {
      ChatMyFriend();
    }
  }, [props.check]);

  const ChatMyFriend = async () => {
    const response = await chatapi.ChatUserFriendInfo();
    console.log(response);
    let key, val;
    for ([key, val] of Object.entries(response.data)) {
      console.log(val);
      setChatFriendInfo(val);
    }
  };
  const ChatMyService = async () => {
    const response = await chatapi.ChatUserServiceInfo();
    console.log(response);
    let key, val;
    for ([key, val] of Object.entries(response.data)) {
      console.log(val);
      setChatServiceInfo(val);
    }
  };
  const manageoutsideclickemoji = (event)=>{
   if(!(emojiref && emojiref.current && emojiref.current.contains(event.target) || emojiref1 && emojiref1.current && emojiref1.current.contains(event.target))){
    console.log("jdjdj",showEmojiPicker)
  //  if(showEmojiPicker){
  //   console.log("djdd")
  //  }
    setShowEmojiPicker(false);
   }
  }
  React.useEffect(() => {
    ChatMyFriend();
    ChatMyService();
    document.addEventListener("click",manageoutsideclickemoji);

    return () =>{
      document.removeEventListener("click",manageoutsideclickemoji);
    }
  }, []);

  const onEmojiClick = (event, emojiObject) => {
    const emojiToAdd = emojiObject && emojiObject.emoji ? emojiObject.emoji : '';
  
    setSendMessage((prev) => (prev || '') + emojiToAdd);
    setShowEmojiPicker(false);
  };


  const handleSearchString = (e) => {
    SetSearchValue(e.target.value);
  };
  const ChangeCategory = (e) => {
    setCategory(e.target.dataset.value);
    SetSearchValue("");
  };
  const addCHATUSER = () => {
    console.log("run");
    props.addition();
  };
  const deleteFriend = async (id) => {
    const response = await chatapi.DeleteFriend(id);
    console.log("Deleting chat with ID:", id);
    console.log(response);
    ChatMyFriend();
    ChatMyService();
  };
  const handleBlockUser = async (id, is_block) => {
    if (is_block) {
      // Call the unblock API
      const response = await chatapi.UnBlockUser(id);
      console.log("Unblock chat with ID:", id);
      console.log(response);
      ChatMyFriend();
      notify("success", "User unblocked successfully!");
    } else {
      const response = await chatapi.BlockUser(id);
      console.log("Block chat with ID:", id);
      console.log(response);

      ChatMyFriend();
      notify("success", "User blocked successfully!");
    }
  };

  return (
    <React.Fragment>
      <>
        <div className="chat-box-left-container">
          <div className="chat-box-left-edit-row">
            <BsFillPersonPlusFill onClick={addCHATUSER} fontSize={25} />
          </div>
          <div className="chat-box-search-chat-container">
            <div className="chat-box-search-chat">
              <img src={search} alt="" />
              <input
                type="text"
                value={searchValue}
                name="search_value"
                onChange={handleSearchString}
                placeholder="Search or Start a New Chat"
                required
              />
            </div>
          </div>
          <div className="chat-box-left-category-handle">
            {category === "friend" ? (
              <div>Friends</div>
            ) : (
              <span onClick={ChangeCategory} data-value="friend">
                Friends
              </span>
            )}
            {category === "seller" ? (
              <div>Sellers</div>
            ) : (
              <span onClick={ChangeCategory} data-value="seller">
                Sellers
              </span>
            )}
            {category === "customer" ? (
              <div>Customers</div>
            ) : (
              <span onClick={ChangeCategory} data-value="customer">
                Customers
              </span>
            )}
          </div>

          {/*  */}
          <div className="chat-box-left-container-chat-icons">
            {console.log(chatFriendInfo)}
            {category === "friend" ? (
              <>
                {chatFriendInfo &&
                  chatFriendInfo.map((item) => {
                    if (item["is user"] === 1) {
                      return <></>;
                    }
                    if (
                      searchValue !== "" &&
                      item.username
                        .toLowerCase()
                        .includes(searchValue.toLowerCase())
                    ) {
                      console.log(item.username);
                      return (
                        <ChatBoxPerson
                          data={item}
                          user={CurrentChatDetails}
                          messenger={CurrentChatMessageDetails}
                          deleteFriend={deleteFriend}
                          handleBlockUser={handleBlockUser}
                        />
                      );
                    }
                    if (searchValue === "") {
                      return (
                        <ChatBoxPerson
                          data={item}
                          user={CurrentChatDetails}
                          messenger={CurrentChatMessageDetails}
                          deleteFriend={deleteFriend}
                          handleBlockUser={handleBlockUser}
                        ></ChatBoxPerson>
                      );
                    }
                  })}
              </>
            ) : category === "seller" ? (
              <>
                {chatServiceInfo && chatServiceInfo.length > 0 ? (
                  chatServiceInfo.map((item) => {
                    if (item["is user"] === 1) {
                      return <></>;
                    }
                    if (
                      searchValue !== "" &&
                      item.username
                        .toLowerCase()
                        .includes(searchValue.toLowerCase())
                    ) {
                      console.log(item.username);
                      return (
                        <ChatBoxPerson
                          data={item}
                          user={CurrentChatDetails}
                          messenger={CurrentChatMessageDetails}
                          deleteFriend={deleteFriend}
                        />
                      );
                    }
                    if (searchValue === "") {
                      return (
                        <ChatBoxPerson
                          data={item}
                          user={CurrentChatDetails}
                          messenger={CurrentChatMessageDetails}
                          deleteFriend={deleteFriend}
                        ></ChatBoxPerson>
                      );
                    }
                  })
                ) : (
                  <div>No sellers available</div>
                )}
              </>
            ) : (
              <>no customer</>
            )}
          </div>
        </div>
        <div
          className={`chat-box-right-container ${
            chatScreen && "showRightChat"
          }`}
        >
          {currentChatUser ? (
            <>
              <div className="chat-box-right-container-full-case">
                <div className="chat-box-right-container-full-case-name">
                  <IoMdArrowBack
                    className="backButton"
                    onClick={() => setChatScreen(false)}
                  />
                  <div className="chat-box-right-container-full-case-name-image">
                    <img src={currentChatUser.profile_image}></img>
                  </div>
                  <div className="chat-box-right-container-full-case-name-name">
                    {currentChatUser.username}
                  </div>
                </div>
                <div className="chat-box-right-container-full-case-icons">
                  <div className="chat-box-right-container-full-case-icons-image">
                    <img src={vcicon}></img>
                  </div>
                  <div className="chat-box-right-container-full-case-icons-image">
                    <img src={callicon}></img>
                  </div>
                </div>
         </div>
              <div className="chat-box-actual-chat-place">
                <ChatArea data={currentChatMessege}></ChatArea>
                {showEmojiPicker && (
 <div className="emoji-picker" ref={emojiref}> <Picker onEmojiClick={onEmojiClick} height={400} width={400} /></div>
)}
     
              </div>
              <div className="chat-box-actual-chat-place-send-message">
                <div className="chat-box-actual-chat-place-send-message-mes" ref={emojiref1}>
                  <span onClick={toggleEmojiPicker}>
                    <img src={emoji} ></img>
                  </span>
                  <input
                    type="text"
                    value={SendMessage}
                    name="send_message"
                    onChange={handleSendMessaging}
                    onKeyDown={handleRealSending}
                    placeholder="Type a message"
                    required
                  ></input>
                  <div>
                    <img src={pin}></img>
                  </div>
                </div>
                <div className="chat-box-actual-chat-place-send-message-mic">
                  <img src={mic}></img>
                </div>
                
              </div>

            </>
          ) : (
            <>
              <div className="chat-box-right-container-empty-case">
                <img src={emptypage}></img>
              </div>
            </>
          )}
        </div>
      </>
    </React.Fragment>
  );
}
