import React from "react";
import ServiceCards from "./serviceCards";
import "../css/service.css";
import scrollright from '../images/scrollright.svg'
import scrollleft from '../images/scrollleft.svg';
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
export default function Service(props) {
  //console.log(props)
  const [firstClass, setFirstClass] = React.useState("service-name");

  const [isHovered, setIsHovered] = React.useState(false);
  React.useEffect(() => {
    if (props.id === 1) {
      setFirstClass("service-name");
    }
  }, []);

  const wrapperRef = React.useRef(null);
  const scrollLeft = (e) => {
    // console.log(e);
    // console.log(wrapperRef.current.offsetWidth);
    // console.log(props.popular.length * 376);
    let x = wrapperRef.current.scrollLeft;
    console.log(x);
    wrapperRef.current.scrollLeft += 752;
  };
  const scrollRight = (e) => {
    // console.log(e);
    // console.log(wrapperRef.current.offsetWidth);
    // console.log(props.popular.length * 376);
    let x = wrapperRef.current.scrollLeft;
    wrapperRef.current.scrollLeft -= 752;
  };

  const navigate = useNavigate();
  function move() {
    navigate(`/Sub_serviceProduct/${props.id}/${props.name}`);

  }

  // console.log(props)

  return (
    <React.Fragment>
      <div className="service-container">
        <div className={firstClass}>
          <div className="service-name-container" onClick={move}>
            {props.name}
          </div>
        </div>
        <div className={`service-provide-head ${isHovered ? 'hovered' : ''}`} 
         onMouseEnter={() => setIsHovered(true)}
         onMouseLeave={() => setIsHovered(false)}
        >
                    {props.popular.seller.length > 4 && (
           <div className='carousel-scroll'>
           <button
             className='carousel-scroll-left'
             onClick={scrollRight}
           >
             <img src={scrollleft}></img>
           </button>

           <button
             className='carousel-scroll-right'
             onClick={scrollLeft}
           >
             <img src={scrollright}></img>
           </button>
         </div>
          )}
          <div className="service-provider" ref={wrapperRef}>
            {props.popular.seller.map((item) => {
              // let temp = '/serviceprovider/' + item.username;
              // console.log(temp)
              // console.log(item);
              // console.log(item);
              return (
                <Link to={`/${item.username}`} key={item.id}>
                  <ServiceCards
                    key={item.id}
                    logo={item.logo}
                    name={item.name}
                    serve={item.services}
                    user={item.username}
                    id={item.id}
                    parent_id={props.id || props.parent_id}
                    address_line1={item.address_line1}
                    serviceable_distance={item.serviceable_distance}
                    serviceprovider_description={
                      item.serviceprovider_description
                    }
                    sub_service_id={item.sub_service_id}
                    serviceprovider_id={item.serviceprovider_id}
                  />
                </Link>
              );
            })}
          </div>

        </div>
      </div>
    </React.Fragment>
  );
}
