import React, { Component } from 'react'
import './chat-css/chat.css';
import Footer from '../components/footer';
import Chatbox from './ChatBox';
import { useGlobalContext } from '../context';
import chatapi from '../api/chatapi';
import notify from '../error';
import { useParams } from 'react-router-dom';
import emptypage from '../images/chatImages/image3.svg';

export default function Chat()
{
 const { Userprofile } = useGlobalContext();
 const{type}=useParams();
const [mobile,setMobile]=React.useState('')
const [reload, setReload] = React.useState(false);
const [add, setAdd] = React.useState(false);
const handleChange=(e)=>
{
 setMobile(e.target.value)
}
const handleSubmit = async (e) => {
  if (e.key === 'Enter') {
    handleAddUser();
  }
};

const handleAddUser = async () => {
  if (mobile.match(/^[0-9]+$/) === null || mobile.length !== 10) {
    notify('error', 'Please enter a valid mobile number.');
    return;
  }

  setMobile(mobile);
  setReload(false);
  const response = await chatapi.ConnectToANewFirend(`+91${mobile}`);
  console.log(response);
  if (response.data.error !== '') {
    notify('error', response.data.error);
  } else {
    if(response.data.connetion_exist===1){
      notify('error', 'User Already added to chat.');
      return;
    }
    notify('success', 'User successfully added to chat.');
    setReload(true);
    addnewuser();
    setMobile('');
  }
};


const addnewuser=()=>
{
 setAdd(!add);
}

 return (
   <React.Fragment>
     {Userprofile ? (
       <>
         <div className='chat-page-container'>
           {add && (
             <div className='chat-page-add-friend-back'>
               <div className='chat-page-add-friend'>
                 <div className='chat-page-add-friend-cross'>
                   <span onClick={addnewuser}>X</span>
                 </div>
                 <span>MOBILE NUMBER</span>
                 <input
                   type='text'
                   name='mobile'
                   maxLength='10'
                   value={mobile}
                   onChange={handleChange}
                   onKeyDown={handleSubmit}
                   placeholder='Enter Mobile Number'
                 ></input>
                 <div className='chat-page-add-friend-button-div'>
                 <button className="chat-page-add-friend-button" onClick={handleAddUser} >ADD</button>
                 </div>
               </div>
               
             </div>
           )}
           <div className='chat-page-inner-container'>
             <Chatbox check={reload} addition={addnewuser} usertype={type}></Chatbox>
           </div>
         </div>
        
       </>
     ) : (
       <div className='login-to-continue-chat-page'>
        <div className="login-to-continue-photo">
         <img src={emptypage}></img>
        </div>
        <span>
         Login TO Continue
        </span>
        </div>
     )}
   </React.Fragment>
 );
}