import axios from 'axios';
//company
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

class ProductPageService {
  GetProductData(service_id, sub_service_id, index) {
    return axios.post(
      API_BASE_URL + '/v1/api/sub_service_product/' + index,
      { service_id, sub_service_id },
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }
  GetAllProductData(
    brand_id,
    price_greater_than,
    price_less_than,
    product_type_id,
    quantity_id,
    rating,
    service_id,
    sort_by,
    sub_service_id,
    index
  ) {
    
    return axios.post(
      API_BASE_URL + '/v1/api/sub_service_product/' + index,
      {
        brand_id,
        price_greater_than,
        price_less_than,
        product_type_id,
        quantity_id,
        rating,
        service_id,
        sort_by,
        sub_service_id,
      },
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }
  GetFilteredProductData(
    brand_id,
    price_greater_than,
    price_less_than,
    product_type_id,
    quantity_id,
    rating,
    service_id,
    sort_by,
    sub_service_id,
    index,
    color_code
  ) {
  
    const data = {
      brand_id,
      quantity_id,
      rating,
      service_id,
      sort_by,
      sub_service_id,

      
    };
    if (price_greater_than !== null && price_less_than!==null) {
      console.log("null");
      data.price_greater_than = price_greater_than;
      data.price_less_than = price_less_than;
    }
  
    if(
      color_code!==null || color_code!==undefined
    ){
      data.color_code=color_code;
    }
    const validProductTypeIds = product_type_id.filter(
      (id) => !isNaN(id) && id !== null
    );
console.log(validProductTypeIds);
    // If there are valid product_type_ids, use the first one and overwrite the previous entry
    if (validProductTypeIds.length > 0) {
      data.product_type_id = validProductTypeIds;
    }

    console.log(data);
    return axios.post(
      API_BASE_URL + '/v1/api/ProductAccordingToSubServiceFilter_withcolor/' + index,
      
        data
      ,
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }
  // for filters data of provider page
  GetAllFiltersnewData(
    service_id,
    sub_service_id,

  ) {
    
    return axios.post(
      API_BASE_URL + '/v1/api/ProductAccordingToSubServiceFilter_withcolor_filter/0',
      {
       
        service_id,
        sub_service_id,
      },
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }
}

export default new ProductPageService();
