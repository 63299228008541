import React, { useState, useEffect } from 'react'
import "./../admincss/adminTabs.css"
const AdminTabs = (props) => {
    const handleTabClick = (tabName, page3, page2) => {
        props.setPage2(page2);
        props.setTabSelected(tabName);
    };
    return (
        <>
            <div className="admin-service-profile-page-view-main">
                <div className="admin-service-profile-page-view-main-container">


                    <div
                        className={`admin-service-profile-page-view-main-headings ${props.tabSelected === 'Pending Orders' ? 'admin-service-profile-page-view-main-headings-active' : ''}`}
                        onClick={() => handleTabClick('Pending Orders', 1, 1)}
                    >
                        <span>Pending Orders {" "}
                        {
                        props.data.pending_orders_count  !== undefined  ? <>(<span>
                                {props.data.pending_orders_count}
                                </span>)
                                </>:<>
                              {  props.tabSelected === 'Pending Orders' ? <> &nbsp;<span className='justloader'></span>
                              </>:
                              <>
                              &nbsp;<span className='justloader1'></span>
                              </>
}
                                </>
                        }
                            </span>
                    </div>
                    <div
                        className={`admin-service-profile-page-view-main-headings ${props.tabSelected === 'Orders alloted to ServiceProvider' ? 'admin-service-profile-page-view-main-headings-active' : ''}`}
                        onClick={() => handleTabClick('Orders alloted to ServiceProvider', 2, 2)}
                    >
                        <span>Orders alloted to ServiceProvider {" "}
                        {
                        props.data.alloted_order_to_serviceprovider_count  !== undefined  ? <>(<span>
                                {props.data.alloted_order_to_serviceprovider_count}
                                </span>)
                                </>:<>
                              {  props.tabSelected === 'Orders alloted to ServiceProvider' ? <> &nbsp;<span className='justloader'></span>
                              </>:
                              <>
                              &nbsp;<span className='justloader1'></span>
                              </>
}
                                </>
                        }

                        </span>
                    </div>
                    <div
                        className={`admin-service-profile-page-view-main-headings ${props.tabSelected === 'Shipped by ServiceProvider' ? 'admin-service-profile-page-view-main-headings-active' : ''}`}
                        onClick={() => handleTabClick('Shipped by ServiceProvider', 3, 3)}
                    >
                        <span>Shipped by ServiceProvider {" "}
                        {
                        props.data.shipped_by_providers_order_count !== undefined ? <>(<span>
                                {props.data.shipped_by_providers_order_count}
                                </span>)
                                </>:<>
                              {  props.tabSelected === 'Shipped by ServiceProvider' ? <> &nbsp;<span className='justloader'></span>
                              </>:
                              <>
                              &nbsp;<span className='justloader1'></span>
                              </>
}
                                </>
                        }
                        </span>
                    </div>
                    <div
                        className={`admin-service-profile-page-view-main-headings ${props.tabSelected === 'Received at Areamanager' ? 'admin-service-profile-page-view-main-headings-active' : ''}`}
                        onClick={() => handleTabClick('Received at Areamanager', 4, 4)}
                    >
                        <span>Received at Areamanager{" "}
                        {
                        props.data.order_recieved_at_areamanager_count  !== undefined  ? <>(<span>
                                {props.data.order_recieved_at_areamanager_count}
                                </span>)
                                </>:<>
                              {  props.tabSelected === 'Received at Areamanager' ? <> &nbsp;<span className='justloader'></span>
                              </>:
                              <>
                              &nbsp;<span className='justloader1'></span>
                              </>
}
                                </>
                        }
                        </span>
                    </div>
                    <div
                        className={`admin-service-profile-page-view-main-headings ${props.tabSelected === 'Shipped from Areamanager' ? 'admin-service-profile-page-view-main-headings-active' : ''}`}
                        onClick={() => handleTabClick('Shipped from Areamanager', 5, 5)}
                    >
                        <span>Shipped from Areamanager {" "}
                        {
                        props.data.shipped_orders_by_manager_count !== undefined ? <>(<span>
                                {props.data.shipped_orders_by_manager_count}
                                </span>)
                                </>:<>
                              {  props.tabSelected === 'Shipped from Areamanager' ? <> &nbsp;<span className='justloader'></span>
                              </>:
                              <>
                              &nbsp;<span className='justloader1'></span>
                              </>
}
                                </>
                        }</span>
                    </div>  <div
                        className={`admin-service-profile-page-view-main-headings ${props.tabSelected === 'Orders cancel by ServiceProvider' ? 'admin-service-profile-page-view-main-headings-active' : ''}`}
                        onClick={() => handleTabClick('Orders cancel by ServiceProvider', 6, 6)}
                        >
                        <span>Orders cancel by ServiceProvider {" "}
                        {
                        props.data.order_cancel_by_serviceprovider_count !== undefined  ? <>(<span>
                                {props.data.order_cancel_by_serviceprovider_count}
                                </span>)
                                </>:<>
                              {  props.tabSelected === 'Orders cancel by ServiceProvider' ? <> &nbsp;<span className='justloader'></span>
                              </>:
                              <>
                              &nbsp;<span className='justloader1'></span>
                              </>
}
                                </>
                        }
                                </span>
                    </div>
                    <div
                        className={`admin-service-profile-page-view-main-headings ${props.tabSelected === 'Partial action of order' ? 'admin-service-profile-page-view-main-headings-active' : ''}`}
                        onClick={() => handleTabClick('Partial action of order', 7, 7)}
                    >
                        <span>Partial action of order {" "}
                        {
                        props.data.order_partial_action_by_providers_count !== undefined ? <>(<span>
                                {props.data.order_partial_action_by_providers_count}
                                </span>)
                                </>:<>
                              {  props.tabSelected === 'Partial action of order' ? <> &nbsp;<span className='justloader'></span>
                              </>:
                              <>
                              &nbsp;<span className='justloader1'></span>
                              </>
}
                                </>
                        }</span>
                    </div>
                    <div
                        className={`admin-service-profile-page-view-main-headings ${props.tabSelected === 'Order Delivered' ? 'admin-service-profile-page-view-main-headings-active' : ''}`}
                        onClick={() => handleTabClick('Order Delivered', 8, 8)}
                    >
                        <span>Order Delivered {" "}
                        {
                        props.data.delivered_order_by_manager_count !== undefined  ? <>(<span>
                                {props.data.delivered_order_by_manager_count}
                                </span>)
                                </>:<>
                              {  props.tabSelected === 'Order Delivered' ? <> &nbsp;<span className='justloader'></span>
                              </>:
                              <>
                              &nbsp;<span className='justloader1'></span>
                              </>
}
                                </>
                        }</span>
                    </div>
                    <div
                        className={`admin-service-profile-page-view-main-headings ${props.tabSelected === 'Order Cancelled by Areamanager' ? 'admin-service-profile-page-view-main-headings-active' : ''}`}
                        onClick={() => handleTabClick('Order Cancelled by Areamanager', 9, 9)}
                    >
                        <span>Order Cancelled by Areamanager {" "}
                        {
                        props.data.cancelled_order_by_manager_count !== undefined ? <>(<span>
                                {props.data.cancelled_order_by_manager_count}
                                </span>)
                                </>:<>
                              {  props.tabSelected === 'Order Cancelled by Areamanager' ? <> &nbsp;<span className='justloader'></span>
                              </>:
                              <>
                              &nbsp;<span className='justloader1'></span>
                              </>
}
                                </>
                        }
                        </span>
                    </div>
                    <div
                        className={`admin-service-profile-page-view-main-headings ${props.tabSelected === 'Order Cancelled by User' ? 'admin-service-profile-page-view-main-headings-active' : ''}`}
                        onClick={() => handleTabClick('Order Cancelled by User', 10, 10)}
                    >
                        <span>Order Cancelled by User {" "}
                        {
                        props.data.cancelled_order_by_user_count !== undefined ? <>(<span>
                                {props.data.cancelled_order_by_user_count}
                                </span>)
                                </>:<>
                              {  props.tabSelected === 'Order Cancelled by User' ? <> &nbsp;<span className='justloader'></span>
                              </>:
                              <>
                              &nbsp;<span className='justloader1'></span>
                              </>
}
                                </>
                        }
                        </span>
                    </div>
                </div>
                </div>
        </>
    )
}

export default AdminTabs