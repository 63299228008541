import axios from 'axios';
//company
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

class PutatoeCartApi {
  ShowCart() {
    return axios.get(API_BASE_URL + '/v1/api/show_cart', {
      headers: {
        authtoken: localStorage.getItem('authtoken'),
      },
    });
  }

  UpdateProd(cart_id, coupon_id, quantity, status) {
    return axios.post(
      API_BASE_URL + '/v1/api/update_cart',
      { cart_id, coupon_id, quantity, status },
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }

  DeleteProd(cart_id) {
    return axios.get(API_BASE_URL + '/v1/api/delete_from_cart/' + cart_id, {
      headers: {
        authtoken: localStorage.getItem('authtoken'),
      },
    });
  }

  ClearCart() {
    return axios.get(API_BASE_URL + '/v1/api/clear_cart', {
      headers: {
        authtoken: localStorage.getItem('authtoken'),
      },
    });
  }
  AllCoupons(for_mobile) {
    return axios.post(
      API_BASE_URL + '/v1/api/getallcoupons',
      { for_mobile }, //false for cart true for mobile recharge
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }
  ApplyCoupons(coupon_id) {
    return axios.post(
      API_BASE_URL + '/v1/api/applycoupon',
      { coupon_id }, //false for cart true for mobile recharge
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }
  ApplyCouponsMobRecharge(coupon_id,recharge_amount) {
    return axios.post(
      API_BASE_URL + '/v1/api/applymobilecoupon',
      { coupon_id,recharge_amount }, 
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }
  BillingPage() {
    return axios.get(API_BASE_URL + '/v1/api/billing_page', {
      headers: {
        authtoken: localStorage.getItem('authtoken'),
      },
    });
  }
 removeCoupon() {
    return axios.get(API_BASE_URL + '/v1/api/removecoupon', {
      headers: {
        authtoken: localStorage.getItem('authtoken'),
      },
    });
  }
}

export default new PutatoeCartApi();
