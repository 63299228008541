import React from "react";
import api from "../api/api";
import Service from "./service";
import { useGlobalContext } from "../context";
import "../css/bestSellers.css";
import Loading from "./Loading";
export default function BestSellers(props) {
  const { products, setStatusloading, sellerData } = useGlobalContext();



  const [loading, setLoading] = React.useState(true);




  // const { cocktails } = useGlobalContext();
  // console.log(cocktails);
  // console.log(useGlobalContext())

  //console.log(products[0].data);
  return (
    <React.Fragment>
      <div className='desktop-view'>
        <div className='bestseller-container'>
          <div className='bestseller-heading-container'>



            {products?.length > 0 && products[0].data && (
              <>
                <div className='bestseller-vertical-fill'></div>
                <h1 className='bestseller-heading'>
                  Best Seller For {products[0].data.service_name}
                </h1>

              </>

            )}
          </div>
          {sellerData ? Object.entries(sellerData).map(([category, items]) => (

            <React.Fragment key={category}>
              {/* {console.log(items)} */}
              <Service
                key={category}
                id={items.sub_service_id}
                name={category}
                popular={items}

              />
            </React.Fragment>

          ))
            : <>{loading && <Loading />}</>

          }



        </div>
      </div>
    </React.Fragment>
  );
}
