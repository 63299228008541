import React, { useState,useEffect } from 'react';
import Adminheader from './adminheader'
import "../admincss/adminprofile.css"
import adminloginapi from '../adminapi/adminloginapi';
import notify from '../../error';

const Adminmyprofile = (props) => {
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [initialFormData, setInitialFormData] = useState({
    firstName: '',
    lastName: '',
    state: '',
    district: '',
    userid: '',
    userName: '',
  });
  const [formDataProfile, setFormData] = useState({
    firstName: '',
    lastName: '',
    state: '',
    district: '',
    userid: '',
    userName: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  useEffect(() => {

    fetchUserData();
  }, []);
  
  const fetchUserData=async()=>{
    const response = await adminloginapi.Managerprofile();
    if (response.data && response.data.error != "") {
      notify("error", response.data.error);
    } else {
      const [firstname,lastName]=response.data.name.split(" ");
      const initialData = {
        firstName: firstname,
        lastName: lastName,
        state: response.data.state,
        district: response.data.district,
        userid: response.data.id,
        userName: response.data.username,
      };

      setInitialFormData(initialData);
      setFormData(initialData);
    }
  }
  const handleSaveChanges = async () => {
    // Add logic to save changes to the backend
    const changedFields = areFieldsChanged();

  if (Object.keys(changedFields).length === 0) {
    // No changes, do nothing
    return;
  }
  if (changedFields.firstName && !changedFields.lastName) {
    // Only first name changed
    changedFields.name = `${changedFields.firstName} ${initialFormData.lastName}`;
  } else if (!changedFields.firstName && changedFields.lastName) {
    // Only last name changed
    changedFields.name = `${initialFormData.firstName} ${changedFields.lastName}`;
  } else if (changedFields.firstName && changedFields.lastName) {
    // Both first name and last name changed
    changedFields.name = `${changedFields.firstName} ${changedFields.lastName}`;
  }
  console.log("Changed Fields:", changedFields);
    const response = await adminloginapi.UpdateProfileManager(changedFields);
    if (response.data && response.data.error != "") {
      notify("error", response.data.error);
    } else {
      notify("success", "profile updated successfully");
      fetchUserData();

  };

}

const areFieldsChanged = () => {
  // Compare current form data with initial form data
  const changedFields = {};

  for (const key in formDataProfile) {
    if (formDataProfile[key] !== initialFormData[key]) {
      // Field has changed, add it to the changedFields object
      changedFields[key] = formDataProfile[key];
    }
  }

  // Return the object containing only the changed fields
  return changedFields;
};
  useEffect(() => {
    const changedFields = areFieldsChanged();
    setIsFormChanged(Object.keys(changedFields).length > 0);
  }, [formDataProfile]);

  return (
<>
<Adminheader isLoggedIn={props.isLoggedIn} setisLoggedIn={props.setIsLoggedIn}/>
<div className='divvv'>
            <div className="profile-account-setting-profile-details">
              {/*  */}
              <div className="profile-account-setting-profile-detials-box">
                <div className="profile-account-setting-profile-details-box-field">
                  First Name:
                </div>
                <div className="profile-account-setting-profile-detail-box-field-value">
                  <input
                    name="firstName"
                    type="text"
                    onChange={handleInputChange}
                    value={formDataProfile.firstName}
                  />
                </div>
              </div> 
              <div className="profile-account-setting-profile-detials-box">
                <div className="profile-account-setting-profile-details-box-field">
                  Last Name:
                </div>
                <div className="profile-account-setting-profile-detail-box-field-value">
                  <input
                     name="lastName"
                    type="text"
                    onChange={handleInputChange}
                    value={formDataProfile.lastName}
                  />
                </div>
              </div> 
              <div className="profile-account-setting-profile-detials-box">
                <div className="profile-account-setting-profile-details-box-field">
                  State:
                </div>
                <div className="profile-account-setting-profile-detail-box-field-value">
                  <input
                    name="state"
                    type="text"
                    readOnly
                    value={formDataProfile.state}
                  />
                </div>
              </div> 
              <div className="profile-account-setting-profile-detials-box">
                <div className="profile-account-setting-profile-details-box-field">
                  District:
                </div>
                <div className="profile-account-setting-profile-detail-box-field-value">
                  <input
                    name="district"
                    type="text"
                    readOnly
                    value={formDataProfile.district}
                  />
                </div>
              </div> 
              <div className="profile-account-setting-profile-detials-box">
                <div className="profile-account-setting-profile-details-box-field">
                  User ID:
                </div>
                <div className="profile-account-setting-profile-detail-box-field-value">
                  <input
                    name="userid"
                    type="text"
                    readOnly
                    value={formDataProfile.userid}
                  />
                </div>
              </div> 
              <div className="profile-account-setting-profile-detials-box">
                <div className="profile-account-setting-profile-details-box-field">
                  UserName:
                </div>
                <div className="profile-account-setting-profile-detail-box-field-value">
                  <input
                    name="userName"
                    type="text"
                    onChange={handleInputChange}
                    value={formDataProfile.userName}
                  />
                </div>
              </div> 
              </div> 
              {/*  */}

            <div className="profile-account-setting-profile-save">
              <button               onClick={handleSaveChanges}
              disabled={!isFormChanged} >Save Changes</button>
            </div>
          </div>
</>
  )
}

export default Adminmyprofile