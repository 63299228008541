import axios from 'axios';
//company
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

class PaymentApi {
  CashOnDelivery(day_id, slot_id, address_id, coupon_id) {
    return axios.post(
      API_BASE_URL + '/v1/api/order_new/cod',
      { day_id, slot_id, address_id, coupon_id },
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }

  PayFromWallet(day_id, slot_id, address_id, coupon_id) {
    return axios.post(
      API_BASE_URL + '/v1/api/order_new/wallet',
      { day_id, slot_id, address_id, coupon_id },
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }

  RazorPayOrderCreate(day_id, slot_id, address_id, coupon_id) {
    return axios.post(
      API_BASE_URL + '/v1/api/CreateOrderRazor',
      { day_id, slot_id, address_id, coupon_id },
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }

  RazorPayOrderVerify(
    razorpay_payment_id,
    razorpay_order_id,
    razorpay_signature,
    putatoe_order_id
  ) {
    return axios.post(
      API_BASE_URL + '/v1/api/VerifyPayment',
      {
        razorpay_payment_id,
        razorpay_order_id,
        razorpay_signature,
        putatoe_order_id,
      },
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }

  UPIPayment(day_id, slot_id, address_id, coupon_id, txnID, status, amount) {
    return axios.post(
      API_BASE_URL + '/v1/api/order_new/UPI',
      { day_id, slot_id, address_id, coupon_id, txnID, status, amount },
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }

  ClearCart() {
    return axios.get(API_BASE_URL + '/v1/api/clear_cart', {
      headers: {
        authtoken: localStorage.getItem('authtoken'),
      },
    });
  }
  AllCoupons(for_mobile) {
    return axios.post(
      API_BASE_URL + '/v1/api/getallcoupons',
      { for_mobile }, //false for cart true for mobile recharge
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }
  ApplyCoupons(coupon_id) {
    return axios.post(
      API_BASE_URL + '/v1/api/applycoupon',
      { coupon_id }, //false for cart true for mobile recharge
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }

  OrderConfirmation(orderId)
  {
    return axios.get(API_BASE_URL + '/v1/api/confirmation/'+ orderId, {
      headers: {
        authtoken: localStorage.getItem('authtoken'),
      },
    });
  }

  // AllPromotion(index) {
  //   return axios.get(API_BASE_URL + `/v1/api/displayPost/${index}`, {
  //     headers: {
  //       authtoken: localStorage.getItem('authtoken'),
  //       'Content-Type': 'application/json',
  //     },
  //   });
  // }
  // DeletePost(is_comment, id) {
  //   return axios.post(
  //     API_BASE_URL + '/v1/api/deletePost', //0 for post //1 for comment
  //     { is_comment, id },
  //     {
  //       headers: {
  //         authtoken: localStorage.getItem('authtoken'),
  //       },
  //     }
  //   );
  // }
}

export default new PaymentApi();
