import React, { useState } from 'react';
import { allStates, getDistricts } from "../stateDistrict";
import profileapi from '../api/profileapi';
import notify from '../error';

const EditAddress = ({ address, onClose, fetchAddress, HideModal }) => {
    const UserProfile = JSON.parse(localStorage.getItem("UserProfile"));
    // console.log(address, 'address 1212ad', UserProfile);
    const [formDataPassword, setFormDataPassword] = useState({
        name: address?.name || ` ${UserProfile.first_name}  ${UserProfile.last_name}`,
        phone: address?.phone || UserProfile.phone,
        addressline1: address?.addressline1,
        addressline2: address?.addressline2,
        landmark: address?.landmark,
        town: address?.town,
        state: address?.state,
        district: address?.district,
        pincode: address?.pincode,
        latitude: address?.latitude,
        longitude: address?.longitude,
        address_id: address?.address_id,
        default_address: 1
    });
    
    const [submitted, setSubmitted] = useState(false);

    const handleChangePassword = (e) => {
        const { name, value } = e.target;
        setFormDataPassword((prevState) => ({ ...prevState, [name]: value }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(formDataPassword)
        // return;
        try {
            setSubmitted(true);
            const { name, phone, addressline1, addressline2, landmark, town, state, district, pincode, latitude, longitude, address_id, default_address } = formDataPassword;

            const response = await profileapi.ModifyAddress(name, phone, addressline1, addressline2, landmark, town, pincode, latitude, longitude, district, state, default_address, address_id
            );
            console.log(response, 'edit address response 1212ad');
            if (response.status === 200) {
                fetchAddress();
                HideModal();
                onClose();
                notify('success', 'address updated successfully');
            }

        } catch (error) {
            console.error(error);
            notify('warn', 'try again later');
        }

    };

    return (
        <form onSubmit={handleSubmit} style={{ marginBottom: '10px' }}>
            <div className="profile-account-setting-right-new-address-input" style={{ gap: '10px' }}>
                <div className="form-group form-groupmodify">
                    <div className="form-label">Name<span className="required">*</span></div>
                    <input
                        type="text"
                        value={formDataPassword.name}
                        name="name"
                        onChange={handleChangePassword}
                        placeholder="Enter your name"
                        className={`${!formDataPassword.name && submitted ? 'errorborder' : 'form-groupmodifyinput'}`}
                    />
                    {!formDataPassword.name && submitted && <div className="error">Please Enter Your Name</div>}
                </div>

                <div className="form-group form-groupmodify">
                    <div className="form-label">Mobile No<span className="required">*</span></div>
                    <input
                        type="text"
                        value={formDataPassword.phone}
                        name="phone"
                        onChange={handleChangePassword}
                        placeholder="Enter your mobile number"
                        className={`${submitted && ((formDataPassword.phone != undefined || formDataPassword.phone != null) && !/^[0-9]{10}$/.test(formDataPassword.phone)) ? 'errorborder' : 'form-groupmodifyinput'}`}
                    />
                    {submitted && ((formDataPassword.phone != undefined || formDataPassword.phone != null) && !/^[0-9]{10}$/.test(formDataPassword.phone)) && <div className="error">Please Enter your valid 10 digit number</div>}
                </div>

                <div className="form-group form-groupmodify">
                    <div className="form-label">Address line 1<span className="required">*</span></div>
                    <input
                        type="text"
                        value={formDataPassword.addressline1}
                        name="addressline1"
                        onChange={handleChangePassword}
                        placeholder="Enter address line 1"
                        className={`${!formDataPassword.addressline1 && submitted ? 'errorborder' : 'form-groupmodifyinput'}`}
                    />
                    {submitted && !formDataPassword.addressline1 && <div className="error">Please fill the address line 1</div>}
                </div>

                <div className="form-group form-groupmodify">
                    <div className="form-label">Address line 2</div>
                    <input
                        type="text"
                        name="addressline2"
                        className="form-groupmodifyinput"
                        placeholder="Enter address line 2"
                        value={formDataPassword.addressline2}
                        onChange={handleChangePassword}
                    />
                </div>

                <div className="form-group form-groupmodify">
                    <div className="form-label">Landmark</div>
                    <input
                        type="text"
                        name="landmark"
                        className="form-groupmodifyinput"
                        placeholder="Enter your landmark"
                        value={formDataPassword.landmark}
                        onChange={handleChangePassword}
                    />
                </div>

                <div className="form-group form-groupmodify">
                    <div className="form-label">Town<span className="required">*</span></div>
                    <input
                        type="text"
                        value={formDataPassword.town}
                        name="town"
                        onChange={handleChangePassword}
                        placeholder="Enter your Town/City"
                        className={`${!formDataPassword.town && submitted ? 'errorborder' : 'form-groupmodifyinput'}`}
                    />
                    {!formDataPassword.town && submitted && <div className="error">Please Enter Your Town</div>}
                </div>

                <div className="form-group form-groupmodify">
                    <div className="form-label">State<span className="required">*</span></div>
                    <select
                        name="state"
                        className={`form-select form-select-h ${!formDataPassword.state && submitted ? 'errorborder' : 'form-groupmodifyselect'}`}
                        value={formDataPassword.state}
                        onChange={handleChangePassword}
                    >
                        <option value="Select State">Select State</option>
                        {allStates.map((state) => (
                            <option key={state} value={state}>
                                {state}
                            </option>
                        ))}
                    </select>
                    {!formDataPassword.state && submitted && <div className="error">Please Select a state</div>}
                </div>

                <div className="form-group form-groupmodify">
                    <div className="form-label">District<span className="required">*</span></div>
                    <select
                        name="district"
                        className={`form-select form-select-h ${!formDataPassword.district && submitted ? 'errorborder' : 'form-groupmodifyselect'}`}
                        value={formDataPassword.district}
                        onChange={handleChangePassword}
                    >
                        <option value="Select District">Select District</option>
                        {formDataPassword.state !== "Select State" &&
                            getDistricts(formDataPassword.state).map((district) => (
                                <option key={district} value={district}>
                                    {district}
                                </option>
                            ))}
                    </select>
                    {!formDataPassword.district && submitted && <div className="error">Please Select a district</div>}
                </div>

                <div className="form-group form-groupmodify">
                    <div className="form-label">Pincode<span className="required">*</span></div>
                    <input
                        type="number"
                        name="pincode"
                        className={`${submitted && ((formDataPassword.pincode != undefined || formDataPassword.pincode != null) && !/^[0-9]{6}$/.test(formDataPassword.pincode)) ? 'errorborder' : 'form-groupmodifyinput'}`}
                        placeholder="Enter your pincode"
                        value={formDataPassword.pincode}
                        onChange={handleChangePassword}
                    />
                    {submitted && ((formDataPassword.pincode != undefined || formDataPassword.pincode != null) && !/^[0-9]{6}$/.test(formDataPassword.pincode)) && <div className="error">Please Enter a Pincode</div>}
                </div>

                <div className="form-group form-groupmodify">
                    <div className="form-label">Latitude<span className="required">*</span></div>
                    <input
                        type="number"
                        name="latitude"
                        className="form-groupmodifyinput"
                        placeholder="Enter latitude"
                        value={formDataPassword.latitude}
                        onChange={handleChangePassword}
                        readOnly
                    />
                </div>

                <div className="form-group form-groupmodify">
                    <div className="form-label">Longitude<span className="required">*</span></div>
                    <input
                        type="number"
                        name="longitude"
                        className="form-groupmodifyinput"
                        placeholder="Enter longitude"
                        value={formDataPassword.longitude}
                        onChange={handleChangePassword}
                        readOnly
                    />
                </div>
            </div>
            <div className="profile-account-setting-right-new-address-input-result">
                <button type='submit' >Submit</button>
            </div>
        </form >
    );
};

export default EditAddress;
