import React, { useState, useContext, useEffect } from "react";
import { useCallback } from "react";
import api from "./api/api";
import apiclient from "./api/apiclient";
import PutatoeServiceApi from "./api/PutatoeServiceApi";
import notify from "./error";

//rerenderd is happeing becuase of fetchproducts api  but context is rerendering only once

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  const [paymentAmountMobile, setPaymentAmountMobile] = React.useState();
  const [admindetail, setAdmindetail] = React.useState("");
  const [service, setServices] = React.useState([]);
  const [loginDetail, setLoginDetail] = React.useState();
  const [loginPage, setLoginPage] = React.useState(0);
  const [notification, setNotification] = React.useState();
  const [products, setProducts] = React.useState([]);
  const [authtemp, setAuthtemp] = React.useState();
  const [tabs, setTabs] = React.useState([]);
  const [mobileNumber, setMobileNumber] = React.useState();
  const [allService, setAllService] = React.useState([]);
  const [userName, setUserName] = React.useState();
  const [timer, setTimer] = React.useState(false);
  const [generateBillArray, setGenerateBillArray] = React.useState([]);
  const notificationref = React.useRef("");
  const [statusloading, setStatusloading] = useState(false);
  let newObject = window.localStorage.getItem("UserProfile");
  // console.log(JSON.parse(newObject));
  const [Userprofile, setUserProfile] = React.useState(JSON.parse(newObject));
  // console.log(Userprofile)
  const [UserLocation, setUserLocation] = React.useState(newObject);
  // console.log(JSON.parse(newObject));
  const [allServiceSelector, setAllServiceSelector] = React.useState([]);
  const [subServicedata, setSubServicedata] = React.useState([]);
  const [sellerData, setSellerData] = React.useState([]);
  const [categorydata, setCategorydata] = React.useState([]);
  const [coouponid, setCoouponid] = useState(null);
  const [bannerloading, setBannerloading] = React.useState(false);
  const [banner, setbanners] = React.useState([]);
  //----------------------------------------------------------------
  const [token, setToken] = useState(localStorage.getItem("authtoken"));
  const [service_id, setServiceId] = useState(localStorage.getItem("serviceId"));
  // ----------------------------------------------------------------
  const [tempProduct, setTempProduct] = React.useState([]);
  const [tempProduct3, setTempProduct3] = React.useState([]);
  const [tempProduct4, setTempProduct4] = React.useState([]);
  const [tempProduct5, setTempProduct5] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [loginModal, setLoginModal] = React.useState(false);
  const [UndesiredProducts, setUndesiredProducts] = React.useState([]);
  const [desiredProducts, setDesiredProducts] = React.useState([]);
  const [changeInCart, setChangeInCart] = React.useState();
  const [likeUpdate, setLikeUpdate] = useState(false);
  const [modifyModal, setModifyModal] = useState(false);
  const [showPdfModal, setShowPdfModal] = useState(false);
  const [isCouponApplied, setIsCoupounApplied] = React.useState(false);
  const [expand, setExpand] = React.useState(true);
  const [notificationCount, setNoficationCount] = React.useState(0);
  const [display, setDisplay] = React.useState("login-sidebar-container-none");
  // blogStates
  const [createBlogModal, setCreateBlogModal] = React.useState(false);
  const [postBlog, setPostBlog] = React.useState(false);
  const [blogUpdate, setBlogUpdate] = React.useState(false);
  const [blogInfo, setBlogInfo] = React.useState([]);
  const [editBlogData, setEditBlogData] = React.useState([]);
  // const [loginwithotp, setLoginwithotp] = React.useState("unset");
  //Myservice states
  const [productsUpdate, setProductsUpdate] = React.useState(false);
  const [isMobileView, setIsMobileView] = React.useState(false);
  const [isproviderTypepage, setIsproviderTypepage] = React.useState(false);
  //recharege section and bill payements 
  const [rechargepage, setRechargePage] = React.useState(0);
  //chatbox
  const [chatScreen, setChatScreen] = React.useState(false);
  const [addwithoutLogin, setAddwithoutLogin] = React.useState("Gorakhpur, Gorakhpur Division, Uttar Pradesh");
  // job section
  const [jobModalInfo, setJobModalInfo] = useState("");
  //myprofile page
  const [page, setPage] = React.useState(0);
  const [innerPage, setInnerPage] = useState(false);
  const [externalRedirect, setExternalRedirect] = useState(false);
  const [isNumberChange, setIsNumberChange] = useState(false);
  const [sendOtp, setSendotp] = useState(false);
  const [refreshPage, setRefreshPage] = useState('');
  const [isOtpForSellerRegisteration, setIsOtpForSellerRegisteration] = useState(false);

  // offline orders allproducts
  const [inputPrice, setInputprice] = useState("");
  const [startIndex, setStartIndex] = useState(0);
  const [logoutdrop, setlogoutdrop] = React.useState("login-details-header-dropdown-none");

  //My service all subservice filter
  const [userServiceIDselected, setUserServiceIDselected] = React.useState(null);
  const [Allsubserviceavialable, setAllsubserviceavialable] = React.useState([]);
  const [otptoken, setOtptoken] = React.useState();

  // bloginfo
  const [myownblog, setMyownblog] = useState();
  const [cartdata, setCartdata] = useState();

  if (!localStorage.getItem('serviceId')) {
    localStorage.setItem('serviceId', 6);
  }

  const fetchbanner = async () => {
    try {
      setBannerloading(true);
      const response = await apiclient.wideBanners(service_id);
      setbanners(response.data['Banner details']);
      setBannerloading(false)
    } catch (error) {
      console.log(error);
    }
  };

  // call from editbusiness acount if upper level provider has  changed
  const fetchHomePageDataAll = () => {
    fetchProduct();
  }

  const fetchService = async () => {
    try {
      setLoading(true);
      const response = await api.allpopularsellerdata(
        service_id
      );
      console.log("from fetch service", response.data);
      setSellerData(response.data.popular_seller);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchServiceCategory = async () => {
    setLoading(true);
    // setStatusloading(true);
    const response = await PutatoeServiceApi.serviceCategory(
      parseInt(tempProduct[0])
    );
    // console.log(response);
    if (response.data.error !== "") {
      notify("error", response.data.error);
    } else {
      // notify('success','here are response')
      setCategorydata(response.data.product_type);
      setLoading(false);
      setStatusloading(false);
    }
  };

  const fetchsubservice = async () => {
    try {
      const response2 = await PutatoeServiceApi.PopularServiceData(service_id);
      console.log(response2.data.subcategory);
      setSubServicedata(response2.data.subcategory);
    }
    catch (error) {
      console.log(error);
    }
  }

  const fetchunreadNotification = async () => {
    const response = await apiclient.UnReadNotification();
    setNoficationCount(response.data.no_of_unread_notifications);
  };

  const fetchProduct = async () => {
    try {
      const tempProduct2 = [];
      // console.log(tempProduct4);
      // console.log(tempProduct3);
      setStatusloading(true);
      if (tempProduct3.indexOf(tempProduct) === -1) {
        //it is not that await is not making the progarm wait for api to return  its something in the context dont get it
        const response2 = await apiclient.popularproductByService(
          parseInt(tempProduct)
        );
        tempProduct2[0] = response2;
        // console.log(tempProduct2);
      }

      setProducts(tempProduct2);
      setStatusloading(false);
      setTempProduct3((prev) => tempProduct);
      setTimeout(() => {
        setStatusloading(false);
        setTempProduct5(tempProduct);
        setLoginModal(false);
      }, 2300);
      setStatusloading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchtabs = async () => {
    const response = await api.popularSubServicesApi();
    console.log("rendered tabs");
    setTabs(response.data.popularSubService);
  };

  const fetchAllService = async () => {
    try {
      const response = await apiclient.services();
      setAllService(response.data.services_list);
    } catch (error) {
      console.error('error getting all services ', error);
    }
  };

  function UserProfileStatus() {
    if (newObject) {
      setUserProfile(JSON.parse(newObject));
    }
  }

  React.useEffect(() => {
    setTimeout(() => {
      fetchunreadNotification();
    }, 2000);
    // fetchbanner();// fetch banners
  }, []); // previously loading is dependency bcz of that twice apis are calling

  React.useEffect(() => {
    const myTimeout = setTimeout(UserProfileStatus, 1000);
    console.log("called timeout for profile status");
  }, []);

  React.useEffect(() => {
    if (products[0]) {
      fetchsubservice();
    }
  }, [products, token])

  React.useEffect(() => {
    if (token) {
      fetchAllService();// this is for geting all sevices shown in the side icon 
    }
  }, [token]);

  React.useEffect(() => {
    console.log('temp product ', tempProduct);

  }, [tempProduct]);

  React.useEffect(() => {
    function handleResize() {
      setIsMobileView(window.innerWidth <= 767);
    }
    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  React.useEffect(() => {
    const isUserLocationCompleted = localStorage.getItem("isUserLocationCompleted") === "true";
    if (tempProduct && tempProduct.length > 0 && isUserLocationCompleted) {
      fetchbanner();
      fetchProduct();
      fetchServiceCategory();
      fetchService();
    }
  }, [
    tempProduct,
    localStorage.getItem("isUserLocationCompleted"),
    addwithoutLogin,
    loginDetail,
  ]);

  React.useEffect(() => {
    // console.log(service_id, 'service_id 1212ad');
    if (service_id) {

    }
  }, [service_id])

  return (
    <AppContext.Provider
      value={{
        service_id, setServiceId,
        token, setToken,
        service,
        products,
        tabs,
        allService,
        userName,
        setUserName,
        loginDetail,
        setLoginDetail,
        loginPage,
        setLoginPage,
        authtemp,
        setAuthtemp,
        mobileNumber,
        setMobileNumber,
        desiredProducts,
        setDesiredProducts,
        timer,
        setTimer,
        Userprofile,
        setUserProfile,
        allServiceSelector,
        setAllServiceSelector,
        tempProduct,
        setTempProduct,
        UndesiredProducts,
        setUndesiredProducts,
        loading,
        setLoading,
        paymentAmountMobile,
        setPaymentAmountMobile,
        changeInCart,
        setChangeInCart,
        likeUpdate,
        setLikeUpdate,
        generateBillArray,
        setGenerateBillArray,
        modifyModal,
        setModifyModal,
        showPdfModal,
        setShowPdfModal,
        isCouponApplied,
        setIsCoupounApplied,
        notificationCount,
        setNoficationCount,
        fetchunreadNotification,
        expand,
        setExpand,
        blogInfo,
        setBlogInfo,
        createBlogModal,
        setCreateBlogModal,
        postBlog,
        setPostBlog,
        blogUpdate,
        setBlogUpdate,
        editBlogData,
        setEditBlogData,
        display,
        setDisplay,
        loginModal,
        setLoginModal,
        productsUpdate,
        setProductsUpdate,
        chatScreen,
        setChatScreen,
        addwithoutLogin,
        setAddwithoutLogin,
        jobModalInfo,
        setJobModalInfo,
        isMobileView,
        setIsMobileView,
        page,
        setPage,
        innerPage,
        setInnerPage,
        Allsubserviceavialable,
        setAllsubserviceavialable,
        userServiceIDselected,
        setUserServiceIDselected,
        inputPrice,
        setInputprice,
        startIndex,
        setStartIndex,
        externalRedirect,
        setExternalRedirect,
        statusloading,
        setStatusloading,
        rechargepage, setRechargePage,
        isproviderTypepage,
        setIsproviderTypepage, bannerloading, setBannerloading,
        banner, setbanners,
        fetchHomePageDataAll, subServicedata, setSubServicedata, sellerData, setSellerData, categorydata, setCategorydata,
        isNumberChange, setIsNumberChange,
        sendOtp, setSendotp,
        otptoken,
        setOtptoken,
        // setLoginwithotp,
        // loginwithotp,
        refreshPage, setRefreshPage,
        isOtpForSellerRegisteration, setIsOtpForSellerRegisteration, myownblog, setMyownblog, admindetail, setAdmindetail, notification, setNotification, notificationref, logoutdrop, setlogoutdrop, cartdata, setCartdata, coouponid, setCoouponid
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
// make sure use
export const useGlobalContext = () => {
  return useContext(AppContext);
};

export { AppContext, AppProvider };
