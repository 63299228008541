import React from "react";

import scrollright from "../images/scrollright.svg";
import scrollleft from "../images/scrollleft.svg";
import "../css/popularProductsSubContainer.css";
import { useNavigate } from "react-router-dom";

import Categorycard from "./Categorycard";
import "../css/carousel.css";

export default function CategorySubContainer(props) {
  // console.log(props)
  //  console.log(props.popularproduct.length);
  const [isHovered, setIsHovered] = React.useState(false);
  const wrapperRef = React.useRef(null);
  const scrollLeft = (e) => {
    // console.log(e)
    // console.log(wrapperRef.current.offsetWidth)
    // console.log(props.products.length * 200)
    let x = wrapperRef.current.scrollLeft;
    let y = wrapperRef.current.scrollTop;
    // console.log(x)
    wrapperRef.current.scrollLeft += 800;
  };
  const scrollRight = (e) => {
    // console.log(e);
    // console.log(wrapperRef.current.offsetWidth);
    // console.log(props.products.length * 200);
    let x = wrapperRef.current.scrollLeft;
    let y = wrapperRef.current.scrollTop;
    wrapperRef.current.scrollLeft -= 800;
  };
  // console.log('rerendered subcontainerof products')

  const navigate = useNavigate();
  function move() {
    navigate(`/potatoeProduct/${props.id}/${props.service_name}`);
  }
  return (
    <React.Fragment>
      <div className="popular-product-container">
        <div className="popular-product-container-info-heading">
        <div className="popular-product-category">{props.category}</div>
        </div>


        <div
          className={`popular-products-per-category-head ${
            isHovered ? "hovered" : ""
          }`}
          style={{ marginBottom: "10px" }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <div className="carousel-scroll">
            {props.product_length > 6 && (
              <>
                <button className="carousel-scroll-left" onClick={scrollLeft}>
                  <img src={scrollleft}></img>
                </button>

                <button className="carousel-scroll-right" onClick={scrollRight}>
                  <img src={scrollright}></img>
                </button>
              </>
            )}
          </div>
          <div className="popular-products-per-category" ref={wrapperRef}>
            {props.products.map((product) => (
              <Categorycard
                key={product.product_type_id}
                id={product.product_type_id}
                product_type_name={product.product_type_name}
                product_type_discount={product.product_type_discount}
                product_type_image={product.product_type_image}
                category={product.category}
                service_id={product.service_id}
                sub_service_id={product.sub_service_id}
              ></Categorycard>
            ))}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
