import React, { useState } from "react";
import myProfileServiceApi from "../../api/myProfileServiceApi";
import { useGlobalContext } from "../../context";
import Loading from "../../components/Loading";
import "./css/AllMyProducts.css";
import back from "../myprofileIcoons/bx-arrow-back..svg";
import notify from "../../error";
import penciledit from "../../imageMobile/pencil-svgrepo-com.svg";
import { IoIosSend } from "react-icons/io";

const AllMyProducts = (props) => {
  const [inStock, setinStock] = React.useState(10);
  const renderOptions = () => {
    const options = [];
    for (let i = 1; i <= inStock; i++) {
      options.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    return options;
  };
  const { Userprofile, loading, setLoading } = useGlobalContext();
  const [MyProductsList, setMyProductsList] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [editId, setEditId] = React.useState({});
  const [QuantityMyProduct, setQuantityMyProduct] = React.useState();
  const [searchString, setSearchString] = useState("");
  const [filteredProductList, setFilteredProductList] = React.useState([]);
  const [formData, setFormData] = React.useState({
    id: "",
    amount: "",
    description: "",
    product: "",
  });
  function goBack() {
    props.return();
  }
  const handleQtyChange = (e) => {
    setQuantityMyProduct(e.target.value);
  };
  const getMyProducts = async () => {
    try {
      setLoading(true);
      const response = await myProfileServiceApi.ServiceProviderAllMyProduct(
        Userprofile.service_provider_id
      );
      if (response.data) {
        setLoading(false);
        const data = response.data;
        setMyProductsList(data.transaction_list);
      }
    } catch (error) {
      notify("error", "Error fetching my products");
    }
  };
  React.useEffect(() => {
    getMyProducts();
  }, []);
  function handleChange(event) {
    console.log(event);
    const { name, value, type, checked } = event.target;
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: type === "checkbox" ? checked : value,
      };
    });
    console.log(formData);
  }
  const EditMyProductService = async () => {
    const response = await myProfileServiceApi.EditMyProduct(
      formData.id,
      formData.amount,
      formData.description,
      formData.product
    );
    console.log(response);
    if (response.data && response.data.error !== "") {
      notify("error", response.data.error);
    } else {
      notify("success", "Successfully Edited The Service");
      getMyProducts();
      setPage(1);
    }
  };
  const DeleteParticluarSerivce = async (trans_id) => {
    const response = await myProfileServiceApi.DeleteMyProduct(trans_id);
    console.log(response);
    if (response.data && response.data.error !== "") {
      notify("error", response.data.error);
    } else {
      notify("success", "Successfully Deleted The Service");
      getMyProducts();
    }
  };

  React.useEffect(() => {
    setFormData((prev) => {
      return {
        id: editId.id,
        amount: editId.amount,
        description: editId.description,
        product: editId.tagline,
      };
    });
  }, [editId]);

  const handleGenerateBillArray = async (
    myproductId,
    myproductAmount,
    QuantityMyProduct
  ) => {
    try {
      // console.log(quantity);
      const res = await myProfileServiceApi.ServiceAddGenerateBillMyProduct(
        myproductId,
        myproductAmount,
        QuantityMyProduct
      );
      if (res.status === 200)
        notify("success", "Product added successfully...");
      console.log(res);
    } catch (err) {
      console.log(err);
    }
    // console.log(QuantityMyProduct);
  };

  const handleChangeseacrch = (e) => {
    const input = e.target.value;
    setSearchString(input);
  };

  React.useEffect(() => {
    let searchTimeout;

    const GetMyfilterproducts = async () => {
      try {
        setLoading(true);
        const response = await myProfileServiceApi.GetmyProductsfilter(
          searchString
        );
        // console.log(response);
        if (response.data) {
          setLoading(false);
          setFilteredProductList(response.data.transaction_list);
        }
        // console.log(response.data.transaction_list);
      } catch (error) {
        notify("error", "Error fetching my products");
      }
      return () => {
        if (searchTimeout) {
          clearTimeout(searchTimeout);
        }
      };
    };

    searchTimeout = setTimeout(() => {
      if (searchString) {
        GetMyfilterproducts();
      }
    }, 500);

    // Clear the timeout when the component unmounts
    return () => {
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }
    };
  }, [searchString]);

  
  return (
    <React.Fragment>
      {page === 1 && (
        <div className="all-services-profile-service-main-page">
          <div className="AllProductsSearchBox">
            <input
              type="text"
              placeholder="Enter your product here"
              value={searchString}
              // onChange={(e) => setSearchString(e.target.value)}
              onChange={handleChangeseacrch}
            />
            <IoIosSend className="sendIcon" />
          </div>
          <div className="all-services-profile-service-main-page-main-box-before">
            {MyProductsList && (
              <>
                Total <span>{MyProductsList.length}</span> Product(s)
              </>
            )}
          </div>
          <div className="all-myproducts-profile-serivce-main-page-main-box">
            {loading ? (
              <Loading />
            ) : searchString !== "" ? (
              filteredProductList.length !== 0 ? (
                filteredProductList.map((myproduct) => (
                  <>
                    <div className="all-myproducts-profile-serivce-main-page-main-box-card">
                      <div className="all-myproducts-profile-serivce-main-page-main-box-card-amount">
                        Amount : &nbsp; <span> &#8377; {myproduct.amount}</span>
                      </div>
                      <div className="all-myproducts-profile-serivce-main-page-main-box-card-name">
                        Name : &nbsp; <span>{myproduct.tagline}</span>
                      </div>
                      <div className="all-myproducts-profile-serivce-main-page-main-box-card-desc">
                        Description : &nbsp;
                        <span>{myproduct.description}</span>
                      </div>
                      <div className="all-myproducts-profile-serivce-main-page-main-box-card-qty">
                        <button
                          htmlFor="number-select"
                          style={{ marginRight: 5 }}
                        >
                          Qty :
                          <select id="number-select" onChange={handleQtyChange}>
                            {renderOptions()}
                          </select>
                        </button>
                      </div>
                      <div
                        className="all-myproducts-profile-serivce-main-page-main-box-card-icon"
                        style={{ marginTop: 15 }}
                      >
                        <img
                          src={penciledit}
                          onClick={() => {
                            setEditId(myproduct);
                            setPage(2);
                          }}
                          alt=""
                        />
                        <div className="all-services-profile-service-main-page-add-service">
                          <button
                            onClick={() =>
                              handleGenerateBillArray(
                                myproduct.id,
                                myproduct.amount,
                                QuantityMyProduct
                              )
                            }
                          >
                            ADD
                          </button>
                        </div>
                        <i
                          class="fa-solid fa-trash-can fa-xl"
                          onClick={() => DeleteParticluarSerivce(myproduct.id)}
                        ></i>
                      </div>
                    </div>
                  </>
                ))
              ) : (
                <div className="no-products-message">
                  No products found for the search query.
                </div>
              )
            ) : MyProductsList && MyProductsList.length !== 0 ? (
              MyProductsList.map((myproduct) => (
                <>
                  <div className="all-myproducts-profile-serivce-main-page-main-box-card">
                    <div className="all-myproducts-profile-serivce-main-page-main-box-card-amount">
                      Amount : &nbsp; <span> &#8377; {myproduct.amount}</span>
                    </div>
                    <div className="all-myproducts-profile-serivce-main-page-main-box-card-name">
                    Name : &nbsp; <span>{myproduct.tagline}</span>
                    </div>
                    <div className="all-myproducts-profile-serivce-main-page-main-box-card-desc">
                      Description : &nbsp;
                      <span>{myproduct.description}</span>
                    </div>
                    <div className="all-myproducts-profile-serivce-main-page-main-box-card-qty">
                      <button
                        htmlFor="number-select"
                        style={{ marginRight: 5 }}
                      >
                        Qty :
                        <select id="number-select" onChange={handleQtyChange}>
                          {renderOptions()}
                        </select>
                      </button>
                    </div>
                    <div
                      className="all-myproducts-profile-serivce-main-page-main-box-card-icon"
                      style={{ marginTop: 15 }}
                    >
                      <img
                        src={penciledit}
                        onClick={() => {
                          setEditId(myproduct);
                          setPage(2);
                        }}
                        alt=""
                      />
                      <div className="all-services-profile-service-main-page-add-service">
                        <button
                          onClick={() =>
                            // console.log(QuantityMyProduct)
                            handleGenerateBillArray(
                              myproduct.id,
                              myproduct.amount,
                              QuantityMyProduct
                            )
                          }
                        >
                          ADD
                        </button>
                      </div>
                      <i
                        class="fa-solid fa-trash-can fa-xl"
                        onClick={() => DeleteParticluarSerivce(myproduct.id)}
                      ></i>
                    </div>
                  </div>
                </>
              ))
            ) : (
              <div className="no-products-message">
                No products found for the search query.
              </div>
            )}
          </div>
        </div>
      )}
      {page === 2 && (
        <div className="all-services-profile-service-main-page">
          <div className="profile-wallet-main-page-heading issusesolev">
            <b className="my-profile-wallet-page-transfer-top-back">
              <img
                src={back}
                onClick={() => {
                  setPage(1);
                }}
              ></img>
            </b>
            <span>Edit My Product</span>
          </div>
          <div className="all-myproducts-profile-service-main-page-inputs">
            <div className="all-myproducts-profile-service-main-page-inputs-1">
              <span>Amount</span>
              <input
                type="number"
                name="amount"
                value={formData.amount}
                onChange={handleChange}
                placeholder={`Enter Amount`}
              ></input>
            </div>
            <div className="all-myproducts-profile-service-main-page-inputs-1">
              <span>Product Name</span>
              <input
                type="text"
                name="product"
                value={formData.product}
                onChange={handleChange}
                placeholder="Enter Product Name"
              ></input>
            </div>
            <div className="all-myproducts-profile-service-main-page-inputs-1">
              <span>Description</span>
              <input
                type="text"
                name="description"
                value={formData.description}
                onChange={handleChange}
                placeholder="Enter Description"
              ></input>
            </div>
          </div>
          <div className="all-myproducts-profile-service-main-page-inputs-submit">
            <button onClick={EditMyProductService}>Submit</button>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default AllMyProducts;

