import axios from 'axios';
//company
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

class PutatoeJobApi {
  DisplayAllJobs() {
    return axios.get(API_BASE_URL + '/v1/api/JobLists', {
      headers: {
        authtoken: localStorage.getItem('authtoken'),
      },
    });
  }
  ViewBankAccounts() {
    return axios.get(API_BASE_URL + '/v1/api/viewAcoounts', {
      headers: {
        authtoken: localStorage.getItem('authtoken'),
      },
    });
  }
  ApplyForJob(
    degree_graduation,
    degree_post_graduation,
    email,
    f_name,
    m_name,
    l_name,
    guardian_name,
    image,
    marriage_status,
    id_proof,
    id_proof_2,
    contact_no,
    address_id,
    secondary_percent,
    higher_secondary_percent,
    graduation_percent,
    post_graduation_percent,
    diploma_percent,
    dob,
    skills,
    interests,
    projects,
    field_of_interest,
    portfolio,
    experience,
    trainings,
    internships,
    additional_details,
    position_of_responsibility
  ) {
    return axios.post(
      API_BASE_URL + '/v1/api/apply_for_job',
      {
        degree_graduation,
        degree_post_graduation,
        email,
        f_name,
        m_name,
        l_name,
        guardian_name,
        image,
        marriage_status,
        id_proof,
        id_proof_2,
        contact_no,
        address_id,
        secondary_percent,
        higher_secondary_percent,
        graduation_percent,
        post_graduation_percent,
        diploma_percent,
        dob,
        skills,
        interests,
        projects,
        field_of_interest,
        portfolio,
        experience,
        trainings,
        internships,
        additional_details,
        position_of_responsibility,
        // position_of_responsibility,
      },
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }
  CheckJobApplication(){
    return axios.get(API_BASE_URL + '/v1/api/MyJobApplication',{
      headers: {
        authtoken: localStorage.getItem('authtoken'),
      },
    })
  }

  // ModifyJobApplication(email,
  //   f_name,
  //   m_name,
  //   l_name,
  //   guardian_name,
  //   id_proof,
  //   contact_no,
  //   address_id,
  //   secondary_percent,
  //   higher_secondary_percent,
  //   graduation_percent,
  //   post_graduation_percent,
  //   diploma_percent,
  //   dob,
  //   skills,
  //   interests,
  //   projects,
  //   field_of_interest,
  //   portfolio,
  //   experience,
  //   trainings,
  //   internships,
  //   additional_details,
  //   position_of_responsibility,
  //   id
  //   ){
  //     return axios.post(
  //       API_BASE_URL + '/v1/api/ModifyJobApplication',
  //       {
  //         email,
  //         f_name,
  //         m_name,
  //         l_name,
  //         guardian_name,
  //         id_proof,
  //         contact_no,
  //         address_id,
  //         secondary_percent,
  //         higher_secondary_percent,
  //         graduation_percent,
  //         post_graduation_percent,
  //         diploma_percent,
  //         dob,
  //         skills,
  //         interests,
  //         projects,
  //         field_of_interest,
  //         portfolio,
  //         experience,
  //         trainings,
  //         internships,
  //         additional_details,
  //         position_of_responsibility,
  //         position_of_responsibility,
  //         job_id:id,
  //       },
  //       {
  //         headers: {
  //           authtoken: localStorage.getItem('authtoken'),
  //         },
  //       }
  //     );
  //     // console.log(id);
  //   }

  ModifyJobApplication = (
    degree_graduation,
    degree_post_graduation,
    email,
    f_name,
    m_name,
    l_name,
    guardian_name,
    image,
    marriage_status,
    id_proof,
    id_proof_2,
    contact_no,
    address_id,
    secondary_percent,
    higher_secondary_percent,
    graduation_percent,
    post_graduation_percent,
    diploma_percent,
    dob,
    skills,
    interests,
    projects,
    field_of_interest,
    portfolio,
    experience,
    trainings,
    internships,
    additional_details,
    position_of_responsibility,
    id
  ) => {
    const payload = {
      degree_graduation,
      degree_post_graduation,
      email,
      f_name,
      m_name,
      l_name,
      guardian_name,
      image,
      marriage_status,
      id_proof,
      id_proof_2,
      contact_no,
      address_id,
      secondary_percent,
      higher_secondary_percent,
      graduation_percent,
      post_graduation_percent,
      diploma_percent,
      dob,
      skills,
      interests,
      projects,
      field_of_interest,
      portfolio,
      experience,
      trainings,
      internships,
      additional_details,
      position_of_responsibility,
      job_id: id,
    };

    // Remove empty or undefined fields from the payload
    Object.keys(payload).forEach((key) => {
      if (payload[key] === '' || payload[key] === undefined || payload[key] === null ) {
        delete payload[key];
      }
    });
    console.log(payload);
    return axios.post(API_BASE_URL + '/v1/api/ModifyJobApplication', payload, {
      headers: {
        authtoken: localStorage.getItem('authtoken'),
      },
    });
  };
  

  AddBankAccount(acc_no, acc_type, acc_holder_name, IFSC) {
    return axios.post(
      API_BASE_URL + '/v1/api/addAccount',
      { acc_no, acc_type, acc_holder_name, IFSC },
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }
  ModifyBankAccount(id, acc_number, acc_type, acc_holder_name, ifsc) {
    return axios.post(
      API_BASE_URL + '/v1/api/modifyBankAccount',
      { id, acc_number, acc_type, acc_holder_name, ifsc },
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }

  // GetAllCircle() {
  //   return axios.get(API_BASE_URL + '/v1/api/getCircles', {
  //     headers: {
  //       authtoken: localStorage.getItem('authtoken'),
  //     },
  //   });
  // }

  ApplyJobPosition(JobId) {
    return axios.get(
      API_BASE_URL + `/v1/api/apply_for_post/${JobId}`,
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }

  ApplyLoan(
    name,
    mobile,
    email,
    city,
    is_salaried,
    annual_income,
    loan_type,
    is_user,
    loan_amount
  ) {
    return axios.post(
      API_BASE_URL + '/v1/api/applyloan',
      {
        name,
        mobile,
        email,
        city,
        is_salaried,
        annual_income,
        loan_type,
        is_user,
        loan_amount,
      },
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }

  GetAuth() {
    return axios.get('https://www.universal-tutorial.com/api/getaccesstoken/', {
      headers: {
        // authtoken: localStorage.getItem('authtoken'),
        Accept: 'application/json',
        'api-token':
          'e_A4xj9C60mrjRNVkl22g2HqrAcnZ7DgKDw_BM7yt5K2Ox-eNRc6buF6LaVRNDbTjSk',
        'user-email': 'viceke7523@pantabi.com',
      },
    });
  }
}

export default new PutatoeJobApi();
