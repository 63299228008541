import React from 'react';
// import './css/profileSection.css';
import homepic from '../myprofile/myprofileIcoons/homeimage.svg';
// import AddServiceProvider from './components/addServiceProvider';
// import AccountSettings from './components/accountsettings';
// import MyBillsTwo from './components/MyBills/MyBillsTwo';
// import MyWalletMainzpage from './components/MyWallet/MyWalletMainPage';
import PrePaidMobile from './PrepaidMobile';
import DthRechargePage from './DthRechargePage';
import ElectrityPage from './ElectricityPage';
import WaterPage from './WAterPage';
import LoanRepaymentPage from './LoanRepayment';
import DataCardPage from './DataCardPage';
import PostPaidMobile from './PostPaidMobilePage';
import GasCylinderPage from './GasCylinderPage';

import LandlinePage from './LandlinePage';
import EducationFees from "./EducationPage"
import BroadBand from './BroadBand';
import Fastag from "./Fastag";
export default function DTHServiceSection(props) {
  console.log(props, 'here', props.pageNo === '6');
  console.log(props.pageNo === '6');

  if (props.pageNo === 0) {
    return (
      <React.Fragment>
        <div className='profile-section-container dthimageprofilecontainer'>
          <div className='profile-section-container-0-image dthimageprofilesection'>
            <img src={homepic}></img>
          </div>
        </div>
      </React.Fragment>
    );
  } else if (props.pageNo === '1') {
    return (
      <React.Fragment>
        <div className='profile-section-container2'><PostPaidMobile  pageNo={props.pageNo}/></div>
      </React.Fragment>
    );
  } else if (props.pageNo === '2') {
    return (
      <React.Fragment>
        <div className='profile-section-container2'><DthRechargePage pageNo={props.pageNo}></DthRechargePage></div>
      </React.Fragment>
    );
  } else if (props.pageNo === '3') {
    return (
      <React.Fragment>
        <div className='profile-section-container2'><PrePaidMobile pageNo={props.pageNo}/></div>
      </React.Fragment>
    );
  } else if (props.pageNo === '4') {
    return (
      <React.Fragment>
        <div className='profile-section-container2'><Fastag pageNo={props.pageNo}/></div>
      </React.Fragment>
    );
  } else if (props.pageNo === '5') {
    return (
      <React.Fragment>
        <div className='profile-section-container2'><DataCardPage pageNo={props.pageNo}/></div>
      </React.Fragment>
    );
  } else if (props.pageNo === '6') {
    return (
      <React.Fragment>
        <div className='profile-section-container2'>
          {/* <AddServiceProvider /> */}
          <BroadBand pageNo={props.pageNo}/>
        </div>
      </React.Fragment>
    );
  } else if (props.pageNo === '7') {
    return (
      <React.Fragment>
        <div className='profile-section-container2'><ElectrityPage pageNo={props.pageNo}></ElectrityPage></div>
      </React.Fragment>
    );
  } else if (props.pageNo === '8') {
    return (
      <React.Fragment>
        <div className='profile-section-container2'><GasCylinderPage pageNo={props.pageNo} /></div>
      </React.Fragment>
    );
  } else if (props.pageNo === '9') {
    return (
      <React.Fragment>
        <div className='profile-section-container2'>
          {' '}
          <WaterPage pageNo={props.pageNo}></WaterPage>
        </div>
      </React.Fragment>
    );
  } else if (props.pageNo === '100') {
    return (
      <React.Fragment>
        <div className='profile-section-container2'>
          {/* <AccountSettings /> */}
        </div>
      </React.Fragment>
    );
  } else if (props.pageNo === '11') {
    return (
      <React.Fragment>
        <div className='profile-section-container2'>
          <LandlinePage pageNo={props.pageNo}/>
        </div>
      </React.Fragment>
    );
  } else if (props.pageNo === '120') {
    return (
      <React.Fragment>
        <div className='profile-section-container2'>
          {/* <LoanRepaymentPage></LoanRepaymentPage> */}

          
        </div>
      </React.Fragment>
    );
  } else if (props.pageNo === '13') {
    return (
      <React.Fragment>
        <div className='profile-section-container2'>
          <LoanRepaymentPage pageNo={props.pageNo}></LoanRepaymentPage>
        </div>
      </React.Fragment>
    );
  }
  else {
    return (
      <React.Fragment>
        {/* no {props.pageNo} {props.pageNo === '6'} */}
        <div className="no-service-started">
          <div className="no-service-empty">
          <p>service is about to start soon</p> 
          </div>

        </div>
       
      </React.Fragment>
    );
  }
}
