import React, { Component } from 'react'
import logo from '../images/logo.png'
import './aboutus.css';
import Footer from '../components/footer';
export default function AboutUs()
{
 return (
   <React.Fragment>
     <>
       <div className='about-us-page-cont'>
         <div className='about-us-page-heading'>About US</div>

         <div className='about-us-page-intro'>
           <div className='about-us-page-intro-head'>
             <div>
               <img src={logo}></img>
             </div>
             <span>INTRODUCTION</span>
           </div>
           <div className='about-us-page-intro-text'>
             I am sure that most of us have faced the problem where we have to
             visit different sites for different purposes. If we want to buy
             clothes we have to visit a different site from the one if we want
             to buy groceries. The same thing happens when we go for booking
             tickets for traveling or is searching for an interior designer for
             our house. Sometimes it gets really difficult to find the correct
             site in accordance with our need at that span of time.
           </div>
         </div>

         <div className='about-us-page-work'>
           <div className='about-us-page-work-text'>
             We will surely find many different sites to provide us with the
             different things that are necessary for our day-to-day life and
             even for our luxury. But, don’t you think it will be a lot easier
             if we found that entire requirement of us in one single site rather
             than surfing through lots and lots of different ones? Don’t you
             think it will be better if you got your things of need and luxury
             items on a single site rather than useless site visiting and
             time-wasting?
           </div>
           <div className='about-us-page-work-head'>
             <div>
               <img src={logo}></img>
             </div>
             <span>WORK OF PUTATOE</span>
           </div>
         </div>
         <div className='about-us-page-work-secondary-text'> 
           <div>
             This is where we come in. Putatoe is a private company that
             promises to give you that entire facility in just one click. On
             this site, you will find all that is necessary to you in a single
             platform and with various offers and prices that are affordable by
             all. All you need to do is visit the site and log in if you like
             our services and products. Now, you might be thinking that there
             are many sites that claim to do the same thing, if so then let me
             tell you one important fact; we are a lot different from all those
             sites. Let’s discuss why.
           </div>
           <div>
             The services and the products that you ask for will be given to you
             from shops near your house; as a result, if you want you can have
             them within the span of 24 hours. This is very different from the
             other sites that are present that have their own centers located at
             various places and then your product takes days to reach you. Along
             with this, you can also set the time at which you want to get your
             product. So, if you are not present in the house you can set the
             time for when you will be and thus receiving it at your convenient
             time.
           </div>
           <div>
             On our site, you will not only find products but also services like
             electricians, plumbers, interior designers, etc. For example, if
             you are going for house maintenance and house repairing you will
             not only get the supplies like wire or rods but also plumbers and
             electricians.
           </div>
         </div>

         <div className='about-us-page-vision'>
           <div className='about-us-page-vision-head'>
             <div>
               <img src={logo}></img>
             </div>
             <span>VISION</span>
           </div>
           <div className='about-us-page-intro-text'>
             To become one stop solution for every requirement with all
             facilities in just one click.
           </div>
         </div>

         <div className='about-us-page-mission'>
           <div className='about-us-page-mission-text'>
             To provide a platform where consumer can reach to service providers
             at his convenience for best quality, price and deals.
           </div>
           <div className='about-us-page-mission-head'>
             <div>
               <img src={logo}></img>
             </div>
             <span>MISSION</span>
           </div>
         </div>

         <div className='about-us-offer-intro'>
           <div className='about-us-page-offer-head'>
             <div>
               <img src={logo}></img>
             </div>
             <span>THE SPECIAL OFFERS</span>
           </div>
           <div className='about-us-page-offer-text'>
             You will get first visiting charge-free on any service or products
             that you order. Secondly you will get a 10% discount on your order
             terms and conditions applied.
           </div>
         </div>

         <div className='about-us-page-conclusion'>
           <div className='about-us-page-conclusion-text'>
             As you read this through you can realize the reason why our website
             is better than other sites that are available in the market.
             Firstly you will get all your requirements on one single website,
             which is a lot more convenient rather than visiting from one
             website to another to meet all your needs. Moreover, there are a
             variety of options that are available and from which you can choose
             in accordance with your requirement. To avail of our service, all
             you need to do is log in to our website. To get more updates and
             information follow us on our Instagram account. You can also reach
             us through Facebook.
           </div>
           <div className='about-us-page-conclusion-head'>
             <div>
               <img src={logo}></img>
             </div>
             <span>CONCLUSION</span>
           </div>
         </div>
       </div>

     </>
   </React.Fragment>
 );
}