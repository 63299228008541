import React, { useRef } from "react";
// import api from '../api/api'
import logo from "../images/logo.png";
import chatIcon from "../images/comment-multiple-svgrepo-com.svg";
import bell from "../images/bell-svgrepo-com.svg";
import user from "../images/blogimages/user.png";
import cart from "../images/cart-svgrepo-com.svg";
import searchIcon from "../images/search-svgrepo-com.svg";
import LoginSidebar from "./login-sidebar";
import apiclient from "../api/apiclient";
import "../css/header.css";
import { useGlobalContext } from "../context";
import LoginSidebarForgotPassword from "./login-sidebar-forgotpass";
import location from "../images/location-2-svgrepo-com.svg";
import api from "../api/api";
import { Link, useNavigate } from "react-router-dom";
import notify from "../error";
import Notification from "./notification";
import presignup from "../aes";
import CartMOdalHeader from "./CartModalHeader";
import { FaPaperPlane, FaMicrophone, FaUserCircle } from "react-icons/fa";
import cartapi from "../api/cartapi";
import LocationSidebar from "./location-sidebar";
import { HiOutlineSearch } from "react-icons/hi";
import { useState, useEffect } from "react";
export default function Header() {
  const {
    token,
    loginDetail,
    setLoginDetail,
    loginPage,
    setLoginPage,
    Userprofile,
    setUserProfile,
    notificationCount,
    setNoficationCount,
    display,
    setDisplay,
    fetchunreadNotification,
    addwithoutLogin,
    setAddwithoutLogin, page,
    setPage, isproviderTypepage, setIsproviderTypepage, notification, setNotification, notificationref

  } = useGlobalContext();
  const { loading, setLoading, setExpand } = useGlobalContext();
  const { changeInCart, setChangeInCart } = useGlobalContext();
  // const [notification, setNotification] = React.useState();
  const [notificationData, setNotificationData] = React.useState();
  const [searchValue, setSearchvalue] = React.useState("");
  const [searchPrediction, setSearchPrediction] = React.useState([]);
  const [displaySearch, setDisplaySearch] = React.useState(false);
  const [showSidebar, setShowSidebar] = React.useState(false);
  const wrapperRef = React.useRef(null);
  const searchModalRef = useRef(null);

  useEffect(() => {
    // document.addEventListener('click',);
  }, [])
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        (searchModalRef.current &&
          !searchModalRef.current.contains(event.target))
      ) {
        if (!(Array.from(event.target.classList).includes("predictionDiv"))) {
          setDisplaySearch(false);
        }
      }
    }

    if (displaySearch) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [displaySearch]);

  const [logoutdrop, setlogoutdrop] = React.useState(
    "login-details-header-dropdown-none"
  );

  const [display2, setDisplay2] = React.useState(
    "location-sidebar-container-none"
  );

  useOutsideAlerter(wrapperRef);

  const LogoutDropDown = () => {
    if (logoutdrop === "login-details-header-dropdown") {
      setlogoutdrop("login-details-header-dropdown-none");
    } else {
      setlogoutdrop("login-details-header-dropdown");
    }
  };

  const navigate = useNavigate();
  function ProfileNavigate() {
    navigate("/myprofile");
  }
  function ChatNavigation() {
    navigate("/chat/friend");
  }
  function NavigateToHomePage() {
    navigate("/");
  }

  const logOutOption = async () => {
    const response = api.LogOut();
    console.log(response);
    if ((await response).data.status === true) {
      navigate("/");
      setLoginDetail();
      setUserProfile();
      setPage(0);
      localStorage.removeItem("UserProfile");
      localStorage.removeItem("isUserLocationCompleted")
      localStorage.removeItem("authtoken");
      UserLocation();

      // presignup();
      setTimeout(function () {
        // window.location.reload(false);
        fetchunreadNotification();
        GetMyCartDertail();
      }, 2000);

      notify("success", "logout successful");
    } else {
      notify("fail", "logout unsuccessful");
    }
  };

  const UserLocation = async () => {
    const {
      address_line1,
      address_line2,
      landmark,
      town,
      state,
      latitude,
      longitude,
      district,
      country,
      pincode,
    } = Userprofile.default_address;
    setLoading(true);
    const response = await api.UserLocationApiaddresschange(
      address_line1,
      address_line2,
      landmark,
      town,
      state,
      latitude,
      longitude,
      district,
      country,
      pincode
    );
    console.log(response);
    if ("token" in response.data && response.data["token"]) {
      console.log("yes authtoken set");
      localStorage.setItem("authtoken", response.data["token"]);
      // let keyus=[data[keys]]
      console.log(response.data["token"], "token");
      localStorage.setItem("userkey", JSON.stringify(response.data["keys"]));
      console.log("presignup called");
      presignup();
      console.log(loading, "afterpresignup");
      setLoading(false);
      setTimeout(() => {
        localStorage.setItem("isUserLocationCompleted", true);
        // setLoading(false);
      }, 900); // set flag that userlocation has completed
    }
  };

  React.useEffect(() => {
    setSearchvalue("");
    // console.log(typeof notificationData);
  }, []);
  const onLogin = () => {
    setDisplay("login-sidebar-container2");
    setLoginPage(1);
    // console.log(display);
  };
  const showsidebar = () => {
    setDisplay2("location-sidebar-container");
  };
  const handleChange = () => {
    console.log("fired");
    setDisplay("login-sidebar-container-none");
  };
  const handleChange2 = () => {
    console.log("fired2");
    setDisplay2("location-sidebar");
  };
  function displayNotification() {
    console.log(notification);
    setNotification(!notification);
  }

  function useOutsideAlerter(ref) {
    React.useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target) && notificationref && notificationref.current && !notificationref.current?.contains(event.target)) {
          //alert('You clicked outside of me!');
          setNotification(false);
          // setDisplaySearch(false);
          setlogoutdrop("login-details-header-dropdown-none");
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const [cartDetail, setCartDetail] = React.useState();

  const GetMyCartDertail = async () => {
    const response = await cartapi.ShowCart();
    console.log(response);
    setCartDetail(response.data);
  };
  React.useEffect(() => {
    const isUserLocationCompleted =
      localStorage.getItem("isUserLocationCompleted") === "true";

    if (isUserLocationCompleted, token) {
      GetMyCartDertail();
    }
  }, [changeInCart, localStorage.getItem("isUserLocationCompleted")]);

  const getPrediction = async () => {
    // setSearchvalue(keywords);
    setDisplaySearch(true);
    if (searchValue === "") {
      setSearchPrediction([]);
      setDisplaySearch(false);
      return;
    }
    if (searchValue.length >= 2) {
      try {
        const searchResponse = await apiclient.SearchPridictionApi(searchValue);

        searchResponse.data.p_name.length === 0
          ? setSearchPrediction([[searchValue], ["No matching data"]]) : setSearchPrediction([[searchValue], ...searchResponse.data.p_name]);
        console.log(searchResponse.data.p_name, [searchValue]);
      } catch (error) {
        notify("error", error);
      }
    }
  };

  useEffect(() => {
    let timeout;
    timeout = setTimeout(() => {
      getPrediction();
    }, 700);
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [searchValue])

  const enterKeyHandler = (e, manualClick) => {
    if (e.key === "Enter" || manualClick) {
      document.activeElement.blur();
      navigate("/search", {
        state: { string: manualClick ? searchValue : e.target.value },
      });
      setDisplaySearch(false);
    }
  };

  const handleItemClick = (item) => {
    console.log(item);
    setSearchvalue(item[0]);
    enterKeyHandler("", true);
  };

  const sendIconClick = () => {
    enterKeyHandler("", true);
  };

  const locationState = window.location.pathname;
  useEffect(() => {
    if (locationState === "/") {
      setSearchvalue("");
      setDisplaySearch(false);
    }
  }, [locationState]);

  return (
    <React.Fragment>
      <div className="navbar">
        {/* logo */}
        <div className="putatoe-logo">
          <img className="putatoe-logo-image" src={logo} alt="logo" />
          <section
            className="putatoe-logo-text"
            onClick={() => {
              NavigateToHomePage();
              setExpand(true);
            }}
          >
            <h1 className="putatoe-logo-text-upper">PUTATOE</h1>
            <span className="putatoe-logo-text-lower">One Solution</span>
          </section>
        </div>
        <div className="putatoe-location">
          <div className="putatoe-user-location" onClick={showsidebar}>
            <span className="user-address">
              {Userprofile && Userprofile.default_address ? (
                <>
                  {Userprofile.default_address.address_line1}{" "}
                  {Userprofile.default_address.town},{" "}
                  {Userprofile.default_address.state}
                </>
              ) : localStorage.getItem("defaultAddressWithoutLogin") ? (
                localStorage.getItem("defaultAddressWithoutLogin")
              ) : (
                addwithoutLogin
              )}
            </span>
            <span>
              <i className="fa fa-chevron-down"></i>
            </span>
          </div>
        </div>
        {/* search bar */}

        <div className={`nav-search-bar-desktop ${isproviderTypepage ? 'navbar-visibility-hidden' : ''}`} ref={wrapperRef} >
          <div className="search-icon-desktop">
            <HiOutlineSearch
              className="search-icon-image"
              onClick={getPrediction}
            //  onKeyUp={getPrediction}
            />
          </div>
          <input
            type="text"
            name="searchValue"
            className="search-bar-desktop"
            placeholder="Search For Products and Brands"
            value={searchValue}
            onChange={(e) => {
              setSearchvalue(e.target.value)
              let [, ...newarray] = searchPrediction;
              setSearchPrediction([[e.target.value], ...newarray]);
              // getPrediction(e.target.value);
            }}
            onKeyDown={enterKeyHandler}
            autoComplete="off"
          />
          {displaySearch && (
            <div className="search-modal" ref={searchModalRef}>
              {searchPrediction &&
                searchPrediction.map((item, index) => {
                  console.log(item);
                  return (
                    <div
                      className="predictionDiv"
                      key={index}
                      onClick={(e) => {
                        // console.log(item.length > 1,item!="No Matching Data");
                        if (item[0].length > 1 && item[0] != "No Matching Data") {
                          handleItemClick(item)
                        }
                      }}
                    >
                      {item[0]}
                    </div>
                  );
                })}
            </div>
          )}
        </div>
        {/* voice icon */}

        {/* chat icon */}
        <div className="chaticon-container">
          <span>0</span>
          <img
            className="chat-icons"
            src={chatIcon}
            alt="chat_icon"
            onClick={ChatNavigation}
          ></img>
        </div>
        {/* bell icon */}
        <div className="notification-header">
          <span>{notificationCount}</span>
          <img
            className="bell-icon"
            src={bell}
            alt="bell_icon"
            onClick={displayNotification}
          ></img>
          <div className="notification-header-modal">
            {!notification ? (
              <div className="notification-none"></div>
            ) : (
              <div className="notification-show rounded">
                <Notification />
              </div>
            )}
          </div>
        </div>
        {/* cart icon  */}
        <div className="cart-header-main-header-image">
          <Link className="cart-image" to="/cart">
            <div>{cartDetail ? cartDetail.items_list.length : 0}</div>
            <img className="cart-icon" src={cart} alt="cart_icon"></img>
          </Link>
          <div className="cart-header-main-header-image-show-on-hover">
            {cartDetail && cartDetail.items_list.length > 0 && (
              <CartMOdalHeader data={cartDetail}></CartMOdalHeader>
            )}
          </div>
        </div>
        {Userprofile ? (
          <>
            <div className="login-details-header" onClick={LogoutDropDown}>
              <div className="login-details-header-image">
                {/* {console.log(Userprofile.profile_image)} */}
                {!Userprofile.profile_image ? (
                  <img src={user} className="no-image-for-now" alt="" />
                ) : (
                  <img
                    className="google-image-for-now"
                    src={Userprofile.profile_image}
                    alt=""
                  />
                )}
              </div>
              <div className="login-details-header-details">
                <div className="login-details-header-details-name">
                  {Userprofile.first_name} {Userprofile.last_name}
                </div>
                <div className="login-details-header-details-location">
                  <img src={location} alt="locationIcon" />
                  {Userprofile && Userprofile?.default_address.address_line1 ? (
                    <span>
                      {Userprofile &&
                        Userprofile?.default_address.address_line1}
                    </span>
                  ) : (
                    <span>Unknown</span>
                  )}
                </div>
              </div>
              <div ref={wrapperRef} className={logoutdrop}>
                <span>
                  <div onClick={ProfileNavigate}>Profile</div>
                  <hr />
                  <div onClick={() => logOutOption()}>Log Out</div>
                </span>
              </div>
            </div>
          </>
        ) : (
          <div className="login-button" onClick={onLogin}>
            <span>Log In</span>
            <div>
              {" "}
              <FaUserCircle className="no-user-icon" />
            </div>
          </div>
        )}
        {/* <div
          className="navbar-hamburger-menu"
          onClick={() => setShowSidebar((prevValue) => !prevValue)}
        >
          <CgMenu />
        </div> */}
        <div className="login-sidebar">
          <LoginSidebar view={display} onChange={handleChange} />
          <LoginSidebarForgotPassword view={display} />
        </div>
        <div className={display2}>
          <LocationSidebar
            view={display2}
            onChange={handleChange2}
            setDisplay2={setDisplay2}
          />
        </div>
      </div>

      {/* to be displayed when screen size very short */}
      <div className="navbar-short">
        <div className="nav-search-bar-desktop-mobile">
          <div className="search-icon-desktop">
            <HiOutlineSearch
              className="search-icon-image"
              onClick={getPrediction}
            //  onKeyUp={getPrediction}
            />
          </div>
          <input
            type="text"
            name="searchValue"
            className="search-bar-desktop"
            placeholder="Search For Products and Brands"
            value={searchValue}
            onChange={(e) => {
              setSearchvalue(e.target.value);
              let [, ...newarray] = searchPrediction;
              setSearchPrediction([[e.target.value], ...newarray]);
              // getPrediction(e.target.value);
              // setSearchPrediction([e.target.value,...searchPrediction]);
            }}
            onKeyDown={enterKeyHandler}
            autoComplete="off"
          />

          <FaPaperPlane className="sendIcon" onClick={sendIconClick} />
          {displaySearch && (
            <div className="search-modal" ref={searchModalRef}>
              {searchPrediction &&
                searchPrediction.map((item, index) => {
                  console.log(item)
                  return (
                    <div
                      className="predictionDiv"
                      key={index}
                      onClick={(e) => {
                        if (item[0].length > 1 && item[0] != "No Matching Data") {
                          handleItemClick(item)
                        }
                      }}
                    >
                      {item[0]}
                    </div>
                  );
                })}
            </div>
          )}
        </div>

        <div className="navbarshort-chat-icon">
          <img
            className="chat-icons"
            src={chatIcon}
            alt="chat_icon"
            onClick={ChatNavigation}
          ></img>
        </div>
      </div>
    </React.Fragment>
  );
}
