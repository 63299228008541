import React, { useRef, useState } from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import generatePDF from 'react-to-pdf';
import emaillogo from '../../images/pdfviewerimages/2.png';
import phonelogo from '../../images/pdfviewerimages/1.png';
import nextlogo from '../../images/pdfviewerimages/next.png';
import Putatoe from '../../images/logo.png';
const styles = StyleSheet.create({
    page: {
        display: 'flex',
        flexDirection: 'column',
        width: "900px",
        minHeight: '100vh',
        margin: 20,

    },
    tableadd: {
        display: 'flex',
    },
    logo: {
        width: 20,
        height: 20,
        marginRight: 3
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1, marginTop: 'auto',

    },
    table: {
        display: 'flex',
        width: '100%',
        marginTop: 20,
        padding: '5px',
        borderRadius: '5px', // Add rounded corners to the table
        overflow: "hidden",
        flexDirection: "column",
        gap: 20,
    },
    tableRow: {

        display: 'flex',
        flexDirection: 'column',
        // margin: 'auto',
        backgroundColor: '#fff',
    },
    header: {
        display: "flex",
        flexDirection: "column"
    },
    headingText1: {
        fontSize: "15px",
        color: "#00838f",
        fontWeight: "800",
        display: "flex",
        alignItems: "center"
    },
    headingText: {
        fontSize: "35px",
        color: "#00838f",
        fontWeight: "900",
        fontStyle: "italic",
        marginTop: "-10px",
        textTransform: "uppercase"
    }, verticalBar: {
        fontSize: 16,
        fontStyle: "normal",
        color: '#00838f',

        borderLeftStyle: "solid",
        borderLeftWidth: 3,
        height: "40px"
    },
    contactRow: {
        width: "100",
        display: "flex",
        flexDirection: 'column',
        alignItems: 'start',
        marginBottom: 5,

    },
    contactText: {
        fontSize: 14,
        display: "flex",
        alignItems: "center"
    },
    bar: {
        height: '60px', backgroundColor: '#1BA1B9',
        display: "flex", marginLeft: "-22px"
    },
    barcontainer: {
        display: "flex",
        gap: "10px",
        alignItems: "center", marginTop: 40
    },
    hr: {
        width: "30px",
        marginTop: "-7px",
        borderStyle: "solid",
        padding: "0 10px",
        borderRadius: "10%",
        borderWidth: 3,
        borderColor: '#00838f',

    },
    contactInfo: {
        display: "flex",
        flexDirection: "column",
        fontStyle: "italic",
        color: "#00838f", fontWeight: "500"
    },
    catlogHeader: {
        display: "flex",
        justifyContent: "space-between",
        width: '100%'
    },
    contactLabel: {

        fontSize: '15px',

        fontWeight: '800',
        textTransform: "uppercase",
        color: '#333',
        textAlign: "left"
    },
    contactContainer: {
        flexDirection: "row", width: "100%", justifyContent: "space-between", marginTop: "30px",
    },
    tablebg: {
        margin: "10px 2px",
        backgroundColor: "#D3D3D3",
        borderRadius: "3px"

    }, tablebgl: {
        margin: "10px 2px",
        backgroundColor: "#E5E4E2",
        borderRadius: "3px"

    },
    tableCell: {
        display: 'flex',
        width: '100%',
        padding: 10,
        textAlign: "center",

        fontWeight: "500",



    },
    dflex: {
        display: "flex"
    },
    tableimagecontainer: {
        display: "flex",
        flexDirection: "column",
    },
    tableColHeader: {
        textTransform: "uppercase",
        fontSize: "15px", color: "#fff",
        backgroundColor: '#1BA1B9',
    },

});


const CatlogPdfViewer = (props) => {
    const targetRef = useRef();
    const countertable = useRef();
    const { data, onCancel, spname } = props;
    const { first_name, last_name, email, phone } = spname;

    const updatecountertable = () => {
        countertable.current = countertable.current + 1;
    }
    const assigncountertable = () => {
        countertable.current = 0;
    }
    return (
        <>
            <div className='invoice-pdf-container2'>
                <div className="invoice-pdf-content">
                    <button className="close-button" onClick={onCancel}>X</button>
                    <Document ref={targetRef}>
                        <View style={styles.barcontainer} >
                            <View style={{ ...styles.bar, width: "60px" }}></View>
                            <img
                                style={{ width: 50, height: 50 }}
                                src={Putatoe}
                            />
                            <View style={styles.verticalBar
                            }></View>
                            <Text style={{ color: "#00838f", fontSize: 40, marginRight: "-5px", fontWeight: "500" }}>Putatoe</Text>
                            <View style={{ ...styles.bar, width: "calc(90% - 73px)", marginRight: "-52px", marginLeft: "10px" }}></View>
                        </View>
                        <Page size="A4" style={styles.page} >

                            <View style={styles.section}>


                                <View style={styles.catlogHeader}>
                                    <View style={styles.header}>
                                        <Text style={styles.headingText1}>{data.servie_name}<img
                                            style={styles.logo}
                                            src={nextlogo}
                                        /> <Text style={{ fontSize: 12 }}>{data.product_type_name}</Text></Text>
                                        <Text style={styles.headingText}>{first_name + " " + last_name}</Text>
                                        <hr style={styles.hr} />
                                    </View>
                                    <View style={styles.contactInfo}>
                                        <Text style={styles.contactLabel}>Contact Info:</Text>
                                        <Text style={styles.contactText}>  <img
                                            style={styles.logo}
                                            src={phonelogo}
                                        />{phone}</Text>
                                        <Text style={styles.contactText}>   <img
                                            style={styles.logo}
                                            src={emaillogo}
                                        />{email}</Text>
                                    </View>
                                </View>
                                <View style={styles.productContainer}>
                                    {/* Product ID and Image Table */}
                                    <View style={styles.table}>
                                        {/* Table Data */}
                                        {data.products_list && data.products_list.length > 0 && data.products_list.map((product) => (
                                            <View key={product.product_type_id} >
                                                {/* Table Header for each product */}
                                                <View style={{ ...styles.tableColHeader, display: "flex", justifyContent: "center", alignItems: 'center', borderBottom: "1px solid white", padding: 5 }}>
                                                    <Text style={{ fontWeight: "450", fontSize: 16, fontFamily: 'Poppins' }}>{product.product_name}</Text>

                                                </View>
                                                <View style={styles.tableadd}>
                                                    <View style={{ ...styles.tableRow, ...styles.tableheading, justifyContent: 'space-between', width: "19%" }}>
                                                        <View style={{ ...styles.tableColHeader, ...styles.tableCell }}>
                                                            <Text style={{ fontWeight: "400", fontFamily: 'Poppins', width: "100%" }}>Id:{product.product_id}</Text>
                                                        </View>
                                                        {(product.qty_list && product.qty_list.length > 0) ? (
                                                            ((
                                                                <View key={product.qty_list.index} style={{ display: "flex", height: "100%", alignItems: "center" }}>
                                                                    <View style={{ ...styles.tableCell, borderLeftWidth: "none" }} >
                                                                        <img src={`${product.product_image}`} style={{ width: 100, height: 120, borderLeftWidth: "1px", borderRadius: 10 }} />
                                                                    </View>
                                                                </View>))) : <></>
                                                        }
                                                    </View>
                                                    <View style={{ width: "100%" }}>
                                                        <View style={styles.dflex}>
                                                            <View style={{ ...styles.tableColHeader, ...styles.tableCell }}>
                                                                <Text style={{
                                                                    fontWeight: "400", fontFamily: 'Poppins', fontStyle: 'italic'
                                                                }}>Color</Text>
                                                            </View>
                                                            <View style={{ ...styles.tableColHeader, ...styles.tableCell }}>
                                                                <Text style={{ fontWeight: "400", fontFamily: 'Poppins', fontStyle: 'italic' }}>Price</Text>
                                                            </View>
                                                            <View style={{ ...styles.tableColHeader, ...styles.tableCell }}>
                                                                <Text style={{ fontWeight: "400", fontFamily: 'Poppins', fontStyle: 'italic' }}>Discount</Text>
                                                            </View>
                                                            <View style={{ ...styles.tableColHeader, ...styles.tableCell }}>
                                                                <Text style={{ fontWeight: "400", fontFamily: 'Poppins', fontStyle: 'italic' }}>Sale Price</Text>
                                                            </View>
                                                            <View style={{ ...styles.tableColHeader, ...styles.tableCell }}>
                                                                <Text style={{ fontWeight: "400", fontFamily: 'Poppins', fontStyle: 'italic' }}>Quantity</Text>
                                                            </View>
                                                        </View>
                                                        {assigncountertable()}
                                                        {product.qty_list && product.qty_list.length > 0 ? (
                                                            product.qty_list.map((qty, index) => (
                                                                <View key={index} style={{ display: "flex", flexDirection: "column" }}>
                                                                    {/* //        {index===0 &&  <View style={{...styles.tableCell,borderLeftWidth:"none"}} > */}
                                                                    {/* <img src={product.product_image} style={{ width: 100, height: 100,borderLeftWidth:"1px" }} /> */}
                                                                    {/* // </View> */}
                                                                    {/* // } */}
                                                                    {qty.color && qty.color.length > 0 ? (
                                                                        // Map through color array
                                                                        qty.color.map((color, colorIndex) => (
                                                                            <>
                                                                                {updatecountertable()}
                                                                                <View key={colorIndex} style={styles.dflex}>
                                                                                    {/* Display product information */}
                                                                                    <View style={{ ...styles.tableCell, ...(countertable.current % 2 === 0 ? styles.tablebgl : styles.tablebg) }}>
                                                                                        <Text style={{ width: "100%" }}>{color.color_name}</Text>
                                                                                    </View>
                                                                                    <View style={{ ...styles.tableCell, ...(countertable.current % 2 === 0 ? styles.tablebgl : styles.tablebg) }}>
                                                                                        <Text style={{ width: "100%" }}>&#8377;{color.actual_price}</Text>
                                                                                    </View>
                                                                                    <View style={{ ...styles.tableCell, ...(countertable.current % 2 === 0 ? styles.tablebgl : styles.tablebg) }}>
                                                                                        <Text style={{ width: "100%" }}>{color.discount} % Off</Text>
                                                                                    </View>
                                                                                    <View style={{ ...styles.tableCell, ...(countertable.current % 2 === 0 ? styles.tablebgl : styles.tablebg) }}>
                                                                                        <Text style={{ width: "100%" }}>&#8377;{color.selling_price}</Text>
                                                                                    </View>
                                                                                    <View style={{ ...styles.tableCell, ...(countertable.current % 2 === 0 ? styles.tablebgl : styles.tablebg) }}>
                                                                                        <Text style={{ width: "100%" }}>{qty.quantity}</Text>
                                                                                    </View>
                                                                                </View>

                                                                            </>
                                                                        ))
                                                                    ) : (
                                                                        // Display data from qty list when color array is empty
                                                                        <View style={styles.dflex}>
                                                                            {updatecountertable()}
                                                                            {/* Display product information */}
                                                                            <View style={{ ...styles.tableCell, ...(countertable.current % 2 === 0 ? styles.tablebgl : styles.tablebg) }}>
                                                                                <Text style={{ width: "100%" }}>-</Text>
                                                                            </View>
                                                                            <View style={{ ...styles.tableCell, ...(countertable.current % 2 === 0 ? styles.tablebgl : styles.tablebg) }}>
                                                                                <Text style={{ width: "100%" }}>&#8377;{qty.actual_price}</Text>
                                                                            </View>
                                                                            <View style={{ ...styles.tableCell, ...(countertable.current % 2 === 0 ? styles.tablebgl : styles.tablebg) }}>
                                                                                <Text style={{ width: "100%" }}>{qty.discount} % Off</Text>
                                                                            </View>
                                                                            <View style={{ ...styles.tableCell, ...(countertable.current % 2 === 0 ? styles.tablebgl : styles.tablebg) }}>
                                                                                <Text style={{ width: "100%" }}>&#8377;{qty.selling_price}</Text>
                                                                            </View>
                                                                            <View style={{ ...styles.tableCell, ...(countertable.current % 2 === 0 ? styles.tablebgl : styles.tablebg) }}>
                                                                                <Text style={{ width: "100%" }}>{qty.quantity}</Text>
                                                                            </View>

                                                                        </View>
                                                                    )}

                                                                </View>

                                                            ))
                                                        ) : (
                                                            <Text>No Qty List Available</Text>
                                                        )}
                                                    </View>
                                                </View>


                                            </View>

                                        ))}
                                    </View>
                                </View>



                                <View style={styles.contactContainer}>


                                </View>
                            </View>
                        </Page>
                    </Document>
                    <button className='save-pdf' onClick={() => generatePDF(targetRef, { filename: 'catlog.pdf' })}>Save as PDF</button>
                </div></div>
        </>

    )
}

export default CatlogPdfViewer