import React, { Component, useState } from "react";
import "./css/feedbackcard.css";
import image from "./img/feedback.png";
import FeedBackapi from "../../api/FeedBackapi";
import notify from "../../error";
export default function FeedBackCard(props) {
  const [display, setDisplay] = React.useState(false);
  const modalRef = React.useRef(null);

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setDisplay(false);
    }
  };
  function handleChange(event) {
    // console.log(event);
    const { name, value, type, checked } = event.target;
    setEditData(value);
    console.log(editData);
  }
  function handleChange2(event) {
    // console.log(event);
    const { name, value, type, checked } = event.target;
    setReply(value);
    console.log(reply);
  }
  async function SubmitMyReply(e) {
    if (props.free === "yes" && e.key === "Enter") {
      const response = await FeedBackapi.ReplyToFeedbackGiven(
        reply,
        props.data.message_id
      );
      if (response.data.error !== "") {
        notify("error", response.data.error);
      } else {
        e.target.blur();
        GetREplyData();
        setReply("");

        // notify('success','replied successfully')
      }
    } else if (e.key === "Enter") {
    }
  }
  const [edit, setEdit] = React.useState(false);
  const [editData, setEditData] = React.useState(props.data.message);
  const [replyData, setReplyData] = React.useState([]);
  const [showreplyData, setshowReplyData] = React.useState(false);
  const [reply, setReply] = React.useState("");
  function ChangeDisplay1() {
    setDisplay(!display);
  }

  const DeletMyFeedback = async () => {
    // const response = await FeedBackapi.DeleteFeedback(props.data.message_id);
    // console.log(response);
    // if (response.data.error !== '') {
    //   notify('error', response.data.error);
    // } else {
    //   notify('success', 'Post Deleted Successfully');
    //   props.refresh();
    // }
  };

  const EditMeddage = async (e) => {
    if (e.key === "Enter") {
      if (editData === "") {
        notify("warn", "Enter Your message");
        return;
      }
      const response = await FeedBackapi.EditFeedBackMessage(
        props.data.message_id,
        editData
      );
      console.log(response);
      if (response.data.error !== "") {
        notify("error", response.data.error);
      } else {
        notify("success", "Edited Successfully");
        props.refresh();
        setTimeout(() => {
          setEdit(!edit);
        }, 1000);
      }
    }
  };

  const GetREplyData = async () => {
    const response = await FeedBackapi.ShowReplies(props.data.message_id);
    if (response.data.error !== "") {
      notify("error", response.data.error);
    } else {
      setReplyData(response.data.Replies);
    }
  };
  React.useEffect(() => {
    GetREplyData();
  }, []);

  return (
    <React.Fragment>
      <div className="my-feedback-profile-feedback-card-main">
        <div className="my-feedback-profile-feedback-card-row1">
          <div className="my-feedback-profile-feedback-card-image">
            <img src={image} />
          </div>
          <div className="my-feedback-profile-feedback-card-col2">
            <div>
              Message :{" "}
              {edit ? (
                <>
                  <input
                    type="text"
                    value={editData}
                    name="name"
                    onChange={handleChange}
                    placeholder="Enter Your Message"
                    onKeyDown={EditMeddage}
                    required
                  ></input>
                </>
              ) : (
                <>{props.data.message}</>
              )}
            </div>
            {props.data.username && (
              <section>To : {props.data.username}</section>
            )}
            <section>{props.data.datetime}</section>
            <article>
              {replyData.length > 0 ? (
                showreplyData ? (
                  <span
                    onClick={() => {
                      setshowReplyData(false);
                    }}
                  >
                    Hide Replies
                  </span>
                ) : (
                  <span
                    onClick={() => {
                      setshowReplyData(true);
                    }}
                  >
                    View Replies
                  </span>
                )
              ) : (
                <>No Replies To Show</>
              )}
            </article>
            <>
              {!showreplyData && (
                <input
                  type="text"
                  value={reply}
                  name="name"
                  onChange={handleChange2}
                  placeholder="Enter Your Message"
                  onKeyDown={SubmitMyReply}
                  required
                ></input>
              )}
            </>
          </div>
          <div
            className="my-feedback-profile-feedback-card-col3"
            onClick={ChangeDisplay1}
            ref={modalRef}
          >
            <div onClick={ChangeDisplay1}></div>
            <div onClick={ChangeDisplay1}></div>
            <div onClick={ChangeDisplay1}></div>
            {display && (
              <section
                class="my-feedback-profile-feedback-card-col3-thredots"
                onClick={ChangeDisplay1}
              >
                <span onClick={DeletMyFeedback}>Delete</span>
                {props.free === "yes" && (
                  <span
                    onClick={() => {
                      setEdit(!edit);
                    }}
                  >
                    Edit
                  </span>
                )}
              </section>
            )}
          </div>
        </div>
        {showreplyData && (
          <div className="my-feedback-profile-feedback-card-row2">
            <div className="my-feedback-profile-feedback-card-row2-replies">
              {replyData.length > 0 &&
                replyData.map((item) => {
                  return (
                    <FeedbackCardRepliesCArd
                      data={item}
                      m={props.data.message_id}
                      refresh={GetREplyData}
                    ></FeedbackCardRepliesCArd>
                  );
                })}
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

function FeedbackCardRepliesCArd(props) {
  console.log(props);
  const [editData, setEditData] = React.useState(props.data.reply);
  const [edit, setEdit] = React.useState(false);
  const [display, setDisplay] = React.useState(false);
  const [seeMore, setSeeMore] = useState(false);
  function ChangeDisplay1() {
    setDisplay(!display);
  }

  const EditMeddage = async (e) => {
    if (e.key === "Enter") {
      if (editData === "") {
        notify("warn", "Enter Your reply");
        return;
      }
      const response = await FeedBackapi.EditFeedBackReplies(
        props.data.reply_id,
        editData
      );
      console.log(response);
      if (response.data.error !== "") {
        notify("error", response.data.error);
      } else {
        notify("success", "Edited Successfully");
        props.refresh(); //here
        setTimeout(() => {
          setEdit(!edit);
        }, 1000);
      }
    }
  };

  function handleChange(event) {
    // console.log(event);
    const { name, value, type, checked } = event.target;
    setEditData(value);
    console.log(editData);
  }

  const DeletMyFeedback = async () => {
    // notify('success', 'Post Deleted Successfully');
    const response = await FeedBackapi.DeleteReplies(
      props.data.reply_id,
      props.m
    ); //here
    console.log(response);
    if (response.data.error !== "") {
      notify("error", response.data.error);
    } else {
      notify("success", "Post Deleted Successfully");
      props.refresh();
    }
  };

  function convertTo12HourFormat(timeString) {
    const date = new Date(timeString);
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const hours12 = hours % 12 || 12;
    const formattedTime = `${hours12}:${
      minutes < 10 ? "0" : ""
    }${minutes} ${ampm}`;
    console.log(formattedTime);
    return formattedTime;
  }

  return (
    <React.Fragment>
      <div className="my-feedback-profile-feedback-card-replies-cont">
        <div className="my-feedback-profile-feedback-card-replies-col1">
          <img src={props.data.profile_image} alt="" />
        </div>
        <div className="my-feedback-profile-feedback-card-replies-col2">
          <div>
            <div>
              {props.data.my_reply === true ? (
                <span>You</span>
              ) : (
                <span>{props.data.sender}</span>
              )}
            </div>
            <section>
              {props.data.my_reply === true && edit ? (
                <>
                  <textarea
                    type="text"
                    value={editData}
                    name="name"
                    onChange={handleChange}
                    placeholder="Enter Your Message"
                    onKeyDown={EditMeddage}
                    required
                    rows={5}
                  ></textarea>
                </>
              ) : (
                <div
                  className={`${seeMore && "expand"}`}
                  onClick={() => setSeeMore(!seeMore)}
                >
                  {props.data.reply}
                </div>
              )}
            </section>
          </div>
          <div>
            <article>{convertTo12HourFormat(props.data.time)}</article>
          </div>
        </div>
        <div className="my-feedback-profile-feedback-card-replies-col3">
          <div onClick={ChangeDisplay1}></div>
          <div onClick={ChangeDisplay1}></div>
          <div onClick={ChangeDisplay1}></div>
          {display && (
            <section
              class="my-feedback-profile-feedback-card-col3-thredots"
              onClick={ChangeDisplay1}
            >
              <span onClick={DeletMyFeedback}>Delete</span>
              {
                <span
                  onClick={() => {
                    setEdit(!edit);
                  }}
                >
                  Edit
                </span>
              }
            </section>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
