import React, { Component } from 'react';
import MyBillsApi from '../api/MyBillsApi';
// import bulb from '../images/DTh/bulb-svgrepo-com.svg';
// import coverImage from '../images/DTh/electricity.avif';
import notify from '../error';
import { Navigate,useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../context';
import WaterCover from "../images/Putatoe-Illustrations/9.png"


export default function WaterPage(props) {
  const { loginDetail, Userprofile } = useGlobalContext();
  const [operatorList, setOperatorList] = React.useState();
  const WaterList = async () => {
    const response = await MyBillsApi.OtherOperatorList('water');
    console.log(response);
    setOperatorList(response.data.operator_list);
  };
  React.useEffect(() => {
    WaterList();
  }, []);

  const navigate = useNavigate();

   const [formData, setFormData] = React.useState({
     operator_code: '',
     DTH: '',
     amount: '',
     coupon_id: null,
     payment_method: '',
   });

   function ChangeValue(e) {
     const { name, value, type, checked } = e.target;
     // console.log(value,name)
     setFormData((prevFormData) => {
       return {
         ...prevFormData,
         [name]: type === 'checkbox' ? checked : value,
       };
     });
     console.log(formData);
   }
   const validateMobileNumber = (mobileNumber) => {
    const regex = /^[6-9]\d{9}$/;
    return regex.test(mobileNumber);
  };
   const BillMyDTH = async () => {
    const { operator_code, DTH, amount,payment_method } = formData;
    if (DTH === '') {
      notify('error','Please enter a valid Customer Id.');
         return;
       }
   
       if (!validateMobileNumber(DTH)) {
      notify('error','Please enter a valid 10-digit Customer Id.');
         return;
       }
   
       if (operator_code === '') {
      notify('error','Please select a provider.');
         return;
       }
   
       if (amount === '' || parseInt(amount) <= 0) {
      notify('error','Please enter a valid recharge amount.');
         return;
       }
       console.log(operatorList[formData.operator_code]);
       navigate(
        '/cart/recharge',
        { replace: true,
          state: {
            op_code: operatorList[formData.operator_code].op_code,
            serial_no: formData.DTH,
            amount: formData.amount,
            circle:2,
            op_image: operatorList[formData.operator_code].operator_image,
            text1: `Mobile Number ${formData.DTH}`,
            text2: `Recharge ${operatorList[formData.operator_code].operator_name} with `,     pageno:props.pageNo,redirect:"/bill-payments"
          },
        }

      );
    //  const response = await MyBillsApi.RechargeMyDTHApi(
    //    formData.operator_code,
    //    formData.DTH,
    //    parseInt(formData.amount),
    //    formData.coupon_id,
    //    formData.payment_method
    //  );
    //  console.log(response);
    //  if (response.data.error !== '') {
    //    notify('error', response.data.error);
    //  } else {
    //    notify('success', 'DTH Recharge Done SuccessFully');
    //  }
   };



  return (
    <React.Fragment>
      <div className='my-electricity-recharge-main-page-container'>
        <div className='my-electricity-recharge-main-page-container-heading'>
          <div>
            {/* <img src={bulb}></img> */}
            <i class="fa-sharp fa-solid fa-water fa-xl"></i>
          </div>
          <div>
            Let's Get Your<br></br>
            <span>Water Bill Payment Done</span>
            <hr></hr>
          </div>
        </div>
        <div className='my-electricity-recharge-main-page-container-main-content'>
          <div className='my-electricity-recharge-main-page-container-main-content-col1'>
            <div className='my-dth-recharge-main-page-container-main-content-col1-inputs'>
              <div className='my-dth-recharge-main-page-container-main-content-col1-inputs-1'>
      
                <input
                  type='text'
                  value={formData.DTH}
                  onChange={ChangeValue}
                  name='DTH'
                  placeholder='Enter Customer Id'
                ></input>
              </div>
              <div className='my-dth-recharge-main-page-container-main-content-col1-inputs-1'>
                <form>
                  
                  <input
                    list='my-electricity-recharge-main-page-container-main-content-col1-list'
                    onChange={ChangeValue}
                    placeholder="Select Your Provider"
                    name='operator_code'
                  />

                  <datalist id='my-electricity-recharge-main-page-container-main-content-col1-list'>
                    <option value=''>Select Your Provider</option>
                    {operatorList &&
                      operatorList.length > 0 &&
                      operatorList.map((item) => {
                        return (
                          <option value={item.op_code}>
                            {item.operator_name}
                          </option>
                        );
                      })}
                  </datalist>
                </form>
              </div>
              <div className='my-dth-recharge-main-page-container-main-content-col1-inputs-1'>

                <input
                  type='number'
                  value={formData.amount}
                  onChange={ChangeValue}
                  name='amount'
                  placeholder='Enter Recharge Amount'
                ></input>
              </div>
              {/* <div className='my-dth-recharge-main-page-container-main-content-col1-inputs-2'>
                <span>Payment Method</span>
                <form>
                  <input
                    id='payment-mode-input-id-is-online-payment-my-dth-recharge'
                    type='radio'
                    name='payment_method'
                    value='paytm'
                    onChange={ChangeValue}
                  ></input>
                  <label htmlFor='payment-mode-input-id-is-online-payment-my-dth-recharge'>
                    Pay Now
                  </label>
                  <br></br>
                  {Userprofile ? (
                    <>
                      {Userprofile.wallet_balance === 1 ||
                      Userprofile.wallet_balance === 0 ? (
                        <></>
                      ) : (
                        // <span>Your current wallet balance is 0.00</span>
                        <>
                          <input
                            id='payment-mode-input-id-is-wallet-payment-my-dth-recharge'
                            type='radio'
                            name='payment_method'
                            value='wallet'
                            onChange={ChangeValue}
                          ></input>
                          <label htmlFor='payment-mode-input-id-is-wallet-payment-my-dth-recharge'>
                            Wallet
                          </label>
                          <br></br>
                        </>
                      )}
                    </>
                  ) : (
                    <span></span>
                  )}
                </form>
              </div> */}
              <div className='my-dth-recharge-main-page-container-main-content-col1-inputs-button'>
                <button onClick={BillMyDTH}>Proceed</button>
              </div>
            </div>
          </div>
          <div className='my-electricity-recharge-main-page-container-main-content-col2 bill-payments-col2-mobile-view'>
            <div>
              <img src={WaterCover} width="400"></img>
            </div>
          </div>
        </div>

        <div className='my-dth-recharge-main-page-container-allProvider-content'>
          <div className='my-dth-recharge-main-page-container-allProvider-content-all-provider'>
            All Providers
          </div>
          <div className='my-dth-recharge-main-page-container-allProvider-content-all-provider-card-holder'>
            {operatorList &&
              operatorList.length > 0 &&
              operatorList.map((item) => {
                return (
                  <div className='my-dth-recharge-main-page-container-allProvider-content-all-provider-cards'>
                    <div className='my-dth-recharge-main-page-container-allProvider-content-all-provider-cards-col1'>
                      <div>
                        <img src={item.operator_image}></img>
                      </div>
                    </div>
                    <div className='my-dth-recharge-main-page-container-allProvider-content-all-provider-cards-col2'>
                      {item.operator_name}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>

        
      </div>
    </React.Fragment>
  );
}
