import { useState, useEffect, useRef } from "react";
import { RxCross2 } from "react-icons/rx";
import { useGlobalContext } from "../../context";
import { HiUserCircle } from "react-icons/hi";
import myProfileServiceApi from "../../api/myProfileServiceApi";
import { useNavigate } from 'react-router-dom';
import "./css/GeneratePdf.css";
import notify from "../../error";
export default function GeneratePdf({ amount, changeCallFunction,setpage }) {
  const date = new Date();
  const formattedDate = `${date.getFullYear()}-${String(
    date.getMonth() + 1
  ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;

  const [number, setNumber] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [gstnumber, setGstnumber] = useState("");
  const [billType, setBillType] = useState("");
  const [billStatus, setBillStatus] = useState("completed");
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [nosuchstate,setNosuchstate]=useState(false);
  const { Userprofile } = useGlobalContext();
  const [checkvalue,setCheckvalue]=useState(true);
  const { setShowPdfModal,isMobileView} = useGlobalContext();
  const [customerList, setCustomerList] = useState([]); // data we get from findname api
  const [showDropdown, setShowDropdown] = useState({
    name: false,
    number: false,
  });
  const refer=useRef(null);
  const navigate = useNavigate();
  const toggleDatePicker = () => {
    setShowDatePicker(!showDatePicker);
  };
useEffect(()=>{
  window.addEventListener("click",(e)=>{
    console.log(refer.current != e.target,(showDropdown.name || showDropdown.number),showDropdown.name ,showDropdown.number);
    if(refer.current && (showDropdown.name || showDropdown.number) && refer.current != e.target){
      console.log("jsjjjwj");
     setShowDropdown((prevdropdowm)=>{
      return {
    name: false,
    number: false,
      }
     })
    }
  })
},[showDropdown])

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const handleSelectDateClick = (e) => {
    toggleDatePicker();
  };

  function checkNumber(number) {
    const numberString = String(number);
    return /^\d{10}$/.test(numberString);
  }
  const handleChangeCustomer = async (customer) => {
    console.log("called changec");
    console.log("sl");
    setName(customer.user);
    setNumber(customer.phone);
    // await fetchCustomerList();
    setShowDropdown({
      name:false,
      number: false,
    });
  };
  // const fetchCustomerList = async () => {
  //   // const res = await myProfileServiceApi.fetchsearchofflinecustomers();
  //   // console.log(res);
  //   // if (res.data.error !== "") {
  //   //   notify("error", res.data.error);
  //   // } else {
  //   //   setCustomerList(res.data.customer_list);
  //   // }
  // };

  useEffect(() => {
    let searchedtimeout;
    searchedtimeout=setTimeout(async () => {
      let response
      if(number){
       response=await myProfileServiceApi.fetchsearchofflinecustomers(
          {sp_id:Userprofile.service_provider_id,
            search_phone:number,
          });
          if (response.data.error !== "") {
              notify("error", response.data.error);
            } else {
              if(response.data.allCustomer.length > 0){
                setCustomerList(response.data.allCustomer);
                setShowDropdown({
                  name:false,
                  number: true,
                });
              }
              else{
                // setNosuchstate(true);
              }
            }
        }
        else if (name){
              response=await myProfileServiceApi.fetchsearchofflinecustomers(
                {sp_id:Userprofile.service_provider_id,
                search_name:name,
                search_phone:"",
                });
                if (response.data.error !== "") {
                  notify("error", response.data.error);
                } else {
                  if(response.data.allCustomer.length > 0){
                    setCustomerList(response.data.allCustomer);
                    setShowDropdown({
                      name:true,
                      number: false,
                    });
                  }
                  else{
                    // setNosuchstate(true);
                  }
                }
            }
    }, 700);

    return ()=>{
      if(searchedtimeout){
        clearTimeout(searchedtimeout);
      }
    }
  }, [number,name]);

  // const handleNumberChange = async (e) => {
  //   const enteredNumber = e.target.value;
  //   setNumber(enteredNumber);

  //   // Check if only one digit is entered
  //   if (enteredNumber.length > 10) {
  //     console.log("over");
    

  //     setShowDropdown({
  //       name:false,
  //       [e.target.id]: false,
  //     });
  //   } else if (enteredNumber.length >= 1) {
  //     const filteredCustomers = customerList.filter((customer) => {
  //       console.log(
  //         enteredNumber.toString(),
  //         customer.phone.startsWith(enteredNumber.toString())
  //       );
  //       return customer.phone.indexOf(enteredNumber.toString())>-1;
  //     });
  //     console.log(filteredCustomers);

  //     if (filteredCustomers.length > 0) {
  //       setCustomerList(filteredCustomers);
  //       setShowDropdown({
  //         name:false,
  //         [e.target.id]: true,
  //       });

  //       console.log("data");
  //     }else{
  //        // If no matching customers found, hide the dropdown
  //        setShowDropdown({
  //         name:false,
  //         [e.target.id]: false,
  //       });
  //     }
  //   } else {
  //     console.log("nodata");
  //     // await fetchCustomerList();
  //     setShowDropdown({
  //       name:false,
  //       [e.target.id]: false,
  //     });
  //   }
  // };

  // const handleNameChange = async (e) => {
  //   const enteredName = e.target.value;
  //   setName(enteredName);

  //   // Check if name is not empty
  //   if (enteredName.trim() !== "") {
  //     // Filter the customerList based on enteredName
  //     const filteredCustomers = customerList.filter((customer) =>
  //       customer.name.toUpperCase().indexOf(enteredName.toUpperCase())>-1
  //     );

  //     if (filteredCustomers.length > 0) {
  //       // Show the dropdown and update the filtered customer list
  //       setShowDropdown({
  //         number: false,
  //         [e.target.id]: true,
  //       });
  //       setCustomerList(filteredCustomers);
  //     } else {
  //       // If no matching customers found, hide the dropdown
  //       setShowDropdown({
  //         number: false,
  //         [e.target.id]: false,
  //       });
  //     }
  //   } else {
  //     // If the name is empty, hide the dropdown and reset customerList
  //     setShowDropdown({
  //       number: false,
  //       [e.target.id]: false,
  //     });
  //     // await fetchCustomerList(); // This fetches the full list again
  //   }
  // };


  const handleGeneratePdfClick = async (e) => {
    let localIncoming, localOutgoing, localCompleted, localPending;

    if (billType === "incoming") {
      localIncoming = 1;
      localOutgoing = 0;
    } else if (billType === "outgoing") {
      localIncoming = 0;
      localOutgoing = 1;
    }

    if (billStatus === "completed") {
      localCompleted = 1;
      localPending = 0;
    } else if (billStatus === "pending") {
      localCompleted = 0;
      localPending = 1;
    }
    // console.log(number, name, description, localIncoming, localOutgoing, localCompleted, localPending, Userprofile.service_provider_id,amount);
    if (name === "" || number === "" || billStatus === "" || billType === "") {
      notify("warn", "Fill all the inputs");
      return;
    }
    if (!checkNumber(number)) {
      notify("warn", "Enter a valid number");
      return;
    }

    try {
      const res = await myProfileServiceApi.OfflineBillGeneration(
        Userprofile.service_provider_id,
        amount.toString(),
        localIncoming,
        localOutgoing,
        localPending,
        localCompleted,
        0,
        name,
        number.toString(),
        description,
        gstnumber,
        selectedDate || formattedDate,
        checkvalue

      );
      if (res.status === 200) {
        notify("success", "Bill generated successfully...");
        setShowPdfModal(false);
        changeCallFunction(true);
    
      }
      if (res.data && res.data.orderId) {
        // Navigate to the viewpdf route
        setTimeout(() => {
          window.open(`/viewpdf/offline/${res.data.orderId}/`, '_blank');
        }, 1500);

      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <section className="generate-bill-overlay">
      {isMobileView && <>
      <div
        className="generate-pdf-container"
        style={{ border: "2px solid black" }}
      >
        <form
          className="generate-pdf-form"
          onSubmit={(e) => e.preventDefault()}
        >
          <RxCross2
            className="crossIcon"
            onClick={() => setShowPdfModal(false)}
          />
          <div>
            <label htmlFor="number">
              Customer mobile number <span>*</span>
            </label>
            <div className="input-container">
              {/* {nosuchstate && number && <div className="nosuchcustomer">No such Customer</div>} */}
              <div className="inputsection">
              <input
                id="number"
                type="number"
                value={number}
                autoComplete="off"
                onChange={(e)=>{
                  setNumber(e.target.value)
                  // setNosuchstate(false);
                }}
                required
              />
              <HiUserCircle className="user-icon" />
              </div>
              {showDropdown.number && (
                <div className="generate-pdf-form-dropdown-list" ref={refer}>
                  {customerList.map((customer, index) => (
                    <div
                      key={index}
                      className="dropdown-item"
                      onClick={() => handleChangeCustomer(customer)}
                    >
                      {customer.user} : {customer.phone}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div>
            <label htmlFor="name">
              Customer name <span>*</span>
            </label>
            <div className="input-container">
            {/* {nosuchstate && name && <div className="nosuchcustomer">No such Customer</div>} */}
              <input
                id="name"
                type="text"
                autoComplete="off"
                value={name}
                onChange={(e)=>{
                  setName(e.target.value)
                  // setNosuchstate(false);
                }}
                required
              />
                {showDropdown.name && (
                <div className="generate-pdf-form-dropdown-list" ref={refer}>
                  {customerList.map((customer, index) => (
                    <div
                      key={index}
                      className="dropdown-item"
                      onClick={() => handleChangeCustomer(customer)}
                    >
                      {customer.user} : {customer.phone}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div>
            <label htmlFor="description">Description</label>
            <div className="input-container">
              <input
                id="description"
                type="text"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
          </div>
          <div>
            <label htmlFor="description">GST Number</label>
            <div className="input-container">
              <input
                id="GSTNUMBER"
                type="text"
                value={gstnumber}
                onChange={(e) => setGstnumber(e.target.value)}
              />
            </div>
          </div>
          <div>
            <label>
              Choose bill type <span>*</span>
            </label>
            <div className="input-radio-container">
              <div>
                <input
                  type="radio"
                  id="incoming"
                  value="incoming"
                  checked={billType === "incoming"}
                  onChange={(e) => setBillType(e.target.value)}
                  required
                />
                <label htmlFor="incoming">Incoming</label>
              </div>
              <div>
                <input
                  type="radio"
                  id="outgoing"
                  value="outgoing"
                  checked={billType === "outgoing"}
                  onChange={(e) => setBillType(e.target.value)}
                  required
                />
                <label htmlFor="outgoing">Outgoing</label>
              </div>
            </div>
          </div>

          <div>
            <label>
              Choose bill status <span>*</span>
            </label>
            <div className="input-radio-container">
              <div>
                <input
                  type="radio"
                  id="completed"
                  value="completed"
                  checked={billStatus === "completed"}
                  onChange={(e) => setBillStatus((prevvalue)=>{
                    return e.target.value;
                  })}
                  required
                />
                <label htmlFor="completed">Completed</label>
              </div>
              <div>
                <input
                  type="radio"
                  id="pending"
                  value="pending"
                  checked={billStatus === "pending"}
                  onChange={(e) => setBillStatus((prevvalue)=>{
                    return e.target.value;
                  })}
                  required
                />
                <label htmlFor="pending">Pending</label>
              </div>
            </div>
          </div>
<div>
<label>Payment Type<span>*</span></label>
<div className="checkinputtt">
  <input type="checkbox" checked={checkvalue} onChange={(e)=>{
    setCheckvalue((prevcheckvalue)=>{
      return !prevcheckvalue;
    })
    console.log(e.target.checked);
  }} id="checkinput"></input>

  <span className="checkk">No Due</span>

</div>
</div>
          <div>
            <label>
              Select Date <span>*</span>
            </label>
            <div className="date-picker">
              {/* <button type="text" className="current-date active-button">
                {selectedDate || formattedDate}
              </button> */}
               <input
                type="date"
                // className="custom-date-picker"
                className="current-date active-button"
                onChange={handleDateChange}
                max={formattedDate}
                value={selectedDate || formattedDate}
              />
              <button
                type="button"
                className="select-date active-button"
                onClick={handleSelectDateClick}
              >
                Change Date
              </button>
            </div>
            {/* {showDatePicker && (
              <input
                type="date"
                className="custom-date-picker"
                onChange={handleDateChange}
                max={formattedDate}
              />
            )} */}
          </div>
          <div>
            <label style={{ width: "120px" }}>Bill Amount :</label>&#8377;{" "}
            {amount}
          </div>
          <button
            type="submit"
            className="big-button"
            onClick={(e) => handleGeneratePdfClick(e)}
          >
            Generate Bill
          </button>
        </form>
      </div>
      </>}
      {
        !isMobileView && <>
        <div
        className="generate-pdf-container"
        style={{ border: "2px solid black" }}
      >
        <form
          className="generate-pdf-form"
          onSubmit={(e) => e.preventDefault()}
        >
          <RxCross2
            className="crossIcon"
            onClick={() => setShowPdfModal(false)}
          />
          <div className="billdesktop">
          <div className="sectiondesktopp">
            <label htmlFor="number">
              Customer mobile number <span class="star" style={{ color:"red"}}>*</span>
            </label>
            <div className="input-container">
              {/* {nosuchstate && number && <div className="nosuchcustomer">No such Customer</div>} */}
              <div className="inputsection">
              <input
                id="number"
                type="number"
                value={number}
                autoComplete="off"
                onChange={(e)=>{
                  setNumber(e.target.value)
                  // setNosuchstate(false);
                }}
                required
              />
              <HiUserCircle className="user-icon" />
              </div>
              {showDropdown.number && (
                <div className="generate-pdf-form-dropdown-list" ref={refer}>
                  {customerList.map((customer, index) => (
                    <div
                      key={index}
                      className="dropdown-item"
                      onClick={() => handleChangeCustomer(customer)}
                    >
                      {customer.user} : {customer.phone}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div className="sectiondesktopp">
            <label htmlFor="name">
              Customer name <span class="star" style={{ color:"red"}}>*</span>
            </label>
            <div className="input-container">
            {/* {nosuchstate && name && <div className="nosuchcustomer">No such Customer</div>} */}
              <input
                id="name"
                type="text"
                autoComplete="off"
                value={name}
                onChange={(e)=>{
                  setName(e.target.value)
                  // setNosuchstate(false);
                }}
                required
              />
                {showDropdown.name && (
                <div className="generate-pdf-form-dropdown-list" ref={refer}>
                  {customerList.map((customer, index) => (
                    <div
                      key={index}
                      className="dropdown-item"
                      onClick={() => handleChangeCustomer(customer)}
                    >
                      {customer.user} : {customer.phone}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          </div>
          <div className="billdesktop">
          <div className="sectiondesktopp">
            <label htmlFor="description">Description</label>
            <div className="input-container">
              <input
                id="description"
                type="text"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
          </div>
          <div className="sectiondesktopp">
            <label htmlFor="description">GST Number</label>
            <div className="input-container">
              <input
                id="GSTNUMBER"
                type="text"
                value={gstnumber}
                onChange={(e) => setGstnumber(e.target.value)}
              />
            </div>
          </div>
          </div>
          <div className="billdesktop">
          <div className="sectiondesktopp">
            <label>
              Choose bill type <span class="star" style={{ color:"red"}}>*</span>
            </label>
            <div className="input-radio-container">
              <div>
                <input
                  type="radio"
                  id="incoming"
                  value="incoming"
                  checked={billType === "incoming"}
                  onChange={(e) => setBillType(e.target.value)}
                  required
                />
                <label htmlFor="incoming">Incoming</label>
              </div>
              <div>
                <input
                  type="radio"
                  id="outgoing"
                  value="outgoing"
                  checked={billType === "outgoing"}
                  onChange={(e) => setBillType(e.target.value)}
                  required
                />
                <label htmlFor="outgoing">Outgoing</label>
              </div>
            </div>
          </div>

          <div className="sectiondesktopp">
            <label>
              Choose bill status <span class="star" style={{ color:"red"}}>*</span>
            </label>
            <div className="input-radio-container">
              <div>
                <input
                  type="radio"
                  id="completed"
                  value="completed"
                  checked={billStatus === "completed"}
                  onChange={(e) => setBillStatus((prevvalue)=>{
                    return e.target.value;
                  })}
                  required
                />
                <label htmlFor="completed">Completed</label>
              </div>
              <div>
                <input
                  type="radio"
                  id="pending"
                  value="pending"
                  checked={billStatus === "pending"}
                  onChange={(e) => setBillStatus((prevvalue)=>{
                    return e.target.value;
                  })}
                  required
                />
                <label htmlFor="pending">Pending</label>
              </div>
            </div>
          </div>
          </div>
          <div className="billdesktop">
          <div className="sectiondesktopp">
<label>Payment Type <span class="star" style={{ color:"red"}}>*</span></label>
<div className="checkinputtt">
  <input type="checkbox" checked={checkvalue} onChange={(e)=>{
    setCheckvalue((prevcheckvalue)=>{
      return !prevcheckvalue;
    })
    console.log(e.target.checked);
  }} id="checkinput"></input>

  <span className="checkk">No Due</span>

</div>
</div>
          <div className="sectiondesktopp">
            <label>
              Select Date <span class="star" style={{ color:"red"}}>*</span>
            </label>
            <div className="date-picker">
              {/* <button type="text" className="current-date active-button">
                {selectedDate || formattedDate}
              </button> */}
              <input
                type="date"
                // className="custom-date-picker"
                className="current-date active-button"
                onChange={handleDateChange}
                max={formattedDate}
                value={selectedDate || formattedDate}
              />
              <button
                type="button"
                className="select-date active-button"
                onClick={handleSelectDateClick}
              >
                Change Date
              </button>
            </div>
            {/* {showDatePicker && (
              <input
                type="date"
                className="custom-date-picker"
                onChange={handleDateChange}
                max={formattedDate}
              />
            )} */}
          </div>
          </div>
          <div className="biiillamount">
            <label style={{ width: "120px" }}>Bill Amount :</label>&#8377;{" "}
            {amount}
          </div>
          <button
            type="submit"
            className="big-button"
            onClick={(e) => handleGeneratePdfClick(e)}
          >
            Generate Bill
          </button>
        </form>
      </div>
        </>
      }
    </section>
  );
}
