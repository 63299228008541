import React, { Component } from 'react'
import './css/referAndEarnPage.css'
import fbimg from './img/facebook.png'
import mailimg from './img/mail.png';
import rfimg from './img/referandearn.png';
import twimg from './img/twitter.png';
import wtimg from './img/whatsapp.png';
import { useGlobalContext } from '../../context';
import notify from '../../error';
export default function ReferAndEarnPageMain()
{
const { loginDetail, Userprofile } = useGlobalContext();


 function copyToClipboard() {
   navigator.clipboard.writeText(Userprofile['refferal code']);
   notify('success','copied to clipboard')
 }

 let postUrl = encodeURI(`https://putatoeapp.web.app/`);
        let postTitle = encodeURI("Sign Up with Putatoe and get 1000 reward points by using my referral code " + Userprofile['refferal code'] + ".");

 function whatapp()
 {
  window.open(`https://api.whatsapp.com/send?text=${postTitle} ${postUrl}`,"_blank")
 }
 function twitter() {
   window.open(`https://twitter.com/share?url=${postUrl}&text=${postTitle}`,"_blank");
 }
 function mail() {
   window.open(`https://mail.google.com/mail/u/0/?tf=cm&su=PUTATOE&body=${postTitle}+${postUrl}`,"_blank");
 }
 function facebook() {
   window.open(`https://www.facebook.com/sharer/sharer.php?u=${postUrl}"e=${postTitle} ${postUrl}`,"_blank");
 }

 return (
   <React.Fragment>
     <div className='my-profile-refer-and-earn-main-page'>
       <div className='profile-wallet-main-page-heading profile-refer-and-earn-main-page-heading'>
         <span>Refer And Earn</span>
       </div>
       <div className='my-profile-refer-and-earn-banner'>
         <div className='my-profile-refer-and-earn-banner-col1'>
           <div>Refer a friend and earn 500 reward points</div>
           <section>
             <div>When they place an order using the referral code,</div>
             <section>
               <b>they get 1000 reward points</b>
             </section>
             <div>100 Reward Points = 1 Rupee</div>
           </section>
         </div>
         <div className='my-profile-refer-and-earn-banner-col2'>
           <img src={rfimg}></img>
         </div>
       </div>
       <div className='my-profile-refer-and-earn-code'>
         <div className='my-profile-refer-and-earn-copycode'>
           <div>Tap To Copy The Code</div>
           <section onClick={copyToClipboard}>{Userprofile['refferal code']}</section>
         </div>
         <div className='my-profile-refer-and-earn-code-seperator'>
           <span>OR</span>
         </div>
         <div className='my-profile-refer-and-earn-sharecode'>
           <div>Share Via</div>
           <section>
             <div onClick={facebook}>
               <img src={fbimg}></img>
               <div>FaceBook</div>
             </div>
             <div onClick={mail}>
               <img src={mailimg}></img>
               <div>Email</div>
             </div>
             <div onClick={whatapp}>
               <img src={wtimg}></img>
               <div>Whatsapp</div>
             </div>
             <div onClick={twitter}>
               <img src={twimg}></img>
               <div>Twitter</div>
             </div>
           </section>
         </div>
       </div>
       <div className='my-profile-refer-and-earn-working'>
         <div>
           <span>How Does Referral Work ?</span>
           <ul>
             <li>
               <span class='my-profile-refer-and-earn-working-Bullet'>1</span>
               <span>Invite your friend to Putatoe</span>
             </li>
             <li>
               <span class='my-profile-refer-and-earn-working-Bullet'>2</span>
               <span>They register their account with your referral code</span>
             </li>
             <li>
               <span class='my-profile-refer-and-earn-working-Bullet'>3</span>
               <span>They get 500 reward points</span>
             </li>
             <li>
               <span class='my-profile-refer-and-earn-working-Bullet'>4</span>
               <span>You get 500 reward points</span>
             </li>
           </ul>
         </div>
         <div></div>
       </div>
     </div>
   </React.Fragment>
 );
}