import React, { Component } from "react";
import profileapi from "../api/profileapi";
import notify from "../error";
import "./cart-css/AddNewAddresss.css";
import Geocode from "react-geocode";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import locationImage from "../images/location-2-svgrepo-com.svg";

export default function AddNewAddress(props) {
  // const [coupon, setCoupon] = React.useState();
  function HideModal() {
    props.setShow();
  }

  const [formData, setFormData] = React.useState({
    name: "",
    phone: "",
    addressline1: "",
    addressline2: "",
    landmark: "",
    town: "",
    pincode: "",
    latitude: "",
    longitude: "",
    district: "",
    state: "",
    default_address: 0,
  });
  const [location, setLocation] = React.useState(null);
  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  Geocode.setApiKey(googleMapsApiKey);

  const onAddition = async (e) => {
    e.preventDefault();
    console.log(formData.phone.length);
    if (
      formData.name === "" ||
      formData.phone === "" ||
      formData.addressline1 === "" ||
      formData.addressline2 === "" ||
      formData.landmark === "" ||
      formData.town === "" ||
      formData.pincode === "" ||
      formData.district === "" ||
      formData.state === ""
    ) {
      notify("warn", "Please Fill Out All The Fields");
      return;
    }

    if (formData.phone.length !== 10) {
      notify("error", "Enter 10-digit mobile number");
      return;
    }

    let variable = formData.default_address ? 1 : 0;
    const response = await profileapi.AddAdress(
      formData.name,
      formData.phone,
      formData.addressline1,
      formData.addressline2,
      formData.landmark,
      formData.town,
      formData.pincode,
      formData.latitude,
      formData.longitude,
      formData.district,
      formData.state,
      variable
    );
    console.log(response);
    // props.reload();
    if (response.data.status === true) {
      props.setShow();
    } else {
      notify("warn", response.data.error);
      props.setShow();
    }
  };

  function handleChange(event) {
    console.log(event);
    const { name, value, type, checked } = event.target;
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: type === "checkbox" ? checked : value,
      };
    });
    console.log(formData);
  }

  const handleClick = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;

          try {
            // Retrieve address from coordinates
            const response = await Geocode.fromLatLng(latitude, longitude);
            const address = response.results[0].formatted_address;
            console.log(address);
            const address_formatted = address.split(",");
            console.log(address_formatted);
            const state_pincode_formatted =
              address_formatted[address_formatted.length - 2];
            const state_pincode_array = state_pincode_formatted.split(" ");

            setLocation({ lat: latitude, lng: longitude });
          } catch (error) {
            console.log("Error retrieving address:", error);
            notify("error", "Network error");
          }
        },
        (error) => {
          console.log("Geolocation error:", error);
          notify("error", error.message);
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };

  const handleMarkerDragend = async (e) => {
    const { latLng } = e;
    console.log(e);
    const lat = latLng.lat();
    const lng = latLng.lng();
    try {
      setLocation({ lat, lng });
      const response = await Geocode.fromLatLng(lat, lng);
      const address = response.results[0].formatted_address;
      const address_formatted = address.split(", ");
      console.log(address_formatted);
      const state_pincode_formatted =
        address_formatted[address_formatted.length - 2];
      console.log(state_pincode_formatted);
      const state_pincode_array = state_pincode_formatted.split(" ");
      console.log(state_pincode_array);
      let newState = "";
      let newPincode = "";

      if (state_pincode_array.length === 1 && !isNaN(state_pincode_array[0])) {
        newPincode = state_pincode_array[0];
      } else if (state_pincode_array.length === 2) {
        newState = isNaN(state_pincode_array[0]) ? state_pincode_array[0] : "";
        newPincode =
          isNaN(state_pincode_array[1]) ||
            typeof state_pincode_array[1] !== "string"
            ? ""
            : state_pincode_array[1];
      }
      console.log(response.results);
    } catch (error) {
      console.error("Error retrieving address:", error);
      notify("error", "Error retrieving address");
    }
  };

  return (
    <React.Fragment>
      {props.show && (
        <>
          <div className="add-address-modal-container-background"></div>
          <div
            className={
              props.show
                ? " add-address-modal-container add-address-modal-container-intro-in"
                : "add-address-modal-container"
            }
          >
            <div className="add-address-modal-cross">
              <span onClick={HideModal}>X</span>
            </div>
            <form>
              <div className="add-address-modal-coupon-enter">
                <div className="add-address-modal-map-section">
                  {!location ? (
                    <button onClick={handleClick}>
                      <img src={locationImage} alt="" />
                      <p>Use my current location</p>
                    </button>
                  ) : (
                    <div
                      style={{
                        width: "400px",
                        height: "300px",
                        position: "relative",
                      }}
                    >
                      <Map
                        google={window.google}
                        apiKey={googleMapsApiKey}
                        zoom={14}
                        initialCenter={location}
                        style={{ width: "100%", height: "100%" }}
                      >
                        <Marker
                          position={location}
                          draggable={true}
                          onDragend={(t, map, coord) =>
                            handleMarkerDragend(coord)
                          }
                        />
                      </Map>
                    </div>
                  )}
                </div>
                <div className="add-address-modal-coupon-enter-input">
                  <span>FULL NAME</span>
                  <input
                    type="text"
                    value={formData.name}
                    name="name"
                    onChange={handleChange}
                    placeholder="Name"
                    required
                  ></input>
                </div>
                <div className="add-address-modal-coupon-enter-input">
                  <span>MOBILE NUMBER</span>
                  <input
                    type="phone"
                    value={formData.phone}
                    name="phone"
                    onChange={handleChange}
                    placeholder="10- digit mobile number"
                    required
                  ></input>
                </div>
                <div className="add-address-modal-coupon-enter-input">
                  <span>PINCODE</span>
                  <input
                    type="text"
                    value={formData.pincode}
                    name="pincode"
                    onChange={handleChange}
                    placeholder="Pincode"
                    required
                  ></input>
                </div>
                <div className="add-address-modal-coupon-enter-input">
                  <span>ADDRESS</span>
                  <input
                    type="text"
                    value={formData.addressline1}
                    name="addressline1"
                    onChange={handleChange}
                    placeholder="Flat , House no. , Landmark   "
                    required
                  ></input>
                </div>
                <div className="add-address-modal-coupon-enter-input">
                  <span>LOCALITY/TOWN</span>
                  <input
                    type="text"
                    value={formData.town}
                    name="town"
                    onChange={handleChange}
                    placeholder="Town/City"
                    required
                  ></input>
                </div>
                <div className="add-address-modal-coupon-enter-input">
                  <span>Area/Street</span>
                  <input
                    type="text"
                    value={formData.addressline2}
                    name="addressline2"
                    onChange={handleChange}
                    placeholder="Area , Street , Sector , Village"
                    required
                  ></input>
                </div>
                <div className="add-address-modal-coupon-enter-input">
                  <span>LandMark</span>
                  <input
                    type="text"
                    value={formData.landmark}
                    name="landmark"
                    onChange={handleChange}
                    placeholder="Landmark"
                    required
                  ></input>
                </div>
                <div className="add-address-modal-coupon-enter-input">
                  <span>STATE</span>
                  <input
                    type="text"
                    value={formData.state}
                    name="state"
                    onChange={handleChange}
                    placeholder="State"
                    required
                  ></input>
                </div>
                <div className="add-address-modal-coupon-enter-input">
                  <span>DISTRICT</span>
                  <input
                    type="text"
                    value={formData.district}
                    name="district"
                    onChange={handleChange}
                    placeholder="District"
                    required
                  ></input>
                </div>
                <div className="add-new-address-input-check">
                  <input
                    type="checkbox"
                    id="add-new-address-input-check-1"
                    checked={formData.default_address}
                    onChange={handleChange}
                    name="default_address"
                  />
                  <span htmlFor="add-new-address-input-check-1">
                    Make this my default address
                  </span>
                </div>
              </div>
              <div className="add-address-modal-coupon-enter-submit">
                <button onClick={onAddition}>SAVE ADDRESS</button>
              </div>
            </form>
          </div>
        </>
      )}
    </React.Fragment>
  );
}

// <div className='profile-account-setting-right-new-address-input'>
//           <input
//             type='text'
//             value={formData.name}
//             name='name'
//             onChange={handleChange}
//             placeholder='Name'
//           ></input>
//           <input
//             type='text'
//             value={formData.phone}
//             name='phone'
//             onChange={handleChange}
//             placeholder='10- digit mobile number'
//           ></input>
//           <input
//             type='text'
//             value={formData.pincode}
//             name='pincode'
//             onChange={handleChange}
//             placeholder='Pincode'
//           ></input>
//           <input
//             type='text'
//             value={formData.addressline1}
//             name='addressline1'
//             onChange={handleChange}
//             placeholder='Flat , House no. , Landmark   '
//           ></input>
//           <input
//             type='text'
//             value={formData.town}
//             name='town'
//             onChange={handleChange}
//             placeholder='Town/City'
//           ></input>
//           <input
//             type='text'
//             value={formData.addressline2}
//             name='addressline2'
//             onChange={handleChange}
//             placeholder='Area , Street , Sector , Village'
//           ></input>
//           <input
//             type='text'
//             value={formData.state}
//             name='state'
//             onChange={handleChange}
//             placeholder='State'
//           ></input>
//           <input
//             type='text'
//             value={formData.district}
//             name='district'
//             onChange={handleChange}
//             placeholder='District'
//           ></input>
//         </div>
//         <div className='profile-account-setting-right-new-address-input-check'>
//           <input
//             type='checkbox'
//             id='profile-account-setting-right-new-address-input-check-1'
//             checked={formData.default_address}
//             onChange={handleChange}
//             name='default_address'
//           />
//           <label htmlFor='profile-account-setting-right-new-address-input-check-1'>
//             Make this my default address
//           </label>
//         </div>
