import React, { Component, useState, useRef, useEffect } from "react";
import myProfileServiceApi from "../../api/myProfileServiceApi";
import notify from "../../error";
import { useGlobalContext } from "../../context";
import "./css/ManageProduct.css";
import back from "../myprofileIcoons/bx-arrow-back..svg";
import { motion } from "framer-motion";
import { AiFillEdit, AiOutlinePlusCircle } from "react-icons/ai";
import { BiSolidPlusCircle, BiSolidTrashAlt } from "react-icons/bi";
import { MdOutlineModeEdit } from "react-icons/md"
import { IoIosArrowDown, IoIosSend } from "react-icons/io";
import { TfiEye } from "react-icons/tfi"
import { ChromePicker } from 'react-color';
import { GetColorName } from 'hex-color-to-color-name';
import profileapi from "../../api/profileapi";
import { IoCloseCircleSharp, IoTrashOutline } from "react-icons/io5";
import postapi from "../../api/postapi";
import Loading from "../../components/Loading";
import ManageProductModal from "./ManageProdutModal";
import CatlogPdfViewer from "./CatlogPdfViewer";

export default function ManageProduct(props) {
  // TO close the color drop down option when clicking on screen 
  const dropdownRef = useRef(null);
  const dropdownRefsarray = [];
  const showcolorcloseindex= useRef("");
  let abarray=useRef([]);

  // const toggledispalyColor = () => {
  //   setDisplayColor(!displayColor);
  // }


  const {
    Userprofile,
    loading,
    setLoading,
    modifyModal,
    setModifyModal,
    productsUpdate,
    setProductsUpdate,
    setInnerPage,
    userServiceIDselected,
    isMobileView,
  } = useGlobalContext();




  // const [newProductUpdate, setNewProductUpdate] = useState(false);
  //const [displayColor, setDisplayColor] = React.useState(false);
  const [isAddingProduct, setIsAddingProduct] = useState(false);
  const myElementRef = useRef(null);
  let searchString = "";
  const [isUploadingImage, setIsUploadingImage] = useState(false);
  const [apicalling, setApicalling] = useState(false);
  const [page, setPage] = React.useState(1);
  const [productList, setProductList] = React.useState([]);
  const [modalProduct, setModalProduct] = React.useState();
  const [modalProductQuantityIndex, setModalProductquantityIndex] = useState(0);
  const [modalProductColorIndex, setModalProductcolorIndex] = useState(null);
  const [subId, setSubId] = React.useState([]);
  const [stringsearchstore, setStringsearchstore] = useState("");
  const [outofstockmodalstate,setOutofstockmodalstate]=useState(false);
  let tablecounter = 0;
  const dialogreeef = React.useRef();
  const dialogreeef1 = React.useRef();
  const colormodalref = React.useRef();
  const [unitsdata, setUnitsdata] = useState([
    "piece",
    "pack",
    "number",
    "inch",
    "bag",
    "kg",
    "ton",
    "litre",
    "sq feet",
    "bucket",
    "meter",
    "metric ton",
    "quintal"
  ]);


  //colordropdown
  const [selectedColor, setSelectedColor] = useState([]); // selected colors from user
  const [showColors, setShowColors] = useState(false);// show apidata color dropdown
  const [isColorDropdownVisible, setIsColorDropdownVisible] = useState(false); // show selected color dropdown
  const [newselectedcurrentcolor, setnewselectedcurrentcolor] = useState("");
  const [colors, setColors] = useState([
    { name: 'Red', code: '#f00' },
    { name: 'Green', code: '#0f0' },
    { name: 'Blue', code: '#00f' },
    { name: 'Yellow', code: '#ff0' },
    { name: 'Orange', code: '#f70' },
    { name: 'Purple', code: '#808' },
  ]);
  const [currentSelectedColor, setCurrentSelectedColor] = useState();
  const [colorIndexDelete, setColorIndexDelete] = useState({});
  let inputrref = useRef("");

  //quantitydropdown
  const [allselectedVariants, setAllselectedVariants] = useState([]); // selected quantities from the user
  const [showQuantities, setShowQuantities] = useState(false); // show API data quantity dropdown
  const [isQuantityDropdownVisible, setIsQuantityDropdownVisible] = useState(false); // show selected quantity dropdown
  const [isotherqty, setIsotherqty] = useState(false);
  const [isotherunit, setIsotherunit] = useState(false);
  const [isothercolor, setIsothercolor] = useState(false);
  const [isColorPickerVisible, setIsColorPickerVisible] = useState(true); // Initialize as true
  const [quantityData, setQuantityData] = useState([]);
  const [currentVariant, setCurrentVariant] = useState({
    quantity: '',
    colors: [],
  });
  const [showTable, setShowTable] = useState(false);
  const colorPickerRef = useRef();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  // const [ismobile,setIsmobile]=useState(false);
  // storing data of each field from api response and showing in option
  const [productData, setProductData] = React.useState({
    productType: "",
    productBrand: "",
    productName: "",
    productColor: "",
    productQuantity: "",
    productQuantityUnit: "",
    productSubcategory: "",
  });
  const [catalogServicesdata, setCatlogServicesdata] = React.useState('');
  const [catalogProducts, setCatalogProducts] = React.useState([]);// store catlog data from api
  const [displayPriceUnitValue, setDisplayPriceUnitValue] = React.useState([
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
  ]);
  const [scrollset, SetScrollset] = useState(false);
  const [searchProductName, setSearchProductName] = useState("");
  const [quantityIndex, setQuantityIndex] = React.useState(0);
  const [displayPriceUnit, setDisplayPriceUnit] = React.useState(1);
  const [startIndex, setStartIndex] = React.useState(0);
  const [colorvis, setcolorvis] = useState(false);
  const [displayPriceQuantityMap, setDisplayPriceQuantityMap] = React.useState(
    {}
  );
  const [emptyFetch, setEmptyFetch] = React.useState(false);
  const [allImagePreviews, setAllImagePreviews] = React.useState([]);
  const [formData, setFormData] = React.useState({
    type: "",
    brand: "",
    pname: "",
    color: "",
    quantity: "",
    qunit: "",
    desc: "",
    price: "",
    units: "",
    discount: "",
    img: "",
    service: "",
    subservice: "",
    othertype: "",
    otherbrand: "",
    otherpname: "",
    othercolorname: "",
    othercolorcode: "",
    otherquantity: "",
  });

  const [showConfirmation, setShowConfirmation] = React.useState(false);
  const [showQuantitySelection, setShowQuantitySelection] =
    React.useState(false);
  const [showcatlogmodal, setShowcatlogmodal] =
    React.useState(false);
  const [selectedProductId, setSelectedProductId] = React.useState(null);
  const fileInputRef = React.useRef({});
  //for color products data
  const [colorItemIndexes, setColorItemIndexes] = React.useState(Array(productList.length).fill(0));
  const [showMorecolours, setShowMorecolours] = React.useState(Array(productList.length).fill(false));

  const [coloraddedother, setColoraddedother] = useState(false);
  const [forstateupdate,setForstateupdate]=useState({element:"",status:false});
  const [stockstatus,setStockstatus]=useState([]);
  useEffect(() => {
    if (coloroptionparent && coloroptionparent.current && !isMobileView) {
      coloroptionparent.current.scrollTo({ top: coloroptionparent.current.scrollHeight, behavior: "smooth" })
    }
    else if (coloroptionparent && coloroptionparent.current && isMobileView) {
      coloroptionparent.current.scrollTo({ left: coloroptionparent.current.scrollWidth, behavior: "smooth" })
    }
  }, [colors])
  const coloroptionparent = React.useRef("");

  useEffect(() => {
    const cliick=(Event)=>{
      setForstateupdate({element:Event,status:!(forstateupdate.status)});
    }
    document.addEventListener('click', cliick);
    return () => {
      document.removeEventListener('click',cliick)
    };
  }, []);

  useEffect(()=>{
    console.log("KSK",forstateupdate);
    const checkingwhichclicked = ()=>{

      if(forstateupdate.element.target?.getAttribute("forcheckingonly") || forstateupdate.element.target?.parentElement?.getAttribute("forcheckingonly")){
          return false
        }
      return true;
    }

if(checkingwhichclicked()){
  console.log("jsjjsjjjsj");
  setShowMorecolours(Array(productList.length).fill(false));
}
  },[forstateupdate])


  const handleChangeColor = (index, colorIndex) => {
    const newColorItemIndexes = [...colorItemIndexes];
    newColorItemIndexes[index] = colorIndex;
    setColorItemIndexes(newColorItemIndexes);
  };
const cleanup = ()=>{
  setShowTable(false);
              setIsModalOpen(false);
              setIsotherqty(false);
              setIsQuantityDropdownVisible(false)
              setShowQuantities(false)
              setShowColors(false);
              setAllselectedVariants([])
              setQuantityData([]);
              setSelectedColor([]);
              setCurrentVariant({});
              setSubId([])
              //  setProductList([]);
              setnewselectedcurrentcolor({});
              setProductData(
                {
                  productType: "",
                  productBrand: "",
                  productName: "",
                  productColor: "",
                  productQuantity: "",
                  productQuantityUnit: "",
                  productSubcategory: ""
                }
              )
              setFormData({
                type: "",
                brand: "",
                pname: "",
                color: "",
                quantity: "",
                qunit: "",
                desc: "",
                price: "",
                units: "",
                discount: "",
                img: "",
                service: "",
                subservice: "",
                othertype: "",
                otherbrand: "",
                otherpname: "",
                othercolor: "",
                otherquantity: "",
              })

}
  const toggleShowMore = (index) => {

    const newShowMoreColors = [...showMorecolours];
    newShowMoreColors[index] = !newShowMoreColors[index];
    setShowMorecolours(newShowMoreColors);
    console.log(showMorecolours);
  };

  const colorui = (colors) => {
    return (
      <>
        {colors.map(color => (
          color.code !== 'None' ?
            <div key={color.code} className="color-option">
              <span>{color.name} </span>
              <div className="color-box" style={{ backgroundColor: color.code }}></div>
              <input
                type="checkbox"
                checked={selectedColor.some(c => c.code === color.code)}
                onChange={(e) => {
                  toggleColor(color)
                  checkedtoggle(e, color)
                  checknoneexist();
                }}
              />
            </div>
            : ""
        ))}
      </>
    )
  }
  console.log(productList, colorItemIndexes);
  const allselectedVariantsupdation = (data) => {
    for (let i = 0; i < quantityData.length; i++) {
      if (quantityData[i].quantity == data.quantity && quantityData[i].colors.length <= 1) {
        var removingdataindex = i;
        console.log(i);
        console.log(allselectedVariants)
        allselectedVariants.splice(removingdataindex, 1);
        console.log("handle", allselectedVariants, allselectedVariants.length)
        console.log(quantityData);
      }
    }
  }

  const GetMyServices = async (index) => {
    setLoading(true);
    const selectedServiceID = userServiceIDselected ? userServiceIDselected[0] : null;
  
    try {
      const response = (stringsearchstore === "") ? await myProfileServiceApi.ServiceProviderAllProduct(
        Userprofile.service_provider_id,
        index,
        selectedServiceID
      ) : null;
  
      setLoading(false);
  
      if (response && response.data && response.data.error !== "") {
        notify("error", response.data.error);
      } else if (response && response.data.product_list.length === 0) {
        setEmptyFetch(true);
        
      } else if (response && response.data.product_list.length !== 0) {
        const newProductList = response.data.product_list;
  
        // Update productList state
        setProductList((prevProductList) => [
          ...prevProductList,
          ...newProductList,
        ]);

        // Update colorItemIndexes and showMorecolours states
        setColorItemIndexes((prevColorIndexes) => [
          ...prevColorIndexes,
          ...Array(newProductList.length).fill(0),
        ]);
  
        setShowMorecolours((prevShowMore) => [
          ...prevShowMore,
          ...Array(newProductList.length).fill(false),
        ]);
  
        SetScrollset(false);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  

  const GetMyServicesClone = async (index) => {
    setLoading(true);
    const selectedServiceID = userServiceIDselected
      ? userServiceIDselected[0]
      : null;
    const response = await myProfileServiceApi.ServiceProviderAllProduct(
      Userprofile.service_provider_id,
      index,
      selectedServiceID
    );
    setLoading(false);
    if (response.data && response.data.error !== "") {
      notify("error", response.data.error);
    } else {
      setProductList([])
      setProductList(
        response.data.product_list,
      );
      if (response.data.product_list.length > 0) {
        console.log("uindexing", productList, productList.length);
        setColorItemIndexes(Array(response.data.product_list.length).fill(0)
        );

        setShowMorecolours(Array(response.data.product_list.length).fill(false)
        );
      }


    }
  };

  useEffect(()=>{
    setStockstatus(()=>{
      return productList.map((item)=>{
        return item.qty_list.map((item1)=>{
          return item1.out_of_stock;
        })
      })
    })

  },[productList])
  const manageOutsideclick = (event) => {

    if ((dialogreeef && dialogreeef.current && dialogreeef.current.contains(event.target))) {
      console.log("insideclick")
      return;
    }

    else if ((event.target.classList[0] !== "quantity-option") && !(event.target.parentElement.classList.contains("color-option-other"))) {
      dialogreeef1.current?.classList.add("selection12")
      dropdownRefs.moreColor.current?.classList.add("selection12")
      console.log("kdkdhhdhd")
      setcolorvis(false);
      setShowColors(false);
      return
    }
    // console.log(isMobileView,event.target.parentElement.classList,event?.target.parentElement.classList.contains("color-option-other"))
  }
  function handleChange(e) {
    console.log(formData);
    const { name, value, type, checked } = e.target;
    console.log(name, value);
    if (name === "service") {
      console.log(serviceSubSerivce);
      for (let i = 0; i < serviceSubSerivce.servicelist.length; i++) {
        // console.log(serviceSubSerivce.service_list[i].id, value);
        if (value === `${serviceSubSerivce.servicelist[i].id}`) {
          console.log(serviceSubSerivce.servicelist[i]);
          setSubId(serviceSubSerivce.servicelist[i].subcategory);

          break;
        }
      }
    }
    if (!value) {
      //if user want to remove selected value and wants tooset default value
      console.log("empty value set");
      if (name === "service" || name === "subservice") {
        // if first two fields are default then reset all fields
        setFormData({
          type: "",
          brand: "",
          pname: "",
          color: "",
          quantity: "",
          qunit: "",
          desc: "",
          price: "",
          units: "",
          discount: "",
          img: "",
          service: "",
          subservice: "",
          othertype: "",
          otherbrand: "",
          otherpname: "",
          othercolorname: "",
          othercolorcode: "",
          otherquantity: "",
        });
      }
      setFormData((prevFormData) => {
        return {
          ...prevFormData,
          [name]: type === "checkbox" ? checked : value,
        };
      });
      return;
    }
    if (name === "type") {
      // Get selected type name
      const selectedTypeName = value;
      if (value === "other") {
        // set remaining fields  to other
        setFormData((prev) => ({
          ...prev,
          [name]: "other",
          brand: "other",
          pname: "other",
          color: "other",
          quantity: "other",
        }));
        return;
      }
      // Filter types array
      const selectedType = productData.productType.find(
        (t) => t.name === selectedTypeName
      );
      console.log(selectedType);

      const brands = [];
      setProductData((prevProductData) => ({
        ...prevProductData,
        productbrand: [],
      })); // set prev response clear
      if (!selectedType) {
        return [];
      }
      Object.keys(selectedType.brand).forEach((typeName) => {
        if (typeName === "pt_id") {
          return;
        }

        let type = selectedType.brand[typeName];
        console.log(typeName, type);
        brands.push({
          brandname: typeName,
          brandid: type.b_id,
          productname: type,
        });
      });
      console.log(brands);

      setProductData((prevProductData) => ({
        ...prevProductData,
        productBrand: brands,
      }));
    }

    if (name === "brand") {
      // Get selected type name
      const selectedTypeName = value;
      if (value === "other") {
        // set remaining fields  to other
        setFormData((prev) => ({
          ...prev,
          [name]: "other",
          pname: "other",
          color: "other",
          quantity: "other",
        }));
        return;
      }
      // Filter types array
      const selectedType = productData.productBrand.find(
        (t) => t.brandname === selectedTypeName
      );
      console.log(selectedType);

      const pnames = [];
      setProductData((prevProductData) => ({
        ...prevProductData,
        productName: [],
      })); // set prev response clear
      if (!selectedType) {
        return [];
      }
      Object.keys(selectedType.productname).forEach((typeName) => {
        if (typeName === "b_id") {
          return;
        }

        let type = selectedType.productname[typeName];
        console.log(typeName, type);
        pnames.push({
          pname: typeName,
          pnameid: type.p_id,
          productQuantity: type,
        });
      });
      console.log(pnames);

      setProductData((prevProductData) => ({
        ...prevProductData,
        productName: pnames,
      }));
    }

    if (name === "pname") {
      // Get selected type name
      const selectedTypeName = value;
      if (value === "other") {
        // set remaining fields  to other
        setFormData((prev) => ({
          ...prev,
          [name]: "other",
          color: "other",
          quantity: "other",
        }));
        return;
      }
      // Filter types array
      const selectedType = productData.productName.find(
        (t) => t.pname === selectedTypeName
      );
      console.log(selectedType);


      const pQuantity = [];
      setProductData((prevProductData) => ({
        ...prevProductData,
        productQuantity: [],
      }));
      // set prev response clear
      if (!selectedType) {
        return [];
      }
      Object.keys(selectedType.productQuantity).forEach((typeName) => {
        if (typeName === "p_id") {
          return;
        }

        let type = selectedType.productQuantity[typeName];
        console.log(typeName, type);
        pQuantity.push({
          pQuantity: typeName,
          productcolor: type,
        });
      });
      console.log(pQuantity);

      setProductData((prevProductData) => ({
        ...prevProductData,
        productQuantity: pQuantity,
      }));
    }

    if (name === "quantity") {
      // Get selected type name
      const selectedTypeName = value;
      if (value === "other") {
        // set remaining fields  to other
        setFormData((prev) => ({
          ...prev,
          [name]: "other",
          quantity: "other",
        }));
        return;
      }
      // Filter types array
      const selectedType = productData.productQuantity.find(
        (t) => t.pQuantity === selectedTypeName
      );
      console.log(selectedType);

      const pcolors = [];
      setProductData((prevProductData) => ({
        ...prevProductData,
        productColor: [],
      })); // set prev response clear
      if (!selectedType) {
        return [];
      }
      Object.keys(selectedType.productcolor).forEach((typeName) => {
        if (typeName === "qty_id") {
          return;
        }

        let type = selectedType.productcolor[typeName];
        console.log(typeName, type);
        pcolors.push({
          pcolorname: typeName,
          pcolornameid: type.col_id,
          productquantity: type,
        });
      });
      console.log(pcolors);

      setProductData((prevProductData) => ({
        ...prevProductData,
        productColor: pcolors,
      }));
    }

    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: type === "checkbox" ? checked : value,
      };
    });
  }
  React.useEffect(() => {
    const fetchData = async () => {
      // Check if both service and subservice are set
      if (formData.service && formData.subservice) {
        try {
          const response = await myProfileServiceApi.GetProductTypeotherdata(
            formData.service,
            formData.subservice
          );
          setProductData({
            productType: "",
            productBrand: "",
            productName: "",
            productColor: "",
            productQuantity: "",
            productQuantityUnit: "",
            productSubcategory: "",
          });
          console.log(response.data.servicelist[0].subcategory);

          setProductData((prevProductData) => ({
            ...prevProductData,
            productSubcategory: response.data.servicelist[0].subcategory,
          }));
        } catch (error) {
          console.error(error);
        }
      }
    };

    fetchData();
  }, [formData.service, formData.subservice]);

  React.useEffect(() => {
    console.log("fetched3");
    // Watch for changes in producttypedata and call fetchproductype when it changes
    if (productData.productSubcategory) {
      console.log("fetched");
      fetchproductype();
    }
  }, [productData.productSubcategory]);

  const fetchproductype = () => {
    console.log("called t");
    // Initialize an array to collect product type names
    console.log(productData.productSubcategory);
    console.log("called t2");
    for (const team in productData.productSubcategory) {
      console.log(productData.productSubcategory);
      const subcategoryname = productData.productSubcategory[team];
      console.log(subcategoryname);
      const productype = subcategoryname.product_type.map((obj) =>
        Object.keys(obj)
      );

      const products = [];

      for (let i = 0; i < subcategoryname.product_type.length; i++) {
        let typeObj = subcategoryname.product_type[i];
        console.log(typeObj);

        if (!typeObj || !Object.keys(typeObj).length) {
          return [];
        }
        Object.keys(typeObj).forEach((typeName) => {
          let type = typeObj[typeName];
          console.log(typeName, type);
          products.push({
            name: typeName,
            ptid: type.pt_id,
            brand: type,
          });
        });
      }

      console.log(products);

      console.log(productype);
      setProductData((prevProductData) => ({
        ...prevProductData,
        productType: products,
      }));

      // setProducttype(products);
    }
  };

  const buttonMessage =
    allImagePreviews ? "Image" : "Add more Images";


  React.useEffect(() => {
    if (startIndex === 0) return;
    GetMyServices(startIndex);
    // allServicesubService();
  }, [startIndex]);

  React.useEffect(() => {
    if (productsUpdate) {
      setStartIndex(0);
      GetMyServicesClone(0);
    }
setProductsUpdate(false);
  }, [productsUpdate]);

  function goBack() {
    props.return();
    setInnerPage(false);
  }

  React.useEffect(() => {
    addedServiceProvider();
    allServicesubService(); // needed to show service in dropdown initially 
    fetchcatlogservicesdata();// required to show services and subservices of provider for catlog pdf 


  }, []);

  // hidedropdown color,quantity ref
  const dropdownRefs = {
    moreColor: useRef(null),
    moreQuantity: useRef(null),
  };


  const fetchcatlogservicesdata = async () => {
    const response =
      await myProfileServiceApi.fetchAllservicecatlogdata(
        Userprofile.service_provider_id
      );
    console.log(response);
    setCatlogServicesdata(response.data);
  }





  // Add a click event listener to the document to close the dropdowns when clicking outside
  React.useEffect(() => {
    const handleClickOutside = (event) => {
      const isClickInsideColorOption = event.target.closest('.color-option');
      const isClickInsidequantityOption = event.target.closest('.more-quantity-dropdown-data');
      if (
        dropdownRefs.moreColor.current &&
        !dropdownRefs.moreColor.current.contains(event.target) && !isClickInsideColorOption && showQuantities == true
      ) {
        console.log("he");
        setShowColors(false);

      }

      if (
        dropdownRefs.moreQuantity.current &&
        !dropdownRefs.moreQuantity.current.contains(event.target) && !isClickInsidequantityOption

      ) {
        setShowQuantities(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [dropdownRefs]);

  function handleAddOtherQuantity() {
    const { otherquantity, qunit, customunit } = formData;
    const parsedQuantity = parseFloat(otherquantity);
    if (isNaN(parsedQuantity) || parsedQuantity < 0) {
      notify("error", "Invalid quantity. Please enter a non-negative number.");
      return;
    }
    let selectedUnit = qunit;

    // If the selected unit is "other", use the custom unit
    if (qunit === "other") {
      selectedUnit = customunit.trim();
      // Check if custom unit is empty
      if (selectedUnit === "") {
        notify("error", "Custom unit cannot be empty!");
        return;
      }

    }

    const newQty = {
      pQuantity: otherquantity + " " + selectedUnit,
    };

    // Check if productQuantity exists and has data
    if (productData.productQuantity && productData.productQuantity.length > 0) {
      // Check if the new quantity is not empty and does not already exist
      if (newQty.pQuantity.trim() === "") {
        notify("error", "Quantity cannot be empty!");
      } else if (
        productData.productQuantity.some((qty) => qty.pQuantity === newQty.pQuantity)
      ) {
        // Handle the case where the quantity already exists (e.g., show an error message)
        notify("error", "Quantity already exists!");
      } else {
        // Append new qty to selected
        setProductData((prevProductData) => ({
          ...prevProductData,
          productQuantity: [...prevProductData.productQuantity, newQty],
        }));
        setIsotherqty(false);

        setFormData({
          ...formData,
          otherquantity: "",
          qunit: "",
          customunit: "", // Clear custom unit after adding the quantity
        });
      }
    } else {
      // If productQuantity is empty, add the new quantity
      setProductData({
        ...productData,
        productQuantity: [newQty],
      });
      setIsotherqty(false);

      setFormData({
        ...formData,
        otherquantity: "",
        qunit: "",
        customunit: "", // Clear custom unit after adding the quantity
      });
    }
  }
  const getColorName = (hex) => {
    const colorNameResult = GetColorName(hex);
    return colorNameResult || 'Unknown';
  };
  const handleChangecolorpicker = (color) => {
    console.log(color);
    const colorNameResult = getColorName(color.hex);
    console.log(colorNameResult);
    setFormData((prevData) => ({
      ...prevData,
      othercolorcode: color.hex,
      othercolorname: colorNameResult,
    }));
  };


  const handleClickOutside = (event) => {
    if ((colorPickerRef.current && !colorPickerRef.current.contains(event.target)) || (event?.target.classList.contains("mobile-color-option-other"))) {
      setIsColorPickerVisible(false);
    }
  }
  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleAddOtherColor = () => {
    // setColoraddedother(false);
    const { othercolorname, othercolorcode } = formData;

    // Check if the color with the same name or code already exists
    const isColorAlreadyExists = colors.some(
      (color) => color.name.toLowerCase() === othercolorname.toLowerCase() || color.code === othercolorcode
    );

    if (isColorAlreadyExists) {
      // Handle the case where the color already exists (e.g., show an error message)
      notify('error', 'Color with the same name  already exists!');
    } else {
      // Update your color list
      setColors((prevColors) => [...prevColors, { name: othercolorname, code: othercolorcode }]);

      // Reset form data
      setFormData((prevData) => ({
        ...prevData,
        othercolorcode: '',
        othercolorname: '',
      }));

      // Toggle visibility of the color picker
      setIsothercolor(false);
    }
  };


  const checknoneexist = () => {
    let selectedcolor2;
    if (selectedColor[0].name == "None" && selectedColor.length > 1) {
      console.log("kdkdkwwe");
      selectedcolor2 = selectedColor.filter((e) => {
        return e.name != "None";
      })
      setSelectedColor([...selectedcolor2]);
      console.log(selectedcolor2);
    }
  }
  const toggleColor = (color) => {
    if (Object.entries(currentVariant).length === 0) {
      notify("error", "Quantity cannot be empty!");
      return;
    }
    console.log(color);
    setCurrentSelectedColor(color);
    setnewselectedcurrentcolor(color);
    console.log(newselectedcurrentcolor);

    // apply filter to avoid the increment in length of the color even for the same selected color
    const add = selectedColor.filter((previous_color) => {
      console.log(previous_color.name, color.name, previous_color.name !== color.name)
      return previous_color.name !== color.name;
    })

    setSelectedColor([...add, color]);
    // setSelectedColor([...selectedColor,color])
    // console.log(selectedColor)
    console.log(color, selectedColor);
    console.log("kdkd", currentVariant);
    setCurrentVariant(
      (prevVariant) => {
        const existingColorIndex = prevVariant.colors.findIndex((existingColor) => {
          return existingColor.color === color.name
        }
          // (existingColor) => existingColor.name === color.name
        );
        console.log("hel00l", prevVariant, existingColorIndex)
        if (existingColorIndex !== -1) {
          // Color already exists, update it
          prevVariant.colors[existingColorIndex] = {
            ...prevVariant.colors[existingColorIndex],
            color: color.name,
            color_code: color.code,
            price: '',
            quantity_sell: '',
            discount: '',
            images: [],
          };
        } else {
          // Add a new color to the list
          prevVariant.colors.push({
            color: color.name,
            color_code: color.code,
            price: '',
            quantity_sell: '',
            discount: '',
            images: [],
          });

          // If both quantity and color are selected, add a new row
          if (prevVariant.quantity && prevVariant.colors.length > 0) {
            handleAddRow();
            setShowTable(true);
          }
        }

        let selectedcolor2;
        if (selectedColor.length > 0 && selectedColor[0].name == "None") {
          selectedcolor2 = selectedColor.filter((e) => {
            return e.name != "None";
          })
          selectedcolor2 = color;
          setSelectedColor([selectedcolor2]);

          let colorsafternone = currentVariant.colors.filter((e) => {
            return e.color != "None";
          })
          setCurrentVariant({ colors: colorsafternone, quantity: currentVariant.quantity })
          handleDeleteRow(currentVariant.quantity, 0);
        }
        return { ...prevVariant };
      });

  }

  const checkedtoggle = (e, color1) => {
    if (e.target.checked == false) {
      let checkedindex = currentVariant.colors.findIndex((elem) => {
        console.log(color1);
        return color1.name == elem.color;
      })

      console.log(checkedindex);

      let colors = currentVariant.colors.filter((eleme1) => {
        return color1.name !== eleme1.color
      })

      setCurrentVariant({
        colors,
        "quantity": currentVariant.quantity
      });
      handleDeleteRow(currentVariant.quantity, checkedindex);

      let selectedcolornotremoved = selectedColor.filter((elem) => {
        return color1.name !== elem.name
      })
      setSelectedColor(selectedcolornotremoved);
      if (color1.name != "None") {
        allselectedVariantsupdation(currentVariant);
      }
      if (selectedcolornotremoved.length == 0) {
        setAllselectedVariants(allselectedVariants.filter((el) => {
          return el.quantity != currentVariant.quantity;
        }))
        setCurrentVariant({});
      }
    }
  }

  // Update the currentSelectedColor when selectedColor changes
  React.useEffect(() => {
    if (selectedColor && selectedColor.length > 0) {
      setCurrentSelectedColor(selectedColor[0]);
      // setCurrentSelectedColor();
      // console.log(selectedColor);
      // Check if both quantity and colors are selected, then add the variant
      // if (newVariant.quantity && newVariant.colors.length > 0) {

      //   // Check if both quantity and colors are selected to show the table
      //   console.log("called table and addvariant");
      //   setShowTable(true);


      // }
    } else {
      setCurrentSelectedColor(null);
    }
  }, [selectedColor]);

  React.useEffect(() => {
    document.addEventListener("click", manageOutsideclick);

    return () => {
      document.removeEventListener("click", manageOutsideclick);
    }
  }, []);

  // Update currentSelectedColor when the user clicks a different color in the dropdown
  const handleColorClick = (color) => {
    console.log(color);
    setCurrentSelectedColor(color);

  };

  const handleNoColorClick = (e) => {
    // Clear the selected colors
    setSelectedColor([]);
    setCurrentVariant((prevVariant) => {
      // Assuming colors is an array within your variant object
      prevVariant.colors = [
        {
          color: 'None', // Set the color to whatever you use to represent "No Colour"
          color_code: 'None', // Set the color code to an appropriate value
          price: '',
          quantity_sell: '',
          discount: '',
          images: [],
        },
      ];
      let noneset = false;
      const add = selectedColor.filter((previous_color) => {
        noneset = true;
        return previous_color.name !== "None";
      })
      // console.log(add);                        
      setSelectedColor([{ code: prevVariant.colors[0].color_code, name: prevVariant.colors[0].color }])
      // console.log(currentVariant);
      const selectedupdate = quantityData.filter((data) => {
        return data.quantity !== currentVariant.quantity
      })
      setQuantityData([...selectedupdate, currentVariant]);
      // setSelectedColor(); 
      // If both quantity and color are selected, add a new row   
      // console.log(e.target.checked != true,e.target.checked)
      if (prevVariant.quantity && prevVariant.colors.length > 0 && !noneset) {
        handleAddRow(); // You need to implement this function
        setShowTable(true);
      }
      else {

      }
      console.log({ ...prevVariant })
      return { ...prevVariant };
    });
  };

  const handleInputChange = (quantity, colorIndex, field, value) => {
    const parsedValue =
      ["price", "discount", "quantity_sell"].includes(field) && value !== ""
        ? parseInt(value, 10)
        : value;
    console.log(quantity, colorIndex, field, value);

    setQuantityData((prevData) => {
      console.log(prevData);
      // Map over the previous data to create a new array with updated values
      const updatedData = prevData.map((quantityItem, index) => {
        // Check if the current item in the array matches the specified quantity
        if (quantity === quantityItem.quantity) {
          console.log(index, colorIndex);
          // If it matches, create a new object with the updated colors array
          const updatedColors = quantityItem.colors.map((color, colorIdx) => {
            // Check if the current color index matches the specified colorIndex
            console.log(colorIdx, colorIndex);
            // setCurrentVariant(quantityData);
            if (colorIdx === colorIndex) {
              // If it matches, create a new object with the updated field and its value
              return {
                ...color,
                [field]: parsedValue,
              };
            }
            // If it doesn't match, return the color unchanged
            return color;
          });

          // Return the quantityItem with the updated colors array
          const selectedcolor12index1 = quantityData.findIndex((elemen) => {
            return quantity == elemen.quantity;
          })
          setCurrentVariant({ colors: updatedColors, quantity: quantityData[selectedcolor12index1].quantity });
          // console.log(updatedColors)
          return {
            ...quantityItem,
            colors: updatedColors,
          };
        }
        // If it doesn't match, return the quantityItem unchanged
        return quantityItem;
      });

      // Return the updated array to set it as the new state
      return updatedData;
    });


    console.log(quantityData);
  };
  const handleDeleteRow = (quantity, colorIndex) => {
    setQuantityData((prevData) =>
      prevData.map((quantityItem) =>
        quantity === quantityItem.quantity
          ? {
            ...quantityItem,
            colors: quantityItem.colors.filter((_, i) => i !== colorIndex),
          }
          : quantityItem
      ).filter((quantityItem) => quantityItem.colors.length > 0)// Remove entries with empty colors array
    );

  };

  const handleImageUpload = async (quantity, colorIndex, event) => {
    console.log(quantity, colorIndex);
    console.log("upload  hitted");
    const files = event.target.files;

    // Check if the total number of images exceeds the limit (4)
    if (files.length > 4) {
      notify("error", "You can only select up to 4 images.");
      event.target.value = null; // Clear all images
      return;
    }


    try {
      setIsUploadingImage(!isUploadingImage);
      // Use the API to upload all images
      const response = await postapi.UploadImageOGCforADDproduct(files);
      const imageUrl = response.data.url;
      console.log(imageUrl);
      setCurrentVariant((prevData) =>
        true ? {
          ...prevData,
          colors: prevData.colors.map((color, i) =>
            i === colorIndex ? {
              ...color,
              // Check if there are existing images and limit to 4
              images: color.images
                ? [...color.images.slice(0, 4), ...imageUrl]
                : imageUrl,
            } : color
          )
        } : prevData
      )
      console.log(currentVariant);
      setQuantityData((prevData) =>
        prevData.map((quantityItem) =>
          quantity === quantityItem.quantity
            ? {
              ...quantityItem,
              colors: quantityItem.colors.map((color, i) =>
                i === colorIndex ? {
                  ...color,
                  // Check if there are existing images and limit to 4
                  images: color.images
                    ? [...color.images.slice(0, 4), ...imageUrl]
                    : imageUrl,
                } : color
              ),
            }
            : quantityItem
        )
      );
      setIsUploadingImage(false);

    } catch (error) {
      console.error("Error uploading images:", error);
    }
  };


  
  const removeImage = (imageIndex, colorIndex) => {
    setQuantityData((prevQuantityData) => {
      // Create a deep copy of the previous state
      const newQuantityData = JSON.parse(JSON.stringify(prevQuantityData));

      // Find the corresponding quantity and color
      const quantityItem = newQuantityData.find(
        (quantityItem) => colorIndex.quantity === quantityItem.quantity
      );

      if (quantityItem) {
        const color = quantityItem.colors[colorIndex.colorIndex];
        console.log(color);
        // Check if the color and images array exist
        if (color && color.images) {
          // Remove the specified image
          color.images.splice(imageIndex, 1);
          setAllImagePreviews(color.images)
          // Update the state with the modified data
          return newQuantityData;
        }
      }

      // Return the previous state if no modifications were made
      return prevQuantityData;
    });

  };

  const handleRemoveImage = (imageIndex, colorIndex) => {
    console.log(colorIndex, imageIndex);
    removeImage(imageIndex, colorIndex,);
  };
  const handlePreviewClick = (quantity, colorIndex) => {
    const selectedRow = quantityData.find(
      (quantityItem) => quantity === quantityItem.quantity
    );
    if (selectedRow) {
      const imagesArray = selectedRow.colors[colorIndex]?.images || [];

      // Extract the images array and set it in allImagePreviews
      setAllImagePreviews(imagesArray);
      setColorIndexDelete({ colorIndex, quantity: selectedRow.quantity })
      setIsModalOpen(true);
    }

  }

  const handleAddRow = () => {
    if (currentVariant.quantity && currentVariant.colors.length > 0) {
      // Check if a row with the same quantity and colors already exists
      const existingRowIndex = quantityData.findIndex(
        (row) => {
          // this function helps to avoid the issue of double selecting of color for selected quantity
          const contains = row.colors.some(element => {
            console.log(element, row.colors, row.colors.includes(element));
            return row.colors.includes(element);
          });
          return row.quantity === currentVariant.quantity && contains;
        }
      );

      if (existingRowIndex !== -1) {
        // Update the existing row
        setQuantityData((prevData) => {
          const updatedData = [...prevData];
          updatedData[existingRowIndex].colors = currentVariant.colors;
          return updatedData;
        });
      } else {
        // Add a new row to the quantityData state
        setQuantityData((prevData) => [...prevData, { ...currentVariant }]);
      }
    }
  };

  const predefinedselect = (quantity = "1 unit") => {
    let predefinedstatus = false;
    predefinedstatus = allselectedVariants.some((e) => {
      return quantity.pQuantity === e.quantity;
    })

    if (!predefinedstatus) {
      const newVariant1 = { quantity: quantity.pQuantity, colors: [] };

      setAllselectedVariants((prevVariants) => [...prevVariants, newVariant1]);
      setSelectedColor([]);
      setCurrentVariant((prevVariant) => {
        prevVariant.colors = [
          {
            color: 'None', // Set the color to whatever you use to represent "No Colour"
            color_code: 'None', // Set the color code to an appropriate value
            price: '',
            quantity_sell: '',
            discount: '',
            images: [],
          },
        ]
        prevVariant.quantity = quantity.pQuantity;

        if (prevVariant.quantity && prevVariant.colors.length > 0) {
          handleAddRow(); // You need to implement this function
          setSelectedColor([{ code: prevVariant.colors[0].color_code, name: prevVariant.colors[0].color }])
          setShowTable(true);
        }
        return { ...prevVariant };
      });
    }
    else {
      toggleQuantity(quantity)
    }
    setShowQuantities(false);
  }

  const toggleQuantity = (quantity) => {

    setCurrentVariant({
      quantity: '',
      colors: [],
    });
    console.log(quantity, quantity.pQuantity, currentVariant.quantity);
    if (currentVariant && currentVariant.quantity === quantity.pQuantity) {


      // The same quantity is selected again, clear color selection
      // setCurrentVariant({
      //   quantity: '',
      //   colors: [],
      // });
      // setSelectedColor([]);


      setCurrentVariant({ ...currentVariant, quantity: quantity.pQuantity });
      // Find the existing variant for the selected quantity
      const existingVariant = allselectedVariants.find(
        (variant) => variant.quantity === quantity.pQuantity
      );
    } else {
      setCurrentVariant({ ...currentVariant, quantity: quantity.pQuantity });
      // Find the existing variant for the selected quantity
      const existingVariant = allselectedVariants.find(
        (variant) => variant.quantity === quantity.pQuantity
      );

      // const inddex = quantityData.findIndex(
      //   (variant) => variant.quantity === quantity.pQuantity
      // );
      // const inddex1 = quantityData.findIndex(
      //   (variant) => variant.quantity === currentVariant.quantity
      // );

      // console.log(inddex,inddex1);

      if (existingVariant) {
        console.log("matched");
        // If an existing variant is found, set its colors as selected colors
        const selectedcolor12 = quantityData.findIndex((elemen) => {
          return existingVariant.quantity == elemen.quantity;
        })
        // console.log(selectedcolor12);
        console.log(quantityData[selectedcolor12].colors)

        let colorselecteddd = [];
        let obje = {};
        for (let i = 0; i < quantityData[selectedcolor12].colors.length; i++) {
          obje = {
            name: quantityData[selectedcolor12].colors[i].color,
            code: quantityData[selectedcolor12].colors[i].color_code
          }
          colorselecteddd.push(obje)
        }
        setSelectedColor(colorselecteddd)
        // setSelectedColor(existingVariant.colors);
        setCurrentVariant({ colors: quantityData[selectedcolor12].colors, quantity: quantityData[selectedcolor12].quantity });
        // setCurrentVariant({colors:[],quantity:quantityData[selectedcolor12].quantity});


      } else {
        // A new quantity is selected
        console.log("unmatched");
        const previouss_variant = currentVariant;
        if ((quantityData.length != 0 || allselectedVariants.length == 1) && previouss_variant.colors.length == 0) {
          setSelectedColor([]);

          setCurrentVariant((prevVariant) => {
            // Assuming colors is an array within your variant object
            prevVariant.colors = [
              {
                color: 'None', // Set the color to whatever you use to represent "No Colour"
                color_code: 'None', // Set the color code to an appropriate value
                price: '',
                quantity_sell: '',
                discount: '',
                images: [],
              },
            ];
            let abb = { colors: prevVariant.colors, quantity: previouss_variant.quantity };
            setCurrentVariant(abb);
            if (currentVariant.quantity != "") {
              setQuantityData([...quantityData, abb])
            }
            setShowTable(true);
          }
          )
        }
        else {
          // If no existing variant is found, create a new variant with the selected quantity
          const newVariant = { quantity: quantity.pQuantity, colors: [] };
          setAllselectedVariants((prevVariants) => [...prevVariants, newVariant]);
          setSelectedColor([]);
          setCurrentVariant({ colors: [], quantity: quantity.pQuantity });
        }
      }
    }

  }



  const handleviewcatlog = async (service_id, product_type_id, sub_service_id) => {
    const response = await profileapi.downloadCatlog(
      Userprofile.service_provider_id,
      service_id,
      product_type_id,
      sub_service_id
    );
    if (response.data && response.data.error !== "") {
      notify("error", response.data.error);
    } else {
      setCatalogProducts(response.data)
      setShowcatlogmodal(false);
    }

  }




  const [serviceProvider, setServiceProvider] = React.useState();
  const addedServiceProvider = async () => {
    const response = await profileapi.MyAddedServiceprovider();
    console.log(response);
    setServiceProvider(response.data);
  };

  const [serviceSubSerivce, setServiceSubSerivce] = React.useState();
  const allServicesubService = async () => {
    const response =
      await myProfileServiceApi.Showsubserviceasperserviceprovider(
        Userprofile.service_provider_id
      );
    console.log(response);
    setServiceSubSerivce(response.data);
  };

  function validateFormData(formData) {
    const requiredFields = [
      { key: "service", message: "Select a Product Category" },
      { key: "subservice", message: "Select a Product Sub-Category" },
      { key: "type", message: "Enter Product Type" },
      { key: "brand", message: "Enter Product Brand" },
      { key: "pname", message: "Enter Product Name" },

    ];

    for (const field of requiredFields) {
      if (!formData[field.key]) {
        return field.message;
      }
    }

    return "";
  }

  const handleButtonClick = (quantity, colorIndex) => {
console.log(fileInputRef);
    console.log(quantity,colorIndex);

    // Trigger the hidden file input for the specific row when the button is clicked
    fileInputRef.current[`${quantity}-${colorIndex}`].click();
    // setCurrentVariant({ colors:updatedColors, quantity: quantityData[selectedcolor12index2].quantity }); 

  };

  const AddProduct = async () => {
    const errorMessage = validateFormData(formData);

    if (errorMessage) {
      notify("error", errorMessage);
      return;
    }
    for (const [dataIndex, data] of quantityData.entries()) {
      for (const [colorIndex, color] of data.colors.entries()) {
        // Check if required fields are filled
        if (
          color.price === "" ||
          color.quantity_sell === "" ||
          color.discount === ""
        ) {
          notify(
            "error",
            `Please fill in all required fields for Quantity ${data.quantity}, Color ${colorIndex + 1}.`
          );
          return false; // Validation failed
        }

        // // If color is "none," image can be empty
        // if (color.color !== "None" && color.images.length===0) {
        //   notify("error", `Insert image for Quantity ${data.quantity}, Color ${color.color}.`);
        //   return; // Validation failed
        // }
      }
    }

    setIsAddingProduct(true);

    const payload = {
      service: parseInt(formData.service),
      subservice: parseInt(formData.subservice),
      type: formData.type,
      brand: formData.brand,
      pname: formData.pname,
      desc: formData.desc,
    };

    // Check if user selected "Other" for a specific field and update the payload accordingly
    const otherFields = ["type", "brand", "pname", "color", "quantity"];
    otherFields.forEach((fieldName) => {
      if (formData[fieldName] === "other") {
        payload[fieldName] = formData[`other${fieldName}`];
      }

    });

    console.log(payload);
    const response = await myProfileServiceApi.RegisterProduct(
      payload.service,
      payload.subservice,
      payload.type,
      payload.brand,
      payload.pname,
      payload.desc,
      "",
      quantityData
    );
    console.log(response);
    if (response.data && response.data.error !== "") {
      notify("error", response.data.error);
      setIsAddingProduct(false);
    } else {
      // Set all formData fields to empty strings
      const updatedFormData = {};
      for (const key in formData) {
        updatedFormData[key] = "";
      }
      setFormData(updatedFormData);
      setQuantityData([])
      setShowTable(false)
      setProductsUpdate((prevState) => !prevState);
      setIsAddingProduct(false);
      notify("success", "Product Registered Successfully");
      setPage(1);
      cleanup();
    }
  };

  const handleDisplayPriceQuantityChange = (index, e) => {
    const value = e.target.value;
    setDisplayPriceQuantityMap((prevMap) => ({
      ...prevMap,
      [index]: value,
    }));
    setQuantityIndex(value);
    setColorItemIndexes((prevColorItemIndexes) => {
      const newColorItemIndexes = [...prevColorItemIndexes];
      newColorItemIndexes[index] = 0; // Set colorItemIndex to 0 for the specific index
      return newColorItemIndexes;
    });
  };



  const observer = React.useRef();
  const markerRef = React.useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && emptyFetch === false) {
          SetScrollset(true);
          setStartIndex((prevStartIndex) => prevStartIndex + 18);
        }
        console.log(entries, entries[0]);
        // SetScrollset(false);
        if (emptyFetch) setLoading(false);
      });
      if (node) observer.current.observe(node);
    },
    [setStartIndex, emptyFetch]
  );
  const handleDeleteProduct = async (selectedQuantity, item, wholeProduct) => {
    console.log(item);
    console.log(selectedQuantity, wholeProduct);
    // Create separate arrays for productd and colorId
    const productIds = selectedQuantity.map((item) => item.quantityId);
    // Filter out items where colorId is not null
    const colorIds = selectedQuantity.map(item => item.colorIds).flat().filter(arr => arr !== null);


    console.log(productIds, colorIds);

    const { product_id, service_id, service_provider_id } = item;
    if (!wholeProduct && selectedQuantity.length === 0) {
      notify("error", "Please select a quantity ");
      return;
    } else {
      const response = await profileapi.DeleteMyProduct(
        service_id,
        service_provider_id,
        productIds,
        colorIds,
        wholeProduct
      );
      console.log(response);
      if (response.data && response.data.error !== "") {
        notify("error", response.data.error);
      } else {
        notify("success", "Product Deleted Successfully");
        setShowQuantitySelection(false);
        setSelectedProductId(null);
        setProductList([]);
        setStartIndex(0);
        GetMyServices(0);
      }
    }
    // console.log(wholeProduct);
  };




  const handleModifyClick = (product, colorindex, quantityindex) => {
    setModifyModal((prevState) => !prevState);
    setModalProduct(product);
    setModalProductquantityIndex(quantityindex);
    setModalProductcolorIndex(colorindex)
  };
const dropdownsfunction = () =>{
  dropdownRefsarray.push(dropdownRef)
  console.log(dropdownRefsarray)
}
  React.useEffect(() => {
    let searchtimeout;
    console.log(stringsearchstore, stringsearchstore.length);
    const searchProduct = async () => {
      if (stringsearchstore.length > 0) {
        try {
          setLoading(true);
          setApicalling(true);
    
          const response = await myProfileServiceApi.SearchProductApi(stringsearchstore);
    
          if (response.data.product_list.length !== 0) {
            setProductList(response.data.product_list);
    
            // Update colorItemIndexes and showMorecolours
            setColorItemIndexes(Array(response.data.product_list.length).fill(0));
            setShowMorecolours(Array(response.data.product_list.length).fill(false));
          } else {
            setProductList([]);
            setColorItemIndexes([]);
            setShowMorecolours([]);
          }
    
          setLoading(false);
          setApicalling(false);
        } catch (err) {
          setLoading(false);
          setApicalling(false);
          notify("error", "Error while searching");
        }
      } else {
        setProductList([]);
        setColorItemIndexes([]);
        setShowMorecolours([]);
      }
    };
    if (stringsearchstore.length === 0) {
setProductList([])
      setStartIndex(0);
      GetMyServices(0);

    }
    // set the timeout for calling the api
    searchtimeout = setTimeout(() => {
      if (stringsearchstore && stringsearchstore !== "") {
        searchProduct();
      }
    }, 700);

    // clear the timeout when components are rerender or remove
    return () => {
      if (searchtimeout) {
        clearTimeout(searchtimeout);
      }
    };
  }, [stringsearchstore])
  // const countstate=useRef(0);
  const handleKeyPresskeydownkeyup = (event) => {
    console.log(!scrollset, emptyFetch);
    if (!scrollset || emptyFetch || stringsearchstore !== "") {
      let clickEvent = new Event('onKeyDown');
      myElementRef.current.dispatchEvent(clickEvent);
      console.log(clickEvent);
    }
    else if (event.type === 'keydown' && scrollset) {
      event.preventDefault();
    }
  }
  return (
    <React.Fragment>
      <>
        {page === 1 && (
          <div className="manage-services-profile-service-main-page">
            {modifyModal && (
              <div className="overlay">
                <ManageProductModal
                  product={modalProduct}
                  quantityIndex={modalProductQuantityIndex}
                  colorIndex={modalProductColorIndex}
                />
              </div>
            )}
            <section className="manageProductActions">
              <div className="my-service-profile-back-and-count">
                <img src={back} onClick={goBack} alt="backIcon" />
                <div className="manage-services-profile-service-main-page-main-box-before">
                  {productList && (
                    <>
                      Total <span>{productList.length}</span> Product(s)
                    </>
                  )}
                </div>
              </div>
              <div className="manage-services-profile-service-main-page-add-service">
                <button
                  style={{ marginRight: "10px" }}
                  onClick={() => {
                    setPage(2);
                  }}
                >
                  Add New Product
                </button>
                <div className="my-order-profile-dropdown">

                  <div className="ellipsis" onClick={() => setShowcatlogmodal(!showcatlogmodal)}>
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                  </div>
                </div>
              </div>

              {
                showcatlogmodal && <ViewCatalogModal onCancel={() => setShowcatlogmodal(false)} services={catalogServicesdata.details} onOK={handleviewcatlog} />
              }
              {
                catalogProducts && Object.keys(catalogProducts).length > 0 && <CatlogPdfViewer data={catalogProducts} onCancel={() => setCatalogProducts([])} spname={Userprofile} />
              }
            </section>
            <div className="manageProductSearchBox">
              <input
                type="text"
                placeholder="Enter your product here"
                onChange={(e) => {
                  searchString = e.target.value;
                  setStringsearchstore(searchString)
                }}
              />
              <IoIosSend className="sendIcon" />
            </div>
            {
              <div className="manage-services-profile-service-main-page-main-box" ref={myElementRef} onKeyDown={handleKeyPresskeydownkeyup} tabIndex="0">
                {productList && productList.length !== 0 && colorItemIndexes && colorItemIndexes.length > 0 
                  ? productList.map((item, index) => {
                    const selectedQtyIndex =
                      displayPriceQuantityMap[index] || 0;
                    const selectedQty = item.qty_list[selectedQtyIndex];
                    const showColorInfo =
                      selectedQty.color &&
                      selectedQty.color.length > 0 &&
                      Object.keys(selectedQty.color[colorItemIndexes[index]] || {}).length > 0;
                    // console.log(selectedQty.images, selectedQty, index, colorItemIndexes, colorItemIndexes[index], selectedQty.color[colorItemIndexes[index]], showColorInfo, Object.keys(selectedQty.color[colorItemIndexes[index]] || {}), Object.keys(selectedQty.color[colorItemIndexes[index]] || {}).length);
                    return (
                      <>
                        <div
                          key={index}
                          className="manage-products-profile-serivce-main-page-main-box-card"
                        >
                          <div className="manage-products-profile-serivce-main-page-main-box-card-image">

                            {
                              showColorInfo &&
                                selectedQty.color &&
                                selectedQty.color.length > 0 &&
                                selectedQty.color[colorItemIndexes[index]].image.length > 0 ? <img src={selectedQty.color[colorItemIndexes[index]].image[0].image} alt=""></img> :

                                <img
                                  src={selectedQty?.images[0]?.image}
                                  alt=""
                                />
                            }
                          </div>
                          <div className="manage-products-profile-serivce-main-page-main-box-card-names">
                            <div className="product_brand">{item.brand}</div>
                            <span className="product_name">
                              {item.product_name}
                            </span>
                            <section className="product_type">
                              {item.producttype}
                            </section>
                            {!showColorInfo 
  ? !selectedQty.out_of_stock 
    ? <span style={{ color: "#00838f" }}>In Stock</span> 
    : <span style={{ color: "red", maxWidth: "100px" }}>Out Of Stock</span>
  : !selectedQty.color[colorItemIndexes[index]].out_of_stock 
    ? <span style={{ color: "#00838f" }}>In Stock</span> 
    : <span style={{ color: "red", maxWidth: "100px" }}>Out Of Stock</span>
}
                          </div>
                          <div className="manage-products-profile-serivce-main-page-main-box-card-quantity">
                            <div>
                              <span>Qty :&nbsp;</span>
                              <select
                                onChange={(e) =>
                                  handleDisplayPriceQuantityChange(index, e)
                                }
                                value={selectedQtyIndex}
                              >
                                {item.qty_list &&
                                  item.qty_list.length > 0 &&
                                  item.qty_list.map((item2, index) => {
                                    return (
                                      <option key={index} value={index}>
                                        {item2.quantity}
                                      </option>
                                    );
                                  })}
                              </select>
                            </div>
                            <div>
                              <span>Units :&nbsp;</span>
                              <select>
                                {showColorInfo
                                  ? displayPriceUnitValue.map((item2, ind) => (
                                    <option key={ind} value={item2}>
                                      {item2 * selectedQty.color[colorItemIndexes[index]].unit}
                                    </option>
                                  ))
                                  : displayPriceUnitValue &&
                                  displayPriceUnitValue.map((item2, index) => (
                                    <option key={index} value={item2}>
                                      {item2 * selectedQty.units}
                                    </option>
                                  ))}
                              </select>

                            </div>
                            <div class="service-color-choices service-color-left-view">
                              {showColorInfo && selectedQty.color && selectedQty.color.length > 0 && (
                                <>
                                  <span>Color:</span>
                                  <div className="initial-choice service-color-choice" style={{
                                    backgroundColor:
                                      selectedQty.color[colorItemIndexes[index]].color_code,
                                  }}
                                  >
                                  </div>
                                </>
                              )}
                              {showColorInfo && selectedQty.color && selectedQty.color.length > 1 && <span forcheckingonly="arrow" ref={dropdownRef} onClick={() => 
                              {
                              toggleShowMore(index)
                              showcolorcloseindex.current=index;
                              }
                                }
                                >{"+" + selectedQty.color.length + " "} <IoIosArrowDown forcheckingonly="arrow" size={16} fontWeight={600} />
                                
                                {/* {
                                  dropdownsfunction()
                                } */}
                                </span>
                                }
                                
                              {showMorecolours[index] && selectedQty.color.length > 1 && (
                                <div className="additional-choices-container">
                                  {selectedQty.color.map((color, colorindex) => (
                                    <div
                                      className={`additional-choice service-color-choice ${colorItemIndexes[index] === colorindex ? "initial-choice" : ""
                                        }`}

                                      style={{ backgroundColor: color.color_code }}
                                      onClick={() => handleChangeColor(index, colorindex)}
                                    >
                                      {/* {color.color_name} */}
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>





                          </div>
                          <div className="manage-products-profile-serivce-main-page-main-box-card-price">
                            <div>{showColorInfo ? selectedQty.color[colorItemIndexes[index]].discount : selectedQty && selectedQty.discount} % Off</div>
                            <span>
                              &#8377;
                              {showColorInfo ? selectedQty.color[colorItemIndexes[index]].actual_price : selectedQty && selectedQty.actual_price}
                            </span>
                            <section>
                              &#8377;
                              {showColorInfo ? selectedQty.color[colorItemIndexes[index]].selling_price : selectedQty && selectedQty.selling_price}
                            </section>
                          </div>
                      
                          <div className="manage-products-profile-serivce-main-page-main-box-card-buttons">
                            <motion.div whileHover={{ color: "black" }}>
                              <AiFillEdit
                                size={30}
                                onClick={() => handleModifyClick(item, colorItemIndexes[index], selectedQtyIndex)}
                              />
                            </motion.div>
                            <motion.div
                              whileHover={{ color: "black" }}
                              onClick={() => {
                                setSelectedProductId(index);
                                setShowConfirmation(true);
                                setShowQuantitySelection(false);
                              }}
                            >
                              <BiSolidTrashAlt size={30} />
                            </motion.div>
                          </div>
                    <div className="outofstockblock">
                          <div
                  className="ouutofstockbutton"
                  onClick={()=>{
                    setOutofstockmodalstate(true);
                    setSelectedProductId(index);
                  }}
                >
                  Stock Status
                </div>
                </div>
                {
                  outofstockmodalstate &&
                  selectedProductId === index && <><QuantitySelectionModalforoutofstock
                  id={index}
                  funcforstockstatus={setStockstatus}
                  outofstockstatus={stockstatus[index]}
                  selectedItem={item}
                  getmyseervice={()=>{
                    GetMyServicesClone(0);
                  }}
                              onOK={(
                                selectedQuantity,
                                selectedItem,
                                wholeProduct
                              ) => {
                                console.log(selectedQuantity);
                                // handleDeleteProduct(
                                //   selectedQuantity,
                                //   item,
                                //   wholeProduct
                                // );
                              }}
                              onCancel={() => setOutofstockmodalstate(false)}
                              
                   ></QuantitySelectionModalforoutofstock></>
                }
                        </div>
                        {index === productList.length - 1 && (
                          <div
                            className="marker"
                            style={{ minHeight: "200px" }}
                            ref={markerRef}
                          >
                            {loading ? (
                              <Loading />
                            ) : (
                              <div className="allfetched">
                                "That's all we have"
                              </div>
                            )}
                          </div>
                        )}
                        {showConfirmation && selectedProductId === index && (
                          <ConfirmationModal
                            onCancel={() => setSelectedProductId(null)}
                            onConfirm={() => {
                              setShowConfirmation(false);
                              setShowQuantitySelection(true);
                            }}
                          />
                        )}
                        {showQuantitySelection &&
                          selectedProductId === index && (
                            <QuantitySelectionModal
                              selectedItem={item}
                              onOK={(
                                selectedQuantity,
                                selectedItem,
                                wholeProduct
                              ) => {
                                console.log(selectedQuantity);
                                handleDeleteProduct(
                                  selectedQuantity,
                                  item,
                                  wholeProduct
                                );
                              }}
                              onCancel={() => setShowQuantitySelection(false)}
                            />
                          )}

                      </>
                    );
                  })
                  : ((loading && stringsearchstore === "") || (loading && stringsearchstore !== "" && apicalling)) ? <Loading /> : <p className="noresult">No results to display
                  </p>
                }
              </div>}
          </div>
        )}
        {page === 2 && (
          <div className="manage-services-profile-service-main-page ">
            <div className="my-service-profile-ongoing-and-pending-online-back" onClick={cleanup}>
              <img
                src={back}
                onClick={() => {
                  setPage(1);
                }}
                alt=""
              />
            </div>
            <div className="manage-products-profile-service-main-page-inputs manage-products-profile-inputs-align">
              <secion className="firstSecion">
                <div className="add-service-provider-profile-right-inputs add-new-product-inputs ">
                  <div>
                    Product Category{" "}
                    <div className="job-application-field-required">*</div>
                  </div>

                  <select
                    name="service"
                    placeholder="Enter your service"
                    value={formData.service}
                    onChange={handleChange}
                  >
                    <option value="">Select Your Category</option>
                    {serviceSubSerivce &&
                      serviceSubSerivce.servicelist &&
                      serviceSubSerivce.servicelist.map((item) => {
                        console.log(item);
                        return <option value={item.id}>{item.service}</option>;
                      })}
                  </select>
                </div>

                {formData.service !== "" && <div className="add-service-provider-profile-right-inputs add-new-product-inputs">
                  <div>
                    Product Sub-Category
                    <div className="job-application-field-required"> *</div>
                  </div>
                  <select
                    name="subservice"
                    placeholder="Enter your subservice"
                    value={formData.subservice}
                    onChange={handleChange}
                  >
                    <option value="">Select Your Sub-Category</option>
                    {subId &&
                      Object.keys(subId).map((key) => {
                        const item = subId[key];

                        return (
                          <option key={key} value={item.sb_id}>
                            {key}
                          </option>
                        );
                      })}
                  </select>
                </div>
                }
                {formData.subservice !== "" && <div className="add-service-provider-profile-right-inputs add-new-product-inputs">
                  <div>
                    Product Type
                    <div className="job-application-field-required">
                      {" "}
                      *
                    </div>{" "}
                  </div>

                  <select
                    name="type"
                    value={formData.type}
                    onChange={handleChange}
                  >
                    <option value="">Select Product Type</option>
                    {productData.productSubcategory && (
                      <>
                        {console.log(productData.productType)}
                        {productData.productType &&
                          productData.productType.map((item, index) => (
                            <option key={index} value={item.name}>
                              {item.name}
                            </option>
                          ))}
                      </>
                    )}
                    <option value="other">Other</option>
                  </select>
                </div>
                }
                {formData.type === "other" && (
                  <div className="add-service-provider-profile-right-inputs add-new-product-inputs-other">
                    <input
                      type="text"
                      name="othertype"
                      placeholder="Enter the product type"
                      value={formData.othertype}
                      onChange={handleChange}
                    />
                  </div>
                )}
                {formData.type && <div className="add-service-provider-profile-right-inputs add-new-product-inputs">
                  <div>
                    Product Brand{" "}
                    <div className="job-application-field-required">*</div>
                  </div>
                  <select
                    name="brand"
                    value={formData.brand}
                    onChange={handleChange}
                  >
                    <option value="">Select the Product Brand</option>
                    {productData.productType.length > 0 && (
                      <>
                        {console.log(
                          productData.productType,
                          productData.productBrand
                        )}
                        {productData.productBrand &&
                          productData.productBrand.map((item, index) => (
                            <option key={index} value={item.brandname}>
                              {item.brandname}
                            </option>
                          ))}
                        <option value="other">Other</option>
                      </>
                    )}
                  </select>
                </div>
                }
                {formData.brand === "other" && (
                  <div className="add-service-provider-profile-right-inputs add-new-product-inputs-other">
                    <input
                      type="text"
                      name="otherbrand"
                      placeholder="Enter Product Band"
                      value={formData.otherbrand}
                      onChange={handleChange}
                    />
                  </div>
                )}

                {formData.brand && <div className="add-service-provider-profile-right-inputs add-new-product-inputs">
                  <div>
                    Product Name{" "}
                    <div className="job-application-field-required">*</div>
                  </div>

                  <select
                    name="pname"
                    value={formData.pname}
                    onChange={handleChange}
                  >
                    <option value="">Select the Product Name</option>
                    {productData.productBrand.length > 0 && (
                      <>
                        {productData.productName &&
                          productData.productName.map((item, index) => (
                            <option key={index} value={item.pname}>
                              {item.pname}
                            </option>
                          ))}
                      </>
                    )}
                    <option value="other">Other</option>
                  </select>
                </div>
                }
                {formData.pname === "other" && (
                  <div className="add-service-provider-profile-right-inputs add-new-product-inputs-other">
                    <input
                      type="text"
                      name="otherpname"
                      placeholder="Enter Product Name"
                      value={formData.otherpname}
                      onChange={handleChange}
                    />
                  </div>
                )}



                <div className="add-service-provider-profile-right-inputs add-new-product-inputs">
                  <div>Product Description </div>
                  <textarea
                    name="desc"
                    placeholder="Describe Your Product"
                    value={formData.desc}
                    onChange={handleChange}
                    maxLength="100"
                  ></textarea>
                </div>

              </secion>
              <secion className="secondSection">
                <div className="add-service-provider-profile-right-inputs">
                  <section className="add-service-provider-image-options-box">
                    <section className="add-service-subsection" ref={dialogreeef}>
                      <div className="quantityDropdown colorDropdown" onClick={() => setIsQuantityDropdownVisible(!isQuantityDropdownVisible)

                      } >

                        <label >
                          {(allselectedVariants != undefined && allselectedVariants.length) > 0 ? (
                            <>
                              {`(${allselectedVariants.length}) Quantities`}
                            </>
                          ) : (
                            'No Quantity'
                          )}
                        </label>

                      </div>

                      <div className="quantityDropdown moreColorDropdown" ref={dropdownRefs.moreQuantity} onClick={() => {
                        setShowQuantities(!showQuantities);
                        console.log('Button clicked', showQuantities);
                        // else{
                        //   document.getElementsByClassName("selection1")[0].style.display="none";
                        //   document.getElementsByClassName("selection1")[1].style.display="none";
                        // }

                      }} >
                        <BiSolidPlusCircle className="plusIcon" />
                        <label for="option">Quantities </label>
                      </div>

                      {showQuantities && (
                        <div className="dropdown-options-product more-quantity-dropdown-data" >
                          {productData.productQuantity.length > 0 && productData.productQuantity.map(quantity => (
                            <div key={quantity.pQuantity} className="quantity-option" onClick={() => {
                              dialogreeef1.current.classList.remove("selection12")
                              dropdownRefs.moreColor.current.classList.remove("selection12")
                              predefinedselect(quantity)
                              setShowColors(true);
                              setcolorvis(true);
                            }}>
                              {quantity.pQuantity}
                              {/* <input
                                type="checkbox"
                                checked={allselectedVariants && allselectedVariants.some(q => q.quantity === quantity.pQuantity)}
                                onChange={() => toggleQuantity(quantity)}
                              /> */}
                            </div>
                          ))}

                          <div className="quantity-option no-selected-quantity" onClick={() => {
                            setAllselectedVariants([])
                            setQuantityData([]);
                            setSelectedColor([])
                            setCurrentVariant({})
                            // dialogreeef1.current.classList.remove("selection12")
                            //   dropdownRefs.moreColor.current.classList.remove("selection12")
                            //   predefinedselect()
                            //   setShowColors(true);
                            //   setcolorvis(true);
                          }}>
                            No Quantity
                          </div>

                          {/* Other Option */}
                          <div
                            className="quantity-option quantity-option-other"
                            onClick={() => {
                              setIsotherqty(true);
                              setIsotherunit(false);
                            }}
                          >
                            Other
                          </div>

                          {isotherqty && <div className="quantity-option-other">
                            <input
                              type="text"
                              name="otherquantity"
                              placeholder="Enter Quantity"
                              value={formData.otherquantity}
                              onChange={handleChange}

                            />
                            <select
                              name="qunit"
                              value={formData.qunit}
                              onChange={(e) => {
                                const selectedUnit = e.target.value;
                                if (selectedUnit === "other") {
                                  // If "Other" is selected, show the input field for custom unit
                                  setFormData((prevData) => ({
                                    ...prevData,
                                    qunit: selectedUnit,
                                  }));
                                  setIsotherunit(true);
                                } else {
                                  // If a regular unit is selected, hide the input field for custom unit
                                  setFormData((prevData) => ({
                                    ...prevData,
                                    qunit: selectedUnit,
                                  }));
                                  setIsotherunit(false);
                                }
                              }}
                            >
                              <option value="">Select Unit </option>
                              {unitsdata.map((unit, index) => (
                                <option key={index} value={unit}>
                                  {unit}
                                </option>
                              ))}<option value="other">Other</option>
                            </select>
                            {isotherunit && (
                              <input
                                type="text"
                                name="customunit"
                                placeholder="Enter Custom Unit"
                                value={formData.customunit}
                                onChange={handleChange}
                              />
                            )}
                            <button onClick={handleAddOtherQuantity}>Add</button>
                          </div>
                          }
                        </div>
                      )}



                      <div className="selection1 quantityDropdown colorDropdown" ref={dialogreeef1}>
                        {selectedColor?.length > 0 && isColorDropdownVisible ? (
                          <div className="dropdown-options-product" >
                            {selectedColor.map((color) => (
                              <>

                                <div key={color.code} className="color-option" onClick={() => handleColorClick(color)} >
                                  <div className="color-box" style={{ backgroundColor: color.code }}></div>
                                  <span>{color.name} </span>

                                  <div className="edit-color">
                                    <MdOutlineModeEdit
                                      size={20}

                                    />
                                  </div>
                                </div>
                              </>
                            ))}

                          </div>
                        ) : (
                          <label >
                            {selectedColor && selectedColor.length > 0 && selectedColor[0].name != "None" ? (
                              < >
                                {`(${selectedColor.length}) Colours`}
                              </>
                            ) : (
                              'No Colour'
                            )}
                          </label>
                        )}
                      </div>
                      <div className="selection1 quantityDropdown moreColorDropdown" ref={dropdownRefs.moreColor} onClick={() => setShowColors(!showColors)} >
                        <BiSolidPlusCircle className="plusIcon" />
                        <label for="option"  > Colours</label>
                      </div>
                      {showColors && (

                        <div className="dropdown-options-product more-color-dropdown-data" ref={colorPickerRef} >

                          {(!isMobileView) ? <>
                            <div className="color-option-parent" ref={coloroptionparent}>
                              {colorui(colors)}
                            </div>
                          </> : <>
                            <div className="color-option-mobile-parent" ref={coloroptionparent}>
                              {colorui(colors)}
                            </div>
                          </>
                          }
                          <div className="color-box no-selected-color">
                            <span >No Colour</span>
                            <input
                              type="checkbox"
                              checked={selectedColor.some(c => c.code === "None")}
                              onChange={(e) => {
                                if (e.target.checked != false) {
                                  handleNoColorClick(e)
                                  console.log("kdk");
                                }
                                else {
                                  notify("error", "please select colors from menu");
                                  return;
                                }
                                // checkedtoggle(e, {code:"None",name:"None"})
                              }}
                            />
                          </div>

                          {/* Other Color Option */}
                          <div className="color-option" onClick={() => { setIsothercolor(true); setIsColorPickerVisible(true); }}>
                            Other Color
                          </div>
                          {/* color-option-other */}
                          {(isothercolor && isColorPickerVisible) && (isMobileView ?
                            <div className="mobile-color-option-other">
                              <div className="color-option-other">

                                <ChromePicker
                                  color={formData.othercolorcode}
                                  onChange={handleChangecolorpicker}
                                />
                                <button onClick={handleAddOtherColor}>Add</button>
                              </div>
                            </div> :
                            <>
                              <div className="color-option-other">

                                <ChromePicker
                                  ref={colormodalref}
                                  color={formData.othercolorcode}
                                  onChange={handleChangecolorpicker}
                                />
                                <button onClick={() => {
                                  handleAddOtherColor();
                                  // coloroptionparent.current.scrollTop=coloroptionparent.current.scrollHeight + document.getElementsByClassName("color-option")[0].offsetHeight;
                                  // setColoraddedother(true);
                                }}>Add</button>
                              </div>
                            </>
                          )
                          }
                        </div>
                      )}


                      {/* <div className="priceInfoRow">
                        <input
                          className="priceInput"
                          type="number"
                          placeholder="Price"
                          value={formData.price}
                          onChange={(e) =>
                            setFormData({ ...formData, price: e.target.value })
                          }
                        />
                        <div className="product-selling-unit">

                          <input
                            className="discountInput" 
                            type="number"
                            placeholder="Units"
                            value={formData.units}
                            onChange={(e) =>
                              setFormData({ ...formData, units: e.target.value })
                            }
                          />
                        </div>
                        <input
                          className="discountInput"
                          type="number"
                          placeholder="Discount"
                          value={formData.discount}
                          onChange={(e) =>
                            setFormData({ ...formData, discount: e.target.value })
                          }
                        />
                      </div> */}
                      {/* 
                      {allImagePreviews?.length > 0 && (
                        <div className="addproduct-imageContainer">
                          {allImagePreviews &&
                            allImagePreviews.map((image, index) => (
                              <>
                                <div key={index} className="imageWrapper">
                                  <IoCloseCircleSharp
                                    size={30}
                                    className="crossIcon-image"
                                    onClick={() => handleRemoveImage(index)}
                                  />
                                  <img src={image} alt="" className="image" />
                                </div>
                              </>
                            ))}
                        </div>
                      )}
                      <div className="add-service-provider-profile-right-inputs-submit">
                        <label onClick={handleButtonClick}>
                          <BiSolidPlusCircle className="plusIcon" />
                          {buttonMessage}
                        </label>
                        <input
                          type="file"
                          name="img"
                          id="image-input"
                          multiple
                          style={{ display: "none" }}
                          ref={fileInputRef}
                          accept="image/png, image/jpg, image/gif, image/jpeg"
                          onChange={HandleChange3}
                        />
                      </div> */}
                    </section>
                    {showTable && quantityData.length > 0 && (

                      <div className="table">
                        <table>
                          <div className="table-content">

                            <div className="table-header">
                              <thead>
                                <tr>
                                  <th className="table-header o-sr">Sr. No.</th>
                                  <th className="table-header o-qty">Qty</th>
                                  <th className="table-header o-color">Color</th>
                                  <th className="table-header o-price">Price</th>
                                  <th className="table-header o-unit"> Unit</th>
                                  <th className="table-header o-dis">Discount</th>
                                  <th className="table-header o-image">Images</th>

                                </tr>
                              </thead>

                            </div>
                            <tbody>
                              {quantityData && quantityData.length > 0 && quantityData?.map((data, dataIndex) =>
                                data.colors.map((color, colorIndex) => (
                                  <tr key={`${dataIndex}-${colorIndex}`}>
                                    {/* {tablecounter=tablecounter+1} */}
                                    {/* dataIndex * data.colors.length + colorIndex + 1 */}
                                    <td className="o-sr">{++tablecounter}.</td>
                                    <td className="o-qty" >{data.quantity}</td>
                                    <td className="o-color">{color.color}</td>
                                    <td className="o-price">
                                      <span>₹	<input
                                        type="number"
                                        value={color.price}
                                        placeholder="price"
                                        onChange={(e) => handleInputChange(data.quantity, colorIndex, 'price', e.target.value)}
                                      />
                                      </span>
                                    </td>
                                    <td className="o-unit">
                                      <input
                                        type="number"
                                        placeholder="Enter in no"
                                        value={color.quantity_sell}
                                        onChange={(e) => handleInputChange(data.quantity, colorIndex, 'quantity_sell', e.target.value)}
                                      />
                                    </td>
                                    <td className="o-dis">
                                      <span><input
                                        type="number"
                                        value={color.discount}
                                        onChange={(e) => handleInputChange(data.quantity, colorIndex, 'discount', e.target.value)}
                                      /> %
                                      </span>
                                    </td>
                                    <td className="o-image">
                                      {/* Input for images */}
                                      <label onClick={() => handleButtonClick(data.quantity, colorIndex)}>
                                        <BiSolidPlusCircle className="plusIcon" />
                                        {buttonMessage}
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        style={{ display: "none" }}
                                        ref={(el) => (fileInputRef.current[`${data.quantity}-${colorIndex}`] = el)}

                                        accept="image/png, image/jpg, image/gif, image/jpeg"
                                        onChange={(e) => {
                                          handleImageUpload(data.quantity, colorIndex, e)
                                          console.log(data.quantity,e.target.value)
                                          e.target.value = null;
                                          console.log(data.quantity,e.target.value)
                                          e.target.parentElement.children[2].style.display = "block";
                                          e.target.parentElement.children[3].style.display = "block";
                                        }}
                                      />

                                      {
                                        (color.images.length > 0) ?
                                          (<> <button className="previewyes" onClick={() => handlePreviewClick(data.quantity, colorIndex)}>Preview</button><TfiEye className="previewyes" />
                                          </>) : <></>
                                      }
                                    </td>

                                    <td className="o-delete">
                                      <IoTrashOutline onClick={(e) => {
                                        handleDeleteRow(data.quantity, colorIndex)
                                        allselectedVariantsupdation(data);
                                        console.log(data, colorIndex)

                                        let colors = currentVariant.colors.filter((elem) => {
                                          return data.colors[colorIndex].color !== elem.color
                                        })

                                        setCurrentVariant({
                                          colors,
                                          "quantity": currentVariant.quantity
                                        });

                                        let selectedcolornotremoved = selectedColor.filter((elem) => {
                                          return data.colors[colorIndex].color !== elem.name
                                        })
                                        console.log("hello", selectedcolornotremoved)
                                        setSelectedColor(selectedcolornotremoved);
                                        // quantitydata

                                        if (selectedcolornotremoved.length == 0) {
                                          setCurrentVariant({});
                                        }
                                      }
                                      } size={26} />
                                    </td>
                                  </tr>
                                ))
                              )}
                            </tbody>
                          </div>
                        </table>
                      </div>

                    )}
                    {isModalOpen && <ImagePreviewModal allImagePreviews={allImagePreviews} setAllImagePreviews={setAllImagePreviews} onRemoveImage={(index, e) => handleRemoveImage(index, colorIndexDelete)
                    } />}
                    {quantityData && quantityData.length > 0 && <div className="greenText-count-products-added">
                      Total <span>({quantityData.length})</span> Products Added
                    </div>
                    }
                  </section>
                </div>
                <div className="add-service-provider-profile-right-submit">
                  <button onClick={AddProduct} disabled={isAddingProduct}>
                    <BiSolidPlusCircle size={33} style={{ paddingRight: "10px" }} />
                    Save Product
                  </button>
                </div>
              </secion>
            </div>
            {isAddingProduct || isUploadingImage ? (
              <div className="overlay addingproduct">
                <div>{isAddingProduct ? "Adding product..." : "Uploading image..."}</div>
              </div>
            ) : null}

            {/* {isothercolor && isColorPickerVisible &&  !ismobile && 
  <div className="mobile-color-option-other">
<div className="color-option-other" ref={colormodalref}>

        <ChromePicker
          color={formData.othercolorcode}
          onChange={handleChangecolorpicker}
        />
  <button onClick={handleAddOtherColor}>Add</button>
</div>
</div>
} */}
          </div>
        )
        }
      </>
    </React.Fragment >
  );
}

const ConfirmationModal = ({ onCancel, onConfirm }) => {
  return (
    <div className="confirmation-modal">
      <div className="confirmation-modal-content">
        <h3>Are you sure you want to delete this product?</h3>
        <div className="confirmation-modal-buttons">
          <button onClick={onConfirm}>Yes</button>
          <button onClick={onCancel}>No</button>
        </div>
      </div>
    </div>
  );
};

const QuantitySelectionModal = ({ selectedItem, onOK, onCancel }) => {
  const [selectedQuantity, setSelectedQuantity] = React.useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const { qty_list } = selectedItem;

  const toggleSelectAll = () => {
    setSelectAll((prevSelectAll) => !prevSelectAll);
    setSelectedQuantity((prevSelectedItems) => {
      if (!selectAll) {
        // Select all quantities
        return qty_list.map((option) => ({
          quantityId: option.id,

        }));
      } else {
        // Deselect all quantities
        return [];
      }
    });


  };
  const toggleQuantity = (quantityId, colors) => {
    setSelectAll(false);
    setSelectedQuantity((prevSelectedItems) => {
      const existingItemIndex = prevSelectedItems.findIndex(
        (item) => item.quantityId === quantityId
      );

      if (existingItemIndex !== -1) {
        // Quantity is selected, so deselect it
        return [
          ...prevSelectedItems.slice(0, existingItemIndex),
          ...prevSelectedItems.slice(existingItemIndex + 1),
        ];
      } else {
        // Quantity is not selected, so select it with default color
        const newItem = {
          quantityId: quantityId,
          colorIds: [],

        };
        return [...prevSelectedItems, newItem];
      }
    })
  };


  const toggleColor = (quantityId, colorId) => {
    setSelectedQuantity((prevSelectedItems) =>
      prevSelectedItems.map((item) =>
        item.quantityId === quantityId
          ? {
            ...item,
            colorIds: item.colorIds.includes(colorId)
              ? item.colorIds.filter((id) => id !== colorId)
              : [...item.colorIds, colorId],
          }
          : item
      )
    );


  };

  console.log(selectedItem);
  console.log(selectedQuantity);
  return (
    <div className="quantity-selection-modal">
      <div className="quantity-selection-modal-content">
        <h3>Select Quantity</h3>
        <div className="quantity-selection-modal-checkboxes">
          {qty_list.map((quantityOption, index) => {
            const { product_id, actual_price, selling_price } = qty_list[index];
            return (
              <>
                <label key={index}>
                  <input
                    type="checkbox"
                    value={quantityOption.quantity}
                    checked={selectedQuantity.some(
                      (item) =>
                        item.quantityId === quantityOption.id
                    )}
                    onChange={() =>
                      toggleQuantity(
                        quantityOption.id,
                        quantityOption.color


                      )
                    }
                  />
                  {quantityOption.quantity}
                </label>
                {!selectAll && selectedQuantity.some((item) => item.quantityId === quantityOption.id) && (
                  <div className="quantity-selection-modal-colors-container">
                    {quantityOption.color && quantityOption.color.length > 0 && (
                      <div className="quantity-selection-modal-colors">
                        <span>Choose color to delete:</span>
                        {quantityOption.color.map((color, colorIndex) => (
                          <label key={colorIndex}>
                            <input
                              type="checkbox"
                              value={color.color_id}
                              checked={selectedQuantity.some(
                                (item) =>
                                  item.quantityId === quantityOption.id &&
                                  item.colorIds.includes(color.color_id)
                              )}
                              onChange={() => toggleColor(quantityOption.id, color.color_id)}
                            />
                            {color.color_name}

                          </label>


                        ))}

                      </div>
                    )}
                  </div>
                )}

              </>
            );
          })}
          <label className="selectAllInput">
            <input
              type="checkbox"
              checked={selectAll}
              onChange={toggleSelectAll}
            />
            Select All
          </label>
        </div>
        <div className="quantity-selection-modal-buttons">
          <button
            onClick={() => onOK(selectedQuantity, selectedItem, selectAll)}
          >
            OK
          </button>
          <button onClick={onCancel}>Cancel</button>
        </div>
      </div>
    </div>
  );
};
const QuantitySelectionModalforoutofstock = ({ selectedItem, onCancel, id, outofstockstatus,funcforstockstatus,getmyseervice }) => {
  const {
    Userprofile,
  } = useGlobalContext();
  const [selectedQuantity, setSelectedQuantity] = React.useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const { qty_list } = selectedItem;
  const [newoutofstockstatus,setNewoutofstockstatus] = useState([]);
  let outofstockupdated=[];
  let instockupdated=[];
  let [onok,setOnok]=useState(false);
  var ab;
  let payload={};
useEffect(()=>{
  setNewoutofstockstatus(outofstockstatus);
},[])

useEffect(()=>{
if(onok){
  apicallingfun();
}
},[onok])
const apicallingfun = async ()=>{
  newoutofstockstatus.map((item,index)=>{
    if(item!=outofstockstatus[index]){
      if(item==1){
        outofstockupdated.push(qty_list[index].id)
      }
      else if (item==0){
        instockupdated.push(qty_list[index].id)
      }
    }
  })
  console.log(outofstockupdated,instockupdated);
   if(outofstockupdated.length !== 0 && instockupdated.length !== 0){
    payload["mark_outofstock_spproduct_ids"]=outofstockupdated;
    payload["mark_instock_spproduct_ids"]=instockupdated;
  }
      else if(outofstockupdated.length !== 0){
        console.log("yesss1")
        payload["mark_outofstock_spproduct_ids"]=outofstockupdated;
      }
      else if(instockupdated.length !== 0){
        console.log("yesss2")
        payload["mark_instock_spproduct_ids"]=instockupdated;
      }
      const response = await myProfileServiceApi.selectedoutofstock({...payload,"sp_id":Userprofile.service_provider_id})
      if (response.data && response.data.error !== "") {
        notify("error", response.data.error);
      }
      else {
        notify("success","status of product updated successfully")
        onCancel();
        getmyseervice();
      }
      setOnok(false);
      outofstockupdated=[];
      instockupdated=[];
      payload={};

}
  const toggleSelectAll = () => {
    setSelectAll((prevSelectAll) => !prevSelectAll);
    setSelectedQuantity((prevSelectedItems) => {
      if (!selectAll) {
        // Select all quantities
        return qty_list.map((option) => ({
          quantityId: option.id,

        }));
      } else {
        // Deselect all quantities
        return [];
      }
    });


  };
  const toggleQuantity = (quantityId, colors) => {
    setSelectAll(false);
    setSelectedQuantity((prevSelectedItems) => {
      const existingItemIndex = prevSelectedItems.findIndex(
        (item) => item.quantityId === quantityId
      );

      if (existingItemIndex !== -1) {
        // Quantity is selected, so deselect it
        return [
          ...prevSelectedItems.slice(0, existingItemIndex),
          ...prevSelectedItems.slice(existingItemIndex + 1),
        ];
      } else {
        // Quantity is not selected, so select it with default color
        const newItem = {
          quantityId: quantityId,
          colorIds: [],

        };
        return [...prevSelectedItems, newItem];
      }
    })
  };


  const toggleColor = (quantityId, colorId) => {
    setSelectedQuantity((prevSelectedItems) =>
      prevSelectedItems.map((item) =>
        item.quantityId === quantityId
          ? {
            ...item,
            colorIds: item.colorIds.includes(colorId)
              ? item.colorIds.filter((id) => id !== colorId)
              : [...item.colorIds, colorId],
          }
          : item
      )
    );


  };

  console.log(selectedItem);
  console.log(selectedQuantity);
  return (
    <>
    {newoutofstockstatus && <>
    <div className="quantity-selection-modal">
      
      <div className="quantity-selection-modal-content">
        <h3>Select Quantity</h3>
        <div className="quantity-selection-modal-checkboxes quantity-selection-modal-checkboxes11">
          {qty_list.map((quantityOption, index) => {
            const { product_id, actual_price, selling_price } = qty_list[index];
            return (
              <>
                <label className="outofstocklabel" key={index}>
                  {/* <input
                    type="checkbox"
                    value={quantityOption.quantity}
                    checked={selectedQuantity.some(
                      (item) =>
                        item.quantityId === quantityOption.id
                    )}
                    onChange={() =>
                      toggleQuantity(
                        quantityOption.id,
                        quantityOption.color


                      )
                    }
                  /> */}
                  <div className="outssstatus">
                    <div>{quantityOption.quantity}</div>
      <label className="switch switch1outofstock">

            <input
            className="checkboxforoutofstock"
              // value={newoutofstockstatus[index]}
              type="checkbox"
              checked={newoutofstockstatus[index]}
              onChange={(e) => {
                ab = newoutofstockstatus.map((item,index1)=>{
                  if(index1==index){
                    if(item == 1){
                      return 0;
                    }
                    return 1;
                  }
                  return item;
                })
               setNewoutofstockstatus(ab);
              }}
            />
        
            <span className="slider round"></span>
            
          </label>
                  </div>
                </label>
                {!selectAll && selectedQuantity.some((item) => item.quantityId === quantityOption.id) && (
                  <div className="quantity-selection-modal-colors-container">
                    {quantityOption.color && quantityOption.color.length > 0 && (
                      <div className="quantity-selection-modal-colors">
                        <span>Choose color to delete:</span>
                        {quantityOption.color.map((color, colorIndex) => (
                          <label key={colorIndex}>
                            <input
                              type="checkbox"
                              value={color.color_id}
                              checked={selectedQuantity.some(
                                (item) =>
                                  item.quantityId === quantityOption.id &&
                                  item.colorIds.includes(color.color_id)
                              )}
                              onChange={() => toggleColor(quantityOption.id, color.color_id)}
                            />

{/* <div className="outssstatus">
                    <div>{color.color_name}</div>
                    <div>{color.out_of_stock == 0 ? 
                    <>
                    <div class="initial-choice service-color-choice coloroutof" style={{backgroundColor: "#96F97B",}}></div>
                    </>:
                    <>
                    <div class="initial-choice service-color-choice coloroutof" style={{backgroundColor: "red",}}></div>
                    </>}</div>
                  </div> */}
                           

                          </label>


                        ))}

                      </div>
                    )}
                  </div>
                )}

              </>
            );
          })}
          {/* <label className="selectAllInput">
            <input
              type="checkbox"
              checked={selectAll}
              onChange={toggleSelectAll}
            />
            Select All
          </label> */}
        </div>
        <div className="quantity-selection-modal-buttons">
          <button
            onClick={() => {
              setOnok(true);
            }}
          >
            OK
          </button>
          <button onClick={onCancel}>Cancel</button>
        </div>
      </div>
    </div>
   </> }
    </>
  );
};

// const previewclose = (index) => {
//   console.log(document.getElementsByTagName("tr")[index + 1].children[6].children);
//   document.getElementsByTagName("tr")[index + 1].children[6].children[2].style.display = "none";
//   document.getElementsByTagName("tr")[index + 1].children[6].children[3].style.display = "none";
//   console.log("hii");
// }
const ImagePreviewModal = ({ allImagePreviews, setAllImagePreviews, onRemoveImage }) => {
  return (
    <>
      {allImagePreviews?.length > 0 && (
        <div className="addproduct-imageContainer image-selection-modal">
          <div className="image-selection-modal-content">
            <div className="slider-image">
              {allImagePreviews &&
                allImagePreviews.map((image, index) => (
                  <>
                    <div key={index} className="imageWrapper">
                      <IoCloseCircleSharp
                        size={30}
                        className="crossIcon-image"
                        onClick={(e) => {
                          onRemoveImage(index)
                          console.log(allImagePreviews)
                          if ((allImagePreviews.length) === 1) {
                            // previewclose(index)
                          }
                        }}
                      />
                      <img src={image} alt="" className="image" />
                    </div>
                  </>
                ))}
            </div>

            <div className="image-modal-footer">
              <button onClick={() => setAllImagePreviews([])} >Cancel</button>
            </div>
          </div>

        </div>
      )}
    </>
  );
};

const ViewCatalogModal = ({ onOK, onCancel, services }) => {
  const [selectedService, setSelectedService] = React.useState('');
  const [selectedSubservice, setSelectedSubService] = React.useState('');
  const [selectedProductId, setSelectedProductId] = React.useState('');
  const [subserviceslist, setSubserviceslist] = React.useState([]);
  const [productTypes, setProductTypes] = React.useState([]);

  // pdf generator related state 
  const [generatingpdf, setGeneratingpdf] = React.useState(false);
  function handleChange(e) {
    const { name, value } = e.target;
    console.log(name, value);

    if (name === 'service') {
      setSubserviceslist([]);
      setProductTypes([]);
      setSelectedSubService(''); // Reset subservice when service changes
      setSelectedProductId(''); // Reset product type when service changes
      setSelectedService((prevSelectedService) => {
        // Use callback form to ensure the latest value is used
        if (prevSelectedService !== value) {
          const selectedServiceObj = services.find(
            (service) => parseInt(service.service_id) === parseInt(value)
          );

          console.log(selectedServiceObj);

          if (selectedServiceObj) {
            setSubserviceslist(selectedServiceObj.sub_service);

          }
        }
        return value;
      });
      setProductTypes(subserviceslist.flatMap((subcategory) => subcategory.product_type));
    }

    if (name === 'subservice') {
      setSelectedSubService(value);
      setSelectedProductId(''); // Reset product type when subservice changes


    }
    if (name === 'producttype') {
      setSelectedProductId(value);



    }
    console.log(subserviceslist);
  }

  React.useEffect(() => {
    // Update product types based on selected subservice when subservice changes
    if (selectedSubservice !== '') {
      const selectedSubserviceObj = subserviceslist.find(
        (subcategory) => parseInt(subcategory.subservice_id) === parseInt(selectedSubservice)
      );
      setProductTypes(selectedSubserviceObj ? selectedSubserviceObj.product_type : []);
    } else {
      // If 'All' is selected or no subservice is selected, show product types from all subservices
      setProductTypes(subserviceslist.flatMap((subcategory) => subcategory.product_type));
    }
  }, [selectedSubservice, subserviceslist]);

  const handleOKClick = () => {
    setGeneratingpdf(true);
    if (selectedService !== '') {
      onOK(selectedService, selectedProductId, selectedSubservice);
    } else {
      // Notify an error when both service and product ID are empty
      notify('error', 'Please select service before clicking OK');
    }
  };
  return (
    <div className="view-catalog-modal">
      {(generatingpdf) ? <Loading text="GENERATING PDF" stylechange="generatingpdfyes"></Loading> : <>
        <div className="view-catalog-modal-content">
          <h3>View Catalog</h3>
          <div className="modal-catlog-conatiner">
            <div className="service-dropdown">
              <label> Services:</label>
              <select
                name="service"
                value={selectedService}
                onChange={handleChange}
              >
                <option key={99} value="" >Select Service</option>
                {services && services.map((service) => (
                  <option key={service.service_id} value={service.service_id}
                  >
                    {service.service_name}
                  </option>
                ))}
              </select>
            </div>
            <div className="service-dropdown">
              <label> Subservice:</label>
              <select
                name="subservice"
                value={selectedSubservice}
                onChange={handleChange}
              >
                <option key={99} value="" >All</option>
                {subserviceslist && subserviceslist.length > 0 &&
                  subserviceslist.map((subcategory, index) => (
                    <option key={subcategory.subservice_id + index} value={subcategory.subservice_id}>
                      {subcategory.subservice_name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="or">OR</div>
            <div className="product-type-dropdown">
              <label>Product Type:</label>
              <select
                name="producttype"
                value={selectedProductId}
                onChange={handleChange}

              >
                <option key={90} value="" >All</option>
                {productTypes && productTypes.length > 0 &&
                  productTypes.map((ptype, index) => (
                    <option key={ptype.product_type_id + index} value={ptype.product_type_id}>
                      {ptype.proudct_type_name}
                    </option>
                  ))}
              </select>
            </div>
          </div>

          <div className="view-catalog-modal-buttons">
            <button

              onClick={handleOKClick}

            >
              OK
            </button>
            <button onClick={onCancel}>Cancel</button>
          </div>
        </div>
      </>}
    </div>
  );
}

