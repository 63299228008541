import React from 'react'
import logo from "../../images/logo.png";
import { useNavigate } from 'react-router-dom';

const Adminheader = (props) => {
    const navigate = useNavigate();
    const logout = () => {

        localStorage.removeItem('AdminToken');
        props.setisLoggedIn(false);
     
      }
      const move = () => {

navigate('/admin/myprofile')
     
      }
  return (
    <>
    <div className='adminnav'>
    <div className="putatoe-logo">
          <img className="putatoe-logo-image" src={logo} alt="logo"/>
          <section
            className="putatoe-logo-text"
            onClick={() => {
                !props.isLoggedIn? navigate('/admin'):navigate('/admin/dashboard')
            }}
          >
            <h1 className="putatoe-logo-text-upper">PUTATOE</h1>
            <span className="putatoe-logo-text-lower">One Solution</span>
          </section>
        </div>
        {props.isLoggedIn &&<div className="login-details-headeradmin">
              <div className='login-details-headeradminright putatoe-logo-text-lower' onClick={move}>
                My Profile
              </div>
              <div className='login-details-headeradminright putatoe-logo-text-lower' onClick={logout}>
                Log out
              </div>
              </div>}
    </div>
    </>
  )
}

export default Adminheader