// importing useRef and useEffect
import React, { useEffect, Component, useRef, useState } from 'react'
import '../serviceprovider-css/serviceProviderCard.css';
// import photo from '../images/Levista-tea.svg'
import add from '../images/add.svg';
import starf from '../images/Starf.svg';
import star from '../images/Star.svg';
import { useProductContext } from '../productPageContext.js';
import { IoIosArrowDown } from "react-icons/io";
import apiclient from '../api/apiclient';
import notify from '../error';
import { useGlobalContext } from '../context';
import cartapi from '../api/cartapi';
import { BsCartPlus } from "react-icons/bs"
import { motion } from 'framer-motion'
import { Link, useNavigate } from 'react-router-dom';
import { BiShareAlt } from 'react-icons/bi';

export default function ServiceProviderCard(props) {

  // *********TO close the drop down when clicking outside menue ************//
  const colorDropdownRef = useRef(null);

  const closeColorDropdown = (event) => {
    if (colorDropdownRef.current && !colorDropdownRef.current.contains(event.target)) {
      setShowMorecolours(false);
    }
  };
  useEffect(() => {
    document.addEventListener('click', closeColorDropdown);
    return () => {
      document.removeEventListener('click', closeColorDropdown);
    };
  }, []);

  // **********To close the drop-down when choosing other color*******/
  const handleColorClick = (index) => {
    setcolorItemIndex(index);
    setShowMorecolours(false); 
  };


  const { changeInCart, setChangeInCart } = useGlobalContext();
  const { image, setImage, productInfo, setProductInfo, serviceDetail, productDetail, setProductDetail } = useProductContext();
  const navigate = useNavigate();
  const [cartCount, setCartCounter] = React.useState(1);
  const [myCartId, setMyCardId] = React.useState();
  const [rating, setRating] = React.useState();
  const [options, setOptions] = React.useState([]);
  const [quantityItemIndex, setQuantityItemIndex] = React.useState(0);
  const [prroductid,setProductid]=React.useState();
  const [counterDisplay, setCounterDisplay] = React.useState(false);
  const [showMorecolours, setShowMorecolours] = React.useState(false);
  const [colorItemIndex, setcolorItemIndex] = React.useState(0);
  const additionalColors = [
    "blue",
    "green",
    "yellow",
    "purple",
    "orange",
    "pink",
  ];

  const toggleShowMore = () => {
      setShowMorecolours(!showMorecolours);
  }

  useEffect(()=>{
setProductid(props.data.qty_list[quantityItemIndex].service_provider_product_id);
  },[quantityItemIndex])
  const display = async (prodid, providerid,color_id=undefined) => {

    const response = await apiclient.getproductImage(prodid, providerid,color_id);
    console.log(response);

    if (response.data.error !== '') {
      notify('error', response.data.error);
    }
    else {

      setImage(response.data.all_images);
      console.log(response.data);
    }

  }


  const display2 = () => {
    setImage();
    console.log(props.data.product_id)
    // console.log(props.data);
    setProductInfo(props.data);

  };

  console.log(props)

  console.log("qindex", quantityItemIndex);
  React.useEffect(() => {
    let arr = [];

    let i = 1;
    for (; i <= props.data.Ratings; i++) {
      arr.push(
        <img className='service-card-card-star' src={starf} alt=''></img>
      );
    }
    for (; i <= 5; i++) {
      arr.push(
        <img className='service-card-card-star' src={star} alt=''></img>
      );
    }
    setRating(arr);
  }, []);
  React.useEffect(() => {
    let arr = [];
    for (let i = 0; i < props.data.qty_list.length; i++) {
      arr.push(<option key={i} value={i}>{props.data.qty_list[i].quantity}</option>);
    }
    setOptions(arr);

    // setQuantityItemIndex(0);
  }, [props]);

  const handleChangeQty = (e) => {
    console.log(e.target.value);
    setQuantityItemIndex(parseInt(e.target.value));
    setcolorItemIndex(0);
    setCounterDisplay(false);
  };

  const handleChangeColor = (index) => {        // can delete this block
    console.log(index);
    setcolorItemIndex(index);
    setCounterDisplay(false);
  };

  function getBaseUrl() {
    // Check if the application is running on localhost
    if (window.location.hostname === "localhost" && window.location.port === "3000") {
      // Running on localhost:3000
      return "http://localhost:3000";
    } else if (window.location.hostname === "putatoetest.web.app") {
      return "https://putatoetest.web.app";
    }
    else {
      return "https://www.putatoe.com"
    }
  }

  // Get the base URL
  const baseUrl = getBaseUrl();

  const shareOption = async() => {
    const { service_id, sub_service_id, 
      service_provider_product_id
       } = props.data;
    console.log(props.productid);
    if ( service_provider_product_id) {
      try {
        if (navigator.share) {
          console.log('Web Share API is supported');
          await navigator.share({
            title: 'Share Link',
            text: 'Check out this'+props.data.product_name+'!' ,
            url: `${baseUrl}/product/${service_provider_product_id}/${props.data.product_name}/${props.data.service_id}`
          });
          
          notify("success", "Link shared successfully");
        } else {
          console.log('Web Share API is not supported');
          // Fallback for browsers that do not support Web Share API
          const link = `${baseUrl}/product/${
            service_provider_product_id}/${props.data.product_name}/${props.data.service_id}`;
          const tempInput = document.createElement("input");
          tempInput.value = link;
          document.body.appendChild(tempInput);
          tempInput.select();
          document.execCommand("copy");
          document.body.removeChild(tempInput);
          notify("success", "Link copied successfully");
        }
      } catch (error) {
        console.error('Error sharing link:', error);
        notify("error", "Error sharing link");
      }
    }
  }




  const RunAddToCart = async () => {
    if (showColorInfo ? !props.data.qty_list[quantityItemIndex].color[colorItemIndex].out_of_stock:!props.data.qty_list[quantityItemIndex].out_of_stock ) {

      const response = await apiclient.AddToCart(
        props.data.qty_list[quantityItemIndex].product_id,
        props.data.qty_list[quantityItemIndex].quantity_id,
        '',
        1,
        props.data.service_id,
        props.data.qty_list[quantityItemIndex].id,
        props.data.qty_list[quantityItemIndex].color && props.data.qty_list[quantityItemIndex].color.length > 0 ? props.data.qty_list[quantityItemIndex].color[colorItemIndex].color_id : null
      );
      console.log(props.data.qty_list[quantityItemIndex].product_id,);
      if (response.data.error !== '') {
        notify('error', response.data.error);
      } else {
        // console.log(response.data.cart_id)
        setCounterDisplay(true);
        notify('success', `one item added to cart`);
        setMyCardId(response.data.cart_id);
        setChangeInCart(!changeInCart);
      }
    } else {
      notify('error', 'Item is out of stock');
    }
    // setProductDetail();
  };

  const runUpdateMyCart = async () => {
    const response = await cartapi.UpdateProd(myCartId, null, cartCount, 1);
    console.log(response);
    if (response.data && response.data.error !== '') {
      notify('error', response.data.error);
    } else {
      setChangeInCart(!changeInCart);
    }
  };

  React.useEffect(() => {
    if (myCartId) {
      runUpdateMyCart();
    }
  }, [cartCount]);
  
  const DeleteMyProduct = async () => {
    setCounterDisplay(false);
    const response = await cartapi.DeleteProd(myCartId);
    console.log(response);
    if (response.data.error === '' && response.data.status === true) {
      notify('success', 'product removed from cart');
      setChangeInCart(!changeInCart);
    }
  };

  const showColorInfo =
    props.data.qty_list[quantityItemIndex].color && props.data.qty_list[quantityItemIndex].color.length > 0 &&
    Object.keys(props.data.qty_list[quantityItemIndex].color[colorItemIndex]).length > 0;

  return (
    <React.Fragment>
      <div className='service-provider-card service-providercard-mobile'>
        <div className='service-provider-image-container'>
          {showColorInfo && props.data.qty_list[quantityItemIndex].color[colorItemIndex].image && props.data.qty_list[quantityItemIndex].color[colorItemIndex].image.length > 0 ? (
            // If color array has images, render the color image
            <img
              src={props.data.qty_list[quantityItemIndex].color[colorItemIndex].image[0].image}
              className="service-provider-image"
              alt="Color Image"
              onClick={() => display(prroductid, props.data.service_id,props.data.qty_list[quantityItemIndex].color[colorItemIndex].color_id)}
            />
          ) :
            <img
              src={props.data.qty_list[quantityItemIndex].qty_image}
              className="service-provider-image"
              alt="Logo"
              onClick={() => display(prroductid, props.data.service_id)}
            />

          }
        </div>

        <div className='service-provider-card-details-top ' onClick={display2}>
          <Link to={`/product/${props.data.service_provider_product_id}/${props.data.product_name}/${props.data.service_id}`} >
            <div className='service-provider-card-details-brand-name'>
              {props.data.brand}
            </div>

          </Link>

        </div>



        <div className='service-provider-card-details-mid' >
        <Link to={`/product/${props.data.service_provider_product_id}/${props.data.product_name}/${props.data.service_id}`} >
          <div className='service-provider-card-details-product-name'>
            {props.data.product_name}
          </div>
          <div className='service-provider-card-details-product-type'>
            {props.data.producttype}
          </div>
</Link>
          <div className='service-provider-card-details-price-cont'>
            <div className='service-provider-card-details-price sp-cd-price-cont-mobile'>
              &#8377;    {showColorInfo
                ? props.data.qty_list[quantityItemIndex].color[colorItemIndex].selling_price
                : props.data.qty_list[quantityItemIndex].selling_price}
            </div>
            {props.data.qty_list[quantityItemIndex].discount !== 0 ?
              <div className='service-provider-card-details-pricecut sp-cd-price-cont-mobile'>
                <strike>  M.R.P. &#8377; {showColorInfo
                  ? props.data.qty_list[quantityItemIndex].color[colorItemIndex].actual_price
                  : props.data.qty_list[quantityItemIndex].actual_price}</strike>
              </div>
              : null}
            {props.data.qty_list[quantityItemIndex].discount !== 0 ?
              <div className='service-provider-card-details-price-percentage sp-cd-price-cont-mobile'>
                {showColorInfo
                  ? props.data.qty_list[quantityItemIndex].color[colorItemIndex].discount
                  : props.data.qty_list[quantityItemIndex].discount}% OFF
              </div>
              :
              null

            }
            <div className='service-provider-card-details-price sp-cd-price-cont-desktop'>
              <div className='sp-card-price-offer-cont'>
                &#8377;{showColorInfo
                  ? props.data.qty_list[quantityItemIndex].color[colorItemIndex].selling_price
                  : props.data.qty_list[quantityItemIndex].selling_price}
                {props.data.qty_list[quantityItemIndex].discount !== 0 ? <span style={{ color: "green", fontWeight: "400", marginLeft: 12 }}>     {showColorInfo
                  ? props.data.qty_list[quantityItemIndex].color[colorItemIndex].discount
                  : props.data.qty_list[quantityItemIndex].discount}% OFF</span> : null}
              </div>
              {props.data.qty_list[quantityItemIndex].discount !== 0 ? <span style={{ color: "gray", fontWeight: "400" }}><strike>&#8377; {showColorInfo
                ? props.data.qty_list[quantityItemIndex].color[colorItemIndex].actual_price
                : props.data.qty_list[quantityItemIndex].actual_price}</strike></span> : null}
              {/* <span style={{ color: "#03989E" }}>{!props.data.qty_list[quantityItemIndex].out_of_stock ? "In Stock" : "Out of Stock"}</span> */}
            </div>
          </div>
        </div>
        <div className="service-rating-instock-container">
          <div className="service-stock-color-container">
          {!showColorInfo 
  ? !props.data.qty_list[quantityItemIndex].out_of_stock 
    ? <span>In Stock</span> 
    : <span style={{color: 'rgb(172, 12, 12)'}}>Out Of Stock</span>
  : !props.data.qty_list[quantityItemIndex].color[colorItemIndex].out_of_stock 
    ? <span>In Stock</span> 
    : <span style={{color: 'rgb(172, 12, 12)'}}>Out Of Stock</span>
}
                      <BiShareAlt className="shareIcon" onClick={shareOption} />
          </div>
          <div className='service-provider-card-rating-container'>
            <section>
              <img className='service-card-card-star' src={starf} alt=''></img>
              <img className='service-card-card-star' src={starf} alt=''></img>
              <img className='service-card-card-star' src={starf} alt=''></img>
              <img className='service-card-card-star' src={starf} alt=''></img>
              <img className='service-card-card-star' src={star} alt=''></img>
            </section>
            {/* <div className='service-provider-card-rating-text'>4.5</div> */} 
            <div class="service-color-choices" ref={colorDropdownRef}>    
              {showColorInfo && props.data.qty_list[quantityItemIndex].color && props.data.qty_list[quantityItemIndex].color.length > 0 && (
                <div className="initial-choice service-color-choice" style={{
                  backgroundColor:
                    props.data.qty_list[quantityItemIndex].color[colorItemIndex].color_code,
                }}
                >
                </div>
              )}
              {showColorInfo && props.data.qty_list[quantityItemIndex].color && props.data.qty_list[quantityItemIndex].color.length > 1 && <span onClick={toggleShowMore}>{"+" + props.data.qty_list[quantityItemIndex].color.length + " "} <IoIosArrowDown size={16} fontWeight={600} /></span>}
              {showMorecolours && props.data.qty_list[quantityItemIndex].color.length > 1 && (
                <div className="additional-choices-container">
                  {props.data.qty_list[quantityItemIndex].color.map((color, index) => (
                    <div
                      className={`additional-choice service-color-choice ${colorItemIndex === index ? "initial-choice" : ""
                        }`}

                      style={{ backgroundColor: color.color_code }}
                      onClick={() => handleColorClick(index)}
                    >
                      {/* {color.color_name} */}
                    </div>
                  ))}
                </div>
              )}

            </div>

          </div>
          <div className="service-unit-individual-qty"><span>
            Unit: <span>    {showColorInfo
              ? props.data.qty_list[quantityItemIndex].color[colorItemIndex].unit
              : props.data.qty_list[quantityItemIndex].units}</span>
          </span></div>
        </div>
        <div className='service-provider-card-bottom'>
          <div className='service-card-product-info-container-prime-quantity'>
            <select
              name='pieces'
              onChange={handleChangeQty}
            >
              {options}

            </select>
          </div>
          {!counterDisplay ? (
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              className='service-provider-add-to-cart'
              onClick={RunAddToCart}
            >
              <BsCartPlus className="service-provider-add-to-cart-image" />
              <div className="service-provider-plus-icon">+</div>
              {!showColorInfo 
  ? !props.data.qty_list[quantityItemIndex].out_of_stock 
    ? <span>Add to cart</span> 
    : <span className="outoffstock" >Out Of Stock</span>
  : !props.data.qty_list[quantityItemIndex].color[colorItemIndex].out_of_stock 
    ? <span>Add to cart</span> 
    : <span className="outoffstock" >Out Of Stock</span>
}
            </motion.button>
          ) : (
            <>
              <div className='service-provider-card-bottom-counter'>
                <div
                  className='service-provider-card-bottom-counter-minus'
                  onClick={() => {
                    cartCount > 1
                      ? setCartCounter((prev) => prev - 1)
                      : DeleteMyProduct();
                  }}
                >
                  -
                </div>
                <div className='service-provider-card-bottom-counter-count'>
                  {cartCount}
                </div>
                <div
                  className='service-provider-card-bottom-counter-plus'
                  onClick={() => {
                    setCartCounter((prev) => prev + 1);
                  }}
                >
                  +
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}