import React, { Component } from 'react';
import '../serviceprovider-css/checkList.css';
import { useServiceContext } from '../serviceContext.js';
export default function CheckList(props) {

  // const[formData,setFormData]=React.useState({})

  const {
    quantityData, setQuantityData,
    brandData,
    setbrandData,
    productData,
    setproductData,colorData, setColorData
  } = useServiceContext();



  function handleChange(event) {
    const { name, value, type, checked } = event.target;
    console.log({name, value, type, checked})

    if (props.name === 'Product Type')
    {
      console.log('here3', props.name);
      if(!checked)
      {
        setproductData((prevFormData) => {
          const arr=[];
          for(let i=0;i<productData.length;i++)
          {
            if(productData[i]!== parseInt(value))
            {
              arr.push(parseInt(productData[i]));
            }
          }
          console.log(arr)
          return arr;
        });
      }
      else
      {
        setproductData((prevdata)=> {return[...prevdata,parseInt(value)]})
      }
    }
    else if(props.name === 'Brands')
    {
      console.log('here2', props.name);
      if (!checked) {
        setbrandData((prevFormData) => {
          const arr = [];
          for (let i = 0; i < brandData.length; i++) {
            if (brandData[i] !== parseInt(value)) {
              arr.push(parseInt(brandData[i]));
            }
          }
          console.log(arr);
          return arr;
        });
      } else {
        setbrandData((prev2) => ([...prev2,parseInt(value)]));
      }
    }    else if(props.name === 'Colors')
    {
      console.log('here2', props.name);
      if (!checked) {
        setColorData((prevFormData) => {
          const arr = [];
          for (let i = 0; i < colorData.length; i++) {
            if (colorData[i] !== value) {
              arr.push(colorData[i]);
            }
          }
          console.log(arr);
          return arr;
        });
      } else {
        setColorData((prev2) => ([...prev2,value]));
      }
    }
    if (props.name === 'Quantity');
    {
      console.log(props.name === 'Quantity');
      console.log('here',props.name)
      if (!checked && props.name === 'Quantity') {
        console.log('here', props.name);
        setQuantityData((prevFormData) => {
          const arr = [];
          for (let i = 0; i < quantityData.length; i++) {
            if (quantityData[i] !== parseInt(value)) {
              arr.push(parseInt(quantityData[i]));
            }
          }
          console.log(arr);
          return arr;
        });
      } else if (props.name === 'Quantity') {
        console.log('here', props.name);
        setQuantityData((prev3) => [...prev3,parseInt(value)]);
      }
    }
  }
  // console.log(formData)


  
  // console.log(props)
  return (
    <React.Fragment>
      
      <div className='checklist-container'>
        <div className='checklist-heading'>{props.name}</div>
        <div className='checklist-items-container'>
         <form>
         {props.items && props.items.map((it)=>
         {
          const item = Object.values(it);
          const isColorCode = typeof item[0] === 'string' && item[0].startsWith('#');
          return (
            <div className='checklist-list' >
              <input className='checklist-item' type='checkbox' value={item[0]} name={item[1]} id={item[0]} onChange={handleChange}></input>
              {isColorCode ? (
          <label htmlFor={item[0]} ><span style={{ backgroundColor: item[0] }}></span></label>
        ) : (
          <label htmlFor={item[0]}>{item[1]}</label>
        )}
            </div>
          );
         })}
           
         </form>
        </div>
      </div>
    </React.Fragment>
  );
}
