import React, { Component, useEffect, useRef } from "react";
import myProfileServiceApi from "../../api/myProfileServiceApi";
import notify from "../../error";
import { useGlobalContext } from "../../context";
import "./css/ManageServices.css";
import back from "../myprofileIcoons/bx-arrow-back..svg";
import { motion } from "framer-motion";
import dustbin from "../../imageMobile/trash-bin-svgrepo-com.svg";
import profileapi from "../../api/profileapi";
import Loading from "../../components/Loading";
import { IoCloseCircleSharp } from "react-icons/io5";

export default function ManageServices(props) {
  const { Userprofile, loading, setLoading,setInnerPage } = useGlobalContext();
  const [page, setPage] = React.useState(1);
  const [serviceList, setServiceList] = React.useState();
  const [deleteservice,setDeleteservice]=React.useState(false);
  const [deleteservicename, setDeleteservicename] = React.useState({
    service_id: "",
    subservice_id: "",
    "sub-service provided": "",
  });
  const [subId, setSubId] = React.useState(["Select Your Service"]);

  const [formData, setFormData] = React.useState({
    service: "",
    subservice: "",
  });


  // State to store form errors
const [formError, setFormError] = React.useState({ service: '', subservice: '' });

// Validation function
const validateForm = () => {
  let valid = true;
  const errors = {};

  if (!formData.service) {
    errors.service = 'Service is required';
    valid = false;
  }

  if (!formData.subservice) {
    errors.subservice = 'Sub Service is required';
    valid = false;
  }

  setFormError(errors);
  return valid;
};
  const GetMyServices = async () => {
    setLoading(true);
    const response = await myProfileServiceApi.AllServicesByUser(
      Userprofile.username
    );
    console.log(response);
    if (response.data && response.data.error !== "") {
      notify("error", response.data.error);
    } else {
      setServiceList(response.data.sub_service_provided);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    GetMyServices();
    allServicesubService();
  }, []);

  function goBack() {
    props.return();
    setInnerPage(false)
  }

  const DeleteParticluarSerivce = async (service_id, sub_service_id) => {
    const response = await myProfileServiceApi.DeleteMyService(
      service_id,
      sub_service_id
    );
    console.log(response);
    if (response.data && response.data.error !== "") {
      notify("error", response.data.error);
    } else {
      notify("success", "Successfully Deleted The Service");
      GetMyServices();
    }
    setDeleteservice(false);
    setDeleteservicename({
      service_id: "",
      subservice_id: "",
      "sub-service provided": "",
    })
  };

  const AddParticularService = async () => {
    if (!validateForm()) {
      return; // Exit if the form is not valid
    }

    const response = await myProfileServiceApi.AddToMyService(
      formData.service,
      formData.subservice
    );
    console.log(response);
    if (response.data && response.data.error !== "") {
      notify("error", response.data.error);
    } else {
      setFormData({
        service: "",
        subservice: "",
      });
      notify("success", "Successfully Added The Service");
      GetMyServices();
      setPage(1);
    }
  };

  const [serviceSubSerivce, setServiceSubSerivce] = React.useState();
  const allServicesubService = async () => {
    const response = await profileapi.AllServiceSubSerice();
    console.log(response);
    setServiceSubSerivce(response.data);
  };

  function searchInput(e) {
    console.log(formData);
    const { name, value, type, checked } = e.target;
    console.log(name, value);
    console.log(name === "service");
    if (name === "service") {
      console.log(serviceSubSerivce);
      for (let i = 0; i < serviceSubSerivce.service_list.length; i++) {
        // console.log(serviceSubSerivce.service_list[i].id, value);
        if (value === `${serviceSubSerivce.service_list[i].id}`) {
          // console.log(serviceSubSerivce.service_list[i].sub_list);
          setSubId(serviceSubSerivce.service_list[i].sub_list);
          break;
        }
      }
    }
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: type === "checkbox" ? checked : value,
      };
    });
  }
  const handleCrossClicked = () =>{
    setDeleteservice(false);
  }

 useEffect(()=>{
  if(deleteservicename.service_id && deleteservicename.subservice_id){
 DeleteParticluarSerivce(
    deleteservicename.service_id,
    deleteservicename.subservice_id
)
  }

 },[deleteservicename.service_id,deleteservicename.subservice_id])

  return (
    <React.Fragment>
      <>
        {page === 1 && (
          <>
         { 
          deleteservice &&
      <div className="overlayTheModal">
      <section 
      className="mycontainer MycontainerEnterOTP mycontainermanagesevice" >
        <IoCloseCircleSharp
          size={30}
          className="crossIcon"
          onClick={(handleCrossClicked)}
        />
        <div className="mycontainermanagesevicecenter">
        <div className="headingOfFieldChange">
Do You Want to Delete All the Products of {deleteservicename["sub-service provided"]} ?
        </div>
        <div className='delete-service-provider-profile-service-main-page-buttons'>
             <button  onClick={()=>{
              setDeleteservicename(
                ...serviceList.filter((items)=>{
                    return items["sub-service provided"] == deleteservicename["sub-service provided"];
                  })
              )
             }} >YES</button> 
             <button onClick={()=>{
              setDeleteservice(false);
             }}>NO</button>
            </div>
        </div>
                  </section>
                  </div>
         }
          <div className="manage-services-profile-service-main-page">
            <div className="my-service-profile-ongoing-and-pending-online-back">
              <img src={back} onClick={goBack}></img>
            </div>
            <div className="manage-services-profile-service-main-page-add-service">
              <button
                onClick={() => {
                  setPage(2);
                }}
              >
                Add New Service
              </button>
            </div>
            <div className="manage-services-profile-service-main-page-main-box-before">
              {serviceList && (
                <>
                  Total <span>{serviceList.length}</span> Service(s)
                </>
              )}
            </div>
            <div className="manage-services-profile-service-main-page-main-box">
              {loading ? (
                <Loading />
              ) : (
                serviceList &&
                !loading &&
                serviceList.map((item) => {
                  console.log(item)
                  return (
                    <>
                      <div className="manage-services-profile-service-main-page-main-box-card">
                        <div className="manage-services-profile-service-main-page-main-box-card-provider">
                          {item["sub-service provided"]}
                        </div>
                        <div className="manage-services-profile-service-main-page-main-box-card-price">
                          <span>
                            <img
                              src={dustbin}
                              onClick={() => {
                                // DeleteParticluarSerivce(
                                //   item.service_id,
                                //   item.subservice_id
                                // );
                                setDeleteservice(true);
                                setDeleteservicename(prevstate => ({
                                  ...prevstate,
                                  "sub-service provided":item["sub-service provided"],
                                }))
                              }
                            }
                              alt="dustbin"
                            ></img>
                          </span>
                        </div>
                      </div>
                    </>
                  );
                })
              )}
            </div>
          </div>
          </>
        )}
        {page === 2 && (
          <div className="manage-services-profile-service-main-page">
            <div className="my-service-profile-ongoing-and-pending-online-back">
              <img
                src={back}
                onClick={() => {
                  setPage(1);
                }}
              ></img>
            </div>
            <div className="manage-services-profile-service-main-page-input-fields">
            <span>
                  Please complete the fields below in order to add a new
                  service. We will verify and activate them here for service
            </span>
              <div className="manage-services-profile-service-main-page-input-field-1">
              <div className={`add-service-provider-profile-right-inputs ${formError.service ? 'error' : ''}`}>
                  <span>Service </span>
                  <select
                    name="service"
                    placeholder="Enter your service"
                    value={formData.service}
                    onChange={searchInput}
                  >
                    <option value="">Enter your service</option>
                    {serviceSubSerivce &&
                      serviceSubSerivce.service_list &&
                      serviceSubSerivce.service_list.map((item) => {
                        return <option value={item.id}>{item.name}</option>;
                      })}
                  </select>
                  {formError.service && <p className="error-message">{formError.service}</p>}
                </div>
                <div className={`add-service-provider-profile-right-inputs ${formError.subservice ? 'error' : ''}`}>
                  <span>Sub Service </span>
                  <select
                    name="subservice"
                    placeholder="Enter your subservice"
                    value={formData.subservice}
                    onChange={searchInput}
                  >
                    <option value="">Enter your subservice</option>
                    {subId &&
                      subId.map((item) => {
                        return (
                          <option value={item.id}>{item.sub_service}</option>
                        );
                      })}
                  </select>
                  {formError.subservice && <p className="error-message">{formError.subservice}</p>}
                </div>
              </div>
              <div className="manage-services-profile-service-main-page-input-field-button">
                <button onClick={AddParticularService}>Add Service</button>
              </div>
            </div>
          </div>
        )}
      </>
    </React.Fragment>
  );
}
