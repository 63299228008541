import React, { Component, useEffect, useState } from "react";
import "./css/jobapplicationpage.css";
import Jobapi from "../../api/Jobapi";
import { useNavigate } from "react-router-dom";
import defaultuserprofile from "../../images/defaultuserprofile.jpeg"
import notify from "../../error";
import profileapi from "../../api/profileapi";
import postapi from '../../api/postapi';
import Loading from "../../components/Loading";
import { useGlobalContext } from "../../context";
// import MyJobProfile from './MyJobProfile';
export default function MyJobApplication() {
  const degreegraduation=[
    "B.Com",
    "B.A",
    "B.Arch",
    "B.Des.",
    "B.Ed",
    "B.Pharma",
    "B.Sc",
    "B.Tourism",
    "B.B.A/ B.M.S",
    "BCA",
    "BDS",
    "B.Tech/B.E.",
    "BFA",
    "BHM",
    "BVSC",
    "Diploma",
    "LLB",
    "MBBS",
    "BHMS",
    "BAMS",
    "B.El.Ed",
    "B.P.Ed",
    "BHMCT",
    "B.U.M.S",
  ];
  const postdegreegraduation=[
    "CA",
    "CS",
    "ICWA (CMA)",
    "LLM",
    "M.A",
    "M.Arch",
    "M.Com",
    "Medical-MS/MD",
    "M.Des.",
    "M.Tech",
    "M.Ed",
    "M.Ekistics",
    "M.Pharma",
    "MS/M.Sc(Science)",
    "MBA/PGDM",
    "MCA",
    "MDS",
    "MFA",
    "MVSC",
    "PG Diploma",
    "MCM",
    "Integrated PG",
    "DM",
    "M.Ch",
  ]
  const bydefaultimageref= React.useRef(defaultuserprofile);
  const imageref= React.useRef();
  const [uploading,setUploading]=React.useState(false);
  const [submited, setSubmited] = React.useState(false);
  const [imagedeleteicon,setImagedeleteicon]=React.useState(false);
  const navigate = useNavigate();
  const { Userprofile } = useGlobalContext();
  const [showJobProfile, setShowJobProfile] = React.useState(false);
  const [formData, setFormData] = React.useState({
    degree_graduation:"",
    degree_post_graduation:"",
    email: "",
    f_name: "",
    m_name: "",
    l_name: "",
    guardian_name: "",
    image: defaultuserprofile,
    marriage_status: false,
    id_proof: "",
    id_proof_2: "",
    contact_no: "",
    address_id: "",
    secondary_percent: "",
    higher_secondary_percent: "",
    graduation_percent: "",
    post_graduation_percent: "",
    diploma_percent: "",
    dob: "",
    skills: "",
    interests: "",
    projects: "",
    field_of_interest: "",
    portfolio: "",
    experience: "",
    trainings: "",
    internships: "",
    additional_details: "",
    position_of_responsibility: "",
  });

  const [myAddress, setMyAddress] = React.useState([]);

  const GetMyAddress = async () => {
    const response = await profileapi.ShowAddress();
    console.log(response);
    if (response.data.error !== "") {
      notify("error", response.data.error);
    } else {
      setMyAddress(response.data.all_address);
    }
  };

  const GetMyApplication = async () => {
    try {
      const response = await Jobapi.CheckJobApplication();
   
      if (response.data.details) {
        setShowJobProfile(true);
        console.log(response.data.details);
        let mobileNo = response.data.details.address.mobile;
        if (mobileNo) {
          if (mobileNo.startsWith('+91')) {
            mobileNo = mobileNo.substring(3);  // Removes the first 3 characters (+91)
          }
        } else {
          mobileNo = ""; // Set mobileNo to an empty string if it is null
        }
        setFormData({
          degree_graduation:response.data.details.degree_graduation,
          degree_post_graduation:response.data.details.degree_post_graduation,
          email: response.data.details.emaill,
          f_name: response.data.details.f_name,
          m_name: response.data.details.m_name,
          l_name: response.data.details.l_name,
          dob: response.data.details.dob,
          guardian_name: response.data.details.guardian_name,
          image: response.data.details.image,
          marriage_status: response.data.details.marriage_status,
          id_proof: response.data.details.id_proof,
          id_proof_2: response.data.details.id_proof_2,
          contact_no: mobileNo,
          address_id: response.data.details.address.id,
          secondary_percent: response.data.details.secondary_percent,
          higher_secondary_percent:
            response.data.details.higher_secondary_percent,
          graduation_percent: response.data.details.graduation_percent,
          post_graduation_percent: response.data.details.post_graduation_percent,
          diploma_percent: response.data.details.diploma_percent,
          // dob: "",
          skills: response.data.details.skills,
          interests: response.data.details.interests,
          projects: response.data.details.projects,
          field_of_interest: response.data.details.field_of_interest,
          portfolio: response.data.details.portfolio,
          experience: response.data.details.experience,
          trainings: response.data.details.trainings,
          internships: response.data.details.internships,
          additional_details: response.data.details.additional_details,
          position_of_responsibility:
            response.data.details.position_of_responsibility,
          id: response.data.details?.id || "",
        });
      } else {
        console.log("no job profile created");
        if(Userprofile){
          let mobileNo = Userprofile.phone;
          if (mobileNo) {
            if (mobileNo.startsWith('+91')) {
              mobileNo = mobileNo.substring(3);  // Removes the first 3 characters (+91)
            }
          } else {
            mobileNo = ""; // Set mobileNo to an empty string if it is null
          }
          const defaultAddress = myAddress && myAddress.find(addr => addr.default_address === 1);
          setFormData({
            email: Userprofile.email?Userprofile.email:"",
            f_name: Userprofile.first_name,
            m_name: "",
            l_name: Userprofile.last_name ?Userprofile.last_name:"",
            dob: Userprofile.DOB?Userprofile.DOB:"",
            guardian_name: "",
            image: Userprofile.profile_image,
            marriage_status: false,
            id_proof: "",
            id_proof_2:"",
            contact_no: mobileNo,
            address_id: defaultAddress ? defaultAddress.address_id : "", 
           
          })
        }
      
        
      
      }
  console.log(formData);
    } catch (err) {
        console.log(err);
      }
    };
    React.useEffect(() => {
      GetMyAddress();
      GetMyApplication();
   
    }, []);

    React.useEffect(()=>{
      if(showJobProfile===false && myAddress.length!==0){
        const defaultAddress = myAddress && myAddress.find(addr => addr.default_address === 1);
     
        setFormData(prevFormData => ({
          ...prevFormData,
          address_id: defaultAddress ? defaultAddress.address_id : "",
        }));
        console.log(formData);
      }
    },[showJobProfile,myAddress])

    // const checkApplication = async ()=>{
    //   try{const res = await Jobapi.CheckJobApplication();
    //   console.log(res.data.details);
    //   res.data.details && setShowJobProfile(true);
    //   }catch(err){
    //     console.log(err);
    //   }
    // }

    // useEffect(()=>{
    //   checkApplication();
    // },[])

    const HandleMyApplication = async (action, formData) => {
              // Regular expression to validate URL
     const urlRegex = /^(ftp|http|https):\/\/(www\.)?([a-zA-Z0-9-]+\.?)+([a-zA-Z]{2,6})(\/\S*)?$/;
      const apiToCall =
        action === "modify" ? "ModifyJobApplication" : "ApplyForJob";
      console.log(formData);
      if (
        !formData.email ||
        !formData.f_name ||
        !formData.contact_no ||
        !formData.address_id ||
        !formData.id_proof ||
        !formData.dob ||
        !formData.skills
      ) {
        notify("error", "Please fill in all required fields");
        setSubmited(true);
        return;
      } else if (!formData.diploma_percent && !formData.secondary_percent) {
        // notify(
        //   "error",
        //   "Please fill either secondary or diploma percentage field"
        //   );
        setSubmited(true);
        return;
      }
      else if(!(/^[^0-9]+$/.test(formData.skills))){
        console.log((/^[^0-9]+$/.test(formData.skills)))
        setSubmited(true);
        return;
      }
      // Contact number validation
      else if (!/^[0-9]{10}$/.test(formData.contact_no)) {
        // notify("error", "Please enter a valid 10-digit contact number");
        setSubmited(true);
        return;
      }
      else if (formData.portfolio && !urlRegex.test(formData.portfolio)) {
   
        notify("error","Please Enter Valid URL");
        return;
         }
      // Contact id_proof validation
      else if (!/^[0-9]{12}$/.test(formData.id_proof)) {
        // notify("error", "Please enter a valid 12-digit Adhar no.");
        setSubmited(true);
        return;
      }
      // Email validation
      else if (!/\S+@\S+\.\S+/.test(formData.email)) {
        // notify("error", "Invalid email address");
        setSubmited(true);
        return;
      }
      // Identity proof number validation
      else if (!formData.id_proof || formData.id_proof.length !== 12) {
        // notify("error", "Invalid identity proof number");
        setSubmited(true);
        return;
      }

      // Percentage fields validation
      const percentageFields = [
        { field: "secondary_percent", label: "Secondary Percentage" },
        {
          field: "higher_secondary_percent",
          label: "Higher Secondary Percentage",
        },
        { field: "graduation_percent", label: "Graduation Percentage" },
        { field: "post_graduation_percent", label: "Post Graduation Percentage" },
        { field: "diploma_percent", label: "Diploma Percentage" },
      ];

      percentageFields.forEach(({ field, label }) => {
        const value = formData[field];
        if (value < 0 || value > 100) {
          // notify("error", ` ${label} must be between 0 and 100`);
          setSubmited(true);
          return;
        }
      });
      try {
        const response = await Jobapi[apiToCall](
          formData.degree_graduation,
          formData.degree_post_graduation,
          formData.email,
          formData.f_name,
          formData.m_name,
          formData.l_name,
          formData.guardian_name,
          formData.image,
          formData.marriage_status,
          formData.id_proof,
          formData.id_proof_2,
          `+91${formData.contact_no}`,
          parseInt(formData.address_id),
          parseInt(formData.secondary_percent),
          parseInt(formData.higher_secondary_percent),
          parseInt(formData.graduation_percent),
          parseInt(formData.post_graduation_percent),
          parseInt(formData.diploma_percent),
          formData.dob,
          formData.skills,
          formData.interests,
          formData.projects,
          formData.field_of_interest,
          formData.portfolio,
          formData.experience,
          formData.trainings,
          formData.internships,
          formData.additional_details,
          formData.position_of_responsibility,
          formData.id
        );
        console.log(response); 
        if (response.status === 200)
          notify("success", "Successfully modified...");
        navigate("/jobs")

      } catch (err) {
        notify("error", err);
        console.log(err);
      }
    };

    function handleChange(event) {
      const { name, value, type, checked } = event.target;

      console.log(name, value);

      setFormData((prevFormData) => {
        return {
          ...prevFormData,
          [name]: value,
        };
      });

      if (formData.f_name && (formData.email || /\S+@\S+\.\S+/.test(formData.email)) && (formData.contact_no || /^[0-9]{10}$/.test(formData.contact_no)) && formData.address_id && (formData.id_proof || /^[0-9]{12}$/.test(formData.id_proof)) && (formData.secondary_percent && (0 <= formData.secondary_percent && formData.secondary_percent <= 100))
      ) {
        setSubmited(false);
      }
    }

    const DeleteMyApplication = async () => {
      const response = await Jobapi.DeleteMyApplication();
      console.log(response);
      if (response.data.error !== "") {
        notify("error", response.data.error);
      } else {
        notify("success", "Successfully Submitted application");
      }
    };

  useEffect(()=>{
    if(formData.image !== bydefaultimageref.current){
      setImagedeleteicon(true);
    }
  },[formData.image])

    const handleimagechange = async (event) => {
      const selectedFile = event.target.files[0];
  setUploading(true);
      const response = await postapi.UploadImageOGC(selectedFile);
      console.log(event.target);
  setUploading(false);
      if (response.data.error === "") {
        setFormData((prevdata) => {
          return {
            ...prevdata,
            [event.target.name]: response.data.url,
          }
        })
      }
      else {
notify("error","Fail to upload a image")
      }
    }
    return (
      <React.Fragment>
        <form onSubmit={(e) => e.preventDefault()}>

          <div className="job-application-profile-view">
            <div className="profile-wallet-main-page-heading">
              <span>My Job Profile</span>
            </div>

           { (!uploading) ? <div className="add-address-modal-coupon-enter-image">
              <div className="add-address-modal-coupon-enter-image-section">
                <img src={formData.image} alt="profile-image"></img>
          { imagedeleteicon && <div className="crossIcon-imagediv" onClick={()=>{
              setFormData((prevdata)=>{
              return{
                ...prevdata,
                image:bydefaultimageref.current
              }
              })
              setImagedeleteicon(false);
            }}>
            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" class="crossIcon-image" height="30" width="30" xmlns="http://www.w3.org/2000svg">
              <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm86.63 272L320 342.63l-64-64-64 64L169.37 320l64-64-64-64L192 169.37l64 64 64-64L342.63 192l-64 64z" className="crossIcon-imagepath"></path></svg>
            </div>
          }
              </div>
              <input id="imageinput" type="file" name="image" accept="image/png, image/jpeg" onChange={handleimagechange}></input>
              <label htmlFor="imageinput" className="imagelabel">Choose Image File</label>
            </div>:<div className="loaderdiv"><Loading text="Uploading Image"></Loading></div>}
            <div className="job-application-profile-input1">
              <div className="job-application-profile-input">
                <div className="add-address-modal-coupon-enter-input">
                  <span>
                    FIRST NAME{" "}
                    <span className="job-application-field-required">*</span>
                  </span>
                  <input
                    type="text"
                    value={formData.f_name}
                    name="f_name"
                    onChange={handleChange}
                    placeholder="First Name"
                    className={(!formData.f_name && submited) ? "error1" : "noerror"}
                  ></input>
                  {(!formData.f_name && submited) && <span className="errormessageforjob">Please enter your first name</span>}
                </div>
                <div className="add-address-modal-coupon-enter-input">
                  <span>MIDDLE NAME</span>
                  <input
                    type="text"
                    value={formData.m_name}
                    name="m_name"
                    onChange={handleChange}
                    placeholder="Middle Name"
                  ></input>
                </div>
                <div className="add-address-modal-coupon-enter-input">
                  <span>LAST NAME </span>
                  <input
                    type="text"
                    value={formData.l_name}
                    name="l_name"
                    onChange={handleChange}
                    placeholder="Last Name"
                  ></input>
                </div>
                <div className="add-address-modal-coupon-enter-input">
                  <span>DATE OF BIRTH(DOB) { }<span className="job-application-field-required">*</span>
                  </span>

                  <input
                    type="date"
                    value={formData.dob}
                    name="dob"
                    onChange={handleChange}
                    className={((!formData.dob) && submited) ? "error1" : "noerror"}
                  ></input>
                  {((!formData.dob) && submited) && <span className="errormessageforjob">Please enter your date of birth</span>}
                </div>
                <div className="add-address-modal-coupon-enter-input">
                  <span>
                    EMAIL <span className="job-application-field-required">*</span>
                  </span>
                  <input
                    type="text"
                    value={formData.email}
                    name="email"
                    onChange={handleChange}
                    placeholder="Email"
                    className={((!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) && submited) ? "error1" : "noerror"}
                  ></input>
                  {((!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) && submited) && <span className="errormessageforjob">Please enter your email-id</span>}
                </div>
                <div className="add-address-modal-coupon-enter-input">
                  <span>
                    CONTACT NUMBER{" "}
                    <span className="job-application-field-required">*</span>
                  </span>
                  <input
                    type="number"
                    value={formData.contact_no}
                    name="contact_no"
                    onChange={handleChange}
                    placeholder="Contact Number"
                    className={(submited && (!formData.contact_no || !/^[0-9]{10}$/.test(formData.contact_no))) ? "error1" : "noerror"}
                  ></input>
                  {(submited && (!formData.contact_no || !/^[0-9]{10}$/.test(formData.contact_no))) && <span className="errormessageforjob">Please enter a valid 10-digit contact number</span>}
                </div>
                {/* <div className="add-address-modal-coupon-enter-input">
              <span>
                CONTACT NUMBER 2
              </span>
              <input
                type="number"
                value={formData.contact_no}
                name="contact_no"
                onChange={handleChange}
                placeholder="Contact Number"
                className={(submited && (!formData.contact_no || !/^[0-9]{10}$/.test(formData.contact_no))) ? "error1":"noerror"}
              ></input>
              {(submited && (!formData.contact_no || !/^[0-9]{10}$/.test(formData.contact_no))) && <span className="errormessageforjob">Please enter a valid 10-digit contact number</span> }
            </div> */}
                <div className="add-address-modal-coupon-enter-input">
                  <span>
                    YOUR ADDRESS{" "}
                    <span className="job-application-field-required">*</span>
                  </span>
                  <select
                    value={formData.address_id}
                    name="address_id"
                    onChange={handleChange}
                    className={(submited && !formData.address_id) ? "error1" : "noerror"}
                  >
                    <option value="">Select Your Address</option>
                    {myAddress.length > 0 &&
                      myAddress.map((item) => {
                        return (
                          <option value={item.address_id}>
                            {item.addressline1}, {item.addressline2},{" "}
                            {item.district} ,{item.town} ,{item.state}
                          </option>
                        );
                      })}
                  </select>
                  {(submited && !formData.address_id) && <span className="errormessageforjob">Please select valid address</span>}
                </div>
                <div className="add-address-modal-coupon-enter-input">
                  <span>GUARDIAN NAME </span>
                  <input
                    type="text"
                    value={formData.guardian_name}
                    name="guardian_name"
                    onChange={handleChange}
                    placeholder="Guardian Name"
                  ></input>
                </div>
                <div className="add-address-modal-coupon-enter-input">
                  <span>Martial Status</span>
                  <select
                    value={formData.marriage_status}
                    onChange={handleChange}
                    name='marriage_status'
                    placeholder='Select Martial Status'
                  >
                    <option value=''>Select Martial Status</option>
                    <option value={true}>Married</option>
                    <option value={false}>Not Married</option>
                  </select>
                </div>
                  <div className="add-address-modal-coupon-enter-input">
                  <span>Graduation Degree
                  </span>
                  <select
                    value={formData.degree_graduation}
                    onChange={handleChange}
                    name='degree_graduation'
                    placeholder='Select Graduation Degree'
                    >
                      <option value='None'>Select Graduation Degree</option>
                      {
                    degreegraduation.map((element) => {
                      return (
                        <>
                        <option value={element}>{element}</option>
                        </>
                      )
                    })
                  }
                  </select>
                </div>

                <div className="add-address-modal-coupon-enter-input">
                  <span>Post Graduation Degree
                  </span>
                  <select
                    value={formData.degree_post_graduation}
                    onChange={handleChange}
                    name='degree_post_graduation'
                    placeholder='Select PostGraduation Degree'
                    >
                      <option value='None'>Select Post Graduation Degree</option>
                      {
                    postdegreegraduation.map((element) => {
                      return (
                        <>
                        <option value={element}>{element}</option>
                        </>
                      )
                    })
                  }
                  </select>
                </div>
                <div className="add-address-modal-coupon-enter-input">
                  <span>
                    IDENTITY PROOF NUMBER{" "}
                    <span className="job-application-field-required">*</span>
                  </span>
                  <input
                    type="number"
                    value={formData.id_proof}
                    name="id_proof"
                    onChange={handleChange}
                    placeholder="Id Proof"
                    className={(submited && (!formData.id_proof || !/^[0-9]{12}$/.test(formData.id_proof))) ? "error1" : "noerror"}
                  ></input>
                  {(submited && (!formData.id_proof || !/^[0-9]{12}$/.test(formData.id_proof))) && <span className="errormessageforjob">Please enter a valid 12-digit Adhar no</span>}
                </div>

                <div className="add-address-modal-coupon-enter-input">
                  <span>
                    IDENTITY PROOF NUMBER 2
                  </span>
                  <input
                    type="number"
                    value={formData.id_proof_2}
                    name="id_proof_2"
                    onChange={handleChange}
                    placeholder="Id Proof 2"
                  ></input>
                </div>

                <div className="add-address-modal-coupon-enter-input">
                  <span>
                    SECONDARY PERCENTAGE{" "}
                    <span className="job-application-field-required">*</span>
                  </span>
                  <input
                    type="number"
                    value={formData.secondary_percent}
                    name="secondary_percent"
                    onChange={handleChange}
                    placeholder="secondary percent"
                    className={(submited) && ((!formData.secondary_percent && !formData.diploma_percent) || (formData.secondary_percent && !(0 <= formData.secondary_percent && formData.secondary_percent <= 100))) ? "error1" : "noerror"}
                  ></input>
                  {(submited &&
                    ((!formData.secondary_percent && !formData.diploma_percent) ? <span className="errormessageforjob">Please fill either secondary or diploma percentage field</span> :
                      <>{(formData.secondary_percent && !(0 <= formData.secondary_percent && formData.secondary_percent <= 100)) &&
                        <span className="errormessageforjob">secondary percent must be between 0 and 100</span>}
                      </>))}
                </div>
                <div className="add-address-modal-coupon-enter-input">
                  <span>HIGHER SECONDARY PERCENTAGE </span>
                  <input
                    type="number"
                    value={formData.higher_secondary_percent}
                    name="higher_secondary_percent"
                    onChange={handleChange}
                    placeholder="higher secondary percent"
                    className={(formData.higher_secondary_percent && !(0 <= formData.higher_secondary_percent && formData.higher_secondary_percent <= 100)) ? "error1" : "noerror"}
                  ></input>

                  {(formData.higher_secondary_percent && !(0 <= formData.higher_secondary_percent && formData.higher_secondary_percent <= 100)) &&
                    <span className="errormessageforjob">higher secondary percent must be between 0 and 100</span>}
                </div>
                <div className="add-address-modal-coupon-enter-input">
                  <span>DIPLOMA PERCENTAGE</span>
                  <input
                    type="text"
                    value={formData.diploma_percent}
                    name="diploma_percent"
                    onChange={handleChange}
                    placeholder="diploma percent"
                    className={(formData.diploma_percent && !(0 <= formData.diploma_percent && formData.diploma_percent <= 100)) ? "error1" : "noerror"}
                  ></input>
                  {(formData.diploma_percent && !(0 <= formData.diploma_percent && formData.diploma_percent <= 100)) &&
                    <span className="errormessageforjob">diploma percent must be between 0 and 100</span>}
                </div>
                <div className="add-address-modal-coupon-enter-input">
                  <span>GRADUATION PERCENTAGE</span>
                  <input
                    type="number"
                    value={formData.graduation_percent}
                    name="graduation_percent"
                    onChange={handleChange}
                    placeholder="graduation percent"
                    className={(formData.graduation_percent && !(0 <= formData.graduation_percent && formData.graduation_percent <= 100)) ? "error1" : "noerror"}
                  ></input>
                  {(formData.graduation_percent && !(0 <= formData.graduation_percent && formData.graduation_percent <= 100)) &&
                    <span className="errormessageforjob">graduation percent must be between 0 and 100</span>}
                </div>
                <div className="add-address-modal-coupon-enter-input">
                  <span>POST GRADUATION PERCENTAGE</span>
                  <input
                    type="text"
                    value={formData.post_graduation_percent}
                    name="post_graduation_percent"
                    onChange={handleChange}
                    placeholder="post graduation percent"
                    className={(formData.post_graduation_percent && !(0 <= formData.post_graduation_percent && formData.post_graduation_percent <= 100)) ? "error1" : "noerror"}
                  ></input>
                  {(formData.post_graduation_percent && !(0 <= formData.post_graduation_percent && formData.post_graduation_percent <= 100)) &&
                    <span className="errormessageforjob">post graduation percent must be between 0 and 100</span>}
                </div>
                <div className="add-address-modal-coupon-enter-input">
                  <span>FIELD OF INTEREST </span>
                  <input
                    type="text"
                    value={formData.field_of_interest}
                    name="field_of_interest"
                    onChange={handleChange}
                    placeholder="Fields Of Interest"
                  ></input>
                </div>

                <div className="add-address-modal-coupon-enter-input">
                  <span>SKILLS{" "}
                  <span className="job-application-field-required">*</span>
                  </span>
                  <input
                    type="text"
                    value={formData.skills}
                    name="skills"
                    onChange={handleChange}
                    placeholder="Enter Your Skills"
                    className={(!formData.skills && submited) ? "error1" : "noerror"}
                  ></input>
                  {(submited && !formData.skills) ? <span className="errormessageforjob">Please Enter Your Skills</span>:<>
                  {
                    (submited && formData.skills && !(/^[^0-9]+$/.test(formData.skills))) ? <><span className="errormessageforjob">Please enter the skills in text</span></>:<></>
                  }
                  </>}
                  {
                    (formData.skills && !submited && !(/^[^0-9]+$/.test(formData.skills))) ? <> <span className="errormessageforjob">Please enter the skills in text</span></>:<>
                    </>
                  }
                </div>
                <div className="add-address-modal-coupon-enter-input">
                  <span>TRAININGS</span>
                  <input
                    type="text"
                    value={formData.trainings}
                    name="trainings"
                    onChange={handleChange}
                    placeholder="trainings"
                  ></input>
                </div>
                <div className="add-address-modal-coupon-enter-input">
                  <span>PORTFOLIO LINK</span>
                  <input
                    type="text"
                    value={formData.portfolio}
                    name="portfolio"
                    onChange={handleChange}
                    placeholder="portfolio link"
                  ></input>
                </div>
                <div className="add-address-modal-coupon-enter-input">
                  <span>POSITION OF RESPONSIBILITY</span>
                  <input
                    type="text"
                    value={formData.position_of_responsibility}
                    name="position_of_responsibility"
                    onChange={handleChange}
                    placeholder="position of responsibility"
                  ></input>
                </div>
                <div className="add-address-modal-coupon-enter-input">
                  <span>INTERESTS</span>
                  <textarea
                    type="text"
                    value={formData.interests}
                    name="interests"
                    onChange={handleChange}
                    placeholder="interests"
                  ></textarea>
                </div>
                <div className="add-address-modal-coupon-enter-input">
                  <span>PROJECTS</span>
                  <textarea
                    type="text"
                    value={formData.projects}
                    name="projects"
                    onChange={handleChange}
                    placeholder="projects"
                  ></textarea>
                </div>

                <div className="add-address-modal-coupon-enter-input">
                  <span>INTERNSHIPS</span>
                  <textarea
                    type="text"
                    value={formData.internships}
                    name="internships"
                    onChange={handleChange}
                    placeholder="internships"
                  ></textarea>
                </div>
                <div className="add-address-modal-coupon-enter-input">
                  <span>EXPERIENCE</span>
                  <textarea
                    type="text"
                    value={formData.experience}
                    name="experience"
                    onChange={handleChange}
                    placeholder="experience"
                  ></textarea>
                </div>

                <div className="add-address-modal-coupon-enter-input">
                  <span>ADDITIONAL DETAILS</span>
                  <textarea
                    type="text"
                    value={formData.additional_details}
                    name="additional_details"
                    onChange={handleChange}
                    placeholder="additional details"
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="job-application-profile-submit-buttons">
              {showJobProfile ? (
                <button
                  type="submit"
                  onClick={(e) => HandleMyApplication("modify", formData)}
                >
                  Modify
                </button>
              ) : (
                <button
                  type="submit"
                  onClick={(e) => HandleMyApplication("submit", formData)}
                >
                  SUBMIT
                </button>
              )}
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }
