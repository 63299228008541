import React from "react";
import "../css/addnewaddress.css";
import locationImage from "../../images/location-2-svgrepo-com.svg";
import profileapi from "../../api/profileapi";
import notify from "../../error";
import Geocode from "react-geocode";
import { allStates, getDistricts } from "../../stateDistrict";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";

// Set Google Maps API key
const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
Geocode.setApiKey(googleMapsApiKey);

function AddNewAddress(props) {
  const [location, setLocation] = React.useState(null);
  const [submitted, setsubmiited] = React.useState(false);
  const [formDataPassword, setFormDataPassword] = React.useState({
    name: "",
    phone: "",
    addressline1: "",
    addressline2: "",
    landmark: "",
    town: "",
    pincode: "",
    latitude: "",
    longitude: "",
    district: "",
    state: "",
    default_address: 0,
  });

  function handleChangePassword(event) {
    //  console.log(event);
    const { name, value, type, checked } = event.target;
    setFormDataPassword((prevFormData) => {
      return {
        ...prevFormData,
        [name]: type === "checkbox" ? checked : value,
      };
    });
    //  console.log(formDataPassword)
    if (formDataPassword.name && /^[0-9]{10}$/.test(formDataPassword.phone) && formDataPassword.addressline1 && formDataPassword.town && formDataPassword.state && formDataPassword.district && /^[0-9]{6}$/.test(formDataPassword.pincode)) {
      setsubmiited(false);
    }
  }

  // const OnCancel=()=>
  // {
  //  console.log(props)
  //  props.back()
  // }
  const handleMarkerDragend = async (e) => {
    const { latLng } = e;
    console.log(e);
    const lat = latLng.lat();
    const lng = latLng.lng();
    try {
      setLocation({ lat, lng });
      const response = await Geocode.fromLatLng(lat, lng);
      const address = response.results[0].formatted_address;
      const address_formatted = address.split(", ");
      console.log(address_formatted);
      const state_pincode_formatted =
        address_formatted[address_formatted.length - 2];
      console.log(state_pincode_formatted);
      const state_pincode_array = state_pincode_formatted.split(" ");
      console.log(state_pincode_array);
      let newState = "";
      let newPincode = "";

      if (state_pincode_array.length === 1 && !isNaN(state_pincode_array[0])) {
        newPincode = state_pincode_array[0];
      } else if (state_pincode_array.length === 2) {
        newState = isNaN(state_pincode_array[0]) ? state_pincode_array[0] : "";
        newPincode =
          isNaN(state_pincode_array[1]) ||
            typeof state_pincode_array[1] !== "string"
            ? ""
            : state_pincode_array[1];
      }

      setFormDataPassword((prevFormData) => {
        return {
          ...prevFormData,
          addressline1: address_formatted[0],
          addressline2: address_formatted[1],
          town: address_formatted[2],
          state: newState,
          district: response.results[0].address_components[4].long_name,
          pincode: newPincode,
          latitude: lat,
          longitude: lng,
        };
      });
      console.log(response.results);
    } catch (error) {
      console.error("Error retrieving address:", error);
      notify("error", "Error retrieving address");
    }
  };

  const onAddition = async () => {
    let variable = formDataPassword.default_address == 1 ? 1 : 0;
    console.log(variable);
    if (!formDataPassword.name) {

      setsubmiited(true);
      return;
    }
    if (!/^[0-9]{10}$/.test(formDataPassword.phone)) {

      setsubmiited(true);
      return;
    }
    if (!formDataPassword.addressline1) {

      setsubmiited(true);
      return;
    }
    if (!formDataPassword.town) {

      setsubmiited(true);
      return;
    }
    if (!formDataPassword.state) {

      setsubmiited(true);
      return;
    }
    if (!formDataPassword.district) {

      setsubmiited(true);
      return;
    }
    if (!/^[0-9]{6}$/.test(formDataPassword.pincode)) {

      setsubmiited(true);
      return;
    }
    const response = await profileapi.AddAdress(
      formDataPassword.name,
      formDataPassword.phone,
      formDataPassword.addressline1,
      formDataPassword.addressline2,
      formDataPassword.landmark,
      formDataPassword.town,
      formDataPassword.pincode,
      formDataPassword.latitude,
      formDataPassword.longitude,
      formDataPassword.district,
      formDataPassword.state,
      variable
    );
    console.log(response);
    props.reload();
    if (response.data.status === true) {
      props.back();
    } else {
      notify("warn", response.data.error);
    }
  };

  function handleKeyPress(event) {
    const currentLength = event.target.value.length;
    const inputName = event.target.name;

    if (
      inputName === "phone" &&
      (currentLength >= 10 || (event.key && !/^[0-9]*$/.test(event.key)))
    ) {
      event.preventDefault();
    }
    if (
      inputName === "pincode" &&
      (currentLength >= 6 || (event.key && !/^[0-9]*$/.test(event.key)))
    ) {
      event.preventDefault();
    }
    if (
      (inputName === "name" ||
        inputName === "addressline1" ||
        inputName === "addressline2" ||
        inputName === "town" ||
        inputName === "district" ||
        inputName === "state" ||
        currentLength >= 15) &&
      event.key &&
      !/^[a-zA-Z]*$/.test(event.key)
    ) {
      event.preventDefault();
    }
  }

  const handleClick = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;

          try {
            // Retrieve address from coordinates
            const response = await Geocode.fromLatLng(latitude, longitude);
            const address = response.results[0].formatted_address;
            const address_formatted = address.split(", ");
            const state_pincode_formatted =
              address_formatted[address_formatted.length - 2];
            const state_pincode_array = state_pincode_formatted.split(" ");

            setLocation({ lat: latitude, lng: longitude });
            setFormDataPassword((prevFormData) => {
              return {
                ...prevFormData,
                addressline1: address_formatted[0],
                addressline2: address_formatted[1],
                town: address_formatted[2],
                state: state_pincode_array[0],
                district: response.results[0].address_components[3].long_name,
                pincode: state_pincode_array[1],
                latitude: latitude,
                longitude: longitude,
              };
            });
          } catch (error) {
            console.log("Error retrieving address:", error);
            notify("error", "Network error");
          }
        },
        (error) => {
          console.log("Geolocation error:", error);
          notify("error", error.message);
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };

  React.useEffect(() => {
    handleClick();
  }, [])
  return (
    <React.Fragment>
      <div className="profile-account-setting-right-parent">
        <div className="profile-account-setting-right-new-address-heading">
          My New Address
        </div>
        <div className="profile-account-setting-right-container-2">
          <div className="profile-account-setting-right-new-address-location">
            <button onClick={handleClick}>
              <img src={locationImage}></img>
              <p>Use my current location</p>
            </button>

            {location && (
              <div
                style={{ width: "400px", height: "300px", position: "relative" }}
              >
                <Map
                  google={props.google}
                  apiKey={googleMapsApiKey}
                  zoom={14}
                  initialCenter={location}
                  style={{ width: "100%", height: "100%" }}
                >
                  <Marker
                    position={location}
                    draggable={true}
                    onDragend={(t, map, coord) => handleMarkerDragend(coord)}
                  />
                </Map>
              </div>
            )}
          </div>

          <div className="profile-account-setting-right-new-address-input">
            <div className="form-group form-groupmodify">
              <div class="form-label">Name<span class="required">*</span></div>
              <input
                type="text"
                value={formDataPassword.name}
                name="name"
                onChange={handleChangePassword}
                onKeyPress={handleKeyPress}
                placeholder="Enter your name"
                className={`${!formDataPassword.name && submitted ? "errorborder" : "form-groupmodifyinput"}`}
              ></input>
              {
                !formDataPassword.name && submitted && <div className="error">Please Enter Your Name</div>
              }
            </div>
            <div className="form-group form-groupmodify">
              <div class="form-label">Mobile No<span class="required">*</span></div>
              <input
                type="text"
                value={formDataPassword.phone}
                name="phone"
                onChange={handleChangePassword}
                onKeyPress={handleKeyPress}
                placeholder="Enter your mobile number"
                className={`${submitted && ((formDataPassword.phone != undefined || formDataPassword.phone != null) && !/^[0-9]{10}$/.test(formDataPassword.phone)) ? "errorborder" : "form-groupmodifyinput"}`}
              ></input>
              {
                submitted && ((formDataPassword.phone != undefined || formDataPassword.phone != null) && !/^[0-9]{10}$/.test(formDataPassword.phone)) && <div className="error">Please Enter your valid 10 digit number</div>
              }
            </div>
            <div className="form-group form-groupmodify">
              <div class="form-label">Address line 1<span class="required">*</span></div>
              <input
                type="text"
                value={formDataPassword.addressline1}
                name="addressline1"
                onChange={handleChangePassword}
                onKeyPress={handleKeyPress}
                placeholder="Enter address line 1"
                className={`${!formDataPassword.addressline1 && submitted ? "errorborder" : "form-groupmodifyinput"}`}
              ></input>
              {
                submitted && !formDataPassword.addressline1 && <div className="error">Please fill the address line 1</div>
              }
            </div>

            <div className="form-group form-groupmodify">
              <div className="form-label">Address line 2</div>
              <input type="text"
                name="addressline2"
                className="form-groupmodifyinput"
                placeholder="Enter address line 2"
                value={formDataPassword.addressline2}
                onChange={handleChangePassword}
                onKeyPress={handleKeyPress} />
            </div>

            <div className="form-group form-groupmodify">
              <div className="form-label">Landmark</div>
              <input type="text" name="landmark" className="form-groupmodifyinput" placeholder="Enter your landmark" value={formDataPassword.landmark} onChange={handleChangePassword} />
            </div>

            {/* <input
            type="number"
            value={formDataPassword.phone}
            name="phone"
            onKeyPress={handleKeyPress}
            onChange={handleChangePassword}
            placeholder="10- digit mobile number"
          ></input> */}
            <div className="form-group form-groupmodify">
              <div className="form-label">Town<span className="required">*</span></div>
              <input type="text"
                value={formDataPassword.town}
                name="town"
                onKeyPress={handleKeyPress}
                onChange={handleChangePassword}
                placeholder="Enter your Town/City"
                className={`${!formDataPassword.town && submitted ? "errorborder" : "form-groupmodifyinput"}`}
              />
              {
                !formDataPassword.town && submitted && <div className="error">Please Enter Your Town</div>
              }
            </div>

            {/* <input
            type="number"
            value={formDataPassword.pincode}
            name="pincode"
            onKeyPress={handleKeyPress}
            onChange={handleChangePassword}
            placeholder="Pincode"
          ></input> */}
            {/* <input
            type="text"
            value={formDataPassword.addressline1}
            name="addressline1"
            maxLength={15}
            onKeyPress={handleKeyPress}
            onChange={handleChangePassword}
            placeholder="Flat , House no. , Landmark   "
          ></input> */}
            {/* <input
            type="text"
            value={formDataPassword.town}
            name="town"
            maxLength={15}
            onKeyPress={handleKeyPress}
            onChange={handleChangePassword}
            placeholder="Town/City"
          ></input> */}

            {/* <input
            type="text"
            value={formDataPassword.addressline2}
            name="addressline2"
            maxLength={15}
            onKeyPress={handleKeyPress}
            onChange={handleChangePassword}
            placeholder="Area , Street , Sector , Village"
          ></input> */}
            {/* <input
            type="text"
            value={formDataPassword.state}
            name="state"
            maxLength={15}
            onKeyPress={handleKeyPress}
            onChange={handleChangePassword}
            placeholder="State" */}
            {/* ></input> */}
            <div className="form-group form-groupmodify">
              <div className="form-label">
                State<span className="required">*</span>
              </div>
              <select
                name="state"
                className={`form-select form-select-h ${!formDataPassword.state && submitted ? "errorborder" : "form-groupmodifyselect"}`}
                value={formDataPassword.state}
                onChange={handleChangePassword}
              >
                <option value="Select State">Select State</option>
                {allStates.map((state) => (
                  <option key={state} value={state}>
                    {state}
                  </option>
                ))}
              </select>
              {
                !formDataPassword.state && submitted && <div className="error">Please Select a state</div>
              }
            </div>
            <div className="form-group form-groupmodify">
              <div className="form-label">
                District<span className="required">*</span>
              </div>
              <select
                name="district"
                className={`form-select form-select-h ${!formDataPassword.district && submitted ? "errorborder" : "form-groupmodifyselect"}`}
                value={formDataPassword.district}
                onChange={handleChangePassword}
              >
                <option value="Select District">Select District</option>
                {formDataPassword.state != "Select State" &&
                  getDistricts(formDataPassword.state).map((district) => (
                    <option key={district} value={district}>
                      {district}
                    </option>
                  ))}
              </select>
              {
                !formDataPassword.district && submitted && <div className="error">Please Select a district</div>
              }
            </div>
            {/* <input
            type="text"
            value={formDataPassword.district}
            name="district"
            maxLength={15}
            onKeyPress={handleKeyPress}
            onChange={handleChangePassword}
            placeholder="District"
          ></input> */}
            <div className="form-group form-groupmodify">
              <div class="form-label">Pincode<span className="required">*</span>
              </div>
              <input type="number" name="pincode" className={`${submitted && ((formDataPassword.pincode != undefined || formDataPassword.pincode != null) && !/^[0-9]{6}$/.test(formDataPassword.pincode)) ? "errorborder" : "form-groupmodifyinput"}`} placeholder="Enter your pincode" value={formDataPassword.pincode} onChange={handleChangePassword} />
              {
                submitted && ((formDataPassword.pincode != undefined || formDataPassword.pincode != null) && !/^[0-9]{6}$/.test(formDataPassword.pincode)) && <div className="error">Please Enter a Pincode</div>
              }
            </div>


            <>
              <div className="form-group form-groupmodify">
                <div className="form-label">
                  Latitude<span className="required">*</span>
                </div>
                <input
                  type="number"
                  name="latitude"
                  className="form-groupmodifyinput"
                  placeholder="Enter latitude"
                  value={formDataPassword.latitude}
                  onChange={handleChangePassword}
                  readOnly
                />
              </div>
              <div className="form-group form-groupmodify">
                <div className="form-label">
                  Longitude<span className="required">*</span>
                </div>
                <input
                  type="number"
                  name="longitude"
                  className="form-groupmodifyinput"
                  placeholder="Enter longitude"
                  value={formDataPassword.longitude}
                  onChange={handleChangePassword}
                  readOnly// Conditionally set readOnly
                />
              </div>
            </>

          </div>

        </div>
        <div className="profile-account-setting-right-new-address-input-check">
          <input
            type="checkbox"
            id="profile-account-setting-right-new-address-input-check-1"
            checked={formDataPassword.default_address}
            onChange={handleChangePassword}
            name="default_address"
          />
          <label htmlFor="profile-account-setting-right-new-address-input-check-1">
            Make this my default address
          </label>
        </div>
        <div className="profile-account-setting-right-new-address-input-result">
          <button onClick={onAddition}>Add Address</button>
          <div onClick={props.back}>Cancel</div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default GoogleApiWrapper({
  apiKey: googleMapsApiKey,
})(AddNewAddress);
