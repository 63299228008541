import React, { Component } from 'react';
import MyBillsApi from '../api/MyBillsApi';
import { useGlobalContext } from '../context';
// import radar from '../images/radar-observatory-svgrepo-com.svg';
// import cover from '../images/DthCoverPage.svg';
import './css/DthRechargePage.css';
import { Navigate, useNavigate } from 'react-router-dom';
import notify from '../error';
import LandlineCover from "../images/LandLinePage/LandlineCover.png";
export default function LandlinePage(props) {
  const [operatorList, setOperatorList] = React.useState();
  const LandlineList = async () => {
    const response = await MyBillsApi.OtherOperatorList('Landline');
    console.log(response);
    setOperatorList(response.data.operator_list);
  };
  React.useEffect(() => {
    LandlineList();
  }, []);
  const [formData, setFormData] = React.useState({
    operator_code: '',
    DTH: '',
    amount: '',
    coupon_id: null,
    payment_method: '',
  });

  function ChangeValue(e) {
    const { name, value, type, checked } = e.target;
    // console.log(value,name)
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: type === 'checkbox' ? checked : value,
      };
    });
    console.log(formData);
  }
  const navigate = useNavigate();
  const validateMobileNumber = (mobileNumber) => {
    const regex = /^[6-9]\d{9}$/;
    return regex.test(mobileNumber);
  };
  const BillMyDTH = async () => {
    const { operator_code, DTH, amount,payment_method } = formData;
    if (DTH === '') {
      notify('error','Please enter a valid Mobile Number.');
         return;
       }
   
       if (!validateMobileNumber(DTH)) {
      notify('error','Please enter a valid 10-digit Mobile Number.');
         return;
       }
   
       if (operator_code === '') {
      notify('error','Please select a provider.');
         return;
       }
   
       if (amount === '' || parseInt(amount) <= 0) {
      notify('error','Please enter a valid recharge amount.');
         return;
       }
    navigate(
      '/cart/recharge',
      { replace: true,
        state: {
          op_code: operatorList[formData.operator_code].op_code,
          serial_no: formData.DTH,
          amount: formData.amount,
          circle:2,
          op_image: operatorList[formData.operator_code].operator_image,
          text1: `Mobile Number ${formData.DTH}`,
          text2: `Recharge ${operatorList[formData.operator_code].operator_name} with `,
          pageno:props.pageNo,redirect:"/bill-payments"
        },
      }
    );
  };

  //payments

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }
  return (
    <React.Fragment>
      <div className='my-dth-recharge-main-page-container'>
        <div className='my-dth-recharge-main-page-container-heading'>
          <div>
            {/* <img src={radar}></img> */}
            {/* <i class="fa-solid fa-phone-office"></i> */}
            {/* <i class="fa-duotone fa-phone-office fa-xl"></i> */}
            <i class="fa-solid fa-tty fa-2xl"></i>
          </div>
          <div>
            Let's Get Your<br></br>
            <span className='landbill'>Landline Bill Payment Done</span>
            <hr></hr>
          </div>
        </div>
        <div className='my-dth-recharge-main-page-container-main-content'>
          <div className='my-dth-recharge-main-page-container-main-content-col1'>
            <div className='my-dth-recharge-main-page-container-main-content-col1-inputs'>
              <div className='my-dth-recharge-main-page-container-main-content-col1-inputs-1'>

                <input
                  type='text'
                  value={formData.DTH}
                  onChange={ChangeValue}
                  name='DTH'
                  placeholder='Enter Mobile Number'
                ></input>
              </div>
              <div className='my-dth-recharge-main-page-container-main-content-col1-inputs-1'>

                <select
                  value={formData.operator_code}
                  onChange={ChangeValue}
                  name='operator_code'
                >
                  <option value=''>Select Operator</option>
                  {operatorList &&
                    operatorList.length > 0 &&
                    operatorList.map((item,index) => {
                      return (
                        <option value={index}>
                          {item.operator_name}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className='my-dth-recharge-main-page-container-main-content-col1-inputs-1'>

                <input
                  type='number'
                  value={formData.amount}
                  onChange={ChangeValue}
                  name='amount'
                  placeholder='Enter Recharge Amount'
                ></input>
              </div>
              <div className='my-dth-recharge-main-page-container-main-content-col1-inputs-button'>
                <button onClick={BillMyDTH}>Proceed</button>
              </div>
            </div>
          </div>
          <div className='my-dth-recharge-main-page-container-main-content-col2'>
            <div>
              <img src={LandlineCover} width="450"></img>
            </div>
          </div>
        </div>
        <div className='my-dth-recharge-main-page-container-allProvider-content'>
          <div className='my-dth-recharge-main-page-container-allProvider-content-all-provider'>
            All Providers
          </div>
          <div className='my-dth-recharge-main-page-container-allProvider-content-all-provider-card-holder'>
            {operatorList &&
              operatorList.length > 0 &&
              operatorList.map((item) => {
                return (
                  <div className='my-dth-recharge-main-page-container-allProvider-content-all-provider-cards'>
                    <div className='my-dth-recharge-main-page-container-allProvider-content-all-provider-cards-col1'>
                      <div>
                        <img src={item.operator_image}></img>
                      </div>
                    </div>
                    <div className='my-dth-recharge-main-page-container-allProvider-content-all-provider-cards-col2'>
                      {item.operator_name}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>


      </div>
    </React.Fragment>
  );
}
