import React from 'react'
import './css/FollowList.css'

const MyFollowersCard = ({FollowersList}) => {
  return (
    <React.Fragment>
        <div className='follow-list-all-cards-container'>
        {
            FollowersList && FollowersList.length>0 ? (
            FollowersList.map((followers,index) => {
                return(
                    <div className='follow-list-following-card-container' key={index}>
                    <div className='follow-list-following-card'>
                        <div className='follow-list-following-card-image-container'>
                            <img src={followers.profile_image} alt='following provider logo' />
                        </div>
                        <div className='follow-list-following-card-content'>
                            <p style={{color: "#00838f",fontSize: "18px"}}><b>{followers.name}</b></p>
                            <p><b>Email</b> : {followers.email}</p>
                            <p><b>Phone Number</b> : {followers.phone?followers.phone:"Phone Number is not available"} </p>
                        </div>
                    </div>
                    </div>
                )
            })) : (
                <div className='follow-list-following-card-container'>
                    <div className='follow-list-following-card follow-list-following-card-no-following'>
                        <h4><b>No Followers Found</b></h4>
                        <p>No one has followed you yet</p>
                    </div>
                </div>
            )
        }
        </div>
    </React.Fragment>
  )
}

export default MyFollowersCard