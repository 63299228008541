import React, { useRef, useState } from 'react'
import Adminheader from "./adminheader";
import AdminTabs from './adminTabs'
import "../admincss/dashboard.css"
import adminloginapi from '../adminapi/adminloginapi';
import noshow from '../../images/noOrders.png'
import notify from '../../error';
import Loading from '../../components/Loading';
import Cards from './cards';
import back from "../../myprofile/myprofileIcoons/bx-arrow-back..svg";
import logo from "../../images/logo.png";

const Dashboard = (props) => {
  // page for viewing different tabs
  const [page2, setPage2] = React.useState(1);
  const [page3, setPage3] = React.useState(0); // for viewing items 
  const [billData, setBillData] = React.useState();
  const [tabSelected, setTabSelected] = React.useState('Pending Orders');
  const [allorders, setAllorders] = React.useState([]);
  const [selectedordersection, setSelectedordersection] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isMobileView, setIsMobileView] = React.useState(false);
  // const [choosedstate,setChoosedstatus]=React.useState(false);
  const initialref1=useRef(true);

  React.useEffect(() => {
    function handleResize() {
      setIsMobileView(window.innerWidth <= 767);
    }

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const date = new Date();
  const date2 = new Date();

  const exitDetail = () => {
    setPage3(0);
  };
  const newDate = todaydaate(date2);
  function todaydaate(date2) {
    const newDate = new Date(date2);
    newDate.setDate(date2.getDate());
    return newDate;
  }
  const [formData, setFormData] = React.useState({
    to: `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(date.getDate()).padStart(2, "0")}`,
    from: `${newDate.getFullYear()}-${String(newDate.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(newDate.getDate()).padStart(2, "0")}`,
    editFrom: `${newDate.getFullYear()}-${String(
      newDate.getMonth() + 1
    ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
  });

  function handleChange(event) {
    console.log(event)
    setAllorders([]);

    const { name, value, type, checked } = event.target;
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: type === "checkbox" ? checked : value,
      };
    });
    if (name === "editFrom") {

      console.log("here", value);
      const newDate = new Date();
      let now = new Date();
      now = getLastWeeksDate();

      function getLastWeeksDate() {
        const now = new Date();

        return new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
      }

      function getLast30Date() {
        const now = new Date();

        return new Date(now.getFullYear(), now.getMonth(), now.getDate() - 30);
      }
      function getLastmonthDate() {
        const now = new Date();

        return new Date(now.getFullYear(), now.getMonth() - 1, now.getDate());
      }
      function getLast6monthDate() {
        const now = new Date();

        return new Date(now.getFullYear(), now.getMonth() - 6, now.getDate());
      }
      function get2022Date() {
        const now = new Date();

        return new Date(now.getFullYear() - 1, 0, 1);
      }
      function get2021Date() {
        const now = new Date();

        return new Date(now.getFullYear() - 2, 0, 1);
      }
      function get2020Date() {
        const now = new Date();

        return new Date(now.getFullYear() - 3, 0, 1);
      }
      function get2019Date() {
        const now = new Date();

        return new Date(now.getFullYear() - 4, 0, 1);
      }
      function get2018Date() {
        const now = new Date();

        return new Date(now.getFullYear() - 5, 0, 1);
      }
      function get2017Date() {
        const now = new Date();

        return new Date(now.getFullYear() - 6, 0, 1);
      }
      if (value === "1") {
        console.log("here 1");
        setFormData((prevFormData) => {
          const formattedStartDate = `${newDate.getFullYear()}-${String(
            newDate.getMonth() + 1
          ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`;
          const formattedEndDate = `${newDate.getFullYear()}-${String(
            newDate.getMonth() + 1
          ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`;

          return {
            ...prevFormData,
            from: formattedStartDate,
            to: formattedEndDate,
          };
        });
      }

      if (value === "2") {
        console.log("here 2");

        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
              2,
              "0"
            )}-${String(now.getDate()).padStart(2, "0")}`,
            to: `${newDate.getFullYear()}-${String(
              newDate.getMonth() + 1
            ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
          };
        });
      }

      if (value === "3") {
        console.log("here 3");
        now = getLast30Date();
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
              2,
              "0"
            )}-${String(now.getDate()).padStart(2, "0")}`,
            to: `${newDate.getFullYear()}-${String(
              newDate.getMonth() + 1
            ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
          };
        });
      }
      if (value === "4") {
        console.log("here 4");
        now = getLast6monthDate();
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
              2,
              "0"
            )}-${String(now.getDate()).padStart(2, "0")}`,
            to: `${newDate.getFullYear()}-${String(
              newDate.getMonth() + 1
            ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
          };
        });
      }
      if (value === "5") {
        console.log("here 5");
        now = get2022Date();
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
              2,
              "0"
            )}-${String(now.getDate()).padStart(2, "0")}`,
            to: `${newDate.getFullYear()}-${String(
              newDate.getMonth() + 1
            ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
          };
        });
      }
      if (value === "6") {
        // console.log('here 5');
        now = get2021Date();
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
              2,
              "0"
            )}-${String(now.getDate()).padStart(2, "0")}`,
            to: `${newDate.getFullYear()}-${String(
              newDate.getMonth() + 1
            ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
          };
        });
      }
      if (value === "7") {
        // console.log('here 5');
        now = get2020Date();
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
              2,
              "0"
            )}-${String(now.getDate()).padStart(2, "0")}`,
            to: `${newDate.getFullYear()}-${String(
              newDate.getMonth() + 1
            ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
          };
        });
      }
      if (value === "8") {
        // console.log('here 5');
        now = get2019Date();
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
              2,
              "0"
            )}-${String(now.getDate()).padStart(2, "0")}`,
            to: `${newDate.getFullYear()}-${String(
              newDate.getMonth() + 1
            ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
          };
        });
      }
      if (value === "9") {
        console.log("here 5");
        now = get2018Date();
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
              2,
              "0"
            )}-${String(now.getDate()).padStart(2, "0")}`,
            to: `${newDate.getFullYear()}-${String(
              newDate.getMonth() + 1
            ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
          };
        });
      }

      if (value === "10") {
        // console.log('here 5');
        now = get2017Date();
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
              2,
              "0"
            )}-${String(now.getDate()).padStart(2, "0")}`,
            to: `${newDate.getFullYear()}-${String(
              newDate.getMonth() + 1
            ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
          };
        });
      }
    }
    console.log(formData);
  }
  const page2fun=()=>{
    if (page2 === 1) {
      setSelectedordersection(allorders.pending_orders)
    }
    else if (page2 === 2) {
      setSelectedordersection(allorders.alloted_order_to_serviceprovider)
    }
    else if (page2 === 3) {
      setSelectedordersection(allorders.shipped_by_providers_order)
    }
    else if (page2 === 4) {
      setSelectedordersection(allorders.order_recieved_at_areamanager)
    }
    else if (page2 === 5) {
      setSelectedordersection(allorders.shipped_orders_by_manager)
    }
    else if (page2 === 6) {
      setSelectedordersection(allorders.order_cancel_by_serviceprovider)
    }
    else if (page2 === 7) {
      setSelectedordersection(allorders.order_partial_action_by_providers)
    }
    else if (page2 === 8) {
      setSelectedordersection(allorders.delivered_order_by_manager)
    }
    else if (page2 === 9) {
      setSelectedordersection(allorders.cancelled_order_by_manager)
    }
    else if (page2 === 10) {
      setSelectedordersection(allorders.cancelled_order_by_user)
    }
  }
React.useEffect(()=>{
page2fun();
},[allorders])
  const orderfetching= async () =>{
    let start_date = `${formData.from} 00:00:00`;
      let end_date = `${formData.to} 23:59:59`;
      const response = await adminloginapi.Managerorders(start_date, end_date)
      if (response.data && response.data.error != "") {
        notify("error", response.data.error);
      } else {
        setAllorders(response.data)
        // if(stage==="Initial"){
        //   setSelectedordersection(response.data.pending_orders)
        // }
        // else if (stage==="notinitial"){
        //   console.log("kakkaak")
        //   page2fun();
        // }
        setLoading(false);
      }
  }
  React.useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      // setLoading(false);
      orderfetching("Initial");
    }

    // Check if the user is logged in before making the API call
    if (props.isLoggedIn) {
      fetchData();
    }
  }, [props.isLoggedIn]);

  React.useEffect(()=>{
    setLoading(true);
    if(initialref1.current){
      initialref1.current=false;
      return ;
    }
    orderfetching();
    // page2fun();
  },[formData.from, formData.to])

  React.useEffect(() => {
    page2fun();
  }, [page2])
  return (
    <>
      <Adminheader isLoggedIn={props.isLoggedIn} setisLoggedIn={props.setIsLoggedIn} />
      {page3 === 0 ? (<> <div className="admin-profile-time-filter-calander-box">

        <div className="admin-time-filter-calander-1">
          <span>From</span>
          <input
            type="date"
            onChange={handleChange}
            name="from"
            value={formData.from}
          ></input>
        </div>
        <div className="admin-time-filter-calander-1">
          <span>Till</span>
          <input
            type="date"
            onChange={handleChange}
            name="to"
            value={formData.to}
          ></input>
        </div>
        <div className="admin-time-filter-calander-3">
          <span>Sort By : </span>
          <select onChange={handleChange} name="editFrom">
            {/* <option value={0}>Time Filter</option> */}
            <option value={1} >Today</option>
            <option value={2} >Last One Week</option>
            <option value={3}>Last 30 Days</option>
            <option value={4}>Last 6 Months</option>
            <option value={5}>2022</option>
            <option value={6}>2021</option>
            <option value={7}>2020</option>
            <option value={8}>2019</option>
            <option value={9}>2018</option>
            <option value={10}>2017</option>
          </select>


        </div>
      </div>

        <AdminTabs page2={page2} setPage2={setPage2} tabSelected={tabSelected} setTabSelected={setTabSelected} setLoading={setLoading} data={allorders}/>

        <div className='cardsection'>
          {loading ? <Loading /> : <>
            {
              selectedordersection?.length !== 0 ? <>{
                selectedordersection?.map((items) => {
                  return (
                    <Cards data={items} setBillData={setBillData} setPage3={setPage3} tabSelected={tabSelected} allorderfun={orderfetching} loading={setLoading}></Cards>
                  )
                })
              }</> : <>

                <div className='adminnoresult'>
                  <img src={noshow} /></div>
              </>
            }
          </>
          }
        </div></>) : (page3 === 1 &&
          <div className="admin-page-view-main-right">
            <BillDetailsView
              fulldata={allorders}
              data={billData}
              tabSelected={tabSelected}
              isMobileView={isMobileView}
              return={exitDetail}
              allordersfun={orderfetching}
              setbilldatafun={setBillData}
              page3fun={setPage3}
              Loading={setLoading}
            ></BillDetailsView>
          </div>
      )}
    </>
  )
}

export default Dashboard


function BillDetailsView(props) {
  let idvalue=0;
  let serviceprovidercheck=0;
  const [datavalue,setDatavalue]=useState(null);
  const [providerlist,setProviderlist]=useState([]);
  const [isproviderlistopen,setIsproviderlistopen]=useState(false);
  const [choosestatus,setChoosestatus]=React.useState([]);
  const [loading,setLoading]=React.useState(true);
  const [productmodal,setproductmodal]=React.useState([]);
  const initialref=useRef(true);
  const [bookingidproducts,setbookingproducts]=React.useState([]);
  const [selectedinndex,setSelectedinndex]=useState(null);
  const providerlistref=useRef("");
  React.useEffect(()=>{
    const fetchdata = async ()=>{
      let response = await adminloginapi.ServiceProviderlist({
        booking_id:props.data.id
      });
      if (response.data && response.data.error != "") {
        notify("error", response.data.error);
      }
      else {
        setProviderlist(response.data.lists);
        setChoosestatus(response.data.lists.map(()=>{
          return false;
        }))
        setproductmodal(response.data.lists.map(()=>{
          return false;
        }))

      }
      setLoading(false);
    }
    if(datavalue !== null){
      fetchdata();
    }
  },[datavalue])

  React.useEffect(()=>{
     if(providerlist.length !=0){
      setbookingproducts([]);
setbookingproducts((prevbookingidproducts)=>{
  return providerlist.map((item,index)=>{
   return [...prevbookingidproducts,item.products.map((item1,index1)=>{
      return item1.product_booking_id;
    })]
  })
})
    }
  },[providerlist])

  React.useEffect(()=>{
if(initialref.current){
  initialref.current=false;
  return ;
}
const idvalueincre= ()=>{
  idvalue++
}
const fetchdata12 = async ()=>{
  console.log(datavalue);
  let response = await adminloginapi.Assign_ServiceProvider({
    product_booking_id:bookingidproducts[selectedinndex][0],
    serviceprovider_id:providerlist[choosestatus.indexOf("choosing")].serviceprovider_id,
  });
  if (response.data && response.data.error != "") {
    notify("error", response.data.error);
    setChoosestatus(choosestatus.map((item)=>{
      if(item=="choosing"){
        return false;
      }
      return item;
    }))
  }
  else {
    // setProviderlist(response.data.lists);
    setChoosestatus(choosestatus.map((item)=>{
      if(item=="choosing"){
        return true;
      }
      return item;
    }))
    const fetchdatanext = async ()=>{
      let response = await adminloginapi.ServiceProviderlist({
        booking_id:props.data.id
      });
      if (response.data && response.data.error != "") {
        notify("error", response.data.error);
      }
      else if(response.data.lists.length > 0){
        setProviderlist(response.data.lists);
        setChoosestatus(response.data.lists.map(()=>{
          return false;
        }))
        setproductmodal(response.data.lists.map(()=>{
          return false;
        }))

      }
      else if(response.data.lists.length == 0){
        setIsproviderlistopen(false);
        props.allordersfun();
      }
      setLoading(false);
    }
    // setIsproviderlistopen(false);
    setLoading(true)
    fetchdatanext();
    setChoosestatus([]);
    // props.loadingfun(true);
  }
}
if(choosestatus.includes("choosing")){
  fetchdata12();
}
},[choosestatus]);

React.useEffect(()=>{
  if(initialref.current){
    initialref.current=false;
    return;
  }
  let index=props.fulldata.pending_orders.findIndex((item1)=>{
      return item1.order_id == props.data.order_id
     })
if(props.tabSelected === "Pending Orders" && index === -1){
  props.page3fun(0);
  return;
}
// else{
//   setIsproviderlistopen(false);
//   setChoosestatus([]);
// }
},[props.fulldata])

React.useEffect(()=>{
  if(props.tabSelected ==="Pending Orders"){   
    props.setbilldatafun(...props.fulldata.pending_orders.filter((item1)=>{
      return item1.order_id == props.data.order_id
     }))
  }
},[props.fulldata])

  return (
    <React.Fragment>
      <>
        <div className="admin-page-view-main-right-data">
        
          <div className='admin-page-view-main-right-heading-data admin-page-view-main-right-data-bill-option'>
            <div className='viiewsecction'>
              <div>
              <img src={back} onClick={()=>{
                props.return();
                }}></img>
              <span>View Details</span>
              </div>
              <>
              {
              (props.tabSelected==="Pending Orders") && 
                       <>
                      <button data-value={idvalue++}  onClick={(event)=>{
                       setDatavalue(props.data.items[event.target.getAttribute("data-value")]) 
                       console.log(props.data.items[event.target.getAttribute("data-value")])
                       setIsproviderlistopen(true);
                      }} className='availbablleservice'>Available Serviceprovider</button>
                      </>
                    }
                      </>
            </div>


          </div>
          <div className="admin-page-view-main-right-data-box">
            {props &&
              props.data &&
              props.data.items &&
              props.data.items.length > 0 &&
              props.data.items.map((item) => {
                const isNotOrdersTab = props.tabSelected !== "Orders alloted to ServiceProvider";
                return (
                  <>
                  <div className={`admin-page-view-main-right-data-box-card `}>
                    <div className="admin-page-view-main-right-data-box-card-img">
                      <img src={item.image || logo} alt="itemImage" />
                    </div>
                    <div className={`admin-page-view-main-right-data-box-card-des ${!props.isMobileView && props.tabSelected == "Pending Orders" && `onlyforpendingdes`}`}>
                      <span>{item.brand}</span>
                      <span>{item.name}</span>
                      <span>{item.product_type}</span>
                      {!props.isMobileView && (<span className='admin-page-view-main-right-data-box-card-color admin-page-view-main-right-data-box-card-color1'>{`${item.qty} / ${item.pcs}unit / `}
                      {!props.isMobileView && item.color.length > 0 ? <span className='colorsdivvs'>
                      <span className="admin-initial-choice service-color-choice" style={{
                        backgroundColor:item.color[0].color_code,
                      }}
                      >
                      </span> {item.color[0].color_name}
                    </span>:"No Color"}
                      </span>)}
                      {/* Show qty, unit, and color in mobile view */}
                      {props.isMobileView && (
                        <div className='admin-page-view-main-right-data-box-card-mobile-info'>
                          <span>{item.qty}</span>
                          <span> | {item.pcs} Units </span>

                          {item.color && item.color.length > 0 ? (
                            <div className='admin-page-view-main-right-data-box-card-color'>
                              | <div
                                className="initial-choice service-color-choice"
                                style={{
                                  backgroundColor: item.color[0].color_code,
                                }}
                              >
                              </div>{" "}
                              {item.color[0].color_name}
                            </div>
                          ):<>
                          </>}
                        </div>)}
                        {props.isMobileView &&  
                        <>
                        {
                     <div className="admin-page-view-main-right-data-box-card-provider-button">
                          {
                         (item?.product_status === 11)? 
                         <>
                          <div className='alloteddserviceproviderr' data-value={idvalue++}>
                            Alloted to {item?.serviceprovider?.name}
                          </div>
                          </>
                            :
                          <>
                          {
                          <>
                          {
                          (item?.product_status === 12)? 
                          <>
                           <div className='alloteddserviceproviderr' data-value={idvalue++}>
                           Order Delivered to Areamanager
                          </div>
                          </>:
                          <>
                          {
                           (item?.product_status === 2)?<>
                           <div className='alloteddserviceproviderr' data-value={idvalue++}>
                           Order Shipped
                          </div>
                           </>:
                           <>
                        {
                          (props.tabSelected !== "Pending Orders" && item?.product_status === 1)?
                              <>
                              <div className='alloteddserviceproviderr' data-value={idvalue++}>
                              Order Cancelled
                            </div>
                              </> : 
                              <>
                              {
                          (item?.product_status === 14)?
                              <>
                              <div className='alloteddserviceproviderr' data-value={idvalue++}>
                              Order Cancelled by user
                            </div>
                              </> : 
                              <>
                              </>
                       }
                              </>
                       }   
                           </>
                          }
                          </>
                          }
                          </>
                          // }
                          // </>
              }
                          </>
                        }
                        </div>
                        // <>
                        // <div className="admin-page-view-main-right-data-box-card-provider-button">
                        // {/* <button onClick={(event)=>{
    
                        // }}>Alloted Service provider</button> */}
                        // <div  className='alloteddserviceproviderr'>
                        //     Alloted to {item.serviceprovider.name}
                        //   </div>
                        // </div>
                        // </>
                  }
                        </>
                      }
                    </div>
                    {/* {!props.isMobileView && item.color && item.color.length > 0 && <div className='admin-page-view-main-right-data-box-card-color'>
                      <div className="admin-initial-choice service-color-choice" style={{
                        backgroundColor:
                          item.color[0].color_code,
                      }}
                      >
                      </div> {item.color[0].color_name}
                    </div>} */}
                    {/* {!props.isMobileView && <div className="admin-page-view-main-right-data-box-card-qty">
                      Units : {item.pcs}
                    </div>} */}
                    <div className={`admin-page-view-main-right-data-box-card-price ${isNotOrdersTab ? 'admin-page-view-main-right-data-box-card-not-serviceprovider-price' : ''} ${!props.isMobileView && props.tabSelected == "Pending Orders" && `onlyforpendingprrice`}`}>
                      <span>&#8377; {item.price}</span>
                    </div>
                    {!props.isMobileView && (item?.product_status !== 1) && 
                    <>
                    {
                 <div className="admin-page-view-main-right-data-box-card-provider-button">
                      {
                     (item?.product_status === 11)? 
                     <>
                      <div className='alloteddserviceproviderr' data-value={idvalue++}>
                        Alloted to {item?.serviceprovider?.name}
                      </div>
                      </>:
                      <>
                      {
                      <>
                      {
                      (item?.product_status === 12)? 
                      <>
                       <div className='alloteddserviceproviderr' data-value={idvalue++}>
                       Order Delivered to Areamanager
                      </div>
                      </>:
                      <>
                      {
                       (item?.product_status === 2)?<>
                       <div className='alloteddserviceproviderr' data-value={idvalue++}>
                       Order Shipped
                      </div>
                       </>:
                       <>
                       {
                          (props.tabSelected !== "Pending Orders" && item?.product_status === 1)?
                              <>
                              <div className='alloteddserviceproviderr' data-value={idvalue++}>
                              Order Cancelled
                            </div>
                              </> : <></>
                       }
                       </>
                      }
                      </>
                      }
                      </>
                      }
                      </>
                    }
                    </div>
                    // <>
                    // <div className="admin-page-view-main-right-data-box-card-provider-button">
                    // {/* <button onClick={(event)=>{

                    // }}>Alloted Service provider</button> */}
                    // <div  className='alloteddserviceproviderr'>
                    //     Alloted to {item.serviceprovider.name}
                    //   </div>
                    // </div>
                    // </>
              }
                    </>
                    }


           
                  </div>
                 { isproviderlistopen &&  <>   <div className='providerlists'> 
                    <div className='providerlistmodal' ref={providerlistref}>
                      <div className="admin-page-view-main-right-heading-data admin-page-view-main-right-data-bill-option1">
                      <div>
                        <img src={back} onClick={()=>{
                          setIsproviderlistopen(false);
                          // setChoosestatus([]);
                          props.Loading(true);
                          props.allordersfun();
                        }}
                        alt='back'
                        ></img>
                        <span>Service Provider Lists</span>
                        </div>
                      </div>

                      <div className='admin-page-view-main-right-data-box-cardparent'>
                      {loading && <><Loading></Loading></>}
                    { providerlist.length != 0 && !loading && <>
                    {
                      providerlist.map((item,index)=>{
                        console.log(item);
                        return (
                    <div className={`admin-page-view-main-right-data-box-card1 `}>
                      <div className='seerviceimage'>
                    <div className="admin-page-view-main-right-data-box-card-img admin-page-view-main-right-data-box-card-img1">
                      <img src={item.image || logo} alt="itemImage" />
                    </div>
                    <div className='namephone'>
                      <span className="naaame">{item.name}</span>
                      <span className='phhhone'>Phone no:{item.phone}</span>
                    </div>
                      </div>
                    {/* <div className="admin-page-view-main-right-data-box-card-des admin-page-view-main-right-data-box-card-des11">
                    <span>{item.name}/{item.phone}</span>
                    </div> */}
                    <div className='admin-page-view-main-right-data-box-card-mobile-info'>
                      <span className="naaame">Total Available Product</span>:{item.product_count[0]}
                    </div>
                    <div className='admin-page-view-main-right-data-box-card-mobile-info'>
                    <div className="my-order-profile-card-box-item-status-view-more">
                <div className="my-order-profile-card-box-item-status-more-images">
                  {item.products && item.products.slice(0,1).map((item1, index) => (
                    <React.Fragment key={index}>
                        <img src={item1.product_image} alt={`Color Image ${index}`} className='serviceprokaimage' />
                    </React.Fragment>
                  ))}
                  {item.product_count[0] > 2 && <><span className='adddd'>+{item.product_count[0]-1}</span></>}
                {<button onClick={()=>{
                  setproductmodal((prevproductmodal)=>{
                    return prevproductmodal.map((item,index1)=>{
                      if(index==index1){
                        return true;
                      }
                      return false;
                    })
                  })
                }} className='viewprod' id={index}>View Product(s)</button>
                }
                {productmodal[index] && <Productlist products={providerlist} close={setproductmodal} id={index} isMobileView={props.isMobileView}></Productlist>}
                </div>
              </div>
                    </div>
                    <div className='admin-page-view-main-right-data-box-card-buttonss'>
                      <div className={!choosestatus[index] ? "Choose":"Choosed"} onClick={()=>{
                        if(choosestatus.includes(true) || choosestatus.includes("choosing")){
                          return ;
                        }
                        setChoosestatus(choosestatus.map((value,index2)=>{
                          if(index == index2){
                            console.log("entered")
                            setSelectedinndex(index)
                            return "choosing";
                          }
                          return choosestatus[index2];
                        }))
                      }}>{!choosestatus[index] ? "Choose": <>{choosestatus[index]==="choosing" ? "choosing":"Choosed"}</>}</div>

                      {/* <div class="buttonsectionelement">View Address</div> */}
                    </div>
                    </div>
                        )
                      })
                    }
                      </>
              } :
                    </div>
              </div>
                      </div>
                    </>
                  }
                  </>
                );
                 
              })}
                                 
          </div>




        </div>
      </>
    </React.Fragment>
  );
}

function Productlist (props){
  return (
    <>
        <div className="my-order-profile-card-box-item-detailsadmin">
          <div className="my-order-profile-card-box-item-details-contentadmin">
            <div className='HEADINGGADMIN'>
            <div className='heading headingproductlist'>Total Item(s): {props.products[props.id].product_count[0]}</div>
            <button class="my-orders-modal-details-close-btnadmin" onClick={()=>{
              props.close((prevproductmodal)=>{
                return prevproductmodal.map((item,index1)=>{
                  return false;
                })
              })
            }}>X</button>
          </div>

          <div className='productlistadminmainbody'>
            {
              props.products[props.id].products.map((item,index)=>{
                return (
                  <>
                  <div className={`admin-page-view-main-right-data-box-card `}>
                    <div className="admin-page-view-main-right-data-box-card-img">
                      <img src={item.product_image} alt="itemImage" />
                    </div>
                    <div className={`admin-page-view-main-right-data-box-card-des ${!props.isMobileView && `onlyforpendingdes`}`}>
                      <span>{item.brand}</span>
                      <span>{item.name}</span>
                      <span>{item.product_name}</span>
                      <span>{item.product_type}</span>
                      {!props.isMobileView && (<span className='admin-page-view-main-right-data-box-card-color admin-page-view-main-right-data-box-card-color1'>{`${item.qty} / ${item.pcs}unit / `}
                      {item.color_code ? <span className='colorsdivvs'>
                      <span className="admin-initial-choice service-color-choice" style={{
                        backgroundColor:item.color_code,
                      }}
                      >
                      </span> {item.color_name}
                    </span>:"No Color"}
                      </span>)}
                      {/* Show qty, unit, and color in mobile view */}
                      {props.isMobileView && (
                        <div className='admin-page-view-main-right-data-box-card-mobile-info'>
                          <span>{item.qty}</span>
                          <span> | {item.pcs} Units </span>

                          {item.color_code && (
                            <div className='admin-page-view-main-right-data-box-card-color'>
                              | <div
                                className="initial-choice service-color-choice"
                                style={{
                                  backgroundColor: item.color_code,
                                }}
                              >
                              </div>{" "}
                              {item.color_name}
                            </div>
                          )}
                        </div>)}
                       
                    </div>
                    <div className={`admin-page-view-main-right-data-box-card-price`}>
                      <span className='prriceee'>&#8377; {item.price}</span>
                    </div>
                  </div>
                  </>
                )
              })
            }
          </div>
          </div>
          </div>
    </>
  )
}