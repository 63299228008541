import React, { Component } from 'react';
import '../serviceprovider-css/advertisment.css';
import starf from '../images/Starf.svg';
import star from '../images/Star.svg';
import photo from '../images/advertisment-image.svg';
import location from '../images/ad-location.svg'
import car from '../images/ad-car.svg'
import store from '../images/ad-store.svg'
import { Link, useNavigate } from 'react-router-dom';
import { BsFillChatRightDotsFill, BsChatDots, BsShareFill } from "react-icons/bs";
import chatapi from '../api/chatapi';
import logo from "../images/logo.png"
import notify from '../error';
import phone from '../images/ad-call.svg'
import chat from '../images/comment-multiple-svgrepo-com.svg';


export default function Advertisement(props) {
  const navigate = useNavigate();
  console.log(props)
  

  const chatwithprovider = async () => {
    const response = await chatapi.ConnectToANewServiceProvider("5")
    console.log(response)
    if (response.data.error !== '') {
      notify('error', response.data.error)
    }
    else {
      navigate('/chat/seller')
    }
  }
  const handlePhoneClick = () => {
    
    window.location.href = `tel:${8095765454}`;
  };
  return (
    <React.Fragment>
    <div className='service-provider-advertisement d-none'>
      <div className='service-provider-advertisement-image-container'>
        <div className='service-provider-advertisement-image'>
          <img src={logo}></img>
          hidden
        </div>
        
       
      </div>

      <div className='service-provider-advertisement-info-container'>
        <div className='service-provider-advertisement-name-container'>
          <div className='service-provider-advertisement-name'>
            PUTATOE
          </div>
          <div className='service-provider-advertisement-time'>
            Opens At : 8:00 Am -10 : 00 Pm
          </div>
        </div>

      

        <div className='service-provider-advertisement-popularity-container'>
          <div className='service-provider-advertisement-follower-container'>
            <div className='service-provider-advertisement-follower-number'>
              1000
            </div>
            <div className='service-provider-advertisement-follower-text'>
              Follower
            </div>
          </div>
          <div className='service-provider-advertisement-break-container'></div>
          <div className='service-provider-advertisement-following-container'>
            <div className='service-provider-advertisement-follower-number'>
              20
            </div>
            <div className='service-provider-advertisement-follower-text'>
              Following
            </div>
          </div>
          <div className='service-provider-advertisement-break-container'></div>
          <div className='service-provider-advertisement-item-container'>
            <div className='service-provider-advertisement-follower-number'>
              {props.prodlen}
            </div>
            <div className='service-provider-advertisement-follower-text'>
              Items
            </div>
          </div>
        </div>

        <div className='service-provider-advertisement-details-container'>
          <div className='service-provider-advertisement-location-container'>
            <div className='service-provider-advertisement-detail-image-container'>
              <img
                src={location}
                className='service-provider-advertisement-detail-image'
              ></img>
            </div>
            <div className='service-provider-advertisement-detail-text'>
           Putatoe Office ,233 ,Harahawa Phatak, Near SBI Bank, Gorakhpur,Uttar Pradesh
            </div>
          </div>
          <div className='service-provider-advertisement-delivery-container'>
            <div className='service-provider-advertisement-detail-image-container'>
              <img
                src={car}
                className='service-provider-advertisement-detail-image'
              ></img>
            </div>
            <div className='service-provider-advertisement-detail-text'>
              Home delivery available
            </div>
          </div>
          <div className='service-provider-advertisement-pickup-container'>
            <div className='service-provider-advertisement-detail-image-container'>
              <img
                src={store}
                className='service-provider-advertisement-detail-image'
              ></img>
            </div>
            <div className='service-provider-advertisement-detail-text'>
              Store Pickup available
            </div>
          </div>
        </div>

        <div className='service-provider-advertisement-other-container'>
          <div className='service-provider-advertisement-chat'>
            <img src={chat} onClick={chatwithprovider}></img>

          </div>
          <div className='service-provider-advertisement-phone'>
            <img src={phone} onClick={handlePhoneClick}></img>
          </div>
          <div className='service-provider-advertisement-phone'>
            <BsShareFill size={24} color='#fff' />
          </div>

        </div>

      </div>

    </div>
    {/* mobileview */}
    <div className='service-provider-advertisement  service-provider-advertisement-mobileview '>
      <div className='service-provider-advertisement-image-container'>
        <div className='service-provider-advertisement-image'>
          <img src={logo}></img>
        </div>
       
      </div>

      <div className='service-provider-advertisement-info-container'>
        <div className='service-provider-advertisement-name-container'>
          <div className='service-provider-advertisement-name'>
          PUTATOE
          </div>
          <div className='service-provider-advertisement-time'>
            Opens At : 8:00 Am -10 : 00 Pm
          </div>
        </div>





        <div className='service-provider-advertisement-details-container'>
          <div className='service-provider-advertisement-location-container'>
            <div className='service-provider-advertisement-detail-image-container'>
              <img
                src={location}
                className='service-provider-advertisement-detail-image'
              ></img>
            </div>
            <div className='service-provider-advertisement-detail-text'>
            Putatoe Office ,233 ,Harahawa Phatak, Near SBI Bank, Gorakhpur,Uttar Pradesh
            </div>
          </div>
          <div className='service-provider-advertisement-delivery-container'>
            <div className='service-provider-advertisement-detail-image-container'>
              <img
                src={car}
                className='service-provider-advertisement-detail-image'
              ></img>
            </div>
            <div className='service-provider-advertisement-detail-text'>
              Home delivery available
            </div>
          </div>
          <div className='service-provider-advertisement-pickup-container'>
            <div className='service-provider-advertisement-detail-image-container'>
              <img
                src={store}
                className='service-provider-advertisement-detail-image'
              ></img>
            </div>
            <div className='service-provider-advertisement-detail-text'>
              Store Pickup available
            </div>
          </div>
        </div>






      </div>
      <div className='service-provider-advertisement-other-container' style={{gridTemplateColumns:"repeat(3,1fr)",width:"30%",margin:"0 auto"}}>
          <div className='service-provider-advertisement-chat'>
            <img src={chat} onClick={chatwithprovider}></img>

          </div>
          <div className='service-provider-advertisement-phone'>
            <img src={phone} onClick={handlePhoneClick}></img>
          </div>
         
        
         
          <div className='service-provider-advertisement-phone'>
            <BsShareFill size={24} color='#fff' />
          </div>

        </div>
      <div className='service-provider-advertisement-popularity-container' style={{margin:"0 auto",gap:"0px",width:"65%"}}>
          <div className='service-provider-advertisement-follower-container'>
            <div className='service-provider-advertisement-follower-number'>
              1000
            </div>
            <div className='service-provider-advertisement-follower-text'>
              Follower
            </div>
          </div>
          <div className='service-provider-advertisement-break-container'></div>
          <div className='service-provider-advertisement-following-container'>
            <div className='service-provider-advertisement-follower-number'>
              20
            </div>
            <div className='service-provider-advertisement-follower-text'>
              Following
            </div>
          </div>
          <div className='service-provider-advertisement-break-container'></div>
          <div className='service-provider-advertisement-item-container'>
            <div className='service-provider-advertisement-follower-number'>
            {props.prodlen}
            </div>
            <div className='service-provider-advertisement-follower-text'>
              Items
            </div>
          </div>
        </div>
        

    </div>
  



          

  </React.Fragment>
  );
}
