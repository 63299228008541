import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import cmt from '../../images/blogimages/Comments.svg'
import postapi from '../../api/postapi';
import BlogComment from './BlogComment';
import { useGlobalContext } from '../../context';
import notify from '../../error';
import { FaPaperPlane } from "react-icons/fa";
import cnacleUpload from '../../images/blogimages/cnacleUpload.svg';
import share from '../../images/blogimages/shareImg.svg';
import photo from "../../images/post-photo.svg";
import mic from "../../images/post-mic.svg";

import Loading from '../Loading';
import { AiOutlineLike } from 'react-icons/ai';
import { AiFillLike } from 'react-icons/ai';
import { FaUserCircle } from 'react-icons/fa';
import profileImage from "../../images/DTh/account.png";


import "../blog-css/singleblog.css";

function Singleblog({ blogInfo, page }) {
  // const blogId = parseInt(window.location.href.split("/id:")[1]);
  const noImage = "https://images.unsplash.com/photo-1594322436404-5a0526db4d13?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1129&q=80";
  const [singleBlog, SetsingleBlog] = useState();
  const [singlBlogComment, setSingleBlogComment] = useState('');
  const [fetchedComment, setFetchedComment] = useState();
  const { setModifyModal, loading, setLoading, blogUpdate, setBlogUpdate, Userprofile, setDisplay, setLoginPage } = useGlobalContext();
  const [likeUpdate, setLikeUpdate] = useState(false);


  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isCommentActive = queryParams.get('param1') === 'comment';

  const getSingleBlogData = async (blogId) => {
    setLoading(true);
    try {
      const res = await postapi.displaySingleBlog(blogId);
      if (res) {
        SetsingleBlog(res.data.data[0]);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  }

  const postBlogComment = async (id) => {
    if (!Userprofile) {
      setModifyModal(false);
      setDisplay("login-sidebar-container2");
      setLoginPage(1);

      notify("error", "Login First To Comment");
      return;
    }
    try {
      const res = await postapi.AddBlogComment(id, singlBlogComment);
      if (res.status === 200) notify("success", "Comment added successfully...");
      fetchBlogCommentFunction(id);
      setBlogUpdate(prevBlogUpdate => !prevBlogUpdate);
    } catch (err) {
      console.log(err);
    }
  }

  const fetchBlogCommentFunction = async (id) => {
    try {
      const res = await postapi.FetchBlogComment(id);
      if (res) {
        setFetchedComment(res.data.comments);
      }
    } catch (err) {
      console.log(err);
    }
  }

  const handleLike = async (id, value) => {
    if (value === undefined || value === false) {
      try {
        const likeResp = await postapi.setBlogLike(id);
        if (likeResp.status === 200) setLikeUpdate(true);

      } catch (err) {
        console.log(err);
      }
    } else {
      try {
        const dislikeResp = await postapi.setBlogDislike(id);
        if (dislikeResp.status === 200) setLikeUpdate(false);
      } catch (err) {
        console.log(err);
      }
    }
  };
  // fetch singleBlog data when page loads

  // const handleShareClick = async (blogId, bodyData) => {
  //   const res = await postapi.shareBlog(blogId, bodyData);
  //   console.log(res);
  // }
  const handleShareClick = async (id, bodyData) => {
    try {
      const res = await postapi.shareBlog(id, bodyData);
      if (res.data.error === '') {
        notify("success", "Successfully shared Blog...");
        setBlogUpdate(prevBlogUpdate => !prevBlogUpdate);
      }
    } catch (err) {
      notify("err", err.message);
    }
  }
  useEffect(() => {
    getSingleBlogData(blogInfo[0]);
    fetchBlogCommentFunction(blogInfo[0]);
  }, [blogInfo, likeUpdate, blogUpdate])

  const enterKeyHandler = (event) => {
    if (event.key === "Enter") {
      if (!Userprofile) {
        notify('warn', "Please log in first...");
        setSingleBlogComment("");
        event.target.blur();
        return;
      }
      event.target.blur();
      setSingleBlogComment("");
      postBlogComment(blogInfo[0]);
    }
  }

  // const showmoreoption = () => {
  //   const moreOption2 = document.querySelector(".moreOption2");
  //   moreOption2.style.display === "block" ? moreOption2.style.display = "none" : moreOption2.style.display = "block"
  // }
  // console.log(blogInfo)
  return (
    <>
      {singleBlog ?
        <div className="perticulerBlog" >
          <div className="perticulerBlogDiv">
            <img className='crossIcon' src={cnacleUpload} alt="cross_icon"
              onClick={() => {
                setModifyModal(false)
              }} />

            <div className="moreOption2 " style={{ display: "none" }}>
              <ul>
                <li>Save</li>
                <li className="text-danger">Support</li>
              </ul>
            </div>
            <div className='particularBlogUserProfileRow'>
              <div className='userProfileImage'>
                {blogInfo[1] ? <img src={blogInfo[1]} />
                  : <FaUserCircle size={42} color='#00838f' />
                }
              </div>
              <div className='userProfileInfo'>
                <p>{singleBlog.name}</p>
                <p className='date'>{singleBlog.datetime}</p>
              </div>
            </div>
            <div className="perticulerBlogTitle">
              <h1>{singleBlog.hadline}</h1>
            </div>
            {singleBlog.detail !== "<p><br></p>" && (
              <div className="perticulerBlogContent">
                <p className='particularBlogDescription' dangerouslySetInnerHTML={{ __html: singleBlog.detail }} />
              </div>
            )}
            <div className="perticulerBlogImg ">
              {singleBlog.image ? (
                <img src={singleBlog.image} alt="singleblog_image" />
              ) :
                (
                  <img src={noImage} alt="singleblog_image" />
                )}
            </div>
            <h4 className='blogSmallHeading'>{fetchedComment?.length !== 0 ? "Comments" : "No comments"}</h4>
            {fetchedComment && fetchedComment.map((eachComment, index) => (
              <BlogComment
                key={index}
                comment={eachComment.comment}
                date={eachComment.DateTime}
                username={eachComment.username}
                commentId={eachComment.comment_id}
                userId={eachComment["user id"]}
                blogOwnerId={eachComment.blog_owner_userid}
                profileImage={eachComment.profile_image}
              />
            ))}

            <div className="likesAndMoreBlogs">
              <div className="likesBlogs">
                {
                  singleBlog.is_liked === true ?
                    <AiFillLike className='likeIcon' onClick={() => handleLike(singleBlog.id, singleBlog.is_liked)} /> :
                    <AiOutlineLike className='likeIcon' onClick={() => handleLike(singleBlog.id, singleBlog.is_liked)} />
                }
                <p>{singleBlog.totalLikes}</p>
              </div>
              <div className="comments">
                <img src={cmt} alt='commentIcon' /><span>{fetchedComment && (fetchedComment.length || 0)}</span>
              </div>
              <div className='shareBlog'
                onClick={(e) => handleShareClick(singleBlog.id, singleBlog.hadline)}
              ><img src={share} alt='shareIcon' />
                <p>{singleBlog.share_count || 0}</p>
              </div>
            </div>

            <div className='singleBlogComment'>
              {Userprofile ? (
                <img className='blogCommentUserProfileImg' src={Userprofile.profile_image} alt='blogUserProfileImg' />
              ) : (
                <FaUserCircle size={42} color='#00838f' />
              )}
              <input value={singlBlogComment}
                placeholder='Write a comment...'
                type='text'
                onChange={(e) => setSingleBlogComment(e.target.value)}
                {...(isCommentActive ? { autoFocus: true } : {})}
                onKeyDown={(e) => enterKeyHandler(e)}
              />
              {/* <FaPaperPlane className='sendIcon' onClick={() => postBlogComment(singleBlog.id)} /> */}
              {/* <div className="post-card-add-comment-image">
                <img src={mic}></img>
                <img src={photo}></img>
              </div> */}
              <FaPaperPlane className="sendIcon1" onClick={(event)=>{
          if (!Userprofile) {
            notify('warn', "Please log in first...");
            setSingleBlogComment("");
            event.target.blur();
            return;
          }
          event.target.blur();
          setSingleBlogComment("");
          if(singlBlogComment !== ""){
            postBlogComment(singleBlog.id);
            return
          }
          else{
            notify("error","Comment Should Not Be Empty")
          }
        }}/>
            </div>
          </div>
        </div>
        : <Loading />
      }
    </>
  )
}

export default Singleblog;
