import { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { useGlobalContext } from "../../context";
import notify from '../../error';
import myProfileServiceApi from "../../api/myProfileServiceApi";


export default function ModifyModal({ item, closeModal, callBackFunction }) {
  const [localQuantity, setLocalQuantity] = useState(item.quantity);

  const handleModalSubmit = async (cartId,changed_price) => {
    // console.log(changed_price);
    closeModal();
    try {
      const res = await myProfileServiceApi.ModifyGenerateBillProduct(
        localQuantity,
        cartId,
        changed_price
      );
      if (res.status === 200) {
        callBackFunction(true);
        notify("success", "Quantity changed successfully...");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <section className="modify-modal-overlay">

    <div className="generate-bill-modify-modal-container">
      <div className="generate-bill-modify-modal">
        <RxCross2 className="crossIcon" onClick={closeModal} />
        <h4 className="generate-bill-modify-modal-heading">
          Modify Unit
        </h4>
        <div>
          {console.log(item.quantity)}
          <input type="number" value={localQuantity} onChange={(e) => setLocalQuantity(parseInt(e.target.value))} />
          <span>units</span>
        </div>
        <button onClick={(e) => handleModalSubmit(item.cart_id,item.changed_price)}>submit</button>
      </div>
    </div>
    </section>
  )
}