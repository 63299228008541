import React, { Component, useRef, useState } from "react";
import "../serviceprovider-css/serviceProviderView.css";
import Advertisement from "./advertisment";
import SelectionPanel from "./seletionPanel";
import ServiceProviderCard from "./serviceProviderCard";
import { useServiceContext } from "../serviceContext.js";
import ProductImageModal from "./productImageModal";

import { FiFilter, FiX } from "react-icons/fi";


import PutatoeServiceApi from "../api/PutatoeServiceApi";
export default function ServiceProviderView() {
  // const { userName, setUserName } = useGlobalContext();
  const {
    image,
    setImage,
    IsServiceProviderApi,
    setIsServiceProviderApi,
    isError,
    setIsError,
    serviceDetail,
    setServiceDetail,
    productInfo,
    productDetail,
    username,
    setUsername,
    fetchServiceDetailFiltered,
    serviceDetail2,
    globalIndex,
    setGlobalIndex,
    globalIndex2,
    setGlobalIndex2,
    loading,
    setLoading,
    handleSubserviceChange,
    fetchServiceDetail,
    fetchServiceDetailindex,
    prodlen,
    setProdlen,
  } = useServiceContext();

  const [sidebarVisible, setSidebarVisible] = React.useState(false);

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };



  // console.log(username,'here')
  const {
    categoryData,
    brandData,
    productData,
    priceData,
    quantityData,
    setQuantityData,
  } = useServiceContext();
  // console.log(serviceDetail);
  const [selectedservice, setSelectedservice] = useState("");
  const [selectedSubservice, setSelectedSubservice] = useState("");
  const [selectedSubserviceName, setSelectedSubserviceName] = useState("");

  const [initialData, setInitialData] = React.useState([]);
  const [products, setProducts] = React.useState([]);
  let arr = [];

  // console.log(arr);
  //Set the initial products state based on serviceDetail data
  React.useEffect(() => {
    if (serviceDetail && serviceDetail[0]) {

      let arr = [];
      console.log(serviceDetail2);
      serviceDetail2.forEach((item) => {
        arr = arr.concat(item);
      });
      setInitialData(arr);
      setProducts(arr);
            setLoading(false);
            if (products.length < 18) {
              setGlobalIndex(1);
            }

      
      console.log(products);
    }
  }, [serviceDetail]);

 

  React.useEffect(() => {
    // Check if no filters are applied and set products state accordingly
    if (
      !brandData.length &&
      !productData.length &&
      !quantityData.length &&
      !priceData
    ) {
      // No filters are applied, use serviceDetail data

      setProducts(initialData);
      if (serviceDetail) {
        setProdlen(serviceDetail[0].product_count);
      }
      if (initialData.length < 18) {
        console.log("less than 18");
        setGlobalIndex(1);
 
      }
    }
  }, [brandData, productData, quantityData, priceData]);

  React.useEffect(() => {
    if (serviceDetail2 && serviceDetail2.length > 0) {
    
      console.log(serviceDetail2);
      serviceDetail2.forEach((element) => {
        // arr.push(element);
        // console.log(element.data.product_list);

        // console.log(element);
        arr.push(element);
      });
           
    }
  
    setProducts(arr);

    console.log(products);
  }, [serviceDetail2]);
  let value1, value2, value3, value4;

  const driverDataString = localStorage.getItem("driverData");
  
  if (driverDataString) {
    [value1, value2, value3, value4] = driverDataString.split(",");
  } else {
    console.log("driverData is not set in localStorage or is null");
  }
  
  const handleSubserviceSelection = (event) => {
    const selectedIndex = event.target.selectedIndex;
    const selectedOption = event.target.options[selectedIndex];
    console.log(event.target,selectedIndex,selectedOption);
    const selectedSubservice = event.target.value;
    const serviceId =  selectedOption.dataset.serviceid;
console.log(serviceId);
    setSelectedservice( serviceId)
    setSelectedSubservice(selectedSubservice);
    value3=selectedSubservice;
    value4=selectedSubservice

    let  value5=serviceId
    console.log(selectedservice,value5);
    localStorage.setItem("driverData", [value1, value2, value3, value4,value5].join(","));
    const name= serviceDetail[0]?.sub_service.find(
      (subService) =>
        subService.sub_service_id === parseInt(selectedSubservice)
    );
    setSelectedSubserviceName(name.sub_service_name);
  

if(selectedSubservice && selectedSubservice!=="" && selectedSubservice!==undefined)    {
  console.log("fetchServiceDetail");
  fetchServiceDetail(selectedSubservice);// for all filter data and subservice
}
    
   
  };

  React.useEffect(() => {
    if (serviceDetail && serviceDetail[0]) {
      console.log(serviceDetail[0]);
      const driverdata = localStorage.getItem("driverData").split(",");
      console.log(driverdata);
      const defaultSubservice = driverdata[3];
      console.log(defaultSubservice);

      // Find the selected subservice name based on the ID passed
      const selectedSubservicedefault = serviceDetail[0]?.sub_service.find(
        (subService) =>
          subService.sub_service_id === parseInt(defaultSubservice)
      );
      console.log(selectedSubservicedefault);
      if (selectedSubservicedefault && defaultSubservice !== "") {
        console.log("subservice changes");
localStorage.removeItem("sidsbid");
        setSelectedSubserviceName(selectedSubservicedefault.sub_service_name);
        setSelectedSubservice(selectedSubservicedefault.sub_service_id);
         setSelectedservice( selectedSubservicedefault.service_id)
         if(selectedSubservice && selectedSubservice!=="" && selectedSubservice!==undefined) {
          console.log(selectedservice,selectedSubservice);
          fetchServiceDetailFiltered(selectedservice,selectedSubservice)     // for all products data
        }
      } else {
        // setSelectedSubservice("");
        // setSelectedservice("")

    }

    }
  }, [selectedSubservice,serviceDetail]);
  // const handleSubserviceChange = async (event) => {
  //   const selectedValue = event.target.value;
  //   setSelectedSubservice(selectedValue);

  //   if (selectedValue === '') {
  //     // No subservice selected, show all products
  //     setProducts(initialData);
  //     if (initialData.length < 18) {
  //       setGlobalIndex(1);
  //     }
  //   } else {
  //     try {

  //       const response = await PutatoeServiceApi.ServiceProviderAllProduct(selectedValue,username[1])
  //       console.log("hee");
  //        arr = response.data.product_list
  //        console.log("hee2");
  //       console.log(arr);
  //    setProducts(arr);
  //    console.log(serviceDetail);

  //     } catch (error) {

  //     }
  //   }
  // };

  // const [clientWidth,setClientWidth]=React.useState()
  const [clientHeight, setClientHeight] = React.useState();
  React.useEffect(() => {
    let a, b;
    console.log("set clienthieght");
    // if(clientHeight!=)
    a = products.length / 5;
    a = Math.ceil(a);
    console.log(a);
    b = a * 320;
    setClientHeight(b);
    console.log(b, "ckientheight");
    setFired(0);
  }, [products]);

  const wrapperRef = React.useRef(null);

  const [fired, setFired] = React.useState(0);
  // const
  function handleScroll(e) {
    console.log(e.currentTarget.scrollTop);
    setScrollTop(e.currentTarget.scrollTop);
    // console.log(e)
    // if(clientWidth!==e.target.clientWidth)
    // {
    //   setClientWidth(e.target.clientWidth);
    // }

    console.log("reached in scroll");
    console.log(
      scrollTop,
      clientHeight - 640,
      fired,
      scrollTop > clientHeight - 640
    );
    if (scrollTop !== 0 && scrollTop > clientHeight - 640 && fired === 0) {
      setFired(1);
      console.log("did my work to call the loader");

      // LoadMoreProducts();
    }
  }

  const [scrollTop, setScrollTop] = React.useState(0);

  React.useEffect(() => {
    console.log("global index changed");
    console.log(globalIndex, fired);
    if (fired === 1) {
      setGlobalIndex2((prev) => prev + 18);
      setGlobalIndex((prev) => prev + 18);
    }
  }, [fired]);
  const hasModalVisible = image || productInfo;
  return (
    <React.Fragment>
      {!loading ? (
        <div
          className={`service-provider-view ${
            hasModalVisible ? "modal-visible" : ""
          }`}
        >
          <div
            className={`filter-icon ${sidebarVisible ? "active" : ""}`}
            onClick={toggleSidebar}
          >
            {sidebarVisible ? <FiX size={25} /> : <FiFilter size={20} />}
          </div>
          {/* {username} */}
        
          {image && <ProductImageModal />}
          {/* {ratingModal && <RatingModal />} */}
          {serviceDetail && (
            <>
              <div
                className={`selection-sidebar-view ${
                  hasModalVisible ? "hidden" : ""
                }`}
              >
                <SelectionPanel data={serviceDetail[0].filter_details} colorsdata={serviceDetail[0].color} />
              </div>
              <div className="service-provider-mainscreen">
                <Advertisement
                  data={serviceDetail[0].seller_details}
                  providerId={username[1]}
                  len={prodlen}
                />
                <div className="service-provider-selector-subservice">
                  <select
                    name="subservice"
                    onChange={handleSubserviceSelection}
                    defaultValue={selectedSubservice}
                  >
                    
          
                    {/* Show selected subservice name if selected */}
                    {selectedSubservice && (
                      <option value={selectedSubservice}       
                      >
                        {selectedSubserviceName}

                      </option>
                    )}
                    {serviceDetail[0].sub_service
                      .filter(
                        (subService) =>
                          subService.sub_service_id !== parseInt(selectedSubservice)
                      )
                      .map((subService) => (

                        <option
                          key={subService.sub_service_id}
                          value={subService.sub_service_id}
                          data-serviceid={subService.service_id}
                        >   
                          {subService.sub_service_name}

                        </option>
                      ))}
                      
                  </select>
                </div>
                <div
                  className="service-provider-card-screen"
                  id="service-provider-card-screen-id"
                  ref={wrapperRef}
                  onScroll={handleScroll}
                >
                  {products &&
                    products.map((item) => {
                      return <ServiceProviderCard data={item} />;
                    })}
                  { globalIndex % 18 === 0  ? (
                    <div className="loading" id="loading">
                      <div className="obj"></div>
                      <div className="obj"></div>
                      <div className="obj"></div>
                      <div className="obj"></div>
                      <div className="obj"></div>
                      <div className="obj"></div>
                      <div className="obj"></div>
                      <div className="obj"></div>
                      <div className="obje">LOADING MORE</div>
                      <div className="obj"></div>
                      <div className="obj"></div>
                      <div className="obj"></div>
                      <div className="obj"></div>
                      <div className="obj"></div>
                      <div className="obj"></div>
                      <div className="obj"></div>
                      <div className="obj"></div>
                    </div>
                  ) : (
                    <h5 className="finishmsg" id="finishmsg">
                      <h5>That's all we have</h5>
                    </h5>
                  )}
                </div>
                {/* <div onClick={LoadMoreProducts}>load more</div> */}
                {/* {window.scrollY + window.innerHeight >=
                  document.documentElement.scrollHeight && load === 0 ? (
                  <h5
                    className='finishmsg'
                    id='finishmsg'
                    onClick={() => {
                      setLoad(load + 1);
                    }}
                  >
                    That's all we have
                  </h5>
                ) : (
                  <div
                    className='loading'
                    id='loading'
                    onClick={() => {
                      window.removeEventListener('scroll', reloader);
                    }}
                  >
                    <div className='obj'></div>
                    <div className='obj'></div>
                    <div className='obj'></div>
                    <div className='obj'></div>
                    <div className='obj'></div>
                    <div className='obj'></div>
                    <div className='obj'></div>
                    <div className='obj'></div>
                    <div
                      className='obje'
                    >
                      LOADING MORE
                    </div>
                    <div className='obj'></div>
                    <div className='obj'></div>
                    <div className='obj'></div>
                    <div className='obj'></div>
                    <div className='obj'></div>
                    <div className='obj'></div>
                    <div className='obj'></div>
                    <div className='obj'></div>
                  </div>
                )} */}
              </div>
            </>
          )}
        </div>
      ) : (
        <>
          <div className="loading" id="loading" style={{ height: "100vh" }}>
            <div className="obj"></div>
            <div className="obj"></div>
            <div className="obj"></div>
            <div className="obj"></div>
            <div className="obj"></div>
            <div className="obj"></div>
            <div className="obj"></div>
            <div className="obj"></div>
            <div className="obje">LOADING MORE</div>
            <div className="obj"></div>
            <div className="obj"></div>
            <div className="obj"></div>
            <div className="obj"></div>
            <div className="obj"></div>
            <div className="obj"></div>
            <div className="obj"></div>
            <div className="obj"></div>
          </div>
          <div className="mobile-loader" style={{ height: "50vh" }}>
            <div className="loader"></div>
          </div>
        </>
      )}
    </React.Fragment>
  );
}
