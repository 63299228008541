import React, { Component } from 'react';
import MyBillsApi from '../api/MyBillsApi';
import notify from '../error';
import { useGlobalContext } from '../context';
import radar from '../images/radar-observatory-svgrepo-com.svg';
import cover from '../images/Putatoe-Illustrations/1.png';
import './css/DthRechargePage.css';
import { Navigate, useNavigate } from 'react-router-dom';

export default function DthRechargePage(props) {
  const { loginDetail, Userprofile } = useGlobalContext();
  const [operatorList, setOperatorList] = React.useState();
  const getDthOperatorList = async () => {
    const response = await MyBillsApi.OtherOperatorList('DTH');
    console.log(response);
    setOperatorList(response.data.operator_list);
  };
  React.useEffect(() => {
    getDthOperatorList();
  }, []);
  const [fieldValid, setFieldValid] = React.useState(false); // to check input is valid or not
  const [formData, setFormData] = React.useState({
    operator_code: '',
    DTH: '',
    amount: '',
    coupon_id: null,
    payment_method: '',
  });

  function handleKeyPress(event) {
    const currentLength = event.target.value.length;
  
    if (currentLength >= 10 || (event.key && !/^[0-9]*$/.test(event.key))) {
      event.preventDefault();
    }
  }

  function ChangeValue(e) {
    const { name, value, type, checked } = e.target;
    // console.log(value,name)
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: type === 'checkbox' ? checked : value,
      };
    });
    console.log(formData);

if (
    formData.DTH &&
    formData.operator_code &&
    (formData.amount > 0)
) {
    setFieldValid(false);
}else{
  setFieldValid(true);
} 
  }
  const validateMobileNumber = (mobileNumber) => {
    const regex = /^[6-9]\d{9}$/;
    return regex.test(mobileNumber);
  };
  const navigate = useNavigate();
  const BillMyDTH = async () => {
    const { operator_code, DTH, amount } = formData;
    if (DTH === '') {
      setFieldValid(true);
         return;
       }
   
       if (!validateMobileNumber(DTH)) {
        setFieldValid(true);
         return;
       }
   
       if (operator_code === '') {

        setFieldValid(true);
         return;
       }
   
       if (amount === '' || parseInt(amount) <= 0) {

        setFieldValid(true);
         return;
       }
    navigate(
      '/cart/recharge',
      { replace: true,
        state: {
          op_code: operatorList[formData.operator_code].op_code,
          serial_no: formData.DTH,
          amount: formData.amount,
          circle:2,
          op_image: operatorList[formData.operator_code].operator_image,
          text1: `DTH Number ${formData.DTH}`,
          text2: `Recharge ${operatorList[formData.operator_code].operator_name} with `,     
               pageno:props.pageNo,                redirect:"/mobile-recharge"
        },
      }
    );
  };

  //payments

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  // const PlaceOrderFunction = async () => {
  //   // checkout.js 890line
  //   if (!Userprofile) {
  //     notify('error', 'Please Login To Continue');
  //     return;
  //   }
  //   console.log(formData.payment_method);
  //   if (
  //     formData.payment_method === undefined ||
  //     formData.payment_method === '' ||
  //     formData.payment_method === null
  //   ) {
  //     notify('warn', 'Please select a payment mode');
  //   }

  //   if (PaymentMode === 'paynow') {
  //     const res = await loadScript(
  //       'https://checkout.razorpay.com/v1/checkout.js'
  //     );

  //     if (!res) {
  //       notify('error', 'Razorpay SDK failed to load. Are you online?');
  //       return;
  //     }

  //     const response = await paymentApi.RazorPayOrderCreate(
  //       DeliveryDay,
  //       DeliveryTime,
  //       selectedAddress.id,
  //       null
  //     );
  //     console.log(response);
  //     let data = response.data;
  //     if (data.error !== '') {
  //       // console.log()
  //       notify('warn', data.error);
  //     } else if (data.rpay_response.status === 'created') {
  //       var options = {
  //         key: data['rpay_response']['key_id'],
  //         amount: data['rpay_response']['amount'],
  //         currency: data['rpay_response']['currency'],
  //         name: 'PUTATOE SOLUTIONS PVT. LTD.',
  //         description: 'Pay For Your3 Order',
  //         image: 'https://i.ibb.co/ctGstkw/logo.png',
  //         order_id: data['rpay_response']['id'],
  //         handler: async function (response) {
  //           const data2 = {
  //             razorpay_payment_id: response.razorpay_payment_id,
  //             razorpay_order_id: response.razorpay_order_id,
  //             razorpay_signature: response.razorpay_signature,
  //             putatoe_order_id: data['rpay_response']['putatoe_order_id'],
  //           };

  //           const response3 = await paymentApi.RazorPayOrderVerify(
  //             data2.razorpay_payment_id,
  //             data2.razorpay_order_id,
  //             data2.razorpay_signature,
  //             data2.putatoe_order_id
  //           );
  //           console.log(response3);
  //           let data3 = response3.data;
  //           if (data3['status'] == true) {
  //             notify('success', 'Payment Successful!');
  //             //  document.location.href = 'OrderConfirmed.html?id=' + data['id'];
  //             // moveToConfirmationPage(data['id']);
  //           } else {
  //             notify('error', 'Payment unsuccessful');
  //           }
  //         },
  //         prefill: {
  //           name: data['rpay_response']['name'],
  //           email: data['rpay_response']['email'],
  //           contact: data['user_phone'],
  //         },
  //         notes: {
  //           address: 'Putatoe Technologies',
  //         },
  //         theme: {
  //           color: '#00838f',
  //         },
  //       };
  //       var rzp1 = new window.Razorpay(options);
  //       rzp1.on('payment.failed', function (response) {
  //         notify('error', 'Payment failed due to banks issue');
  //       });
  //       rzp1.open();
  //     }
  //   } else if (PaymentMode === 'wallet') {
  //     const response = await MyBillsApi.RechargeMyDTHApi(
  //       DeliveryDay,
  //       DeliveryTime,
  //       DeliveryLocation,
  //       null
  //     );
  //     console.log(response);
  //     var data = response.data;
  //     console.log(data);
  //     if (data.error !== '') {
  //       notify('warn', data.error);
  //     } else if (data['resultMsg'] === 'Txn Success') {
  //       notify('success', 'Payment Successful!');
  //       // document.location.href = 'OrderConfirmed.html?id=' + data['orderId'];
  //       moveToConfirmationPage(data['orderId']);
  //     } else if (data['status'] === true) {
  //       //dbt whether correct
  //       notify('warn', 'Not having enough wallet balance.');
  //       // location.href = "/putatoe_client/checkout.html";
  //     } else {
  //       notify('error', 'Order failed.');
  //       //  location.href = '/putatoe_client/checkout.html';
  //     }
  //   }
  // };

  return (
    <React.Fragment>
      <div className='my-dth-recharge-main-page-container'>
        <div className='my-dth-recharge-main-page-container-heading'>
          <div>
            <img src={radar}></img>
          </div>
          <div>
            Let's Get Your<br></br>
            <span>DTH Bill Payment Done</span>
            <hr></hr>
          </div>
        </div>
        <div className='my-dth-recharge-main-page-container-main-content'>
          <div className='my-dth-recharge-main-page-container-main-content-col1'>
            <div className='my-dth-recharge-main-page-container-main-content-col1-inputs'>
            <div className={`my-dth-recharge-main-page-container-main-content-col1-inputs-1 ${(!formData.DTH&& fieldValid) || (formData.DTH && !validateMobileNumber(formData.DTH)) ? 'error' : ''}`}>
         
                <input
                  type='number'
                  value={formData.DTH}
                  onChange={ChangeValue}
                  onKeyPress={handleKeyPress}
                  name='DTH'
                  placeholder='Enter Customer Id'
                ></input>
                {(!formData.DTH && fieldValid) && <span className="error-message">Please enter your Customer ID</span>}
                {formData.DTH && !validateMobileNumber(formData.DTH) && <span className="error-message"> Please enter a valid Customer ID</span>}
              </div>
              <div className={`my-dth-recharge-main-page-container-main-content-col1-inputs-1 ${(!formData.operator_code) && fieldValid ? 'error' : ''}`}>

                <select
                  value={formData.operator_code}
                  onChange={ChangeValue}
                  onKeyPress={handleKeyPress}
                  name='operator_code'
                >
                  <option value=''>Select Operator</option>
                  {operatorList &&
                    operatorList.length > 0 &&
                    operatorList.map((item,index) => {
                      return (
                        <option value={index}>
                          {item.operator_name}
                        </option>
                      );
                    })}
                </select>
                {(!formData.operator_code && fieldValid) && <span className="error-message">Please select an Operator</span>}
              </div>
              <div className={`my-dth-recharge-main-page-container-main-content-col1-inputs-1 ${(!formData.amount || !formData.amount.trim() || parseInt(formData.amount) <= 0) && fieldValid ? 'error' : ''}`}>
                
                <input
                  type='number'
                  value={formData.amount}
                  onChange={ChangeValue}
                  name='amount'
                  placeholder='Enter Recharge Amount'
                ></input>
                {(!formData.amount  && fieldValid) && <span className="error-message">Please enter a recharge amount</span>}
                {(formData.amount &&formData.amount <= 0 && fieldValid) && <span className="error-message">Please enter a valid recharge amount</span>}


              </div>
              <div className='my-dth-recharge-main-page-container-main-content-col1-inputs-button'>
                <button onClick={BillMyDTH}>Proceed</button>
              </div>
            </div>
          </div>
          <div className='my-dth-recharge-main-page-container-main-content-col2'>
            <div>
              <img src={cover}></img>
            </div>
          </div>
        </div>
        <div className='my-dth-recharge-main-page-container-allProvider-content'>
          <div className='my-dth-recharge-main-page-container-allProvider-content-all-provider'>
            All Providers
          </div>
          <div className='my-dth-recharge-main-page-container-allProvider-content-all-provider-card-holder'>
          
            {operatorList &&
              operatorList.length > 0 &&
              operatorList.map((item) => {
                return (
                  <div className='my-dth-recharge-main-page-container-allProvider-content-all-provider-cards'>
                   
                    <div className='my-dth-recharge-main-page-container-allProvider-content-all-provider-cards-col1'>
                      <div>
                        <img src={item.operator_image}></img>
                      </div>
                    </div>
                    <div className='my-dth-recharge-main-page-container-allProvider-content-all-provider-cards-col2'>
                      {item.operator_name}
                    </div>
                  </div>
                );
              })}

          </div>
        </div>

        
      </div>
    </React.Fragment>
  );
}
