import React, { Component } from "react";
import "../serviceprovider-css/serviceProviderView.css";
import Advertisement from "./advertisment";
import SelectionPanel from "./seletionPanel";
import ServiceProviderCard from "./serviceProviderCard";
import { useProductContext } from "../productPageContext.js";
import ProductImageModal from "./productImageModal";
import ProductInfo from "./productInfo.js";
import { useGlobalContext } from "../context";
import { FiFilter, FiX } from "react-icons/fi";
import Loading from "../components/Loading.jsx";
import { useLocation } from "react-router-dom";
export default function ProductProviderView() {
  const {
    image,
    setImage,
    IsServiceProviderApi,
    setIsServiceProviderApi,
    isError,
    setIsError,
    serviceDetail,
    serviceDetail2,
    filtersdata,
    setServiceDetail,
    productInfo,
    productDetail,
    username,
    setUsername,
    globalIndex,
    setGlobalIndex,
    loading,
    setLoading,
  } = useProductContext();

  const hasModalVisible = image || productInfo;
  const wrapperRef = React.useRef(null);
  const [fired, setFired] = React.useState(0);
  const [scrollTop, setScrollTop] = React.useState(0);
  const [prodlen,setProdlen]=React.useState(0);
  const [products, setProducts] = React.useState([]);
  const [sidebarVisible, setSidebarVisible] = React.useState(false);
  const [clientHeight, setClientHeight] = React.useState();
  let arr = [];
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  console.log(queryParams);
  const serviceId = queryParams.get("serviceId");
  const subServiceId = queryParams.get("subServiceId");
  const typeId = queryParams.get("typeId");

  
  const { categoryData, brandData, productData, priceData } =
    useProductContext();

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  function handleScroll(e) {
    console.log(e.currentTarget.scrollTop);
    setScrollTop(e.currentTarget.scrollTop);

    console.log("reached in scroll");
    console.log(
      scrollTop,
      clientHeight - 640,
      fired,
      scrollTop > clientHeight - 640
    );
    if (scrollTop !== 0 && scrollTop > clientHeight - 640 && fired === 0) {
      setFired(1);
      console.log("did my work to call the loader");
    }
  }




  React.useEffect(() => {
    const storedProductData = localStorage.getItem("productData");
    console.log(storedProductData, "here");
    if (storedProductData) {
      const [service_id, sub_service_id, type_id2] = storedProductData.split(",");
     // Check if any value has changed
     if (username[0] !== service_id || username[1] !== sub_service_id || username[2] !== type_id2) {
      setUsername([service_id, sub_service_id, type_id2]);
    }
    }
  }, []);

  React.useEffect(() => {
    let a, b;
    console.log("set clienthieght");
    a = products.length / 5;
    a = Math.ceil(a);
    console.log(a);
    b = a * 320;
    setClientHeight(b);
    console.log(b, "ckientheight");
    setFired(0);
  }, [products]);

  React.useEffect(() => {
    if (serviceDetail2) {
      setLoading(false);
      serviceDetail2.forEach((element) => {
        element.product_list.forEach((item) => {
          arr.push(item);
        });
      });
      setProdlen(serviceDetail2[0].product_count)
    }

    setProducts(arr);
  }, [serviceDetail2]);

  React.useEffect(() => {
    console.log("global index changed");
    console.log(globalIndex, fired);
    if (fired === 1) {
      setGlobalIndex((prev) => prev + 18);
      console.log(globalIndex);
    }
  }, [fired]);

  return (
    <React.Fragment>
      {!loading ? (
        <div
          className={`service-provider-view ${
            hasModalVisible ? "modal-visible" : ""
          }`}
        >
          <div
            className={`filter-icon ${sidebarVisible ? "active" : ""}`}
            onClick={toggleSidebar}
          >
            {sidebarVisible ? <FiX size={25} /> : <FiFilter size={20} />}
          </div>

          {/* {username} */}

          {image && <ProductImageModal />}
          {/* {ratingModal && <RatingModal />} */}
          {serviceDetail2 ? (
            <>
              <div
                className={`selection-sidebar-view ${
                  hasModalVisible ? "hidden" : ""
                }`}
              >
                {filtersdata && <SelectionPanel data={filtersdata} />}
              </div>
              <div className="service-provider-mainscreen">
                <Advertisement prodlen={products.length} />
                <div
                  className="service-provider-card-screen"
                  ref={wrapperRef}
                  onScroll={handleScroll}
                >
                  {products &&
                    products.map((item) => {
                      return <ServiceProviderCard data={item} />;
                    })}
                  {globalIndex % 18 === 0 ? (
                    <>
                      <div class="loading" id="loading">
                        <div class="obj"></div>
                        <div class="obj"></div>
                        <div class="obj"></div>
                        <div class="obj"></div>
                        <div class="obj"></div>
                        <div class="obj"></div>
                        <div class="obj"></div>
                        <div class="obj"></div>
                        <div class="obje">LOADING MORE</div>
                        <div class="obj"></div>
                        <div class="obj"></div>
                        <div class="obj"></div>
                        <div class="obj"></div>
                        <div class="obj"></div>
                        <div class="obj"></div>
                        <div class="obj"></div>
                        <div class="obj"></div>
                      </div>
                      <div className="mobile-loader">
                        <div className="loader"></div>
                      </div>
                    </>
                  ) : (
                    <h5 class="finishmsg" id="finishmsg">
                      <h5>That's all we have</h5>
                    </h5>
                  )}
                </div>
              </div>
            </>
          ) : (
            <div>params</div>
          )}
        </div>
      ) : (
        <>
          <div class="loading" id="loading" style={{ height: "100vh" }}>
            <div class="obj"></div>
            <div class="obj"></div>
            <div class="obj"></div>
            <div class="obj"></div>
            <div class="obj"></div>
            <div class="obj"></div>
            <div class="obj"></div>
            <div class="obj"></div>
            <div class="obje">LOADING MORE</div>
            <div class="obj"></div>
            <div class="obj"></div>
            <div class="obj"></div>
            <div class="obj"></div>
            <div class="obj"></div>
            <div class="obj"></div>
            <div class="obj"></div>
            <div class="obj"></div>
          </div>
          <div className="mobile-loader" style={{ height: "60vh" }}>
            <div className="loader"></div>
          </div>
        </>
      )}
    </React.Fragment>
  );
}
