import axios from 'axios';
//company
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

class SearchService {
  SearchAll(search_string) {
    const service_id = localStorage.getItem('serviceId');
    return axios.post(
      API_BASE_URL + '/v1/api/searchAll',
      { search_string, service_id },
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }

  SearchAll2(search_string, service_id, subservice_id) {
    return axios.post(
      API_BASE_URL + '/v1/api/searchAll',
      { search_string, service_id, subservice_id },
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }
}

export default new SearchService();
