import React, { useEffect, useState } from 'react';
import postapi from '../../api/postapi';
import ul from '../../images/olList.svg';
import ol from '../../images/ulList.svg';
import cnacleUpload from '../../images/blogimages/cnacleUpload.svg'
import camera from '../../images/blogimages/camera.svg'
import { useGlobalContext } from '../../context';
import notify from '../../error';
import Loading from '../Loading';
import { Editor, EditorState, RichUtils, convertFromHTML, ContentState } from "draft-js";
import { stateToHTML } from 'draft-js-export-html';




import '../blog-css/createblog.css';

function Createblog({ editData }) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [blogTitle, setBlogTitle] = useState('');
  const [blogDescription, setBlogDescription] = useState('');
  const [blogBody, setBlogBody] = useState('');
  const { setCreateBlogModal, setModifyModal, setPostBlog, loading, setLoading, Userprofile, setDisplay, setLoginPage } = useGlobalContext();

  // const [imageUrl,setImageUrl] = useState();
  const [image, setImage] = useState();
  const [imageUrl, setImageUrl] = useState();


  const [selectedCategory, setSelectedCategory] = useState('');
  // for textarea bold and other feature ------>

  // working editor handler functions -------------->

  const handleKeyCommand = (command) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  const toggleInlineStyle = (style) => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, style));
  };

  const toggleBlockType = (style) => {
    setEditorState(RichUtils.toggleBlockType(editorState, style));
  };

  useEffect(() => {
    const contentState = editorState.getCurrentContent();
    const html = stateToHTML(contentState);
    setBlogBody(html);
  }, [editorState]);

  useEffect(() => {
    if (editData) {
      editBlogFunction(editData);

      // seting the editor state
      const contentState = convertFromHTML(editData.detail || '');
      const newEditorState = EditorState.createWithContent(ContentState.createFromBlockArray(contentState.contentBlocks, contentState.entityMap));
      setEditorState(newEditorState);
    }
  }, [editData])


  const handleCategoryChange = (event) => {
    const value = event.target.value;
    setSelectedCategory(parseInt(value));
  };

  const handleImageUpload = (event, editClue) => {
    const reader = new FileReader();
    setImage(event.target.files[0]);
    reader.onload = () => {
      setImageUrl(reader.result);
    };
    reader.readAsDataURL(event.target.files[0]);
    if (editClue !== undefined) uploadOGCFunction(event.target.files[0]);
  };

  const publishBtnClick = async (e) => {
    if(!Userprofile){
      setModifyModal(false);
       notify("error","Please log in first...");
       setDisplay("login-sidebar-container2");
       setLoginPage(1);
       return;
    }
    if (blogTitle !== '') {
      try {
        setLoading(true);
        const response = await postapi.AddBlog(blogTitle, blogDescription, blogBody, selectedCategory, image);
        if (response.status === 200) {
          notify("success", "Blog added successfully...");
          setLoading(false);
          setCreateBlogModal(false);
          setModifyModal(false);
          setPostBlog((prevPostBlogState) => !prevPostBlogState);
        }
      } catch (err) {
        console.log(err);
        notify("error", "something went wrong!!!");
      }
    }
    else {
      notify('error', 'Fill the fields first...');
    }
  }

  async function uploadOGCFunction(file) {
    try {
      const response = await postapi.UploadImageOGC(file);
      setImageUrl(response.data.url);
    } catch (err) {
      console.log(err);
    }

  }

  function editBlogFunction(elm) {
    setBlogTitle(elm.hadline);
    setImageUrl(elm.image);
    setSelectedCategory(elm.blog_category_id);
    setBlogBody(elm.detail);
  }

  const editBlogBtnClick = async () => {
    // console.log(blogTitle, blogDescription, blogBody, selectedCategory, imageUrl);
    try {
      setLoading(true);
      const response = await postapi.EditBlog(editData.id, blogTitle, blogDescription, blogBody, selectedCategory, imageUrl);
      if (response.data.error === ''){
        notify("success", "Blog edited successfully...");
        setLoading(false);
        setCreateBlogModal(false);
        setModifyModal(false);
        setPostBlog((prevPostBlogState) => !prevPostBlogState);         
      }

    } catch (err) {
      console.log(err);
    }
  }



  return (
    <>
      <div className="writeNewBlog">
        <form className="contentContainer" onSubmit={(e) => e.preventDefault()}>
          {loading ? <Loading />
            :
            <>
              <img className='crossIcon' src={cnacleUpload} alt="cross_icon" onClick={() => {
                setModifyModal(false);
                setCreateBlogModal(false);
              }} />
              <section className='inputSection'>
                <div className="titleInput">
                  <h1 className='blogHeading'>Blog Title <span className='field-required'>*</span>
                  </h1>
                  <input className="blogInput" id="blogheading" type="text" placeholder='Title of your Blog' value={blogTitle} onChange={(e) => setBlogTitle(e.target.value)} />
                </div>

                <div className='categoryInput'>
                  <h1 className='blogHeading'>Category <span className='field-required'>*</span></h1>
                  <select className='blogInput blogCategory' value={selectedCategory} onChange={handleCategoryChange} required>
                    <option value="1">Education</option>
                    <option value="2">General</option>
                    <option value="3">Politics</option>
                    <option value="4">Entertainment</option>
                    <option value="5">Life</option>
                    <option value="6">Personal</option>
                    <option value="7">Shopping</option>
                    <option value="8">Sports</option>
                    <option value="9">Technology</option>
                  </select>
                </div>
              </section>
              <h1 className='blogHeading'>Body</h1>
              <div className='create-blog-text-box'>
                <div className='create-blog-text-options'>
                  <div className='create-blog-text-options-caption'>
                    <span>Caption</span>
                    <span className='create-blog-characters'> (250 characters max)</span>
                  </div>
                  <div className='create-blog-text-options-style-biu'>
                    <button onClick={() => toggleInlineStyle("BOLD")}>B</button>
                    <button onClick={() => toggleInlineStyle("ITALIC")}>I</button>
                    <button onClick={() => toggleInlineStyle("UNDERLINE")}>U</button>
                  </div>
                  <div className='create-blog-text-options-style-list'>
                    <button onClick={() => toggleBlockType("unordered-list-item")}>
                      <img src={ul} alt="Unordered List" />
                    </button>
                    <button onClick={() => toggleBlockType("ordered-list-item")}>
                      <img src={ol} alt="Ordered List" />
                    </button>
                  </div>
                </div>
                <Editor editorState={editorState} onChange={(newEditorState) => {
                  setEditorState(newEditorState);
                  setBlogBody(newEditorState.getCurrentContent().getPlainText());
                }} handleKeyCommand={handleKeyCommand} />
              </div>
              <div className="WBuploadImg" >
                <img className='cancelCross' src={cnacleUpload} alt="cross_icon" onClick={() => setImageUrl("")} />
                {imageUrl ?
                  <div className='imageContainerBlog'>
                    <img src={imageUrl} alt='uploaded_image' className='uploadedImage' />
                  </div> :
                  <>
                    <img src={camera} alt='camerIcon' />
                    <div>No Cover image Uploaded</div>
                    <span className='imageContainerBlog'>
                      <label id='create-post-camera-label' htmlFor='image-input'>
                        <input
                          type='file'
                          id='image-input'
                          accept='image/png, image/jpg, image/gif, image/jpeg'
                          onChange={(e) => handleImageUpload(e, editData)}
                        />
                        Select Your Cover Image From <br></br> Your Folder
                      </label>
                    </span>
                  </>
                }

              </div>

              <div className="publishBtn d-flex justify-content-end">
                {editData ?
                  <button onClick={editBlogBtnClick}>Edit</button>
                  :
                  <button onClick={publishBtnClick}>Publish</button>
                }
              </div>
            </>
          }
        </form>
      </div>
    </>
  )
}

export default Createblog;
