import React, { useState,useRef,useEffect } from 'react';
import "./manage_delivery.css";
import { useGlobalContext } from "../../context";
import myProfileServiceApi from "../../api/myProfileServiceApi";
import UserService from '../../api/api';
import back from "../myprofileIcoons/bx-arrow-back..svg";
import notify from "../../error";
import defaultImage from "../../images/imageNotFound.png";
import { BiSolidTrashAlt } from "react-icons/bi";
import "./deliveryaddress.css";
import Deliveryinfo from "./deliveryinfo"
import locationImage from "../../images/location-2-svgrepo-com.svg";
import profileapi from "../../api/profileapi";
import { motion } from "framer-motion";
import { allStates, getDistricts } from "../../stateDistrict";
import { IoCloseCircleSharp } from "react-icons/io5";
import defaultuserprofile from "../../images/defaultuserprofile.jpeg";
import Loading from "../../components/Loading";
import Geocode from "react-geocode";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import postapi from "../../api/postapi";
const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
Geocode.setApiKey(googleMapsApiKey);

const Manage_delivery = () => {
  const [selectedpage,setSelectedpage]=useState(0);
  const {Userprofile}=useGlobalContext();
  const [locationname,setLocationname]=useState("");
  const [predictedaddress,setPredictedaddress]=useState([]);
  const [open,setOpen]=useState(false);
  const refforinput=useRef(null);
  const [location, setLocation] = React.useState(null);
  // const [details, setDetails] = React.useState();
  const [addressdetail,setAddressdetail]=useState({});
  const bydefaultimageref= React.useRef(defaultuserprofile);
  const [deliveryinformation,setDeliveryinformation]=useState({"image":bydefaultimageref.current});
  useEffect(()=>{
    const getpersondetail=async ()=>{
      const response = await postapi.getdeliverypersoninfo();
      console.log(response);
      if(response && response.data.error === ''){
        setAddressdetail(response.data.address)
        setDeliveryinformation({
          "mobile": response.data.mobile,   
          "whatsappnumber": response.data.whatsappnumber,
         "name": response.data.name, 
         "description": response.data.description,   
         "email": response.data.email,  
          "image": response.data.image,
         "adhaar": response.data.adhaar, 
         "vehicle_number": response.data.vehicle_number,
        })
      }
      else if(response.data.error !== ''){
        notify("error","Error while fetching the information");
      }
    }
    if(Userprofile.is_deliveryperson){
      getpersondetail();
      
    }
  },[])

  useEffect(()=>{
    window.addEventListener("click",((event)=>{
      // console.log(event.target,refforinput?.current,event.target != refforinput?.current,!refforinput?.current.contains(event.target),open)
      if(open && refforinput.current && event.target != refforinput.current && !refforinput.current.contains(event.target)){
        setOpen(false);
      }
    }));
  },[open,refforinput])

  useEffect(()=>{
    const searchQueryHandler = async (event) => {
      if (locationname.length > 0) {
          if (Userprofile && Userprofile.default_address) {
              const response = await UserService.addressPrediction(locationname, Userprofile.default_address.latitude, Userprofile.default_address.longitude);
              console.log(response);
              if (response.data.error === "") {
                  // setAddprediction(response.data.predictions);
                  setPredictedaddress(response.data.predictions);
                  setOpen(true);
              } else {
                  console.log(response);
                  console.log("Error occurred while fetching address predictions:", response.data.error);
              }
          }
          else {
              const response = await UserService.addressPrediction(locationname, null, null);
              console.log(response);
              
              if (response.data.error === "") {
                setPredictedaddress(response.data.predictions);
                setOpen(true);
              }
              console.log("error occured to change address");
          }

      }
  };
  let timeout = setTimeout(() => {
    searchQueryHandler();
  }, 700);

  return () => {
    if (timeout) {
      clearTimeout(timeout);
    }
  }; 
  },[locationname])

  const handleChange = (level) => (e) => {
    
    console.log(e,level);

    const { name, value, type, checked } = e.target;
    // console.log(`Name: ${name}, Value: ${value}, Checked: ${e.target.checked}`);
    // console.log(typeof value);
    if(level==="address"){
      setAddressdetail({...addressdetail,[name]:value});
    }
  };

  const handleMarkerDragend = async (e) => {
    const { latLng } = e;
    console.log(e);
    const lat = latLng.lat();
    const lng = latLng.lng();
    try {
      setLocation({ lat, lng });
      const response = await Geocode.fromLatLng(lat, lng);
      const address = response.results[0].formatted_address;
      const address_formatted = address.split(", ");
      console.log(address_formatted);
      const state_pincode_formatted =
        address_formatted[address_formatted.length - 2];
      console.log(state_pincode_formatted);
      const state_pincode_array = state_pincode_formatted.split(" ");
      console.log(state_pincode_array);
      let newState = "";
      let newPincode = "";

      if (state_pincode_array.length === 1 && !isNaN(state_pincode_array[0])) {
        newPincode = state_pincode_array[0];
      } else if (state_pincode_array.length === 2) {
        newState = isNaN(state_pincode_array[0]) ? state_pincode_array[0] : "";
        newPincode =
          isNaN(state_pincode_array[1]) ||
            typeof state_pincode_array[1] !== "string"
            ? ""
            : state_pincode_array[1];
      }

      setAddressdetail((prevFormData) => {
        console.log(prevFormData);
        return {
            address_line1: address_formatted[0],
            town: address_formatted[2],
            state: state_pincode_array[0],
            district: response.results[0].address_components[3].long_name,
            pincode: hasDigit(state_pincode_array[1]) ? state_pincode_array[1] : "",
            latitude: lat.toString(),
            longitude: lng.toString(),
        };
      });
      console.log(response.results);

    } catch (error) {
      console.error("Error retrieving address:", error);
      notify("error", "Error retrieving address");
    }
  };

  function hasDigit(str) {
    return /\d/.test(str);
}
  const handleClick = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;

          try {
            // Retrieve address from coordinates
            const response = await Geocode.fromLatLng(latitude, longitude);
            const address = response.results[0].formatted_address;
            console.log(response.results);
            console.log(address);
            const address_formatted = address.split(",");
            console.log(address_formatted);
            const state_pincode_formatted =
              address_formatted[address_formatted.length - 2];
            const state_pincode_array = state_pincode_formatted.split(" ");
            

            setLocation({ lat: latitude, lng: longitude });
            setAddressdetail((prevFormData) => {
              console.log(prevFormData);
              return {
                  address_line1: address_formatted[0],
                  town: address_formatted[2],
                  state: state_pincode_array[0],
                  district: response.results[0].address_components[3].long_name,
                  pincode: hasDigit(state_pincode_array[1]) ? state_pincode_array[1] : "",
                  latitude: latitude.toString(),
                  longitude: longitude.toString(),
              };
            });
   
          } catch (error) {
            console.log("Error retrieving address:", error);
            notify("error", "Network error");
          }
        },
        (error) => {
          console.log("Geolocation error:", error);
          notify("error", error.message);
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };
  const [deliverypersonalinfo,setDeliverypersonalinfo]=useState({});

const imageref= React.useRef();
const [uploading,setUploading]=React.useState(false);
const [imagedeleteicon,setImagedeleteicon]=React.useState(false);
// useEffect(async ()=>{
//   if(Userprofile.is_deliveryperson){
//     const response = await postapi.getdeliverypersoninfo();
//     if(response)
//     // setDeliveryinformation(()=>{
//     //   return {
//     //     "mobile": "+918329666408",   
//     //     "whatsappnumber": "+919554794798",   
//     //    "name": "imDeliveryboy",
//     //    "description": "24 available",          
//     //    "email": "imran@gmial.com",    
//     //    "image": "fagaggadfaf.jpg",      
//     //    "vehicle_number": "Up53 cw2958"
//     //   }
//     // })
//   }
// },[])

useEffect(()=>{
  if(deliveryinformation.image !== bydefaultimageref.current){
    setImagedeleteicon(true);
  }
},[deliveryinformation.image])

const handleimagechange = async (event) => {
  const selectedFile = event.target.files[0];
setUploading(true);
  const response = await postapi.UploadImageOGC(selectedFile);
  console.log(event.target);
setUploading(false);
  if (response.data.error === "") {
      setDeliveryinformation((prevdata) => {
      return {
        ...prevdata,
        [event.target.name]: response.data.url,
      }
    })
  }
  else {
notify("error","Fail to upload a image")
  }
}

const handleChangename = (e) => {
  const { name, value, type, checked } = e.target;
  setDeliveryinformation({...deliveryinformation,[name]:value});
};

const apicallonsubmit = ()=>{
  if(!deliveryinformation.name || !deliveryinformation.mobile || !deliveryinformation.vehicle_number 
    || !deliveryinformation.adhaar){
    notify("error","Please Enter all the information");
    return;
  }
  if(!((/^\d{10}$/).test(deliveryinformation.mobile))){
    notify("error","Please Enter valid phone number");
    return;
  }
  if(deliveryinformation.whatsappnumber && !((/^\d{10}$/).test(deliveryinformation.whatsappnumber))){
    notify("error","Please Enter valid Whatsapp number");
    return;
  }
  if(deliveryinformation.email && !((/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/).test(deliveryinformation.email))){
    notify("error","Please Enter valid Emailid address");
    return;
  }

  // Deliverypersonalinfo(()=>{
  //   return {
  //     "mobile": `+91${deliveryinformation.mobile}`,   
  //     "whatsappnumber": `+91${deliveryinformation.whatsappnumber}`,  
  //    "name": deliveryinformation.name,  
  //    "description": deliveryinformation.description,          
  //    "email": deliveryinformation.email,    
  //    "image": deliveryinformation.image,      
  //    "adhaar":deliveryinformation.adhaar,
  //    "vehicle_number": deliveryinformation.vehicle_number
  //   }
  // })
}

  return (
    <>
    <div className="my-order-profile-time-filter-calander-box">
    <div className='profile-order-main-page-heading deliveryheading'>
      <span>Manage Delivery</span>
    </div>
    </div>
    <div className='deliverysection'>
      <div className={`modifyaddress ${selectedpage==0 && "selectedpagedelivery"}`} onClick={()=>{
        setSelectedpage(0);
      }}>Address Detail</div>
      <div className={`modifyaddress ${selectedpage==1 && "selectedpagedelivery"}`} onClick={()=>{
        setSelectedpage(1);
      }}>Delivery Person Detail</div>
    </div>
    { selectedpage==0 ? 
    <>
    <div className="profile-account-setting-right-new-address-location deliverrylocationset"> 
                    <div className="useerlocationdiv"> 
                    <input placeholder="Search for area, street name...." className="userlocation1" onChange={(event)=>{
                      setLocationname(event.target.value);
                    }}></input>
                    {
                      predictedaddress.length > 0 && open && <>
                      <div className="search-modal" id="seaaarchmodaladdress" ref={refforinput}>
                        {
                          predictedaddress.map((items,index)=>{
                            return(
                              <div className="predictionDiv" onClick={(e)=>{
                                setAddressdetail((details)=>{
                                  let ab=items.description.split(",");
                                  console.log(ab);
                                  return {
                                    "address_line1": `${ab.slice(0,ab.length/2)}`,
                                    "address_line2": `${ab.slice(ab.length/2)}`,
                                    "country": "India",
                                    "district": "",
                                    "landmark": "",
                                    "latitude":`${items.latitude}`,
                                    "longitude": `${items.longitude}`,
                                    "pincode": "",
                                    "state":"",
                                    "town": ""
                                  }
                                })
                              }}>{items.description}</div>
                            )
                          })
                        }
                      </div>
                      </>
                    }
                  </div>
                    <button onClick={handleClick} className="buttton">
                  <img src={locationImage} alt="" />
                  <p>Use my current location</p>

                </button>

                {location && (
                  <div
                    style={{
                      width: "400px",
                      height: "300px",
                      position: "relative",
                    }}
                  >
                    <Map
                      google={window.google}
                      apiKey={googleMapsApiKey}
                      zoom={14}
                      initialCenter={location}
                      style={{ width: "100%", height: "100%" }}
                    >
                      <Marker
                        position={location}
                        draggable={true}
                        onDragend={(t, map, coord) =>
                          handleMarkerDragend(coord)
                        }
                      />
                    </Map>
                  </div>
                )}
      </div>

<div className="my-job-post-service-profile-page-view-main-2 deliverypanel">
      <div class="add-service-provider-profile-right-inputs">
                        <span>Address Line 1 <span class="star" style={{ color:"red"}}>*</span></span>
                        <input
                          type="text"
                          name="address_line1"
                          value={
                            addressdetail.address_line1 ? addressdetail.address_line1:""
                          }
                          onChange={handleChange("address")}
                          placeholder="Enter Address"
                        ></input>
                      </div>
                      <div class="add-service-provider-profile-right-inputs">
                        <span>Address Line 2</span>
                        <input
                          type="text"
                          name="address_line2"
                          value={
                            addressdetail.address_line2 ? addressdetail.address_line2:""
                          }
                          onChange={handleChange("address")}
                          placeholder="Enter Address"
                        ></input>
                      </div>
                      <div class="add-service-provider-profile-right-inputs">
                        <span>Town <span class="star" style={{ color:"red"}}>*</span></span>
                        <input
                          type="text"
                          name="town"
                          value={
                            addressdetail.town ? addressdetail.town:""
                          }
                          onChange={handleChange("address")}
                          placeholder="Enter town"
                        ></input>
                      </div>
                      <div class="add-service-provider-profile-right-inputs">
                        <span>LandMark </span>
                        <input
                          type="text"
                          name="landmark"
                          value={
                            addressdetail.landmark ? addressdetail.landmark:""
                          }
                          onChange={handleChange("address")}
                          placeholder="Enter landmark"
                        ></input>
                      </div>
                      <div class="add-service-provider-profile-right-inputs">
                        <span>Pincode <span class="star" style={{ color:"red"}}>*</span></span>
                        <input
                          type="text"
                          name="pincode"
                          value={
                            addressdetail.pincode ? addressdetail.pincode:""
                          }
                          onChange={handleChange("address")}
                          placeholder="Enter pincode"
                        ></input>
                      </div>
                      <div class="add-service-provider-profile-right-inputs">
                        <span>Select State <span class="star"style={{ color:"red"}}>*</span></span>
                        <select
                          name="state"
                          value={
                            addressdetail.state ? addressdetail.state:""
                          }
                          onChange={handleChange("address")}
                        >
                          <option value="">Select State</option>
                          {allStates.map((state) => (
                            <option key={state} value={state}>
                              {state}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div class="add-service-provider-profile-right-inputs">
                        <span>Select District <span class="star" style={{ color:"red"}}>*</span></span>
                        <select
                          name="district"
                          value={
                            addressdetail.district  ? addressdetail.district:""
                          }
                          onChange={handleChange("address")}
                        >
                          <option value="">Select District</option>
                          {
                            addressdetail.state != "" &&
                            getDistricts(addressdetail.state).map(
                              (district) => (
                                <option key={district} value={district}>
                                  {district}
                                </option>
                              )
                            )}
                        </select>
                      </div>
                      <div class="add-service-provider-profile-right-inputs">
                        <span>Latitude <span class="star" style={{ color:"red"}}>*</span></span>
                        <input
                          type="text"
                          name="latitude"
                          value={
                            addressdetail.latitude ? addressdetail.latitude:""
                          }
                          onChange={handleChange("address")}
                          readOnly
                          placeholder="Enter latitude"
                        ></input>
                      </div>
                      <div class="add-service-provider-profile-right-inputs longitude">
                        <span>Longitude <span class="star" style={{ color:"red"}}>*</span></span>
                        <input
                          type="text"
                          name="longitude"
                          readOnly
                          value={
                            addressdetail.longitude  ? addressdetail.longitude:""
                          }
                          onChange={handleChange("address")}
                          placeholder="Enter longitude"
                        ></input>
                      </div>
                      {/* <div className="my-job-post-service-profile-page-view-main-input-submit">
                      onClick={modifyStoreDetail}
                        <button>Submit</button>
                      </div> */}
                    </div>
    </> : 
    <>
   {selectedpage==1 && 
   <>
    { (!uploading) ? <div className="add-address-modal-coupon-enter-image">
    <div className="add-address-modal-coupon-enter-image-section">
      <img src={deliveryinformation.image} alt="profile-image"></img>
{ imagedeleteicon && <div className="crossIcon-imagediv" onClick={()=>{
    setDeliveryinformation((prevdata)=>{
    return{
      ...prevdata,
      image:bydefaultimageref.current
    }
    })
    setImagedeleteicon(false);
  }}>
  <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" class="crossIcon-image" height="30" width="30" xmlns="http://www.w3.org/2000svg">
    <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm86.63 272L320 342.63l-64-64-64 64L169.37 320l64-64-64-64L192 169.37l64 64 64-64L342.63 192l-64 64z" className="crossIcon-imagepath"></path></svg>
  </div>
}
    </div>
    <input id="imageinput" type="file" name="image" accept="image/png, image/jpeg" onChange={handleimagechange}></input>
    <label htmlFor="imageinput" className="imagelabel">Choose Image File</label>
  </div>:<div className="loaderdiv"><Loading text="Uploading Image"></Loading></div>}

<div className="my-job-post-service-profile-page-view-main-2 deliverypanel">
<div class="add-service-provider-profile-right-inputs">
              <span>DeliveryBoy Name<span class="star" style={{ color:"red"}}>*</span></span>
              <input
                type="text"
                name="name"
                value={
                  deliveryinformation.name ? deliveryinformation.name:""
                }
                onChange={(event)=>{
                  handleChangename(event)
                }}
                placeholder="Enter Name of delivery boy"
              ></input>
            </div>
            <div class="add-service-provider-profile-right-inputs">
              <span>Mobile Number<span class="star" style={{ color:"red"}}>*</span></span>
              <input
                type="number"
                name="mobile"
                value={
                  deliveryinformation.mobile ? deliveryinformation.mobile : ""
                }
                onChange={(event)=>{
                  handleChangename(event)
                }}
                placeholder="Enter Mobile number"
              ></input>
            </div>
            <div class="add-service-provider-profile-right-inputs">
              <span>WhatsApp Number</span>
              <input
                type="number"
                name="whatsappnumber"
                value={
                  deliveryinformation.whatsappnumber  ? deliveryinformation.whatsappnumber : ""
                }
                onChange={(event)=>{
                  handleChangename(event)
                }}
                placeholder="Enter Whatsapp number"
              ></input>
            </div>
            <div class="add-service-provider-profile-right-inputs">
              <span>Email id</span>
              <input
                type="email"
                name="email"
                value={
                  deliveryinformation.email ? deliveryinformation.email : ""
                }
                onChange={(event)=>{
                  handleChangename(event)
                }}
                placeholder="Enter Email Id"
              ></input>
            </div>
            <div class="add-service-provider-profile-right-inputs">
              <span>Adhaar Number:<span class="star" style={{ color:"red"}}>*</span></span>
              <input
                type="number"
                name="adhaar"
                value={
                  deliveryinformation.adhaar ? deliveryinformation.adhaar : ""
                }
                onChange={(event)=>{
                  handleChangename(event)
                }}
                placeholder="Enter Adhaar number"
              ></input>
            </div>
            <div class="add-service-provider-profile-right-inputs">
              <span>Vehicle Number<span class="star"style={{ color:"red"}}>*</span></span>
              <input
                type="text"
                name="vehicle_number"
                value={
                  deliveryinformation.vehicle_number  ? deliveryinformation.vehicle_number : ""
                }
                onChange={(event)=>{
                  handleChangename(event)
                }}
                placeholder="Enter Vehicle number"
              ></input>
            </div>
            <div class="add-service-provider-profile-right-inputs longitude">
            <span>Description</span>
            <textarea name="description" rows="2" cols="50" onChange={(event)=>{
                  handleChangename(event)
                }} value={
                  deliveryinformation.description ? deliveryinformation.description:""
                }/>
            </div>
            <div className="my-job-post-service-profile-page-view-main-input-submit">
            {/* onClick={apicallonsubmit} */}
              <button onClick={()=>{apicallonsubmit()}}>{Userprofile.is_deliveryperson ? <>Modify</>:<>Submit</>}</button>
            </div>
          </div>
   </>}
    </>}
    </>
  )
}

export default Manage_delivery