import React, { Component, useState } from 'react';
import Footer from '../components/footer';
import Loan from './image/loan.png';
import './css/loanmainpage.css';
import { useGlobalContext } from '../context';
import Loanapi from '../api/Loanapi';
import notify from '../error';
export default function LoanPage() {
  const { loginDetail, Userprofile } = useGlobalContext();
  const [submited,setSubmited] = useState(false);
  const [formData, setFormData] = React.useState({
    name: '',
    mobile: '',
    email: '',
    city: '',
    is_salaried: false,
    annual_income: '',
    loan_type: '',
    is_user: false,
    loan_amount: '',
    state: '',
  });
  const [auth, setAuth] = React.useState();


  //     Car loan
  // Two wheeler loan
  // personal loan
  // ​​​​​​​home loan

  const [statesList, setStatesList] = React.useState();
  const [cityList, setCityList] = React.useState();

  function handleKeyPress(event) {
    const currentLength = event.target.value.length;
    let inputName = event.target.name;
    if (inputName === 'annual_income'|| inputName === 'loan_amount'){
      if (currentLength >= 6 || (event.key && !/^[0-9]*$/.test(event.key))) {
        event.preventDefault();
      }
    }
  
    if (currentLength >= 10 || (event.key && !/^[0-9]*$/.test(event.key))) {
      event.preventDefault();
    }
  }

  function handleChange(event) {
    // console.log(event);
    const { name, value, type, checked } = event.target;
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: type === 'checkbox' ? checked : value,
      };
    });
    // console.log(formData);
  }

  const MyStates = async () => {

    const response = await Loanapi.GetStates('India', auth);
    console.log(response.data);
    setStatesList(response.data);


  };
  const MyCity = async (st) => {
    const response = await Loanapi.GetCitites(st, auth);
    console.log(response);
    setCityList(response.data);
  };

  const MyAuth = async () => {
    const response = await Loanapi.GetAuth();
    console.log(response);
    setAuth(response.data.auth_token);
  };

  React.useEffect(() => {
    if (auth) {
      MyStates();
    }
  }, [auth]);
  React.useEffect(() => {
    MyAuth();
    if (Userprofile) {
      setFormData((prevFormData) => {
        return { ...prevFormData, is_user: true };
      });
    }
  }, [Userprofile]);

  React.useEffect(() => {
 if(formData.state){
  MyCity(formData.state);

 }
  }, [formData.state]);

  //best function i think for mobile validation
  function mob_validate(str) {
    const regexExp = /^[6789]\d{9}$/;
    if (str.length < 6) {
      // notify('error', 'Enter 10 digit numbers');
      return false;
    }
    if (
      regexExp.test(str) == false &&
      (str.length == 9 || str.length == 8 || str.length == 7 || str.length == 6)
    ) {
      // notify('error', 'Invalid mobile number');
      return false;
    }
    if (str.length > 10) {
      // notify('error', 'Enter 10 digit numbers');
      return false;
    }
    else if (regexExp.test(str) == false) {
      // notify('error','Invalid Mobile Number')
    }
    if (regexExp.test(str) == true && str.length == 10) {
      return true;
    }
    return regexExp.test(str);
  }
  function email_validate(email) {
    var validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (email.match(validRegex)) {
      return true;
    }
    // notify('error', 'Invalid email address!');
    console.log('ema');
    return false;
  }
  const onMySubmit = async () => {
    console.log(formData.is_salaried, formData.name);
    if(formData.city==='' || formData.loan_amount==='' || formData.loan_type===''||formData.name===''||formData.state==='' )
    {
      setSubmited(true);
      notify('error','Please Fill All The Fields')
      return;
    }
    if (!email_validate(formData.email)) {
      setSubmited(true);
      return;
    }
    if (!mob_validate(formData.mobile)) {
      setSubmited(true); 
      return;
    }
    
    
    if (formData.annual_income.replace(/^0+/, '') < 10000) {
      notify('error', 'minimum amount 10000');
      setSubmited(true); 
      return;
    }
    
    setSubmited(false); 
    
    if(Userprofile){
      const response = await Loanapi.ApplyLoan(
        formData.name.replace(/[^a-z0-9\s]/gi, '').substring(0, 50),
        formData.mobile,
        formData.email,
        formData.city,
        formData.is_salaried === 'true' ? true : false,
        formData.annual_income.replace(/^0+/, ''),
        formData.loan_type,
        formData.is_user,
        parseInt(formData.loan_amount.replace(/^0+/, ''))
      );
      console.log(response);
      if (response.data.error !== '') {
        notify('error', response.data.error);
      } else {
        notify('success', 'successfully applied for loan');
        setFormData({
          name: '',
          mobile: '',
          email: '',
          city: '',
          is_salaried: false,
          annual_income: '',
          loan_type: '',
          is_user: false,
          loan_amount: '',
          state: '',
        })
      }
    }else{
      notify("error","Please login to proceed further")
    }
   
      
    }
  return (
    <React.Fragment>
      <>
        <div className='loan-page-main-view'>
          <div className='loan-page-inner-rec'>
            <div className='loan-page-inner-rec-col1'>
              <div className='loan-page-inner-rec-col1-image'>
                <img src={Loan}></img>
              </div>
            </div>
            <div className='loan-page-inner-rec-col2'>
              <div className='loan-page-inner-rec-col2-top'>
                <div className='loan-page-inner-rec-col2-top-heading'>
                  Get loans at lowest interest rates
                </div>
                <div className='loan-page-inner-rec-col2-top-desc'>
                  Please complete the fields below in order to receive a call
                  back in 48 hours.
                </div>
              </div>
              <div className='loan-page-inner-rec-col2-inputs'>
                <div className='loan-page-inner-rec-col2-inputs-child'>
                <div class="form-label">Name<span class="required">*</span></div>
                <input
                  type='text'
                  value={formData.name}
                  name='name'
                  onChange={handleChange}
                  placeholder='Full Name'
                  maxLength={25}
                  className={`${!formData.name && submited ? "errorborder":""}`}
                  required
                ></input>
                {
                  !formData.name && submited && <div className='error'>Please Enter Your Name</div>
                }
                </div>
                <div className='loan-page-inner-rec-col2-inputs-child'>
                <div class="form-label">Email-id<span class="required">*</span></div>
                <input
                  type='email'
                  value={formData.email}
                  name='email'
                  onChange={handleChange}
                  placeholder='Email Id'
                  maxLength={25}
                  className={`${!email_validate(formData.email) && submited ? "errorborder":""}`}
                  required
                ></input>
                {
                  !email_validate(formData.email) && submited && <div className='error'>Please Enter Your valid email ID</div>
                }
                </div>
                <div className='loan-page-inner-rec-col2-inputs-child'>
                <div class="form-label">Mobile number<span class="required">*</span></div>
                <input
                  type='number'
                  value={formData.mobile}
                  name='mobile'
                  onChange={handleChange}
                  onKeyPress={handleKeyPress}
                  placeholder='Mobile Number'
                  required
                  maxLength='10'
                  className={`${!mob_validate(formData.mobile) && submited  ? "errorborder":"" }`}
                ></input>
                {
                  !mob_validate(formData.mobile) && submited && <div className='error'>Please Enter Your valid 10 digit mobile number</div>
                }
</div>
<div className='loan-page-inner-rec-col2-inputs-child'>
<div class="form-label">State<span class="required">*</span></div>
                {statesList ? (
                  <select
                    value={formData.state}
                    onChange={handleChange}
                    name='state'
                    placeholder='Select State'
                    className={`${!formData.state && submited ? "errorborder":"" }`}
                  >
                    <option key={989} value={''}>Select State</option>
                    {statesList.map((item,index) => {
                       return <option key={index} value={item.state_name}>{item.state_name}</option>;
                    })}
                  </select>
                ) : (
                  <select
                    value={formData.state}
                    onChange={handleChange}
                    name='state'
                    placeholder='Select State'
                    className={`${!formData.state && submited ? "errorborder":""}`}
                  >
                    <option value=''>Select State</option>
                  </select>
                )}
            {
                  !formData.state && submited && <div className='error'>Please Select Your state</div>
                }    
</div>
<div className='loan-page-inner-rec-col2-inputs-child'>
<div class="form-label">City<span class="required">*</span></div>
                <select
                  value={formData.city}
                  onChange={handleChange}
                  name='city'
                  placeholder='Select City'
                  className={`${!formData.city && submited ? "errorborder":""}`}
                >
                  <option value=''>Select City</option>
                  {cityList &&
                    cityList.map((item) => {
                       return <option value={item.city_name}>{item.city_name}</option>;
                    })}
                </select>
                {
                  !formData.city && submited && <div className='error'>Please Select Your city</div>
                }   
</div>
<div className='loan-page-inner-rec-col2-inputs-child'>
<div class="form-label">Select Employment Type<span class="required">*</span></div>
                <select
                  value={formData.is_salaried}
                  onChange={handleChange}
                  name='is_salaried'
                  placeholder='Select Employment Type'
                  className={`${!formData.is_salaried && submited ? "errorborder":""}`}
                >
                  <option value=''>Select Employment Type</option>
                  <option value={true}>Salaried</option>
                  <option value={false}>Self-Employed</option>
                </select>
                {
                  !formData.is_salaried && submited && <div className='error'>Please Select Employment Type </div>
                }
                </div>
                <div className='loan-page-inner-rec-col2-inputs-child'>
                <div class="form-label">Annual Income<span class="required">*</span></div>
                <input
                  type='number'
                  value={formData.annual_income}
                  name='annual_income'
                  onChange={handleChange}
                  onKeyPress={handleKeyPress}
                  placeholder='Annual Income'
                  required
                  className={`${formData.annual_income.replace(/^0+/, '') < 10000 && submited ? "errorborder":""}`}
                ></input>
                {
                  formData.annual_income.replace(/^0+/, '') < 10000 && submited && <div className='error'>Annual income should be more than 100000</div>
                }
                </div>
                <div className='loan-page-inner-rec-col2-inputs-child'>
                <div class="form-label">Loan Amount<span class="required">*</span></div>
                <input
                  type='number'
                  value={formData.loan_amount}
                  name='loan_amount'
                  onChange={handleChange}
                  onKeyPress={handleKeyPress}
                  placeholder='Loan Amount'
                  className={`${!formData.loan_amount && submited ? "errorborder":""}`}
                  required
                ></input>
                {
                  !formData.loan_amount && submited && <div className='error'>Please Enter loan amount</div>
                }
                </div>
                <div className='loan-page-inner-rec-col2-inputs-child'>
                <div class="form-label">Loan Type<span class="required">*</span></div>
                <select
                  value={formData.loan_type}
                  onChange={handleChange}
                  name='loan_type'
                  placeholder='Select Loan Type'
                  className={`${!formData.loan_type && submited ? "errorborder":""}`}
                >
                  <option value=''>Select Loan Type</option>
                  <option value='home loan'>Home Loan</option>
                  <option value='credit cards'>Credit Cards</option>
                  <option value='car loans'>Car Loans</option>
                  <option value='personal loan'>Personal Loans</option>
                  <option value='bussiness loan'>Business Loans</option>
                  <option value='education loan'>Education Loans</option>
                  <option value='wheeler loan'>Two Wheeler Loans</option>
                  <option value='mortgage loan'>Mortgage Loans</option>
                </select>
                {
                  !formData.loan_type && submited && <div className='error'>Please select loan type</div>
                }
                </div>
              </div>
              <div className='loan-page-inner-rec-col2-submit'>
                <button onClick={onMySubmit}>Submit</button>
              </div>
              
            </div>
          </div>
        </div>

      </>
    </React.Fragment>
  );
}
