import React, { useEffect, useState } from 'react'
import back from "../myprofileIcoons/bx-arrow-back..svg";
import "../MyServices/css/Addcoupon.css";
import myProfileServiceApi from "../../api/myProfileServiceApi";
import { useGlobalContext } from '../../context';
import notify from '../../error';
import arrowdown from "../../../src/images/arrow-down-3101.svg"

const Addcoupon = ({backto,loaddingcoupon,coupon}) => {

    
    const goBack = () =>{
        coupon();
        loaddingcoupon();
        backto(false);
    }
    const coupontype=["Service","Subservice","Product","Comboproduct"]
    const [selectedcoupontype,setSelectedcoupontype]=useState("notselected");
    const [selectedservicetype,setSelectedservicetype]=useState("notselected");
    const [formdata,setFormdata]=useState({
        "cashback_in_rupee":"",
    });
    const [servicelistarray,setServicelistarray]=useState([]);
    const {Userprofile}=useGlobalContext();
    const [productlist,setProductlist]=useState([]);
    const [productcombolist,setProductcombolist]=useState(false);
    const [comboproductarray,setComboproductarray]=useState([]);
    const [couponobj,setCouponobj]=useState({
        sp_id:Userprofile.service_provider_id,
        "coupon_name":"",
        "discount_in_rupee":"",
        "base_cart_price":"",
        "maximum_discount_rupee":""
    });
    const [checkcorrect,setCheckcorrect]=useState(false);
    const [UserSpecificcheck,setUserSpecificcheck]=useState(false);
    useEffect(()=>{
        const Serviceapicall=async ()=>{
     const response =
      await myProfileServiceApi.Showsubserviceasperserviceprovider(
        Userprofile.service_provider_id
      );
      setServicelistarray(response.data.servicelist);
}
        Serviceapicall();
    },[])

    const handlechangeform = (event)=>{
        setCouponobj({
            ...couponobj,
            [event.target.name]:event.target.value
        })
    }
    const apicalling = async () =>{
        if(couponobj.coupon_name == ""){
            notify("error","Please enter coupon name")
            return;
        }
        if(couponobj.expiry_date == ""){
            notify("error","Please select expiry date")
            return;
        }
        if(couponobj.base_cart_price == ""){
            notify("error","Please enter cart price")
            return;
        }
        if(couponobj.discount_in_percentage == "" || couponobj.discount_in_rupee == ""){
            notify("error","Please enter discount in percentage/rupee")
            return;
        }
        if((checkcorrect && (couponobj.cashback_in_percentage == "" || couponobj.cashback_in_rupee == ""))){
            notify("error","Please enter cashback in percentage/rupee")
            return;
        }
        if(checkcorrect && (couponobj.maximum_cashback_rupee == "")){
            notify("error","Please enter maximum cashback in rupee")
            return;
        }
        if(couponobj.maximum_discount_rupee == ""){
            notify("error","Please enter maximum discount in rupee")
            return;
        }
        if(selectedcoupontype === "Comboproduct" && productlist.length < 2){
            notify("error","Please select more than one product")
            return;
        }
        if(!checkcorrect && couponobj.cashback_in_percentage == ""){
            setCouponobj((couponobj)=>{
                    delete couponobj["cashback_in_percentage"]
                return {
                    ...couponobj
                }
            })
        }
        if(!checkcorrect && couponobj.cashback_in_rupee == ""){
            setCouponobj((couponobj)=>{
                    delete couponobj["cashback_in_rupee"]
                return {
                    ...couponobj
                }
            })
        }
        let response;
        if(selectedcoupontype === "Service"){
            response=await myProfileServiceApi.couponadd(
                {
                ...couponobj,
                ["service_id"]:formdata.service_id
            })
            console.log(response);
        }
        if(selectedcoupontype === "Subservice"){
            response= await myProfileServiceApi.couponadd(
                {
                ...couponobj,
                ["sub_service_id"]:formdata.sub_service_id
            })
            console.log(response);
        }
        if(selectedcoupontype === "Product"){
            response= await myProfileServiceApi.couponadd(
                {
                ...couponobj,
                ["product_id"]:formdata.product_id,
                ["product_service_id"]:formdata.service_id
            })
            console.log(response,response.error);
        }
        if(selectedcoupontype === "Comboproduct"){
            response= await myProfileServiceApi.couponadd(
                {
                ...couponobj,
                ["is_combo"]:1,
                ["product_ids"]:comboproductarray,
                ["combo_service_id"]:formdata.service_id
            })
        }
        if(response.data && response.data.error === ""){
            notify("success","coupon added successfully");
            backto(false);
        }
        else{
            notify("error",response.data.error)
        }
    }
    useEffect(()=>{
        const productapicall = async () =>{
            console.log(selectedcoupontype === "Product",selectedcoupontype === "Comboproduct",(selectedcoupontype === "Product" || selectedcoupontype === "Comboproduct"),formdata.service_id,formdata.sub_service_id)

            if((selectedcoupontype === "Product" || selectedcoupontype === "Comboproduct") && formdata.service_id && formdata.sub_service_id){
                let payload= {
                    sp_id:Userprofile.service_provider_id,
                    service_id:formdata.service_id,
                    sub_service_id:formdata.sub_service_id
                }
                const response = await myProfileServiceApi.fetchproducts(payload)
                if(response && response.data && response.data.error == ""){
                    if(response.data.product_names.length !=0){
                        setProductlist(response.data.product_names)
                    }
                    else{
                        notify("error",response.data.error)
                        setFormdata((formdata)=>{
                            delete formdata["sub_service_id"]
                            return {
                                ...formdata,
                            }
                        })
                    }
                }
                else if (response && response.data && response.data.error != ""){
                    notify("error","Error while fetching the product list")
                }
            }
        }
        productapicall();
    },[selectedcoupontype,selectedservicetype,formdata])

    const handlechange = (event) =>{
        const {name,value}=event.target;
        if(value !== "notselected"){
        setFormdata((formdata)=>{
            return {
                ...formdata,
                [name]:value,
            }
        })
    }
    else {
        setFormdata((formdata)=>{
            delete formdata[name]
            return {
                ...formdata,
            }
        })
    }
}
const handlecheckradio = (event)=>{
    if(event.target.name == "discount_check") {
        if(event.target.id == "discountyes"){
            delete couponobj["discount_in_rupee"]
            setCouponobj({
                ...couponobj,
                "discount_in_percentage":""
            })
        }
        else{
            delete couponobj["discount_in_percentage"]
            setCouponobj({
                ...couponobj,
                "discount_in_rupee":""
            })
        }
    }
    else if(event.target.name == "cashbackyes"){
        if(event.target.id == "cashback_in_percentage"){
            delete formdata["cashback_in_rupee"]
            setFormdata({
                ...formdata,
                "cashback_in_percentage":""
            })
        }
        else{
            delete formdata["cashback_in_percentage"]
            setFormdata({
                ...formdata,
                "cashback_in_rupee":""
            })
        }
    }
}
  return (
    <>
    <div className='addcouponsection manage-products-profile-service-main-page-inputs manage-products-profile-inputs-align'>
    <div className="my-service-profile-ongoing-and-pending-online-back">
              <img src={back} onClick={goBack} alt='back'></img>
    </div>
    <div className='couponparent'>
    <div className='coupontypeselection firstSecion'>

    <div className='add-service-provider-profile-right-inputs add-new-product-inputs '>
    <div>
                    Coupon Type{" "}
                    <div className="job-application-field-required">*</div>
                    {" "}:
    </div>
    <select
                    name="Coupontype"
                    placeholder="Enter Coupon Type"
                    // value={selectedcoupontype}
                    onChange={(event)=>{setSelectedcoupontype(event.target.value)}}
                  >
                    <option value="notselected">Select Coupon Type</option>
                    {
                        coupontype && 
                        <>
                     {
                        coupontype.map((item)=>{
                            return (
                                <option value={item}>{item}</option>
                            )
                        })
                     }
                        </>
                    }
                    
    </select>
    </div>

    {
     selectedcoupontype !== "notselected" &&  <div className='add-service-provider-profile-right-inputs add-new-product-inputs '>
    <div>
                    Service Category{" "}
                    <div className="job-application-field-required">*</div>
                    {" "}:
    </div>
    <select
                    name="service_id"
                    placeholder="Select Sevice Category"
                    onChange={(event)=>{

                        let selectedservice = servicelistarray.filter((item)=>{
                            return item.id == event.target.value;
                        })
                        console.log(selectedservice);
                        if(selectedservice.length > 0){
                            setSelectedservicetype(...selectedservice);
                        }
                        else {
                            setSelectedservicetype("notselected");
                        }
                        handlechange(event);
                    }}
                  >
                    <option value="notselected">Select Service Category</option>
                    {
                        servicelistarray && 
                        <>
                     {
                        servicelistarray.map((item)=>{
                            return (
                                <option value={item.id}>{item.service}</option>
                            )
                        })
                     }
                        </>
                    }
                    
    </select>
    </div>
}
    {
     selectedservicetype !== "notselected" && selectedcoupontype !== "Service" &&  <div className='add-service-provider-profile-right-inputs add-new-product-inputs '>
    <div>
                    Sub-Service Category{" "}
                    <div className="job-application-field-required">*</div>
                    {" "}:
    </div>
    <select
                    name="sub_service_id"
                    placeholder="Select subSevice Category"
                    onChange={(event)=>{
                        handlechange(event);
                    }}
                  >
                    <option value="notselected">Select Subservice Category</option>
                    {
                        selectedservicetype && selectedservicetype.subcategory &&
                        <>
                     {
                        Object.keys(selectedservicetype.subcategory).map((item)=>{
                            return (
                                <option value={selectedservicetype.subcategory[item].sb_id}>{item}</option>
                            )
                        })
                     }
                        </>
                    }
                    
    </select>
    </div>
}
    {
     selectedservicetype !== "notselected" && selectedcoupontype == "Product" && selectedservicetype &&formdata.sub_service_id && <div className='add-service-provider-profile-right-inputs add-new-product-inputs '>
    <div>
                    Product Names{" "}
                    <div className="job-application-field-required">*</div>
                    {" "}:
    </div>
    <select
                    name="product_id"
                    placeholder="Select Product Name"
                    onChange={(event)=>{
                       handlechange(event)
                    }}
                  >
                    <option value="notselected">Select Product Name</option>
                    {
                        productlist && 
                        <>
                     {
                        productlist.map((item)=>{
                            return (
                                <option value={item.product_id}>{item.product_name}</option>
                            )
                        })
                     }
                        </>
                     }
                    
    </select>
    </div>
}
{
    selectedservicetype !== "notselected" && selectedcoupontype == "Comboproduct" && selectedservicetype &&formdata.sub_service_id && <div className='add-service-provider-profile-right-inputs add-new-product-inputs '>
    <div>
                    Comboproduct Category{" "}
                    <div className="job-application-field-required">*</div>
                    {" "}:

    </div>
    <div className='checkboxcontainer'>
    <div className='couuuponcombo' onClick={()=>{
        setProductcombolist(!productcombolist);
    }}>
      <span>{comboproductarray.length} products selected</span>
      <img src={arrowdown} className='arrowdown'></img>


    </div>
   {productcombolist && <div className='productslist'>
   {productlist && productlist.map((element)=>{
    return (
        <label for={element.product_name}>
                    {element.product_name}
                    <input type="checkbox" id={element.product_name} value={element.product_id} onChange={(event)=>{
                        if(event.target.checked){
                            setComboproductarray([...comboproductarray,parseInt(event.target.value)])
                        }
                        else{
                            setComboproductarray(comboproductarray.filter((element)=>{
                                return element != event.target.value;
                            }))
                        }
                    }} checked={comboproductarray.some(c => c === element.product_id)}/>
        </label>
    )
   })
}
    </div>}
    </div>
    </div>
}

{
  ((selectedcoupontype === "Service" && formdata.service_id) || (selectedcoupontype === "Subservice" && formdata.service_id && formdata.sub_service_id) || (selectedcoupontype === "Product" && formdata.service_id && formdata.sub_service_id && formdata.product_id) || (selectedcoupontype === "Comboproduct" && formdata.service_id && formdata.sub_service_id && comboproductarray.length >= 2 ))  && <div className='add-service-provider-profile-right-inputs add-new-product-inputs '>
    <div>
                    Coupon Name{" "}
                    <div className="job-application-field-required">*</div>
                    {" "}:

    </div>
    <div className='checkboxcontainer'>
        <input type='text' name="coupon_name" onChange={handlechangeform} value={couponobj.coupon_name}></input>
    </div>
    </div>
}

{
 ((selectedcoupontype === "Service" && formdata.service_id) || (selectedcoupontype === "Subservice" && formdata.service_id && formdata.sub_service_id) || (selectedcoupontype === "Product" && formdata.service_id && formdata.sub_service_id && formdata.product_id) || (selectedcoupontype === "Comboproduct" && formdata.service_id && formdata.sub_service_id && comboproductarray.length >= 2 ))  &&    <div className='add-service-provider-profile-right-inputs add-new-product-inputs '>
    <div className='checksellected'>
    Do you want to provide discount in percentage  {" "}
                    <div className="job-application-field-required">*</div>
                    {" "} ?
    <div className='checkboxchild'>
        <div>
        <label for="discountyes">Yes</label>
        <input type='radio' id='discountyes' name='discount_check' checked={couponobj["discount_in_percentage"] != undefined}  onClick={handlecheckradio}></input>
        </div>
        <div>
        <label for="discountno">No</label>
        <input type='radio' id='discountno' name='discount_check' checked={couponobj["discount_in_rupee"] != undefined} onClick={handlecheckradio}></input>
        </div>

    </div>
    </div>
    <div className='checksellected1'>
        <div>
        Discount {" "}
                    <div className="job-application-field-required">*</div>
                    {" "}:
        </div>
        <input type='number'  onChange={(event)=>{
                        if(couponobj.discount_in_percentage !== undefined){
                            delete couponobj["discount_in_percentage"]
                            setCouponobj({
                                ...couponobj,
                                "discount_in_percentage":event.target.value
                            })
                        }
                        else if(couponobj.discount_in_rupee !== undefined){
                            delete couponobj["discount_in_rupee"]
                            setCouponobj({
                                ...couponobj,
                                "discount_in_rupee":event.target.value
                            })
                        }}}
                        ></input>
    </div>
    </div>
}
{
     ((selectedcoupontype === "Service" && formdata.service_id) || (selectedcoupontype === "Subservice" && formdata.service_id && formdata.sub_service_id) || (selectedcoupontype === "Product" && formdata.service_id && formdata.sub_service_id && formdata.product_id) || (selectedcoupontype === "Comboproduct" && formdata.service_id && formdata.sub_service_id && comboproductarray.length >= 2 ))  &&
     <div className='add-service-provider-profile-right-inputs add-new-product-inputs '>
     <div className='checksellected cashbackyes'>
     <div>
     Do you want to provide Cashback  <div className="job-application-field-required">*</div>  {" "}
                     ?
     </div>
     <div className='checkboxchild'>
         <div>
         <label for="cashhyes">Yes</label>
         <input type='radio' id='cashhyes'  name='cassh' checked={checkcorrect} onChange={()=>{
            setCheckcorrect(true);
        }}></input>
         </div>
         <div>
         <label for="cashhno">No</label>
         <input type='radio' id='cashhno'  name='cassh' checked={!checkcorrect} onChange={()=>{
        setCheckcorrect(false);
         }}></input>
         </div>
    </div>
    </div>
    </div>
 
}
{
 ((selectedcoupontype === "Service" && formdata.service_id) || (selectedcoupontype === "Subservice" && formdata.service_id && formdata.sub_service_id) || (selectedcoupontype === "Product" && formdata.service_id && formdata.sub_service_id && formdata.product_id) || (selectedcoupontype === "Comboproduct" && formdata.service_id && formdata.sub_service_id && comboproductarray.length >= 2 ))  && (checkcorrect) &&    <div className='add-service-provider-profile-right-inputs add-new-product-inputs '>
    <div className='checksellected'>
    <div>
    Do you want to provide Cashback in percentage  {" "}
                    ?
    </div>
    <div className='checkboxchild'>
        <div>
        <label for="cashback_in_percentage">Yes</label>
        <input type='radio' id='cashback_in_percentage'  checked={formdata["cashback_in_percentage"] != undefined} name='cashbackyes' onClick={handlecheckradio}></input>
        </div>
        <div>
        <label for="cashback_in_rupee">No</label>
        <input type='radio' id='cashback_in_rupee' checked={formdata["cashback_in_rupee"] != undefined} name='cashbackyes' onClick={handlecheckradio}></input>
        </div>

    </div>
    </div>
    <div className='checksellected1'>
    <div>
        Cashback {" "} :
    </div>
        <input type='number' onChange={(event)=>{
                        if(formdata.cashback_in_percentage !== undefined){
                            delete formdata["cashback_in_rupee"]
                            delete couponobj["cashback_in_rupee"]
                            setCouponobj({
                                ...couponobj,
                                "cashback_in_percentage":event.target.value
                            })
                        }
                        else if(formdata.cashback_in_rupee !== undefined){
                            delete formdata["cashback_in_percentage"]
                            delete couponobj["cashback_in_percentage"]
                            setCouponobj({
                                ...couponobj,
                                "cashback_in_rupee":event.target.value
                            })
                        }}}></input>
    </div>
    </div>
}
{
 ((selectedcoupontype === "Service" && formdata.service_id) || (selectedcoupontype === "Subservice" && formdata.service_id && formdata.sub_service_id) || (selectedcoupontype === "Product" && formdata.service_id && formdata.sub_service_id && formdata.product_id) || (selectedcoupontype === "Comboproduct" && formdata.service_id && formdata.sub_service_id && comboproductarray.length >= 2 ))  &&    <div className='add-service-provider-profile-right-inputs add-new-product-inputs ammountsecction'>
    <div className='amountsection'>
        <div>Basecart price {" "}
                    <div className="job-application-field-required">*</div>
                    {" "} </div>
        <input type='number' name="base_cart_price" onChange={handlechangeform} value={couponobj.base_cart_price}></input>
    </div>
    <div className='amountsection'>
    <div>Expiry date {" "}
                    <div className="job-application-field-required">*</div>
                    {" "} </div>
        <input type='date' name="expiry_date" onChange={handlechangeform} value={couponobj.expiry_date}></input>
    </div>
    <div className='amountsection'>
    <div>Max discount rupee {" "}
                    <div className="job-application-field-required">*</div>
                    {" "} </div>
        <input type='number' name="maximum_discount_rupee" onChange={handlechangeform} value={couponobj.maximum_discount_rupee}></input>
    </div>
    {
     (checkcorrect) &&  <>
    <div className='amountsection'>
    
    <div>Max cashback rupee {" "}
                    <div className="job-application-field-required">*</div>
                    {" "}  </div>
        <input type='number' name="maximum_cashback_rupee" onChange={handlechangeform} value={couponobj.maximum_cashback_rupee}></input>
    </div>
    </>
}
    </div>
}
{
 ((selectedcoupontype === "Service" && formdata.service_id) || (selectedcoupontype === "Subservice" && formdata.service_id && formdata.sub_service_id) || (selectedcoupontype === "Product" && formdata.service_id && formdata.sub_service_id && formdata.product_id) || (selectedcoupontype === "Comboproduct" && formdata.service_id && formdata.sub_service_id && comboproductarray.length >= 2 ))  &&    <div className='add-service-provider-profile-right-inputs add-new-product-inputs '>
 <div className='checkboxcontainer'>
                Terms & Condition of coupon {" "} :
     <input type='text' name="terms_and_condition" onChange={handlechangeform} value={couponobj.terms_and_condition}></input>
 </div>
 <div className='checksellected useercheck'>
    <div>
    Is UserSpecific ?  {" "}
                    <span className="job-application-field-required">*</span>
                    {" "} 
    </div>
    <div className='checkboxchild'>
        <div>
        <label for="yes">Yes</label>
        <input type='radio' id='yes' name='is_userspecific' checked={UserSpecificcheck} onChange={()=>{
            setUserSpecificcheck(true);
        }}></input>
        </div>
        <div>
        <label for="no">No</label>
        <input type='radio' id='no' name='is_userspecific' checked={!UserSpecificcheck} onChange={()=>{
            setUserSpecificcheck(false);
            setCouponobj((couponobj)=>{
                if(couponobj["mobile"] != undefined){
                    delete couponobj["mobile"]
                }
                if(couponobj["is_userspecific"] != undefined){
                    delete couponobj["is_userspecific"]
                }
                return {
                    ...couponobj
                }
            })

        }}></input>
        </div>

    </div>
    </div>
    {
        UserSpecificcheck &&
        <>
    <div className='checksellected'>
    <div class="amountsection"><div>User Phonenumber  <div class="job-application-field-required">*</div>  </div><input type="tel" name="mobile" onChange={(event)=>{
        setCouponobj((couponobj)=>{
            return {
                ...couponobj,
                [event.target.name]:`+91${event.target.value}`,
                ["is_userspecific"]:true,
            }
        })
    }}/></div>
    </div>
    </>
}
<div>

</div>
 </div>
}
    </div>
    </div>
    {
         ((selectedcoupontype === "Service" && formdata.service_id) || (selectedcoupontype === "Subservice" && formdata.service_id && formdata.sub_service_id) || (selectedcoupontype === "Product" && formdata.service_id && formdata.sub_service_id && formdata.product_id) || (selectedcoupontype === "Comboproduct" && formdata.service_id && formdata.sub_service_id && comboproductarray.length >= 2 ))  &&  
         <div class="my-job-post-service-profile-page-view-main-input-submit" onClick={apicalling}>
        <button>Create Coupon</button>
    </div>
}
    </div>
    </>
  )
}

export default Addcoupon