import React, { useRef, useState, Suspense, lazy } from "react";
// import Header from './components/header';
import Sidebar from "./components/sidebar";
import Dashboard from "./components/dashboard";
import api from "./api/api";
import apiclient from "./api/apiclient";
import AllService from "./components/allServices";
import BestSellers from "./components/bestSellers";
import Footer from "./components/footer";
import PopularProductByService from "./components/popularProductsByService";
import "./app.css";
import { useGlobalContext } from "./context";
import { IoCloseCircleSharp } from "react-icons/io5";
import LoginModal from "./components/LoginModal";
import logo from "./images/logo.png";
import notify from "./error";
import Loading from "./components/Loading";
import { ClipPath } from "@react-pdf/renderer";
// import useScript from './useScript';
// import { getLocation2 } from './js/location';

const Carouselwithfixserviceid = lazy(() => import('./components/carouselwithfixservice_id'));

function HomeView() {
  const prevFavorite = useRef();
  const [isSelectorVisible, setIsSelectorVisible] = useState(false);

  const {
    token,
    setServiceId,
    allServiceSelector,
    allService,
    setAllServiceSelector,
    products,
    tempProduct,
    setTempProduct,
    UndesiredProducts,
    setUndesiredProducts,
    desiredProducts,
    setDesiredProducts,
    loading,
    setLoading,
    statusloading,
    setStatusloading
  } = useGlobalContext();

  React.useEffect(() => {
    setLoading(true);
  }, []);

  React.useEffect(() => {
    console.log("rerendered in home page");
    // setLoading(true);
  }, [tempProduct]);

  const FormatServiceName = (formattedName) => {
    // Replace spaces with underscores
    const nameWithUnderscores = formattedName.replace(/ /g, "_");

    // Replace & with &amp;
    return nameWithUnderscores.replace(/&/g, "and");
  };



  const selection = async (e) => {
    setStatusloading(true);
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    let temp = e.target.dataset.value;
    if (prevFavorite.current === temp) {
      setStatusloading(false);
      // console.log("hello", prevFavorite.current, temp);
      return;
    }
    // console.log(e.target.dataset.value);
    DisplaySelectors2();
    prevFavorite.current = temp;
    let name = FormatServiceName(e.target.dataset.description);
    // console.log(name);
    // console.log(tempProduct, tempProduct.indexOf(temp));
    const response = await apiclient.addFavservice(name);
    // console.log(response);
    if (response.data.error !== "") {
      notify("error", response.data.error);
    } else {
      notify("success", "Added to Favourite ");
    }
    if (tempProduct.indexOf(temp) > -1) {
      // console.log("greater than 0");
      if (UndesiredProducts.indexOf(temp) === -1) {
        setUndesiredProducts((prev) => [...prev, temp]);
        // console.log(UndesiredProducts, "undesired");
      } else {
        setUndesiredProducts((prevSquares) => {
          const newSquares = [];
          for (let i = 0; i < prevSquares.length; i++) {
            const currentSquare = prevSquares[i];
            if (currentSquare !== temp) {
              // console.log(currentSquare, temp);
              newSquares.push(currentSquare);
            }
          }
          // setstatus(false);
          // console.log(newSquares);
          return newSquares;
        });
        // console.log(UndesiredProducts, "undersired");
      }
    } else {
      // console.log("less than 0");
      setTempProduct([parseInt(temp)]);
      localStorage.setItem('serviceId', parseInt(temp));
      setServiceId(parseInt(temp));

      setUndesiredProducts((prevSquares) => {
        const newSquares = [];
        for (let i = 0; i < prevSquares.length; i++) {
          const currentSquare = prevSquares[i];
          if (currentSquare !== temp) {
            console.log(currentSquare, temp);
            newSquares.push(currentSquare);
          }
        }
        // console.log(newSquares);
        return newSquares;
      });
      // console.log(UndesiredProducts, "undesired");
      // console.log(tempProduct);
    }

    //desired

    if (desiredProducts.indexOf(temp) === -1) {
      setDesiredProducts((prev) => {
        return [parseInt(temp)];
      });
      // console.log(desiredProducts);
    } else {
      setDesiredProducts((old) => {
        return old.filter((val) => val != temp);
      });
    }
  };


  function DisplaySelectors(e) {
    // console.log(e)
    if (e.className === "all-service-selector-none") {
      setIsSelectorVisible(false);
      return;
    }
    // console.log(wrapperRef.className);
    wrapperRef.current.className = "all-service-selector-cointainer2";
    const elem = document.getElementById("all-service-selector-id");
    elem.className = "all-service-selector";
    setIsSelectorVisible(true);
  }

  function DisplaySelectors2(e) {
    // console.log(e);
    // console.log(wrapperRef.className);
    wrapperRef.current.className = "all-service-selector-cointainer";
    const elem = document.getElementById("all-service-selector-id");
    // console.log(elem.className);
    elem.className = "all-service-selector-none";
  }

  const wrapperRef = React.useRef(null);

  React.useEffect(() => {

    const isUserLocationCompleted =
      localStorage.getItem("isUserLocationCompleted") === "true";
    console.log(desiredProducts, isUserLocationCompleted);

    const fetchData = async () => {
      if (desiredProducts.length === 0 && token) {
        setStatusloading(true);
        // console.log("jdjjd");
        const response = await apiclient.fetchfavoriteService();
        const favid = response.data.favservice;
        // console.log(favid);
        setStatusloading(true);
        if (tempProduct.indexOf(favid) === -1) {
          setTempProduct(favid);
        }
        setDesiredProducts(favid);
        setUndesiredProducts((old) => {
          return old.filter((val) => val != favid);
        });
        localStorage.setItem('serviceId', favid[0]);
        setServiceId(favid[0]);
        setStatusloading(false);
      }
    };
    fetchData();
  }, [UndesiredProducts, localStorage.getItem("isUserLocationCompleted")], token);

  return (
    <Suspense fallback={<Loading/>}>
      <React.Fragment>
        <div className="App">
          <div className="desktop-view" style={{ position: 'relative' }}>
            {/* {true && <LoginModal />} */}
            {/* <Dashboard /> */}
            <Carouselwithfixserviceid />
            {
              (statusloading) ? <><Loading stylechange="yes" /></> : <><PopularProductByService /></>
            }


            {
              (statusloading) ? <></> :
                <div className="d-none"> <BestSellers is_main_page={1} /></div>
            }
            {/* <BestSellers/> */}
            {allService.length > 0 && <div
              className="all-service-selector-cointainer"
              ref={wrapperRef}
              onMouseOver={DisplaySelectors}
              onMouseOut={DisplaySelectors2}
            >
              <div
                id="all-service-selector-id"
                className="all-service-selector-none"
              >
                <div className="all-service-selector-heading">
                  <p>Choose Services Of Your Choice</p>
                  <div className="cross-icon" onClick={DisplaySelectors2}>
                    <IoCloseCircleSharp size={30} />
                  </div>
                </div>
                <div className="all-service-selector-selectors">
                  {isSelectorVisible && allService.map((item) => {
                    if (desiredProducts.indexOf(item.id) === -1) {
                      return (
                        <React.Fragment key={item.id}>
                          <div
                            className='all-service-selector-tab'
                            data-value={item.id}
                            data-description={item.name}
                            onClick={selection}
                          >
                            {item.name}
                          </div>
                        </React.Fragment >
                      );
                    } else {
                      return (
                        <React.Fragment key={item.id}>
                          <div
                            className="all-service-selector-tab-selected"
                            data-value={item.id}
                            data-description={item.name}
                            onClick={selection}

                          >
                            {item.name}
                          </div>
                        </React.Fragment >
                      );
                    }
                  })}
                </div>
              </div>
              <div className="all-service-selector-cointainer-image">
                <img
                  src={logo}
                  onClick={DisplaySelectors}
                  onMouseOver={DisplaySelectors}
                  onMouseOut={DisplaySelectors2}
                  alt="Pop up"
                ></img>
              </div>
            </div>}

            <AllService text="All Services" />
            {/* <loading></loading> */}
          </div>

        </div>
      </React.Fragment>
    </Suspense>
  );
}

export default HomeView;
