import React, { Component } from 'react';
import MyBillsApi from '../api/MyBillsApi';
import { useGlobalContext } from '../context';
// import radar from '../images/radar-observatory-svgrepo-com.svg';
import cover from '../images/DthCoverPage.svg';
import './css/DthRechargePage.css';
import notify from '../error';
import { Navigate, useNavigate } from 'react-router-dom';
import BroadBandCover from "../images/Putatoe-Illustrations/5.png"

export default function BroadbandPage(props) {
  const [operatorList, setOperatorList] = React.useState();
  const BroadbandList = async () => {
    const response = await MyBillsApi.OtherOperatorList('Broadband');
    console.log(response);
    setOperatorList(response.data.operator_list);
  };
  React.useEffect(() => {
    BroadbandList();
  }, []);
  const [fieldValid, setFieldValid] = React.useState(false); // to check input is valid or not
  const [formData, setFormData] = React.useState({
    operator_code: '',
    DTH: '',
    amount: '',
    coupon_id: null,
    payment_method: '',
  });

  function ChangeValue(e) {
    const { name, value, type, checked } = e.target;
    // console.log(value,name)
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: type === 'checkbox' ? checked : value,
      };
    });
    console.log(formData);
    if (
      formData.DTH &&
      formData.operator_code &&
      (formData.amount > 0)
  ) {
      setFieldValid(false);
  }else{
    setFieldValid(true);
  }
  }
  const validateMobileNumber = (mobileNumber) => {
    const regex = /^[6-9]\d{9}$/;
    return regex.test(mobileNumber);
  };
  const navigate = useNavigate();
  const BillMyDTH = async () => {
    const { operator_code, DTH, amount } = formData;
    if (DTH === '') {
      setFieldValid(true);
         return;
       }
   
       if (!validateMobileNumber(DTH)) {
        setFieldValid(true);
         return;
       }
   
       if (operator_code === '') {

        setFieldValid(true);
         return;
       }
   
       if (amount === '' || parseInt(amount) <= 0) {

        setFieldValid(true);
         return;
       }
    navigate(
      '/cart/recharge',
      { replace: true,
        state: {
          op_code: operatorList[formData.operator_code].op_code,
          serial_no: formData.DTH,
          amount: formData.amount,
          circle:2,
          op_image: operatorList[formData.operator_code].operator_image,
          text1: `Customer Id ${formData.DTH}`,
          text2: `Recharge ${operatorList[formData.operator_code].operator_name} with `,
          pageno:props.pageNo,                redirect:"/mobile-recharge"
        },
      }
    );
  };

  //payments

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }
  return (
    <React.Fragment>
      <div className='my-dth-recharge-main-page-container'>
        <div className='my-dth-recharge-main-page-container-heading'>
          <div>
            {/* <img src={radar}></img> */}
            <i class="fa-solid fa-house-signal fa-2xl"></i>
          </div>
          <div>
            Let's Get Your<br></br>
            <span>Broadband Payment Done</span>
            <hr></hr>
          </div>
        </div>
        <div className='my-dth-recharge-main-page-container-main-content'>
          <div className='my-dth-recharge-main-page-container-main-content-col1'>
            <div className='my-dth-recharge-main-page-container-main-content-col1-inputs'>
            <div className={`my-dth-recharge-main-page-container-main-content-col1-inputs-1 ${(!formData.DTH&& fieldValid) || (formData.DTH && !validateMobileNumber(formData.DTH)) ? 'error' : ''}`}>
    
                <input
                  type='text'
                  value={formData.DTH}
                  onChange={ChangeValue}
                  name='DTH'
                  placeholder='Enter Customer Id'
                ></input>
                 {(!formData.DTH && fieldValid) && <span className="error-message">Please enter your Customer ID</span>}
                {formData.DTH && !validateMobileNumber(formData.DTH) && <span className="error-message"> Please enter a valid Customer ID</span>}
              </div>
              <div className={`my-dth-recharge-main-page-container-main-content-col1-inputs-1 ${(!formData.operator_code) && fieldValid ? 'error' : ''}`}>
         
                <select
                  value={formData.operator_code}
                  onChange={ChangeValue}
                  name='operator_code'
                >
                  <option value=''>Select Operator</option>
                  {operatorList &&
                    operatorList.length > 0 &&
                    operatorList.map((item,index) => {
                      return (
                        <option value={index}>
                          {item.operator_name}
                        </option>
                      );
                    })}
                </select>
                {(!formData.operator_code && fieldValid) && <span className="error-message">Please select an Operator</span>}
              </div>
              <div className={`my-dth-recharge-main-page-container-main-content-col1-inputs-1 ${(!formData.amount || !formData.amount.trim() || parseInt(formData.amount) <= 0) && fieldValid ? 'error' : ''}`}>

                <input
                  type='number'
                  value={formData.amount}
                  onChange={ChangeValue}
                  name='amount'
                  placeholder='Enter Recharge Amount'
                ></input>
                 {(!formData.amount  && fieldValid) && <span className="error-message">Please enter a recharge amount</span>}
                {(formData.amount &&formData.amount <= 0 && fieldValid) && <span className="error-message">Please enter a valid recharge amount</span>}
              </div>
              <div className='my-dth-recharge-main-page-container-main-content-col1-inputs-button'>
                <button onClick={BillMyDTH}>Proceed</button>
              </div>
            </div>
          </div>
          <div className='my-dth-recharge-main-page-container-main-content-col2'>
            <div>
              <img src={BroadBandCover} width="400"></img>
            </div>
          </div>
        </div>
        <div className='my-dth-recharge-main-page-container-allProvider-content'>
          <div className='my-dth-recharge-main-page-container-allProvider-content-all-provider'>
            All Providers
          </div>
          <div className='my-dth-recharge-main-page-container-allProvider-content-all-provider-card-holder'>
            {operatorList &&
              operatorList.length > 0 &&
              operatorList.map((item) => {
                return (
                  <div className='my-dth-recharge-main-page-container-allProvider-content-all-provider-cards'>
                    <div className='my-dth-recharge-main-page-container-allProvider-content-all-provider-cards-col1'>
                      <div>
                        <img src={item.operator_image}></img>
                      </div>
                    </div>
                    <div className='my-dth-recharge-main-page-container-allProvider-content-all-provider-cards-col2'>
                      {item.operator_name}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>

       

       
      </div>
    </React.Fragment>
  );
}
