import axios from 'axios';
//company
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

class PutatoeChatApi {
  ChatUserFriendInfo() {
    return axios.get(API_BASE_URL + '/v1/api/chatinfo', {
      headers: {
        authtoken: localStorage.getItem('authtoken'),
      },
    });
  }

  DeleteFriend(chat_id){
    return axios.post(API_BASE_URL + '/v1/api/deleteChat', {chat_id},{
      headers: {
        authtoken: localStorage.getItem('authtoken'),
      },
    });
  }
  BlockUser(chat_id){
    return axios.post(API_BASE_URL + '/v1/api/block_Chat', {chat_id},{
      headers: {
        authtoken: localStorage.getItem('authtoken'),
      },
    });
  }
  UnBlockUser(chat_id){
    return axios.post(API_BASE_URL + '/v1/api/unblock_Chat', {chat_id},{
      headers: {
        authtoken: localStorage.getItem('authtoken'),
      },
    });
  }
  ShowMessage(chat_id) {
    return axios.post(
      API_BASE_URL + '/v1/api/messeges',
      { chat_id },
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }

  ChatUserServiceInfo() {
    return axios.get(API_BASE_URL + '/v1/api/chatuserservice', {
      headers: {
        authtoken: localStorage.getItem('authtoken'),
      },
    });
  }

  SendMessage(chat_id, message) {
    return axios.post(
      API_BASE_URL + '/v1/api/send',
      { chat_id, message },
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }
  ConnectToANewFirend(friends_number) {
    return axios.post(
      API_BASE_URL + '/v1/api/connect',
      { friends_number }, //false for cart true for mobile recharge
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }
  ConnectToANewServiceProvider(serviceprovider_id) {
    return axios.post(
      API_BASE_URL + '/v1/api/connectsp',
      { serviceprovider_id }, //false for cart true for mobile recharge
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }
 
}

export default new PutatoeChatApi();
