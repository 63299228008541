import React, { Component, useEffect, useState } from "react";
import "./css/transhistory.css";
import walleticon from "./transaction.png";
import back from "../myprofileIcoons/bx-arrow-back..svg";
import arrow from "../myprofileIcoons/Frame427319514.svg";
import arrow2 from "../myprofileIcoons/Shape.svg";
import MyWalletapi from "../../api/MyWalletapi";
import { useGlobalContext } from "../../context";
export default function TransactionHistory(props) {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 480);
  const { setInnerPage } = useGlobalContext();

  useEffect(() => {
    function handleResize() {
      setIsSmallScreen(window.innerWidth < 480);
    }

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [transactionsData, setTransactionsData] = React.useState([
    {
      amount: "100",
      bank_transaction_id: "17674369650",
      "date-time": "Tue, 06 Jul 2021 15:07:31 GMT",
      order_id: "ORDERID_466",
      status: "TXN_SUCCESS",
      transaction_id: "20210530111212800110168621004068436",
    },
    {
      amount: "100",
      bank_transaction_id: "17674369650",
      "date-time": "Tue, 07 Jul 2021 15:07:31 GMT",
      order_id: "ORDERID_466",
      status: "TXN_FAILURE",
      transaction_id: "20210530111212800110168621004068436",
    },
    {
      amount: "100",
      bank_transaction_id: "17674369650",
      "date-time": "Tue, 05 Jul 2021 15:07:31 GMT",
      order_id: "ORDERID_466",
      status: "TXN_SUCCESS",
      transaction_id: "20210530111212800110168621004068436",
    },
    {
      amount: "100",
      bank_transaction_id: "17674369650",
      "date-time": "Tue, 09 Jul 2021 15:07:31 GMT",
      order_id: "ORDERID_466",
      status: "TXN_SUCCESS",
      transaction_id: "20210530111212800110168621004068436",
    },
    {
      amount: "100",
      bank_transaction_id: "17674369650",
      "date-time": "Tue, 10 Jul 2021 15:07:31 GMT",
      order_id: "ORDERID_466",
      status: "TXN_SUCCESS",
      transaction_id: "20210530111212800110168621004068436",
    },
    {
      amount: "100",
      bank_transaction_id: "17674369650",
      "date-time": "Tue, 11 Jul 2021 15:07:31 GMT",
      order_id: "ORDERID_466",
      status: "TXN_SUCCESS",
      transaction_id: "20210530111212800110168621004068436",
    },
    {
      amount: "100",
      bank_transaction_id: "17674369650",
      "date-time": "Tue, 30 Jul 2021 15:07:31 GMT",
      order_id: "ORDERID_466",
      status: "TXN_SUCCESS",
      transaction_id: "20210530111212800110168621004068436",
    },
    {
      amount: "100",
      bank_transaction_id: "17674369650",
      "date-time": "Tue, 1 Jul 2021 15:07:31 GMT",
      order_id: "ORDERID_466",
      status: "TXN_SUCCESS",
      transaction_id: "20210530111212800110168621004068436",
    },
    {
      amount: "100",
      bank_transaction_id: "17674369650",
      "date-time": "Tue, 07 Jul 2021 15:07:31 GMT",
      order_id: "ORDERID_466",
      status: "TXN_FAILURE",
      transaction_id: "20210530111212800110168621004068436",
    },
    {
      amount: "100",
      bank_transaction_id: "17674369650",
      "date-time": "Tue, 07 Jul 2021 15:07:31 GMT",
      order_id: "ORDERID_466",
      status: "TXN_FAILURE",
      transaction_id: "20210530111212800110168621004068436",
    },
    {
      amount: "100",
      bank_transaction_id: "17674369650",
      "date-time": "Tue, 07 Jul 2021 15:07:31 GMT",
      order_id: "ORDERID_466",
      status: "TXN_FAILURE",
      transaction_id: "20210530111212800110168621004068436",
    },
    {
      amount: "100",
      bank_transaction_id: "17674369650",
      "date-time": "Tue, 07 Jul 2021 15:07:31 GMT",
      order_id: "ORDERID_466",
      status: "TXN_FAILURE",
      transaction_id: "20210530111212800110168621004068436",
    },
  ]);

  const MyWalletTransactionsHistory = async () => {
    const response = await MyWalletapi.MyWalletTransactionHistory();
    console.log(response.data.transactions);
    setTransactionsData(response.data.transactions);
  };

  const WalletBalance_RewardPoints = async () => {
    // const response = await Accountapi.UserProfileDetailsService();
    // console.log(response);
  };

  React.useEffect(() => {
    MyWalletTransactionsHistory();
    // WalletBalance_RewardPoints();
  }, []);

  const [transIndex, setTransIndex] = React.useState(0);

  return (
    <React.Fragment>
      <>
        <div className="profile-wallet-transaction-page-view">
          <div className="profile-wallet-transaction-page-top">
            <div className="profile-wallet-transaction-page-back">
              <img
                src={back}
                onClick={() => {
                  props.newpage(0);
                  setInnerPage(false);
                }}
              ></img>
            </div>
            <div className="profile-wallet-transaction-page-heading">
              Transaction History
            </div>
            <div className="profile-wallet-transaction-page-view-other-detail">
              <div className="profile-wallet-transaction-page-no-of-trans">
                {transactionsData.length} transaction total
              </div>
              <div className="profile-wallet-transaction-fiter">
                Filter BY
                <select>
                  <option>Newest</option>
                  <option>Oldest</option>
                </select>
              </div>
            </div>
          </div>
          <div className="profile-wallet-transaction-page-table">
            <div className="profile-wallet-main-page-bot-table-head">
              <span>Order Id</span>
              <span>Amount</span>
              <span>Date</span>
              <span>Status</span>
              <span>Bank Transaction Id</span>
              <span>Transaction ID</span>
            </div>
            <div className="profile-wallet-main-page-bot-table-data">
              {transactionsData.length > 0 ? (
                <>
                  <div>
                    {transactionsData &&
                      transactionsData
                        .slice(transIndex, transIndex + 11)
                        .map((item) => {
                          if (isSmallScreen) {
                            return (
                              <div className="smallscreen-table-data-row">
                                <div className="table-data-row">
                                  <div className="column">
                                    Amount: <span>&#8377;{item.amount}</span>
                                  </div>
                                  <div className="column">
                                    <span>{item["date-time"]}</span>
                                  </div>
                                </div>
                                <div className="table-data-row">
                                  <div className="column">
                                    <span
                                      className={`${
                                        item.status === "Failed"
                                          ? "failedColor"
                                          : "successColor"
                                      }`}
                                    >
                                      {item.status}
                                    </span>
                                  </div>
                                  <div className="column">
                                    {" "}
                                    <span>{item.order_id}</span>
                                  </div>
                                </div>
                                <div className="table-data-row-last">
                                  <div className="column">
                                    <span>{item.transaction_id}</span>
                                  </div>
                                </div>
                              </div>
                            );
                          } else
                            return (
                              <div className="profile-wallet-main-page-bot-table-data-row">
                                <span>{item.order_id}</span>
                                <span>&#8377;{item.amount}</span>
                                <span>{item["date-time"]}</span>
                                {item.status === "TXN_SUCCESS" ||
                                item.status.includes("Success") ? (
                                  <span>{item.status}</span>
                                ) : (
                                  <div>{item.status}</div>
                                )}
                                <span>
                                  {" "}
                                  {item.bank_transaction_id ? (
                                    item.bank_transaction_id
                                  ) : (
                                    <>-</>
                                  )}
                                </span>
                                <span>{item.transaction_id}</span>
                              </div>
                            );
                        })}
                  </div>
                </>
              ) : (
                <>
                  <div className="profile-wallet-transaction-page-no-transcation">
                    <img src={walleticon} alt="icon" />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="profile-wallet-transaction-page-view-next-page">
            Page {parseInt(transIndex / 12 + 1)} of
            {parseInt(transactionsData.length / 11 + 1)}
            <img
              src={arrow2}
              onClick={() => {
                console.log(transIndex);
                if (transIndex > 0) {
                  setTransIndex((prev) => {
                    return prev - 11;
                  });
                }
              }}
            ></img>
            <img
              src={arrow}
              onClick={() => {
                console.log(transIndex);
                if (transIndex + 11 < transactionsData.length) {
                  setTransIndex((prev) => {
                    return prev + 11;
                  });
                }
              }}
            ></img>
          </div>
        </div>
      </>
    </React.Fragment>
  );
}
