import React, { Component, useState } from 'react'
import '../serviceprovider-css/productInfoModal.css';
import { useProductContext } from '../productPageContext.js';
import starf from '../images/Starf.svg';
import star from '../images/Star.svg';
import add from '../images/add.svg';
import scrollright from '../images/scrollright.svg';
import scrollleft from '../images/scrollleft.svg';
import apiclient from '../api/apiclient.js';
import notify from '../error.js';
import { BsCartPlus } from "react-icons/bs"
import { IoMdArrowBack } from "react-icons/io";
import cartapi from "../api/cartapi";
import starNull from '../images/bookmark-favorite-heart-like-rating-star-svgrepo-com.svg';
import Select from 'react-select';
import { IoIosShareAlt } from "react-icons/io";
import { useParams } from 'react-router-dom';
import { useGlobalContext } from '../context.js';
import SimilarProductsContainer from './similarProductsContainer.js';
import { IoIosArrowDown } from 'react-icons/io';
import { useNavigate } from "react-router-dom";


export default function ProductInfo(prproductDetail) {
  const { changeInCart, setChangeInCart ,isMobileView} = useGlobalContext();
  const navigate = useNavigate();

  const handleBackButtonClick = () => {
    // Go back to the previous page
 navigate(-1)
  };
  // const {productDetail,setProductDetail}=useProductContext();
  const [productDetail,setProductDetail]=React.useState();
const [loading,setLoading]=React.useState(false);
const { productId,serviceId} = useParams();
  const [options, setOptions] = React.useState([]);
  const [quantityItemIndex, setQuantityItemIndex] = React.useState(0);
  const [index, setIndex] = React.useState(0);
  const [counter, setCounter] = React.useState(0);
  const [rating, setRating] = React.useState();
  const [colorItemIndex, setcolorItemIndex] = React.useState(0);
  const [showMorecolours, setShowMorecolours] = React.useState(false);
  const [caartid,setCarrtid]=React.useState(null);

  React.useEffect(()=>{
    const funcall= async () =>{
      const response = await cartapi.UpdateProd(
        caartid,
        null,
        counter,
        1
      );

      if (response.data.error && response.data.error !== '') {
        notify('error', response.data.error);
      }
      else {
        notify("success",`${counter} items added in your cart`);  
      }
    }

    let ab1 = setTimeout(() => {
      if(counter>1){
        funcall();
      }
    }, 700);

    return ()=>{
      if(ab1){
        clearTimeout(ab1);
      }
    }
  },[counter])

console.log(productId,serviceId,useParams());
  const toggleShowMore = () => {
    setShowMorecolours(!showMorecolours);
  };

  React.useEffect(()=>{
    console.log("working detail2");
//     const storedProductData = localStorage.getItem("productDetail");
//     console.log(storedProductData, "productdetail");
//     if (storedProductData) {
//       console.log("working detail");
//       const [productid,service_id] = storedProductData.split(",");
// fetchProductDetail(parseInt(productid),service_id);
//     }
fetchProductDetail(parseInt(productId),serviceId);// using same for service provider and putaote products
  },[productId]);




  const showColorInfo =
  productDetail &&
  productDetail.product_details && productDetail.product_details[0].qty_list[quantityItemIndex].color && productDetail.product_details[0].qty_list[quantityItemIndex].color.length > 0 &&
    Object.keys(productDetail.product_details[0].qty_list[quantityItemIndex].color[colorItemIndex]).length > 0;

  React.useEffect(() => {
    if(productDetail && productDetail.product_details[0]){
      let arr = [];
      for (let i = 0; i < productDetail.product_details[0].qty_list.length; i++) {
        arr.push(<option value={i}>{productDetail.product_details[0].qty_list[i].quantity}</option>);
      }
      setOptions(arr);
    }

  }, [productDetail]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const hide = () => {
    setProductDetail();
  };
  // console.log(productDetail)

  React.useEffect(() => {
    if(productDetail && productDetail.product_details[0]){
    let arr = [];
    console.log(productDetail);
    let i = 1;
    for (; i <= productDetail.ratings; i++) {
      arr.push(
        <img className='service-view-card-star' src={starf} alt=''></img>
      );
    }
    for (i = productDetail.ratings || 1; i <= 5; i++) {
      arr.push(
        <img className='service-view-card-star' src={star} alt=''></img>
      );
    }
    setRating(arr);
  }
  }, [productDetail]);

  const reduce = () => {
    if (counter - 1 > -1) {
      setCounter(counter - 1);
    }
  }
  const increase = () => {
    setCounter(counter + 1);
  };
  console.log(showColorInfo);




  let tempIndex = index;

  const changeIndex=(e)=>
  {
    console.log(e.target.name)
    setIndex(e.target.name)
  }




//for single page details api (product details page)
const fetchProductDetail = async (productid, serviceid) => {
setLoading(true);
  const response = await apiclient.ProductDetails(-parseInt(productid), parseInt(serviceid));
  console.log(response);

  if (response.data.error && response.data.error !== '') {
    notify('error', response.data.error);
  }
  else {

    setProductDetail(response.data);
    setLoading(false)
    console.log(productDetail);

   
  }
};

  const addCart = async () => {
    // console.log(productDetail)
    if (showColorInfo ? !productDetail.product_details[0].qty_list[quantityItemIndex].color[colorItemIndex].out_of_stock:!productDetail.product_details[0].qty_list[quantityItemIndex].out_of_stock) {
      const response = await apiclient.AddToCart(
        productDetail.product_details[0].product_id,
        productDetail.product_details[0].qty_list[quantityItemIndex].quantity_id,
        2,
        counter,
        productDetail.product_details[0].service_id,
        -productDetail.product_details[0].qty_list[quantityItemIndex].id,
        productDetail.product_details[0].qty_list[quantityItemIndex].color && productDetail.product_details[0].qty_list[quantityItemIndex].color.length > 0 ? productDetail.product_details[0].qty_list[quantityItemIndex].color[colorItemIndex].color_id : null
      );
      console.log(response);
      if (response.data.error !== '') {
        notify('error', response.data.error);
      } else {
        notify('success', `1 item added to cart`);
        setCounter(1);
        setCarrtid(response.data.cart_id);
      }
      setChangeInCart(!changeInCart);

    } else {
      notify('error', 'Item is out of stock');
    }
  }



  function handleChange(event) {
    console.log(event);
    const { value } = event;
    fetchProductDetail(value)
    // fetchProductDetail();
  }

  const handleChangeQty = (e) => {
    console.log(e.target.value);
    setQuantityItemIndex(e.target.value);
    setcolorItemIndex(0);
    setIndex(0);
  };

  const handleChangeColor = (index) => {
    console.log(index);
    setcolorItemIndex(index);
    setIndex(0);

  };




  function getBaseUrl() {
    // Check if the application is running on localhost
    if (window.location.hostname === "localhost" && window.location.port === "3000") {
      // Running on localhost:3000
      return "http://localhost:3000";
    } else if (window.location.hostname === "putatoetest.web.app") {
      return "https://putatoetest.web.app";
    }
    else {
      return "https://www.putatoe.com"
    }
  }

  // Get the base URL
  const baseUrl = getBaseUrl();

  const shareOption = async() => {
    const { id
       } =productDetail.product_details[0];

    if (id ) {
      try {
        if (navigator.share) {
          console.log('Web Share API is supported');
          await navigator.share({
            title: 'Share Link',
            text: 'Check out this'+productDetail.product_details[0].name+'!' ,
            url: `${baseUrl}/product/${productDetail.product_details[0].id}/${productDetail.product_details[0].name}/${productDetail.product_details[0].service_id}`
          });
          
          notify("success", "Link shared successfully");
        } else {
          console.log('Web Share API is not supported');
          // Fallback for browsers that do not support Web Share API
          const link = `${baseUrl}/product/${productDetail.product_details[0].id}/${productDetail.product_details[0].name}/${productDetail.product_details[0].service_id}`;
          const tempInput = document.createElement("input");
          tempInput.value = link;
          document.body.appendChild(tempInput);
          tempInput.select();
          document.execCommand("copy");
          document.body.removeChild(tempInput);
          notify("success", "Link copied successfully");
        }
      } catch (error) {
        console.error('Error sharing link:', error);
        notify("error", "Error sharing link");
      }
    }
  }


  return (
    <React.Fragment>
      {!loading && productDetail && productDetail.product_details[0] ? (
        <>
       
          <div className='service-card-product-info-container'>
            <div className="back-container-single-page">
          <IoMdArrowBack  className='back-container-single-page-button' onClick={handleBackButtonClick}/></div>
            <div className='service-card-product-info-container-top'>
              <div className='service-card-product-info-container-top-carousel'>
              {showColorInfo && productDetail.product_details[0].qty_list[quantityItemIndex].color && productDetail.product_details[0].qty_list[quantityItemIndex].color.length > 0 ? (
                  // Color information is present
                  <>
                    {productDetail.product_details[0].qty_list[quantityItemIndex].color[colorItemIndex].image.length === 1 ? (
                      // No images for the selected color
                      <div className='service-card-product-image-container imagecorrect'>  <img src={productDetail.product_details[0].qty_list[quantityItemIndex].color[colorItemIndex].image[0].image} alt="Product Image"></img></div>
                    ) : (
                      // Render images based on color information
                      <>
                                          <div className='service-card-product-image-container imagecorrect'>
                    <img src={productDetail.product_details[0].qty_list[quantityItemIndex].color[colorItemIndex].image[index].image} alt="Product Image"></img>
        </div>
                                  <div className='service-card-product-image-sec-container'>
            {productDetail.product_details[0].qty_list[quantityItemIndex].color[colorItemIndex].image.map((img, imgIndex) => (
              <div
                key={imgIndex}
                className="product-Image-Modal-image-sec"
                onClick={changeIndex}
              >
                <img src={img.image} name={imgIndex}   className={parseInt(index) === imgIndex ? 'bright-active-image' : ''} alt=''/>
              </div>
            ))}
          </div>
                      </>
                    )}
                  </>
                ) : (
                  // No color information or showColorInfo is false
                  <>
                    
                    <div className='service-card-product-image-container imagecorrect'>
                    <img src={productDetail.product_details[0].qty_list[quantityItemIndex].images[index]} alt="Product Image"></img>
        </div>
                    {productDetail.product_details[0].qty_list[quantityItemIndex].images.length > 1 && (
                      // Show scroll buttons if there is more than one image
                      <div className='service-card-product-image-sec-container'>
            {productDetail.product_details[0].qty_list[quantityItemIndex].images.map((img, imgIndex) => (
              <div
                key={imgIndex}
                className="product-Image-Modal-image-sec"
                onClick={changeIndex}
              >
                <img src={img} name={imgIndex}   className={parseInt(index) === imgIndex ? 'bright-active-image' : ''} alt=''/>
              </div>
            ))}
          </div>
                    )}
                  </>
                )}
              </div>
              <div className='service-card-product-info-container-top-prime'>
                <div className="service-provider-card-details-top service-provider-card-details-top-product-new-page ">
                  <div className="service-provider-card-details-brand-name-share-container">
                  <div className="service-provider-card-details-brand-name">
                    {productDetail.product_details[0].brand}
                  </div>
                  <div className="share-container">
      <div className="share-wrapper" onClick={shareOption}>
        <IoIosShareAlt className="share-icon" size={25} color="#c2c2c2" />
        <span className="share-label">Share</span>
      </div>
    </div>
                  </div>

                  <div className="service-provider-card-details-product-name">
                    {productDetail.product_details[0].name}
                  </div>
                  <div className="service-provider-card-details-product-type">
                    {productDetail.product_details[0].product_type}
                  </div>


                </div>
                <div className='service-card-product-info-container-top-prime-mrp-new'>
                  <div className='service-card-product-info-container-top-prime-price'>

                    <span>

                      &#8377; {showColorInfo
                        ? productDetail.product_details[0].qty_list[quantityItemIndex].color[colorItemIndex].selling_price : productDetail.product_details[0].qty_list[quantityItemIndex].selling_price}
                    </span>

                  </div>
                  <div>
                    <strike>M.R.P. &#8377;&nbsp;{showColorInfo
                      ? productDetail.product_details[0].qty_list[quantityItemIndex].color[colorItemIndex].actual_price : productDetail.product_details[0].qty_list[quantityItemIndex].actual_price}</strike>

                  </div>

                </div>


                <div className='service-card-product-info-container-top-prime-discount'>
                  You Save :&nbsp;{showColorInfo
                    ? productDetail.product_details[0].qty_list[quantityItemIndex].color[colorItemIndex].discount
                    : productDetail.product_details[0].qty_list[quantityItemIndex].discount}%
                </div>

                <div className="service-stock-color-container">
                {!showColorInfo 
  ? !productDetail.product_details[0].qty_list[quantityItemIndex].out_of_stock 
    ? <span>In Stock</span> 
    : <span>Out Of Stock</span>
  : !productDetail.product_details[0].qty_list[quantityItemIndex].color[colorItemIndex].out_of_stock 
    ? <span>In Stock</span> 
    : <span>Out Of Stock</span>
}
                </div>
                <div className='service-card-product-info-container-top-prime-rating'>
                  <div>
                    {productDetail.ratings ? (
                      rating
                    ) : (
                      <>
                        <div style={{ display: 'flex', gap: '0.2vw' }}>
                          <img
                            src={starNull}
                            className='service-view-card-star'
                          ></img>
                          <img
                            src={starNull}
                            className='service-view-card-star'
                          ></img>
                          <img
                            src={starNull}
                            className='service-view-card-star'
                          ></img>
                          <img
                            src={starNull}
                            className='service-view-card-star'
                          ></img>
                          <img
                            src={starNull}
                            className='service-view-card-star'
                          ></img>
                        </div>
                      </>
                    )}
                  </div>
                  {/* <span>{productDetail.product_details[0].qty_list[quantityItemIndex].rating}</span> */}
                </div>
                <div className="service-card-product-info-container-bottom-scontainer-details">


                  <div className='service-card-product-info-container-top-prime-quantity'>
                    <div className="service-unit-individual-qty"><span>
                      Unit: <span>{showColorInfo
                        ? productDetail.product_details[0].qty_list[quantityItemIndex].color[colorItemIndex].unit : productDetail.product_details[0].qty_list[quantityItemIndex].units}</span>      </span>
                      <div class="service-color-choices" onClick={toggleShowMore}>
                        {showColorInfo && productDetail.product_details[0].qty_list[quantityItemIndex].color && productDetail.product_details[0].qty_list[quantityItemIndex].color.length > 0 && (
                          <div className="initial-choice service-color-choice" style={{
                            backgroundColor:
                              productDetail.product_details[0].qty_list[quantityItemIndex].color[colorItemIndex].color_code,
                          }}
                          >
                          </div>
                        )}

                        {showMorecolours && productDetail.product_details[0].qty_list[quantityItemIndex].color.length > 1 && (
                          <div className="additional-choices-container">
                            {productDetail.product_details[0].qty_list[quantityItemIndex].color.map((color, index) => (
                              <div
                                className={`additional-choice service-color-choice ${colorItemIndex === index ? "initial-choice" : ""
                                  }`}

                                style={{ backgroundColor: color.color_code }}
                                onClick={() => handleChangeColor(index)}
                              >
                                {/* {color.color_name} */}
                              </div>
                            ))}

                          </div>
                          
                        )}
                        {showColorInfo && productDetail.product_details[0].qty_list[quantityItemIndex].color && productDetail.product_details[0].qty_list[quantityItemIndex].color.length > 1 && <span  onClick={toggleShowMore}>{"+"+ productDetail.product_details[0].qty_list[quantityItemIndex].color.length+ " " } <IoIosArrowDown size={16} fontWeight={600} /></span>}
                      </div>
                    </div>
                    <select
                      name='pieces'
                      onChange={handleChangeQty}
                    >
                      {options}
                    </select>

                  </div>
                  <div className={`service-card-product-info-container-top-prime-add-to-cart ${isMobileView && "justformooobille"}`}>
                   { counter!=0   && <div className='service-card-product-info-container-top-prime-quantity-counter'>
                      <span onClick={reduce}>-</span>
                      <div>{counter}</div>
                      <span onClick={increase}>+</span>
                    </div>
}

          {counter==0   && 
<div className='service-card-product-info-container-top-prime-quantity-cart'>
                    {showColorInfo  ? (
  !productDetail.product_details[0].qty_list[quantityItemIndex].color[colorItemIndex].out_of_stock ? (
    <button onClick={addCart}>
      <>
        <BsCartPlus className="service-provider-add-to-cart-image" />
        Add To Cart
      </>
    </button>
  ) : (
    <>
  
    <button> <BsCartPlus className="service-provider-add-to-cart-image" />Out of Stock</button>
    </>

  )
) : (
  !productDetail.product_details[0].qty_list[quantityItemIndex].out_of_stock ? (
    <button onClick={addCart}>
      <>
        <BsCartPlus className="service-provider-add-to-cart-image" />
        Add To Cart
      </>
    </button>
  ):
  (
    <>
  
    <button> <BsCartPlus className="service-provider-add-to-cart-image" />Out of Stock</button>
    </>

  )
)}
                    </div>}
                  </div>
                </div>
                {/* <div className='service-card-product-info-container-top-prime-variety'>
                 <Select
                   options={options}
                   placeholder={'Search For Similiar Product'}
                   onChange={handleChange}
                 ></Select>
               </div> */}
              </div>
            </div>
            <div className='service-card-product-info-container-bot'>
              <div className='service-card-product-info-container-bot-name'>
                About The Product
              </div>
              {productDetail.long_description ? (
                <>
                  {productDetail.long_description && <p>{productDetail.long_description}</p>}
                </>
              ) : (
                <p>No description available</p>
              )}
            </div>

            <div className='service-card-product-info-container-bot-similar-products'>
              {productDetail.similar_product.length > 0 &&
                <SimilarProductsContainer products={productDetail.similar_product} fetchProductDetail={fetchProductDetail} />
              }

            </div>
          </div>
        </>
      ):
      <>
      <div class="loading" id="loading" style={{ height: "100vh" }}>
        <div class="obj"></div>
        <div class="obj"></div>
        <div class="obj"></div>
        <div class="obj"></div>
        <div class="obj"></div>
        <div class="obj"></div>
        <div class="obj"></div>
        <div class="obj"></div>
        <div class="obje">LOADING MORE</div>
        <div class="obj"></div>
        <div class="obj"></div>
        <div class="obj"></div>
        <div class="obj"></div>
        <div class="obj"></div>
        <div class="obj"></div>
        <div class="obj"></div>
        <div class="obj"></div>
      </div>
      <div className="mobile-loader" style={{ height: "60vh" }}>
        <div className="loader"></div>
      </div>
    </>}
    </React.Fragment>
  );
}