import React, { Component } from "react";
import { useGlobalContext } from "../../context";
import myProfileServiceApi from "../../api/myProfileServiceApi";
import back from "../myprofileIcoons/bx-arrow-back..svg";
import notify from "../../error";
import prof from "../../images/logo.png";
import noshow from "../Myorders/noOrders.png";
import "./css/CompletedandpendingOnline.css";
import { motion } from "framer-motion";
import Loading from "../../components/Loading";
export default function CancelledAndCompletedOnline(props) {
  const { Userprofile, isMobileView, setInnerPage, loading, setLoading,userServiceIDselected } =
    useGlobalContext();
  const date = new Date();
  const date2 = new Date();

 
function subtract15Days(date2) {
  const newDate = new Date(date2);
  newDate.setDate(date2.getDate() - 15);
  return newDate;
}
const newDate = subtract15Days(date2);
  const [myOrders, setMyOrders] = React.useState({
    error: "",
    offline_order: [],
    ongoing_orders: [
      {
        address: {
          address_line1: "Harhwa Fatak",
          address_line2: "Nirala Nagar",
          country: "India",
          default_address: 1,
          district: "Gorakhpur",
          id: 198354,
          landmark: "",
          latitude: 26.7236567,
          longitude: 83.4341722,
          pincode: "273004",
          state: "Uttar Pradesh",
          town: "Gorakhpur",
        },
        amt: 10000,
        current_status: "Shipped",
        datetime: "Thu, 25 May 2023 08:37:22 GMT",
        email: "sanawarsaw@gmail.com",
        id: 265,
        items: [
          {
            brand: "Ambuja cement",
            name: "Ambuja cement",
            pcs: "2",
            price: 10000,
            qty: "500 kg",
            user_id: 71792,
          },
        ],
        name: "sana 55",
        order_id: "ORDER_ID_24036",
        phone: "+919951955433",
        username: "sana55",
      },
    ],
    pending_orders: [],
    status: true,
  });

  React.useEffect(() => {
    GetAllMyOrders();
  }, []);

  //date inputs using calander
  const [formData, setFormData] = React.useState({
    to: `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(date.getDate()).padStart(2, "0")}`,
    from: `${newDate.getFullYear()}-${String(newDate.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(newDate.getDate()).padStart(2, "0")}`,
    editFrom: `${newDate.getFullYear()}-${String(
      newDate.getMonth() + 1
    ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
  });

  //date manupulation
  //how to play with dates
  function handleChange(event) {
    console.log(event);
    const { name, value, type, checked } = event.target;
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: type === "checkbox" ? checked : value,
      };
    });
    if (name === "editFrom") {
      console.log("here", value);
      const newDate = new Date();
      let now = new Date();
      now = getLastWeeksDate();

      function getLastWeeksDate() {
        const now = new Date();

        return new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
      }

      function getLast30Date() {
        const now = new Date();

        return new Date(now.getFullYear(), now.getMonth(), now.getDate() - 30);
      }
      function getLastmonthDate() {
        const now = new Date();

        return new Date(now.getFullYear(), now.getMonth() - 1, now.getDate());
      }
      function getLast6monthDate() {
        const now = new Date();

        return new Date(now.getFullYear(), now.getMonth() - 6, now.getDate());
      }
      function get2022Date() {
        const now = new Date();

        return new Date(now.getFullYear() - 1, 0, 1);
      }
      function get2021Date() {
        const now = new Date();

        return new Date(now.getFullYear() - 2, 0, 1);
      }
      function get2020Date() {
        const now = new Date();

        return new Date(now.getFullYear() - 3, 0, 1);
      }
      function get2019Date() {
        const now = new Date();

        return new Date(now.getFullYear() - 4, 0, 1);
      }
      function get2018Date() {
        const now = new Date();

        return new Date(now.getFullYear() - 5, 0, 1);
      }
      function get2017Date() {
        const now = new Date();

        return new Date(now.getFullYear() - 6, 0, 1);
      }
      if (value === "1") {
        console.log("here 1");
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            from: `${newDate.getFullYear()}-${String(
              newDate.getMonth() + 1
            ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
            to: `${newDate.getFullYear()}-${String(
              newDate.getMonth() + 1
            ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
          };
        });
      }

      if (value === "2") {
        console.log("here 2");

        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
              2,
              "0"
            )}-${String(now.getDate()).padStart(2, "0")}`,
            to: `${newDate.getFullYear()}-${String(
              newDate.getMonth() + 1
            ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
          };
        });
      }

      if (value === "3") {
        console.log("here 3");
        now = getLast30Date();
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
              2,
              "0"
            )}-${String(now.getDate()).padStart(2, "0")}`,
            to: `${newDate.getFullYear()}-${String(
              newDate.getMonth() + 1
            ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
          };
        });
      }
      if (value === "4") {
        console.log("here 4");
        now = getLast6monthDate();
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
              2,
              "0"
            )}-${String(now.getDate()).padStart(2, "0")}`,
            to: `${newDate.getFullYear()}-${String(
              newDate.getMonth() + 1
            ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
          };
        });
      }
      if (value === "5") {
        console.log("here 5");
        now = get2022Date();
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
              2,
              "0"
            )}-${String(now.getDate()).padStart(2, "0")}`,
            to: `${newDate.getFullYear()}-${String(
              newDate.getMonth() + 1
            ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
          };
        });
      }
      if (value === "6") {
        // console.log('here 5');
        now = get2021Date();
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
              2,
              "0"
            )}-${String(now.getDate()).padStart(2, "0")}`,
            to: `${newDate.getFullYear()}-${String(
              newDate.getMonth() + 1
            ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
          };
        });
      }
      if (value === "7") {
        // console.log('here 5');
        now = get2020Date();
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
              2,
              "0"
            )}-${String(now.getDate()).padStart(2, "0")}`,
            to: `${newDate.getFullYear()}-${String(
              newDate.getMonth() + 1
            ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
          };
        });
      }
      if (value === "8") {
        // console.log('here 5');
        now = get2019Date();
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
              2,
              "0"
            )}-${String(now.getDate()).padStart(2, "0")}`,
            to: `${newDate.getFullYear()}-${String(
              newDate.getMonth() + 1
            ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
          };
        });
      }
      if (value === "9") {
        console.log("here 5");
        now = get2018Date();
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
              2,
              "0"
            )}-${String(now.getDate()).padStart(2, "0")}`,
            to: `${newDate.getFullYear()}-${String(
              newDate.getMonth() + 1
            ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
          };
        });
      }

      if (value === "10") {
        // console.log('here 5');
        now = get2017Date();
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            from: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
              2,
              "0"
            )}-${String(now.getDate()).padStart(2, "0")}`,
            to: `${newDate.getFullYear()}-${String(
              newDate.getMonth() + 1
            ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`,
          };
        });
      }
    }
    console.log(formData);
  }

  async function GetAllMyOrders() {
    try {
      setLoading(true);
      const selectedServiceID = userServiceIDselected ? userServiceIDselected[0] : null;
      const response = await myProfileServiceApi.CancelledAndCompletedOnline(
        Userprofile.service_provider_id,
        formData.from,
        formData.to,
        selectedServiceID
      );
      console.log(response);

      if (response.data && response.data.error != "") {
        notify("error", response.data.error);
      } else {
        console.log(response.data);
        setMyOrders(response.data);
        setLoading(false);
      }
    } catch (error) {
      notify("error", `Error fetching myOrders`);
    }
  }

  React.useEffect(() => {
    GetAllMyOrders();
  }, [formData.to, formData.from]);

  const makeload = () => {
    GetAllMyOrders();
  };

  const [myObjectKeyArray, setMyObjectKeyArray] = React.useState([]);

  const goBack = () => {
    props.return();
    setInnerPage(false);
  };

  const [page, setPage] = React.useState(1);
  const [billData, setBillData] = React.useState();

  const exitDetail = () => {
    setPage(1);
  };

  return (
    <React.Fragment>
      <>
        <div className="my-service-profile-ongoing-and-pending-online">
          {page !== 4 ? (
            <>
            
              <div className="my-online-ongoing-pending-profile-time-filter-calander-box">
              <div className="my-service-profile-ongoing-and-pending-online-back">
                <img src={back} onClick={goBack} alt=""/>
              </div>
                <div className="my-order-profile-time-filter-calander-1">
                  <span>From</span>
                  <input
                    type="date"
                    onChange={handleChange}
                    name="from"
                    value={formData.from}
                  ></input>
                </div>
                <div className="my-order-profile-time-filter-calander-1">
                  <span>Till</span>
                  <input
                    type="date"
                    onChange={handleChange}
                    name="to"
                    value={formData.to}
                  ></input>
                </div>
                <div className="my-order-profile-time-filter-calander-3">
                  <span>Sort By : </span>
                  <select onChange={handleChange} name="editFrom">
                    <option value={0}>Time Filter</option>
                    <option value={1}>Today</option>
                    <option value={2}>Last One Week</option>
                    <option value={3}>Last 30 Days</option>
                    <option value={4}>Last 6 Months</option>
                    <option value={5}>2022</option>
                    <option value={6}>2021</option>
                    <option value={7}>2020</option>
                    <option value={8}>2019</option>
                    <option value={9}>2018</option>
                    <option value={10}>2017</option>
                  </select>
                </div>
              </div>
              <div className="my-service-profile-ongoing-and-pending-online-selector">
                <div className="my-service-profile-ongoing-and-pending-online-selector-tab">
                  <motion.span
                    onClick={() => {
                      setPage(1);
                    }}
                    animate={{
                      color: page === 1 ? "black" : "rgba(0, 0, 0, 0.512)",
                      "border-bottom":
                        page === 1 ? "3px solid #1D8B97" : "none",
                    }}
                    whileHover={{ color: "black" }}
                  >
                    Completed Orders
                  </motion.span>
                  <motion.span
                    onClick={() => {
                      setPage(2);
                    }}
                    animate={{
                      color: page === 2 ? "black" : "rgba(0, 0, 0, 0.512)",
                      "border-bottom":
                        page === 2 ? "3px solid #1D8B97" : "none",
                    }}
                    whileHover={{ color: "black" }}
                  >
                    Cancelled Orders
                  </motion.span>
                </div>
                {page === 1 ? (
                  <>
                    <div className="my-service-profile-ongoing-and-pending-online-box">
                      {myOrders &&
                        (!loading ? (
                          myOrders.completed_orders &&
                          myOrders.completed_orders.length > 0 ? (
                            myOrders.completed_orders.map((item) => {
                              return (
                                <>
                                  {!isMobileView ? (
                                    <div className="my-bills-profile-page-view-main-right-main-box-card">
                                      {/* <div className='my-bills-profile-page-view-main-right-main-box-card-img'>
                                  <img></img>
                                </div> */}
                                      <div className="my-bills-profile-page-view-main-right-main-box-card-provider">
                                        {item.name}
                                      </div>
                                      <div className="my-bills-profile-page-view-main-right-main-box-card-des">
                                        <span>Order Id : {item.order_id}</span>
                                        <span>
                                          Order Date :{" "}
                                          {item.datetime.slice(5, 16)}
                                        </span>
                                        <span>Mobile : {item.phone}</span>
                                      </div>
                                      <div className="my-bills-profile-page-view-main-right-main-box-card-time">
                                        <span>
                                          Order type : {item.current_status}
                                        </span>
                                        {/* <button>Complete Order</button> */}
                                      </div>
                                      <div className="my-bills-profile-page-view-main-right-main-box-card-des">
                                        {`${item.address.address_line1} , ${item.address.district} , ${item.address.town}, ${item.address.state}`}
                                      </div>
                                      <div className="my-bills-profile-page-view-main-right-main-box-card-price">
                                        <span>&#8377;{item.amt}</span>
                                        <div className="buttons-container">
                                          <button
                                            onClick={() => {
                                              setPage(4);
                                              setBillData(item);
                                            }}
                                          >
                                            View Items
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="my-bills-profile-page-view-main-right-main-box-card-mobileview">
                                      {/* <div className='my-bills-profile-page-view-main-right-main-box-card-img'>
                                <img></img>
                              </div> */}
                                      <div className="my-bills-profile-page-view-main-right-main-box-card-provider">
                                        <span
                                          style={{
                                            fontWeight: "normal",
                                            color: "rgba(0, 0, 0, 0.476)",
                                          }}
                                        >
                                          User Name:
                                        </span>
                                        {" " + item.name}
                                      </div>
                                      <div className="my-bills-profile-page-view-main-right-main-box-card-des">
                                        <span>Order Id : {item.order_id}</span>
                                        <span>
                                          Order Date :{" "}
                                          {item.datetime.slice(5, 16)}
                                        </span>
                                        <span>Mobile : {item.phone}</span>
                                      </div>
                                      <div className="my-bills-profile-page-view-main-right-main-box-card-time">
                                        <span>
                                          <span
                                            style={{
                                              fontWeight: "normal",
                                              color: "rgba(0, 0, 0, 0.476)",
                                            }}
                                          >
                                            Order type :
                                          </span>{" "}
                                          {item.current_status}
                                        </span>
                                        {/* <button>Complete Order</button> */}
                                      </div>
                                      <div className="my-bills-profile-page-view-main-right-main-box-card-des">
                                        Address :{" "}
                                        {`${item.address.address_line1} , ${item.address.district} , ${item.address.town}, ${item.address.state}`}
                                      </div>
                                      <div className="my-bills-profile-page-view-main-right-main-box-card-price">
                                        <div>
                                          {" "}
                                          <span
                                            style={{
                                              fontWeight: "normal",
                                              color: "rgba(0, 0, 0, 0.476)",
                                            }}
                                          >
                                            Amount:
                                          </span>{" "}
                                          &#8377;{item.amt}
                                        </div>
                                        <div className="buttons-container">
                                          <button
                                            onClick={() => {
                                              setPage(4);
                                              setBillData(item);
                                            }}
                                          >
                                            View Items
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </>
                              );
                            })
                          ) : (
                            <div className="my-order-profile-order-box-container-no-order">
                              <img src={noshow}></img>
                            </div>
                          )
                        ) : (
                          <>
                            <div style={{ height: "50vh" }}>
                              {" "}
                              <Loading />
                            </div>
                          </>
                        ))}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="my-service-profile-ongoing-and-pending-online-box">
                      {myOrders &&
                        (!loading ? (
                          myOrders.cancelled_orders &&
                          myOrders.cancelled_orders.length > 0 ? (
                            myOrders.cancelled_orders.map((item) => {
                              return (
                                <>
                                  {!isMobileView ? (
                                    <div className="my-bills-profile-page-view-main-right-main-box-card">
                                      <div className="my-bills-profile-page-view-main-right-main-box-card-provider">
                                        {item.name}
                                      </div>

                                      <div className="my-bills-profile-page-view-main-right-main-box-card-des">
                                        <span>Order Id : {item.order_id}</span>
                                        <span>
                                          Order Date :{" "}
                                          {item.datetime.slice(5, 16)}
                                        </span>
                                        <span>Mobile : {item.phone}</span>
                                      </div>
                                      <div className="my-bills-profile-page-view-main-right-main-box-card-time">
                                        <span>
                                          Order type : {item.current_status}
                                        </span>
                                        {/* <button>Complete Order</button> */}
                                      </div>
                                      <div className="my-bills-profile-page-view-main-right-main-box-card-des">
                                        {`${item.address.address_line1} , ${item.address.district} , ${item.address.town}, ${item.address.state}`}
                                      </div>
                                      <div className="my-bills-profile-page-view-main-right-main-box-card-price">
                                        <span>&#8377;{item.amt}</span>
                                        <div className="buttons-container">
                                          <button
                                            onClick={() => {
                                              setPage(4);
                                              setBillData(item);
                                            }}
                                          >
                                            View Items
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="my-bills-profile-page-view-main-right-main-box-card-mobileview">
                                      <div className="my-bills-profile-page-view-main-right-main-box-card-provider">
                                        <span
                                          style={{
                                            fontWeight: "normal",
                                            color: "rgba(0, 0, 0, 0.476)",
                                          }}
                                        >
                                          User Name:
                                        </span>
                                        {" " + item.name}
                                      </div>

                                      <div className="my-bills-profile-page-view-main-right-main-box-card-des">
                                        <span>Order Id : {item.order_id}</span>
                                        <span>
                                          Order Date :{" "}
                                          {item.datetime.slice(5, 16)}
                                        </span>
                                        <span>Mobile : {item.phone}</span>
                                      </div>

                                      <div className="my-bills-profile-page-view-main-right-main-box-card-time">
                                        <span>
                                          <span
                                            style={{
                                              fontWeight: "normal",
                                              color: "rgba(0, 0, 0, 0.476)",
                                            }}
                                          >
                                            Order type :
                                          </span>{" "}
                                          {item.current_status}
                                        </span>
                                      </div>
                                      <div className="my-bills-profile-page-view-main-right-main-box-card-des">
                                        Address :{" "}
                                        {`${item.address.address_line1} , ${item.address.district} , ${item.address.town}, ${item.address.state}`}
                                      </div>
                                      <div className="my-bills-profile-page-view-main-right-main-box-card-price">
                                        <div>
                                          {" "}
                                          <span
                                            style={{
                                              fontWeight: "normal",
                                              color: "rgba(0, 0, 0, 0.476)",
                                            }}
                                          >
                                            Amount:
                                          </span>{" "}
                                          &#8377;{item.amt}
                                        </div>
                                        <div className="buttons-container">
                                          <button
                                            onClick={() => {
                                              setPage(4);
                                              setBillData(item);
                                            }}
                                          >
                                            View Items
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </>
                              );
                            })
                          ) : (
                            <div className="my-order-profile-order-box-container-no-order">
                              <img src={noshow}></img>
                            </div>
                          )
                        ) : (
                          <>
                            <div style={{ height: "50vh" }}>
                              {" "}
                              <Loading />
                            </div>
                          </>
                        ))}
                    </div>
                  </>
                )}
              </div>
            </>
          ) : (
            <div className="my-bills-profile-page-view-main-right">
              <BillDetailsView
                data={billData}
                return={exitDetail}
                isMobileView={isMobileView}
              ></BillDetailsView>
            </div>
          )}
        </div>
      </>
    </React.Fragment>
  );
}

function BillDetailsView(props) {
  return (
    <React.Fragment>
      <>
        <div className="my-bills-profile-page-view-main-right-data">
          {/* <div className='my-service-profile-ongoing-and-pending-online-back'></div> */}
          <div className="my-bills-profile-page-view-main-right-heading-data">
            <img src={back} onClick={props.return}></img>
            <span>View Details</span>
          </div>
          <div className="my-bills-profile-page-view-main-right-data-box">
            {props &&
              props.data &&
              props.data.items &&
              props.data.items.length > 0 &&
              props.data.items.map((item) => {
                return (
                  <div className="my-bills-profile-page-view-main-right-data-box-card">
                    <div className="my-bills-profile-page-view-main-right-data-box-card-img">
                    {item.color && item.color.length > 0 ? (
            // If color array is not empty, use the color and image from it


              <img src={item.color[0].images[0]}></img>
       
          ) :     item.image ? (
            <img src={item.image}></img>
          ) : (
            <img src={prof}></img>
          )}
                  
                    </div>
                    <div className="my-bills-profile-page-view-main-right-data-box-card-des">
                      <span>{item.brand}</span>
                      <span>{item.name}</span>
          
                      {!props.isMobileView && (   <span>{item.qty}</span>)}
                       {/* Show qty, unit, and color in mobile view */}
 {props.isMobileView && (
 <div className='my-bills-profile-page-view-main-right-data-box-card-mobile-info'>
  <span>{item.qty}</span> 
      <span> | {item.pcs} Units</span>
      {item.color && item.color.length > 0 && (
        <div className='my-bills-profile-page-view-main-right-data-box-card-color'>
         | <div
            className="initial-choice service-color-choice"
            style={{
              backgroundColor: item.color[0].color_code,
            }}
          >
          </div>{" "}
          {item.color[0].color_name}
        </div>
      )}
    </div>)}
                    </div>
                        {!props.isMobileView && item.color && item.color.length > 0 && <div className='my-bills-profile-page-view-main-right-data-box-card-color'>
                    <div className="initial-choice service-color-choice" style={{
                  backgroundColor:
                    item.color[0].color_code,
                }}
                >
                </div> {item.color[0].color_name}
                    </div>}
                    {!props.isMobileView &&  <div className="my-bills-profile-page-view-main-right-data-box-card-qty">
                      Units : {item.pcs}
                    </div>}
                    <div className="my-bills-profile-page-view-main-right-data-box-card-price">
                      <span>&#8377;{item.price}</span>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </>
    </React.Fragment>
  );
}
