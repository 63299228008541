import { IoCloseCircleSharp } from "react-icons/io5";
import { useGlobalContext } from "../../context";
import { useEffect, useState } from "react";

export default function AllProductsEditPriceModal() {
  const { inputPrice, setInputprice,setModifyModal, } = useGlobalContext();



  const submitBtnHandler = () => {
    setModifyModal(false);
  };

  return (
    <section className="AllProducts-overlay">
      <div className="edit-price-container">
        <IoCloseCircleSharp
          className="AllProducts-crossIcon"
          onClick={() => setModifyModal(false)}
        />

        <div className="edit-price-heading">Updated Price</div>
        <div className="price-container">
          <input
            type="number"
            className="edit-price-input"
            value={inputPrice}
            onChange={(e) => setInputprice(e.target.value)}
          />
          <p> &#8377; Final Price</p>
        </div>
        
        <button
          type="submit"
          className="edit-price-submit-button"
          onClick={submitBtnHandler}
        >
          Submit
        </button>
      </div>
    </section>
  );
}
