import React, { Component } from 'react';
import '../serviceprovider-css/advertisment.css';
import starf from '../images/Starf.svg';
import star from '../images/Star.svg';
import photo from '../images/advertisment-image.svg';
import location from '../images/ad-location.svg'
import {BsShareFill} from "react-icons/bs"

import { useGlobalContext } from '../context';
import car from '../images/ad-car.svg'
import store from '../images/ad-store.svg'
import phone from '../images/ad-call.svg'

import chat from '../images/comment-multiple-svgrepo-com.svg';
import apiclient from '../api/apiclient';
import notify from '../error';
import { Link, useNavigate } from 'react-router-dom';
import chatapi from '../api/chatapi';

export default function UserProfileOther(props) {
 console.log(props)
  const [rating,setRating]=React.useState()
  const { Userprofile, loginPage,
    setLoginPage, } = useGlobalContext();
  const [display, setDisplay] = React.useState("login-sidebar-container-none");




  
  React.useEffect(()=>
  {let arr=[]
    let i=1
    for (; i <= props.data.serviceprovider_rating; i++) {
      arr.push(
        <img className='service-view-card-star' src={starf} alt=''></img>
      );
    }
    for(;i<=5;i++)
    {
      arr.push(
        <img className='service-view-card-star' src={star} alt=''></img>
      );
    }
    setRating(arr)
  },[])

  const AddToMyFollowList=async()=>
  {
    if(!Userprofile){
      setDisplay("login-sidebar-container2");
      setLoginPage(1);
      notify("error","Login First To Follow");
      return;
    }
    const response=await apiclient.AddServiceProviderToFav(props.providerId)
    console.log(response);
    if(response.data.error!=='')
    {
      notify('error',response.data.error);
    }
    else{
      notify(
        'success',
        `successfuly followed ${props.data.serviceprovider_name}`
      );
    }
  }

  const navigate = useNavigate();

  const chatwithprovider=async()=>
  {
    const response = await chatapi.ConnectToANewServiceProvider(props.providerId)
    console.log(response)
    if(response.data.error!=='')
    {
      notify('error',response.data.error)
    }
    else{
      navigate('/chat/seller')
    }
  }

  


  return (
    <React.Fragment>
      <div className='service-provider-advertisement'>
        <div className='service-provider-advertisement-image-container'>
          <div className='service-provider-advertisement-image'>
            <img src={props.data.image}></img>
          </div>
          <div className='service-provider-advertisement-rating-container'>
            {rating}
            <div className='service-provider-advertisement-rating-text'>
              {props.data.serviceprovider_rating}
            </div>
          </div>
          <div className='service-provider-advertisement-number-rating'>
            (1,750)
          </div>
        </div>

        <div className='service-provider-advertisement-info-container'>
          <div className='service-provider-advertisement-name-container'>
            <div className='service-provider-advertisement-name'>
              {props.data.serviceprovider_name}
            </div>
            <div className='service-provider-advertisement-time'>
              Opens At : 8:00 Am -10 : 00 Pm
            </div>
          </div>

          <div className='service-provider-advertisement-follow-container'>
            <button
              className='service-provider-advertisement-follow'
              onClick={AddToMyFollowList}
            >
              <div className='service-provider-advertisement-follow-text'>
                Follow
              </div>
            </button>
          </div>

          <div className='service-provider-advertisement-popularity-container'>
            <div className='service-provider-advertisement-follower-container'>
              <div className='service-provider-advertisement-follower-number'>
                1000
              </div>
              <div className='service-provider-advertisement-follower-text'>
                Follower
              </div>
            </div>
            <div className='service-provider-advertisement-break-container'></div>
            <div className='service-provider-advertisement-following-container'>
              <div className='service-provider-advertisement-follower-number'>
                20
              </div>
              <div className='service-provider-advertisement-follower-text'>
                Following
              </div>
            </div>
            <div className='service-provider-advertisement-break-container'></div>
          </div>

          <div className='service-provider-advertisement-details-container'>
            <div className='service-provider-advertisement-location-container'>
              <div className='service-provider-advertisement-detail-image-container'>
                <img
                  src={location}
                  className='service-provider-advertisement-detail-image'
                ></img>
              </div>
              <div className='service-provider-advertisement-detail-text'>
                {props.data.address_line1}
              </div>
            </div>
            <div className='service-provider-advertisement-delivery-container'>
              <div className='service-provider-advertisement-detail-image-container'>
                <img
                  src={car}
                  className='service-provider-advertisement-detail-image'
                ></img>
              </div>
             
            </div>
           
          </div>

          <div className='service-provider-advertisement-other-container'>
            <div className='service-provider-advertisement-chat'>
              <img src={chat} onClick={chatwithprovider}></img>
              
            </div>
            <div className='service-provider-advertisement-phone'>
              <img src={phone}></img>
            </div>
        
          
            <div className='service-provider-advertisement-phone'>
            <BsShareFill size={24} color='#fff'  />
            </div>
         
          </div>
       
        </div>
        
      </div>
    
      
    </React.Fragment>
    
  );
}
