import React, { Component } from 'react'
import '../PutatoeService-Css/PutatoeServiceOptionCards.css';
import { useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../context'; 
export default function PutatoeServiceOptionCards(props)
{
  console.log(props)
  const navigate = useNavigate();
  function move() {
    navigate(`/Sub_serviceProduct/${props.data[0].id}/${props.data[0].sub_service}`);
  }

  const { userName, setUserName } = useGlobalContext();
  // const name = () => {
  //   console.log([props.user, props.id, props.parent_id]);
  //   setUserName([props.user, props.id, props.parent_id]);
  //   console.log(userName);
  // };
  // console.log(props)
 return (
   <React.Fragment>
     {/* <div className='PutatoeServiceOptionCards-container' onClick={move}>{props.data[0].sub_service}{props.data[0].id}{}</div> */}
     <div className='PutatoeServiceOptionCards-container-desktop' onClick={move}>
        <div className='PutatoeServiceOptionCards-desktop-text'>
          <h1 className='PutatoeServiceOptionCards-heading-desktop'>{props.data[0].sub_service}</h1>
          <div className='PutatoeServiceOptionCards-heading-list'>
            {props.data &&
              props.data.slice(1).map((item) => {
                // const name = () => {
                //   console.log([item.name, item.id, parent_id]);
                //   setUserName([props.user, props.id, props.parent_id]);
                //   console.log(userName);
                // };
                return <a className='PutatoeServiceOptionCards-list' >{item.name}</a>;
              })}
          </div>
        </div>
        <div className='PutatoeServiceOptionCards-image-container'>
          <img className='PutatoeServiceOptionCards-image' src={props.data[0].logo} alt=''></img>
        </div>
      </div>
   </React.Fragment>
 );
}

{/* <div className='allservicetab-container-desktop' onClick={move}>
  <div className='allservicetab-desktop-text'>
    <h1 className='allservicetab-heading-desktop'>{props.name}</h1>
    <div className='allservicetab-heading-list'>
      {props.subcategory &&
        props.subcategory.map((item) => {
          return <a className='allservicetab-list'>{item.name}</a>;
        })}
    </div>
  </div>
  <div className='allservicetab-image-container'>
    <img className='allservicetab-image' src={props.logo} alt=''></img>
  </div>
</div>; */}