import React from 'react';
import SimilarProductCard from './similarProductCard';
import '../css/popularProductsSubContainer.css';
import '../css/carousel.css';
import scrollright from '../images/scrollright.svg'
import scrollleft from '../images/scrollleft.svg';

export default function SimilarProductsContainer(props) {
  console.log(props)



    //  console.log(props.popularproduct.length);
    const [isHovered, setIsHovered] = React.useState(false);
  
    const wrapperRef = React.useRef(null);
    const scrollLeft = (e) => {
      // console.log(e)
      // console.log(wrapperRef.current.offsetWidth)
      // console.log(props.products.length * 200)
      let x = wrapperRef.current.scrollLeft;
      let y = wrapperRef.current.scrollTop;
      // console.log(x)
      wrapperRef.current.scrollLeft += 800;
    }
    const scrollRight = (e) => {
      // console.log(e);
      // console.log(wrapperRef.current.offsetWidth);
      // console.log(props.products.length * 200);
      let x = wrapperRef.current.scrollLeft;
      let y = wrapperRef.current.scrollTop;
      wrapperRef.current.scrollLeft -= 800;
    }
 

 

  return (
    <React.Fragment>
             <div className='popular-product-category similar-product-heading' >
            Other Similar Products
        </div>
   <div className={`popular-products-per-category-head  ${isHovered ? 'hovered' : ''}`}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          style={{ marginBottom: "10px"}}
        >
          <div className='carousel-scroll'>
            <button
              className='carousel-scroll-left'
              onClick={scrollLeft}
            >
              <img src={scrollleft}></img>
            </button>

            <button
              className='carousel-scroll-right'
              onClick={scrollRight}
            >
              <img src={scrollright}></img>
            </button>
          </div>
          
      <div className='popular-product-container' style={{width:"100%"}}>
 
          <div className={`popular-products-per-category-head `}
         
            style={{boxShadow:"none"}}>
           
            <div className='popular-products-per-category similar-product-container-new' ref={wrapperRef}>
              {props.products.map((product) => (
                
                <SimilarProductCard
                key={product.product_id}
                  name={product.name }
                  brand={product.brand}
                  logo={product.product_img}
                  product_type_name={product.product_type}
                  product_type={product.producttype_id}
                   serviceproductid={product.id}
                  service_id={product.service_id}
                  sub_service_id={product.sub_service_id}
          fetchproductdetail={props.fetchProductDetail}
          
                  qty_list={product.qty_list}
                type_id2={
                  product.producttype_id ? product.producttype_id : -1
                }
                >
                    
                </SimilarProductCard>

              ))} 








              {/* <button className='popular-products-scroll-right' onClick={scrollRight}>&#60;</button> */}
            </div>
          </div>
          </div>
  
  </div>

    </React.Fragment>
  );
}






