import React, { Component } from "react";
import "./contactus.css";
import contactImage from "./images/contact.png";
import apiclient from "../api/apiclient";
import notify from "../error";
import Footer from "../components/footer";
export default function ContactUs() {
  function mob_validate(str) {
    const regexExp = /^[6789]\d{9}$/;
    if (str.length < 6) {
      notify("error", "Enter 10 digit numbers");
      return false;
    }
    if (
      regexExp.test(str) == false &&
      (str.length == 9 || str.length == 8 || str.length == 7 || str.length == 6)
    ) {
      notify("error", "Invalid mobile number");
      return false;
    }
    if (str.length > 10) {
      notify("error", "Enter 10 digit numbers");
      return false;
    }
    if (regexExp.test(str) == true && str.length == 10) {
      return true;
    }
    return regexExp.test(str);
  }

  function email_validate(email) {
    var validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (email.match(validRegex)) {
      return true;
    }
    notify("error", "Invalid email address!");
    console.log("ema");
    return false;
  }

  const [formData, setFormData] = React.useState({
    name: "",
    email: "",
    mobile: "",
    message: "",
  });

  const onSubmitCLick = async () => {
    if (!mob_validate(formData.mobile)) {
      return;
    }
    if (!email_validate(formData.email)) {
      return;
    }
    if(formData.name === '' || formData.email === '' || formData.message === ''){
      notify("error", "Please fill the fields first");
      return;
    }
    const response = await apiclient.ContactUs(
      formData.name.replace(/[^a-z0-9\s]/gi, "").substring(0, 50),
      formData.email,
      formData.mobile,
      formData.message
    );
    console.log(response);
    if (response.data.error !== "") {
      notify("error", response.data.error);
    } else {
      setFormData({
        name: "",
        email: "",
        mobile: "",
        message: "",
      });
      notify("success", "message delivered successfully");
    }
  };

  function handleChange(event) {
    console.log(event);
    const { name, value, type, checked } = event.target;
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: type === "checkbox" ? checked : value,
      };
    });
    console.log(formData);
  }

  return (
    <React.Fragment>
      <>
        <div className="contact-us-main-page">
          <div className="contact-us-main-page-top">
            <div className="contact-us-main-page-img">
              <img src={contactImage}></img>
            </div>
            <div className="contact-us-main-page-form">
              <div className="add-address-modal-coupon-enter-input">
                <span>
                  FULL NAME <span style={{ color: "red" }}>*</span>
                </span>
                <input
                  type="text"
                  value={formData.name}
                  name="name"
                  onChange={handleChange}
                  placeholder="Name"
                  required
                ></input>
              </div>

              <div className="add-address-modal-coupon-enter-input">
                <span>
                  Email Address
                  <span style={{ color: "red" }}>*</span>
                </span>{" "}
                <input
                  type="email"
                  value={formData.email}
                  name="email"
                  onChange={handleChange}
                  placeholder="Email"
                  required
                ></input>
              </div>

              <div className="add-address-modal-coupon-enter-input">
                <span>
                  Phone Number
                  <span style={{ color: "red" }}>*</span>
                </span>{" "}
                <input
                  type="number"
                  value={formData.mobile}
                  name="mobile"
                  onChange={handleChange}
                  placeholder="Enter 10-Digit Mobile Number"
                  maxLength="10"
                  required
                ></input>
              </div>

              <div className="add-address-modal-coupon-enter-input">
                <span>
                  Message <span style={{ color: "red" }}>*</span>
                </span>
                <textarea
                  type="text"
                  value={formData.message}
                  name="message"
                  onChange={handleChange}
                  placeholder="Enter Your Message"
                  required
                  maxLength="5000"
                ></textarea>
              </div>

              <section className="contact-us-main-page-submit">
                <button onClick={onSubmitCLick}>Submit</button>
              </section>
            </div>
          </div>
          <div className="contact-us-main-page-bottom">
            <div className="contact-us-main-page-bottom-box">
              <div className="contact-us-main-page-bottom-box-image">
                <img src="https://www.wrappixel.com/demos/ui-kit/wrapkit/assets/images/contact/icon1.png"></img>
              </div>
              <div className="contact-us-main-page-bottom-box-text">
                Address <br></br>Putatoe Office , 233 ,Harahawa, Phatak, Near
                SBI Bank, Gorakhpur, Uttar Pradesh - 273004
              </div>
            </div>

            <div className="contact-us-main-page-bottom-box">
              <div className="contact-us-main-page-bottom-box-image">
                <img src="https://www.wrappixel.com/demos/ui-kit/wrapkit/assets/images/contact/icon2.png"></img>
              </div>
              <div className="contact-us-main-page-bottom-box-text">
                Phone<br></br> 8095765454
              </div>
            </div>

            <div className="contact-us-main-page-bottom-box">
              <div className="contact-us-main-page-bottom-box-image">
                <img src="https://www.wrappixel.com/demos/ui-kit/wrapkit/assets/images/contact/icon3.png"></img>
              </div>
              <div className="contact-us-main-page-bottom-box-text">
                Email<br></br> putatoetechnologies@gmail.com
              </div>
            </div>
          </div>
        </div>
      </>
    </React.Fragment>
  );
}
