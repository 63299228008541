import React, { Component, useEffect, useRef, useState } from "react";
import { useGlobalContext } from "../../context";
import myProfileServiceApi from "../../api/myProfileServiceApi";
import UserService from '../../api/api';
import back from "../myprofileIcoons/bx-arrow-back..svg";
import notify from "../../error";
import defaultImage from "../../images/imageNotFound.png";
import { BiSolidTrashAlt } from "react-icons/bi";
import "./css/myJobs.css";
import locationImage from "../../images/location-2-svgrepo-com.svg";
import profileapi from "../../api/profileapi";
import { motion } from "framer-motion";
import { allStates, getDistricts } from "../../stateDistrict";
import { IoCloseCircleSharp } from "react-icons/io5";
import Geocode from "react-geocode";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import postapi from "../../api/postapi";
const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
Geocode.setApiKey(googleMapsApiKey);
export default function MyBussinessAccount(props) {
  const { Userprofile, setInnerPage, updateIsUpperLevelProvider, fetchHomePageDataAll } = useGlobalContext();
  const [serviceProviderImage, setServiceProviderImage] = useState("");
  const [details, setDetails] = React.useState();
  const [isProcessingImage, setIsProcessingImage] = useState(false);
  const [isUpperLevelProviderChanged, setIsUpperLevelProviderChanged] = useState(false);
  const storedetailupdatestatus = React.useRef("set");
  const [imageBtn, setImageBtn] = useState(false);
  const [location, setLocation] = React.useState(null);
  const [usernamechange,setUsernamechange]=React.useState("")
  const [checkingusername,Setcheckingusername]=React.useState(false);
  const [locationname,setLocationname]=useState("");
  const [predictedaddress,setPredictedaddress]=useState([]);
  // const [selectedaddress,set]
  const [open,setOpen]=useState(false);
  const refforinput=useRef(null);

  // const [formData, setFormData] = React.useState({
  //   postion: '',
  //   salary: '',
  //   skill: '',
  //   desc: '',
  // });
  useEffect(()=>{
    window.addEventListener("click",((event)=>{
      // console.log(event.target,refforinput?.current,event.target != refforinput?.current,!refforinput?.current.contains(event.target),open)
      if(open && refforinput.current && event.target != refforinput.current && !refforinput.current.contains(event.target)){
        setOpen(false);
      }
    }));
  },[open,refforinput])
  useEffect(()=>{
    let timeout;
    if(usernamechange !== ""){
    timeout=  setTimeout(() => {
        checkUsernameAvailability(usernamechange)
      },700);
    }
    return (()=>{
clearTimeout(timeout);
    })
  },[usernamechange])
  
  useEffect(()=>{
    const searchQueryHandler = async (event) => {
      if (locationname.length > 0) {
          if (Userprofile && Userprofile.default_address) {
              const response = await UserService.addressPrediction(locationname, Userprofile.default_address.latitude, Userprofile.default_address.longitude);
              console.log(response);
              if (response.data.error === "") {
                  // setAddprediction(response.data.predictions);
                  setPredictedaddress(response.data.predictions);
                  setOpen(true);
              } else {
                  console.log(response);
                  console.log("Error occurred while fetching address predictions:", response.data.error);
              }
          }
          else {
              const response = await UserService.addressPrediction(locationname, null, null);
              console.log(response);
              
              if (response.data.error === "") {
                setPredictedaddress(response.data.predictions);
                setOpen(true);
              }
              console.log("error occured to change address");
          }

      }
  };
  let timeout = setTimeout(() => {
    searchQueryHandler();
  }, 700);

  return () => {
    if (timeout) {
      clearTimeout(timeout);
    }
  }; 
  },[locationname])
  const handleChange = (level) => (e) => {
    console.log(e,level);

    const { name, value, type, checked } = e.target;
    console.log(`Name: ${name}, Value: ${value}, Checked: ${e.target.checked}`);
    console.log(typeof value);
    if (name === "username") {
      // checkUsernameAvailability(value);
      Setcheckingusername(true);
      setUsernamechange(value);
      setHasInputChanged(true);
    }

    if (!level) {


      setDetails((prevFormData) => {
        return {
          ...prevFormData,
          [name]: type === "checkbox" ? checked : value,
        };
      });
      console.log(details, name, value);
    } else {

      setDetails({
        ...details,
        [level]: {
          ...details[level],
          [e.target.name]: e.target.value,
        },
      });
    }
  };

  function hasDigit(str) {
    return /\d/.test(str);
}
  const goBack = () => {
    props.return();
    setInnerPage(false);
  };
  const handleModalClose = () => {
    setEmployeemodal((prev) => !prev);
  };


  const [existingdetails, setExistingDetails] = React.useState();
  const [employeemodal, setEmployeemodal] = React.useState(false);
  const [employeedata, setEmployeedata] = React.useState("");
  const [usernameAvailable, setUsernameAvailable] = React.useState(false);
  const [hasInputChanged, setHasInputChanged] = React.useState(false);
  // const [storedetailupdatestatus,setStoredetailupdatestatus]=React.useState("unset");
  // Function to check username availability
  const checkUsernameAvailability = async (username) => {
    try {
      // Send a request to your API to check if the username is available
      const response = await profileapi.spcheckUsername(username);
      Setcheckingusername(false)
      if (response && response.data && response.data.error != "") {
        setUsernameAvailable(false);
      } else {
        setUsernameAvailable(true);
      }
    } catch (error) {
      console.error("Error checking username availability:", error);
    }
  };
  const GetMyDetails = async () => {
    const response = await myProfileServiceApi.SellerDetails();
    console.log(response);
    if (response.data && response.data.error !== "") {
      storedetailupdatestatus.current = "set";
      notify("error", "error occured");
    } else {
      setDetails(response.data);
      setExistingDetails(response.data)
      setDetails((prevFormData) => {
        return {
          ...prevFormData,
          mobile: prevFormData.mobile.substr(3),
          whatsappnumber: prevFormData.whatsappnumber
            ? prevFormData.whatsappnumber.substr(3)
            : prevFormData.whatsappnumber,
        };
      });
      setServiceProviderImage(response.data.image);
    }
    storedetailupdatestatus.current = "set";
  };
  const GetMyEmployeesdata = async () => {
    const response = await myProfileServiceApi.Showemployee(
      Userprofile.service_provider_id
    );
    console.log(response);
    if (response.data && response.data.error !== "") {
      notify("error", "error occured");
    } else {
      setEmployeedata(response.data.employee_list);
    }
  };
  React.useEffect(() => {
    GetMyDetails();
    GetMyEmployeesdata();
  }, []);

  const ischangesarethere = (detail, existingdetails) => {
    // const keys1=Object.keys(detail);
    // const keys2=Object.keys(existingdetails);
    console.log("helllo")
    for (let keys1 in detail) {
      console.log(keys1, detail[keys1], existingdetails[keys1], detail[keys1] != existingdetails[keys1])
      if (detail[keys1] != existingdetails[keys1]) {
        if (keys1 == "mobile") {
          console.log(detail[keys1], existingdetails[keys1], detail[keys1] != existingdetails[keys1])
          if (detail[keys1] != existingdetails[keys1].slice(3)) {
            return true;
          }
          continue
        }
        return true;
      }
    }
    return false;
  }
  const hasUpperLevelProviderChanged = (details, existingdetails) => {
    return existingdetails?.is_upper_level_provider != details.is_upper_level_provider;
  };

  const modifyStoreDetail = async () => {

    if (details.address.address_line1 === "") {
      notify("error", "Address line 1 is required");
      return;
    }
    if (details.address.town === "") {
      notify("error", "Town is required");
      return;
    }
    if (details.address.state === "Select State") {
      notify("error", "Please select a state");
      return;
    }
    if (details.address.district === "Select District") {
      notify("error", "Please select a district");
      return;
    }
    if (details.address.pincode === "") {
      notify("error", "Pincode is required");
      return;
    } else if (details.address.pincode.length !== 6) {
      notify("error", "Pincode should be 6 digits long");
      return;
    }
    if (details.address.latitude === "") {
      notify("error", "Latitude is required");
      return;
    }
    if (details.address.longitude === "") {
      notify("error", "Longtitude is required");
      return;
    }

    if (!details.email) {
      notify("error", "Email ID is required");
      return;
    } else if (!/\S+@\S+\.\S+/.test(details.email)) {
      notify("error", "Email ID is invalid");
      return;
    }
    if (!details.name) {
      notify("error", "Name is required");
      return;
    }
    // notify('warn', details.mobile.length);
    if (!details.mobile && details.mobile.size() > 10) {
      notify("error", "Mobile number is required");
      return;
    } else if (!/^[0-9]{10}$/.test(details.mobile) && storedetailupdatestatus.current === "set") {
      notify("error", "Please enter a valid 10-digit contact number");
      return;
    }
    else if (storedetailupdatestatus.current === "unset") {
      return;
    }
    // if (!details.whatsappnumber) {
    //   notify("error", "Whatsapp number is required");
    //   return;
    // }

    // else if (!/^[0-9]{10}$/.test(details.whatsappnumber)) {
    //   notify("error", "Please enter a valid 10-digit number");
    //   return;
    // }
    if (!details.serviceabledistance) {
      notify("error", "Servicable distance is required");
      return;
    } else if (details.serviceabledistance <= 0) {
      notify("error", "Servicable distance must be greater than zero.");
      return;
    }
    if (!details.description) {
      notify("error", "Service description is required");
      return;
    }




    let response;
    if (ischangesarethere(details, existingdetails)) {
      setDetails((prevFormData) => {
        return {
          ...prevFormData,
          mobile: `+91${prevFormData.mobile}`,
          whatsappnumber: `+91${prevFormData.whatsappnumber}`,
        };
      });

      // Check if is_upper_level_provider has changed
      storedetailupdatestatus.current = "unset"
      if (hasUpperLevelProviderChanged(details, existingdetails)) {


        setTimeout(async () => {
          console.log(details);



          response = await myProfileServiceApi.ModifySellerDetails(details, true);

          // Handle response for lower level
          if (response.data && response.data.error !== "") {
            notify("error", "Error in Updating ");
            storedetailupdatestatus.current = "set";
          } else {
            notify("success", "Updated Successfully for Higher Level");
            fetchHomePageDataAll();
            GetMyDetails();
          }


        }, 1000);
      }
      else {
        // details !== existingdetails ?
        response = await myProfileServiceApi.ModifySellerDetails(details, false);
        // Handle response for lower level
        if (response.data && response.data.error !== "") {
          notify("error", "Error in Updating for Lower Level");
          storedetailupdatestatus.current = "set";
        } else {
          notify("success", "Updated Successfully for Lower Level");
          GetMyDetails();
        }
      }
    }
    else {
      notify("error", "No changes are observed");
    }
  }

  const handleDeleteEmployee = async (employeename) => {
    const response = await myProfileServiceApi.Deleteemployee(
      employeename,
      Userprofile.service_provider_id
    );
    console.log(response);
    if (response.data && response.data.error !== "") {
      notify("error", response.data.error);
    } else {
      notify("success", "Employee deleted successfully");
      GetMyEmployeesdata();
    }
  };
  const handleProviderImageRemove = () => {
    setServiceProviderImage("");
    setImageBtn(true);
  };
  const handleImageUpload = (event) => {
    const selectedImage = event.target.files[0];
    setIsProcessingImage(true); 
    uploadOGCFunction(event.target.files);

    if (selectedImage) {
      const reader = new FileReader();
      reader.onload = () => {
        setServiceProviderImage(reader.result);

      };
      reader.readAsDataURL(selectedImage);
      
    }
  };
  async function uploadOGCFunction(files) {
    try {
      // console.log(files);
      const response = await postapi.UploadImageOGC(files[0]);
      if (response) {
        setDetails((prevFormData) => {
          return {
            ...prevFormData,
            image: response.data.url,
          };
        });
        setIsProcessingImage(false); 
      }
    } catch (err) {
      console.log(err);
    }
  }

  const handleClick = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;

          try {
            // Retrieve address from coordinates
            const response = await Geocode.fromLatLng(latitude, longitude);
            const address = response.results[0].formatted_address;
            console.log(response.results);
            console.log(address);
            const address_formatted = address.split(",");
            console.log(address_formatted);
            const state_pincode_formatted =
              address_formatted[address_formatted.length - 2];
            const state_pincode_array = state_pincode_formatted.split(" ");

            setLocation({ lat: latitude, lng: longitude });
            setDetails((prevFormData) => {
              console.log(prevFormData);
              return {
                ...prevFormData,
                address: {
                  address_line1: address_formatted[0],
                  town: address_formatted[2],
                  state: state_pincode_array[0],
                  district: response.results[0].address_components[3].long_name,
                  pincode: hasDigit(state_pincode_array[1]) ? state_pincode_array[1] : "",
                  latitude: latitude.toString(),
                  longitude: longitude.toString(),
                }
              };
            });
   
          } catch (error) {
            console.log("Error retrieving address:", error);
            notify("error", "Network error");
          }
        },
        (error) => {
          console.log("Geolocation error:", error);
          notify("error", error.message);
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };
  const handleMarkerDragend = async (e) => {
    const { latLng } = e;
    console.log(e);
    const lat = latLng.lat();
    const lng = latLng.lng();
    try {
      setLocation({ lat, lng });
      const response = await Geocode.fromLatLng(lat, lng);
      const address = response.results[0].formatted_address;
      const address_formatted = address.split(", ");
      console.log(address_formatted);
      const state_pincode_formatted =
        address_formatted[address_formatted.length - 2];
      console.log(state_pincode_formatted);
      const state_pincode_array = state_pincode_formatted.split(" ");
      console.log(state_pincode_array);
      let newState = "";
      let newPincode = "";

      if (state_pincode_array.length === 1 && !isNaN(state_pincode_array[0])) {
        newPincode = state_pincode_array[0];
      } else if (state_pincode_array.length === 2) {
        newState = isNaN(state_pincode_array[0]) ? state_pincode_array[0] : "";
        newPincode =
          isNaN(state_pincode_array[1]) ||
            typeof state_pincode_array[1] !== "string"
            ? ""
            : state_pincode_array[1];
      }

      setDetails((prevFormData) => {
        console.log(prevFormData);
        return {
          ...prevFormData,
          address: {
            address_line1: address_formatted[0],
            town: address_formatted[2],
            state: state_pincode_array[0],
            district: response.results[0].address_components[3].long_name,
            pincode: hasDigit(state_pincode_array[1]) ? state_pincode_array[1] : "",
            latitude: lat.toString(),
            longitude: lng.toString(),
          }
        };
      });
      console.log(response.results);

    } catch (error) {
      console.error("Error retrieving address:", error);
      notify("error", "Error retrieving address");
    }
  };

  const [page, setPage] = React.useState(1);
  return (
    <React.Fragment>
      <>
        <div className="my-service-profile-ongoing-and-pending-online">
          {page !== 4 ? (
            <>
              <div className="my-service-profile-ongoing-and-pending-online-selector">
                <div className="my-service-profile-ongoing-and-pending-online-selector-tab">
                  <div className="my-service-profile-ongoing-and-pending-online-back">
                    <img src={back} onClick={goBack}></img>
                  </div>
                  <motion.span
                    onClick={() => {
                      setPage(1);
                    }}
                    animate={{
                      color: page === 1 ? "black" : "rgba(0, 0, 0, 0.512)",
                      "border-bottom":
                        page === 1 ? "3px solid #1D8B97" : "none",
                    }}
                    whileHover={{ color: "black" }}
                  >
                    Modify Address
                  </motion.span>
                  <motion.span
                    onClick={() => {
                      setPage(2);
                    }}
                    animate={{
                      color: page === 2 ? "black" : "rgba(0, 0, 0, 0.512)",
                      "border-bottom":
                        page === 2 ? "3px solid #1D8B97" : "none",
                    }}
                    whileHover={{ color: "black" }}
                  >
                    Store Details
                  </motion.span>
                  <motion.span
                    onClick={() => {
                      setPage(3);
                    }}
                    animate={{
                      color: page === 3 ? "black" : "rgba(0, 0, 0, 0.512)",
                      "border-bottom":
                        page === 3 ? "3px solid #1D8B97" : "none",
                    }}
                    whileHover={{ color: "black" }}
                  >
                    Manage Employees
                  </motion.span>
                </div>
                {page === 1 ? (
                  <>


                  
                    {/* how to edit sub-object nested object form edit in sub object */}
                    <div className="my-job-post-service-profile-page-view-main-2">
                    <div className="profile-account-setting-right-new-address-location"> 
                    <div className="useerlocationdiv"> 
                    <input placeholder="Search for area, street name...." className="userlocation1" onChange={(event)=>{
                      setLocationname(event.target.value);
                    }}></input>
                    {
                      predictedaddress.length > 0 && open && <>
                      <div className="search-modal" id="seaaarchmodaladdress" ref={refforinput}>
                        {
                          predictedaddress.map((items,index)=>{
                            return(
                              <div className="predictionDiv" onClick={(e)=>{
                                setDetails((details)=>{
                                  let ab=items.description.split(",");
                                  console.log(ab);
                                  return {...details,address:{
                                    "address_line1": `${ab.slice(0,ab.length/2)}`,
                                    "address_line2": `${ab.slice(ab.length/2)}`,
                                    "country": "India",
                                    "district": "",
                                    "landmark": "",
                                    "latitude":`${items.latitude}`,
                                    "longitude": `${items.longitude}`,
                                    "pincode": "",
                                    "state":"",
                                    "town": ""
                                  }}
                                })
                              }}>{items.description}</div>
                            )
                          })
                        }
                      </div>
                      </>
                    }
                  </div>
                    <button onClick={handleClick} className="buttton">
                  <img src={locationImage} alt="" />
                  <p>Use my current location</p>

                </button>

                {location && (
                  <div
                    style={{
                      width: "400px",
                      height: "300px",
                      position: "relative",
                    }}
                  >
                    <Map
                      google={window.google}
                      apiKey={googleMapsApiKey}
                      zoom={14}
                      initialCenter={location}
                      style={{ width: "100%", height: "100%" }}
                    >
                      <Marker
                        position={location}
                        draggable={true}
                        onDragend={(t, map, coord) =>
                          handleMarkerDragend(coord)
                        }
                      />
                    </Map>
                  </div>
                )}
      </div>
                      <div class="add-service-provider-profile-right-inputs">
                        <span>Address Line 1 <span class="star" style={{ color:"red"}}>*</span></span>
                        <input
                          type="text"
                          name="address_line1"
                          value={
                            details
                              ? details.address.address_line1
                                ? details.address.address_line1
                                : ""
                              : ""
                          }
                          onChange={handleChange("address")}
                          placeholder="Enter Address"
                        ></input>
                      </div>
                      <div class="add-service-provider-profile-right-inputs">
                        <span>Address Line 2</span>
                        <input
                          type="text"
                          name="address_line2"
                          value={
                            details
                              ? details.address.address_line2
                                ? details.address.address_line2
                                : ""
                              : ""
                          }
                          onChange={handleChange("address")}
                          placeholder="Enter Address"
                        ></input>
                      </div>
                      <div class="add-service-provider-profile-right-inputs">
                        <span>Town <span class="star" style={{ color:"red"}}>*</span></span>
                        <input
                          type="text"
                          name="town"
                          value={
                            details
                              ? details.address.town
                                ? details.address.town
                                : ""
                              : ""
                          }
                          onChange={handleChange("address")}
                          placeholder="Enter town"
                        ></input>
                      </div>
                      <div class="add-service-provider-profile-right-inputs">
                        <span>LandMark </span>
                        <input
                          type="text"
                          name="landmark"
                          value={
                            details
                              ? details.address.landmark
                                ? details.address.landmark
                                : ""
                              : ""
                          }
                          onChange={handleChange("address")}
                          placeholder="Enter landmark"
                        ></input>
                      </div>
                      <div class="add-service-provider-profile-right-inputs">
                        <span>Pincode <span class="star" style={{ color:"red"}}>*</span></span>
                        <input
                          type="text"
                          name="pincode"
                          value={
                            details
                              ? details.address.pincode
                                ? details.address.pincode
                                : ""
                              : ""
                          }
                          onChange={handleChange("address")}
                          placeholder="Enter pincode"
                        ></input>
                      </div>
                      <div class="add-service-provider-profile-right-inputs">
                        <span>Select State <span class="star"style={{ color:"red"}}>*</span></span>
                        <select
                          name="state"
                          value={
                            details
                              ? details.address.state
                                ? details.address.state
                                : ""
                              : ""
                          }
                          onChange={handleChange("address")}
                        >
                          <option value="">Select State</option>
                          {allStates.map((state) => (
                            <option key={state} value={state}>
                              {state}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div class="add-service-provider-profile-right-inputs">
                        <span>Select District <span class="star" style={{ color:"red"}}>*</span></span>
                        <select
                          name="district"
                          value={
                            details
                              ? details.address.district
                                ? details.address.district
                                : ""
                              : ""
                          }
                          onChange={handleChange("address")}
                        >
                          <option value="">Select District</option>
                          {details &&
                            details.address.state != "" &&
                            getDistricts(details.address.state).map(
                              (district) => (
                                <option key={district} value={district}>
                                  {district}
                                </option>
                              )
                            )}
                        </select>
                      </div>
                      <div class="add-service-provider-profile-right-inputs">
                        <span>Latitude <span class="star" style={{ color:"red"}}>*</span></span>
                        <input
                          type="text"
                          name="latitude"
                          value={
                            details
                              ? details.address.latitude
                                ? details.address.latitude
                                : ""
                              : ""
                          }
                          onChange={handleChange("address")}
                          readOnly
                          placeholder="Enter latitude"
                        ></input>
                      </div>
                      <div class="add-service-provider-profile-right-inputs">
                        <span>Longitude <span class="star" style={{ color:"red"}}>*</span></span>
                        <input
                          type="text"
                          name="longitude"
                          readOnly
                          value={
                            details
                              ? details.address.longitude
                                ? details.address.longitude
                                : ""
                              : ""
                          }
                          onChange={handleChange("address")}
                          placeholder="Enter longitude"
                        ></input>
                      </div>
                      <div className="my-job-post-service-profile-page-view-main-input-submit">
                        <button onClick={modifyStoreDetail}>Submit</button>
                      </div>
                    </div>
                  </>
                ) : page === 2 ? (
                  <>
                    <div className="my-job-post-service-profile-page-view-main-2">
                      <div class="add-service-provider-profile-right-inputs">
                        <span>Provider Type</span>
                        <input
                          type="text"
                          readOnly
                          name="providertype"
                          value={details?.provider_type ? details?.provider_type : ""}

                        ></input>
                      </div>
                      <div class="add-service-provider-profile-right-inputs">
                        <span>Email Id</span>
                        <input
                          type="text"
                          name="email"
                          value={details?.email ? details?.email : ""}
                          onChange={handleChange()}
                          placeholder="Enter email Id"
                        ></input>
                      </div>
                      <div class="add-service-provider-profile-right-inputs">
                        <span>Name</span>
                        <input
                          type="text"
                          name="name"
                          value={details?.name ? details?.name : ""}
                          onChange={handleChange()}
                          placeholder="Enter name"
                        ></input>
                      </div>
                      <div class="add-service-provider-profile-right-inputs useravailabilityinput">
                        <span>Username</span>
                        {hasInputChanged && usernameAvailable !== null && (
                          <div className="availability-message">
                            {
                              checkingusername ? <div className="usernameavailable">checking...</div> :
                              <>
                            {usernameAvailable
                              ? <div className="usernameavailable">Username is available!</div>
                              : <div className="usernamenotavailable">Username is not available!</div>
                              }
                              </>
                              }
                          </div>
                        )}

                        <input
                          type="text"
                          name="username"
                          value={details?.username ? details?.username : ""}
                          onChange={handleChange()}
                          placeholder="Enter username"
                        ></input>
                      </div>

                      <div class="add-service-provider-profile-right-inputs">
                        <span>Mobile Number</span>
                        <input
                          type="number"
                          name="mobile"
                          readOnly
                          value={details?.mobile ? details?.mobile : ""}
                          onChange={handleChange()}
                          placeholder="Enter mobile number"
                        ></input>
                      </div>
                      <div class="add-service-provider-profile-right-inputs">
                        <span>WhatsApp Number</span>
                        <input
                          type="number"
                          name="whatsappnumber"
                          value={
                            details?.whatsappnumber ? details?.whatsappnumber : ""
                          }
                          onChange={handleChange()}
                          placeholder="Enter whatapp Number"
                        ></input>
                      </div>
                      <div class="add-service-provider-profile-right-inputs">
                        <span style={{ width: '35%' }}>Serviceable Distance (in km(s))</span>
                        <input
                          type="number"
                          name="serviceabledistance"
                          value={
                            details?.serviceabledistance
                              ? details?.serviceabledistance
                              : ""
                          }
                          onChange={handleChange()}
                          placeholder="Enter Serviceable Distance"
                        ></input>
                      </div>
                      <div class="add-service-provider-profile-right-inputs">
                        <span>Do You Have a Dynamic Location</span>
                        <div className="my-profile-my-bussiness-account-store-details">
                          <input
                            type="radio"
                            name="hasdynamiclocaion"
                            value={0}
                            onChange={handleChange()}
                            checked={details?.hasdynamiclocaion == "0"}
                          ></input>
                          &nbsp;NO&nbsp;&nbsp;&nbsp;
                          <input
                            type="radio"
                            name="hasdynamiclocaion"
                            value={1}
                            onChange={handleChange()}
                            checked={details?.hasdynamiclocaion == "1"}
                          ></input>
                          &nbsp;Yes
                        </div>
                      </div>
                      <div class="add-service-provider-profile-right-inputs">
                        <span>Do You Have a Multiple Services</span>
                        <div className="my-profile-my-bussiness-account-store-details">
                          <input
                            type="radio"
                            name="multipleservice"
                            value={0}
                            onChange={handleChange()}
                            checked={details?.multipleservice == "0"}
                          ></input>
                          &nbsp;NO&nbsp;&nbsp;&nbsp;
                          <input
                            type="radio"
                            name="multipleservice"
                            value={1}
                            onChange={handleChange()}
                            checked={details?.multipleservice == "1"}
                          ></input>
                          &nbsp;Yes
                        </div>
                      </div>
                      <div class="add-service-provider-profile-right-inputs">
                        <span>Do You Have a Multiple Outlet</span>
                        <div className="my-profile-my-bussiness-account-store-details">

                          <input
                            type="radio"
                            name="multipleoutlet"
                            value={0}
                            onChange={handleChange()}
                            checked={details?.multipleoutlet == "0"}
                          ></input>
                          &nbsp;NO&nbsp;&nbsp;&nbsp;
                          <input
                            type="radio"
                            name="multipleoutlet"
                            value={1}
                            onChange={handleChange()}
                            checked={details?.multipleoutlet == "1"}
                          ></input>
                          &nbsp;Yes
                        </div>
                      </div>
                      <div class="add-service-provider-profile-right-inputs">
                        <span>Visible ?</span>

                        <div className="my-profile-my-bussiness-account-store-details">
                          <input
                            type="radio"
                            name="visible"
                            value={0}
                            onChange={handleChange()}
                            checked={details?.visible == "0"}
                          ></input>
                          &nbsp;NO&nbsp;&nbsp;&nbsp;
                          <input
                            type="radio"
                            name="visible"
                            value={1}
                            onChange={handleChange()}
                            checked={details?.visible == "1"}
                          ></input>
                          &nbsp;Yes
                        </div>
                      </div>
                      <div class="add-service-provider-profile-right-inputs">
                        <span>is {details?.provider_type ? details?.provider_type : ""}  View </span>


                        <div className="my-profile-my-bussiness-account-store-details">
                          <input
                            type="radio"
                            name="is_upper_level_provider"
                            value={0}
                            onChange={handleChange()}
                            checked={details?.is_upper_level_provider == "0"}
                          ></input>
                          &nbsp;NO&nbsp;&nbsp;&nbsp;
                          <input
                            type="radio"
                            name="is_upper_level_provider"
                            value={1}
                            onChange={handleChange()}
                            checked={details?.is_upper_level_provider == "1"}
                          ></input>
                          &nbsp;Yes
                        </div>
                      </div>
                      <div class="add-service-provider-profile-right-inputs">
                        <div className="my-job-post-service-profile-page-image">

                          <span>Business Logo</span>

                          {serviceProviderImage ? (
                            <>

                              <img src={serviceProviderImage} alt="" />
                              <IoCloseCircleSharp
                                size={30}
                                className="crossIconEditProvider"
                                onClick={() => handleProviderImageRemove()}
                              />
                            </>
                          ) : (
                            <form className="imageInputForm">
                              <label htmlFor="image-input">Select Image</label>
                              <input
                                type="file"
                                id="image-input"
                                multiple
                                accept="image/png, image/jpg, image/gif, image/jpeg"
                                onChange={(e) => handleImageUpload(e)}
                              />
                            </form>
                          )}
                        </div>
                      </div>
                      <div class="add-service-provider-profile-right-inputs">
                        <span>Serviceable Description</span>
                        <textarea
                          type="text"
                          name="serviceabledistance"
                          value={details?.description ? details?.description : ""}
                          onChange={handleChange()}
                          placeholder="Enter Service Description"
                        ></textarea>
                      </div>
                      <div className="my-job-post-service-profile-page-view-main-input-submit">
                      {isProcessingImage ?  <button >Updating image, please wait...</button>: <button onClick={modifyStoreDetail}>Update</button>}
                      </div>
                    </div>
                  </>
                ) : page == 3 ? (
                  <>
                    <div className="my-job-post-service-profile-page-view-main-2">
                      <div className="my-job-post-service-profile-page-view-main-input-submit">
                        <button
                          style={{ borderRadius: "15px" }}
                          onClick={() => {
                            handleModalClose();
                          }}
                        >
                          Add New Employee
                        </button>
                      </div>
                      <div className="my-job-post-service-profile-page-view-main my-job-post-service-profile-page-view-mainsecond">
                        {employeedata &&
                          employeedata.length > 0 &&
                          employeedata.map((item) => {
                            return (
                              <>
                                <div className="my-job-post-service-profile-page-view-main-nearby-profile">
                                  <div className="my-job-post-service-profile-page-view-main-nearby-profile-image">
                                    <img
                                      src={
                                        item.profile_image
                                          ? item.profile_image
                                          : defaultImage
                                      }
                                      alt=""
                                    />
                                  </div>
                                  <div className="my-job-post-service-profile-page-view-main-nearby-profile-head">
                                    Name: {item.name}
                                  </div>
                                  <div className="my-job-post-service-profile-page-view-main-nearby-profile-foi">
                                    <span
                                      style={{
                                        color: "rgba(0, 0, 0, 0.827)",
                                        fontWeight: "600",
                                      }}
                                    >
                                      Email :
                                    </span>
                                    &nbsp;
                                    {item.email ? item.email : "Not Available"}
                                  </div>
                                  <div className="my-job-post-service-profile-page-view-main-nearby-profile-skills">
                                    <span
                                      style={{
                                        color: "rgba(0, 0, 0, 0.827)",
                                        fontWeight: "600",
                                      }}
                                    >
                                      Gender :
                                    </span>
                                    &nbsp;
                                    {item.gender
                                      ? item.gender
                                      : "Not Available"}
                                  </div>

                                  <>
                                    <div className="my-job-post-service-profile-page-view-main-nearby-profile-exp">
                                      <span
                                        style={{
                                          color: "rgba(0, 0, 0, 0.827)",
                                          fontWeight: "600",
                                        }}
                                      >
                                        Designation :
                                      </span>
                                      &nbsp;
                                      {item.is_owner ? "Owner" : "Employee"}
                                      {!item.is_owner && (
                                        <div className="my-job-post-service-profile-page-view-main-nearby-profile-delete">
                                          <BiSolidTrashAlt
                                            size={30}
                                            onClick={() =>
                                              handleDeleteEmployee(
                                                item.username
                                              )
                                            }
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </>
                                </div>
                              </>
                            );
                          })}
                      </div>
                    </div>
                    {employeemodal && (
                      <EmployeeModal
                        onClose={handleModalClose}
                        id={details.id}
                        getnewdata={GetMyEmployeesdata}
                      />
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </>
          ) : (
            <div className="my-bills-profile-page-view-main-right"></div>
          )}
        </div>
      </>
    </React.Fragment>
  );
}

const EmployeeModal = ({ onClose, id, getnewdata }) => {
  const [employeename, setEmployeename] = React.useState("");
  const handleChange2 = (e) => {
    const { value } = e.target;
    setEmployeename(value);
  };

  const AddEmployee = async () => {
    if (employeename === "") {
      notify("error", "Username is required");
      return;
    }
    const response = await myProfileServiceApi.Addemployee(employeename, id);
    console.log(response);
    if (response.data && response.data.error !== "") {
      notify("error", response.data.error);
    } else {
      notify("success", "Employee Added Successfully");
      onClose();
      getnewdata();
    }
  };

  return (
    <div className="order-status-modal">
      <div className="order-status-modal-content ">
        <div className="service-provider-modal-content-button">
          <button
            className="service-provider-modal-close-button"
            onClick={onClose}
          >
            X
          </button>
        </div>
        <div class="service-provider-modal-right-inputs">
          <span>
            Username<span> *</span>
          </span>

          <input
            type="text"
            name="employeename"
            value={employeename}
            onChange={handleChange2}
            placeholder="Employee Username"
          ></input>
        </div>

        <div className="buttons-container">
          <button onClick={AddEmployee}>Add Employee</button>
        </div>
      </div>
    </div>
  );
};
