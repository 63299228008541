import React, { Component } from 'react'
import MyWalletMainpageView from './WalletMainPageView';
import TransactionHistory from './transactionHistory';
import TransferToWallet from './transferTowallet';
export default function MyWalletMainMainPage()
{const [page,setPage]=React.useState(0);

 const PageChange =(i)=>
 {
  setPage(i)
 }
 if (page === 0) {
   return (
     <React.Fragment>
       <MyWalletMainpageView newpage={PageChange}></MyWalletMainpageView>
     </React.Fragment>
   );
 } else if (page === 1) {
   return (
     <>
       <TransactionHistory newpage={PageChange}></TransactionHistory>
     </>
   );
 } else if (page === 2) {
   return (
     <>
       <TransferToWallet newpage={PageChange}></TransferToWallet>
     </>
   );
 }
}