import axios from "axios";
//company
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

class PutatoeServiceApi {
  OnlineOrders(seller_id, start_date, end_date, sub_service_id) {
    const requestData = {
      start_date,
      end_date,
    };
    if (sub_service_id !== null && sub_service_id !== "") {
      requestData.sub_service_id = parseInt(sub_service_id);
    }
    return axios.post(
      API_BASE_URL +
        "/v1/api/service_provider_orders_by_serviceId_new/" +
        seller_id,
      requestData,
      {
        headers: {
          authtoken: localStorage.getItem("authtoken"),
        },
      }
    );
  }

  CancelledAndCompletedOnline(seller_id, start_date, end_date, sub_service_id) {
    const requestData = {
      start_date,
      end_date,
    };

    if (sub_service_id !== null && sub_service_id !== "") {
      requestData.sub_service_id = parseInt(sub_service_id);
    }
    return axios.post(
      API_BASE_URL + "/v1/api/cancel_and_complete_order_new/" + seller_id,
      requestData,
      {
        headers: {
          authtoken: localStorage.getItem("authtoken"),
        },
      }
    );
  }
  AllOnlineCustomers(serviceprovider_id) {
    return axios.post(
      API_BASE_URL + "/v1/api/AllOnlineCustomer",
      { serviceprovider_id },
      {
        headers: {
          authtoken: localStorage.getItem("authtoken"),
        },
      }
    );
  }

  ALlOfflineOrders(seller_id, start_date, end_date, sub_service_id) {
    const postData = {
      start_date,
      end_date,
    };

    if (sub_service_id !== null && sub_service_id !== "") {
      postData.sub_service_id = parseInt(sub_service_id);
    }

    return axios.post(
      API_BASE_URL + "/v1/api/allOfflineOrders/" + seller_id,
      postData,
      {
        headers: {
          authtoken: localStorage.getItem("authtoken"),
        },
      }
    );
  }

  AllOfflineCustomers(sp_id, sortingOption, index) {
    let payload = {
      sp_id: sp_id,
      by_descending: 0,
      by_ascending: 0,
      by_alphabatically: 0,
    };

    if (sortingOption === 1) {
      payload.by_ascending = 1;
    } else if (sortingOption === 2) {
      payload.by_descending = 1;
    } else if (sortingOption === 3) {
      payload.by_alphabatically = 1;
    }

    return axios.post(
      API_BASE_URL + "/v1/api/allOfflineCustomers_new/" + index,
      payload,
      {
        headers: {
          authtoken: localStorage.getItem("authtoken"),
        },
      }
    );
  }

  // OfflineShowOutGoing(seller_id, start_date, end_date) {
  //   return axios.post(
  //     API_BASE_URL + '/v1/api/showOutgoing/' + seller_id,
  //     { start_date, end_date },
  //     {
  //       headers: {
  //         authtoken: localStorage.getItem('authtoken'),
  //       },
  //     }
  //   );
  // }

  // OfflineShowIncoming(seller_id, start_date, end_date) {
  //   return axios.post(
  //     API_BASE_URL + '/v1/api/showIncoming/175078',
  //     { start_date: "2023-07-06", end_date },
  //     {
  //       headers: {
  //         authtoken: "CHZ8XH10GXYMBVL6ZT2IB0DHNV7K719S2GATRWPA4PJ4QI9K1C",
  //       },
  //     }
  //   );
  // }

  OfflineIncomingTransaction(
    sub_service_id,
    start_date,
    end_date,
    sp_id,
    user_id,
    selectedTransaction,startIndex
  ) {
    console.log(selectedTransaction,    user_id);
    const payload = {
      start_date: start_date,
      end_date: end_date,
      index:startIndex
    };

    if (sub_service_id !== null && sub_service_id !== "") {
      payload.sub_service_id = parseInt(sub_service_id);
    }
    if (selectedTransaction.prodtypeid !== "" && user_id != " ") {
      payload.product_type_id = selectedTransaction.prodtypeid;
      payload.user_id = user_id;
    }
     else if (user_id !== " ") {
      payload.user_id = user_id;
    } else if (
      selectedTransaction.prodid !== "" &&
      selectedTransaction.prodtypeid !== "" &&
      selectedTransaction.quantityid !== ""
    ) {
      payload.product_id = selectedTransaction.prodid;
      payload.product_type_id = selectedTransaction.prodtypeid;
      payload.qty_id = selectedTransaction.quantityid;
    } else {
      if (selectedTransaction.prodtypeid !== "") {
        payload.product_type_id = selectedTransaction.prodtypeid;
      }
      if (selectedTransaction.prodid !== "") {
        payload.product_id = selectedTransaction.prodid;
      }
      if (selectedTransaction.quantityid !== "") {
        payload.qty_id = selectedTransaction.quantityid;
      }
    }
    return axios.post(API_BASE_URL + "/v1/api/showIncoming/" + sp_id, payload, {
      headers: {
        authtoken: localStorage.getItem("authtoken"),
      },
    });
  }
  OfflineOutgoingTransaction(
    sub_service_id,
    start_date,
    end_date,
    sp_id,
    user_id,
    selectedTransaction,startIndex
  ) {
    const payload = {
      start_date: start_date,
      end_date: end_date,
      index:startIndex
    };

    if (sub_service_id !== null && sub_service_id !== "") {
      payload.sub_service_id = parseInt(sub_service_id);
    }
    if (selectedTransaction.prodtypeid !== "" && user_id != " ") {
      payload.product_type_id = selectedTransaction.prodtypeid;
      payload.user_id = user_id;
    } 
    else if (user_id !== " ") {
      payload.user_id = user_id;
    } else if (
      selectedTransaction.prodid !== "" &&
      selectedTransaction.prodtypeid !== "" &&
      selectedTransaction.quantityid !== ""
    ) {
      payload.product_id = selectedTransaction.prodid;
      payload.product_type_id = selectedTransaction.prodtypeid;
      payload.qty_id = selectedTransaction.quantityid;
    } else {
      if (selectedTransaction.prodtypeid !== "") {
        payload.product_type_id = selectedTransaction.prodtypeid;
      }
      if (selectedTransaction.prodid !== "") {
        payload.product_id = selectedTransaction.prodid;
      }
      if (selectedTransaction.quantityid !== "") {
        payload.qty_id = selectedTransaction.quantityid;
      }
    }

    return axios.post(API_BASE_URL + "/v1/api/showOutgoing/" + sp_id, payload, {
      headers: {
        authtoken: localStorage.getItem("authtoken"),
      },
    });
  }
  deleteOrderBill(sp_id, Order_ID) {
    return axios.post(
      API_BASE_URL + "/v1/api/deleteOrderBill",
      { sp_id, Order_ID },
      {
        headers: {
          authtoken: localStorage.getItem("authtoken"),
        },
      }
    );
  }

  AllServicesByUser(seller_id) {
    return axios.get(
      API_BASE_URL + "/v1/api/all_services_by_user/" + seller_id,
      {
        headers: {
          authtoken: localStorage.getItem("authtoken"),
        },
      }
    );
  }
  DeleteMyService(service_id, sub_service_id) {
    return axios.post(
      API_BASE_URL + "/v1/api/DeleteServiceForServiceprovider",
      { service_id, sub_service_id },
      {
        headers: {
          authtoken: localStorage.getItem("authtoken"),
        },
      }
    );
  }
  AddToMyService(service_id, sub_service_id) {
    return axios.post(
      API_BASE_URL + "/v1/api/AddServiceForServiceprovider",
      { service_id, sub_service_id },
      {
        headers: {
          authtoken: localStorage.getItem("authtoken"),
        },
      }
    );
  }
  DeleteServiceProvider() {
    return axios.get(API_BASE_URL + "/v1/api/deleteServiceprovider", {
      headers: {
        authtoken: localStorage.getItem("authtoken"),
      },
    });
  }

  ServiceProviderAllProduct(service_provider_id, index = 0, sub_service_id) {
    const requestData = {
      service_provider_id,
    };

    if (sub_service_id !== null && sub_service_id !== "") {
      requestData.sub_service_id = sub_service_id;
    }

    return axios.post(
      API_BASE_URL + `/v1/api/ServiceProviderAllProductGlobal/${index}`,
      requestData,
      {
        headers: {
          authtoken: localStorage.getItem("authtoken"),
        },
      }
    );
  }

  Addemployee(username, service_provider_id) {
    return axios.post(
      API_BASE_URL + `/v1/api/addemployee`,
      { username, service_provider_id, is_owner: 0 },
      {
        headers: {
          authtoken: localStorage.getItem("authtoken"),
        },
      }
    );
  }
  Showemployee(sp_id) {
    return axios.post(
      API_BASE_URL + `/v1/api/showEmployees`,
      { sp_id },
      {
        headers: {
          authtoken: localStorage.getItem("authtoken"),
        },
      }
    );
  }
  Deleteemployee(employee_uname, sp_id) {
    return axios.post(
      API_BASE_URL + `/v1/api/DeleteEmployee`,
      { employee_uname, sp_id },
      {
        headers: {
          authtoken: localStorage.getItem("authtoken"),
        },
      }
    );
  }
  Showsubserviceasperserviceprovider(service_provider_id) {
    return axios.get(
      API_BASE_URL + `/v1/api/show_content/` + service_provider_id,
      {
        headers: {
          authtoken: localStorage.getItem("authtoken"),
        },
      }
    );
  }

  ServiceProviderAllMyProduct(service_provider_id) {
    return axios.get(API_BASE_URL + "/v1/api/showtransactions", {
      headers: {
        authtoken: localStorage.getItem("authtoken"),
      },
    });
  }
  ServiceAddGenerateBillMyProduct(myproductId, myproductAmount, quantity) {
    const payload ={
      transaction_id: myproductId,
      changed_price: myproductAmount,
    }
    if(quantity !== undefined && quantity){
      payload["quantity"]=parseInt(quantity)
    }
    return axios.post(
      API_BASE_URL + "/v1/api/shopaddproduct",
      payload,
      {
        headers: {
          authtoken: localStorage.getItem("authtoken"),
        },
      }
    );
  }
  ServiceAddGenerateBillProduct(
    productId,
    color,
    quantityID,
    quantity,
    serviceProviderProductId,
    serviceId,
    changed_price,
  ) 
  {
    const payload = {
      product_id: productId,
      quantity_id: quantityID,
      quantity: quantity,
      service_provider_product_id: serviceProviderProductId,
      service: serviceId,
    };
if (changed_price !== false) {
  payload.changed_price = changed_price;
    }
    if(color!=undefined){
      console.log(color);
      payload.color_id=color.color_id;
    }
    return axios.post(API_BASE_URL + "/v1/api/addproduct", payload, {
      headers: {
        authtoken: localStorage.getItem("authtoken"),
      },
    });
  }

  // ServiceAddGenerateBillMyProduct(myproductId, myproductAmount) {
  //   return axios.post(
  //     API_BASE_URL + "/v1/api/shopaddproduct",
  //     { transaction_id: myproductId, changed_price: myproductAmount },
  //     {
  //       headers: {
  //         authtoken: localStorage.getItem("authtoken"),
  //       },
  //     }
  //   );
  // }

  ShowAddedGenerateBillProduct() {
    return axios.get(API_BASE_URL + "	/v1/api/showaddedproduct", {
      headers: {
        authtoken: localStorage.getItem("authtoken"),
      },
    });
  }
  ModifyGenerateBillProduct(quantity, cartId,changed_price) {
    return axios.post(
      API_BASE_URL + "/v1/api/changeQuantity",
      { quantity: quantity, cart_id: cartId,changed_price },
      {
        headers: {
          authtoken: localStorage.getItem("authtoken"),
        },
      }
    );
  }

  DeleteGenerateBillProduct(productQuantityId, serviceProviderProductId) {
    return axios.post(
      API_BASE_URL + "/v1/api/deleteproduct",
      {
        product_quantity_id: productQuantityId,
        service_provider_product_id: serviceProviderProductId,
      },
      {
        headers: {
          authtoken: localStorage.getItem("authtoken"),
        },
      }
    );
    // console.log(productQuantityId,serviceProviderProductId);
  }

  OfflineBillGeneration(
    spId,
    amount,
    incoming,
    outgoing,
    pending,
    completed,
    cancelled,
    customerName,
    customerPhone,
    description,
    gstnumber,
    date,
    no_due
  ) {
    let payload={
      sp_id: spId,
      amount: amount,
      incoming: incoming,
      outgoing: outgoing,
      pending: pending,
      completed: completed,
      customer_name: customerName,
      customer_phone: customerPhone,
      description: description,
      modified_date: date,
    }
    if(gstnumber !== ""){
      payload["gst_number"]=gstnumber
    }
    if(no_due == true){
      payload["no_due"]=1
    }
    return axios.post(
      API_BASE_URL + "/v1/api/offlinebillgeneratewithdiscription",
      payload,
      {
        headers: {
          authtoken: localStorage.getItem("authtoken"),
        },
      }
    );
    // console.log(spId,amount,incoming,outgoing,pending,completed,cancelled,customerName,customerPhone,description,date);
  }

  ServiceProviderAddMyProduct(
    serviceprovider_id,
    amount,
    description,
    tagline
  ) {
    return axios.post(
      API_BASE_URL + "/v1/api/generateTransaction",
      { serviceprovider_id, amount, description, tagline },
      {
        headers: {
          authtoken: localStorage.getItem("authtoken"),
        },
      }
    );
  }

  DeleteMyProduct(trans_id) {
    return axios.post(
      API_BASE_URL + "/v1/api/deleteTrans",
      { trans_id },
      {
        headers: {
          authtoken: localStorage.getItem("authtoken"),
        },
      }
    );
  }
  EditMyProduct(index, amount, description, tagline) {
    return axios.post(
      API_BASE_URL + "/v1/api/updatetrans/" + index,
      { amount, description, tagline },
      {
        headers: {
          authtoken: localStorage.getItem("authtoken"),
        },
      }
    );
  }
  DeleteServiceProviderAccount() {
    return axios.get(API_BASE_URL + "/v1/api/deleteServiceprovider", {
      headers: {
        authtoken: localStorage.getItem("authtoken"),
      },
    });
  }

  MyJobPostService(index) {
    return axios.get(API_BASE_URL + "/v1/api/MyJobPost/" + index, {
      headers: {
        authtoken: localStorage.getItem("authtoken"),
      },
    });
  }

  PostJobService(
    serviceprovider_id,
    Job_position,
    Salary,
    skills_required,
    Job_description
  ) {
    return axios.post(
      API_BASE_URL + "/v1/api/post_job",
      {
        serviceprovider_id,
        Job_position,
        Salary,
        skills_required,
        Job_description,
      },
      {
        headers: {
          authtoken: localStorage.getItem("authtoken"),
        },
      }
    );
  }

  NearByJobProfiles(serviceprovider_id) {
    return axios.post(
      API_BASE_URL + "/v1/api/NearBy_Job_Profiles",
      { serviceprovider_id },
      {
        headers: {
          authtoken: localStorage.getItem("authtoken"),
        },
      }
    );
  }

  RegisterProduct(
    service,
    subService,
    product_type,
    brand,
    product_name,
long_description,
short_description,
variants
  ) {
    const payload = {
      service,
      subService,
      product_type,
      brand,
      product_name,
      long_description,
      short_description,
   variants
    };

    return axios.post(API_BASE_URL + "/v1/api/Register_sp_Product", payload, {
      headers: {
        authtoken: localStorage.getItem("authtoken"),
      },
    });
  }

  UploadImageOGC(files, detail) {
    const fd = new FormData();
    fd.append("files", files, "/path/to/file");
    return axios.post(API_BASE_URL + "/v1/api/uploadImagesOGC", fd, {
      headers: {
        authtoken: localStorage.getItem("authtoken"),
      },
    });
  }

  Sellerregister(
    email,
    description,
    name,
    mobile,
    whatsappnumber,
    serviceabledistance,
    hasdynamiclocaion,
    serviceprovider_type_id,
    service_id,
    image,
    subservice_id,
    visible,
    multipleoutlet,
    multipleservice,
    discount,
    gst_number,
    address
  ) {
    const payload = {
      email,
      description,
      name,
      mobile,
      whatsappnumber,
      serviceabledistance,
      hasdynamiclocaion,
      serviceprovider_type_id,
      service_id,
      subservice_id,
      visible,
      multipleoutlet,
      multipleservice,
      discount,
      gst_number,
      address,
      extra_phn: [],
      totalrating: 0,
      totalrating_user: 0,
    };

    if (image !== null) {
      payload.image = image;
    }
    return axios.post(API_BASE_URL + "/v1/api/registerseller", payload, {
      headers: {
        authtoken: localStorage.getItem("authtoken"),
      },
    });
  }

  SellerDetails() {
    return axios.get(API_BASE_URL + "/v1/api/SellerDetails", {
      headers: {
        authtoken: localStorage.getItem("authtoken"),
      },
    });
  }

  fetchProductTranscationlist(seller_id) {
    return axios.get(API_BASE_URL + "/v1/api/AllProductTypes/" + seller_id, {
      headers: {
        authtoken: localStorage.getItem("authtoken"),
      },
    });
  }

  getNamebyphone() {
    return axios.get(API_BASE_URL + "/v1/api/findname", {
      headers: {
        authtoken: localStorage.getItem("authtoken"),
      },
    });
  }

  ModifySellerDetails(details,isUpperLevelProviderChanged) {
  const  {
      email,
      description,
      name,
      mobile,
      whatsappnumber,
      serviceabledistance,
      hasdynamiclocaion,
      service_id,
      image,
      subservice_id,
      visible,
      multipleoutlet,
      multipleservice,
      discount,
      address,
      username,
      is_upper_level_provider
    }=details
    console.log(is_upper_level_provider,isUpperLevelProviderChanged);
    // Create the payload object
    const payload = {
      email,
      description,
      name,
      mobile: `+91${mobile}`,
      whatsappnumber: `+91${whatsappnumber}`,
      serviceabledistance,
      hasdynamiclocaion,
      service_id,
      image,
      subservice_id,
      visible,
      multipleoutlet,
      multipleservice:parseInt(multipleservice),
      discount,
      address,
      extra_phn: [],
      totalrating: 0,
      totalrating_user: 0,

    };
    if(isUpperLevelProviderChanged){
      payload.is_upper_level_provider=parseInt(is_upper_level_provider)
    }

    // Conditionally add username to the payload if it's not empty
    if (username.trim() !== "") {
      payload.username = username;
    }

    // Make the Axios request
    return axios.post(API_BASE_URL + "/v1/api/modifySeller", payload, {
      headers: {
        authtoken: localStorage.getItem("authtoken"),
      },
    });
  }

  SearchProductApi(search_string, index = 0) {
    return axios.post(
      API_BASE_URL + `/v1/api/searchProduct/${index}`,
      {
        search_string,
      },
      {
        headers: {
          authtoken: localStorage.getItem("authtoken"),
        },
      }
    );
  }

  fetchAllserviceByusername(seller_name) {
    return axios.get(
      API_BASE_URL + "/v1/api/all_services_by_user/" + seller_name,
      {
        headers: {
          authtoken: localStorage.getItem("authtoken"),
        },
      }
    );
  }

  GetProductTypeotherdata(service_id, sub_service_id) {
    return axios.post(
      API_BASE_URL + "/v1/api/showcontent_new",
      {
        service_id: parseInt(service_id),
        sub_service_id: parseInt(sub_service_id),
      },
      {
        headers: {
          authtoken: localStorage.getItem("authtoken"),
        },
      }
    );
  }

  fetchusersfortrancation(sp_id, search) {
    const body = {
      sp_id,
    };
    console.log(search);
    if (search) {
      console.log(search);
      if (search.searchname !== "") {
        body.search_name = search.searchname;
      } else if (search.searchphone !== "") {
        body.search_phone = search.searchphone;
      }
      return axios.post(API_BASE_URL + "/v1/api/searchCustomer", body, {
        headers: {
          authtoken: localStorage.getItem("authtoken"),
        },
      });
    }
  }

  ShowOfflineIncomingamount(
    sp_id,
    start_date,
    end_date,
    selectedProductsummary
  ) {
    const requestData = {
      start_date,
      end_date,
    };
    console.log(selectedProductsummary);
    if (
      selectedProductsummary.prodtypeid &&
      selectedProductsummary.prodid &&
      selectedProductsummary.userid &&
      selectedProductsummary.quantityid
    ) {
      requestData.product_id = selectedProductsummary.prodid;
      requestData.product_type_id = selectedProductsummary.prodtypeid;
      requestData.user_id = selectedProductsummary.userid;
      requestData.qty_id = selectedProductsummary.quantityid;
    } else {
      if (selectedProductsummary.prodtypeid) {
        requestData.product_type_id = selectedProductsummary.prodtypeid;
      }

      if (selectedProductsummary.prodid) {
        requestData.product_id = selectedProductsummary.prodid;
      }
      if (selectedProductsummary.quantityid) {
        requestData.qty_id = selectedProductsummary.quantityid;
      }
      if (selectedProductsummary.userid) {
        requestData.user_id = selectedProductsummary.userid;
      }
    }

    return axios.post(
      API_BASE_URL + "/v1/api/ShowOfflineIncomingamount/" + sp_id,
      requestData,
      {
        headers: {
          authtoken: localStorage.getItem("authtoken"),
        },
      }
    );
  }

  ShowOfflineOutgoingamount(
    sp_id,
    start_date,
    end_date,
    selectedProductsummary
  ) {
    const requestData = {
      start_date,
      end_date,
    };
    console.log(selectedProductsummary);
    if (
      selectedProductsummary.prodtypeid &&
      selectedProductsummary.prodid &&
      selectedProductsummary.quantityid &&
      selectedProductsummary.userid
    ) {
      requestData.product_id = selectedProductsummary.prodid;
      requestData.product_type_id = selectedProductsummary.prodtypeid;
      requestData.user_id = selectedProductsummary.userid;
      requestData.qty_id = selectedProductsummary.quantityid;
    } else {
      if (selectedProductsummary.prodtypeid) {
        requestData.product_type_id = selectedProductsummary.prodtypeid;
      }

      if (selectedProductsummary.prodid) {
        requestData.product_id = selectedProductsummary.prodid;
      }
      if (selectedProductsummary.quantityid) {
        requestData.qty_id = selectedProductsummary.quantityid;
      }
      if (selectedProductsummary.userid) {
        requestData.user_id = selectedProductsummary.userid;
      }
    }

      return axios.post(
        API_BASE_URL + "/v1/api/ShowOfflineOutgoingamount/" + sp_id,
        requestData,
        {
          headers: {
            authtoken: localStorage.getItem("authtoken"),
          },
        }
      );
    }

    GetmyProductsfilter(search_string) {
      return axios.post(
        API_BASE_URL + '/v1/api/search_myproducts',
        { search_string },
        {
          headers: {
            authtoken: localStorage.getItem("authtoken"),
          },
        }
      );
    }


    Mergebill(customer_name,customer_phone,User_id) {
      const payload = { customer_phone, User_id };
      if (customer_name !== null && customer_name !== "") {
        payload.customer_name = customer_name;
      }
      return axios.post(
        API_BASE_URL + '/v1/api/Merge_bill',
          payload,
        {
          headers: {
            authtoken: localStorage.getItem("authtoken"),
          },
        }
      );
    }

    InvoicePdfOffline(order_id){
      return axios.post(
        API_BASE_URL + '/v1/api/ShowInvoiceOffline',
          {order_id},
        {
          headers: {
            authtoken: localStorage.getItem("authtoken"),
          },
        }
      );
    }
    InvoicePdfOnline(order_id){
      return axios.post(
        API_BASE_URL + '/v1/api/ShowInvoice',
          {order_id},
        {
          headers: {
            authtoken: localStorage.getItem("authtoken"),
          },
        }
      );
    }


  fetchAllservicecatlogdata(seller_name) {
    return axios.get(
      API_BASE_URL + "/v1/api/sp_subservice_producttype/" + seller_name,
      {
        headers: {
          authtoken: localStorage.getItem("authtoken"),
        },
      }
    );
  }

  fetchsearchofflinecustomers(payload){
    return axios.post(
      API_BASE_URL + "/v1/api/searchCustomer",payload,
      {
        headers: {
          authtoken: localStorage.getItem("authtoken"),
        },
      }
    )
  }
  fetchproducts(payload){
    return axios.post(
      API_BASE_URL + "/v1/api/Sp_product_name_by_sub_service",payload,
      {
        headers: {
          authtoken: localStorage.getItem("authtoken"),
        },
      }
    )
  }
  couponadd(payload){
    return axios.post(
      API_BASE_URL + "/v1/api/coupon_create_by_serviceprovider",payload,
      {
        headers: {
          authtoken: localStorage.getItem("authtoken"),
        },
      }
    )
  }

  selectedoutofstock(payload){
    return axios.post(
      API_BASE_URL + "/v1/api/sp_mark_all_qty_inout_stock",payload,
      {
        headers: {
          authtoken: localStorage.getItem("authtoken"),
        },
      }
    )
  }
deleteeditbill(payload){
  return axios.post(
    API_BASE_URL + "/v1/api/Edit_offline_bill_by_sp",payload,
    {
      headers: {
        authtoken: localStorage.getItem("authtoken"),
      },
    }
  )
}
  }

export default new PutatoeServiceApi();
