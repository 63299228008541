import React from "react";
import Loading from "./Loading";
export default function LoginModal(){
    return(
        // <div style={{position:'absolute',height:'100vh',width:'100vw',display:'flex',justifyContent:'center',alignItems:'center'}}>
        //     <Loading />
        // </div>
        <div style={{ position: 'absolute', height: '100vh', zIndex: 10000, background: 'rgba(0, 0, 0, 0.6)', width: '100%',display:'flex',justifyContent:'center',paddingTop:'50vh',color:'white','fontSize':'24px' }}>
           Logging in...
        </div>
        
    )
}