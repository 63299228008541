import React from "react";
import apiclient from "../api/apiclient";
import AllServicesTab from "./allServicesTab";
import PutatoeServiceApi from "../api/PutatoeServiceApi";
import { useGlobalContext } from '../context';
import '../css/allServices.css';
import { NavLink, useNavigate } from 'react-router-dom';
export default function AllService(props)
{
//   const [allService,setAllService]=React.useState([])
//  const fetchAllService=async()=>
//  {
//   const response = await apiclient.services();
//   //console.log(response.data.services_main_list);
//   setService(response.data.services_main_list);
//  }
//  React.useEffect(()=>
//  {
//   fetchAllService();
//  },[])
// console.log(props)

 const { allService,products ,subServicedata, setSubServicedata} = useGlobalContext();

//  console.log(allService)
//console.log(products[0].data.service_id);


  return ( 
    <React.Fragment>
         <div className='allservice-last-container'>
         {subServicedata && subServicedata.length > 0 && (
          <h1 className='allservice-last-heading'>All SubServices</h1>
        )}
        {subServicedata && subServicedata.length > 0 && (
          <div className='allservice-last'>
            {subServicedata.map((item) => (
              <AllServicesTab
                key={item.id}
                id={item.id}
                logo={item.logo}
                name={item.sub_service}
              />
            ))}
          </div>
        )}
      </div>
    </React.Fragment>
  );
}