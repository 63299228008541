import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
// let cancelTokenSource;

const json = {
  subservice_id: null,
  service_id: 56,
};

class UserService2 {
  fetchfavoriteService() {
    return axios.get(API_BASE_URL + '/v1/api/favouriteservice', {
      headers: {
        authtoken: localStorage.getItem('authtoken'),
        'Content-Type': 'application/json',
      },
    });
  }
  addFavservice(servicename) {
    return axios.post(API_BASE_URL + '/v1/api/Addfavouriteservice',
      { [servicename]: 1 }, {
      headers: {
        authtoken: localStorage.getItem('authtoken'),
        'Content-Type': 'application/json',
      },
    });
  }
  subgetSmallBanners2() {
    const smallBanners = {
      subservice_id: null,
      service_id: 56,
    };
    return axios.post(API_BASE_URL + '/v1/api/getbannerfalse', smallBanners, {
      headers: {
        authtoken: localStorage.getItem('authtoken'),
        'Content-Type': 'application/json',
      },
    });
  }
  popularproductByService(url) {
    return axios.get(API_BASE_URL + '/v1/api/homepage_prodcuct_new_api/' + url, {
      headers: {
        authtoken: localStorage.getItem('authtoken'),
        'Content-Type': 'application/json',
      },
    });
  }


  services() {
    return axios.get(API_BASE_URL + '/v1/api/homepage_services_list', {
      headers: {
        authtoken: localStorage.getItem('authtoken'),
        'Content-Type': 'application/json',
      },
    });
  }

  Agencyservices(providerId) {
    return axios.get(API_BASE_URL + '/v1/api/Services_for_Serviceprovider/' + providerId, {
      headers: {
        authtoken: localStorage.getItem('authtoken'),
        'Content-Type': 'application/json',
      },
    });
  }

  AgencyProducts(sp_id, service_id) {
    return axios.post(API_BASE_URL + '/v1/api/product_for_serviceprovider', { sp_id, service_id }, {
      headers: {
        authtoken: localStorage.getItem('authtoken'),
        'Content-Type': 'application/json',
      },
    });
  }
  product_for_serviceprovider

  wideBanners(service_id) {
    return axios.post(API_BASE_URL + '/v1/api/getbannertrue',
      {
        service_id,
        subservice_id: null,
      }, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
  UserLocation(param) {
    return axios.post(API_BASE_URL + '/v1/api/userlocation', param, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
  UnReadNotification() {
    return axios.get(API_BASE_URL + '/v1/api/UnreadNotifications', {
      headers: {
        authtoken: localStorage.getItem('authtoken'),
      },
    });
  }
  ShowNotification(index = 0) {
    return axios.get(API_BASE_URL + `/v1/api/ShowNotification_new/${index}`, {
      headers: {
        authtoken: localStorage.getItem('authtoken'),
      },
    });
  }
  SearchPridictionApi(keyword) {
    return axios.get(API_BASE_URL + '/v1/api/search_prediction/' + keyword, {
      headers: {
        authtoken: localStorage.getItem('authtoken'),
        'Content-Type': 'application/json',
      },
    });
  }
  IsServiceProviderApi(usernameSearched) {
    return axios.get(
      API_BASE_URL + '/v1/api/user_isServiceProvider/' + usernameSearched,
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
          'Content-Type': 'application/json',
        },
      }
    );
  }
  ServicesByUserApi() {
    return axios.get(API_BASE_URL + '/v1/api/all_services_by_user/', {
      headers: {
        authtoken: localStorage.getItem('authtoken'),
        'Content-Type': 'application/json',
      },
    });
  }
  PutatoeServiceProductsApi(index, service_id) {
    return axios.post(
      API_BASE_URL + '/v1/api/service_product/' + index,
      { service_id },
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
    // .then((resp)=>
    // {
    //   console.log(resp)
    // })
  }

  SellerProducts(
    service_provider_id,
    service_id,
    sub_service_id,
    price_greater_than,
    price_less_than,
    brand_id,
    product_type_id,
    quantity_id,
    index
  ) {
    return axios.post(
      API_BASE_URL + '/v1/api/service_provider_filter/' + index,
      {
        service_id,
        sub_service_id,
        service_provider_id,
        sort_by: 'rating',
        rating: null,
        price_greater_than,
        price_less_than,
        brand_id,
        product_type_id,
        quantity_id,
      },
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }

  SellerProductsFilter(
    sp_id,
    service_id,
    sub_service_id,
    price_greater_than,
    price_less_than,
    brand_id,
    product_type_id,
    quantity_id,
    index,
    color_code
  ) {
    console.log(sp_id,
      service_id,
      sub_service_id,
      price_greater_than,
      price_less_than,
      brand_id,
      product_type_id,
      quantity_id,
      index);
    const requestData = {
      service_id,
      sub_service_id,
      sp_id: [parseInt(sp_id)],
      sort_by: 'rating',
      rating: null,
      brand_id,
      product_type_id,
      quantity_id,
    };
    if (price_greater_than !== null && price_less_than !== null) {
      console.log("null");
      requestData.price_greater_than = price_greater_than;
      requestData.price_less_than = price_less_than;
    }


    if (color_code !== null && color_code !== undefined) {
      requestData.color_code = color_code;
    }
    return axios.post(
      API_BASE_URL + '/v1/api/ProductAccordingToSubServiceFilter_withcolor/' + index,
      requestData,
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }

  RateServiceProvider(serviceprovider_id, rating, comment) {
    return axios.post(
      API_BASE_URL + '/v1/api/rating_serviceprovider',
      { serviceprovider_id, rating, comment },
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }

  AddToCart(
    product_id,
    quantity_id,
    coupon_id,
    quantity,
    service,
    service_provider_product_id,
    color_id
  ) {
    const requestData = {
      product_id,
      quantity_id,
      coupon_id,
      quantity,
      service,
      service_provider_product_id,
    };
    if (color_id !== null) {

      requestData.color_id = color_id
    }
    return axios.post(
      API_BASE_URL + '/v1/api/add_to_cart',
      requestData,
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }
  ProductDetails(serviceprovider_product_id, service_id) {
    return axios.post(API_BASE_URL + '/v1/api/GetProduct_new_withcolor', {
      serviceprovider_product_id: Math.abs(serviceprovider_product_id),
      service_id,
    });
  }
  AddServiceProviderToFav(serviceprovider_id) {
    return axios.post(
      API_BASE_URL + '/v1/api/add_to_fav',
      {
        serviceprovider_id,
      },
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }

  ContactUs(name, email, mobile, msg) {
    return axios.post(
      API_BASE_URL + '/v1/api/contactus',
      {
        name,
        email,
        mobile,
        msg,
      },
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }

  UserSellerReview(providerId, rating, msg) {
    return axios.post(
      API_BASE_URL + '/v1/api/rating_serviceprovider',
      {
        serviceprovider_id: parseInt(providerId),
        rating,
        comment: msg
      },
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }

  Checkfavserviceseller(providerId) {
    return axios.post(
      API_BASE_URL + '/v1/api/check_for_fav',
      {
        'serviceprovider_id': providerId
      },
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }

  Deletesellerfromfavlist(providerId) {
    return axios.get(
      API_BASE_URL + '/v1/api/delete_from_fav/' + providerId,
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
          'Content-Type': 'application/json',
        },
      }
    );
  }

  getproductImage(providerId, service_id, color_id = undefined) {
    let requestobj = {
      'serviceprovider_product_id': providerId,
      'service_id': service_id,
    }
    if (color_id) {
      requestobj["color_id"] = color_id;
    }
    return axios.post(
      API_BASE_URL + '/v1/api/GetProduct_image',
      requestobj,
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }

  BlockProvider(providerId) {
    return axios.get(
      API_BASE_URL + '/v1/api/block_serviceProvider/' + providerId,
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
          'Content-Type': 'application/json',
        },
      }
    );
  }

}

export default new UserService2();