import React from 'react';
import '../css/allServicesTab.css';
import { useNavigate } from 'react-router-dom';
export default function AllServicesTab(props) {
  // console.log(props.subcategory)
  const navigate = useNavigate();
  function move() {
    // navigate(`/potatoeProduct/${props.id}/${props.name}`);
    navigate(`/Sub_serviceProduct/${props.id}/${props.name}`);
  }

  return (
    <React.Fragment>
      <div className='allservicetab-container-desktop' onClick={move}>
        <div className='allservicetab-desktop-text'>
          <h1 className='allservicetab-heading-desktop'>{props.name}</h1>
          {/* <div className='allservicetab-heading-list'>
            {props.subcategory &&
              props.subcategory.map((item) => {
                return (
                  <a className='allservicetab-list'>
                    <span>{item.name}</span>
                  </a>
                );
              })}
          </div> */}
        </div>
        <div className='allservicetab-image-container'>
          <img className='allservicetab-image' src={props.logo} alt=''></img>
        </div>
      </div>
    </React.Fragment>
  );
}
