import React, { useState, useEffect } from 'react'
import Adminlogin from './adminpanel/components/Adminlogin';
import Dashboard from "./adminpanel/components/dashboard"
import { BrowserRouter as Router, Routes, Route,Navigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import Adminmyprofile from './adminpanel/components/Adminmyprofile';

const Adminpanel = () => {
    // State to track the authentication status
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    // Check if the user is already logged 
  useEffect(() => {
    const authToken = localStorage.getItem('AdminToken');
    if (authToken) {
      setIsLoggedIn(true);
    }
  }, []);
  return (
    <React.Fragment>
      <div className="App" >
        <Router>
        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
        <Routes>
        <Route
              path="/admin"
              element={
                isLoggedIn ? (
                  // If logged in, navigate to the Dashboard
                  <Navigate to="/admin/dashboard" />
                ) : (
                  // If not logged in, show the login page
                  <Adminlogin setIsLoggedIn={setIsLoggedIn} />
                )
              }
            />
            <Route
              path="/admin/dashboard"
              element={
                isLoggedIn ? (
                  // If logged in, show the dashboard
                  <Dashboard setIsLoggedIn={setIsLoggedIn} isLoggedIn={isLoggedIn} />
                ) : (
                  // If not logged in, redirect to the login page
                  <Navigate to="/admin" />
                )
              }
            />
            <Route
              path="/admin/myprofile"
              element={
                isLoggedIn ? (
                  // If logged in, show the dashboard
                  <Adminmyprofile  setIsLoggedIn={setIsLoggedIn} isLoggedIn={isLoggedIn} />
                ) : (
                  // If not logged in, redirect to the login page
                  <Navigate to="/admin" />
                )
              }
/>
          </Routes>
        </Router>
        </div>
        </React.Fragment>
  )
}

export default Adminpanel