import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

class WalletService {
  MyWalletTransactionHistory() {
    return axios.get(API_BASE_URL + `/v1/api/transhistory`, {
      headers: {
        authtoken: localStorage.getItem('authtoken'),
        'Content-Type': 'application/json',
      },
    });
  }

  WalletTransferServiceWithPhone(amount, phone) {
    return axios.post(
      API_BASE_URL + '/v1/api/walletTransfer',
      {
        amount,
        phone,
      },
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }
  WalletTransferServiceWithUsername(amount, username) {
    return axios.post(
      API_BASE_URL + '/v1/api/walletTransfer',
      {
        amount,
        username,
      },
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }

  CheckIfAdHAAREXIST() {
    return axios.get(API_BASE_URL + `/v1/api/checkAadharexist`, {
      headers: {
        authtoken: localStorage.getItem('authtoken'),
        'Content-Type': 'application/json',
      },
    });
  }

  ADDADHAR(aadhaar_no) {
    return axios.post(
      API_BASE_URL + '/v1/api/Addaadhaar',
      {
        aadhaar_no,
      },
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }
  ConvertRewardToMoney() {
    return axios.get(API_BASE_URL + `/v1/api/convertreward`, {
      headers: {
        authtoken: localStorage.getItem('authtoken'),
        'Content-Type': 'application/json',
      },
    });
  }
  RazorAddMoney(amount) {
    return axios.post(
      API_BASE_URL + '/v1/api/RazorAddWallet',
      {
        amount,
      },
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }
  RazorPayWalletVerify(
    amount,
    putatoe_order_id,
    razorpay_order_id,
    razorpay_payment_id,
    razorpay_signature
  ) {
    return axios.post(
      API_BASE_URL + '/v1/api/VerifyRazorWallet',
      {
        amount,
        putatoe_order_id,
        razorpay_order_id,
        razorpay_payment_id,
        razorpay_signature,
      },
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }
}
export default new WalletService();