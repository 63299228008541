import React from "react";
import postapi from "../api/postapi";
import "../post-css/comment.css";
import profileImage from "../images/DTh/account.png";
import more from "../images/blogimages/more.svg";
import notify from "../error";

export default function Comment(props) {
  // const { } = usePostContext();
  const [display, setDisplay] = React.useState(false);
  const [edit, setedit] = React.useState(false);
  const [editData, seteditData] = React.useState();
  const handleClick = () => {
    setDisplay(!display);
  };

  const handleEdit = () => {
    setDisplay(!display);
    setedit(true);
  };

  const handleDelete = async () => {
    try {
      const response = await postapi.DeleteCommentPost(props.data.id);
      if (response.status === 200)
        notify("success", "Comment deleted succssfully...");
      setDisplay(!display);
      props.changes();
    } catch (err) {
      console.log(err);
    }
  };

  const handleInput = (e) => {
    seteditData(e.target.value);
  };

  async function HandleSubmit(e) {
    if (e.key === "Enter") {
      try {
        const response = await postapi.EditCommentPost(props.data.id, editData);
        if (response.status === 200)
          notify("success", "Comment changed succssfully...");
        setedit(false);
        props.changes();
      } catch (err) {
        console.log(err);
      }
    }
  }
  {console.log(props)}
  return (
    <React.Fragment>
      <div className="post-comment-box">
        <div className="post-comment-box-image-container">
          <img src={props.data.profile_image || profileImage} alt="" />
        </div>
        <div className="post-comment-box-details">
          <div>{props.data.Comment_name || "Unkown User"}</div>
          {edit ? (
            <section>
              <input
                type="text"
                placeholder={props.data.comment}
                onChange={handleInput}
                value={editData}
                onKeyDown={HandleSubmit}
              ></input>
            </section>
          ) : (
            <section>{props.data.comment}</section>
          )}
          <span>{props.data.datatime}</span>
        </div>
        <div className="post-comment-box-dots" onClick={handleClick}>
          <img src={more} alt="" />
          {display && (
            <section className="post-comment-box-dots-modal">
              <span
                class="editclick"
                // style={{ margin: "10px" }}
                onClick={handleEdit}
              >
                Edit
              </span>
              <span className="deleteclick" onClick={handleDelete}>
                Delete
              </span>
            </section>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
