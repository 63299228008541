import React, { Component } from "react";
import myProfileServiceApi from "../../api/myProfileServiceApi";
import notify from "../../error";
import { useGlobalContext } from "../../context";
import "./css/AllOfflineCustomer.css";
import prof from "../../images/logo.png"
import back from "../myprofileIcoons/bx-arrow-back..svg";
import Loading from "../../components/Loading"
import { motion } from "framer-motion";
import axios, { all } from "axios";

export default function AllOflineCustomers(props) {
  // const [modifiedData, setModifiedData] = React.useState([])
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [allCustomer, setAllCustomer] = React.useState();
  const { Userprofile, setInnerPage } = useGlobalContext();
  const [page, setPage] = React.useState(1);
  const [showConfirmation, setShowConfirmation] = React.useState(false);
  const [conflictusername,setConflictusername]=React.useState(''); // if same mobile number then show user name of that user while merging the bill
  const [isLoading, setIsLoading] = React.useState(false);
  const [editId, setEditId] = React.useState({});
  const [filtersCustomers, setFiltersCustomers] = React.useState([]);
  const [checkamountstatus, setCheckamountstatus] = React.useState({
    hasAdvanceData: false,
    hasOutstandingData: false,
    hasnodues: false
  });


  const [sortingOption, setSortingOption] = React.useState(0); // Default sorting option
  const sp_id = Userprofile.service_provider_id;
  const [formData, setFormData] = React.useState({
    Id: 0,
    Name: "",
    MobileNumber: "",
  });
  console.log("editId", editId);
  console.log("allCustomer", allCustomer);
  let startIndex = 0;
  const getOnlineCustomer = async (sortingOption, startIndex, prevCustomers = []) => {
    console.log(sortingOption);
    setIsLoading(true);
    const response = await myProfileServiceApi.AllOfflineCustomers(
      Userprofile.service_provider_id, sortingOption, startIndex
    );
    console.log(response);
    if (response.data && response.data.error != "") {
      notify("error", response.data.error);
    } else {
      const customers = response.data.customer_list;

      if (response.data.customer_list.length === 0) {
        // If the response is empty, stop recursive calls and set the final state
        setAllCustomer(prevCustomers);
        setIsLoading(false);
      } else {
        console.log(customers);
        if (customers.length === 0) {
          // If the response is empty, return the accumulated customers
          return [...prevCustomers, ...customers];
        }

        // Combine the current batch of customers with the previous ones
        const updatedCustomers = [...prevCustomers, ...customers];

        // Make another recursive call to fetch the next batch of customers
        return getOnlineCustomer(sortingOption, startIndex + 18, updatedCustomers);
      }
    }
  };

  const handleSortChange = (e) => {
    const selectedSortOption = parseInt(e.target.value);
    console.log(selectedSortOption);
    setSortingOption(selectedSortOption);
  };

  function handleChange1(event) {
    console.log(event);
    const { name, value, type, checked } = event.target;
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: type === "checkbox" ? checked : value,
      };
    });
    console.log(formData);
  }

  React.useEffect(() => {
    getOnlineCustomer(sortingOption, startIndex);
  }, [sortingOption]);


  React.useEffect(() => {
    setFormData((prev) => {
      return {
        Id: editId.user_id,
        Name: editId.name || editId.user,
        MobileNumber: editId.phone,
      };
    });
  }, [editId]);

  const goBack = () => {
    props.return();
    setInnerPage(false);
  };


  const [searchuser, setSearchuser] = React.useState({
    searchname: "",
    searchphone: ""
  });

  const EditOfflineCustomer = async () => {
    // Find the customer with the given customerId
    const customer = allCustomer.find(
      (cust) => cust.user_id === formData.Id
    );

    if (customer) {
      console.log(customer);

      let changedFields = {};
      console.log(formData.Name, customer.name);
      if (formData.Name !== customer.name) {
        changedFields.customer_name = formData.Name;
      }
      if (formData.MobileNumber !== customer.phone) {
        changedFields.customer_phone = formData.MobileNumber;
      }

      // Check if any fields have changed
      if (Object.keys(changedFields).length === 0) {
        console.log("No changes detected.");
        console.log("Changed fields:", changedFields);
        return; // No changes, so don't make the API call
      }

      // Send a POST request to update the data
      const updateResponse = await axios.post(
        API_BASE_URL + "/v1/api/changeNumber",
        {
          User_id: customer.user_id,
          ...changedFields
        },
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authtoken: localStorage.getItem('authtoken'),
          },

        }
      );

      if (updateResponse.data && updateResponse.data.error != '') {
        if (updateResponse.data.error === "Number Already Exist") {
          notify("error", updateResponse.data.error);
          if(updateResponse.data.exist_customer){
            setConflictusername(updateResponse.data.exist_customer);

          }
          setShowConfirmation(true);
        } else {
          notify("error", updateResponse.data.error);
        }
      } else {

        console.log(updateResponse.data);
        getOnlineCustomer(sortingOption, startIndex);
        notify('success', "edited successfully");
        setFormData({})
        showConfirmation(false);
        setPage(1);
      }


    }
  }

  const mergebill = async () => {
    const { Id, Name, MobileNumber } = formData;
    const customer = allCustomer.find(
      (cust) => cust.user_id === formData.Id
    );

    let changedFields = {};
    console.log(formData.Name, customer.name);
    if (formData.Name !== customer.name) {
      changedFields.customer_name = formData.Name;
    }
    
    // Check if the Name field has changed
    if (Object.keys(changedFields).length === 0) {
      console.log("Name is not changed.");
      console.log("Changed fields:", changedFields);
      // Don't send the Name parameter in the API call
      const response = await myProfileServiceApi.Mergebill(
        null,
        MobileNumber,
        Id,
      );
      console.log(response);
      if (response.data && response.data.error !== "")
       {
        notify("error", response.data.error);
      } else 
       {
        getOnlineCustomer(sortingOption, startIndex);
        notify("success", "Edited successfully");
        setFormData({});
        setPage(1);
      }
    } else {
      // Name 
      const response = await myProfileServiceApi.Mergebill(
        Name, MobileNumber, Id,
      );
      console.log(response);
      if (response.data && response.data.error != "") {
        notify("error", response.data.error);
      } else {
        getOnlineCustomer(sortingOption, startIndex);
        notify('success', "edited successfully");
        setFormData({})
        setPage(1);
      }
    }
  }
    const handleSearchuserChange = async (e) => {

      const input = e.target.value;
      console.log(input);

      if (!input) {
        setSearchuser({
          searchname: "",
          searchphone: ""
        });
        setFiltersCustomers([]);
        return;
      } else if (isNaN(input)) {
        setSearchuser({
          searchname: input,
          searchphone: "",
        });
      } else if (input) {
        setSearchuser(prev => ({
          searchname: "",
          searchphone: input
        }));
      }



    };


    React.useEffect(() => {
      let searchTimeout;

      const fetchcustomer = async () => {

        if (
          searchuser &&
          (searchuser.searchname !== "" || searchuser.searchphone !== "")
        ) {
          setIsLoading(true);
          const response = await myProfileServiceApi.fetchusersfortrancation(
            Userprofile.service_provider_id,
            searchuser
          );
          console.log(response);
          // Check if the "advanced_list" has data
          if (response.data.advanced_list.length > 0) {
            setCheckamountstatus({ ...checkamountstatus, hasAdvanceData: true })
            setFiltersCustomers(response.data.advanced_list);
          } else if (response.data.outstanding_list.length > 0) {
            setCheckamountstatus({ ...checkamountstatus, hasOutstandingData: true })
            setFiltersCustomers(response.data.outstanding_list);
          } else if (response.data.simple_list.length > 0) {
            setCheckamountstatus({ ...checkamountstatus, hasnodues: true })
            setFiltersCustomers(response.data.simple_list)
          } else {
            setFiltersCustomers([]);
          }

          setIsLoading(false);
          return;
        } else {
          setSearchuser({
            searchname: "",
            searchphone: "",
          });
        }
        return () => {
          if (searchTimeout) {
            clearTimeout(searchTimeout);
          }
        };
      };
      // Check if both fields are empty or cleared
      if (!searchuser.searchname && !searchuser.searchphone) {

        return;
      }
      // Set a new timeout for making the API call
      searchTimeout = setTimeout(() => {
        if (searchuser.searchname || searchuser.searchphone) {
          fetchcustomer();
        }
      }, 1000);

      // Clear the timeout when the component unmounts
      return () => {
        if (searchTimeout) {
          clearTimeout(searchTimeout);
        }
      };
    }, [searchuser.searchname, searchuser.searchphone]);
    return (
      <React.Fragment>
        <>
          {page === 1 && (
            <div className="my-service-profile-all-online-customer">
              <div className="my-service-profile-ongoing-and-pending-online-back">
                <img src={back} onClick={goBack} alt="" />
              </div>
              <div class="add-service-provider-profile-right-inputs-2 add-service-provider-profile-right-inputs-mob">
                <input
                  type="text"
                  name="customer"
                  placeholder="Search Your Customer By Name or Number"
                  value={searchuser.name || searchuser.phone}
                  onChange={handleSearchuserChange}
                />
                <div className="my-order-profile-time-filter-calander-3">
                  <span>Sort By : </span>
                  <select onChange={handleSortChange} name="editFrom">
                    <option value={0}>Customer Filter</option>
                    <option value={1}>Ascending</option>
                    <option value={2}>Descending</option>
                    <option value={3}>Alphabatically</option>

                  </select>
                </div>
              </div>

              <div className="my-service-profile-all-online-customer-lower-box">
                {isLoading ? (<><Loading /></>) :
                  searchuser && (searchuser.searchname !== "" || searchuser.searchphone) ? (
                    filtersCustomers.length > 0 ? (
                      filtersCustomers.map((item) => (
                        <>

                          <div className="my-service-profile-all-online-customer-lower-box-box mob-offline ">
                            <div className="my-service-profile-all-offline-customer-lower-box-image">
                              {item.profile_image ? <img src={item.profile_image}></img> : <img src={prof}></img>}
                            </div>
                            <div className="my-service-profile-all-offline-customer-lower-box-box-bottom-section">
                              <div className="my-service-profile-all-offline-customer-lower-box-user">
                                {item.user}
                              </div>
                              <div className="my-service-profile-all-offline-customer-lower-box-phone">
                                {item.phone}
                              </div>
                              <div className="my-service-profile-all-offline-customer-lower-box-button">
                                <button
                                  onClick={() => {
                                    setPage(2);
                                    setEditId(item);
                                  }}
                                >
                                  Modify
                                </button>

                                <div className="my-service-profile-all-offline-customer-lower-box-price">
                                  {checkamountstatus.hasAdvanceData && (
                                    <article style={{ background: "rgb(26 173 12)" }}>
                                      <span>Advance</span>
                                      <span>&#8377; {item.amount}</span>
                                    </article>
                                  )}
                                  {checkamountstatus.hasOutstandingData && (
                                    <article style={{ background: "red" }}>
                                      <span>Dues</span>
                                      <span>&#8377; {item.amount}</span>
                                    </article>
                                  )}
                                  {checkamountstatus.hasnodues && (
                                    <article>
                                      <span>No Dues</span>
                                      <span>&#8377; {item.amount}</span>
                                    </article>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ))
                    ) : (
                      <div className="no-products-message">
                        No Customers found for the search query.
                      </div>
                    )
                  ) : allCustomer && allCustomer.length > 0 ? (allCustomer.map((item) => (
                    <>
                      <div className="my-service-profile-all-online-customer-lower-box-box mob-offline ">
                        <div className="my-service-profile-all-offline-customer-lower-box-image">
                          {item.profile_image ? <img src={item.profile_image}></img> : <img src={prof}></img>}
                        </div>
                        <div className="my-service-profile-all-offline-customer-lower-box-box-bottom-section">
                          <div className="my-service-profile-all-offline-customer-lower-box-user">
                            {item.name}
                          </div>
                          <div className="my-service-profile-all-offline-customer-lower-box-phone">
                            {item.phone}
                          </div>
                          <div className="my-service-profile-all-offline-customer-lower-box-button">
                            <button
                              onClick={() => {
                                setPage(2);
                                setEditId(item);
                              }}
                            >
                              Modify
                            </button>

                            <div className="my-service-profile-all-offline-customer-lower-box-price">
                              {item.amount > 0 && (
                                <article      onClick={() => {
                                  props.setPage4(4);
                                  props.onUserIdChange(item.user_id,item.name);
                                }}
                                style={{ background: "rgb(26 173 12)" }}
                                >
                                  <span>Advance</span>
                                  <span>&#8377; {item.amount}</span>
                                </article>
                              )}
                              {item.amount < 0 && (
                                <article style={{ background: "red" }}      onClick={() => {
                                  props.setPage4(4);
                                  props.onUserIdChange(item.user_id,item.name);
                                }}>
                                  <span >Dues</span>
                                  <span     >&#8377; {-1 * item.amount}</span>
                                </article>
                              )}
                              {item.amount === 0 && (
                                <article      onClick={() => {
                                  props.setPage4(4);
                                  props.onUserIdChange(item.user_id,item.name);
                                }}>
                                  <span>No Dues</span>
                                  <span>&#8377; {item.amount}</span>
                                </article>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>

                  ))) : (
                    <div className="no-products-message">
                      No Customers
                    </div>
                  )

                }
              </div>
            </div>
          )}


          {page === 2 && (
            <div className="manage-services-profile-service-main-page">
              <div className="profile-wallet-main-page-heading profile-wallet-main-page-heading-left">
                <b className="my-profile-wallet-page-transfer-top-back">
                  <img
                    src={back}
                    onClick={() => {
                      setPage(1);
                    }}
                    className='bacckbutton'
                  ></img>
                </b>
                <span>Modify Offline Customer</span>
              </div>
              <div className="manage-myproducts-profile-service-main-page-inputs">
                <div className="manage-myproducts-profile-service-main-page-inputs-1">
                  <span>Name</span>
                  <input
                    type="text"
                    name="Name"
                    value={formData.Name}
                    onChange={handleChange1}
                    placeholder="Enter Customer Name"
                  ></input>
                </div>
                <div className="manage-myproducts-profile-service-main-page-inputs-1">
                  <span>Mobile Number</span>
                  <input
                    type="number"
                    name="MobileNumber"
                    value={formData.MobileNumber}
                    onChange={handleChange1}
                    placeholder="Enter Mobile Number"
                  ></input>
                </div>
              </div>
              <div className="manage-myproducts-profile-service-main-page-inputs-submit">
                <button
                  onClick={() => {

                    EditOfflineCustomer();
                  }}
                >
                  Submit
                </button>
              </div>
              {showConfirmation && (
                <ConfirmationModal
                conflictusername={conflictusername}
                  onCancel={() => setShowConfirmation(false)}
                  onConfirm={() => {
                    mergebill();

                  }}
                />
              )}
            </div>


          )}


        </>
      </React.Fragment>
    );
  };


  const ConfirmationModal = ({ onCancel, onConfirm,conflictusername,setConflictusername }) => {
    if (!conflictusername) {
      return null;
    }
    return (
      <div className="confirmation-modal">
        <div className="confirmation-modal-content">
          <h3>Are you sure you want to merge this Bill <span>{conflictusername} </span>?</h3>
          <div className="confirmation-modal-buttons">
            <button onClick={onConfirm}>Yes</button>
            <button onClick={onCancel}>No</button>
          </div>
        </div>
      </div>
    );
  };