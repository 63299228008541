import axios from 'axios';
//company
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

class PutatoeLoanApi {
  GetStates(country,auth) {
    return axios.get(
      'https://www.universal-tutorial.com/api/states/' + country,
      {
        headers: {
          Authorization: 'Bearer ' + auth,
          Accept: 'application/json',
        },
      }
    );
  }
  GetCitites(states,auth) {
    return axios.get(
      'https://www.universal-tutorial.com/api/cities/' + states,
      {
        headers: {
          Authorization: 'Bearer ' + auth,
          Accept: 'application/json',
        },
      }
    );
  }

  // GetAllCircle() {
  //   return axios.get(API_BASE_URL + '/v1/api/getCircles', {
  //     headers: {
  //       authtoken: localStorage.getItem('authtoken'),
  //     },
  //   });
  // }

  ApplyLoan(
    name,
    mobile,
    email,
    city,
    is_salaried,
    annual_income,
    loan_type,
    is_user,
    loan_amount,
  ) {
    return axios.post(
      API_BASE_URL + '/v1/api/applyloan',
      {
        name,
        mobile,
        email,
        city,
        is_salaried,
        annual_income,
        loan_type,
        is_user,
        loan_amount,
      },
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }

  GetAuth() {
    return axios.get('https://www.universal-tutorial.com/api/getaccesstoken/', {
      headers: {
        // authtoken: localStorage.getItem('authtoken'),
        Accept: 'application/json',
        'api-token':
          'e_A4xj9C60mrjRNVkl22g2HqrAcnZ7DgKDw_BM7yt5K2Ox-eNRc6buF6LaVRNDbTjSk',
        'user-email': 'viceke7523@pantabi.com',
      },
    });
  }
}

export default new PutatoeLoanApi();
