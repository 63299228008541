import React, { Component } from "react";
import "./css/myServiceMainPage.css";
import OngoingAndPendingOrder from "./OngoingandPendingOrder";
import CancelledAndCompletedOnline from "./CompletedAndPendingOnline";
import AllMyProducts from "./AllMyProducts";
import AllOnlineCustomers from "./AllOnlineCustomer";
import UserService from "../../api/api";
import back from "../myprofileIcoons/bx-arrow-back..svg";
import OfflineOrders from "./OfflineOrders";
import { allStates, getDistricts } from "../../stateDistrict";
import notify from "../../error";
import AllOflineCustomers from "./AllOfflineCustomers";
import OfflineCustomerTransactionDetail from "./OfflineCustomerTransactionDetail";
import OfflineIncomingAndOutgoingOrder from "./OfflineIncomingOutgoingOrders";
import ManageServices from "./ManageServices";
import locationImage from "../../images/location-2-svgrepo-com.svg";
import ManageProduct from "./ManageProduct";
import ManageMyProduct from "./ManageMyProducts";
import Managecoupon from "./Managecoupon";
import DeleteServiceProviderAccount from "./DeleteServiceProviderAccount";
import PostJobSerivce from "./PostJobServiceProvider";
import myProfileServiceApi from "../../api/myProfileServiceApi";
import api from "../../api/api";
import { useGlobalContext } from "../../context";
import MyBussinessAccount from "./MyBussinessAccount";
import AllProducts from "./AllProducts";
import GenerateBill from "./GenerateBill";
import Geocode from "react-geocode";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import FieldChange from "../components/FieldChange";

const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
Geocode.setApiKey(googleMapsApiKey);
function MyServiceMainPage() {
  const {
    Userprofile,
    changeInCart,
    setChangeInCart,
    setUserProfile,
    setInnerPage,
    Allsubserviceavialable,
    setAllsubserviceavialable,
    userServiceIDselected,
    setUserServiceIDselected,
    isNumberChange,
    setIsNumberChange,        isMobileView,
    setSendotp,
    isOtpForSellerRegisteration, setIsOtpForSellerRegisteration,
  } = useGlobalContext();

  const [page, setPage] = React.useState(1);
  const [page2, setPage2] = React.useState(0);
  const [page3, setPage3] = React.useState(1);
  const [page4, setPage4] = React.useState(1);
  const [fieldValid, setFieldValid] = React.useState(false);

  const [page5, setPage5] = React.useState(1);
  const [services, setServices] = React.useState([]);
  const [selectedService, setSelectedService] = React.useState(null);
  const [location, setLocation] = React.useState(null);
  const [showBusinessForm, setShowBusinessForm] = React.useState(false);
  const [reg_address, setreg_address] = React.useState({
    address_line1: "",
    address_line2: "",
    landmark: "",
    town: "",
    state: "Select State",
    district: "Select District",
    country: "India",
    pincode: "",
    latitude: "",
    longitude: "",
  });
  const [isAddingBusiness, setIsAddingBusiness] = React.useState(false);
  const [businessDetails, setBusinessDetails] = React.useState({
    businessEmail: "",
    businessName: "",
    businessMobileNo: "",
    businessWhatsapp: "",
    businessServicable: "",
    businessService: "Select Service",
    businessSubservice: "Select Subservice",
    businessproviderType: "",
    businessServiceId: "",
    businessSubserviceId: "",
    businessDiscount: "",
    businessImage: null,
    businessDescription: "",
    businessLocation: "0",
    businessDelivery: "0",
    businessMultipleService: "0",
    businessVisible: "0",
    businessOutlets: "0",
    businessWebsitename: "",
    businessGstnumber: ""
  });
  const [abc, setAbc] = React.useState();
  const [userId, setUserId] = React.useState(null); // for moving from customertranscation to incoming outgoing
  const [userName, setUserName] = React.useState(null);
  const [existingDate, setExistingDate] = React.useState('')
  
  const handleUserIdChange = (newUserId, user,existingDate) => {
    console.log(newUserId,user);
    setUserId(newUserId);
    setUserName(user);
    setExistingDate(existingDate)
  };
  const getProfileAfterLogin = async () => {
    const response2 = await api.ProfileApi();
    console.log(response2);
    setAbc(response2.data);
    if (response2.data.error === "") {
      // setLoginPage(0);
      setChangeInCart(!changeInCart);
      // notify('success', 'successfully ');
      notify("success", "Seller Added successfully");
      setShowBusinessForm(false);
    } else {
      notify(
        "error",
        "Some Error occured please RE-Login for Proper Functioning of App"
      );
    }
    // localStorage.setItem('user', response2.data);
    console.log(abc);
  };
  React.useEffect(() => {
    console.log(abc);
    if (abc) {
      setUserProfile(abc);
      console.log(abc);
      localStorage.setItem("UserProfile", JSON.stringify(abc));
    }
  }, [abc]);

  // for getting allsubservice list of provider
  React.useEffect(() => {
    const fetchData = async () => {
      const response = await myProfileServiceApi.fetchAllserviceByusername(
        Userprofile.username
      );
      console.log(response);

      if (response.data && response.data.error != "") {
        notify("error", response.data.error);
      } else {
        setAllsubserviceavialable(response.data.sub_service_provided);
        console.log(response.data);
      }
    };
    fetchData();
  }, []);

  const handleSubserviceChange = (event) => {
    const selectedId = event.target.value;
    const selectedName = event.target.options[event.target.selectedIndex].text;
    setUserServiceIDselected([selectedId, selectedName]);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    // let newValue = value;
    // if (name === "latitude" || name === "longitude") {
    //   newValue = parseFloat(value);
    // }

    setreg_address((prevreg_address) => ({
      ...prevreg_address,
      [name]: value,
    }));

    if (reg_address.address_line1 && reg_address.town && reg_address.state && reg_address.district && reg_address.pincode && reg_address.latitude && reg_address.longitude) {
      setFieldValid(false);
    } else {
      setFieldValid(true);
    }
  };
  
  const handleChange2 = (e) => {
    const { name, value, files } = e.target;
    console.log(name, value, files);

    if (name === "businessService") {
      // Find the selected service
      const selectedService = services.find(
        (service) => service.name === value
      );
      console.log(selectedService);

      // Update the selected service and sub-services in the businessDetails state
      setBusinessDetails((prevValues) => ({
        ...prevValues,
        businessService: selectedService?.name,
        businessServiceId: selectedService?.id,
      }));
      setSelectedService(selectedService);
    } else if (name === "businessSubservice") {
      // Find the selected sub-service within the selected service
      const selectedSubService = selectedService.sub_list.find(
        (subService) => subService.sub_service === value
      );
      console.log(selectedSubService);
      // Update the selected sub-service in the businessDetails state
      setBusinessDetails((prevValues) => ({
        ...prevValues,
        businessSubservice: selectedSubService?.sub_service,
        businessSubserviceId: selectedSubService?.id,
      }));
    } else if (name === "files") {
      // Handle file upload
      const uploadedFiles = files[0];
      console.log(uploadedFiles);

      myProfileServiceApi.UploadImageOGC(uploadedFiles).then((response) => {
        console.log(response.data);
        if (response.data.error !== "") {
          notify("error", response.data.error);
        } else {
          const imageUrl = response.data.url;
          console.log(imageUrl);

          // Update the file(s) in the businessDetails state
          setBusinessDetails((prevValues) => ({
            ...prevValues,
            businessImage: imageUrl,
          }));
        }
      });
    } else {
      // Update other business details inputs
      setBusinessDetails((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }
  };

  
  React.useEffect(() => {
    const fetchData = async () => {
      const response = await UserService.allservices();
      if (response.data.error !== "") {
        notify("error", response.data.error);
      } else {
        setServices(response.data.service_list);
      }
    };
if(!Userprofile.is_service_provider){
  fetchData();
}

  }, []);

  const handleSubmit = () => {
    if (reg_address.address_line1 === "") {

      setFieldValid(true);
      return;
    }
    if (reg_address.town === "") {

      setFieldValid(true);
      return;
    }
    if (reg_address.state==="" ||  reg_address.state === "Select State") {

      setFieldValid(true);
      return;
    }
    if (
      reg_address.district === "Select District" ||
      reg_address.district === ""
    ) {
      setFieldValid(true);
      return;
    }
    if (reg_address.pincode === "") {

      setFieldValid(true);
      return;
    } else if (reg_address.pincode.length !== 6) {
;
      setFieldValid(true);
      return;
    }
    if (reg_address.latitude === "") {
      notify("error", "Latitude is required");
      setFieldValid(true);
      return;
    }
    if (reg_address.longitude === "") {
      notify("error", "Longtitude is required");
      setFieldValid(true);
      return;
    }

    setShowBusinessForm(true); 
    console.log("Form submitted:", reg_address);
  };


  const [refreshPage, setRefreshPage] = React.useState(false);
  const [isRegisterHit, setIsRegisterHit] = React.useState(false);
  const addbusinessdata = () => {
    if (!businessDetails.businessEmail) {
      notify("error", "Email ID is required");
      return;
    } else if (!/\S+@\S+\.\S+/.test(businessDetails.businessEmail)) {
      notify("error", "Email ID is invalid");
      return;
    }
    if (!businessDetails.businessName) {
      notify("error", "Name is required");
      return;
    }

    if (!businessDetails.businessMobileNo) {
      notify("error", "Mobile number is required");
      return;
    }
    if (!businessDetails.businessWhatsapp) {
      notify("error", "Whatsapp number is required");
      return;
    } else if (!/^[0-9]{10}$/.test(businessDetails.businessMobileNo)) {
      notify("error", "Please enter a valid 10-digit contact number");
      return;
    } else if (!/^[0-9]{10}$/.test(businessDetails.businessWhatsapp)) {
      notify("error", "Please enter a valid 10-digit number");
      return;
    }
    if (!businessDetails.businessServicable) {
      notify("error", "Servicable distance is required");
      return;
    } else if (businessDetails.businessServicable <= 0) {
      notify("error", "Servicable distance must be greater than zero.");
      return;
    }
    if (
      businessDetails.businessService === undefined ||
      businessDetails.businessService === "Select Service"
    ) {
      notify("error", "Please select service");
      return;
    }
    if (
      businessDetails.businessService !== "Select Service" &&
      !businessDetails.businessSubservice
    ) {
      notify("error", "Please select Subservice");
      return;
    }
    if (!businessDetails.businessproviderType) {
      notify("error", "Please select Service Provider Type");
      return;
    }
    if (!businessDetails.businessDiscount) {
      notify("error", "Discount is required");
      return;
    } else if (
      businessDetails.businessDiscount < 0 ||
      businessDetails.businessDiscount > 100
    ) {
      notify("error", "Discount must be between 0 and 100");
      return;
    }
    if (!businessDetails.businessDescription) {
      notify("error", "Service description is required");
    }
    console.log("zentered Submit");
    setIsRegisterHit(true);
    setIsNumberChange((prevState)=> !prevState);    
  };

  
  

  React.useEffect(()=>{
    if(isOtpForSellerRegisteration){
      console.log("Entered ISOTPRegisterSeller");
      console.log(businessDetails);
      setIsAddingBusiness(true);
      myProfileServiceApi
        .Sellerregister(
          businessDetails.businessEmail,
          businessDetails.businessDescription,
          businessDetails.businessName,
          `+91${businessDetails.businessMobileNo}`,
          `+91${businessDetails.businessWhatsapp}`,
          businessDetails.businessServicable,
          businessDetails.businessLocation,
          parseInt(businessDetails.businessproviderType),
          parseInt(businessDetails.businessServiceId),
          businessDetails.businessImage,
          parseInt(businessDetails.businessSubserviceId),
          parseInt(businessDetails.businessVisible),
          parseInt(businessDetails.businessOutlets),
          parseInt(businessDetails.businessMultipleService),
          parseInt(businessDetails.businessDiscount),
          parseInt(businessDetails.businessGstnumber),
          reg_address
        )
        .then((response) => {
          console.log(response.data);
          if (response.data.error !== "") {
            notify("error", response.data.error);
            setIsAddingBusiness(false);
            setIsRegisterHit(false);
          } else {
            const myTimeout = setTimeout(() => {
              getProfileAfterLogin();
              setIsAddingBusiness(false); // Set isAddingBusiness to false after the timeout
            }, 5000);
          }
        })
  
        .catch((error) => {
          console.error(error);
          notify("error", "An error occurred while adding the seller details.");

        });
        setIsOtpForSellerRegisteration(false);
  setIsRegisterHit(false);
        setIsNumberChange(false);
        setSendotp(false);
    }
  },[isOtpForSellerRegisteration])
    // console.log(businessDetails);
    // setIsAddingBusiness(true);
    // myProfileServiceApi
    //   .Sellerregister(
    //     businessDetails.businessEmail,
    //     businessDetails.businessDescription,
    //     businessDetails.businessName,
    //     `+91${businessDetails.businessMobileNo}`,
    //     `+91${businessDetails.businessWhatsapp}`,
    //     businessDetails.businessServicable,
    //     businessDetails.businessLocation,
    //     parseInt(businessDetails.businessproviderType),
    //     parseInt(businessDetails.businessServiceId),
    //     businessDetails.businessImage,
    //     parseInt(businessDetails.businessSubserviceId),
    //     parseInt(businessDetails.businessVisible),
    //     parseInt(businessDetails.businessOutlets),
    //     parseInt(businessDetails.businessMultipleService),
    //     parseInt(businessDetails.businessDiscount),
    //     parseInt(businessDetails.businessGstnumber),
    //     reg_address
    //   )
    //   .then((response) => {
    //     console.log(response.data);
    //     if (response.data.error !== "") {
    //       notify("error", response.data.error);
    //     } else {
    //       const myTimeout = setTimeout(() => {
    //         getProfileAfterLogin();
    //         setIsAddingBusiness(false); // Set isAddingBusiness to false after the timeout
    //       }, 5000);
    //     }
    //   })

    //   .catch((error) => {
    //     console.error(error);
    //     notify("error", "An error occurred while adding the seller details.");
    //   });
   
    React.useEffect(()=>{
      if(     Userprofile &&
        !Userprofile.is_service_provider){
          console.log("geolocation");
      handleClick();
      }

    },[     Userprofile &&
      !Userprofile.is_service_provider])
    function hasDigit(str) {
      return /\d/.test(str);
  }

  const handleClick = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;

          try {
            // Retrieve address from coordinates
            const response = await Geocode.fromLatLng(latitude, longitude);
            const address = response.results[0].formatted_address;
            console.log(response.results);
            console.log(address);
            const address_formatted = address.split(",");
            console.log(address_formatted);
            const state_pincode_formatted =
              address_formatted[address_formatted.length - 2];
            const state_pincode_array = state_pincode_formatted.split(" ");

            setLocation({ lat: latitude, lng: longitude });
            setreg_address((prevFormData) => {
              return {
                ...prevFormData,
                address_line1: address_formatted[0],
                town: address_formatted[2],
                state: state_pincode_array[0],
                district: response.results[0].address_components[3].long_name,
                pincode:  hasDigit( state_pincode_array[1])?state_pincode_array[1]:"",
                latitude: latitude.toString(),
                longitude: longitude.toString(),
              };
            });
            if (reg_address.address_line1 && reg_address.town && reg_address.state && reg_address.district && reg_address.pincode && reg_address.latitude && reg_address.longitude) {
              setFieldValid(false);
            } else {
              setFieldValid(true);
            }
          } catch (error) {
            console.log("Error retrieving address:", error);
            notify("error", "Network error");
          }
        },
        (error) => {
          console.log("Geolocation error:", error);
          if(error.message===
            "User denied Geolocation")
          notify("error", "Please Allow Location");
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };
  const handleMarkerDragend = async (e) => {
    const { latLng } = e;
    console.log(e);
    const lat = latLng.lat();
    const lng = latLng.lng();
    try {
      setLocation({ lat, lng });
      const response = await Geocode.fromLatLng(lat, lng);
      const address = response.results[0].formatted_address;
      const address_formatted = address.split(", ");
      console.log(address_formatted);
      const state_pincode_formatted =
        address_formatted[address_formatted.length - 2];
      console.log(state_pincode_formatted);
      const state_pincode_array = state_pincode_formatted.split(" ");
      console.log(state_pincode_array);
      let newState = "";
      let newPincode = "";

      if (state_pincode_array.length === 1 && !isNaN(state_pincode_array[0])) {
        newPincode = state_pincode_array[0];
      } else if (state_pincode_array.length === 2) {
        newState = isNaN(state_pincode_array[0]) ? state_pincode_array[0] : "";
        newPincode =
          isNaN(state_pincode_array[1]) ||
            typeof state_pincode_array[1] !== "string"
            ? ""
            : state_pincode_array[1];
      }

      setreg_address((prevFormData) => {
        return {
          ...prevFormData,
          address_line1: address_formatted[0],
          town: address_formatted[2],
          state: newState,
          district: response.results[0].address_components[4].long_name,
          pincode: newPincode,
          latitude: lat.toString(),
          longitude: lng.toString(),
        };
      });
      console.log(response.results);
      if (reg_address.address_line1 && reg_address.town && reg_address.state && reg_address.district && reg_address.pincode && reg_address.latitude && reg_address.longitude) {
        setFieldValid(false);
      } else {
        setFieldValid(true);
      }
    } catch (error) {
      console.error("Error retrieving address:", error);
      notify("error", "Error retrieving address");
    }
  };
  return (
    <React.Fragment>
      <div className="my-serivce-main-page-view">
        {!showBusinessForm &&
          Userprofile &&
          !Userprofile.is_service_provider && (
            <div className="business-reg-container" id="business_reg_1">
              <div className="heading">ADDRESS DETAILS</div>
              <div className="profile-account-setting-right-new-address-location">
                <button onClick={handleClick}>
                  <img src={locationImage} alt="" />
                  <p>Use my current location</p>
                </button>

                {location && (
                  <div
                    style={{
                      width: "400px",
                      height: "300px",
                      position: "relative",
                    }}
                  >
                    <Map
                      google={window.google}
                      apiKey={googleMapsApiKey}
                      zoom={14}
                      initialCenter={location}
                      style={{ width: "100%", height: "100%" }}
                    >
                      <Marker
                        position={location}
                        draggable={true}
                        onDragend={(t, map, coord) =>
                          handleMarkerDragend(coord)
                        }
                      />
                    </Map>
                  </div>
                )}
              </div>
        
              <div className="form-fields">
              <div className={`form-group ${!reg_address.address_line1 && fieldValid ? 'error' : ''}`}>
                  <div className="form-label">
                    Address line 1<span className="required">*</span>
                  </div>
                  <input
                    type="text"
                    placeholder="Enter address line 1"
                    name="address_line1"
                    className="form-input"
                    value={reg_address.address_line1}
                    onChange={handleChange}
                  />
                   {fieldValid && !reg_address.address_line1 && <p className="error-message">Address line 1 is required</p>}
                </div>
                <div className="form-group">
                  <div className="form-label">Address line 2</div>
                  <input
                    type="text"
                    name="address_line2"
                    className="form-input"
                    placeholder="Enter address line 2"
                    value={reg_address.address_line2}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <div className="form-label">Landmark</div>
                  <input
                    type="text"
                    name="landmark"
                    className="form-input"
                    placeholder="Enter your landmark"
                    value={reg_address.landmark}
                    onChange={handleChange}
                  />
                </div>
                <div className={`form-group ${!reg_address.town && fieldValid ? 'error' : ''}`}>
                  <div className="form-label">
                    Town<span className="required">*</span>
                  </div>
                  <input
                    type="text"
                    name="town"
                    className="form-input"
                    placeholder="Enter your town/city"
                    value={reg_address.town}
                    onChange={handleChange}
                  />
                    {fieldValid && !reg_address.town && <p className="error-message">Town is required</p>}
                </div>
                <div className={`form-group ${( reg_address.state == "Select State" || reg_address.state==="" ) && fieldValid ? 'error' : ''}`}>
                  <div className="form-label">
                    State<span className="required">*</span>
                  </div>
                  <select
                    name="state"
                    className="form-select form-select-h"
                    value={reg_address.state}
                    onChange={handleChange}
                  >
                    <option value="Select State">Select State</option>
                    {allStates.map((state) => (
                      <option key={state} value={state}>
                        {state}
                      </option>
                    ))}
                  </select>
                  {fieldValid &&( reg_address.state == "Select State" || reg_address.state==="" )&& <p className="error-message">Please Select State</p>}
                </div>
                <div className={`form-group ${( reg_address.district == "Select District" || reg_address.district==="") && fieldValid ? 'error' : ''}`}>
                  <div className="form-label">
                    District<span className="required">*</span>
                  </div>
                  <select
                    name="district"
                    className="form-select form-select-h"
                    value={reg_address.district}
                    onChange={handleChange}
                  >
                    <option value="Select District">Select District</option>
                    {reg_address.state != "Select State" &&
                      getDistricts(reg_address.state).map((district) => (
                        <option key={district} value={district}>
                          {district}
                        </option>
                      ))}
                  </select>
                  {fieldValid &&( reg_address.district == "Select District" || reg_address.district==="" )&& <p className="error-message">Please Select District</p>}
                </div>
                <div className={`form-group ${(!reg_address.pincode || reg_address.pincode.length !== 6) && fieldValid ? 'error' : ''}`}>
                  <div className="form-label">
                    Pincode<span className="required">*</span>
                  </div>
                  <input
                    type="number"
                    name="pincode"
                    className="form-input"
                    placeholder="Enter your pincode"
                    value={reg_address.pincode}
                    onChange={handleChange}
                  />
                   {(fieldValid && !reg_address.pincode) && <p className="error-message">Pincode is required</p>}
  {(reg_address.pincode && reg_address.pincode.length !== 6 )&& <p className="error-message">Pincode should be 6 digits long</p>}

                </div>
     
                  <>
                    <div className="form-group">
                      <div className="form-label">
                        Latitude<span className="required">*</span>
                      </div>
                      <input
                        type="number"
                        name="latitude"
                        className="form-input"
                        placeholder="Enter latitude"
                        value={reg_address.latitude}
                        onChange={handleChange}
                        readOnly
                      />
                    </div>
                    <div className="form-group">
                      <div className="form-label">
                        Longitude<span className="required">*</span>
                      </div>
                      <input
                        type="number"
                        name="longitude"
                        className="form-input"
                        placeholder="Enter longitude"
                        value={reg_address.longitude}
                        onChange={handleChange}
                        readOnly// Conditionally set readOnly
                      />
                    </div>
                  </>
   
              </div>

              <div className="button-container">
                <button
                  type="submit"
                  className="save-button"
                  onClick={handleSubmit}
                >
                  SAVE ADDRESS
                </button>
              </div>
            </div>
          )}
        {showBusinessForm && (
          <div className="business-registration-form " id="business_reg_2">
            <div className="my-service-profile-ongoing-and-pending-online-back">
              <img
                src={back}
                onClick={() => {
                  setShowBusinessForm(!showBusinessForm);
                }}
                alt=""
              />
            </div>
            <div className="">
              <div className="form-fields2">
                <div className="form-group ">
                  <div className="form-label">
                    Email ID<span className="required">*</span>
                  </div>
                  <input
                    type="email"
                    className="form-input"
                    placeholder="Enter your business email id"
                    id="business_email"
                    name="businessEmail"
                    value={businessDetails.businessEmail}
                    onChange={handleChange2}
                  />
                </div>
                <div className="form-group">
                  <div className="form-label">
                    Name<span className="required">*</span>
                  </div>
                  <input
                    type="text"
                    className="form-input"
                    placeholder="Enter your business name"
                    id="business_name"
                    name="businessName"
                    value={businessDetails.businessName}
                    onChange={handleChange2}
                  />
                </div>
                <div className="form-group ">
                  <div className="form-label">
                    Mobile number<span className="required">*</span>
                  </div>
                  <input
                    type="number"
                    className="form-input"
                    placeholder="Enter your business mobile number"
                    id="business_mobileNo"
                    name="businessMobileNo"
                    value={businessDetails.businessMobileNo}
                    onChange={handleChange2}
                  />
                </div>
                <div className="form-group ">
                  <div className="form-label">
                    Whatsapp number<span className="required">*</span>
                  </div>
                  <input
                    type="number"
                    className="form-input"
                    placeholder="Enter your business whatsapp number"
                    id="business_whatsapp"
                    name="businessWhatsapp"
                    value={businessDetails.businessWhatsapp}
                    onChange={handleChange2}
                  />
                </div>
                <div className="form-group ">
                  <div className="form-label">
                    Servicable distance (in kms)
                    <span className="required">*</span>
                  </div>
                  <input
                    type="number"
                    className="form-input"
                    placeholder="Enter your business servicable distance"
                    id="business_servicable"
                    name="businessServicable"
                    value={businessDetails.businessServicable}
                    onChange={handleChange2}
                  />
                </div>
                <div className="form-group ">
                  <div className="form-label">
                    Select the service you want to provide
                    <span className="required">*</span>
                  </div>
                  <select
                    className="form-select form-select-h"
                    id="business_service"
                    name="businessService"
                    onChange={handleChange2}
                    value={businessDetails.businessService}
                  >
                    <option value="Select Service">Select Service</option>
                    {services.map((service) => (
                      <option key={service.id} value={service.name}>
                        {service.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group ">
                  <div className="form-label">
                    Select the subservice you want to provide
                    <span className="required">*</span>
                  </div>
                  <select
                    className="form-select form-select-h"
                    id="business_subservice"
                    name="businessSubservice"
                    onChange={handleChange2}
                    value={businessDetails.businessSubservice}
                  >
                    <option value="Select Subservice">Select Subservice</option>
                    {businessDetails.businessService != "Select Service" &&
                      selectedService?.sub_list.map((subService) => (
                        <option
                          key={subService.id}
                          value={subService.sub_service}
                        >
                          {subService.sub_service}
                        </option>
                      ))}
                  </select>
                </div>

                <div className="form-group ">
                  <div className="form-label">
                    Select the Service Provider Type
                    <span className="required">*</span>
                  </div>
                  <select
                    className="form-select form-select-h"
                    name="businessproviderType"
                    onChange={handleChange2}
                    value={businessDetails.businessproviderType}
                  >
                    <option value="">Select Service Provider Type</option>
                    <option value="1">Manufacturer</option>
                    <option value="2">Wholesaler</option>
                    <option value="3">Retailer</option>
                    <option value="4">Superstock</option>
                    <option value="5">Agency</option>
                  </select>
                </div>
                <div className="form-group ">
                  <div className="form-label">
                    Discount (in %)<span className="required">*</span>
                  </div>
                  <input
                    type="number"
                    className="form-input"
                    placeholder="Enter your discount"
                    id="business_discount"
                    name="businessDiscount"
                    value={businessDetails.businessDiscount}
                    onChange={handleChange2}
                  />
                </div>
                <div className="form-group ">
                  <div className="form-label">
                    GST Number
                  </div>
                  <input
                    type="number"
                    className="form-input"
                    placeholder="Enter your GST Number"
                    name="businessGstnumber"
                    value={businessDetails.businessGstnumber}
                    onChange={handleChange2}
                  />
                </div>
                <div className="form-group ">
                  <div className="form-label">Business Logo/Image</div>
                  <input
                    className="form-input"
                    id="addbusiness_image"
                    accept="image/png, image/jpeg"
                    type="file"
                    name="files"
                    onChange={handleChange2}
                  />
                </div>
                <div className="form-group ">
                  <div className="form-label">
                    Service Description<span className="required">*</span>
                  </div>
                  <textarea
                    type="text"
                    className="form-input"
                    placeholder="Describe your service"
                    id="businessDescription"
                    name="businessDescription"
                    value={businessDetails.businessDescription}
                    onChange={handleChange2}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="radio-inputs">
              <div className="inner-radio-conatiner">
                <div className="form-group ">
                  <div className="form-label">
                    Do you have dynamic location?
                    <span className="required">*</span>
                  </div>
                  <div className="options-to-select">
                    <div className="select-option-radio">
                      <input
                        type="radio"
                        name="businessLocation"
                        value="1"
                        className="form-input-radio"
                        checked={businessDetails.businessLocation === "1"}
                        onChange={handleChange2}
                      />
                      <label className="">Yes</label>
                    </div>
                    <div className="select-option-radio">
                      <input
                        type="radio"
                        name="businessLocation"
                        value="0"
                        className="form-input-radio"
                        checked={businessDetails.businessLocation === "0"}
                        onChange={handleChange2}
                      />
                      <label className="">No</label>
                    </div>
                  </div>
                </div>
                <div className="form-group ">
                  <div className="form-label">
                    Is home delivery available?
                    <span className="required">*</span>
                  </div>
                  <div className="options-to-select">
                    <div className="select-option-radio">
                      <input
                        type="radio"
                        name="businessDelivery"
                        value="1"
                        className="form-input-radio"
                        checked={businessDetails.businessDelivery === "1"}
                        onChange={handleChange2}
                      />
                      <label className="">Yes</label>
                    </div>
                    <div className="select-option-radio">
                      <input
                        type="radio"
                        name="businessDelivery"
                        value="0"
                        className="form-input-radio"
                        checked={businessDetails.businessDelivery === "0"}
                        onChange={handleChange2}
                      />
                      <label className="">No</label>
                    </div>
                  </div>
                </div>
                <div className="form-group ">
                  <div className="form-label">
                    Do you provide multiple services?
                    <span className="required">*</span>
                  </div>
                  <div className="options-to-select">
                    <div className="select-option-radio">
                      <input
                        type="radio"
                        name="businessMultipleService"
                        value="1"
                        className="form-input-radio"
                        checked={
                          businessDetails.businessMultipleService === "1"
                        }
                        onChange={handleChange2}
                      />

                      <label className="">Yes</label>
                    </div>
                    <div className="select-option-radio">
                      <input
                        type="radio"
                        name="businessMultipleService"
                        value="0"
                        className="form-input-radio"
                        checked={
                          businessDetails.businessMultipleService === "0"
                        }
                        onChange={handleChange2}
                      />
                      <label className="">No</label>
                    </div>
                  </div>
                </div>
                <div className="form-group ">
                  <div className="form-label">
                    Is your business visible to others?
                    <span className="required">*</span>
                  </div>
                  <div className="options-to-select">
                    <div className="select-option-radio">
                      <input
                        type="radio"
                        name="businessVisible"
                        value="1"
                        className="form-input-radio"
                        checked={businessDetails.businessVisible === "1"}
                        onChange={handleChange2}
                      />
                      <label className="">Yes</label>
                    </div>
                    <div className="select-option-radio">
                      <input
                        type="radio"
                        name="businessVisible"
                        value="0"
                        className="form-input-radio"
                        checked={businessDetails.businessVisible === "0"}
                        onChange={handleChange2}
                      />
                      <label className="">No</label>
                    </div>
                  </div>
                </div>
                <div className="form-group ">
                  <div className="form-label">
                    Do you have multiple outlets? *
                    <span className="required">*</span>
                  </div>
                  <div className="options-to-select">
                    <div className="select-option-radio">
                      <input
                        type="radio"
                        name="businessOutlets"
                        value="1"
                        className="form-input-radio"
                        checked={businessDetails.businessOutlets === "1"}
                        onChange={handleChange2}
                      />
                      <label className="">Yes</label>
                    </div>
                    <div className="select-option-radio">
                      <input
                        type="radio"
                        name="businessOutlets"
                        value="0"
                        className="form-input-radio"
                        checked={businessDetails.businessOutlets === "0"}
                        onChange={handleChange2}
                      />
                      <label className="">No</label>
                    </div>
                  </div>
                </div>
                <div className="form-group ">
                  <div className="form-label">Website Name</div>
                  <input
                    type="text"
                    className="form-input"
                    name="businessWebsitename"
                    placeholder="Enter your website name"
                    value={businessDetails.businessWebsitename}
                    onChange={handleChange2}
                  ></input>
                </div>
              </div>
            </div>
            <div className="button-container">
              <button className="save-button" onClick={addbusinessdata}>
                {/* <FieldChange textValue="Validate Your Mobile Number" refreshPage={businessDetails} setRefreshPage={setBusinessDetails}/> */}
                Register
              </button>
            </div>
            {(isRegisterHit) && <FieldChange textValue="Verify Your Mobile Number" purpose="register seller" details={businessDetails} setDetails={setBusinessDetails}/> }
          </div>
        )}



        {Userprofile.is_service_provider && (
          <div className="my-bills-profile-page-view-main-left">
            <div className="my-bills-profile-page-view-left-top">
              <div className="my-bills-profile-page-view-left-top-heading">
              {isMobileView ? (
          <>
            {page2 !== 0 ? (
              page === 1 ? (
                <span>
                  {page3 === 1 ? "Ongoing & Pending Order" :
                   page3 === 2 ? "Cancelled & Completed Orders" :
                   page3 === 3 ? "All Online Customers" : ""}
                </span>
              ) : page === 2 ? (
                <span>
                  {page4 === 1 ? "Offline Orders" :
                   page4 === 2 ? "Customer Transaction Details" :
                   page4 === 3 ? "All Offline Customers" :
                   page4 === 4 ? "Incoming & Outgoing Transaction" :
                   page4 === 5 ? "Generate Bill" : ""}
                </span>
              ) : (
                <span>
                  {page5 === 1 ? "Manage Products" :
                   page5 === 2 ? "Manage My Products" :
                   page5 === 3 ? "Manage Services" : 
           page5 === 4 ? "Jobs" :
           page5 === 5 ? "Edit Business account" :
           page5 === 6 ? "Delete Service Provider" : 
           page5 === 7 ? "Manage Coupon" : ""
           }
                </span>
              )
            ) : (
              <span>MY Services</span>
            )}
          </>
        ) : <>
       { page2 !== 0 ? (
                  page === 1 ? (
                    <span>Online Orders</span>
                  ) : page === 2 ? (
                    <span>Offline Orders</span>
                  ) : (
                    <span>Manage Bussiness</span>
                  )
                ) : (
                  <span>MY Services</span>
                )}</>}
              </div>
            </div>

            {page2 === 0 ? (
              <>
                <section className="my-bills-options my-bills-options1">
                  <div
                    className="profile-account-setting-container-left-1"
                    onClick={() => {
                      setPage(1);
                      setPage2(0);
                      console.log(page, page2);
                    }}
                  >
                    {page === 1 ? (
                      <div className="profile-account-setting-container-left-1-selected"></div>
                    ) : (
                      <div className="profile-account-setting-container-left-1-unselected"></div>
                    )}
                    <span>Online Order</span>
                  </div>
                  {/* 2 */}
                  <div
                    className="profile-account-setting-container-left-1"
                    onClick={() => {
                      setPage(2);
                      setPage2(0);
                      console.log(page, page2);
                    }}
                  >
                    {page === 2 ? (
                      <div className="profile-account-setting-container-left-1-selected"></div>
                    ) : (
                      <div className="profile-account-setting-container-left-1-unselected"></div>
                    )}
                    <span>Offline Order</span>
                  </div>
                  <div
                    className="profile-account-setting-container-left-1"
                    onClick={() => {
                      setPage(3);
                      setPage2(0);
                      console.log(page, page2);
                    }}
                  >
                    {page === 3 ? (
                      <div className="profile-account-setting-container-left-1-selected"></div>
                    ) : (
                      <div className="profile-account-setting-container-left-1-unselected"></div>
                    )}
                    <span>Manage Business</span>
                  </div>
                </section>
              </>
            ) : page === 1 && page2 !== 0 ? (
              <>
                <section className="my-bills-options-hidden innerOptions">
                  {userServiceIDselected && userServiceIDselected[1] !== "All services" && <div className="manage-business-selectedsubservice">{userServiceIDselected[1]}</div>}

                  <div
                    className="profile-account-setting-container-left-1"
                    onClick={() => {
                      setPage3(1);
                      setPage2(1);
                      console.log(page, page2);
                    }}
                  >
                    {page3 === 1 ? (
                      <div className="profile-account-setting-container-left-1-selected"></div>
                    ) : (
                      <div className="profile-account-setting-container-left-1-unselected"></div>
                    )}
                    <span>Ongoing & Pending Order</span>
                  </div>
                  {/* 2 */}
                  <div
                    className="profile-account-setting-container-left-1"
                    onClick={() => {
                      setPage3(2);
                      setPage2(1);
                      console.log(page);
                    }}
                  >
                    {page3 === 2 ? (
                      <div className="profile-account-setting-container-left-1-selected"></div>
                    ) : (
                      <div className="profile-account-setting-container-left-1-unselected"></div>
                    )}
                    <span>Cancelled & Completed Orders</span>
                  </div>
                  <div
                    className="profile-account-setting-container-left-1"
                    onClick={() => {
                      setPage3(3);
                      setPage2(1);
                      console.log(page);
                    }}
                  >
                    {page3 === 3 ? (
                      <div className="profile-account-setting-container-left-1-selected"></div>
                    ) : (
                      <div className="profile-account-setting-container-left-1-unselected"></div>
                    )}
                    <span>All Online Customers</span>
                  </div>
                </section>
              </>
            ) : page === 2 ? (
              <>
                <section className="my-bills-options-hidden innerOptions">
                  {userServiceIDselected &&
                    userServiceIDselected[1] !== "All services" && (
                      <div className="manage-business-selectedsubservice">
                        {userServiceIDselected[1]}
                      </div>
                    )}

                  <div
                    className="profile-account-setting-container-left-1"
                    onClick={() => {
                      setPage4(1);
                      setPage2(2);
                      setInnerPage(true);
                    }}
                  >
                    {page4 === 1 ? (
                      <div className="profile-account-setting-container-left-1-selected"></div>
                    ) : (
                      <div className="profile-account-setting-container-left-1-unselected"></div>
                    )}
                    <span>Offline Orders</span>
                  </div>
                  {/* 2 */}
                  <div
                    className="profile-account-setting-container-left-1"
                    onClick={() => {
                      setPage4(2);
                      setPage2(2);
                      setInnerPage(true);
                    }}
                  >
                    {page4 === 2 ? (
                      <div className="profile-account-setting-container-left-1-selected"></div>
                    ) : (
                      <div className="profile-account-setting-container-left-1-unselected"></div>
                    )}
                    <span>Customer Transaction Details</span>
                  </div>
                  <div
                    className="profile-account-setting-container-left-1"
                    onClick={() => {
                      setPage4(3);
                      setPage2(2);
                      setInnerPage(true);
                    }}
                  >
                    {page4 === 3 ? (
                      <div className="profile-account-setting-container-left-1-selected"></div>
                    ) : (
                      <div className="profile-account-setting-container-left-1-unselected"></div>
                    )}
                    <span>All Offline Customers</span>
                  </div>
                  <div
                    className="profile-account-setting-container-left-1"
                    onClick={() => {
                      setPage4(4);
                      setPage2(2);
                      setInnerPage(true);
                    }}
                  >
                    {page4 === 4 ? (
                      <div className="profile-account-setting-container-left-1-selected"></div>
                    ) : (
                      <div className="profile-account-setting-container-left-1-unselected"></div>
                    )}
                    <span>Incoming & Outgoing Transaction</span>
                  </div>
                  <div
                    className="profile-account-setting-container-left-1"
                    onClick={() => {
                      setPage4(5);
                      setPage2(2);
                      setInnerPage(true);
                    }}
                  >
                    {page4 === 5 ? (
                      <div className="profile-account-setting-container-left-1-selected"></div>
                    ) : (
                      <div className="profile-account-setting-container-left-1-unselected"></div>
                    )}
                    <span>Generate Bill</span>
                  </div>
                  {/* <div
                  className='profile-account-setting-container-left-1'
                  onClick={() => {
                    setPage4(6);
                    setPage2(2);
                    console.log(page);
                  }}
                >
                  {page4 === 6 ? (
                    <div className='profile-account-setting-container-left-1-selected'></div>
                  ) : (
                    <div className='profile-account-setting-container-left-1-unselected'></div>
                  )}
                  <span>My Products</span>
                </div> */}
                  {/* <div
                  className='profile-account-setting-container-left-1'
                  onClick={() => {
                    setPage4(7);
                    setPage2(2);
                    console.log(page);
                  }}
                >
                  {page4 === 7 ? (
                    <div className='profile-account-setting-container-left-1-selected'></div>
                  ) : (
                    <div className='profile-account-setting-container-left-1-unselected'></div>
                  )}
                  <span>Generate Bills</span>
                </div> */}
                </section>
              </>
            ) : (
              <>
                <section className="my-bills-options-hidden innerOptions">
                  {userServiceIDselected &&
                    userServiceIDselected[1] !== "All services" && (
                      <div className="manage-business-selectedsubservice">
                        {userServiceIDselected[1]}
                      </div>
                    )}
                  <div
                    className="profile-account-setting-container-left-1"
                    onClick={() => {
                      setPage5(1);
                      setPage2(3);
                      console.log(page);
                    }}
                  >
                    {page5 === 1 ? (
                      <div className="profile-account-setting-container-left-1-selected"></div>
                    ) : (
                      <div className="profile-account-setting-container-left-1-unselected"></div>
                    )}
                    <span>Manage Products</span>
                  </div>
                  <div
                    className="profile-account-setting-container-left-1"
                    onClick={() => {
                      setPage5(2);
                      setPage2(3);
                      console.log(page);
                    }}
                  >
                    {page5 === 2 ? (
                      <div className="profile-account-setting-container-left-1-selected"></div>
                    ) : (
                      <div className="profile-account-setting-container-left-1-unselected"></div>
                    )}
                    <span>Manage My Products</span>
                  </div>
                  <div
                    className="profile-account-setting-container-left-1"
                    onClick={() => {
                      setPage5(3);
                      setPage2(3);
                      console.log(page);
                    }}
                  >
                    {page5 === 3 ? (
                      <div className="profile-account-setting-container-left-1-selected"></div>
                    ) : (
                      <div className="profile-account-setting-container-left-1-unselected"></div>
                    )}
                    <span>Manage Services</span>
                  </div>
                  <div
                    className="profile-account-setting-container-left-1"
                    onClick={() => {
                      setPage5(7);
                      setPage2(3);
                      console.log(page);
                    }}
                  >
                    {page5 === 7 ? (
                      <div className="profile-account-setting-container-left-1-selected"></div>
                    ) : (
                      <div className="profile-account-setting-container-left-1-unselected"></div>
                    )}
                    <span>Manage Coupon</span>
                  </div>
                  {Userprofile.is_employee === false && (
                    <>
                      <div
                        className="profile-account-setting-container-left-1"
                        onClick={() => {
                          setPage5(4);
                          setPage2(3);
                          console.log(page);
                        }}
                      >
                        {page5 === 4 ? (
                          <div className="profile-account-setting-container-left-1-selected"></div>
                        ) : (
                          <div className="profile-account-setting-container-left-1-unselected"></div>
                        )}
                        <span>Jobs</span>
                      </div>
                      <div
                        className="profile-account-setting-container-left-1"
                        onClick={() => {
                          setPage5(5);
                          setPage2(3);
                          console.log(page);
                        }}
                      >
                        {page5 === 5 ? (
                          <div className="profile-account-setting-container-left-1-selected"></div>
                        ) : (
                          <div className="profile-account-setting-container-left-1-unselected"></div>
                        )}
                        <span>Edit Business Account</span>
                      </div>
                      <div
                        className="profile-account-setting-container-left-1"
                        onClick={() => {
                          setPage5(6);
                          setPage2(3);
                          console.log(page);
                        }}
                      >
                        {page5 === 6 ? (
                          <div className="profile-account-setting-container-left-1-selected"></div>
                        ) : (
                          <div className="profile-account-setting-container-left-1-unselected"></div>
                        )}
                        <span>Delete Service Provider</span>
                      </div>
      
                    </>
                  )}
                </section>
              </>
            )}
          </div>
        )}
        {Userprofile.is_service_provider && (
          <div className="my-bills-profile-page-view-main-right">
            {page2 === 0 ? (
              page === 1 ? (
                <div className="my-service-profile-page-view-main-right">
                  <div className="my-order-profile-subservice-filter">
                    {console.log(
                      userServiceIDselected ? userServiceIDselected[1] : ""
                    )}
                    <select
                      name="service-user"
                      onChange={handleSubserviceChange}
                      value={
                        userServiceIDselected ? userServiceIDselected[0] : ""
                      }
                    >
                      <option value="">All services</option>
                      {Allsubserviceavialable.map((option) => (
                        <option
                          key={option.subservice_id}
                          value={option.subservice_id}
                        >
                          {option["sub-service provided"]}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div
                    className="my-bills-profile-page-view-main-right-headings"
                    onClick={() => {
                      setPage3(1);
                      setPage2(1);
                      setInnerPage(true);
                    }}
                  >
                    <span>Ongoing & Pending Order</span>
                  </div>
                  <div
                    className="my-bills-profile-page-view-main-right-headings"
                    onClick={() => {
                      setPage3(2);
                      setPage2(1);
                      setInnerPage(true);
                    }}
                  >
                    <span>Cancelled & Completed Orders</span>
                  </div>
                  <div
                    className="my-bills-profile-page-view-main-right-headings"
                    onClick={() => {
                      setPage3(3);
                      setPage2(1);
                      setInnerPage(true);
                    }}
                  >
                    <span>All Online Customers</span>
                  </div>
                </div>
              ) : page === 2 ? (
                <div className="my-service-profile-page-view-main-right">
                  <div className="my-order-profile-subservice-filter">
                    <select
                      name="service-user"
                      onChange={handleSubserviceChange}
                      value={
                        userServiceIDselected ? userServiceIDselected[0] : ""
                      }
                    >
                      <option value="">All services</option>
                      {Allsubserviceavialable.map((option) => (
                        <option
                          key={option.subservice_id}
                          value={option.subservice_id}
                        >
                          {option["sub-service provided"]}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div
                    className="my-bills-profile-page-view-main-right-headings"
                    onClick={() => {
                      setPage4(1);
                      setPage2(2);
                      setInnerPage(true);
                    }}
                  >
                    <span>Offline Orders</span>
                  </div>
                  <div
                    className="my-bills-profile-page-view-main-right-headings"
                    onClick={() => {
                      setPage4(2);
                      setPage2(2);
                      setInnerPage(true);
                    }}
                  >
                    <span>Customer Transaction Details</span>
                  </div>
                  <div
                    className="my-bills-profile-page-view-main-right-headings"
                    onClick={() => {
                      setPage4(3);
                      setPage2(2);
                      setInnerPage(true);
                    }}
                  >
                    <span>All Offline Customers</span>
                  </div>
                  <div
                    className="my-bills-profile-page-view-main-right-headings"
                    onClick={() => {
                      setPage4(4);
                      setPage2(2);
                      setInnerPage(true);
                    }}
                  >
                    <span>Incoming & Outgoing Transaction</span>
                  </div>
                  <div
                    className="my-bills-profile-page-view-main-right-headings"
                    onClick={() => {
                      setPage4(5);
                      setPage2(2);
                      setInnerPage(true);
                    }}
                  >
                    <span>Generate Bill</span>
                  </div>
                  {/* <div
                    className='my-bills-profile-page-view-main-right-headings'
                    onClick={() => {
                      setPage4(6);
                      setPage2(2);
                    }}
                  >
                    <span>My Products</span>
                  </div>
                  <div
                    className='my-bills-profile-page-view-main-right-headings'
                    onClick={() => {
                      setPage4(7);
                      setPage2(2);
                    }}
                  >
                    <span>Generate Bills</span>
                  </div> */}
                </div>
              ) : (
                <div className="my-service-profile-page-view-main-right">
                  <div className="my-order-profile-subservice-filter">
                    <select
                      name="service-user"
                      onChange={handleSubserviceChange}
                      value={
                        userServiceIDselected ? userServiceIDselected[0] : ""
                      }
                    >
                      <option value="">All services</option>
                      {Allsubserviceavialable.map((option) => (
                        <option
                          key={option.subservice_id}
                          value={option.subservice_id}
                        >
                          {option["sub-service provided"]}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div
                    className="my-bills-profile-page-view-main-right-headings"
                    onClick={() => {
                      setPage5(1);
                      setPage2(3);
                      setInnerPage(true);
                    }}
                  >
                    <span>Manage Products</span>
                  </div>
                  <div
                    className="my-bills-profile-page-view-main-right-headings"
                    onClick={() => {
                      setPage5(2);
                      setPage2(3);
                      setInnerPage(true);
                    }}
                  >
                    <span>Manage My Products</span>
                  </div>
                  <div
                    className="my-bills-profile-page-view-main-right-headings"
                    onClick={() => {
                      setPage5(3);
                      setPage2(3);
                      setInnerPage(true);
                    }}
                  >
                    <span>Manage Services</span>
                  </div>
                  <div
                    className="my-bills-profile-page-view-main-right-headings"
                    onClick={() => {
                      setPage5(7);
                      setPage2(3);
                      setInnerPage(true);
                    }}
                  >
                    <span>Manage Coupon</span>
                  </div>
                  {Userprofile.is_employee === false && (
                    <>
                      <div
                        className="my-bills-profile-page-view-main-right-headings"
                        onClick={() => {
                          setPage5(4);
                          setPage2(3);
                          setInnerPage(true);
                        }}
                      >
                        <span>Jobs</span>
                      </div>
                      <div
                        className="my-bills-profile-page-view-main-right-headings"
                        onClick={() => {
                          setPage5(5);
                          setPage2(3);
                          setInnerPage(true);
                        }}
                      >
                        <span>Edit Business Account</span>
                      </div>
                      <div
                        className="my-bills-profile-page-view-main-right-headings"
                        onClick={() => {
                          setPage5(6);
                          setPage2(3);
                          setInnerPage(true);
                        }}
                      >
                        <span>Delete Service Provider</span>
                      </div>
                    
                    </>
                  )}
                </div>
              )
            ) : (
              <>
                {page2 === 1 && page3 === 1 && (
                  <OngoingAndPendingOrder
                    return={() => setPage2(0)}
                  ></OngoingAndPendingOrder>
                )}
                {page2 === 1 && page3 === 2 && (
                  <CancelledAndCompletedOnline
                    return={() => {
                      setPage2(0);
                    }}
                  ></CancelledAndCompletedOnline>
                )}
                {page2 === 1 && page3 === 3 && (
                  <AllOnlineCustomers
                    return={() => {
                      setPage2(0);
                    }}
                  ></AllOnlineCustomers>
                )}
                {page2 === 2 && page4 === 1 && (
                  <OfflineOrders
                    return={() => {
                      setPage2(0);
                    }}
                  ></OfflineOrders>
                )}
                {page2 === 2 && page4 === 2 && (
                  <OfflineCustomerTransactionDetail
                    return={() => {
                      setPage2(0);
                    }}
                    userId={userId}
                    userName={userName}
                    onUserIdChange={handleUserIdChange}
                    setPage4={setPage4}
                    existingdate={existingDate}
                  ></OfflineCustomerTransactionDetail>
                )}
                {page2 === 2 && page4 === 3 && (
                  <AllOflineCustomers
                    return={() => {
                      setPage2(0);
                    }}
                    userId={userId}
                    userName={userName}
                    onUserIdChange={handleUserIdChange}
                    setPage4={setPage4}
                  ></AllOflineCustomers>
                )}
                {page2 === 2 && page4 === 4 && (
                  <OfflineIncomingAndOutgoingOrder
                    return={() => {
                      setPage2(0);
                    }}
                    userId={userId}
                    userName={userName}
                    onUserIdChange={handleUserIdChange}
                    existingdate={existingDate}
                  ></OfflineIncomingAndOutgoingOrder>
                )}
                {page2 === 2 && page4 === 5 && (
                  <AllProducts
                    return={() => {
                      setPage2(0);
                    }}
                  ></AllProducts>
                )}
                {page2 === 2 && page4 === 6 && (
                  <AllMyProducts
                    return={() => {
                      setPage2(0);
                    }}
                  ></AllMyProducts>
                )}
                {page2 === 2 && page4 === 7 && (
                  <GenerateBill
                    return={() => {
                      setPage2(0);
                    }}
                  />
                )}
                {page2 === 3 && page5 === 1 && (
                  <ManageProduct
                    return={() => {
                      setPage2(0);
                    }}
                  ></ManageProduct>
                )}
                {page2 === 3 && page5 === 2 && (
                  <ManageMyProduct
                    return={() => {
                      setPage2(0);
                    }}
                  ></ManageMyProduct>
                )}
                {page2 === 3 && page5 === 3 && (
                  <ManageServices
                    return={() => {
                      setPage2(0);
                    }}
                  ></ManageServices>
                )}
                {page2 === 3 && page5 === 4 && (
                  <PostJobSerivce
                    return={() => {
                      setPage2(0);
                    }}
                  ></PostJobSerivce>
                )}
                {page2 === 3 && page5 === 5 && (
                  <MyBussinessAccount
                    return={() => {
                      setPage2(0);
                    }}
                  ></MyBussinessAccount>
                )}
                {page2 === 3 && page5 === 6 && (
                  <DeleteServiceProviderAccount
                    return={() => {
                      setPage2(0);
                    }}
                  ></DeleteServiceProviderAccount>
                )}
              {page2 === 3 && page5 === 7 && (
                  <Managecoupon
                    return={() => {
                      setPage2(0);
                    }}
                  ></Managecoupon>
                )}
              </>
            )}
          </div>
        )}


      </div>
      {isAddingBusiness && (
        <div className="overlay addingprovider" style={{ top: "0" }}>
          <div>Creating provider account. Please wait...</div>
        </div>
      )}
    </React.Fragment>
  );
}

// {
//   page2 === 0 ? (
//     <>
//       <div
//         className='profile-account-setting-container-left-1'
//         onClick={() => {
//           setPage(1);
//           setPage2(1);
//           console.log(page);
//         }}
//       >
//         {page === 1 ? (
//           <div className='profile-account-setting-container-left-1-selected'></div>
//         ) : (
//           <div className='profile-account-setting-container-left-1-unselected'></div>
//         )}
//         <span>Online Order</span>
//       </div>
//       {/* 2 */}
//       <div
//         className='profile-account-setting-container-left-1'
//         onClick={() => {
//           setPage(2);
//           setPage2(1);
//           console.log(page);
//         }}
//       >
//         {page === 2 ? (
//           <div className='profile-account-setting-container-left-1-selected'></div>
//         ) : (
//           <div className='profile-account-setting-container-left-1-unselected'></div>
//         )}
//         <span>Offline Order</span>
//       </div>
//       <div
//         className='profile-account-setting-container-left-1'
//         onClick={() => {
//           setPage(3);
//           setPage2(1);
//           console.log(page);
//         }}
//       >
//         {page === 3 ? (
//           <div className='profile-account-setting-container-left-1-selected'></div>
//         ) : (
//           <div className='profile-account-setting-container-left-1-unselected'></div>
//         )}
//         <span>Manage Business</span>
//       </div>
//     </>
//   ) : page === 1 ? (
//     <>
//       <div
//         className='profile-account-setting-container-left-1'
//         onClick={() => {
//           setPage3(1);
//           console.log(page);
//         }}
//       >
//         {page3 === 1 ? (
//           <div className='profile-account-setting-container-left-1-selected'></div>
//         ) : (
//           <div className='profile-account-setting-container-left-1-unselected'></div>
//         )}
//         <span>Ongoing & Pending Order</span>
//       </div>
//       {/* 2 */}
//       <div
//         className='profile-account-setting-container-left-1'
//         onClick={() => {
//           setPage3(2);
//           console.log(page);
//         }}
//       >
//         {page3 === 2 ? (
//           <div className='profile-account-setting-container-left-1-selected'></div>
//         ) : (
//           <div className='profile-account-setting-container-left-1-unselected'></div>
//         )}
//         <span>Cancelled & Completed Orders</span>
//       </div>
//       <div
//         className='profile-account-setting-container-left-1'
//         onClick={() => {
//           setPage3(3);
//           console.log(page);
//         }}
//       >
//         {page3 === 3 ? (
//           <div className='profile-account-setting-container-left-1-selected'></div>
//         ) : (
//           <div className='profile-account-setting-container-left-1-unselected'></div>
//         )}
//         <span>All Online Customers</span>
//       </div>
//     </>
//   ) : page === 2 ? (
//     <>
//       <div
//         className='profile-account-setting-container-left-1'
//         onClick={() => {
//           setPage4(1);
//           console.log(page);
//         }}
//       >
//         {page4 === 1 ? (
//           <div className='profile-account-setting-container-left-1-selected'></div>
//         ) : (
//           <div className='profile-account-setting-container-left-1-unselected'></div>
//         )}
//         <span>Offline Orders</span>
//       </div>
//       {/* 2 */}
//       <div
//         className='profile-account-setting-container-left-1'
//         onClick={() => {
//           setPage4(2);
//           console.log(page);
//         }}
//       >
//         {page4 === 2 ? (
//           <div className='profile-account-setting-container-left-1-selected'></div>
//         ) : (
//           <div className='profile-account-setting-container-left-1-unselected'></div>
//         )}
//         <span>Customer Transaction Details</span>
//       </div>
//       <div
//         className='profile-account-setting-container-left-1'
//         onClick={() => {
//           setPage4(3);
//           console.log(page);
//         }}
//       >
//         {page4 === 3 ? (
//           <div className='profile-account-setting-container-left-1-selected'></div>
//         ) : (
//           <div className='profile-account-setting-container-left-1-unselected'></div>
//         )}
//         <span>All Offline Customers</span>
//       </div>
//       <div
//         className='profile-account-setting-container-left-1'
//         onClick={() => {
//           setPage4(4);
//           console.log(page);
//         }}
//       >
//         {page4 === 4 ? (
//           <div className='profile-account-setting-container-left-1-selected'></div>
//         ) : (
//           <div className='profile-account-setting-container-left-1-unselected'></div>
//         )}
//         <span>Incoming & Outgoing Transaction</span>
//       </div>
//       <div
//         className='profile-account-setting-container-left-1'
//         onClick={() => {
//           setPage4(5);
//           console.log(page);
//         }}
//       >
//         {page4 === 5 ? (
//           <div className='profile-account-setting-container-left-1-selected'></div>
//         ) : (
//           <div className='profile-account-setting-container-left-1-unselected'></div>
//         )}
//         <span>All Products</span>
//       </div>
//       <div
//         className='profile-account-setting-container-left-1'
//         onClick={() => {
//           setPage4(6);
//           console.log(page);
//         }}
//       >
//         {page4 === 6 ? (
//           <div className='profile-account-setting-container-left-1-selected'></div>
//         ) : (
//           <div className='profile-account-setting-container-left-1-unselected'></div>
//         )}
//         <span>Generate Bills</span>
//       </div>
//     </>
//   ) : (
//     <>
//       <div
//         className='profile-account-setting-container-left-1'
//         onClick={() => {
//           setPage5(1);
//           console.log(page);
//         }}
//       >
//         {page5 === 1 ? (
//           <div className='profile-account-setting-container-left-1-selected'></div>
//         ) : (
//           <div className='profile-account-setting-container-left-1-unselected'></div>
//         )}
//         <span>Manage Products</span>
//       </div>
//       <div
//         className='profile-account-setting-container-left-1'
//         onClick={() => {
//           setPage5(2);
//           console.log(page);
//         }}
//       >
//         {page5 === 2 ? (
//           <div className='profile-account-setting-container-left-1-selected'></div>
//         ) : (
//           <div className='profile-account-setting-container-left-1-unselected'></div>
//         )}
//         <span>Manage My Products</span>
//       </div>
//       <div
//         className='profile-account-setting-container-left-1'
//         onClick={() => {
//           setPage5(3);
//           console.log(page);
//         }}
//       >
//         {page5 === 3 ? (
//           <div className='profile-account-setting-container-left-1-selected'></div>
//         ) : (
//           <div className='profile-account-setting-container-left-1-unselected'></div>
//         )}
//         <span>Manage Services</span>
//       </div>
//       <div
//         className='profile-account-setting-container-left-1'
//         onClick={() => {
//           setPage5(4);
//           console.log(page);
//         }}
//       >
//         {page5 === 4 ? (
//           <div className='profile-account-setting-container-left-1-selected'></div>
//         ) : (
//           <div className='profile-account-setting-container-left-1-unselected'></div>
//         )}
//         <span>Jobs</span>
//       </div>
//       <div
//         className='profile-account-setting-container-left-1'
//         onClick={() => {
//           setPage5(5);
//           console.log(page);
//         }}
//       >
//         {page5 === 5 ? (
//           <div className='profile-account-setting-container-left-1-selected'></div>
//         ) : (
//           <div className='profile-account-setting-container-left-1-unselected'></div>
//         )}
//         <span>Edit Business Account</span>
//       </div>
//       <div
//         className='profile-account-setting-container-left-1'
//         onClick={() => {
//           setPage5(6);
//           console.log(page);
//         }}
//       >
//         {page5 === 6 ? (
//           <div className='profile-account-setting-container-left-1-selected'></div>
//         ) : (
//           <div className='profile-account-setting-container-left-1-unselected'></div>
//         )}
//         <span>Delete Service Provider</span>
//       </div>
//     </>
//   );
// }
export default GoogleApiWrapper({
  apiKey: googleMapsApiKey,
})(MyServiceMainPage);
