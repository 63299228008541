import axios from 'axios';
//company
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

class PutatoeNewsApi {
  ShowNews(category, search_term) {
    return axios.post(
      API_BASE_URL + '/v1/api/news',
      { category, search_term },
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }
}

export default new PutatoeNewsApi();
