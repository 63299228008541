import React, { useState } from "react";
import "../post-css/myPost.css";
import PostCard from "./postCard";
import PostCardModal from "./PostCardModal";
import { usePostContext } from "../postContext.js";
import postapi from "../api/postapi";
import notify from "../error";
import Loading from "../components/Loading";
import { useGlobalContext } from "../context";
import CreatePost from "./createPost";

export default function MyPost() {
  const { setEditPostData, postInfo, createPostModal, editPostData,newpost,
    setnewpost,setCreatePostModal,postUpdate, setPostUpdate } =
    usePostContext();
  const { modifyModal, loading, setLoading } = useGlobalContext();
  const [startIndex, setStartIndex] = useState(0);
  const [mypost, setmypost] = useState();
  const [isEmpty, setIsEmpty] = useState(false);
  const [loading1,setLoading1]=useState(false);

  const observer = React.useRef();
  const markerRef = React.useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setStartIndex((prevStartIndex) => prevStartIndex + 18);
        }
      });
      if (node) observer.current.observe(node);
    },
    [setStartIndex]
  );
  const getmypost = async (startIndex) => {
    try {
      const response = await postapi.MyPromotion(startIndex);
      setLoading(false);
      setLoading1(false);
      if (response.data.error === "") {
        if(startIndex==0){
          setmypost(response.data.data);
          setPostUpdate(false);
          console.log(response.data.data)
        }
        else{
          setmypost([...mypost,...response.data.data])
        }
        if(response.data.data.length == 0){
          setIsEmpty(true);
        }
      }
    } catch (error) {
      notify("error", "Error While fetching posts");
    }
  };

  React.useEffect(() => {
    if(startIndex!=0 && !isEmpty){
      setLoading1(true)
      getmypost(startIndex);
    }
  }, [startIndex]);

  React.useEffect(() => {
    if(postUpdate && startIndex==0){
      setLoading(true);
      getmypost(0);
    }
  }, [postUpdate]);

  //   const HandleEdit = async () => {
  //     if(imageUrl)
  //     {
  //       const response = await postapi.EditPromotion(image4.id, detail, imageUrl);
  //       if(response.status === 200)
  //       {
  //         notify("success", "Post Edited Successfully...");
  //         getmypost();
  //       }
  //       else
  //       {
  //         notify("error", "Something went Wrong");
  //       }
  //     }
  //     else
  //     {
  //       const response = await postapi.EditPromotion(image4.id, detail, image4.image);
  //       if(response.status === 200)
  //       {
  //         notify("success", "Post Edited Successfully...");
  //         getmypost();
  //       }
  //       else
  //       {
  //         notify("error", "Something went Wrong");
  //       }
  //     }
  //   };

  //   const HandleDelete = async (postId) => {
  //     try {
  //       const response = await postapi.DeletePost(0, postId);
  //       if (response.status === 200) {
  //         notify("success", "Post Deleted Successfully...");
  //         // After successful deletion, update the 'mypost' state
  //         setmypost((prevPosts) => prevPosts.filter((post) => post.id !== postId));
  //       }
  //     } catch (error) {
  //       // Handle error here if needed
  //       console.error("Error deleting post:", error);
  //     }
  //   };
  console.log(modifyModal,createPostModal,editPostData,postInfo);
  return (
    <>
            <div className="myblog-blog-view">
            <div className="myblog-blog-page-heading">
              <span>My Ads</span>
            </div>
          </div>
<div className="addBlogButton postaad">
            <button onClick={() => {
            setnewpost(true);
            setEditPostData([]);
            setCreatePostModal(true);
          }} >Post A Advertisement</button>
          </div>
      {mypost && !loading ? (
        <div className="my-post-container">
           {
            newpost && createPostModal && 
            <div className="postOverlay">
              <CreatePost editData={[]} />
          </div>
          }
          {modifyModal && (
            <div className="postOverlay">
              {createPostModal && editPostData ? (
                <CreatePost editData={editPostData} />
              ) : (
                <PostCardModal
                  id={postInfo[0]}
                  profileInfo={{ img: postInfo[1], isLiked: postInfo[2] }}
                />
              )}
            </div>
          )}
          {mypost.map((elm, index) => {
            return (
              <section key={index} className="MyBlogContainer MyBlogContainer1">
                <PostCard data={elm} key={index} />
              </section>
            );
          })}
          {mypost.length > 0 && !isEmpty && <div className="marker" ref={markerRef} />}
          {loading1 && <div className="blogposts_loading"><Loading /></div>}
          {
            isEmpty &&  <div className="allfetched">"That's all we have"</div>
          }
        </div>
      ) : isEmpty ? (
        <div className="EmptyBlogContainer">No Posts Yet...</div>
      ) : (
         <Loading />
      )}
    </>
  );
}
