import React from 'react';
import PopularProductCard from './popularProductCard';
import scrollright from '../images/scrollright.svg'
import scrollleft from '../images/scrollleft.svg';
import '../css/popularProductsSubContainer.css';
import '../css/carousel.css';
import { useNavigate } from 'react-router-dom';
export default function PopularProductsSubContainer(props) {
  // console.log(props)

  //  console.log(props.popularproduct.length);
  const [isHovered, setIsHovered] = React.useState(false);

  const wrapperRef = React.useRef(null);
  const scrollLeft = (e) => {
    // console.log(e)
    // console.log(wrapperRef.current.offsetWidth)
    // console.log(props.products.length * 200)
    let x = wrapperRef.current.scrollLeft;
    let y = wrapperRef.current.scrollTop;
    // console.log(x)
    wrapperRef.current.scrollLeft += 800;
  }
  const scrollRight = (e) => {
    // console.log(e);
    // console.log(wrapperRef.current.offsetWidth);
    // console.log(props.products.length * 200);
    let x = wrapperRef.current.scrollLeft;
    let y = wrapperRef.current.scrollTop;
    wrapperRef.current.scrollLeft -= 800;
  }
  // console.log('rerendered subcontainerof products')


  const navigate = useNavigate();
  function move() {

    navigate(`/Sub_serviceProduct/${props.sub_service_id}/${props.category}`);
  }
  function move2() {
    console.log(props)
    console.log([props.service_id, props.sub_service_id, JSON.stringify(null)]);


    localStorage.setItem('productData', [
      props.service_id,
      props.sub_service_id, JSON.stringify(null)
    ]);

    navigate("/productprovider");

  }

  return (
    <React.Fragment>

      <div className='popular-product-container'>
        <div className='popular-product-container-info-heading'>
          <div className='popular-product-category' onClick={move}>
     {props.category}
          </div>
          <div className="more-products" onClick={move2}>View All</div>
        </div>
        <div className={`popular-products-per-category-head ${isHovered ? 'hovered' : ''}`}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          style={{ marginBottom: "10px" }}
        >
          <div className='carousel-scroll'>
            <button
              className='carousel-scroll-left'
              onClick={scrollRight}
            >
              <img src={scrollleft}></img>
            </button>

            <button
              className='carousel-scroll-right'
              onClick={scrollLeft}
            >
              <img src={scrollright}></img>
            </button>
          </div>
          <div className='popular-products-per-category popular-products-per-category-for-mobile-view' ref={wrapperRef}>
            {props.products.map((product) => (

              <PopularProductCard
                key={product.id}
                productid={product.id}
                name={product.name}
                logo={product.logo}
                price={product.price}
                product_type={product.product_type}
                service_id={product.service_id}
                sub_service_id={product.sub_service_id}
                type_id={product.producttype_id}
                qty_list={product.qty_list}
                bran_name={product.bran_name}

       
              // type_id2={
              //   props.product_type && props.product_type[index] ? props.product_type[index].product_type_id : -1
              // }
              ></PopularProductCard>

            ))}








            {/* <button className='popular-products-scroll-right' onClick={scrollRight}>&#60;</button> */}
          </div>
        </div>

      </div>

    </React.Fragment>
  );
}






