import React, { Component } from "react";
import "./chat-css/ChatBoxperson.css";
import { useGlobalContext } from "../context";

// import { useGlobalContext } from '../context';
export default function ChatBoxPerson(props) {
  // const { Userprofile } = useGlobalContext();
  // console.log(props);
  const [display, setDisplay] = React.useState(false);
  const modalRef = React.useRef(null);
  const [isBlocked, setIsBlocked] = React.useState(props.data.is_block);
  const { setChatScreen } = useGlobalContext();

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setDisplay(false);
    }
  };
  const handledeleteFriend = async () => {
    const { chat_id } = props.data;
    props.deleteFriend(chat_id);
  };
  const handleblockfriend = async () => {
    const { chat_id, is_block } = props.data;
    props.handleBlockUser(chat_id, is_block);
  };
  const ShowChat = () => {
    props.user(props.data);
    props.messenger(props.data.chat_id);
    setChatScreen(true);
  };
  function ChangeDisplay1() {
    setDisplay(!display);
  }
  return (
    <React.Fragment>
      <div className="chat-box-person-icons">
        <div className="chat-box-person-icons-col-1" onClick={ShowChat}>
          <div className="chat-box-person-icons-image">
            <img src={props.data.profile_image} alt="" />
          </div>
          <div className="chat-box-person-icons-username">
            {props.data.username}
            <span>stray message</span>
          </div>
        </div>

        <div className="chat-box-person-icons-col-3" ref={modalRef}>
          <div className="chat-box-person-card-delete-col3">
            <div onClick={ChangeDisplay1}></div>
            <div onClick={ChangeDisplay1}></div>
            <div onClick={ChangeDisplay1}></div>

            {display && (
              <section
                class="chat-box-person-card-delete-col3-thredots"
                onClick={ChangeDisplay1}
              >
                <span onClick={handledeleteFriend}>Delete</span>

                <span onClick={handleblockfriend}>
                  {props.data.is_block ? "Unblock" : "Block"}
                </span>
              </section>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
