import React, { useState } from "react";
import { Link } from "react-router-dom";
import Modal from "@mui/material/Modal";

import "../css/login-sidebar.css";
import api from "../api/api";
import { useGlobalContext } from "../context";
import logo from "../images/putatoepass1.svg";
import google from "../images/google.svg";
import presignup from "../aes";
import { BsArrowLeft } from "react-icons/bs";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import notify from "../error";
import eyeclose from "../images/eyeclose-pass.svg";
import eye from "../images/eye-pass.svg";

export default function LoginSidebar(props) {
  const {
    setLoginDetail,
    loginPage,
    setLoginPage,
    setAuthtemp,
    setMobileNumber,
    setTimer,
    setUserProfile,
    fetchunreadNotification,
    loading,
    setLoading,
    changeInCart,
    setChangeInCart,
    setLoginModal,
    setOtptoken,
    // setLoginwithotp,
    // loginwithotp,
  } = useGlobalContext();

  const [localMobileNumber, setLocalMobileNumber] = React.useState();
  const [firstName, setFirstName] = React.useState("");
  const [refral, setRefral] = React.useState();
  const [lastName, setLastName] = React.useState("");
  const [page, setPage] = React.useState(1);
  const [password, setPassword] = React.useState();
  const [showPass, setShowPass] = React.useState(true);

  const [loginbuttonpressed, setLoginbuttonpressed] = React.useState(false);
  const [loginstared, setLoginstared] = useState(false);

  const wrapperRef = React.useRef(null);

  OutsideAlerter(wrapperRef);

  function OutsideAlerter(ref) {
    React.useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          // console.log(ref.current);
          setLoginPage(0);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const ChangePageLogin = () => {
    setPage(1);
  };

  const ChangePageNewAccount = () => {
    setPage(2);
  };

  const UserLocation = async () => {
    setLoading(true);
    const response = await api.UserLocationApi();
    console.log(response);
    if ("token" in response.data && response.data["token"]) {
      console.log("yes authtoken set");
      localStorage.setItem("authtoken", response.data["token"]);
      // let keyus=[data[keys]]
      console.log(response.data["token"], "token");
      localStorage.setItem("userkey", JSON.stringify(response.data["keys"]));
      console.log("presignup called");
      presignup();
      console.log(loading, "afterpresignup", "loading in after login");
      setLoading(false);
    } else {
      console.log("notoken");
      alert("no yoken");
    }
    // presignup()
  };

  const userlocationresend = async () => {
    const response = await api.UserLocationApi();
    if ("token" in response.data) {
      localStorage.setItem("authtoken", response.data["token"]);
      localStorage.setItem("userkey", JSON.stringify(response.data["keys"]));
      presignup();
    }
  };

  // function for login with otp 
  const LoginWithOtpMobileFunc = async () => {
    // Validate mobile number or username
    if (!localMobileNumber) {
      notify("error", "Mobile number or Username is required");
      return;
    }

    const input = localMobileNumber.trim().split(" ")[0];
    const isNumeric = /^\d+$/;

    // Validate input
    if (isNumeric.test(input)) {
      // Validate mobile number
      if (!/^\d{10}$/.test(input)) {
        notify("error", "Enter a valid 10-digit mobile number.");
        return;
      }
    } else {
      // Validate username
      if (input.length < 6 || input.length > 15) {
        notify("error", "Username must be between 6 and 15 characters.");
        return;
      }
    }

    try {
      setLoginbuttonpressed(true);
      if (isNumeric.test(input)) {
        const response = await api.LoginWithOtpMobile("+91" + input);
        if (response && response.data.token) {
          setAuthtemp(response.data.token);
          setOtptoken(response.data.token);
          localStorage.setItem("otptoken", response.data.token);
          setTimer(true);
          notify("success", "OTP is sent to your number");
          setLoginPage(10);
          setLocalMobileNumber("");
          setPassword("")

          userlocationresend();
        } else if (response.data.status === false) {
          notify("error", response.data.error);
        }
      } else {
        const response = await api.LoginWithOtpUserName(input);
        if (response && response.data.token) {
          setAuthtemp(response.data.token);
          setTimer(true);
          setLoginPage(10);
          setLocalMobileNumber("");
          setPassword("")

          userlocationresend();

        } else if (response.data.status === false) {
          notify("error", response.data.error);
        }
      }
    } catch (error) {
      notify("error", error.message);
    } finally {
      setLoginbuttonpressed(false);
    }
  };


  function editMobileNumber(e) {
    const val = e.target.value;
    setLocalMobileNumber(val);
    // console.log(localMobileNumber);
  }
  function editPassword(e) {
    const val = e.target.value;
    setPassword(val);
  }
  function editFirstName(e) {
    const val = e.target.value;
    setFirstName(val);
    // console.log(firstName);
  }
  function editLastName(e) {
    const val = e.target.value;
    setLastName(val);
    // console.log(lastName);
  }
  function editReferralCode(e) {
    const val = e.target.value;
    setRefral(val);
    // console.log(refral);
  }



  const LoginWithPasswordMobileFunc = async () => {
    // Validate mobile number or username
    if (!localMobileNumber) {
      notify("error", "Mobile number or Username is required");
      return;
    }

    // Validate password
    if (!password) {
      notify("error", "Password is required");
      return;
    }

    const input = localMobileNumber.trim().split(" ")[0];
    const isNumeric = /^\d+$/;

    const handleResponse = (response) => {
      if (response && response.data) {
        if (response.data.error === "") {
          setLoginModal(true);
          localStorage.setItem("authtoken", response.data.token);
          presignup();
          setLoginDetail(response.data);
          setTimeout(getProfileAfterLogin, 1000);
          setLoginPage(0);
          setLocalMobileNumber("");
          setPassword("")
        } else {
          notify("error", response.data.error);
          UserLocation();
        }
      } else {
        notify("error", "Wrong password");
      }
    };

    try {
      setLoginbuttonpressed(true);
      if (isNumeric.test(input)) {
        // Validate mobile number
        if (!/^\d{10}$/.test(input)) {
          notify("error", "Enter a valid 10-digit mobile number.");
          return;
        }

        const response = await api.LoginWithPassWordMobile("+91" + input, password);
        handleResponse(response);
      } else {
        // Validate the length of username
        if (input.length < 6 || input.length > 15) {
          notify("error", "Username must be between 6 and 15 characters.");
          return;
        }

        const response = await api.LoginWithPassWordUserName(input, password);
        handleResponse(response);
      }
    } catch (error) {
      notify("error", error.message || "Login failed");
    } finally {
      setLoginbuttonpressed(false);


    }
  };


  const ClickForgotPass = () => {
    setLoginPage(2);
  };

  const CreateNewAccountButton = async () => {
    // Helper function to notify errors
    const notifyError = (message) => {
      notify("error", message);
      return false;
    };

    // Validate First Name
    if (!firstName) return notifyError("First Name is required");
    if (!/^[A-Za-z]+$/.test(firstName)) return notifyError("First Name should contain alphabetic characters only");
    if (firstName.length < 2 || firstName.length > 15) return notifyError("First Name should be between 2 and 15 characters");

    // Validate Last Name
    if (!lastName) return notifyError("Last Name is required");
    if (!/^[A-Za-z]+$/.test(lastName)) return notifyError("Last Name should contain alphabetic characters only");
    if (lastName.length < 2 || lastName.length > 15) return notifyError("Last Name should be between 2 and 15 characters");

    // Validate Mobile Number
    if (!localMobileNumber) return notifyError("Mobile Number is required");
    const mobileNumber = localMobileNumber.split(" ")[0];
    if (!/^[1-9][0-9]{9}$/.test(mobileNumber)) return notifyError("Invalid mobile number format");

    try {
      // Show loader
      setLoginstared(true);

      // Set mobile number and previous token
      setMobileNumber(mobileNumber);
      localStorage.setItem("prevtoken", localStorage.getItem("authtoken"));

      // Register User
      const response = await api.RegisterUSer(firstName, lastName, "+91" + mobileNumber, refral, 12);
      if (response.data.error === "") {
        // Successful registration
        localStorage.setItem("authtoken", response.data.token);
        // console.log(localStorage.getItem("authtoken"));
        presignup();
        setTimer(true);
        setLoginPage(9);
      } else {
        // Registration error
        notify("error", response.data.error);
        UserLocation();
      }
    } catch (error) {
      // API error
      notify("error", error.message || error);
    } finally {
      // Hide loader
      setLoginstared(false);
    }
  };




  const login = useGoogleLogin({
    onSuccess: async (respose) => {
      try {
        const res = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${respose.access_token}`,
            },
          }
        );

        console.log(res.data);
        setLoginDetail(res.data);

        // console.log(loginDetail.name);
        loginWithGoogleApiResponse(res.data);
      } catch (err) {
        console.log(err);
      }
    },
  });


  const loginWithGoogleApiResponse = async (data) => {
    const response = await api.LoginWithSocialMedia(
      "google",
      data.sub,
      data.email,
      data.name
    );
    console.log(response);
    setLoginDetail(response.data);
    localStorage.setItem("authtoken", response.data.token);
    // presignup();
    const myTimeout = setTimeout(getProfileAfterLogin, 5000);
  };
  const [abc, setAbc] = React.useState();

  const getProfileAfterLogin = async () => {
    // setLoginLoading(true);
    const response2 = await api.ProfileApi();
    setAbc(response2.data);
    // localStorage.setItem("user",response2.data)
    if (response2.data.error === "") {
      setLoginPage(0);
      setChangeInCart(!changeInCart);
      notify("success", "successful login");
      setLoginModal(false);
      fetchunreadNotification();
    } else {
      notify("error", "login failed");
    }
  };

  React.useEffect(() => {
    console.log(abc);
    setUserProfile(abc);
    if (abc) {
      console.log(abc);
      console.log(JSON.stringify(abc));
      localStorage.setItem("UserProfile", JSON.stringify(abc));
    }
  }, [abc]);

  const ChangeEyeNewPass = () => {
    setShowPass(!showPass);
  };

  const onCross = () => {
    setLoginPage(0);
  };

  // console.log("here is mylogin page");
  return (
    <React.Fragment>
      {loginPage === 1 && (
        <div className={props.view} ref={wrapperRef}>
          {/* <div className='close-login-sidebar'>
         <span onClick={onCross}>X</span>
       </div> */}
          <div className="login-box-back-button" onClick={onCross}>
            <BsArrowLeft className="back-icon  m-0 fw-bold" />
          </div>
          <div className="login-box-sidebar-logo">
            <img src={logo}></img>
          </div>
          <div className="login-box-sidebar-welcome">
            Welcome To <span>PUTATOE</span>
          </div>
          {/* <div className="login-box-sidebar-text">
            Before you get started, you must login or register if you don't
            already have an account.
          </div> */}
          {page === 1 ? (
            <>
              <div className="login-box-sidebar-option">
                <div>LOGIN</div>
                <span onClick={ChangePageNewAccount}>CREATE NEW ACCOUNT</span>
              </div>
              <div className="login-box-sidebar-input-box">
                <span className="compulsory">
                  <span>Mobile Number or Username </span>
                  <span className="star" style={{ color: "red" }}>
                    *
                  </span>
                </span>

                <input
                  placeholder="Enter Mobile Number or UserName"
                  type="text"
                  pattern="[1-9]{1}[0-9]{9}"
                  size="1"
                  value={localMobileNumber}
                  onChange={editMobileNumber}
                //  oninput="checkIfValidIndianMobileNumber(value, 'login_mobile_number')"
                ></input>

                {!showPass ? (
                  <>
                    <span>Password</span>
                    <div>
                      <input
                        placeholder="Enter Password"
                        type="text"
                        pattern="[1-9]{1}[0-9]{9}"
                        size="1"
                        value={password}
                        onChange={editPassword}
                      //  oninput="checkIfValidIndianMobileNumber(value, 'login_mobile_number')"
                      ></input>
                      <img src={eye} onClick={ChangeEyeNewPass}></img>
                    </div>
                  </>
                ) : (
                  <>
                    <span>Password</span>
                    <div>
                      <input
                        placeholder="Enter Password"
                        type="password"
                        pattern="[1-9]{1}[0-9]{9}"
                        size="1"
                        value={password}
                        onChange={editPassword}
                      //  oninput="checkIfValidIndianMobileNumber(value, 'login_mobile_number')"
                      ></input>
                      <img src={eyeclose} onClick={ChangeEyeNewPass}></img>
                    </div>
                  </>
                )}
              </div>
              <div
                className="login-box-sidebar-forgot-password"
                onClick={ClickForgotPass}
              >
                Forgot Password?
              </div>
              <div className="login-box-sidebar-button-login ">
                {loginbuttonpressed ?
                  <div class="spinner-border text-dark" role="status">
                    <span class="visually-hidden"></span>
                  </div>
                  :
                  <>
                    <button onClick={() => { LoginWithPasswordMobileFunc(); }}>Login</button>
                    <button onClick={LoginWithOtpMobileFunc}>Login With O.T.P</button>
                  </>}
              </div>
            </>
          ) : (
            <>
              <div className="login-box-sidebar-option">
                <span onClick={ChangePageLogin}>LOGIN</span>
                <div>CREATE NEW ACCOUNT</div>
              </div>
              <div className="login-box-sidebar-input-box-new-account">
                <span className="compulsory">
                  <span>First Name </span>
                  <span className="star" style={{ color: "red" }}>
                    *
                  </span>
                </span>
                <input
                  placeholder="Enter First Name"
                  type="text"
                  pattern="[1-9]{1}[0-9]{9}"
                  size="1"
                  value={firstName}
                  onChange={editFirstName}
                //  oninput="checkIfValidIndianMobileNumber(value, 'login_mobile_number')"
                ></input>
                <span className="compulsory">
                  <span>Last Name </span>
                  <span className="star" style={{ color: "red" }}>
                    *
                  </span>
                </span>
                <input
                  placeholder="Enter Last Name"
                  type="text"
                  value={lastName}
                  onChange={editLastName}
                //  oninput="checkIfValidIndianMobileNumber(value, 'login_mobile_number')"
                ></input>
                <span className="compulsory">
                  <span>Mobile Number </span>
                  <span className="star" style={{ color: "red" }}>
                    *
                  </span>
                </span>
                <input
                  placeholder="Enter Mobile Number"
                  type="text"
                  pattern="[1-9]{1}[0-9]{9}"
                  size="1"
                  maxLength="10"
                  value={localMobileNumber}
                  onChange={editMobileNumber}
                //  oninput="checkIfValidIndianMobileNumber(value, 'login_mobile_number')"
                ></input>
                <span>Referral Code (optional)</span>
                <input
                  placeholder="Enter Referral Code (optional)"
                  type="text"
                  value={refral}
                  onChange={editReferralCode}
                //  oninput="checkIfValidIndianMobileNumber(value, 'login_mobile_number')"
                ></input>
              </div>
              <div className="login-box-sidebar-button-login-new-account">
                {loginstared ?
                  <div class="spinner-border" role="status">
                    <span class=" sr-only">Loading...</span>
                  </div>
                  :
                  <button
                    onClick={CreateNewAccountButton}
                  >
                    Create Account
                  </button>}

              </div>
              <div className="login-box-sidebar-description-terms">
                By clicking LogIn, LogIn with O.T.P or Continue with google ,
                you agree to our{" "}
                <Link to="/terms-and-conditon">Terms and Conditions</Link> and{" "}
                Privacy Statements
              </div>
            </>
          )}

          <div className="login-box-sidebar-hr"></div>
          <div className="login-box-sidebar-hr-text">
            <span>Or Continue With</span>
          </div>
          <div className="login-box-sidebar-google-button mt-4">
            <GoogleOAuthProvider clientId="234552605255-f6plqcaia9v3t8cqheqhrc7aiftsl6ag.apps.googleusercontent.com">
              <button onClick={login} id="googleLoginButton">
                <img src={google} alt="google"></img>
                Google
              </button>
            </GoogleOAuthProvider>
          </div>

          <div className="login-box-sidebar-description-terms mt-5">
            By clicking LogIn, LogIn with O.T.P or Continue with google ,
            you agree to our{" "}
            <Link to="/terms-and-conditon">Terms and Conditions</Link> and{" "}
            Privacy Statements
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
