import React from 'react'
import './css/FollowList.css'

const FollowingCard = ({FollowingList}) => {
  return (
    <React.Fragment>
        <div className='follow-list-all-cards-container'>
        {
            FollowingList.length ? (
            FollowingList.map((following) => {
                return(
                    <div className='follow-list-following-card-container'>
                    <div className='follow-list-following-card'>
                        <div className='follow-list-following-card-image-container'>
                            <img src={following.logo} alt='following provider logo' width='50'/>
                        </div>
                        <div className='follow-list-following-card-content'>
                            <p style={{color: "#00838f",fontSize: "16px"}}><b>{following.provider}</b></p>
                            <p style={{fontSize: "14px"}}>Description : <span style={{color: "rgba(0,0,0,0.7)"}}>{following.discription}</span></p>
                            <p style={{fontSize: "14px"}}>Serviceable distance : <span style={{color: "rgba(0,0,0,0.7)"}}>{following.distance} kms</span></p>
                        </div>
                    </div>
                    </div>
                )
            })) : (
                <div className='follow-list-following-card-container'>
                <div className='follow-list-following-card follow-list-following-card-no-following'>
                    <h4><b>No Followings Found</b></h4>
                    <p>You haven't followed any service provider yet</p>
                </div>
                </div>
            )
        }
        </div>
    </React.Fragment>
  )
}

export default FollowingCard