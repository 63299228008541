import React, { Component } from "react";
import "../css/addmoneywallet.css";
import notify from "../../error";
import girl from "../myprofileIcoons/girl.svg";
import MyWalletapi from "../../api/MyWalletapi";
import api from "../../api/api";
import { useGlobalContext } from "../../context";
export default function AddMoney(props) {
  const { Userprofile, setUserProfile } = useGlobalContext();
  const wrapper = React.useRef(null);
  OutsideAlerter(wrapper);
  function OutsideAlerter(ref) {
    React.useEffect(() => {
      function handleClickDetailOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          console.log(ref.current);
          props.close();
        }
      }
      document.addEventListener("mousedown", handleClickDetailOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickDetailOutside);
      };
    }, [ref]);
  }

  function handleChange(event) {
    console.log(event);
    const { name, value, type, checked } = event.target;
    setFormData(value);
    console.log(formData);
  }
  const [formData, setFormData] = React.useState("");

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  const AddMoneyToWallet = async () => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      notify("error", "Razorpay SDK failed to load. Are you online?");
      return;
    }

    const response = await MyWalletapi.RazorAddMoney(parseInt(formData));
    console.log(response);
    let data = response.data;
    if (data.error !== "") {
      // console.log()
      notify("warn", data.error);
    } else if (data.rpay_response.status === "created") {
      var options = {
        key: data["rpay_response"]["key_id"],
        amount: data["rpay_response"]["amount"],
        currency: data["rpay_response"]["currency"],
        name: "PUTATOE SOLUTIONS PVT. LTD.",
        description: "Pay For Your Order",
        image: "https://i.ibb.co/ctGstkw/logo.png",
        order_id: data["rpay_response"]["id"],
        handler: async function (response) {
          const data2 = {
            amount: parseInt(formData),
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_order_id: response.razorpay_order_id,
            razorpay_signature: response.razorpay_signature,
            putatoe_order_id: data["rpay_response"]["putatoe_order_id"],
          };

          const response3 = await MyWalletapi.RazorPayWalletVerify(
            data2.amount,
            data2.putatoe_order_id,
            data2.razorpay_order_id,
            data2.razorpay_payment_id,
            data2.razorpay_signature
          );
          console.log(response3);
          let data3 = response3.data;
          if (data3["status"] == true) {
            notify("success", "Payment Successful!");
            //  document.location.href = 'OrderConfirmed.html?id=' + data['id'];
            // moveToConfirmationPage(data['id']);

            const responset = await api.ProfileApi();
            if (responset.data.error !== "") {
              notify(
                "error",
                "unable to update your wallet balance login again to continue"
              );
            } else {
              let abc = responset.data;
              setUserProfile((prev) => {
                console.log(abc);
                console.log(JSON.stringify(abc));
                localStorage.setItem("UserProfile", JSON.stringify(abc));
                return abc;
              });
            }
          } else {
            notify("error", "Payment unsuccessful");
          }
        },
        prefill: {
          name: data["rpay_response"]["name"],
          email: data["rpay_response"]["email"],
          contact: data["user_phone"],
        },
        notes: {
          address: "Putatoe Technologies",
        },
        theme: {
          color: "#00838f",
        },
      };
      var rzp1 = new window.Razorpay(options);
      rzp1.on("payment.failed", function (response) {
        notify("error", "Payment failed due to banks issue");
      });
      rzp1.open();
    }
  };
  const [isOpen, setIsOpen] = React.useState(true);
  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <React.Fragment>
      {isOpen && (
        <>
          <div className="my-wallet-profile-add-money-background">
            <div className="my-wallet-profile-page-addMoney-modal-image">
              <img src={girl} alt="Girl" />
            </div>
            <div
              className="my-wallet-profile-page-addMoney-modal"
              ref={wrapper}
            >
              <button className="close-icon" onClick={handleClose}>
                &times;
              </button>
              <p>Add Money To Wallet</p>
              <div>
                <section>
                  <span>&#8377;</span>
                  <input
                    type="number"
                    value={formData}
                    name="name"
                    onChange={handleChange}
                    placeholder="Enter your amount"
                    required
                  ></input>
                </section>
                <button onClick={AddMoneyToWallet}>Submit</button>
              </div>
            </div>
          </div>
        </>
      )}
    </React.Fragment>
  );
}
