import React, { Component } from 'react';
import newapi from '../api/newapi';
import './news-css/article.css';
import { useNavigate } from 'react-router-dom';
import defaultImage from '../images/imageNotFound.png'
export default function Article(props) {
  // const [searchTerm, setSearchTerm] = React.useState('');
  // const [category, setCategory] = React.useState('business');
  // const [news, setNews] = React.useState();
  // const fetchData = async () => {
  //   const response = await newapi.ShowNews(category, searchTerm);
  //   console.log(response);
  //   setNews(response.data.news);
  // };
  // React.useEffect(() => {
  //   fetchData();
  // }, []);

 const navigate =useNavigate();

 const navToNews =()=>
 {
  window.location.href =props.data.url;
 }
 let str='';
 if(props.data.content)
 {
  str = props.data.content.split('[')[0];
 }
 

  return (
    <React.Fragment>
      <>
        <div className='news-page-article-container' onClick={navToNews}>
          <div className='news-page-article-image'>
          {props.data.urlToImage ? (
              <img src={props.data.urlToImage} alt={props.data.title} />
            ) : (
              <img src={defaultImage} alt='Default' />
            )}
          </div>
          <div className='news-page-article-author'>
          Author: {props.data && props.data.author ? props.data.author : 'Unknown'}
          </div>
          <div className='news-page-article-author'>
          Source: {props.data && props.data.source && props.data.source.name ? props.data.source.name : 'Unknown'}
          </div>
          <div className='news-page-article-author'>
          Published On: {props.data && props.data.publishedAt ? props.data.publishedAt : 'Unknown'}
          </div>
          <div className="news-page-article-description">
          Description: {props.data && props.data.description ? props.data.description : 'No description available.'}
          </div>
          <div className="news-page-article-content">
           Content : {props.data && str}<span>Read More</span>
          </div>
        </div>
      </>
    </React.Fragment>
  );
}
