import React, { Component } from 'react';
import profileapi from '../api/profileapi';
import notify from '../error';
import './cart-css/AddNewAddresss.css';
export default function EditMyAddress(props) {
  // const [coupon, setCoupon] = React.useState();
  function HideModal() {
    props.setShow();
  }
  console.log(props)

  const [formData, setFormData] = React.useState({
    name: props.show.name || '',
    phone:props.show.phone?
      (props.show.phone[0] === '+'
        ? props.show.phone.substr(3) || ''
        : props.show.phone || ''):'',
    addressline1: props.show.addressline1 || '',
    addressline2: props.show.addressline2 || '',
    landmark: props.show.landmark || '',
    town: props.show.town || '',
    pincode: props.show.pincode || '',
    latitude: props.show.latitude || '',
    longitude: props.show.longitude || '',
    district: props.show.district || '',
    state: props.show.state || '',
    default_address: props.show.default_address || '',
    address_id: props.show.address_id,
  });
  // console.log(formData.employment);

  const onAddition = async (e) => {
    e.preventDefault();
    console.log(formData.phone.length);
    if (
      formData.name === '' ||
      formData.phone === '' ||
      formData.addressline1 === '' ||
      formData.addressline2 === '' ||
      formData.landmark === '' ||
      formData.town === '' ||
      formData.pincode === '' ||
      formData.district === '' ||
      formData.state === ''
    ) {
      notify('warn', 'Please Fill Out All The Fields');
      return;
    }

    if (formData.phone.length !== 10) {
      notify('error', 'Enter 10-digit mobile number');
      return;
    }

    let variable = formData.default_address ? 1 : 0;
    const response = await profileapi.ModifyAddress(
      formData.name,
      formData.phone,
      formData.addressline1,
      formData.addressline2,
      formData.landmark,
      formData.town,
      formData.pincode,
      formData.latitude,
      formData.longitude,
      formData.district,
      formData.state,
      variable,
      formData.address_id
    );
    console.log(response);
    // props.reload();
    if (response.data.status === true) {
      props.setShow();
      notify('success','Address Edited Successfully')
    } else {
      notify('warn', response.data.error);
      props.setShow();
    }
  };

  function handleChange(event) {
    console.log(event);
    const { name, value, type, checked } = event.target;
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: type === 'checkbox' ? checked : value,
      };
    });
    console.log(formData);
  }
  return (
    <React.Fragment>
      {props.show && (
        <>
          <div className='add-address-modal-container-background'></div>
          <div
            className={
              props.show
                ? ' add-address-modal-container add-address-modal-container-intro-in'
                : 'add-address-modal-container'
            }
          >
            <div className='add-address-modal-cross'>
              <span onClick={HideModal}>X</span>
            </div>
            <form>
              <div className='add-address-modal-coupon-enter'>
                <div className='add-address-modal-coupon-enter-input'>
                  <span>FULL NAME</span>
                  <input
                    type='text'
                    value={formData.name}
                    name='name'
                    onChange={handleChange}
                    placeholder='Name'
                    required
                  ></input>
                </div>
                <div className='add-address-modal-coupon-enter-input'>
                  <span>MOBILE NUMBER</span>
                  <input
                    type='phone'
                    value={formData.phone}
                    name='phone'
                    onChange={handleChange}
                    placeholder='10- digit mobile number'
                    required
                  ></input>
                </div>
                <div className='add-address-modal-coupon-enter-input'>
                  <span>PINCODE</span>
                  <input
                    type='text'
                    value={formData.pincode}
                    name='pincode'
                    onChange={handleChange}
                    placeholder='Pincode'
                    required
                  ></input>
                </div>
                <div className='add-address-modal-coupon-enter-input'>
                  <span>ADDRESS</span>
                  <input
                    type='text'
                    value={formData.addressline1}
                    name='addressline1'
                    onChange={handleChange}
                    placeholder='Flat , House no. , Landmark   '
                    required
                  ></input>
                </div>
                <div className='add-address-modal-coupon-enter-input'>
                  <span>LOCALITY/TOWN</span>
                  <input
                    type='text'
                    value={formData.town}
                    name='town'
                    onChange={handleChange}
                    placeholder='Town/City'
                    required
                  ></input>
                </div>
                <div className='add-address-modal-coupon-enter-input'>
                  <span>Area/Street</span>
                  <input
                    type='text'
                    value={formData.addressline2}
                    name='addressline2'
                    onChange={handleChange}
                    placeholder='Area , Street , Sector , Village'
                    required
                  ></input>
                </div>
                <div className='add-address-modal-coupon-enter-input'>
                  <span>LandMark</span>
                  <input
                    type='text'
                    value={formData.landmark}
                    name='landmark'
                    onChange={handleChange}
                    placeholder='Landmark'
                    required
                  ></input>
                </div>
                <div className='add-address-modal-coupon-enter-input'>
                  <span>STATE</span>
                  <input
                    type='text'
                    value={formData.state}
                    name='state'
                    onChange={handleChange}
                    placeholder='State'
                    required
                  ></input>
                </div>
                <div className='add-address-modal-coupon-enter-input'>
                  <span>DISTRICT</span>
                  <input
                    type='text'
                    value={formData.district}
                    name='district'
                    onChange={handleChange}
                    placeholder='District'
                    required
                  ></input>
                </div>
                <div className='add-new-address-input-check'>
                  <input
                    type='checkbox'
                    id='add-new-address-input-check-1'
                    checked={formData.default_address}
                    onChange={handleChange}
                    name='default_address'
                  />
                  <label htmlFor='add-new-address-input-check-1'>
                    Make this my default address
                  </label>
                </div>
              </div>
              <div className='add-address-modal-coupon-enter-submit'>
                <button onClick={onAddition}>UPDATE ADDRESS</button>
              </div>
            </form>
          </div>
        </>
      )}
    </React.Fragment>
  );
}

// <div className='profile-account-setting-right-new-address-input'>
//           <input
//             type='text'
//             value={formData.name}
//             name='name'
//             onChange={handleChange}
//             placeholder='Name'
//           ></input>
//           <input
//             type='text'
//             value={formData.phone}
//             name='phone'
//             onChange={handleChange}
//             placeholder='10- digit mobile number'
//           ></input>
//           <input
//             type='text'
//             value={formData.pincode}
//             name='pincode'
//             onChange={handleChange}
//             placeholder='Pincode'
//           ></input>
//           <input
//             type='text'
//             value={formData.addressline1}
//             name='addressline1'
//             onChange={handleChange}
//             placeholder='Flat , House no. , Landmark   '
//           ></input>
//           <input
//             type='text'
//             value={formData.town}
//             name='town'
//             onChange={handleChange}
//             placeholder='Town/City'
//           ></input>
//           <input
//             type='text'
//             value={formData.addressline2}
//             name='addressline2'
//             onChange={handleChange}
//             placeholder='Area , Street , Sector , Village'
//           ></input>
//           <input
//             type='text'
//             value={formData.state}
//             name='state'
//             onChange={handleChange}
//             placeholder='State'
//           ></input>
//           <input
//             type='text'
//             value={formData.district}
//             name='district'
//             onChange={handleChange}
//             placeholder='District'
//           ></input>
//         </div>
//         <div className='profile-account-setting-right-new-address-input-check'>
//           <input
//             type='checkbox'
//             id='profile-account-setting-right-new-address-input-check-1'
//             checked={formData.default_address}
//             onChange={handleChange}
//             name='default_address'
//           />
//           <label htmlFor='profile-account-setting-right-new-address-input-check-1'>
//             Make this my default address
//           </label>
//         </div>
