import React, { Component } from "react";
import "./css/redem.css";
import downimg from "../myprofileIcoons/Group.svg";
import girl from "../myprofileIcoons/girl.svg";
import MyWalletapi from "../../api/MyWalletapi";
import notify from "../../error";
import api from "../../api/api";
import { useGlobalContext } from "../../context";
export default function RedemAsCredit(props) {
  const { Userprofile, setUserProfile } = useGlobalContext();
  const wrapper = React.useRef(null);
  OutsideAlerter(wrapper);
  function OutsideAlerter(ref) {
    React.useEffect(() => {
      function handleClickDetailOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          console.log(ref.current);
          props.close();
        }
      }
      document.addEventListener("mousedown", handleClickDetailOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickDetailOutside);
      };
    }, [ref]);
  }

  function handleChange(event) {
    console.log(event);
    const { name, value, type, checked } = event.target;
    setFormData(value);
    console.log(formData);
  }
  const [formData, setFormData] = React.useState("");

  const SubmitAdhar = async () => {
    const response = await MyWalletapi.ADDADHAR(formData);
    console.log(response);
    if (response.data.error !== "") {
      notify("error", response.data.error);
    } else {
      notify("success", "Aadhar Card is valid ");
      const response2 = await MyWalletapi.ConvertRewardToMoney();
      console.log(response2);
      if (response2.data.error !== "") {
        notify("error", response2.data.error);
      } else {
        notify("success", "Reward Converted To Wallet Money");
        const responset = await api.ProfileApi();
        if (responset.data.error !== "") {
          notify(
            "error",
            "unable to update your wallet balance login again to continue"
          );
        } else {
          let abc = responset.data;
          setUserProfile((prev) => {
            console.log(abc);
            console.log(JSON.stringify(abc));
            localStorage.setItem("UserProfile", JSON.stringify(abc));
            return abc;
          });
        }
        setisOpen(false);
      }
    }
  };
  const [isOpen, setisOpen] = React.useState(true);
  const handleCloseIcon = () => {
    setisOpen(false);
  };
  return (
    <React.Fragment>
      {isOpen && (
        <>
          <div className="my-wallet-profile-page-redem-background">
            <div className="my-wallet-profile-page-redem-modal-image">
              <img src={girl}></img>
            </div>
            <div className="my-wallet-profile-page-redem-modal" ref={wrapper}>
              <button className="close-icon" onClick={handleCloseIcon}>
                &times;
              </button>
              <p>
                Redeem your credit scores by entering your Aadhaar Card Number
              </p>
              <div>
                <input
                  type="text"
                  value={formData}
                  name="name"
                  onChange={handleChange}
                  placeholder="Enter your AADHAAR CARD NUMBER"
                  required
                ></input>
                <button onClick={SubmitAdhar}>Submit</button>
              </div>
            </div>
          </div>
        </>
      )}
    </React.Fragment>
  );
}
