import React, { Component } from "react";
import "../serviceprovider-css/checkList.css";
import { useProductContext } from "../productPageContext.js";

export default function CheckList(props) {
  // const[formData,setFormData]=React.useState({})

  const {
    quantityData,
    setQuantityData,
    brandData,
    setbrandData,
    productData,
    setproductData,colorData, setColorData
  } = useProductContext();
  const [showAll, setShowAll] = React.useState(false);

  const shouldApplyOverflow = props.items.length > 10;

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  console.log(props);
  function handleChange(event) {
    const { name, value, type, checked } = event.target;
    console.log({ name, value, type, checked });

    if (props.name === "Product Type") {
      console.log("here3", props.name);
      if (checked) {
        setproductData((prevData) => [...prevData, parseInt(value)]);
      } else {
        setproductData((prevData) =>
          prevData.filter((item) => item !== parseInt(value))
        );
      }
    } else if (props.name === "Brands") {
      if (checked) {
        setbrandData((prevData) => [...prevData, parseInt(value)]);
      } else {
        setbrandData((prevData) =>
          prevData.filter((item) => item !== parseInt(value))
        );
      }
    } else if (props.name === "Quantity") {
      if (checked) {
        setQuantityData((prevData) => [...prevData, parseInt(value)]);
      } else {
        setQuantityData((prevData) =>
          prevData.filter((item) => item !== parseInt(value))
        );
      }
    }
      else if (props.name === "Colors") {
        if (checked) {
          setColorData((prevData) => [...prevData,value]);
        } else {
          setColorData((prevData) =>
            prevData.filter((item) => item !== value)
          );
        }
      }
    
    
  }
  // console.log(formData)

  // console.log(props)
  return (
    <React.Fragment>
      <div className="checklist-container">
        <div className="checklist-heading">{props.name}</div>
        <div className="checklist-items-container">
          <form>
            {props.items &&
              props.items.map((it) => {
                const item = Object.values(it);
                const isColorCode = typeof item[0] === 'string' && item[0].startsWith('#');
                console.log(item);
                return (
                  <div className="checklist-list">
                    <input
                      className="checklist-item"
                      type="checkbox"
                      value={item[0]}
                      name={item[1]}
                      id={item[0]}
                      onChange={handleChange}
                      checked={
                        props.name === "Product Type"
                          ? productData.includes(parseInt(item[0]))
                          : props.name === "Brands"
                          ? brandData.includes(parseInt(item[0]))
                          : props.name === "Quantity"
                          ? quantityData.includes(parseInt(item[0]))
                          :    props.name === "Colors"
                          ? colorData.includes(item[0]) :false
                      }
                    ></input>
  {isColorCode ? (
          <label htmlFor={item[0]} ><span style={{ backgroundColor: item[0] }}></span></label>
        ) : (
          <label htmlFor={item[0]}>{item[1]}</label>
        )}
                  </div>
                );
              })}
          </form>
        </div>
      </div>
    </React.Fragment>
  );
}
