import React, { Component } from "react";
import "./css/searchMainViewDesktop.css";
import { useLocation, useNavigate } from "react-router-dom";
import SearchApi from "../api/SearchApi";
import notify from "../error";
import starf from "../images/Starf.svg";
import { motion } from "framer-motion";
import Loading from "../components/Loading";
import { useGlobalContext } from "../context";

export default function SearchDesktopMainView() {
  const [isLoading, setIsLoading] = React.useState(false);
  const { isMobileView } = useGlobalContext();
  const location = useLocation();
  let { string } = location.state;
  const [inputVal, setInputVal] = React.useState(string);
  const [type, setType] = React.useState("product_list");
  const [searchResult, setSearchResult] = React.useState();

  React.useEffect(() => {
    if (typeof string === "object") {
      console.log(string[0]);
      SearchMyInput(string[0]);
    } else {
      SearchMyInput(string);
      console.log(string);
    }
  }, [string]);

  const SearchMyInput = async (searchValue) => {
    setIsLoading(true);
    try {
      const response = await SearchApi.SearchAll(searchValue);
      console.log(response);
      if (response.data && response.data.error !== "")
        notify("error", response.data.error);
      else setSearchResult(response.data);
    } catch (err) {
      notify("error", err);
    }
    setIsLoading(false);
  };

  const navigate = useNavigate();
  const GoToService = (user, id, parent_id) => {
    // console.log([props.user, props.id, props.parent_id]);
    // setUserName([props.user,props.id,props.parent_id])
    // localStorage.setItem("driverData", [user, id]);
    // console.log(
    //   "local storage",
    //   localStorage.getItem("driverData").split(",")[0],
    //   localStorage.getItem("driverData").split(",")[1],
    //   localStorage.getItem("driverData").split(",")[2]
    // );
    navigate(`/${user}`, { replace: true });
  };

  const GoToProduct = (service_id, sub_service_id, type_id) => {
    console.log([service_id, sub_service_id, type_id]);

    localStorage.setItem("productData", [service_id, sub_service_id, type_id]);
    navigate("/productprovider", { replace: true });
  };

  const tester = (v) => {
    console.log(v);
  };
  const getStyles = (isSelected) => {
    if (isMobileView) {
      return {
        fontSize: isSelected ? "24px" : "18px",
        color: isSelected ? "black" : "rgba(0, 0, 0, 0.316)",
      };
    } else {
      return {
        fontSize: isSelected ? "40px" : "30px",
        color: isSelected ? "black" : "rgba(0, 0, 0, 0.316)",
      };
    }
  };
  //framer motion guide for begining
  //mostly used motion from framer motion
  return (
    <React.Fragment>
      <>
        {/* {inputVal} */}
        <div className="search-page-desktop-main-view">
          <div className="search-page-desktop-main-view-type-select">
            <motion.span
              onClick={() => {
                setType("product_list");
              }}
              animate={getStyles(type === "product_list")}
              transition={{ type: "tween", duration: 0.5 }}
            >
              Products
            </motion.span>
            <motion.span
              onClick={() => {
                setType("serviceprovider_list");
              }}
              animate={getStyles(type === "serviceprovider_list")}
              transition={{ type: "tween", duration: 0.5 }}
            >
              Service Providers
            </motion.span>
            <motion.span
              onClick={() => {
                setType("user_list");
              }}
              animate={getStyles(type === "user_list")}
              transition={{ type: "tween", duration: 0.5 }}
            >
              Users
            </motion.span>
          </div>
          {searchResult && console.log(searchResult[`${type}`])}
          <div className="search-page-desktop-main-view-search-result-container">
            {isLoading ? (
              <Loading />
            ) : searchResult &&
              searchResult[`${type}`] &&
              searchResult[`${type}`].length > 0 ? (
              <>
                <motion.div
                  className="search-page-desktop-main-view-search-result"
                  initial={{ scale: 0.1 }}
                  animate={{
                    scale: type === "product_list" ? [0.1, 1] : [1, 0.1],
                    opacity:
                      type === "product_list"
                        ? [0, 0.1, 0.2, 0.4, 0.6, 0.8, 1]
                        : [1, 0.8, 0.6, 0.4, 0.2, 0.1, 0],
                  }}
                  transition={{ duration: 0.5 }}
                >
                  {searchResult.product_list.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="search-page-desktop-main-view-search-result-product"
                        onClick={() => {
                          GoToProduct(
                            item.service_id,
                            item.sub_service_id,
                            item.product_type_id
                          );
                        }}
                      >
                        <div className="search-page-desktop-main-view-search-result-product-left">
                          <img src={item.url} alt="" />
                        </div>
                        <div className="search-page-desktop-main-view-search-result-product-right">
                          <span>{item.brand}</span>
                          <section>{item.product_name}</section>
                          <section>{item.product_type}</section>
                          <article>Service : {item.service}</article>
                          <article>
                            Service provider : {item.serviceprovider}
                          </article>
                  
                          {
                            isMobileView ?    <acticle className="priceAndQuantity">
                            <div>&#8377; {item.selling_price} | {item.quantity}</div>
                           
                          </acticle>:    <acticle className="priceAndQuantity">
                            <div>price: &#8377; {item.selling_price}</div>
                            <div>quantity: {item.quantity}</div>
                          </acticle>
                          }
                        </div>
                      </div>
                    );
                  })}
                </motion.div>
                <motion.div
                  className="search-page-desktop-main-view-search-result"
                  initial={{ scale: 0.1 }}
                  animate={{
                    scale:
                      type === "serviceprovider_list" ? [0.1, 1] : [1, 0.1],
                    opacity:
                      type === "serviceprovider_list"
                        ? [0, 0.1, 0.2, 0.4, 0.6, 0.8, 1]
                        : [1, 0.8, 0.6, 0.4, 0.2, 0.1, 0],
                  }}
                  transition={{ duration: 1 }}
                >
                  {searchResult.serviceprovider_list.map((item) => {
                    return (
                      <div
                        className="search-page-desktop-main-view-search-result-service"
                        onClick={() => {
                          GoToService(item.user_name, item.id);
                        }}
                      >
                        <div className="search-page-desktop-main-view-search-result-service-left">
                          <img src={item.logo}></img>
                        </div>
                        <div className="search-page-desktop-main-view-search-result-service-right">
                          <span>{item.provider}</span>
                          <section>{item.discription}</section>
                          <article>Distance :{item.distance} km</article>
                          <div>
                            Rating : {item.rating || 0}&nbsp;
                            <img src={starf}></img>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </motion.div>
                <motion.div
                  className="search-page-desktop-main-view-search-result"
                  animate={{
                    scale: type === "user_list" ? [0.1, 1] : [1, 0.1],
                    opacity:
                      type === "user_list"
                        ? [0, 0.1, 0.2, 0.4, 0.6, 0.8, 1]
                        : [1, 0.8, 0.6, 0.4, 0.2, 0.1, 0],
                    //  display: type === 'user_list' ? 'grid' : 'none',
                  }}
                  transition={{ duration: 1 }}
                >
                  {searchResult.user_list.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="search-page-desktop-main-view-search-result-user"
                      >
                        <div className="search-page-desktop-main-view-search-result-user-left">
                          <img src={item.profile_image}></img>
                        </div>
                        <div className="search-page-desktop-main-view-search-result-user-right">
                          <span>{item.username}</span>
                        </div>
                      </div>
                    );
                  })}
                </motion.div>
              </>
            ) : (
              <div className="search-page-desktop-main-view-search-result-no result">
                <div className="no-result-container">
                  <p>No Result Found!</p>
                  <p>Please search something different</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    </React.Fragment>
  );
}

// function Testing(props)
// {
//  props.func('a')
//  return (<>a</>)
// }

//  searchResult[`${type}`].map((item) => {
//                if (type === 'product_list') {
//                  return (
//                    <div
//                      className='search-page-desktop-main-view-search-result-product'
//                      onClick={() => {
//                        GoToProduct(
//                          item.service_id,
//                          item.sub_service_id,
//                          item.product_type_id
//                        );
//                      }}
//                    >
//                      <div className='search-page-desktop-main-view-search-result-product-left'>
//                        <img src={item.url}></img>
//                      </div>
//                      <div className='search-page-desktop-main-view-search-result-product-right'>
//                        <span>{item.brand}</span>
//                        <section>{item.product_name}</section>
//                        <article>Service : {item.service}</article>
//                        <article>
//                          Service provider : {item.serviceprovider}
//                        </article>
//                        <div>{item.quantity}</div>
//                      </div>
//                    </div>
//                  );
//                } else if (type === 'serviceprovider_list') {
//                  return (
//                    <div
//                      className='search-page-desktop-main-view-search-result-service'
//                      onClick={() => {
//                        GoToService(item.provider, item.id);
//                      }}
//                    >
//                      <div className='search-page-desktop-main-view-search-result-service-left'>
//                        <img src={item.logo}></img>
//                      </div>
//                      <div className='search-page-desktop-main-view-search-result-service-right'>
//                        <span>{item.provider}</span>
//                        <section>{item.discription}</section>
//                        <article>Distance :{item.distance} km</article>
//                        <div>
//                          Rating : {item.rating || 0}&nbsp;
//                          <img src={starf}></img>
//                        </div>
//                      </div>
//                    </div>
//                  );
//                } else if (type === 'user_list') {
//                  return (
//  <div className='search-page-desktop-main-view-search-result-user'>
//    <div className='search-page-desktop-main-view-search-result-user-left'>
//      <img src={item.profile_image}></img>
//    </div>
//    <div className='search-page-desktop-main-view-search-result-user-right'>
//      <span>{item.username}</span>
//    </div>
//  </div>
//                  );
//                }
//              })

// import React, { Component } from 'react'
// import './css/searchMainViewDesktop.css'
// import { useLocation, useNavigate } from 'react-router-dom';
// import SearchApi from '../api/SearchApi';
// import notify from '../error';
// import starf from '../images/Starf.svg';
// import {motion} from 'framer-motion'
// export default function SearchDesktopMainView()
// {

//  const location = useLocation();
//  let { string } = location.state;
//  const [inputVal,setInputVal]=React.useState(string);
//  const [type,setType]=React.useState('product_list')
//  const [searchResult,setSearchResult]=React.useState()

//  React.useEffect(()=>
//  {
//   setInputVal(string)
//  },[string])

//  const SearchMyInput=async()=>
//  {
//   const response=await SearchApi.SearchAll(inputVal)
//   console.log(response)
//   if(response.data && response.data.error!='')
//   {
//    notify('error',response.data.error)
//   }
//   else
//   {
//    setSearchResult(response.data)
//   }
//  }

//  React.useEffect(()=>
//  {
//   SearchMyInput();
//  },[inputVal])

//  React.useEffect(()=>
//  {
//   SearchMyInput();
//  },[])

//  const navigate=useNavigate();
//   const GoToService = (user, id, parent_id) => {
//     // console.log([props.user, props.id, props.parent_id]);
//     // setUserName([props.user,props.id,props.parent_id])
//     localStorage.setItem('driverData', [user, id, 2]);
//     console.log(
//       'local storage',
//       localStorage.getItem('driverData').split(',')[0],
//       localStorage.getItem('driverData').split(',')[1],
//       localStorage.getItem('driverData').split(',')[2]
//     );
//     navigate('/serviceprovider',{replace:true});
//   };

//   const GoToProduct = (service_id, sub_service_id, type_id) => {
//     console.log([service_id, sub_service_id, type_id]);

//     localStorage.setItem('productData', [service_id, sub_service_id, type_id]);
//     navigate('/productprovider', { replace: true });
//   };

//   const tester=(v)=>
//   {
//    console.log(v)
//   }

//  return (
//    <React.Fragment>
//      <>
//        {/* {inputVal} */}
//        <div className='search-page-desktop-main-view'>
//          <div className='search-page-desktop-main-view-type-select'>
//            <motion.span
//              onClick={() => {
//                setType('product_list');
//              }}
//              animate={{
//                'font-size': type === 'product_list' ? '50px' : '30px',
//                color:
//                  type === 'product_list' ? 'black' : 'rgba(0, 0, 0, 0.316)',
//              }}
//              transition={{ type: 'spring', duration: 2 }}
//            >
//              Products
//            </motion.span>
//            <motion.span
//              onClick={() => {
//                setType('serviceprovider_list');
//              }}
//              animate={{
//                'font-size': type === 'serviceprovider_list' ? '50px' : '30px',
//                color:
//                  type === 'serviceprovider_list'
//                    ? 'black'
//                    : 'rgba(0, 0, 0, 0.316)',
//              }}
//              transition={{ type: 'spring', duration: 2 }}
//            >
//              Serivce Providers
//            </motion.span>
//            <motion.span
//              onClick={() => {
//                setType('user_list');
//              }}
//              animate={{
//                'font-size': type === 'user_list' ? '50px' : '30px',
//                color: type === 'user_list' ? 'black' : 'rgba(0, 0, 0, 0.316)',
//              }}
//              transition={{ type: 'spring', duration: 2 }}
//            >
//              Users
//            </motion.span>
//          </div>
//          {searchResult && console.log(searchResult[`${type}`])}

//          {searchResult &&
//          searchResult[`${type}`] &&
//          searchResult[`${type}`].length > 0 ? (
//            type === 'product_list' ? (
//              <motion.div
//                className='search-page-desktop-main-view-search-result'
//                initial={{ scale: 0.1 }}
//                animate={{
//                  scale: [0.1, 1],
//                  opacity: [0, 0.1, 0.2, 0.4, 0.6, 0.8, 1],
//                  display: type === 'product_list' ? 'grid' : 'none',
//                }}
//                transition={{ duration: 1 }}
//              >
//                {searchResult.product_list.map((item) => {
//                  return (
//                    <div
//                      className='search-page-desktop-main-view-search-result-product'
//                      onClick={() => {
//                        GoToProduct(
//                          item.service_id,
//                          item.sub_service_id,
//                          item.product_type_id
//                        );
//                      }}
//                    >
//                      <div className='search-page-desktop-main-view-search-result-product-left'>
//                        <img src={item.url}></img>
//                      </div>
//                      <div className='search-page-desktop-main-view-search-result-product-right'>
//                        <span>{item.brand}</span>
//                        <section>{item.product_name}</section>
//                        <article>Service : {item.service}</article>
//                        <article>
//                          Service provider : {item.serviceprovider}
//                        </article>
//                        <div>{item.quantity}</div>
//                      </div>
//                    </div>
//                  );
//                })}
//              </motion.div>
//            ) : type === 'serviceprovider_list' ? (
//              <motion.div className='search-page-desktop-main-view-search-result'>
//                {searchResult.serviceprovider_list.map((item) => {
//                  return (
//                    <div
//                      className='search-page-desktop-main-view-search-result-service'
//                      onClick={() => {
//                        GoToService(item.provider, item.id);
//                      }}
//                    >
//                      <div className='search-page-desktop-main-view-search-result-service-left'>
//                        <img src={item.logo}></img>
//                      </div>
//                      <div className='search-page-desktop-main-view-search-result-service-right'>
//                        <span>{item.provider}</span>
//                        <section>{item.discription}</section>
//                        <article>Distance :{item.distance} km</article>
//                        <div>
//                          Rating : {item.rating || 0}&nbsp;
//                          <img src={starf}></img>
//                        </div>
//                      </div>
//                    </div>
//                  );
//                })}
//              </motion.div>
//            ) : (
//              <motion.div className='search-page-desktop-main-view-search-result'>
//                {searchResult.user_list.map((item) => {
//                  return (
//                    <div className='search-page-desktop-main-view-search-result-user'>
//                      <div className='search-page-desktop-main-view-search-result-user-left'>
//                        <img src={item.profile_image}></img>
//                      </div>
//                      <div className='search-page-desktop-main-view-search-result-user-right'>
//                        <span>{item.username}</span>
//                      </div>
//                    </div>
//                  );
//                })}
//              </motion.div>
//            )
//          ) : (
//            <div className='search-page-desktop-main-view-search-result-no result'>
//              no result page
//            </div>
//          )}
//        </div>
//      </>
//    </React.Fragment>
//  );
// }

// // function Testing(props)
// // {
// //  props.func('a')
// //  return (<>a</>)
// // }

// //  searchResult[`${type}`].map((item) => {
// //                if (type === 'product_list') {
// //                  return (
// //                    <div
// //                      className='search-page-desktop-main-view-search-result-product'
// //                      onClick={() => {
// //                        GoToProduct(
// //                          item.service_id,
// //                          item.sub_service_id,
// //                          item.product_type_id
// //                        );
// //                      }}
// //                    >
// //                      <div className='search-page-desktop-main-view-search-result-product-left'>
// //                        <img src={item.url}></img>
// //                      </div>
// //                      <div className='search-page-desktop-main-view-search-result-product-right'>
// //                        <span>{item.brand}</span>
// //                        <section>{item.product_name}</section>
// //                        <article>Service : {item.service}</article>
// //                        <article>
// //                          Service provider : {item.serviceprovider}
// //                        </article>
// //                        <div>{item.quantity}</div>
// //                      </div>
// //                    </div>
// //                  );
// //                } else if (type === 'serviceprovider_list') {
// //                  return (
// //                    <div
// //                      className='search-page-desktop-main-view-search-result-service'
// //                      onClick={() => {
// //                        GoToService(item.provider, item.id);
// //                      }}
// //                    >
// //                      <div className='search-page-desktop-main-view-search-result-service-left'>
// //                        <img src={item.logo}></img>
// //                      </div>
// //                      <div className='search-page-desktop-main-view-search-result-service-right'>
// //                        <span>{item.provider}</span>
// //                        <section>{item.discription}</section>
// //                        <article>Distance :{item.distance} km</article>
// //                        <div>
// //                          Rating : {item.rating || 0}&nbsp;
// //                          <img src={starf}></img>
// //                        </div>
// //                      </div>
// //                    </div>
// //                  );
// //                } else if (type === 'user_list') {
// //                  return (
//                   //  <div className='search-page-desktop-main-view-search-result-user'>
//                   //    <div className='search-page-desktop-main-view-search-result-user-left'>
//                   //      <img src={item.profile_image}></img>
//                   //    </div>
//                   //    <div className='search-page-desktop-main-view-search-result-user-right'>
//                   //      <span>{item.username}</span>
//                   //    </div>
//                   //  </div>
// //                  );
// //                }
// //              })
