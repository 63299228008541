import React from 'react';
import starf from '../images/Starf.svg';
import star from '../images/Star.svg';
import heart from '../images/heart.svg';
import phone from '../images/phone.svg';
import chat from '../images/chat.svg';
import { IoLocationSharp} from 'react-icons/io5';
import '../css/serviceCards.css';

import { useGlobalContext } from '../context'; 

export default function ServiceCards(props) {

  const {userName,setUserName,setstatusloading,statusloading } = useGlobalContext();
  // if(statusloading){
  //   setstatusloading(false);
  // }
  // console.log(props)
  // setLoading(false);
    const name=()=>  
    {
      // console.log([props.user, props.id, props.parent_id]);
      // setUserName([props.user,props.id,props.parent_id])
      localStorage.setItem('driverData', [props.user,props.id,props.parent_id,props.sub_service_id,Object.keys(props.serve)[0]]);
      // console.log(
      //   'local storage',
      //   localStorage.getItem('driverData').split(',')[0],
      //   localStorage.getItem('driverData').split(',')[1],
      //   localStorage.getItem('driverData').split(',')[2],
      //   localStorage.getItem('driverData').split(',')[3],
       
      // );
    }
  return (
    <React.Fragment>
      <div className='service-card-container' onClick= {name}>
        <img className='service-card-image' src={props.logo} alt=''></img>
        <div className='service-card-name-container'>
          <div className='service-card-name'>
            <span>{props.name}</span>
          </div>
        </div>
        <div className='service-card-contact-container'>
          <div className='service-card-contact'>CONTACT</div>
        </div>
        <div className='service-card-hover'>
          <div className='service-hover-top-row'>
            <div className='service-hover-star-heart'>
              <div className='service-card-star-container'>
                <img className='service-card-star' src={starf} alt=''></img>
                <img className='service-card-star' src={starf} alt=''></img>
                <img className='service-card-star' src={starf} alt=''></img>
                <img className='service-card-star' src={starf} alt=''></img>
                <img className='service-card-star' src={star} alt=''></img>
              </div>
              <div className='service-card-heart-container'>
                <img className='service-card-heart' src={heart} alt=''></img>
              </div>
            </div>
            <div className='book-now-container'>
              <div className='book-now'>Book Now</div>
            </div>
          </div>
          <div className='service-hover-bottom-row'>
            <div className='service-card-services'>
              <div className='service-card-service-heading'>{props.address_line1}</div>
              <div className='service-card-service-list'>
               <div className='service-card-service-subheading'>{props.serviceprovider_description}</div> 
               <div className='service-card-servicable-distance'>
               <IoLocationSharp size={17}/>
                {props.serviceable_distance}<span>Km</span></div>
              </div>
            </div>
            <div className='serive-card-chat-phone'>
              <div className='service-card-phone-container'>
                <img className='service-card-phone' src={phone} alt='star-icon'></img>
              </div>
              <div className='service-card-chat-container'>
                <img className='service-card-chat' src={chat} alt='chat-icon'></img>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
