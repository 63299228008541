import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

class Adminloginapi {
 Managerlogin(user_id, password) {
        return axios.post(
          API_BASE_URL + '/v1/api/manager_login',
          { user_id,password},
         
        );
      }
      Managerorders(start_date, end_date) {
        return axios.post(
          API_BASE_URL + '/v1/api/orders_manager_new',
          { start_date,end_date},
          {
            headers: {
              authtoken: localStorage.getItem('AdminToken'),
            },
          }
        );
      }
      UpdateProfileManager(changedFields) {
        const payload = {};

  if (changedFields.name) {
    payload.name = changedFields.name;
  }

  if (changedFields.username) {
    payload.username = changedFields.username;
  }

        return axios.post(
          API_BASE_URL + '/v1/api/UpdateProfile_Manager',
          payload,
          {
            headers: {
              authtoken: localStorage.getItem('AdminToken'),
            },
          }
        );
      }
      Managerprofile() {
        return axios.get(
          API_BASE_URL + '/v1/api/manager_profile',
          {
            headers: {
              authtoken: localStorage.getItem('AdminToken'),
            },
          }
        );
      }
      ServiceProviderlist(payload){
        return axios.post(
          API_BASE_URL + "/v1/api/availabe_sp_for_allproduct",
          payload,
          {
            headers: {
              authtoken: localStorage.getItem('AdminToken'),
            },
          }
        )
      }
      Assign_ServiceProvider(payload){
        return axios.post(
          API_BASE_URL + "/v1/api/Assign_ServiceProvider_new",
          payload,
          {
            headers:{
              authtoken:localStorage.getItem("AdminToken"),
            },
          }
        )
      }
      change_order_status_by_manager(payload){
        return axios.post(
          API_BASE_URL + "/v1/api/change_order_status_by_areamanager",
          payload,
          {
            headers:{
              authtoken:localStorage.getItem("AdminToken"),
            },
          }
        )
      }
}
export default new Adminloginapi();