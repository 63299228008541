import React, { Component } from 'react';
import PutatoeServiceApi from '../api/PutatoeServiceApi';
import PopularProductsSubContainer from '../components/popularProductsSubContainer';
import Service from '../components/service';
import PutatoeServiceOptionCards from './PutatoeServiceOptionCards';
import { useParams } from 'react-router-dom';
import '../PutatoeService-Css/PutatoeSERVICEVIEW.css';

export default function PutatoeServiceView() {
  const { name ,service} = useParams();
  // console.log(name)
  let currentUrl = window.location.href;
  // write code to get option from here
  let option = 6;
  const [popularProduct, setPopularProduct] = React.useState('');
  const [popularProductId, setPopularProductId] = React.useState('');
  const [popularSeller, setPopularSeller] = React.useState([]);
  const [OurService, setOurService] = React.useState([]);

  const fetchPopularByService = async () => {
    const response = await PutatoeServiceApi.PopularSellerAndProductData(
      name
    );
    setPopularProduct(response.data.popular_product);
    setPopularProductId(response.data.popular_product_type)
    setPopularSeller(response.data.popular_seller);
  };
  const fetchOtherService = async () => {
    const response = await PutatoeServiceApi.PopularServiceData(name);
    // setOurService(response.data)
    let arr = [];
    for (let i = 0; i < response.data.subcategory.length; i++) {
      let t = [];
      t.push(response.data.subcategory[i]);
      for (
        let j = 0;
        j < response.data.subcategory_main_list[i].available.length && j < 3;
        j++
      ) {
        t.push(response.data.subcategory_main_list[i].available[j]);
      }
      arr.push(t);
      console.log(arr);
    }
    setOurService(arr);
  };
  React.useEffect(() => {
    fetchPopularByService();
    fetchOtherService();
  }, [option]);

  return (
    <React.Fragment>
      <div className='putatoeServiceView-container'>
        {popularProduct.length>0 &&
          <>
          
          <div className='putatoeServiceView-top-heading'>TOP DEALS</div>
        <PopularProductsSubContainer
        popularproduct={popularProduct}
        service_name={service}
        product_type={popularProductId}
        ></PopularProductsSubContainer>
        </>
      }
      {popularSeller.length>0 && 
        <>
        <div className='putatoeServiceView-sec-heading-container'>
          <div className='putatoeServiceView-sec-vertical-fill'></div>
          <div className='putatoeServiceView-sec-heading'>BESTSELLERS</div>
        </div>
        <div className='putatoeServiceView-sec-data'>
          <Service
            name={service}
            popular={popularSeller}
            parent_id={name}
            ></Service>
        </div>
        </>
          }
          {OurService.length>0 &&
            <>
            <div className='putatoeServiceView-third-heading'>
          SUB-SERVICES WE PROVIDE
        </div>
        <div className='putatoeServiceView-third-data-container'>
          {OurService &&
            OurService.map((item) => {
              return (
                <PutatoeServiceOptionCards
                data={item}
                ></PutatoeServiceOptionCards>
                );
              })}
        </div>
        </>
            }
      </div>
    </React.Fragment>
  );
}
