import React from 'react';
import addpic from '../myprofileIcoons/addpic.svg';
import star from '../myprofileIcoons/star.svg';
import '../css/addServiceProvider.css';
import profileapi from '../../api/profileapi';
import myProfileServiceApi from '../../api/myProfileServiceApi';
import { IoCloseCircleSharp } from "react-icons/io5"
import notify from '../../error'
// import homepic from './myprofileIcoons/homeimage.svg';
export default function AddServiceProvider(props) {
  // const ref1=React.useRef(0);
  // const ref2 = React.useRef();

  const [editingProvider, setEditingProvider] = React.useState(null);
  const [isEditing, setIsEditing] = React.useState(false);
  const [image, setImage] = React.useState(false);
  const [formData, setFormData] = React.useState({
    FullName: '',
    emailid: '',
    mobileNumber: '',
    service: '',
    subservice: '',
    image: ''
  });

  const [formData2, setFormData2] = React.useState();
  const [subPage, setSubPage] = React.useState(true);
  const [subId, setSubId] = React.useState(['Select Your Service']);
  const fileInputRef = React.useRef(null);





  function IsActive(e) {
    setSubPage(true);
  }
  function IsActive2(e) {
    setSubPage(false);
  }



  function searchInput(e) {
    console.log(formData);
    const { name, value, type, checked, files } = e.target;
    console.log(name, value)
    console.log(name === 'service')
    if (name === 'service') {
      // Check if the service value is changing
      if (formData.service !== value) {
        // If the service changes, reset the subservice
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: type === 'checkbox' ? checked : value,
          subservice: '' // Reset subservice when service changes
        }));

        // Find the selected service's subservices
        for (let i = 0; i < serviceSubSerivce.service_list.length; i++) {
          if (value === `${serviceSubSerivce.service_list[i].id}`) {
            setSubId(serviceSubSerivce.service_list[i].sub_list);
            break;
          }
        }
      } else {
        // If the service doesn't change, keep the previous subservice value
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: type === 'checkbox' ? checked : value
        }));
      }

    }
    else if (name === 'image') {
      // Handle file upload
      const uploadedFiles = files[0];
      console.log(uploadedFiles);

      myProfileServiceApi.UploadImageOGC(uploadedFiles)
        .then(response => {
          console.log(response.data);
          if (response.data.error !== "") {
            notify("error", response.data.error);
          } else {
            const imageUrl = response.data.url;
            setImage(imageUrl);
            console.log("here", imageUrl);

            // Update the file(s) in the businessDetails state
            setFormData((prevValues) => ({
              ...prevValues,
              image: imageUrl
            }));
          }
        })
    }
    else {
      setFormData((prevFormData) => {
        return {
          ...prevFormData,
          [name]: type === 'checkbox' ? checked : value,
        };
      });
      console.log(formData);
    }
  }

  function searchservice(e) {
    console.log(formData2);
    const { name, value, type, checked } = e.target;

    setFormData2(value);
  }
  const [serviceProvider, setServiceProvider] = React.useState()
  const addedServiceProvider = async () => {
    const response = await profileapi.MyAddedServiceprovider();
    console.log(response);
    setServiceProvider(response.data)
  }
  const [serviceSubSerivce, setServiceSubSerivce] = React.useState()
  const allServicesubService = async () => {
    const response = await profileapi.AllServiceSubSerice();
    console.log(response);
    setServiceSubSerivce(response.data);
  }

  React.useEffect(() => {
    addedServiceProvider();
    allServicesubService();
  }, []);

  const serviceCountForRerender = React.useRef(0);
  const addedServiceProvider2 = React.useCallback(async () => {
    console.log(1);
    const response = await profileapi.MyAddedServiceprovider();
    console.log(response);
    setServiceProvider(response.data);
  }, [serviceCountForRerender])

  const addServiceProvider = async () => {

    console.log(formData)
    if (!formData.emailid || !formData.service || !formData.FullName || !formData.mobileNumber || !formData.subservice || !formData.image) {
      notify('error', 'Please fill in all required fields')
      return;
    }
    // Contact number validation
    else if (!/^[0-9]{10}$/.test(formData.mobileNumber)) {
      notify('error', 'Please enter a valid 10-digit contact number')
      return;
    }
    // Email validation
    else if (!/\S+@\S+\.\S+/.test(formData.emailid)) {
      notify('error', 'Invalid email address')
      return;
    }

    const response = await profileapi.AddServiceprovider(formData?.emailid, formData?.image, formData?.mobileNumber, formData?.FullName, formData?.service, formData?.subservice)
    console.log(response);
    serviceCountForRerender.current = serviceCountForRerender?.current + 1;
    addedServiceProvider2();
    if (response.data.error !== '') {
      notify('error', response.data.error)
    }
    else {
      notify('success', 'Successfully Added Service Provider')
      setFormData({
        FullName: '',
        emailid: '',
        mobileNumber: '',
        service: '',
        subservice: '',
        image: ''
      })
      setImage("");
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }

  }


  const searchFromMyService = () => {
    let arr = [];
    console.log('ere')
  }
  const handleDeleteServiceProvider = async (id) => {
    try {
      const response = await profileapi.DeleteServiceProvider(id);
      console.log(response);
      if (response.data.error !== '') {
        notify('error', response.data.error);
      } else {
        notify('success', 'Service provider deleted successfully');
        // Call the addedServiceProvider function to fetch updated data
        addedServiceProvider();
      }
    } catch (error) {
      notify('error', 'An error occurred while deleting the service provider');
      console.error(error);
    }
  };
  const handleEditServiceProvider = (provider) => {
    console.log(provider);
    const numericMobile = provider.mobile.substring(3);
    console.log(numericMobile);
    setEditingProvider(provider);
    setIsEditing(true);
    setFormData({
      FullName: provider?.provider,
      mobileNumber: parseInt(numericMobile),
      image: provider?.logo,
      service: provider?.services[0]?.service_id,
      subservice: provider?.services[0]?.sub_service_id,
    });

  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditingProvider(null);
    setFormData({
      emailid: '',
      mobileNumber: '',
      image: '',
      service: '',
      subservice: '',
    });

  };

  const handleSaveChanges = async () => {
    if (!formData.emailid && !formData.mobileNumber && !formData.image && !formData.subservice) {
      notify('error', 'Please provide at least one field to update');
      return;
    }
    console.log(editingProvider.id);
    if (!formData.subservice) {
      notify('error', 'Please select a subservice');
      return;
    }

    try {

      const response = await profileapi.ModifyProvider(
        editingProvider.id,
        formData.image,
        formData.mobileNumber, formData.FullName, formData.service, formData.subservice
      );

      if (response.data.error !== '') {
        notify('error', response.data.error);
      } else {
        notify('success', 'Successfully updated service provider details');

      }
    } catch (error) {
      notify('error', 'An error occurred while updating service provider details');
      console.error(error);
    }

    // Reset the form and editing states after saving
    setIsEditing(false);
    setEditingProvider(null);
    setFormData({
      emailid: '',
      mobileNumber: '',
      image: '',
      service: '',
      subservice: '',
    });
    addedServiceProvider();

  };



  return (
    <React.Fragment>
      <div className='profile-section-container-add-service-provider'>
        <div className='add-service-provider-profile-left'>
          {subPage ? (
            <>
              <div
                className='add-service-provider-profile-left-1-active'
                onClick={IsActive}
              >
                <div></div>
                <span>Add service provider</span>
              </div>
              <div
                className='add-service-provider-profile-left-2'
                onClick={IsActive2}
              >
                <div></div>
                <span>Added service provider</span>
              </div>
            </>
          ) : (
            <>
              <div
                className='add-service-provider-profile-left-1'
                onClick={IsActive}
              >
                <div></div>
                <span>Add service provider</span>
              </div>
              <div
                className='add-service-provider-profile-left-2-active'
                onClick={IsActive2}
              >
                <div></div>
                <span>Added service provider</span>
              </div>
            </>
          )}
        </div>
        <div className='add-service-provider-profile-right add-service-provider-profile-right1' >
          {subPage ? (
            <>
              <div className='add-service-provider-profile-right-heading'>
                Please complete the fields below in order to recommend a service
                provider . We will verify and activate them here for service
              </div>
              <div className='add-service-provider-profile-right-inputs add-service-provider-profile-right-inputs1'>
                <span>Full Name</span>
                <input
                  type='text'
                  name='FullName'
                  placeholder='Enter Full Name'
                  value={formData?.FullName}
                  onChange={searchInput}
                  maxLength={15}
                ></input>
              </div>
              <div className='add-service-provider-profile-right-inputs add-service-provider-profile-right-inputs1'>
                <span>Email Id</span>
                <input
                  type='email'
                  name='emailid'
                  placeholder='Enter your email'
                  value={formData?.emailid}
                  onChange={searchInput}
                ></input>
              </div>
              <div className='add-service-provider-profile-right-inputs add-service-provider-profile-right-inputs1'>
                <span>Mobile Number</span>
                <input
                  type='number'
                  name='mobileNumber'
                  maxLength='10'
                  placeholder='Enter your number'
                  value={formData?.mobileNumber}
                  onChange={searchInput}
                ></input>
              </div>
              <div className='add-service-provider-profile-right-inputs add-service-providerservices'>
                <span>Service</span>
                <select
                  name='service'
                  placeholder='Enter your service'
                  value={formData?.service}
                  onChange={searchInput}
                >
                  <option value=''>Enter your service</option>
                  {serviceSubSerivce &&
                    serviceSubSerivce?.service_list &&
                    serviceSubSerivce?.service_list?.map((item) => {
                      return <option value={item?.id}>{item?.name}</option>;
                    })}
                </select>
              </div>
              <div className='add-service-provider-profile-right-inputs add-service-providerservices'>
                <span>Sub Service</span>

                <select
                  name='subservice'
                  placeholder='Enter your subservice'
                  value={formData?.subservice}
                  onChange={searchInput}
                >
                  <option value=''>Enter your subservice</option>
                  {subId && subId.map((item) => {
                    return (
                      <option value={item?.id}>{item?.sub_service}</option>
                    );
                  })}
                </select>
              </div>
              <div className='add-service-provider-profile-right-image-upload '>
                {/* <span>Upload Image</span>
                <img src={addpic}></img>
                <input ref={fileInputRef} onChange={searchInput} name='image' type="file" accept='image/*' /> */}
                {!image ?
                  <form className="imageInputform">
                    <label htmlFor="image-input">Select Image</label>
                    <input
                      type="file"
                      id="image-input"
                      name='image'
                      accept="image/png, image/jpg, image/gif, image/jpeg"
                      onChange={(e) => searchInput(e)}
                      ref={fileInputRef}
                    />
                  </form>
                  :
                  <div className='imageHolder'>
                    <IoCloseCircleSharp
                      size={30}
                      className="crossXIcon"
                      onClick={() => setImage("")}
                    />
                    <img src={image} alt='' />
                  </div>
                }
              </div>


              <div className='add-service-provider-profile-right-submit'>
                <button onClick={addServiceProvider}>Submit</button>
              </div>
            </>
          ) : (
            <>
              {!isEditing && (

                <div className='add-service-provider-profile-right-inputs-2'>
                  <input
                    type='text'
                    name='serviceprovider'
                    placeholder='Search Service Provider'
                    value={formData2}
                    onChange={searchservice}
                    onKeyUp={searchFromMyService}
                  ></input>
                </div>
              )}
              {isEditing ? (
                <div className='add-service-provider-profile-right-edit-form'>
                  <span>Edit Service Provider</span>
                  {/* Form fields for editing */}
                  <div className='add-service-provider-profile-right-inputs'>
                    <span>Full Name</span>
                    <input
                      type='text'
                      name='FullName'
                      placeholder='Enter Name'
                      value={formData?.FullName}
                      onChange={searchInput}
                    ></input>
                  </div>
                  <div className='add-service-provider-profile-right-inputs'>
                    <span>Mobile Number</span>
                    <input
                      type='number'
                      name='mobileNumber'
                      maxLength='10'
                      readOnly
                      placeholder='Enter your number'
                      value={formData?.mobileNumber}
                      onChange={searchInput}
                    ></input>
                  </div>
                  <div className="add-service-provider-profile-right-inputs">
                    <span>Service</span>
                    <select
                      name='service'
                      placeholder='Enter your service'
                      value={formData.service}
                      onChange={searchInput}
                    >

                      {serviceSubSerivce &&
                        serviceSubSerivce?.service_list &&
                        serviceSubSerivce?.service_list?.map((item) => {
                          return (
                            <option key={item?.id} value={item?.id}>
                              {item?.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                  <div className='add-service-provider-profile-right-inputs'>
                    <span>Sub Service</span>
                    <select
                      name='subservice'
                      placeholder='Enter your subservice'
                      value={formData.subservice}
                      onChange={searchInput}
                    >
                      <option value=''>Enter your Subservice</option>
                      {subId &&
                        subId.map((item) => {
                          console.log(item);
                          return (
                            <option key={item?.id} value={item?.id}>
                              {item?.sub_service}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                  <div className='add-service-provider-profile-right-image-upload'>
                    <span>Upload Image</span>
                    <input
                      ref={fileInputRef}
                      onChange={searchInput}
                      name='image'
                      type='file'
                      accept='image/*'
                    />
                  </div>
                  <div className='add-service-provider-profile-right-submit row-button'>
                    <button onClick={handleSaveChanges}>Save Changes</button>
                    <button onClick={handleCancelEdit}>Cancel</button>
                  </div>
                </div>
              ) :

                <div className='add-service-provider-profile-right-search-result add-service-provider-profile-right-search-result12'>
                  {/* add map here */}
                  {serviceProvider && serviceProvider?.myServiceProvider?.length > 0 ? (
                    serviceProvider?.myServiceProvider?.map((item, index) => {
                      if (formData2 !== '' && formData2) {
                        console.log(formData2)
                        //how to implement search //search string //search js code //search in frontened
                        if (item?.provider?.toUpperCase().indexOf(formData2?.toUpperCase()) > -1) {
                          return (
                            <CardserviceComponent key={index} item={item} onDelete={handleDeleteServiceProvider}
                              onEdit={handleEditServiceProvider} />
                          )
                        };
                      }
                      else {
                        return (
                          <CardserviceComponent key={index} item={item} onDelete={handleDeleteServiceProvider}
                            onEdit={handleEditServiceProvider} />
                        );
                      }
                    }
                    )
                  ) : (
                    <>
                      <div>No Data To Show</div>
                    </>
                  )}

                </div>
              }
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}


const CardserviceComponent = ({ item, onDelete, onEdit }) => {
  const [display, setDisplay] = React.useState(false);
  const modalRef = React.useRef(null);

  const handleCardMenuToggle = () => {
    setDisplay(!display);
  };
  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {

    if (modalRef.current &&
      !modalRef.current.contains(event.target)
    ) {
      setDisplay(false);
    }
  };

  return (
    <div className='add-service-provider-profile-right-search-result-box'  >
      <div className='add-service-provider-profile-right-search-result-box-image'>
        <img src={item?.logo} alt=''/>
        <div className='add-service-provider-profile-right-search-result-box-detail'>
        <span>{item?.provider}</span>
        <div>{item?.distance} Km</div>
      </div>
      </div>
      <div className='add-service-provider-profile-right-search-result-box-phone'>
        {item?.mobile}
      </div>
      <div className='add-service-provider-profile-right-search-result-box-gen-detail'>
        <div>{item?.services[0]?.service_name}</div>
        <span>
          {item?.rating} <img src={star}></img>
        </span>

      </div>
      <div className='chat-box-person-icons-col-3' ref={modalRef} >
        <div className='chat-box-person-card-delete-col3' >

          <div onClick={handleCardMenuToggle}></div>
          <div onClick={handleCardMenuToggle}></div>
          <div onClick={handleCardMenuToggle}></div>


          {display && (
            <section
              className='chat-box-person-card-delete-col3-thredots'
              onClick={handleCardMenuToggle}
            >
              <span onClick={() => onDelete(item.id)} >Delete</span>

              <span
                onClick={() => onEdit(item)}
              >
                Edit
              </span>

            </section>
          )}
        </div>
      </div>
    </div>
  );
};