import axios from 'axios';
//company
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

class PutatoeBankApi {
  ViewBankAccounts() {
    return axios.get(API_BASE_URL + '/v1/api/viewAcoounts', {
      headers: {
        authtoken: localStorage.getItem('authtoken'),
      },
    });
  }
  RemoveBankAccount(account_id) {
    return axios.post(
      API_BASE_URL + '/v1/api/removebank',
      { account_id },
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }
  TransferMoney(account_id,amount) {
    return axios.post(
      API_BASE_URL + '/v1/api/transferfromwallet',
      { account_id,renew_reward_point:amount },
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }
  AddBankAccount(acc_no, acc_type, acc_holder_name, IFSC) {
    return axios.post(
      API_BASE_URL + '/v1/api/addAccount',
      { acc_no, acc_type, acc_holder_name, IFSC },
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }
  ModifyBankAccount(id, acc_number, acc_type, acc_holder_name, ifsc) {
    return axios.post(
      API_BASE_URL + '/v1/api/modifyBankAccount',
      { id, acc_number, acc_type, acc_holder_name, ifsc },
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }

  // GetAllCircle() {
  //   return axios.get(API_BASE_URL + '/v1/api/getCircles', {
  //     headers: {
  //       authtoken: localStorage.getItem('authtoken'),
  //     },
  //   });
  // }

  ApplyLoan(
    name,
    mobile,
    email,
    city,
    is_salaried,
    annual_income,
    loan_type,
    is_user,
    loan_amount
  ) {
    return axios.post(
      API_BASE_URL + '/v1/api/applyloan',
      {
        name,
        mobile,
        email,
        city,
        is_salaried,
        annual_income,
        loan_type,
        is_user,
        loan_amount,
      },
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }

  GetAuth() {
    return axios.get('https://www.universal-tutorial.com/api/getaccesstoken/', {
      headers: {
        // authtoken: localStorage.getItem('authtoken'),
        Accept: 'application/json',
        'api-token':
          'e_A4xj9C60mrjRNVkl22g2HqrAcnZ7DgKDw_BM7yt5K2Ox-eNRc6buF6LaVRNDbTjSk',
        'user-email': 'viceke7523@pantabi.com',
      },
    });
  }
}

export default new PutatoeBankApi();
