import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

class MyBillsService {
  GasOperatorList() {
    return axios.get(API_BASE_URL + '/v1/api/GasOperators', {
      headers: {
        authtoken: localStorage.getItem('authtoken'),
        'Content-Type': 'application/json',
      },
    });
  }
  ElectricOperatorList() {
    return axios.get(API_BASE_URL + '/v1/api/ElectricOperators', {
      headers: {
        authtoken: localStorage.getItem('authtoken'),
        'Content-Type': 'application/json',
      },
    });
  }
  OtherOperatorList(type) {
    return axios.post(
      API_BASE_URL + '/v1/api/getOperator',
      { type },
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }

  RechargeMyDTHApi(
    operator_code,
    DTH,
    amount,
    coupon_id,
    payment_method
  ) {
    return axios.post(
      API_BASE_URL + '/v1/api/rechargeDTH',
      { operator_code, DTH, amount, coupon_id, payment_method },
      {
        headers: {
          authtoken: localStorage.getItem('authtoken'),
        },
      }
    );
  }
}
export default new MyBillsService();
