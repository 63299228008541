import React from 'react'
import { Link } from 'react-router-dom'
import './Pagenotfound.css'

const Pagenotfound = () => {
  return (
    <div className='pagenotfound-main'>
         <div className='pagenotfound-inner-container'>
      <div className="pagenotfound-content">
        <h1>Sorry, this page isn't available.</h1>
        <p>The link you followed may be broken, or the page may have been removed. <Link to={"/"} > Go back to Putatoe.</Link></p>
      </div>
      </div>
    </div>
  )
}

export default Pagenotfound